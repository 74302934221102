import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrandColourEditorComponent } from './brand-colour-editor/brand-colour-editor.component';
import { BrandingDemoComponent } from './branding-demo/branding-demo.component';
import { BrandingHomeComponent } from './branding-home/branding-home.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ColourSwatchesComponent } from './colour-swatches/colour-swatches.component';
import { BrandFontEditorComponent } from './brand-font-editor/brand-font-editor.component';
import { BrandHubVisibilityEditorComponent } from './brand-hub-visibility-editor/brand-hub-visibility-editor.component';
import { BrandLogoEditorComponent } from './brand-logo-editor/brand-logo-editor.component';
import { BrandLogosComponent } from './brand-logos/brand-logos.component';
import { LoadersModule } from '../loaders/loaders.module';
import { TabsModule } from '../navigation/tabs/tabs.module';
import { CardsModule } from '../content';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { BrandsListingComponent } from './brands-listing/brands-listing.component';
import { BrandsNewComponent } from './brands-new/brands-new.component';
import { BrandHomeComponent } from './brand-home/brand-home.component';
import { BrandSlugEditorComponent } from './brand-slug-editor/brand-slug-editor.component';
import { BrandsRoutingModule } from './brands-routing.module';


import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { CollapseModule as NgxCollapseModule } from 'ngx-bootstrap/collapse';
import { LinksModule } from '@frontend/shared';
import { UploadersModule } from '@frontend/shared';
import { WebsitesModule } from '../sites';

@NgModule({
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'branding' }],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    BrandsRoutingModule,
    TabsModule,
    AlertModule,
    TranslocoModule,
    LoadersModule,
    CardsModule,
    NgxCollapseModule,
    UploadersModule,
    LinksModule,
    WebsitesModule,
  ],
  exports :
  [
    BrandColourEditorComponent,
    BrandingDemoComponent,
    BrandingHomeComponent,
    ColourSwatchesComponent,
    BrandsListingComponent,
    BrandsNewComponent
  ],
  declarations: [
    BrandColourEditorComponent,
    BrandFontEditorComponent,
    BrandHubVisibilityEditorComponent,
    BrandSlugEditorComponent,
    BrandLogoEditorComponent,
    BrandLogosComponent,
    BrandingDemoComponent,
    BrandingHomeComponent,
    ColourSwatchesComponent,
    BrandFontEditorComponent,
    BrandsListingComponent,
    BrandsNewComponent,
    BrandHomeComponent
  ]
})
export class BrandingModule { }
