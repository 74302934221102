import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepProgressIndicatorComponent } from './step-progress-indicator.component';



@NgModule({
  declarations: [
    StepProgressIndicatorComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    StepProgressIndicatorComponent
  ]
})
export class StepProgressIndicatorModule { }
