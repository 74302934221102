<h3 *ngIf="titleText || titleTranslationKey"> <span *transloco="let t">{{ titleTranslationKey ? t(titleTranslationKey) :
    titleText}}</span> </h3>


<progress-indicator-card-simple ngClass="hide-progress" *ngIf="!hideProgressIndicator && (surveyProgressValue === 0 || surveyProgressValue > 0)"
  [titleTranslationKey]="'tracking.progress'" [value]="surveyProgressValue"
  [unit]="'%'"></progress-indicator-card-simple>

<div *ngFor="let question of questions">
  <multisite-question-multiple-choice
    *ngIf="question.class === 'MultipleChoiceQuestion'"
    [question]="question"
    [feedback]="questionFeedbacks[question.question_id]"
    [showTryAgainButtonWithFeedback]="showTryAgainButtonWithFeedback"
    (submitResponse)="doSubmitResponse($event)"
    [loading]="loading"
    [error_message_translation_key]="error_message_translation_key"
    >
  </multisite-question-multiple-choice>
  <multisite-question-lickert
    *ngIf="question.class === 'LickertQuestion'"
    [question]="question"
    [feedback]="questionFeedbacks[question.id]"
    [showTryAgainButtonWithFeedback]="showTryAgainButtonWithFeedback"
    [surveyProgressValue]="surveyProgressValue"
    [surveyProgressUnit]="'%'"
    (submitResponse)="doSubmitResponse($event)"
    [loading]="loading"
    [error_message_translation_key]="error_message_translation_key"
    >
  </multisite-question-lickert>

</div>
<div *ngIf="showNextButton">
  <button class="btn btn-primary" (click)="gotoNext()" [disabled]="disableNextButton"><span *transloco="let t">{{
      t('common.next')}}</span></button>
</div>
<div class="row">
  <div class="col-lg my-2" *ngIf="showSnapshotContext">
    <snapshot-context *ngIf="snapshot && showSnapshotContext"
    [date]="snapshot.date"
    [title]="snapshot.title"
    [description]="snapshot.description"
    [cultures]="snapshot.cultures"
    [primary_culture_id]="snapshot.primary_culture_id"
  ></snapshot-context>
  </div>
  <div class="col-lg my-2" *ngIf="showMultiRaters">
    <multi-rater-list-card
    [raters]="raters"
    ></multi-rater-list-card>
  </div>
</div>