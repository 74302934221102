import { Tag, Video } from "@frontend/core";
import { CloudinaryMediaAsset } from "../models";
import { Culture, Topic, UserExtraLite } from '@frontend/common';
import { ContentItemMeta } from './content-item-meta.model';


export class ContentItem {

    // a generic content type for displaying various other classes

    constructor (
        public id : number,
        public title : string,
        public titleTranslationKey : string, // 'ciprofile.act'
        public subtitle : string,
        public subtitleTranslationKey : string, // 'ciprofile.act'
        public description : string,
        public descriptionTranslationKey : string, // 'ciprofile.act'
        public body : string, // allows HTML
        public slug : string,
        public cta_text: string, // if a link, we should generate this from the Link model's 'cta_label'
        public cta_translationKey: string, // 'survey.complete_your_profile' // if a link, we should generate this from the Link model's 'cta_type'
        public media: CloudinaryMediaAsset,
        public additional_media: CloudinaryMediaAsset[],
        public video: Video,
        public rating: number,
        public duration: number, // minutes
        public date: Date,
        public tags: Tag[] | Culture[] | Topic[],
        public user: UserExtraLite,
        public meta: ContentItemMeta,
         ) {
        this.id = id;
        this.title = title;
        this.subtitle = subtitle;
        this.description = description;
        this.body = body;
        this.cta_text = cta_text;
        this.cta_translationKey = cta_translationKey;
        this.media = media;
        this.additional_media = additional_media;
        this.video = video;
        this.rating = rating;
        this.duration = duration;
        this.date = date,
        this.tags = tags;
        this.user = user;
        this.meta = meta;
    }
}