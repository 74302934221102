import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TabsModule as NgxTabsModule } from 'ngx-bootstrap/tabs';
import { TabsBasicComponent } from './tabs-basic/tabs-basic.component';
import { TranslocoModule } from '@jsverse/transloco';




@NgModule({
  declarations: [
    TabsBasicComponent,
  ],
  imports: [
    CommonModule,
    NgxTabsModule,
    TranslocoModule,
  ],
  exports:[
    TabsBasicComponent
  ]
})
export class TabsModule { }
