import { Component, OnInit } from '@angular/core';

import { Hub, StatusItem } from '@frontend/common';
import { Subscription } from 'rxjs';


import { ActivatedRoute, /*Router,*/ Params } from '@angular/router';
import { AuthService, User } from '../../auth';
import { HubManagementTask, HubService } from '../hub.service';
import { PageTitleService } from '../../navigation/title/title.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalStorageNotificationStatus, NotificationService } from '../../notifications/notification.service';

@Component({
  selector: 'multisite-hub-home',
  templateUrl: './hub-home.component.html',
  styleUrls: ['./hub-home.component.scss']
})
export class HubHomeComponent implements OnInit {

  hub : Hub;
  hubSubscription: Subscription;
  loading: boolean;
  user : User = null;
  userSubscription: Subscription;
  isAuthenticated : boolean;
  error = null;
  nameEditable : boolean;
  hubNameForm : FormGroup;
  tasks : HubManagementTask[];
  masterTask : HubManagementTask;
  alertsCollapsed : boolean = true;
  alertsDismissed : LocalStorageNotificationStatus;
  statusItemsObject : {
    [key:string]:StatusItem[]
  }


  constructor(
    private hubService : HubService,
    private route : ActivatedRoute,
    // private router : Router,
    private authService : AuthService,
    private pageTitleService : PageTitleService,
    private notificationService : NotificationService
  ) { 
    this.statusItemsObject = {
      roles : [
        {name: 'hub_owner', translationKey: 'hubs.hub_owner'},
        {name: 'hub_managers', translationKey: 'hubs.hub_managers'},
        {name: 'contact_persons', translationKey: 'common.contact_persons'},
        {name: 'trainers', translationKey: 'common.trainers'},
      ],
      marketing : [
        {name: 'slogans', translationKey: 'content_management.slogans'},
        {name: 'descriptions', translationKey: 'common.descriptions'},
        {name: 'banners_and_other_images', translationKey: 'content_management.banners_and_other_images'},
        {name: 'brands', translationKey: 'navigation.brands'},
      ],
      cultureconnector : [
        {name: 'promo-links', translationKey: 'hubs.cco.promo_links'},
        {name: 'referral-codes', translationKey: 'hubs.cco.referral_codes'},
      ],
    }
  }

  makeHubNameEditForm (){
    this.hubNameForm = new FormGroup({
      name: new FormControl(this.hub.name, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
    });
  }
  handleSubAreaSelection(){
    // not in use
  } 
  dismissTaskAlert (){
    this.tasks = [];
    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    this.notificationService.putNotificationStatusInLocalStorage('hub',this.hub.id.toString(),'pending_tasks',{type_dismissed_until: futureDate})
  }
  updateStatusItems(tasks : HubManagementTask[], statusItemsObject : {[key:string]:StatusItem[]}){
    for (let category in statusItemsObject){
      statusItemsObject[category].forEach(si=>{
        let task = tasks.find(t=>t.related_status_item === si.name);
        if (task){
          if( !si.status || si.status === 'info' || (si.status === 'warning' && task.status !== 'info')  ){
              si.status = task.status;
          }
        }
      })
    }
    return statusItemsObject;
  }
  handleHubLoadSuccess (response){
    if (response){
      this.hub = response;
      this.makeHubNameEditForm();
      this.alertsDismissed = this.notificationService.getLocalNotificationStatusFromLocalStorage('hub',this.hub.id.toString(),'pending_tasks');
      let taskObject = this.hubService.generateTaskList(this.hub,0);
      this.masterTask = taskObject.masterTask;
      this.tasks = taskObject.tasks;
      if (this.tasks?.length < 2){
          this.alertsCollapsed = false;
      };
      this.statusItemsObject = this.updateStatusItems(this.tasks,this.statusItemsObject);
    }
    this.pageTitleService.setTitle(this.hub.name);
    this.loading = false;
  }
  getManagedHub (slug){
    this.loading = true;
    this.error = null;
    slug = slug ? slug : this.route.snapshot.params['hub'];
    this.hubSubscription = this.hubService.getManagedHub (slug)
      .subscribe(
        response => {
          this.handleHubLoadSuccess(response);
        },
        error => {
          // this.hub = this.makeFakeHub();
          this.error = error;  
          this.loading = false;
        }
      );
  };
  
  updateManagedHub (name:string){
    this.loading = true;
    this.error = null;
    this.hubSubscription = this.hubService.updateManagedHub (this.hub.slug,{name:name})
      .subscribe(
        response => {
          this.handleHubLoadSuccess(response);
          this.toggleNameEditState();
        },
        error => {
          this.error = error;  
          this.loading = false;
        }
      );
  };

  toggleNameEditState (){
    this.nameEditable = !this.nameEditable;
  }
  isAdmin (){
    return this.authService.checkRole("Admin");
  }
  ngOnInit(): void {
    this.route.params.subscribe(
      (params : Params)=>{
        this.getManagedHub (params['hub']);
      }
    );
    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
      this.isAuthenticated = !!user;
    });
  }
  ngOnDestroy () {
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    };
    if (this.hubSubscription){
      this.hubSubscription.unsubscribe();
    }
  }

}
