<!-- <div class="card mb-3">
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="..." class="card-img" alt="...">
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <h5 class="card-title">Card title</h5>
        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
      </div>
    </div>
  </div>
</div> -->



<div class="card mb-3">
  <div class="row no-gutters">
    <div class="col-sm-4" *ngIf="link.media && !hideImage">
      <div class="card-body">
        <a class="title-link" [href]="link.url">
          <frontend-media-image
            [classes]="'card-img'"
            [media]="link.media"
            [transformations]="imageTransformations ? imageTransformations : 'w_700/'"
            >
          </frontend-media-image>
        </a>
      </div>
    </div>
    <div class="col">
      <div class="card-body text-center text-sm-left">
        <h5 class="card-title"><a class="title-link" [href]="link.url">{{link.label}}</a></h5>
        <p>{{link.description}}</p>
        
        <div class="card-text d-flex justify-content-center justify-content-sm-left" *ngIf="!hideButton && (link.cta_label || ctaTranslation)">
          <a [href]="link.url">
          <button mdbBtn type="button" color="secondary" rounded="true" outline="true" mdbWavesEffect>
              <span *ngIf="ctaTranslation && !link.cta_label">
                <ng-template transloco let-t>
                  <span> {{ t(ctaTranslation) }} </span>
                </ng-template>
              </span>
              <span *ngIf="link.cta_label">{{link.cta_label}}</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
