import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { WindowService } from '@frontend/common';
import { Subscription, take } from 'rxjs';
import { AuthService, User, UserExtraLite } from '../../../auth';
import { Guest } from '../../../auth/user/guest.model';
import { AvailableLanguage, LanguageService } from '../../../language';
import { BreadcrumbService } from '../../../navigation/breadcrumb/breadcrumb.service';
import { PageTitleService } from '../../../navigation/title/title.service';
import { Experience, Progressable } from '../../../tracking';
import { TrackingService } from '../../../tracking/tracking.service';
import { Snapshot } from '../../snapshots';
import { Survey } from '../../survey.model';
import { SurveysService } from '../../surveys.service';

@Component({
  selector: 'lib-question-mode-home-type-one',
  templateUrl: './question-mode-home-type-one.component.html',
  styleUrls: ['./question-mode-home-type-one.component.scss']
})
export class QuestionModeHomeTypeOneComponent implements OnInit {

  loadingQuestions : boolean;

  survey : Survey;
  questions: any[];
  questionFeedbacks: any = {}; // [question_id]['correction'][any]
  showNextButton: boolean = false;
  showTryAgainButtonWithFeedback: string; // 'survey.try_again' - a translation key
  questionsSub: Subscription;
  questionResponseSub: Subscription;
  surveySub: Subscription;
  relatedExperiencesSub: Subscription;
  progressablesSubscription: Subscription;
  surveyProgressValue: number;
  loading: boolean;
  loadingSurvey: boolean;
  loadingQuestionResponse: boolean;
  loadingRelatedExperiences: boolean;
  loadedRelatedExperiences: boolean; // to avoid requesting these twice
  savedResponseData: any; // saved here while we create a Guest. TODO consider putting this in localstorage.
  user : User = null;
  guest : Guest = null;
  userSubscription: Subscription;
  guestSub: Subscription; // existing from storage
  activeLanguageSubscription: Subscription;
  activeLanguageObject : AvailableLanguage;
  showSnapshotContext: boolean = false;
  snapshotContext: any; // later this will hold the user's snapshot data
  snapshot: Snapshot;
  showMultiRaters: boolean = false;
  multiRaters: UserExtraLite;
  hideProgressIndicator: boolean = true;

  error = null;
  surveyResponseError = null;
  loadingCompletionSub: Subscription;
  loadingCompletionStatus: boolean;

  constructor(
    private breadcrumbService : BreadcrumbService,
    private surveysService: SurveysService,
    private route : ActivatedRoute,
    private router : Router,
    private authService : AuthService,
    private pageTitleService : PageTitleService,
    private trackingService : TrackingService,
    private languageService: LanguageService,
    private windowService: WindowService,
  ) { }

  findCurrentSurveyProgress (progressables : Progressable[]){
    if (!progressables?.length || !this.survey){
      return null;
    }
    return this.trackingService.getProgressFromProgressables(progressables,'survey',this.survey.slug);
  }
  subscribeToSurveyProgress (){
    this.progressablesSubscription = this.trackingService.progressables.subscribe( progressables => {
      this.surveyProgressValue = this.findCurrentSurveyProgress(progressables);
    })
  }
  getRelatedExperiences (){
    if (!this.user && !this.guest){
      // do something or nothing
    } else if (this.survey && !this.loadedRelatedExperiences && !this.loadingRelatedExperiences) {
      this.loadingRelatedExperiences = true;
      this.surveysService.getRelatedExperiences (this.survey.slug,+this.route.snapshot.params['snapshot'])
        .subscribe(
          (response : Experience[]) => {
            this.loadingRelatedExperiences = false;
            this.loadedRelatedExperiences = true;
          },
          error => {
            this.loadingRelatedExperiences = false;
          }
        );
    }
  }

  redirectIfSnapshotNotAccepted(snapshot: Snapshot){
    if (this.snapshot && !this.snapshot.current_accepted){
      this.router.navigate(['/snapshots/'+snapshot.id], { relativeTo: this.route });
    }
  }
  setSnapshot(snapshot:Snapshot){
    this.snapshot = snapshot;
    this.redirectIfSnapshotNotAccepted(snapshot);
  }

  setSnapshotContextVisibility (questions: any[], survey: Survey){
    let showSnapshotContext : boolean = false;
    if (survey?.type == 'profiling' && questions?.length){
      showSnapshotContext = questions.find(q => !q.hide_context);
    }
    return showSnapshotContext;
  }
  setRatersVisibility (questions: any[], survey: Survey){
    let showMultiRaters : boolean = false;
    if (survey?.type == 'profiling' && questions?.length){
      showMultiRaters = questions.find(q => !q.hide_raters);
    }
    return showMultiRaters;
  }

  getQuestions (){
    this.loadingQuestions = true;
    this.questionsSub = this.surveysService.getNextQuestionBySurvey(this.survey.slug)
      .subscribe(
        response => {
          if (response?.question) {
            let truncatedQuestion = response.question.text?.length > 60 ? response.question.text.substring(0,45)+'...' : response.question.text;
            // this.pageTitleService.setTitle(truncatedTitle);
            this.breadcrumbService.setBreadcrumbFragment({urlFragment:'questions',fragmentName:truncatedQuestion});
            this.questions = [response.question];
            this.showSnapshotContext = this.setSnapshotContextVisibility(this.questions,this.survey);
            this.showMultiRaters = this.setRatersVisibility(this.questions,this.survey);
            this.windowService.goToTop();
          } else if (response?.message === 'no_more_questions'){
            this.surveysService.surveyCompleteAlertSubject.next(this.survey.slug);
            this.surveysService.getRelatedExperiences (this.survey.slug, +this.route.snapshot.params['snapshot'], true).subscribe();
            this.router.navigate(['../feedback'], { relativeTo: this.route });
          }

          this.loadingQuestions = false;
          this.showNextButton = false;

        },
        error => {
          this.loadingQuestions = false;
        }
      );
  };
  getProgress (){  
    // this.trackingService.getTotalProgressCoursesOnly().subscribe();
    this.trackingService.getProgressables('survey',this.survey.id).subscribe();
  }
  

  doSubmitSurveyResponse(responseData){
    if(!this.user && !this.guest){
      // this.makeGuest(responseData);
      alert('It looks like you have no account yet. Please go to the start and then log in or start again.')
    }
    this.loadingQuestionResponse = true;
    this.showNextButton = false;
    this.questionResponseSub = this.surveysService.submitSingleQuestionResponse(responseData.survey_id,responseData.question_id,responseData.response)
      .subscribe(
        response => {
          this.getProgress ();
          if (this.survey.correction === 'hidden' || this.survey.correction === 'on_completion'){
            this.questions = null;
            this.getQuestions()
          } else {
            if (response?.corrections?.length){
              this.questionFeedbacks[responseData.question_id] = response.corrections.find(c=>c.question_id ===responseData.question_id);
              this.showNextButton = true;
            };
          }
          this.loadingQuestionResponse = false;
        },
        error => {
          this.surveyResponseError = error?.message;
          this.loadingQuestionResponse = false;
        }
      )
  }
  grammaticalFormsArrayForThisSurveyInCurrentLanguage (){
    if(!this.survey || !this.activeLanguageObject ){
      return [];
    };
    return this.survey.meta?.available_languages?.find(al=>al.iso === this.activeLanguageObject.languageKey)?.grammatical_forms ?? [];
  }
  forceLanguagePreferences(){

      if(!this.survey || !this.activeLanguageObject ){
        return;
      };

      if (!this.survey.meta.available_languages.find(al=>al.iso === this.activeLanguageObject.languageKey) && !this.survey.meta.allow_locales_not_included_in_available_languages) {
        this.router.navigate(['../language'], { relativeTo: this.route });
        return;
      };

      this.error = null;
      this.surveysService.getLanguagePreferences(this.survey.id).pipe(take(1)).subscribe(response => {
        let languagePreferences = response;
        let surveyRequiresLanguagePreferences = this.grammaticalFormsArrayForThisSurveyInCurrentLanguage().length > 0;
        if (surveyRequiresLanguagePreferences && !languagePreferences?.[this.activeLanguageObject.languageKey]){
          this.router.navigate(['../language'], { relativeTo: this.route });
        }
      },(error)=>{
        this.error = error;
      });
      
  }
  handleSurveyLoadingSuccess (){
    if (this.user || this.guest){
      this.getRelatedExperiences();
      this.getProgress();
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
    if (this.survey.correction == 'tease'){
      this.showTryAgainButtonWithFeedback = 'survey.try_again';
    }
    if(this.survey.meta?.available_languages){
      this.forceLanguagePreferences();
    }
    this.getQuestions();
    let truncatedTitle = this.survey.name?.length > 60 ? this.survey.name.substring(0,45)+'...' : this.survey.name;
    this.pageTitleService.setTitle(truncatedTitle);
    this.breadcrumbService.setBreadcrumbFragment({urlFragment:'survey',fragmentName:this.survey.name});
    this.loading = false;
  }
  getSurvey (slug:string, freshFromServer: boolean){
    this.loading = true;
    this.error = null;
    
    this.surveysService.getSurvey (slug, freshFromServer)
      .subscribe(
        response => {
          if (response){
            this.survey = response;
            this.handleSurveyLoadingSuccess()
          }
          this.loading = false;
        },
        error => {
          this.error = error;  
          this.loading = false;
        }
      );
  };

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
      if (this.survey){
        this.getRelatedExperiences();
      }
    });
    this.guestSub = this.authService.guest.subscribe(guest => {
      this.guest = guest;
      if (this.survey){
        this.getRelatedExperiences();
      }
      if (this.savedResponseData){
        this.doSubmitSurveyResponse(this.savedResponseData);
        this.savedResponseData = null;
      }
    });
    this.languageService.activeLanguageObject.pipe(take(1)).subscribe( (activeLanguageObject) => {
      // this is the behaviour subject, not the subject, so the activeLanguageObject is available when we need it.
      this.activeLanguageObject = activeLanguageObject;
    });
    this.route.params.subscribe(
      (params : Params)=>{
        this.getSurvey (params['survey'],false);
      }
    );
    this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (activeLanguageObject) => {
      // This is the subject, not the behaviour subject because we do not want to 'getSurvey' on first load. We are already getting the survey based on the route params 
      this.activeLanguageObject = activeLanguageObject;
      if(this.survey){
        this.getSurvey (this.survey.slug,true);
      }
      this.forceLanguagePreferences();
    })
    this.subscribeToSurveyProgress();
    this.hideProgressIndicator = !!this.route.routeConfig?.data?.hideProgressIndicator;
  }
  ngOnDestroy () {
    if (this.questionsSub){
      this.questionsSub.unsubscribe();
    };
    if (this.questionResponseSub){
      this.questionResponseSub.unsubscribe();
    };
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    }
    if (this.guestSub){
      this.guestSub.unsubscribe();
    }
    if (this.surveySub){
      this.surveySub.unsubscribe();
    }
    if (this.relatedExperiencesSub){
      this.relatedExperiencesSub.unsubscribe();
    }
    if (this.loadingCompletionSub){
      this.loadingCompletionSub.unsubscribe();
    }
    if (this.progressablesSubscription){
      this.progressablesSubscription.unsubscribe();
    }
    if (this.activeLanguageSubscription){
      this.activeLanguageSubscription.unsubscribe();
    }
  }

}
