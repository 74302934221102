
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyLanguagePreferencesComponent } from './survey-language-preferences.component';
import { TranslocoModule } from '@jsverse/transloco';
import { SurveyContextModule } from '../survey-context';
import { LoadersModule } from '../../loaders';
import { AvatarsModule } from '@frontend/shared';
import { AlertModule } from 'ngx-bootstrap/alert';



@NgModule({
  declarations: [
    SurveyLanguagePreferencesComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    SurveyContextModule,
    LoadersModule,
    AvatarsModule,
    AlertModule
  ],
  exports: [
    SurveyLanguagePreferencesComponent
  ]
})
export class SurveyLanguagePreferencesModule { }
