import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CertificationRoutingModule } from './certification-routing.module';
import { CertificationDisplayListsModule } from './certification-display/certification-display-lists/certification-display-lists.module';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CertificationRoutingModule,
    CertificationDisplayListsModule
  ],
  exports: [
  ]
})
export class CertificationModule { }
