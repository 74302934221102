<div class="container mt-5" style="padding-top: 9vh;">
    <p class="display-1">TEST TOOLTIP STYLES</p>
    <p class="h4">Buttons</p>
    <!-- Buttons -->
    <span class="btn btn-primary tooltip-custom">Tooltip on top
        <p class="tooltiptext-custom-top">Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</p>
    </span>
    <span class="btn btn-primary tooltip-custom">Tooltip on bottom
        <p class="tooltiptext-custom-bottom">Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</p>
    </span>
    <span class="btn btn-primary tooltip-custom">Tooltip on left
        <p class="tooltiptext-custom-left">Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</p>
    </span>
    <span class="btn btn-primary tooltip-custom">Tooltip on right
        <p class="tooltiptext-custom-right">Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</p>
    </span>
    <p class="h4 mt-5">Icon</p>
    <!-- Buttons -->
    <span>Top:</span>
    <span class="tooltip-custom"><i class="fa-solid fa-circle-info"></i>
        <p class="tooltiptext-custom-top">Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</p>
    </span>
    <span>Bottom:</span>
    <span class="tooltip-custom"><i class="fa-solid fa-circle-info"></i>
        <p class="tooltiptext-custom-bottom">Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</p>
    </span>
    <span>Right:</span>
    <span class="tooltip-custom"><i class="fa-solid fa-circle-info"></i>
        <p class="tooltiptext-custom-left">Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</p>
    </span>
    <span>Left:</span>
    <span class="tooltip-custom"><i class="fa-solid fa-circle-info"></i>
        <p class="tooltiptext-custom-right">Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</p>
    </span>
    <p class="h4 mt-5">Text</p>
    <span class="tooltip-custom tooltip-text">Tooltip on top
        <p class="tooltiptext-custom-top">Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</p>
    </span>
    <span>•</span>
    <span class="tooltip-custom tooltip-text">Tooltip on bottom
        <p class="tooltiptext-custom-bottom">Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</p>
    </span>
    <span>•</span>
    <span class="tooltip-custom tooltip-text">Tooltip on left
        <p class="tooltiptext-custom-left">Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</p>
    </span>
    <span>•</span>
    <span class="tooltip-custom tooltip-text">Tooltip on right
        <p class="tooltiptext-custom-right">Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</p>
    </span>
    <!-- End of buttons -->
    <h1 class="display-1">TEST TEXT STYLES</h1>
    <!-- testing font sizes -->
    <h1 class="display-1">Display 1</h1>
    <h1 class="display-2">Display 2</h1>
    <h1 class="display-3">Display 3</h1>
    <h1 class="display-4">Display 4</h1>
    <h1 class="display-5">Display 5</h1>
    <h1 class="display-6">Display 6</h1>
    <hr>
    <p>Default paragraph</p> 
    <hr>
    <h1>h1. Bootstrap heading</h1>
    <h2>h2. Bootstrap heading</h2>
    <h3>h3. Bootstrap heading</h3>
    <h4>h4. Bootstrap heading</h4>
    <h5>h5. Bootstrap heading</h5>
    <h6>h6. Bootstrap heading</h6>
    <hr>
    <p class="h1">h1. Bootstrap heading</p>
    <p class="h2">h2. Bootstrap heading</p>
    <p class="h3">h3. Bootstrap heading</p>
    <p class="h4">h4. Bootstrap heading</p>
    <p class="h5">h5. Bootstrap heading</p>
    <p class="h6">h6. Bootstrap heading</p>
    <hr>
    <a href="#">Default Link</a>
</div>
