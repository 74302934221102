import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MultipleChoiceQuestion } from './question-multiple-choice.model';
import { MultipleChoiceQuestionOption } from './question-multiple-choice-option.model';
import { MultipleChoiceResponse } from '@frontend/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'multisite-question-multiple-choice',
  templateUrl: './question-multiple-choice.component.html',
  styleUrls: ['./question-multiple-choice.component.scss']
})
export class QuestionMultipleChoiceComponent implements OnInit, OnChanges {

  @Input() question : MultipleChoiceQuestion;
  @Input() feedback : any;
  @Input() feedbackObject : any = {};
  @Input() showTryAgainButtonWithFeedback : string; // a translationKey, for example 'survey.try_again', if null, don't show the button at all
  @Input() imageTransformations : string; // 'w_275,ar_16:9/'
  @Output() submitResponse = new EventEmitter<MultipleChoiceResponse>()
  @Input() loading : boolean;
  @Input() error_message_translation_key : string;
  multipleChoiceForm: FormGroup;
  feedbackText : string;
  @ViewChild('imageEnlargedModal') imageEnlargedModal;
  modalRef?: BsModalRef;

  constructor(private modalService: BsModalService) { }

  doSubmitResponse(){
    // TODO - support multiselect (use formArray instead of formGroup?)
    this.feedback = null;
    this.feedbackText = null;
    this.feedbackObject = {};
    this.submitResponse.emit(new MultipleChoiceResponse(
      this.question.id, // Backend MultipleChoiceQuestion type
      this.question.question_id, // Backend Question type; this is missing from Staging 14.2.2024
      this.question.survey_id,
      [this.multipleChoiceForm.value.options]
    ));
  }
  clearError (){
    this.error_message_translation_key = null;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'image-enlarge-modal' })
    );
  }

  ngOnChanges(changesObject) {
    if (changesObject.feedback && changesObject.feedback.currentValue?.correction?.length){
      this.feedback.correction.forEach(f=>{
        this.feedbackObject[f.id]= f;
      });
      this.feedbackText = this.feedback.feedback;
    }

  }

  ngOnInit(): void {
    let formArrayOptions : FormControl[] = [];
    this.question.options.forEach((option: MultipleChoiceQuestionOption) => {
        formArrayOptions.push(new FormControl(option.value));
    });
    this.multipleChoiceForm = new FormGroup({
      options: new FormControl('')
    });
  }

}
