import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BrandColourEditorComponent } from './brand-colour-editor/brand-colour-editor.component';
import { BrandFontEditorComponent } from './brand-font-editor/brand-font-editor.component';
import { BrandHomeComponent } from './brand-home/brand-home.component';
import { BrandLogoEditorComponent } from './brand-logo-editor/brand-logo-editor.component';
import { BrandingDemoComponent } from './branding-demo/branding-demo.component';
import { BrandsListingComponent } from './brands-listing/brands-listing.component';
import { BrandsNewComponent } from './brands-new/brands-new.component';

const routes: Routes = [
    { path: '', component: BrandsListingComponent
  },
    {
      path: 'new',
      data: { label: 'New brand', translationKey: 'branding.new', labelSource: null},
      children: [
        {
          path: '',
          component: BrandsNewComponent,
        },
      ],
    },
    {
      path: ':brand',
      data: { label: 'Brand home', translationKey: null, labelSource: 'titleService'},
      children: [
        {
          path: '',
          component: BrandHomeComponent,
        },
      ],
    },
    {
      path: 'demo',
      data: { label: 'Branding demo', translationKey: 'common.demo', labelSource: null},
      children: [
        {
          path: '',
          component: BrandingDemoComponent,
        },
      ],
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BrandsRoutingModule { }