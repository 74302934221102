import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaStatisticCardComponent } from './media-statistic-card.component';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [MediaStatisticCardComponent],
  imports: [
    CommonModule,
    TranslocoModule,
  ],
  exports: [MediaStatisticCardComponent],
})
export class MediaStatisticCardModule {}
