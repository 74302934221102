import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthConstants, AuthService } from '../../auth'
import { filter, Subscription } from 'rxjs';
import { User } from '../../auth';

@Component({
  selector: 'user-account-details-update',
  templateUrl: './user-account-details-update.component.html',
  styleUrls: ['./user-account-details-update.component.scss']
})
export class UserAccountDetailsUpdateComponent implements OnInit, OnDestroy {

  @Input() user: User;
  @Input() loading: boolean;
  @Input() error: string; // the translationKey
  detailsForm : string; // 'email' or 'password'
  routeSubscription: Subscription;
  showWarningAlert: boolean = false;
  showPasswordSuccessAlert: boolean = false;
  showCheckInboxText: boolean; // Adds some extra text in the password success alert
  passwordSuccessTranslationKey: string; // 'authentication.password_changed'
  oldPasswordForgotten: boolean = false;
  changePasswordForm: FormGroup;
  changePasswordSubscription: Subscription;
  forgotPasswordSubscription: Subscription;
  authConstants: any;
  patternValid: { password: boolean; repeatPassword: boolean };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    ) {
      this.authConstants = AuthConstants;
      this.patternValid = { password: false, repeatPassword: false };
    }

    requestNewPasswordLink (){
      this.loading = true;
      this.showPasswordSuccessAlert = false;
      this.forgotPasswordSubscription = this.authService.forgotPassword(this.user.email).subscribe(
        () => {
          this.loading = false;
          this.doShowPasswordSuccessAlert('authentication.forgot_password_success_email',true);
        },
        errorMessage => {
          this.loading = false;
          this.error = errorMessage;
        }
      );
    };


    changeEmail (newEmail: string){
      this.loading = true;
      this.authService.changeEmail(newEmail).subscribe(
        () => {
          this.loading = false;
        },
        errorMessage => {
          this.loading = false;
          this.error = errorMessage == 'authentication.already_registered_log_in' ? 'authentication.already_registered' : errorMessage;
        });
    };

  doChangeEmail (newEmail:string){
    this.changeEmail(newEmail);
  }
  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('repeatPassword').value
      ? null
      : { mismatch: true };
  }
  passwordMatchValidatorAnotherOne(control: FormControl) {
    if (!this.changePasswordForm){return};
    return this.changePasswordForm.get('password').value === control.value
      ? null
      : { mismatch: true };
  }
  checkRegex(string: string, field) {
    if (field && !string) {
      this.patternValid[field] = false;
    }
    if (!field) {
      return;
    }
    this.patternValid[field] =
      this.authConstants.passwordRegexPattern.test(string);
  }

  doShowPasswordSuccessAlert (translationKey: string, showCheckInboxText){
    this.showPasswordSuccessAlert = true;
    this.showCheckInboxText = showCheckInboxText;
    this.passwordSuccessTranslationKey = translationKey; //'authentication.password_changed';
    setTimeout(()=>{
      this.showPasswordSuccessAlert = false;
    },10000);
  }

  changePassword() {
    this.loading = true;
    this.error = null;

    this.changePasswordSubscription = this.authService.changePassword(this.changePasswordForm.get('oldPassword').value,this.changePasswordForm.get('password').value).subscribe(
      () => {
        this.loading = false;
        this.changePasswordForm.reset();
        this.doShowPasswordSuccessAlert('authentication.password_changed',false);
      },
      errorMessage => {
        this.error = errorMessage;
      });
  }


  generateForm (){
    this.changePasswordForm = new FormGroup(
      {
        oldPassword: new FormControl(null, [Validators.required]),
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          Validators.pattern(this.authConstants.passwordRegexPattern)
        ]),
        repeatPassword: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          Validators.pattern(this.authConstants.passwordRegexPattern),
          this.passwordMatchValidatorAnotherOne.bind(this)
        ]),
      },
      this.passwordMatchValidator
    );
    this.changePasswordForm.controls.password.valueChanges.subscribe((value) => {
      this.checkRegex(value, 'password');
    });
    // this.changePasswordForm.controls.repeatPassword.valueChanges.subscribe(
    //   (value) => {
    //     this.checkRegex(value, 'repeatPassword');
    //   }
    // );
  }
  handleRouteChange(): void {

    const fullUrlParts = this.router.routerState.snapshot.url.toLowerCase().split('/').filter(s => s);

    this.detailsForm = fullUrlParts[1];
    if(this.detailsForm === 'password') {
      this.generateForm();
    }
  }

  ngOnInit(): void {
    this.routeSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.handleRouteChange();
      });
      this.handleRouteChange();
  }

  ngOnDestroy(): void {
    // Don't forget to unsubscribe to prevent memory leaks
    this.routeSubscription.unsubscribe();
  }

}
