

export class CcoDimension {
    
    constructor(
        public id: number,
        public name: string,
        public img_url: string,
    ){}

}