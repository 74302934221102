<!-- <p><span>{{'missing: '+missingConsentSlugs()}}</span> handling: {{handlingMissingConsentSlugs()}}</p> -->
<ng-template #consentRequestModal class="modal-lg">
  <div class="modal-header" *transloco="let t">
    <h4 class="modal-title pull-left" id="consent-request-modal">{{t('consent.agreement_needed')}}</h4>
    <span class="pull-right">
      <div class="row">
        <div class="col">
          <!-- TODO use <language-selector-type-one> instead of the duplicated code in <span class="nav-item languages dropdown">. Needs development: CSS position, flags -->
          <!-- <language-selector-type-one
            (onLanguageSelect)="setActiveLanguage($event)"
            [activeLanguageObject]="activeLanguageObject"
            [availableLanguages]="availableLanguages"
          ></language-selector-type-one> -->
          <span class="languages dropdown">
            <span dropdown>
              <a *ngIf="activeLanguageObject" class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                dropdownToggle (click)="false" [attr.aria-label]="activeLanguageObject.flag_class" aria-expanded="false"
                aria-controls="navbarDropdown-dropdown">
                <img [src]="getFlagUrl(activeLanguageObject,'w_24,c_fill,ar_1:1,r_max,f_auto/','.png')" [alt]="t(activeLanguageObject.name)" class="mr-1">
              </a>
              <ul id="navbarDropdown-dropdown" *dropdownMenu class="dropdown-menu" role="menu"
                aria-labelledby="navbarDropdown">
                <li *ngFor="let availableLanguage of availableLanguages">
                  <a class="dropdown-item" (click)="setActiveLanguage(availableLanguage.languageKey)">
                    <img [src]="getFlagUrl(availableLanguage,'w_16,c_fill,ar_1:1,r_max,f_auto/','.png')"
                      [alt]="t(availableLanguage.name)" class="mr-1">
                    {{t('language.'+availableLanguage.languageKey)}}
                  </a>
                </li>
              </ul>
            </span>
          </span>
        </div>
        <div class="col">
          <button type="button" class="btn-close close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
      </div>
    </span>
  </div>
  @if(currentConsent()){
    
    <div class="modal-body" *transloco="let t">
      <p class="lead my-3">{{currentConsent().question}}</p>
      <p class="my-3">{{currentConsent().description}}</p>
  
      <div class="d-flex align-items-center">
  
        @if(!currentConsent().given_at && !currentConsent().declined_at){
          <button class="btn btn-primary mx-1" (click)="updateConsentStatus(currentConsent(),'given')">{{t(currentConsent().verb_accept)}}</button>
          <button class="btn mx-1" [ngClass]="showingDeclineWarning() ? ' btn-danger':' btn-warning'" (click)="updateConsentStatus(currentConsent(),'declined')">{{showingDeclineWarning() ? t('common.confirm')+': ' : ''}}{{t(currentConsent().verb_reject)}}</button>
        }
        <!-- Users will not be given the opportunity to withdraw consent in this modal. They can do it in the account area. -->
        <!-- @if(currentConsent().withdrawal_allowed && currentConsent().given_at && !currentConsent().declined_at && !currentConsent().withdrawn_at){
          <button class="btn mx-1" [ngClass]="showingDeclineWarning() ? ' btn-danger':' btn-warning'" (click)="updateConsentStatus(currentConsent(),'withdrawn')">{{t(showingDeclineWarning() ? 'common.withdraw_confirm':'common.withdraw')}}</button>
        } -->
        <multisite-loader-globe-animation-detail
          *ngIf="loadingObject().consents"
          class="ml-2 d-block-inline" [width]="40" [height]="40">
        </multisite-loader-globe-animation-detail>
  
      </div>
  
  
      <h6 class="mt-4 d-flex justify-content-between align-items-center" (click)="showCurrentConsentSetMoreInfo.set(!showCurrentConsentSetMoreInfo())"
      style="cursor: pointer;" [attr.aria-expanded]="showCurrentConsentSetMoreInfo()" aria-controls="collapseBasic">
        <span><i class="fa-solid fa-circle-info mr-2"></i>{{t('common.more_info')}}</span>
        @if(showCurrentConsentSetMoreInfo()){
          <span><i class="fa-solid fa-chevron-up ml-1"></i></span>
        } @else {
          <span><i class="fa-solid fa-chevron-down ml-1"></i></span>
        }
      </h6>
      <div class="mt-3" [collapse]="!showCurrentConsentSetMoreInfo()" [isAnimated]="true" id="modalMoreInfo">
        <div class="my-3">
          <strong *transloco="let t; scope: 'account'">{{t('account.what_if_say_no')}}</strong>
          @if(showingDeclineWarning()){
            <alert [type]="'warning'">
              <i class="fa-solid fa-exclamation-triangle mr-2"></i>
              {{currentConsent().decline_info}}
            </alert>
          } @else {
            <p class="card-text mb-4 ">{{currentConsent().decline_info}}</p>
          }
        </div>
        <div class="embed-responsive" *ngIf="currentConsent().videos?.[0]">
          <frontend-video-player-type-two
            [video]="currentConsent().videos[0]"
            [classes]="'mb-3'"
          ></frontend-video-player-type-two>
        </div>
      </div> 
    </div>
  }
</ng-template>