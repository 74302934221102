import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressTrackingModule } from './progress/progress-tracking/progress-tracking.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ProgressTrackingModule
  ]
})
export class TrackingModule { }
