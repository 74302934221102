<!-- Small news -->
<div class="single-news mb-4">
  
  <!-- Grid row -->
  <div class="row">

    <!-- Grid column -->
    <div class="col-lg-4 pr-sm-0 pr-lg-2">

      <div class="view overlay rounded z-depth-1 mb-3">
        <a (click)="doLinkClick()">

          <frontend-media-image
            *ngIf="media"
            [classes]="'img-fluid'"
            [media]="media"
            [transformations]="imageTransformations ? imageTransformations : 'w_700/'"
            >
          </frontend-media-image>
          
          <img
          *ngIf="!media"
          [src]="imageUrl"
          class="img-fluid"
          alt="image related to the link name"
          />
          <span>
            <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
          </span>
        </a>
      </div>

    </div>

    <div class="col-lg-8">


      <h5 class="card-title">
        <a class="title-link" (click)="doLinkClick()">
          <span *ngIf="titleTextTranslation; else elseBlock1">
            <span *transloco="let t">
                <span> {{ t(titleTextTranslation) }} </span>
            </span>
          </span>
          <ng-template #elseBlock1>
            {{ titleText }}
          </ng-template>
  
        </a>

      </h5>

 

    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->
  <div class="row">
    <div class="col-12 pr-0 mr-0">
      <p class="grey-text">{{description}}</p>
        <!-- Feed footer -->
      <div class="mdb-feed">
        <div class="news">
          <div class="excerpt">
            <div class="feed-footer" (click)="doLinkClick()">
              <a class="like" *ngIf="likesCount">
                <!-- <mdb-icon fas icon="heart"></mdb-icon> -->
                <i class="fa-solid fa-heart"></i>
                <span *transloco="let t">
                  {{t(likesCount<6 ? 'SOCIAL.'+likesCount+'_LIKES' : 'SOCIAL.5_LIKES', {'number': likesCount})}}
                </span>
              </a>
              <button type="button" class="ml-3" mdbBtn color="primary" mdbWavesEffect *ngIf="!hideButton">
                  <span *ngIf="buttonTextTranslation; else elseBlock2">
                    <span *transloco="let t">
                        <span> {{ t(buttonTextTranslation) }} </span>
                    </span>
                  </span>
                  <ng-template #elseBlock2>
                    {{ buttonText }}
                  </ng-template>
          
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<!-- Small news -->