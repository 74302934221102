import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyListingComponent } from './survey-listing.component';
import { SurveyRoutingModule } from '../survey-routing.module';
import { ContentListFormatsDuplicateModule } from '../../duplicate/content-list-formats'; // This is a bad workaround for the ContentListFormatsModule import problem
import { LoadersModule } from '../../loaders';
// import { ContentListFormatsModule } from '../../content';



@NgModule({
  declarations: [
    SurveyListingComponent
  ],
  imports: [
    CommonModule,
    SurveyRoutingModule,
    ContentListFormatsDuplicateModule,
    // ContentListFormatsModule,// This causes an error in the browser. Cause unknown. The TypeScript (JS module) import does not cause an error.
    LoadersModule,

  ],
  exports: [
    SurveyListingComponent
  ]
})
export class SurveyListingModule { }
