import { Tag } from "@frontend/core";
import { UserExtraLite } from "../auth";
import { CloudinaryMediaAsset } from "../models";

export class Programme {

    constructor (
      public id: number,
      public slug: string,
      public name: string,
      public description: string,
      public start_at: string,
      public notes: string,
      public hub_id: number,
      public tags: Tag[],
      public trainers: UserExtraLite[],
      public media: CloudinaryMediaAsset[],
      public meta?: any,
      ){
        this.id = id;
        this.slug = slug;
        this.name = name;
        this.description = description;
        this.start_at = start_at;
        this.notes = notes;
        this.hub_id = hub_id;
        this.tags = tags;
        this.trainers = trainers;
        this.media = media;
        this.meta = meta;
    }
}