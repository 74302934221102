import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationModule } from '@frontend/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CardsModule } from '../content/cards/cards.module';
import { ContentItemListingWithSearchAndFilters } from './content-item-listing-with-search-filters/content-item-listing-with-search-filters.component';
import { LoadersModule } from '../loaders';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TranslocoModule } from '@jsverse/transloco';
import { CultureSelectorTypeOneModule } from '../cultures';



@NgModule({
  declarations: [
    ContentItemListingWithSearchAndFilters,

  ],
  imports: [
    CommonModule,
    PaginationModule,
    CollapseModule,
    CardsModule,
    LoadersModule,
    AlertModule,
    ReactiveFormsModule,
    TranslocoModule,
    CultureSelectorTypeOneModule,
  ],
  exports: [
    ContentItemListingWithSearchAndFilters,
  ]
})
export class SearchModule { }
