import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerTypeOneComponent } from './video-player-type-one/video-player-type-one.component';
import { VideoPlayerTypeTwoComponent } from './video-player-type-two/video-player-type-two.component';



@NgModule({
  declarations: [
    VideoPlayerTypeOneComponent,
    VideoPlayerTypeTwoComponent,
  ],
  imports: [
    CommonModule
  ],
  exports : [
    VideoPlayerTypeOneComponent,
    VideoPlayerTypeTwoComponent,
  ]
})
export class VideoPlayersModule { }
