import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyResponseListComponent } from './survey-response-list/survey-response-list.component';
import { RouterModule } from '@angular/router';
import { SurveyStartComponent } from './survey-start/survey-start.component';
import { SurveyQuestionResponseComponent } from './survey-question-response/survey-question-response.component';
import { SurveyResponseRoutingModule } from './survey-response-routing.module';



@NgModule({
  declarations: [
    SurveyResponseListComponent,
    SurveyStartComponent,
    SurveyQuestionResponseComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SurveyResponseRoutingModule,

  ],
  exports: [
    SurveyResponseListComponent,
    SurveyStartComponent,
    SurveyQuestionResponseComponent
  ]
})
export class SurveyResponseModule { }
