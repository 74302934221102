<!-- Dark/active/selectable classes should be dynamic -->
<span class="icon-pill-{{shape}} classic" [ngClass]="{active: active, selectable: selectable, inverted : inverted, compact: compact}" *transloco="let t">
  <span class="icon" *ngIf="iconClasses"><i [class]="iconClasses"></i></span>
  <span>{{labelTranslationKey ?  t(labelTranslationKey) : labelText}}</span>
</span>

<!-- icons withouth text -->
<!-- <span class="icon-pill-square dark selectable p-0" *transloco="let t">
  <span class="icon" *ngIf="iconClasses"><i [class]="iconClasses"></i></span>
  <span class="d-none">{{labelTranslationKey ?  t(labelTranslationKey) : labelText}}</span>
</span> -->