
              <div class="card large-horizontal-card">
                <div class="row">
                    <div [class]="'col-'+breakpoint+'-4'">
                        <a [routerLink]="[content.slug]">
                          <div class="img-holder">
                            <div class="video-content" *ngIf="content.video">
                                <i class="fa-solid fa-circle-play"></i>
                                <p *transloco="let t">{{t('common.video')}}</p>
                            </div>
                            <img class="img-fluid main-img" *ngIf="videoThumbnailUrl" [src]="videoThumbnailUrl" [alt]="'Video thumbnail'">
                            <img class="img-fluid main-img" *ngIf="content.media && !videoThumbnailUrl" [src]="content.media.asset_url && thumbnailTransformations ? content.media.base_url + thumbnailTransformations + content.media.asset_url : content.media.file_url" [alt]="content.media.description">
                            <div class="rating" *ngIf="content.rating">
                                <pre class="rate-value"><b>{{content.rating}}</b></pre>
                                <rating [(ngModel)]="content.rating" [max]="ratingMax" [readonly]="true"></rating>
                            </div>
                        </div>
                        </a>
                    </div>
                    <div [class]="'col-'+breakpoint+'-8'">
                        <div class="text-content">
                            <a class="title" [routerLink]="[content.slug]">{{content.title}}
                            </a>
                            <div class="badges">
                                <span *ngFor="let tag of content.tags" [ngClass]=" [tag.type == 'topic' ? 'badge-info' : 'badge-primary', 'badge badge-pill']">{{tag.name}}</span>
                                <!-- <span class="badge badge-pill badge-more" (click)="action()">
                                    <i class="fa-solid fa-angle-right"></i>
                                </span> -->
                            </div>
                            <p class="text">{{content.description}}</p>
                            <div class="action">
                                <button (click)="gotoContent()">
                                    <p *transloco="let t">{{content.video ? t('common.watch') : t('common.read')}}</p>
                                    <i class="fa-solid fa-angle-right"></i>
                                </button>
                                <p class="reading-time" *ngIf="content.video && content.duration">
                                    <span class="mx-1">{{content.duration}}</span><span *transloco="let t">{{t('common.minutes_abbreviated')}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>