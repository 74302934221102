import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CultureSelectorTypeTwoComponent } from './culture-selector-type-two.component';
// import { MdbVectorMapModule } from 'mdb-angular-vector-maps';
import { AvatarsModule, PillsModule } from '@frontend/shared';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';


@NgModule({
  declarations: [
    CultureSelectorTypeTwoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PillsModule,
    AvatarsModule,
    TranslocoModule,
    TypeaheadModule,
  ],
  exports: [
    CultureSelectorTypeTwoComponent,
  ]
})
export class CultureSelectorTypeTwoModule { }
