  import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
  import { ActivatedRoute, Params, Router } from '@angular/router';
  import { AuthService, Contributor, Experience, LanguageService, LickertQuestion, MediaService, MetaText, Snapshot, SnapshotHomeComponent, SnapshotService, User, WindowService } from '@frontend/common';
  import { ActivityLogService, Link } from '@frontend/core';
  import { Subscription } from 'rxjs';
  import { PageTitleService } from '../../navigation/title/title.service';
  import { DataProcessingService } from '../../utilities/data-processing.service';
  import { Survey } from '../survey.model';
  import { SurveysService } from '../surveys.service';
  import { Progressable } from '../../tracking';
  import { TrackingService } from '../../tracking/tracking.service';
  import { Guest } from '../../auth/user/guest.model';
  import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
  import { TranslocoService } from '@jsverse/transloco';
  import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
  
  @Component({
    selector: 'multisite-survey-instructions',
    templateUrl: './survey-instructions.component.html',
    styleUrls: ['./survey-instructions.component.scss']
  })
  export class SurveyInstructionsComponent implements OnInit {
  
    loading: boolean;
    survey : Survey;
    starting: boolean; // submitting a 'started' experience to the backend
    contributors: Contributor[];
    userSubscription: Subscription;
    guestSub: Subscription; // existing from storage
    makingGuestSub: Subscription; // new from backend
    surveySub: Subscription;
    loadingLinks: boolean;
    progressablesSubscription: Subscription;
    surveyProgressValue: number;
    user : User = null;
    guest : Guest = null;
    prefersToContinueWithoutLoggingIn: boolean;
    ratingMax: number;
    error = null;
    nextQuestion: any; // could be any type of question
    started: boolean; // has the current user started the survey already
    loadingRelatedExperiences: boolean;
    loadedRelatedExperiences: boolean; // to avoid requesting these twice
    thumbnailTransformations: string = 'ar_16:9,w_700/';
    activeLanguageSubscription: Subscription;
    snapshotSubscription: Subscription;
    snapshot: Snapshot;
    exampleScaleQuestion : LickertQuestion;
    examplePersonComparisonQuestion : LickertQuestion;
    translocoSubscription1 : Subscription;
    translocoSubscription2 : Subscription;
    @ViewChild('videoModal') videoModal;
    modalRef?: BsModalRef;
    videoLink: Link;
  
    constructor(
      private surveysService : SurveysService,
      private route : ActivatedRoute,
      private router : Router,
      private authService : AuthService,
      private activityLogService : ActivityLogService,
      private pageTitleService : PageTitleService,
      private breadcrumbService : BreadcrumbService,
      private dataProcessingService : DataProcessingService,
      private trackingService: TrackingService,
      private snapshotService : SnapshotService,
      private languageService: LanguageService,
      private translocoService: TranslocoService,
      private modalService: BsModalService,
      private windowService: WindowService,

    ) {}
  
    filterInstructions (instructions : MetaText[], type : string){
      return instructions?.length ? instructions.filter(i => i.type === type) : [];
    }
  
    videoLinkClicked(){
      this.openModal(this.videoModal);
    }

    closeModal(){
      if (this.modalRef){
        this.modalRef.hide();
      }
    }
    openModal(template: TemplateRef<any>) {
      this.modalRef = this.modalService.show(template);
    }
    getRelatedExperiences (){
      if (!this.user && !this.guest){
        // do something or nothing
      } else if (this.survey && !this.loadedRelatedExperiences && !this.loadingRelatedExperiences) {
        this.loadingRelatedExperiences = true;
        this.surveysService.getRelatedExperiences (this.survey.slug,+this.route.snapshot.params['snapshot'])
          .subscribe(
            (response : Experience[]) => {
              this.loadingRelatedExperiences = false;
              this.loadedRelatedExperiences = true;
              this.started = this.surveysService.getBooleanStatusOfSurvey(response,this.survey.id,'started');
            },
            error => {
              this.loadingRelatedExperiences = false;
            }
          );
      }
    }
    getSnapshot(id:number, freshFromServer: boolean){
      this.snapshotSubscription = this.snapshotService.getSnapshot(id,false).subscribe((snapshot)=>{
        this.snapshot = snapshot;
        this.redirectIfSnapshotNotAccepted(snapshot);
        this.breadcrumbService.setBreadcrumbFragment({urlFragment:'snapshot',fragmentName:snapshot.title});
      })
    }
    redirectIfSnapshotNotAccepted(snapshot: Snapshot){
      if (this.snapshot && !this.snapshot.current_accepted){
        this.router.navigate(['/snapshots/'+snapshot.id], { relativeTo: this.route });
      }
    }
  
    getSurvey (slug:string, freshFromServer: boolean){
      this.loading = true;
      this.error = null;
      slug = slug ? slug : this.route.snapshot.params['survey'];
      let activityLogParams = {
        'model':'survey',
        'identifier' : slug,
        'identifier_type' : 'slug',
        'activity_type' : 'viewed',
        'properties' : null
      };
      this.surveysService.getSurvey (slug,freshFromServer)
        .subscribe(
          response => {
            if (response){
              this.survey = response;
              this.activityLogService.log(activityLogParams);
              // this.pageTitleService.setTitle(this.survey.name);
              this.breadcrumbService.setBreadcrumbFragment({urlFragment:'survey',fragmentName:this.survey.name});
              if (this.user || this.guest){
                this.getRelatedExperiences();
              }
            }
            this.loading = false;
          },
          error => {
            this.error = error;  
            this.loading = false;
          }
        );
    };
    findCurrentSurveyProgressFromProgressables (progressables : Progressable[]){
      if (!progressables?.length || !this.survey){
        return null;
      }
      return this.trackingService.getProgressFromProgressables(progressables,'survey',this.survey.slug);
    }
    getSurveyProgress (){
      this.progressablesSubscription = this.trackingService.progressables.subscribe( progressables => {
        this.surveyProgressValue = this.findCurrentSurveyProgressFromProgressables(progressables);
      })
    }
    goToQuestions (){
      if (this.surveyProgressValue > 99){
        this.router.navigate(['../feedback'], { relativeTo: this.route });
      } else {
        this.router.navigate(['../questions'], { relativeTo: this.route });
      }
    }
    hasRole (roleName:string){
      return this.authService.checkRole(roleName); // for example "Admin"
    }
    goToLogin(){
      this.authService.setRouteForRedirectAfterAuth([window.location.pathname]); // TODO - get route here dynamically instead this hard-coded "quiz"
      this.router.navigate(['/login']);
    }
    startSurvey (){
      if (this.user || this.guest){
        if (this.started){
          this.goToQuestions();
        } else {
          this.starting = true;
          this.trackingService.submitExperience('survey',this.survey.id,this.survey.slug,1,'started',null).subscribe(response=>{
            this.starting = false;
            this.goToQuestions();
          }, error => {
            this.starting = false;
          })
        };
      } else {
        alert('You must log in or continue as a visitor');
      }
    }
  
    ngOnInit(): void {
      this.windowService.goToTop();
      this.userSubscription = this.authService.user.subscribe(user => {
        this.user = user;
        this.getRelatedExperiences();
      });
      this.guestSub = this.authService.guest.subscribe(guest => {
        this.guest = guest;
        this.getRelatedExperiences();
      });
      this.route.params.subscribe(
        (params : Params)=>{
          this.getSurvey (params['survey'], false);
        }
      );
      this.route.params.subscribe(
        (params : Params)=>{
          if (params['snapshot']){
            this.getSnapshot(+params['snapshot'],false);
          }
      });
      this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( () => {
        if (this.survey){
          this.getSurvey(this.survey.slug, true);
        }
      })
      this.getSurveyProgress ();
      this.translocoSubscription1 = this.translocoService.selectTranslate('survey.scale_advice_example_question').subscribe(translation => {this.exampleScaleQuestion = new LickertQuestion(null,translation,null,null,null,null,null,true,true,{type:'bell_curve'},null,['strongly_agree','agree','disagree','strongly_disagree'],null);});
      this.translocoSubscription2 = this.translocoService.selectTranslate('survey.person_comparison_advice_example_question').subscribe(translation => {this.examplePersonComparisonQuestion = new LickertQuestion(null,translation,null,null,null,null,null,true,true,{type:'icon_array'},null,['less_than_almost_everyone','less_than_most','less_than_average_person','about_average_person','more_than_average_person','more_than_most','more_than_almost_everyone'],null);});
      this.videoLink = {
        id: 0,
        name: "No name",
        label: "Quick intro in 2 minutes",
        url: 'https://www.cultureconnector.com',
        category:null,
        type:null,
        cta_label: "Watch",
        cta_type: "resources",
        description:"Get tips on doing a successful questionnaire",
        media: {
          "id": 156,
          "medially_type": "App\\Models\\Link",
          "medially_id": 244,
          "file_url": "https://res.cloudinary.com/cebt/image/upload/v1671029175/resources/podcastlogo_yagluo.png",
          "file_name": "resources/podcastlogo_yagluo.png",
          "file_type": "image",
          "size": 22870,
          "uploader_id": 1,
          "description": "Podcast logo: 21st Century Work Life",
          "credits": "Pilar Orti",
          "permission": null,
          "category": "intercultural",
          "base_url": "https://res.cloudinary.com/cebt/image/upload/",
          "transformations_url": "c_thumb,w_200,g_face/",
          "asset_url": "v1611931415/persons/smiling-businessperson-globalintegration-flickr-cc2-5347195359_8b3a32e4ab_o_y5tcks.jpg",
          "type": "concepts",
          "created_at": new Date("2022-12-14T13:00:03.000000Z"),
          "updated_at": new Date("2022-12-14T13:00:03.000000Z")
        },
        icon:{identifier_1:'fa-video',identifier_2:null}
      };
    }
    ngOnDestroy () {
      if (this.guestSub){
        this.guestSub.unsubscribe();
      };
      if (this.makingGuestSub){
        this.makingGuestSub.unsubscribe();
      };
      if (this.userSubscription){
        this.userSubscription.unsubscribe();
      }
      if (this.surveySub){
        this.surveySub.unsubscribe();
      }
      if (this.progressablesSubscription){
        this.progressablesSubscription.unsubscribe();
      }
      if (this.activeLanguageSubscription){
        this.activeLanguageSubscription.unsubscribe();
      }
      if (this.snapshotSubscription){
        this.snapshotSubscription.unsubscribe();
      }
      if (this.translocoSubscription1){
        this.translocoSubscription1.unsubscribe();
      }
      if (this.translocoSubscription2){
        this.translocoSubscription2.unsubscribe();
      }
    }
  
  }
  