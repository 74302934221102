<div>
  <svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  style="margin: auto; background: transparent; display: block; shape-rendering: auto;"
  [attr.width]="width+'px'"
  [attr.height]="height+'px'"
  viewBox="0 0 100 100"
  preserveAspectRatio="xMidYMid">
<defs>
  <clipPath id="ldio-uvuhe8v37nc-cp">
    <circle cx="50" cy="50" r="35"></circle>
  </clipPath>
</defs>
<circle cx="50" cy="50" r="35" class="secondary"></circle>
<g clip-path="url(#ldio-uvuhe8v37nc-cp)">
  <path
    transform="scale(2) translate(-25 -25)"
    class="primary"
    [attr.d]="d">
    <animateTransform
      attributeName="transform"
      type="translate"
      repeatCount="indefinite"
      dur="3s"
      keyTimes="0;1"
      values="-100 0;0 0">
    </animateTransform>
  </path>
  <path
  transform="scale(2)"
  class="primary"
  [attr.d]="d"
  >
    <animateTransform
    attributeName="transform"
    type="translate"
    repeatCount="indefinite"
    dur="3s"
    keyTimes="0;1"
    values="0 0;100 0">
  </animateTransform>
  </path>
</g>
<!-- [ldio] generated by https://loading.io/ -->
</svg>
</div>