
<div class="container">
  <div class="row mt-4">
    <div class="col-md-4 offset-md-4">
   
      <label for="customRange1" class="form-label">This is where you'll give your response</label>
      <input type="range" class="form-range" id="customRange1">


    </div>
  </div>
</div>