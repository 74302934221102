import { Component, OnInit, Input } from '@angular/core';
import { Media } from '@frontend/core';


@Component({
  selector: 'frontend-content-list-item-simple-type-one',
  templateUrl: './content-list-item-simple-type-one.component.html',
  styleUrls: ['./content-list-item-simple-type-one.component.scss']
})
export class ContentListItemSimpleTypeOneComponent implements OnInit {
  @Input() media : Media;
  @Input() imageTransformations : string;
  @Input() titleText : string;
  @Input() titleTextTranslation : string;
  @Input() linkTextTranslation : string;
  @Input() linkText : string;
  @Input() truncateLinkText : boolean;
  @Input() hideBottomBorder : boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
