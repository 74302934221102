import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { SavedPendingChanges, UnsavedPendingChanges } from '../brand.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MediaService } from '../../content';

@Component({
  selector: 'multisite-brand-logos',
  templateUrl: './brand-logos.component.html',
  styleUrls: ['./brand-logos.component.scss']
})
export class BrandLogosComponent implements OnInit, OnChanges {

  @Output() formDataChanged = new EventEmitter<FormData>();
  @Output() removePendingItem = new EventEmitter<string>();
  @Output() markSavedLogoForDeletion = new EventEmitter<{logo_type:string,removeSaved:boolean}>();
  @Input() disabled : boolean;
  @Input() pending : SavedPendingChanges;
  @Input() loading : boolean; // TODO - use this to disable stuff
  @Input() logo_banner : string;
  @Input() logo_square : string;
  @Input() logo_banner_inverse : string;
  @Input() logo_square_inverse : string;
  @Input() pending_updates : UnsavedPendingChanges;
  @Input() error : string;
  logoExampleImages: {[key:string]:{asset_url:string}};
  logoBlankExampleImages: {[key:string]:{asset_url:string}};
  logoExampleImageSelected: string = 'banner'; // 'banner_inverse'
  logoExampleImageBaseUrl: string;
  collapsedContent : {[key:string]:{collapsed: boolean}};
  formToResetOnCompletion : string; // the logo type, e.g. 'banner'
  formResetTriggerOnChange : {[key:string]:{counter: number}}; // the logo type, e.g. {'banner': 14, 'square':2}

  // TODO: how can we restore these?

  // fileUploadError: string;
  // fileUploadSuccess: string;
  // uploadProgress: number; // should be an object with a property for each form

  constructor(
  ) {
    this.logoExampleImageBaseUrl = 'https://res.cloudinary.com/cebt/image/upload/';
    this.logoExampleImages = {
      banner_transparency: {asset_url: 'v1688069489/UI/branding-logo-example-transparency_muttx2.jpg'},
      banner_margin: {asset_url: 'v1688069489/UI/branding-logo-example-margin_ltsopi.jpg'},
      banner_inverse_transparency: {asset_url: 'v1688131886/UI/branding-logo-inverse-example-transparency_lybno7.jpg'},
      banner_inverse_margin: {asset_url: 'v1688131869/UI/branding-logo-inverse-example-margin_kjebzf.jpg'},
      square_transparency: {asset_url: 'v1688143467/UI/branding-logo-square-example-transparency_c5upjt.jpg'},
      square_margin: {asset_url: 'v1688143467/UI/branding-logo-square-example-margin_zdkth3.jpg'},
      square_inverse_transparency: {asset_url: 'v1688145655/UI/branding-logo-square-inverse-example-transparency_upjccw.jpg'},
      square_inverse_margin: {asset_url: 'v1688145655/UI/branding-logo-square-inverse-example-margin_wp7ccp.jpg'},
      /* NOT IN USE
      banner: {asset_url: 'v1687638170/UI/branding-logo-examples-banner_il29v4.jpg'},
      square: {asset_url: 'v1687638170/UI/branding-logo-examples-square_whmyri.jpg'},
      banner_inverse: {asset_url: 'UI/branding-logo-examples-banner-darkmode_gctska.jpg'},
      square_inverse: {asset_url: 'v1687638170/UI/branding-logo-examples-square-darkmode_ymxlqk.jpg'},
      */
    }
    this.logoBlankExampleImages = {
      banner: {asset_url: 'v1689603629/UI/branding-logo-blank-light-mode-desktop.png'},
      square: {asset_url: 'v1689603430/UI/branding-logo-blank-light-mode-mobile.png'},
      banner_inverse: {asset_url: 'v1689603669/UI/branding-logo-blank-dark-mode-desktop.png'},
      square_inverse: {asset_url: 'v1689603560/UI/branding-logo-blank-dark-mode-mobile.png'},
    }
    this.collapsedContent = {
      banner:{collapsed:true},
      square:{collapsed:true},
      banner_inverse:{collapsed:true},
      square_inverse:{collapsed:true}
    }
    this.formResetTriggerOnChange = {
      logo_banner:{counter:0},
      logo_square:{counter:0},
      logo_banner_inverse:{counter:0},
      logo_square_inverse:{counter:0}
    }
  }

  toggleCollapsedContent (contentName){
    for (let content in this.collapsedContent){
      if (contentName === content){
        this.collapsedContent[content].collapsed = !this.collapsedContent[content].collapsed;
      } else {
        this.collapsedContent[content].collapsed = true;
      }
    }
  }
  allContentIsCollapsed (){
    let somethingIsexpanded : boolean = false;
    for (let content in this.collapsedContent){
      if (!this.collapsedContent[content].collapsed){
        somethingIsexpanded = true;
      }
    }
    return !somethingIsexpanded;
  }

  toggleMarkSavedLogoForDeletion(logo_type:string){
    if (this.pending_updates?.[logo_type]?.removeSaved){
      this.markSavedLogoForDeletion.emit({logo_type:logo_type,removeSaved:false})
    } else {
      this.markSavedLogoForDeletion.emit({logo_type:logo_type,removeSaved:true})
    }
  }

  onFormDataChanged (formData : FormData, logo_type: string){ // logo_type : 'logo_banner', 'logo_banner_inverse', 'logo_square', 'logo_square_inverse' 
    formData.append('logo_type',logo_type);
    this.formToResetOnCompletion = logo_type;
    this.formDataChanged.emit(formData)
  }
  onRemovePendingItem (itemKey: string){  // itemKeys: 'banner','square','banner_inverse','square_inverse'
    this.removePendingItem.emit(itemKey)
  }
  cancelUploadFile (bool : boolean){
    // TODO - restore the fileuploadProgress / cancel functionalities
    // this.fileUploadSuccess = null;
    // if (this.fileUploadSubscription){
    //   this.fileUploadSubscription.unsubscribe();
    // }
    // for (let category in this.uploadProgress){
    //   for (let type in this.uploadProgress[category]){
    //     this.uploadProgress[category][type] = null;
    //   }
    // }
  }
  ngOnChanges(changesObject): void {
    if (changesObject.loading && changesObject.loading.currentValue === false && this.formToResetOnCompletion){
      this.formResetTriggerOnChange[this.formToResetOnCompletion].counter++;
      this.formToResetOnCompletion = null;
    }
    // REMOVE THIS AFTER TESTING
    // if (changesObject.disabled && changesObject.disabled.currentValue === false){
      // let openLogo = null;
      // for (let logo in this.collapsedContent){
      //   if (!this.collapsedContent[logo].collapsed){
      //     openLogo = this.collapsedContent[logo];
      //   }
      // }
      // if (openLogo){
      //   openLogo.collapsed = false;
      // } else {
      //   this.collapsedContent.banner.collapsed = false;
      // }
    // }
  }
  ngOnInit(): void {
    this.error = null;
  }
}
