<form [formGroup]="multilingualForm" role="form" class="d-flex flex-column w-100" *transloco="let t">

  <div formArrayName="languages" *ngIf="multilingualForm?.get('languages')">
    <ng-container *ngFor="let control of multilingualForm.get('languages')['controls']; let i = index"
      [formGroupName]="i">

      <div class="input-self">

        <label for="{{getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')}}"
          class="form-label" *ngIf="labelTextTranslationKey || labelText">{{labelTextTranslationKey ?
          t(labelTextTranslationKey) : labelText}}
        </label>

        <div class="input-group textarea-control mb-3" [ngClass]="{
          'ng-invalid':
            multilingualForm.get('languages')['controls'][i]['controls'][getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')]?.invalid &&
            (multilingualForm.get('languages')['controls'][i]['controls'][getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')]?.touched || multilingualForm.get('languages')['controls'][i]['controls'][getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')]?.dirty),
          'ng-valid':
            multilingualForm.get('languages')['controls'][i]['controls'][getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')]?.valid &&
            multilingualForm.get('languages')['controls'][i]['controls'][getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')]?.touched &&
            multilingualForm.get('languages')['controls'][i]['controls'][getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')]?.dirty
        }">

          <span class="input-group-text">
            {{getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'name')}}
          </span>

          <input *ngIf="!rows" class="form-control" type="text" [disabled]="loading"
            [formControlName]="getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')"
            [id]="getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')"
            [placeholder]="placeholderTranslationKey ? t(placeholderTranslationKey) : placeholderText"
            (change)="updateValidity()" />

          <textarea *ngIf="rows" class="form-control" [rows]="rows" [disabled]="loading"
            [formControlName]="getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')"
            [id]="getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')"
            [placeholder]="placeholderTranslationKey ? t(placeholderTranslationKey) : placeholderText"
            (change)="updateValidity()">
          </textarea>

          <div *ngIf="
            multilingualForm.get('languages')['controls'][i]['controls'][getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')]?.errors?.maxlength && multilingualForm.get('languages')['controls'][i]['controls'][getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')]?.touched
          " class="invalid-feedback">
            {{ t('common.too_long') }}
          </div>

          <div *ngIf="
            multilingualForm.get('languages')['controls'][i]['controls'][getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')]?.errors?.minlength && multilingualForm.get('languages')['controls'][i]['controls'][getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')]?.touched
          " class="invalid-feedback">
            {{ t('common.too_short') }}
          </div>

          <div *ngIf="
            multilingualForm.get('languages')['controls'][i]['controls'][getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')]?.errors?.required &&
            multilingualForm.get('languages')['controls'][i]['controls'][getControlLanguageProperty(multilingualForm.get('languages')['controls'][i]['controls'],'iso')]?.dirty
          " class="invalid-feedback">
            {{ t('common.required') }}
          </div>

          <div *ngIf="errorTranslationKey" class="text-danger">
            {{ t(errorTranslationKey) }}
          </div>

        </div>

      </div>

    </ng-container>

  </div>

  <button [disabled]="!multilingualForm.valid || loading" (click)="submit()" class="btn btn-primary align-self-end" type="button"
    id="button-addon2">
    <multisite-loader-globe-animation-detail *ngIf="loading" [width]="24" [height]="24">
    </multisite-loader-globe-animation-detail>
    {{t('common.submit')}}
  </button>

</form>