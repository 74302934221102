<!-- <p>Value: {{selectedValue}} icon: {{currentIconValue}}</p> -->
<div class="icon-container d-none d-lg-flex">
  <span *ngFor="let icon of iconData"
    [ngClass]="{
      'selected': (icon.value - step) < (selectedValue -(step/2)) && (icon.value) > (selectedValue -step),
      'semi-selected': icon.value > (selectedValue-(step*2)) && icon.value < (selectedValue+(step*1.5)),
      'active' : sliderActive
    }">
    <button class="btn btn-link" (mouseenter)="currentIconValue = icon.value" (click)="doSetValue(icon.value)"><i [class]="icon.classes"></i></button>
    <!-- 
            'semi-selected': ((selectedValue -(step)) > icon.value) && selectedValue > (icon.value+step) && icon.value > selectedValue-(step*3),
      'semi-selected-blue': (selectedValue > (icon.value -(step*2)) && selectedValue <= (icon.value-step)),
     -->
  </span>
</div>
