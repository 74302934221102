<div class="row">
  <div class="col">
    <div *transloco="let t">
      <form [formGroup]="slugForm" role="form" class="mt-4" *ngIf="slugForm">
        <div class="input-self mb-3">
          <label for="slug" class="form-label"
            >{{ t('content_management.web_address')
            }}<span class="required-sign">*</span>
          </label>
          <div
            class="input-group"
            [ngClass]="{
              'ng-invalid':
                slugForm.get('slug').invalid && slugForm.get('slug').touched,
              'ng-valid':
                slugForm.get('slug').valid &&
                slugForm.get('slug').touched &&
                slugForm.get('slug').dirty
            }"
          >
            <span class="input-group-text text-primary-light">
              <i class="fa fa-info" aria-hidden="true"></i>
            </span>
            <input
              class="form-control"
              type="text"
              formControlName="slug"
              id="slug"
              placeholder=""
            />
            <div
              *ngIf="
                slugForm.get('slug').errors?.required &&
                slugForm.get('slug').touched
              "
              class="invalid-feedback"
            >
              {{ t('common.required') }}
            </div>
            <div
              *ngIf="
                slugForm.get('slug').errors?.minlength &&
                slugForm.get('slug').touched
              "
              class="invalid-feedback"
            >
              {{ t('common.too_short') }}
            </div>
            <div
              *ngIf="
                slugForm.get('slug').errors?.maxlength &&
                slugForm.get('slug').touched
              "
              class="invalid-feedback"
            >
              {{ t('common.too_long') }}
            </div>
            <div
              *ngIf="
                slugForm.get('slug').errors?.pattern &&
                slugForm.get('slug').touched
              "
              class="invalid-feedback"
            >
              {{ t('content_management.slug_validation_error') }}
            </div>
          </div>
          <div class="form-text">
            {{ t('branding.web_address_help') }}
          </div>
        </div>
        <p class="my-3">
          <a class="btn-link mr-3" (click)="toggleCollapsedContent('rules')"
            >{{ t('common.rules') }}&nbsp;|&nbsp;{{
              collapsedContent.rules
                ? t('common.show')
                : t('common.hide')
            }}</a
          >
          <a class="btn-link" (click)="toggleCollapsedContent('help')"
            >{{ t('common.more_info') }}&nbsp;|&nbsp;{{
              collapsedContent.help
                ? t('common.show')
                : t('common.hide')
            }}</a
          >
        </p>
        <div class="mt-2" [collapse]="collapsedContent.rules">
          <div class="well well-lg card card-block card-header">
            <h4>{{ t('branding.name_rules') }}</h4>
            <ul>
              <li *ngFor="let rule of slugRulesEmptyIterator; let i = index">
                {{ t('branding.slug_rule_' + i) }}
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-2" [collapse]="collapsedContent.help">
          <div class="well well-lg card card-block card-header">
            <h4>{{ t('branding.name_guide') }}</h4>
            <ul>
              <li *ngFor="let help of slugHelpEmptyIterator; let i = index">
                {{ t('branding.slug_help_' + i) }}
              </li>
            </ul>
          </div>
        </div>
        <div class="input-self my-4">
          <label for="name" class="form-label"
            >{{ t('common.name') }}<span class="required-sign">*</span>
          </label>
          <div
            class="input-group"
            [ngClass]="{
              'ng-invalid':
                slugForm.get('name').invalid && slugForm.get('name').touched,
              'ng-valid':
                slugForm.get('name').valid &&
                slugForm.get('name').touched &&
                slugForm.get('name').dirty
            }"
          >
            <span class="input-group-text text-primary-light">
              <i class="fa fa-info" aria-hidden="true"></i>
            </span>
            <input
              class="form-control"
              type="text"
              formControlName="name"
              id="name"
              placeholder="{{ 'branding.name' | transloco }}"
            />
            <div
              *ngIf="
                slugForm.get('name').errors?.required &&
                slugForm.get('name').touched
              "
              class="invalid-feedback"
            >
              {{ t('common.required') }}
            </div>
          </div>
          <div class="form-text">
            {{ t('content_management.not_public') }}
          </div>
        </div>
      </form>
      <alert class="my-3" [type]="'warning'" [dismissible]="true" *ngIf="error"
        ><span *transloco="let t">{{ t(error) }}</span></alert
      >

      <button
        class="btn btn-warning m-3"
        (click)="doCancelNewBrandMode()"
        [disabled]="loading"
      >
        {{ t('common.cancel') }}
      </button>
      <button
        class="btn btn-primary m-3"
        (click)="doSave()"
        [disabled]="!slugForm?.valid || loading"
      >
        {{ t('common.save') }}
      </button>
    </div>
  </div>
</div>
