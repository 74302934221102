<div class="footer">
<!-- FOR NEW FOOTER STYLE CLASS changed: container-fluid to container -->
  <div class="container px-5">
      <div class="row" *transloco="let t">
          <div class="col-lg-9">
              <div class="row">
                  <div class="col-lg-4">
                      <!-- <img class="logo" src="https://cms-cdn.placeholder.co/Logo_f6bbd8b915.svg?width=384"
                          alt="CultureTTT"> -->
                          <multisite-website-logos class="logo" [logo]="logo" [lightColourOn]="darkModeOn"></multisite-website-logos>
                      <p class="mt-3 mb-4">
                          {{summaryTranslationKey ? t(summaryTranslationKey) : summaryText}}
                      </p>
                  </div>
                  <div class="col-lg-8">
                      <div class="row">
                          <div class="col-lg-4 mb-md-0 mb-4">
                              <h2 class="footer-heading" *transloco="let t">{{t('common.about')}}</h2>
                              <ul class="list-unstyled">
                                  <li><a href="https://www.argonautonline.com/about-us/story/" class="d-block">{{t('content.our_story')}}</a></li>
                                  <li><a href="https://cebt.fi/intercultural" class="d-block">{{t('content.goals')}}</a></li>
                                  <li><a href="https://www.argonautonline.com/about-us/cultural-correspondents/join" class="d-block">Join</a></li>
                                  <li><a href="https://www.argonautonline.com/contact/" class="d-block">{{t('common.contact')}}</a></li>
                              </ul>
                          </div>
                          <div class="col-lg-4 mb-md-0 mb-4">
                              <h2 class="footer-heading">{{t('website_interface.related_websites')}}</h2>
                              <ul class="list-unstyled">
                                  <li><a href="https://cutureconnector.com" class="d-block">CultureConnector</a></li>
                                  <li><a href="https://argonautonline.com" class="d-block">Argonaut</a></li>
                                  <li><a href="https://interculturaltoolbox.org" class="d-block">IC Toolbox</a></li>
                                  <li><a href="https://cebt.fi/" class="d-block">CEBT</a></li>
                              </ul>
                          </div>
                          <div class="col-lg-4 mb-md-0 mb-4">
                              <h2 class="footer-heading">{{t('common.more')}}</h2>
                              <ul class="list-unstyled">
                                  <li><a href="https://www.cultureconnector.com/#/shop/products/one-to-one-coaching-silver" class="d-block">{{t('navigation.shop')}}</a></li>
                                  <li><a href="https://www.argonautonline.com/trainers/accreditation" class="d-block">{{t('navigation.accreditation')}}</a></li>
                                  <li><a href="https://cebt.fi/privacy" class="d-block">{{t('navigation.privacy')}}</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-3 mb-md-0">
              <h2 class="footer-heading">{{t('website_interface.personalise')}}</h2>
              <div class="theme-settings">
                  <p>{{t('website_interface.switch_dark_light_mode')}}</p>

                  <form [formGroup]="darkModeToggleForm">
                    <div class="form-check" aria-label="Toggle dark and light modes">
                        <input class="form-check-input" type="radio"
                        [value]="false"
                        formControlName="mode"
                        id="light">
                        <label class="form-check-label" for="light">{{t('website_interface.light_mode')}}</label>
                    </div>
                    <div class="form-check" aria-label="Toggle Dark and Light Modes">
                        <input class="form-check-input" type="radio"
                        [value]="true"
                        formControlName="mode"
                        id="dark">
                        <label class="form-check-label" for="dark">{{t('website_interface.dark_mode')}}</label>
                    </div>
                    
                  </form>
                  <form [formGroup]="textSizeForm">
                  <label for="customTextSize" aria-label="Change text size" *transloco="let t">{{t('website_interface.change_text_size')}}</label>
                  <input
                    type="range"
                    class="form-range"
                    min="1" max="2" step=".25"
                    formControlName="size"
                    id="customTextSize"
                    [attr.aria-valuenow]="textSizeForm.get('size').value"
                    aria-valuemin="1"
                    aria-valuemax="2"
                    aria-labelledby="customTextSize">
                  </form>
              </div>
          </div>
      </div>
      <hr>
      <div class="row mt-4">
          <div class="col-md-6 col-lg-8 mb-md-0 copyright">
              <p class="mb-0">
                  © {{getYear()}} <a href="https://cebt.fi">CEBT</a>
              </p>
          </div>
          <div class="col-md-6 col-lg-4 pull-right">
              <ul class="footer-social-media p-0">
                  <li>
                      <span>Legal</span>
                      <a href="https://cebt.fi/privacy" data-original-title="Legal" [attr.aria-label]="'Legal'">
                          <i class="fa-solid fa-gavel"></i>
                      </a>
                  </li>
                  <li>
                      <a href="https://www.facebook.com/argonautonline" data-original-title="Facebook" [attr.aria-label]="'Facebook'">
                          <i class="fa-brands fa-square-facebook"></i>
                      </a>
                  </li>
                  <li>
                      <a href="https://www.linkedin.com/company/argonaut-training-facilities-ltd" [attr.aria-label]="'Linkedin'" data-original-title="LinkedIn">
                        <i class="fa-brands fa-linkedin"></i>
                      </a>
                  </li>
              </ul>
          </div>
      </div>
      <div>
        <multisite-guest-import></multisite-guest-import>
        <multisite-guest-login></multisite-guest-login>
        <multisite-consent-request-modal></multisite-consent-request-modal>
      </div>
  </div>
</div>