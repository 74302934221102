import { Icon } from "../../icons"

export class IconLinkListLink {
    constructor(
        public url:string, // 'https://www.wikipedia.org'
        public icons?:Icon[], // most links will have one icon but multiple are allowed
        public imageIconUrls?:string[], // alternative to the icon format
        public labelText?:string, // 'First Choice'
        public labelTranslationKey?:string, // 'common.first'
        public linkHelpTranslationKey?:string, // 'common.open_link'
    ){
        
    }
}