
import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { DataProcessingService } from '@frontend/common';

import { Media } from '@frontend/core';
import { LinksService } from '@frontend/shared';


@Component({
  selector: 'multisite-link-type-nine',
  templateUrl: './link-type-nine.component.html',
  styleUrls: ['./link-type-nine.component.scss']
})
export class LinkTypeNineComponent {

  // This component does not require a Link type data

  @Input() iconClasses : string; //  'fa-solid fa-question'
  @Input() headingClass : string; //  'h2' or 'h4' etc
  @Input() linkLabelPart1TranslationKey : string; // 'common.go'
  @Input() linkLabelPart1Text : string; // 'Go'
  @Input() linkLabelPart2TranslationKey : string; // null
  @Input() linkLabelPart2Text : string; // ':'
  @Input() linkLabelPart3TranslationKey : string; // 'common.brands'
  @Input() linkLabelPart3Text : string; // null
  @Input() url : string; // target page
  @Output() linkClicked = new EventEmitter<Event>();

  // @Input() routerLink is not needed; it works simply as a property of the tag;

  constructor() { }

  doLinkClick (event : Event){
    if (this.url){
      window.location.href = this.url;
    }
    this.linkClicked.emit(event);
  }

}
