import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Breadcrumb } from './breadcrumb.interface';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { PageTitleService } from '../title/title.service';
import { Subscription } from 'rxjs';
import { BreadcrumbFragment, BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'multisite-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: Breadcrumb[]
  pageTitle : string;
  pageTitleTranslationKey : string;
  pageTitleSubscription: Subscription;
  fragments: BreadcrumbFragment[];
  breadcrumbFragmentSubscription: Subscription;
  @Input() breadcrumbFixedWidth : boolean; // if falsy, this will make the breadcrumb fill the full width available

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pageTitleService : PageTitleService,
    private breadcrumbService : BreadcrumbService,
  ) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  getFragmentName(paramName){
    if (!this.fragments){return null;};
    return this.fragments.find(f=>f.urlFragment===paramName)?.fragmentName;
  }
  makeTitleFromRouteConfig (lastBreadcrumb : Breadcrumb){
    if (lastBreadcrumb.translationKey){
      this.pageTitle = null;
      this.pageTitleTranslationKey = lastBreadcrumb.translationKey
    } else {
      this.pageTitle = lastBreadcrumb.label;
    }
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      distinctUntilChanged((previous, current) => {
        // Compare only the paths, ignoring query params
        const previousUrl = previous.urlAfterRedirects.split('?')[0];
        const currentUrl = current.urlAfterRedirects.split('?')[0];
        return previousUrl === currentUrl;
      })
    ).subscribe(() => {
      this.pageTitleTranslationKey = null;
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
      this.pageTitleService.setTitle(null);
      this.breadcrumbService.resetBehaviourSubject();
    })
    this.pageTitleSubscription = this.pageTitleService.currentTitle.subscribe( (title)=>{
      if (title){
        this.pageTitle = title;
      } else if (this.breadcrumbs?.length && this.breadcrumbs[this.breadcrumbs.length-1].labelSource !== 'titleService') {
        this.makeTitleFromRouteConfig(this.breadcrumbs[this.breadcrumbs.length-1]);
      } else {
        this.pageTitle = null;
      }
    });
    this.breadcrumbFragmentSubscription = this.breadcrumbService.currentBreadcrumbs
      .subscribe( (fragments)=>{
        this.fragments = fragments
    });
  }

  /**
   * Recursively build breadcrumb according to activated route.
   * @param route
   * @param url
   * @param breadcrumbs
   */
  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    //If no routeConfig is avalailable we are on the root path
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.label : '';
    let labelSource = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.labelSource : '';
    let translationKey = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.translationKey : '';
    // let isClickable = route.routeConfig && route.routeConfig.data && route.routeConfig.data.isClickable;
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
  
    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.indexOf(':')===0;
    let paramName : string;
    if(isDynamicRoute && !!route.snapshot) {
      paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
    }
  
    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;
  
    const breadcrumb: Breadcrumb = {
        label: label,
        labelSource: labelSource,
        translationKey: translationKey,
        paramName: paramName,
        url: nextUrl,
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
    if (route.firstChild) {
        //If we are not on our current path yet,
        //there will be more children to look after, to build our breadcumb
        return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
  ngOnDestroy () {
    if (this.pageTitleSubscription){
      this.pageTitleSubscription.unsubscribe();
    }
  }

}
