import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebsiteLogosComponent } from './website-logos/website-logos.component';



@NgModule({
  declarations: [
    WebsiteLogosComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    WebsiteLogosComponent
  ]
})
export class WebsitesModule { }
