import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploaderSingleFileComponent } from './uploader-single-file/uploader-single-file.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
// import { LoadersModule } from '../../../../common/src/lib/loaders'; // TODO - this may be causing import errors in staging/production. Why?
// import { LoadersModule } from '@frontend/common'; // TODO - for some reason this does not work. Why?
// import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';

@NgModule({
  declarations: [
    UploaderSingleFileComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    FormsModule,
    AlertModule,
    // LoadersModule,
    TabsModule,
    // ModalModule,
  ],
  exports: [
    UploaderSingleFileComponent
  ]
})
export class UploadersModule { }
