import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SurveyFeedbackRoutingModule } from './survey-feedback-routing.module';
import { SurveyFeedbackHomeComponent } from './home/survey-feedback-home.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ProgressIndicatorSliderModule } from '../../tracking';


@NgModule({
  declarations: [
    SurveyFeedbackHomeComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    SurveyFeedbackRoutingModule,
    ProgressIndicatorSliderModule,
  ],
  exports: [
    SurveyFeedbackHomeComponent
  ]
})
export class SurveyFeedbackModule { }
