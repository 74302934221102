import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessCodeFormComponent } from './access-code-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    AccessCodeFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  exports: [
    AccessCodeFormComponent
  ]
})
export class AccessCodeFormModule { }
