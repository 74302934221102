<div class="color-palete" *transloco="let t">
  <div *ngFor="let swatch of swatches" class="color-palete-item"
    [ngStyle]="{ 'background-color': swatch.translationKey === 'branding.link' ? 'transparent' : swatch.hex }">

    <div class="item-bg" [ngStyle]="{ 'color': swatch.textColour }">
      <div>
        <span *ngIf="swatch.colourKey !== 'link'">
          <p>{{t(swatch.translationKey)}}</p>
          <p *ngIf="showHexCodes">{{swatch.hex}}</p>
        </span>
        <span *ngIf="swatch.colourKey === 'link'">
          <a [ngClass]="type+'-link'">{{t(swatch.translationKey)}}<br>
            <span *ngIf="showHexCodes">
              {{swatch.hex}}
            </span>
          </a>
        </span>

        <div class="input-group" *ngIf="editMode == 'advanced' && swatch.baseColour || editMode === 'expert'">
          <input class="form-control" type="text" [(ngModel)]="swatch.hex" (change)="onInputChange(swatch,'main')"
            minlength="4" />
          <span class="input-group-addon">
            <input type="color" [(ngModel)]="swatch.hex" (change)="onInputChange(swatch,'main')" size="7"
              class="colour-picker" />
          </span>
        </div>

      </div>
    </div>
    <div class="swatch-shadow align-self"
      [ngStyle]="{ 'background-color': swatch.colourKey === 'link' ? 'transparent' :  swatch.shadowHex }">
      <small *ngIf="swatch.colourKey !== 'link' || editMode === 'expert'"
        [ngClass]="{'text-color' : swatch.colourKey == 'link'}">{{swatch.colourKey === 'link' ?
        t('branding.hover') : t(swatch.shadowLabel)}}</small>
      <div class="input-group" *ngIf="editMode === 'expert'">
        <input class="form-control" type="text" [(ngModel)]="swatch.shadowHex" (change)="onInputChange(swatch,'shadow')"
          minlength="4" />
        <span class="input-group-addon">
          <input type="color" [(ngModel)]="swatch.shadowHex" (change)="onInputChange(swatch,'shadow')" size="7"
            class="colour-picker" />
        </span>
      </div>
    </div>
  </div>
</div>