import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

import { Hub, HubService, multilingualMetaTextsContent } from '..';
import { AuthService, User } from '../../auth';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
import { Gallery, GalleryService, MediaService } from '../../content/media';
import { Language, LanguageService } from '../../language';
import { MetaTextMultilingual } from '../../content/metaText-multilingual.model';
import { FormGroup, NgForm } from '@angular/forms';
import { CloudinaryMediaAsset } from '../../models';

type profileKeysObject = {
  [key: string] : {key: string, category: string, type: string, banner_category : string, banner_type: string, feature_category: string,feature_type: string}
}

type languageVersionsObject = {
  [key: string] : Language[]
}
type selectedLanguagesObject = {
  [key: string] : Language
}
type selectedMediaObject = {
  [key: string] : {[key: string] : CloudinaryMediaAsset} // [category][type][media object]
}

@Component({
  selector: 'multisite-hub-profile-edit',
  templateUrl: './hub-profile-edit.component.html',
  styleUrls: ['./hub-profile-edit.component.scss']
})
export class HubProfileEditComponent implements OnInit, OnDestroy {

  multilingualMetaTextsSubscription: Subscription;
  multilingualMetaTextsDraft: MetaTextMultilingual[]; // a copy without reference to hub.multilingualMetaTexts in memory
  multilingualMetaTextDraft: MetaTextMultilingual; // a copy without reference to hub.multilingualMetaTexts in memory
  languagesSubscription: Subscription;
  languages : Language[];
  englishLanguage : Language; // this is special because it is required
  selectedLanguage : Language;
  hubSubscription: Subscription;
  loadingMetaTextUpdate: boolean;
  loading: boolean;
  loadingTrainers: boolean;
  hubSlug: string;
  hub: Hub;
  profile_slug: string; // 'general' or 'coaching'
  profileKeys : profileKeysObject;
  error = null;
  gallerySubscription: Subscription;
  galleryItemSelectSubscription: Subscription;
  fileUploadSubscription: Subscription;
  fileUploadError: string;
  fileUploadSuccess: string;
  uploadProgress:{[key:string]:{[key:string]:number}} = {}; // {'profile':{'feature':77}}
  user : User = null;
  userSubscription: Subscription;
  isAuthenticated : boolean;
  gallery : Gallery;
  selectedMedia: selectedMediaObject = {}; // An object which holds the selected Gallery items
  languageVersions: languageVersionsObject = {}; // An object which holds the languages which the user chooses to edit, for different parts of the page
  selectedLanguages: selectedLanguagesObject = {}; // An object which temporarily holds a newly-selected language for different parts of the page
  @ViewChild('language_form_profile', { static: false }) language_form_profile: NgForm;
  dangerZoneInfoCollapse : boolean = true;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hubService: HubService,
    private breadcrumbService : BreadcrumbService,
    private authService : AuthService,
    private galleryService : GalleryService,
    private languageService : LanguageService,
    private mediaService : MediaService,
  ) {
    this.profileKeys = {
      general : {
        key :'profile_general',
        category :'profile',
        type :'general',
        banner_category :'profile',
        banner_type :'general',
        feature_category :'profile',
        feature_type :'feature',
      },
      // add more kinds of profile here
    }
  }

  
  getGallery (category,type){
    this.gallerySubscription = this.galleryService.getGallery (category,type)
      .subscribe(
        response => {
          if (response){
            this.gallery = response;
          }
        },
        error => {
          // this.error = error;
        }
      );
  };
  makeSelectedMediaObject (mediaArray : CloudinaryMediaAsset[], selectedMedia){
    if (!mediaArray?.length){selectedMedia = {};};
    mediaArray.forEach(m =>{
      if (!selectedMedia[m.category]){
        selectedMedia[m.category] = {[m.type] : m};
      } else {
        selectedMedia[m.category][m.type] = m;
      };
    })
    return selectedMedia;
  }
  getLanguages(){
    this.languagesSubscription = this.languageService.getLanguages()
      .subscribe(response=> {
        this.languages = response;
        this.englishLanguage = this.languages.find(l=>l.iso === 'en');
        if (!this.multilingualMetaTextDraft){
          this.languageVersions[this.profileKeys[this.profile_slug]['key']] = [this.englishLanguage];
        } else {
          this.languageVersions[this.profileKeys[this.profile_slug]['key']] = this.preSelectLanguageVersionsFromDraft(this.multilingualMetaTextDraft, this.languages);
        }
      });
  }
  get selectedLanguageName (){
    return this.selectedLanguages[this.profileKeys[this.profile_slug]['key']]?.name
  }
  set selectedLanguageName (value){
    const foundLanguage = this.languages?.find(l=>l.name == value);
    if (this.selectedLanguages[this.profileKeys[this.profile_slug]['key']] && foundLanguage) {
      this.selectedLanguages[this.profileKeys[this.profile_slug]['key']] = foundLanguage;
    };
  }
  onLanguageSelect($event : {item:Language}, category: string){
    if(!$event?.item){return;};
    this.selectedLanguages[category] = $event?.item;
    if (this.languageVersions[category] && !this.languageVersions[category].some(lang=>lang.iso === $event.item.iso)){
      this.languageVersions[category].push($event.item);
    }
    this.language_form_profile.reset();
    this.selectedLanguages[category] = null;
  }
  onLanguageRemove(language : Language, category: string){
    if (language.iso === this.englishLanguage.iso){
      return;
    }
    this.languageVersions[category] = this.languageVersions[category].filter(lang=>lang.iso !== language.iso);
  }
  cancelUploadFile (bool : boolean){
    this.fileUploadSuccess = null;
    if (this.fileUploadSubscription){
      this.fileUploadSubscription.unsubscribe();
    }
    for (let category in this.uploadProgress){
      for (let type in this.uploadProgress[category]){
        this.uploadProgress[category][type] = null;
      }
    }
  }
  uploadFile (formData : FormData,category,type){
    this.fileUploadSuccess = null;
    this.fileUploadError = null;
    if (!this.hub || !formData){return;};
    formData.append('category',category);
    formData.append('type',type);
    const upload$ = this.hubService.uploadFile(this.hub.slug,formData).pipe(
      finalize(() => {
        // Code to run when the subscription is finalized (cancelled or completed)
        // For example, you can reset any relevant properties or perform cleanup tasks
      })
    );
    this.uploadProgress[category] = {[type]:0};
    this.fileUploadSubscription = upload$.subscribe(event => {
      if (event.type == HttpEventType.UploadProgress) {
        this.uploadProgress[category][type] = Math.round(100 * (event.loaded / event.total));
      } else if (event.type === HttpEventType.Response) {
        this.fileUploadSuccess = 'common.saved';
        this.uploadProgress[category][type] = null;
        this.getHub(this.hub?.slug,true);
      }
    }, error=>{
      this.fileUploadError = error;
        this.uploadProgress[category][type] = null;
    });
  }
  imageWithTransformations(file_url:string,transformations:string){
    return this.mediaService.insertCloudinaryTransformationsIntoMediaUrl(file_url,transformations);
  }
  deepCopyMultilingualMetaTexts(metaTexts) {
    return JSON.parse(JSON.stringify(metaTexts));
  }
  getMultilingualMetaTexts (hub:Hub){
    this.multilingualMetaTextsSubscription = this.hubService.getMultilingualMetaTexts(hub).subscribe(response=>{
      if (response){
        this.hub = response;
        if (this.hub?.multilingualMetaTexts?.length){
          this.multilingualMetaTextsDraft = this.deepCopyMultilingualMetaTexts(this.hub.multilingualMetaTexts);
          this.multilingualMetaTextDraft = this.multilingualMetaTextsDraft.find(mt=>mt.category==='profile'&&mt.type===this.profileKeys[this.profile_slug].type);
          if (this.languages){
            this.languageVersions[this.profileKeys[this.profile_slug]['key']] = this.preSelectLanguageVersionsFromDraft(this.multilingualMetaTextDraft, this.languages);
          }
        }
      }
    })
  }
  preSelectLanguageVersionsFromDraft(metaTexts: MetaTextMultilingual, languages : Language[]){
    let languageVersions : Language[] = [];
    let columns = ['s','m','l','xl'];
    for (let column of columns){
      if (metaTexts[column]){
        languageVersions = [...languageVersions, ...languages.filter(l => Object.keys(metaTexts[column]).includes(l.iso))];
      }
    }
    const uniqueLanguageIds = {};
    const uniqueLanguageVersions = languageVersions.filter((lang, index, array) => {
      if (!uniqueLanguageIds[lang.id]) {
        uniqueLanguageIds[lang.id] = true;
        return true;
      }
      return false;
    });
    return uniqueLanguageVersions.find(l=>l.iso === this.englishLanguage.iso) ? uniqueLanguageVersions : uniqueLanguageVersions.concat(this.englishLanguage);
  }
  updateMultilingualMetaTexts (alias:string, draft_texts_id: number, newTexts : FormGroup[], category:string, type:string){
    this.loadingMetaTextUpdate = true;
    let backendValues : multilingualMetaTextsContent[] = newTexts.map(fc=> fc.value);
    this.multilingualMetaTextsSubscription = this.hubService.setMultilingualMetaTexts(this.hub,draft_texts_id,backendValues,category,type,alias).subscribe(response=>{
      if (response?.multilingualMetaTexts){
        this.multilingualMetaTextsDraft = this.deepCopyMultilingualMetaTexts(response.multilingualMetaTexts);
        this.multilingualMetaTextDraft = this.multilingualMetaTextsDraft.find(mt=>mt.category==='profile'&&mt.type===this.profileKeys[this.profile_slug].type);
        if (this.languages){
          this.languageVersions[this.profileKeys[this.profile_slug]['key']] = this.preSelectLanguageVersionsFromDraft(this.multilingualMetaTextDraft, this.languages);
        }
        this.loadingMetaTextUpdate = false;
      }
    },
    error => {
      this.loadingMetaTextUpdate = false;

    })
  }
  getHub (slug : string, updating : boolean){
    this.hubService.getManagedHub(slug).subscribe(response => {
      this.hub = response;
      this.selectedMedia = this.makeSelectedMediaObject(this.hub.media,this.selectedMedia);
      if (!updating){
        this.breadcrumbService.setBreadcrumbFragment({urlFragment:'hub',fragmentName:this.hub.name});
        this.getMultilingualMetaTexts(this.hub);
      }
    })
  }
  getHubs (){
    this.hubService.getManagedHubs(false).subscribe(response => {
      this.getHub(this.route.snapshot.params['hub'],false);
    })
  }
  isAdmin (){
    return this.authService.checkRole("Admin");
  }
  ngOnInit(): void {
    this.profile_slug =  this.route.snapshot.params['profile'];
    this.route.url.subscribe(() => {
      let fullUrlParts = this.router.routerState.snapshot.url
        .toLowerCase()
        .split('/')
        .filter((s) => s);
      if (this.hub){ // the user has manually changed the URL after the first hub was loaded
        this.getHub(fullUrlParts[1], false);
      }
    });
    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
      this.isAuthenticated = !!user;
    });
    this.getHubs(); // TODO. We need to get ALL the hubs because caching one hub ahead of ALL the hubs does not work right at the moment. The problem exists in this component because we later request the multilingualMetaTexts which can't be cached without all the hubs, for some reason.
    this.getGallery('profile','banner');
    this.getLanguages();
  }
  ngOnDestroy () : void {
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    };
    if (this.gallerySubscription){
      this.gallerySubscription.unsubscribe();
    };
    if (this.hubSubscription){
      this.hubSubscription.unsubscribe();
    }
    if (this.fileUploadSubscription){
      this.fileUploadSubscription.unsubscribe();
    }
    if (this.languagesSubscription){
      this.languagesSubscription.unsubscribe();
    }
    if (this.multilingualMetaTextsSubscription){
      this.multilingualMetaTextsSubscription.unsubscribe();
    }
  }

}
