import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { LinkTypeTwelveComponent } from './link-type-twelve.component';



@NgModule({
  declarations: [LinkTypeTwelveComponent],
  imports: [
    CommonModule,
    TranslocoModule
  ],
  exports: [LinkTypeTwelveComponent],
})
export class LinkTypeTwelveModule { }
