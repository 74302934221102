import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@frontend/shared';
import { MediaService } from '../../../content';

@Component({
  selector: 'progress-indicator-doughnut',
  templateUrl: './progress-indicator-doughnut.component.html',
  styleUrls: ['./progress-indicator-doughnut.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressIndicatorDoughnutComponent implements OnInit {

  @Input() elementWidth: number = 100;
  @Input() progressPercent: number; // 55.3
  @Input() text: string | number; // Could show a step number or something which is not the percentage
  @Input() fillClass: string = 'traffic-light-1';
  @Input() icon: Icon;
  cloudinary_base_url : string; // 'https://res.cloudinary.com/cebt/image/upload/'
  
  constructor(private mediaService : MediaService) { 
    this.cloudinary_base_url = this.mediaService.cloudinary_base_url;
  }

  ngOnInit(): void {
    // console.log('Initiating component '+this.iconClasses);
    // debugger; TODO - this component re-initialises when the page is scrolled.
  }

}
