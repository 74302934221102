<div class="container" *transloco="let t">

  <div class="my-5">
    <div class="row">
      <div class="col-lg-8">
        <form [formGroup]="settingsForm" role="form" *ngIf="settingsForm && hub?.type === 'agency'">
          <h3>{{t('hubs.cco.show_hide_links')}}</h3>
          <p>{{t('hubs.cco.show_hide_links_info')}}</p>
          <div class="form-check my-4">
            <input value="" formControlName="hide_links" class="form-check-input" type="checkbox" id="hide_links"
              [attr.disabled]="loading ? true : null" />
            <label class="form-check-label" for="hide_links">
              {{t('content_management.hide_links')}}
            </label>
            <alert *ngIf="settingsForm.get('hide_links').value" [type]="'warning'">
              <p>{{t('hubs.cco.no_links_warning')}}</p>
            </alert>
          </div>


          <div class="row flex-lg-row-reverse" *ngIf="!settingsForm.get('hide_links').value">
            <div class="col-lg-8">
              <h3>{{t('hubs.cco.redirects')}}</h3>
              <p class="mb-4">{{t('hubs.cco.redirects_info')}}</p>

              <div class="input-self mb-3">
                <label for="redirect_general_link" class="form-label">{{ t('platform.general_link') }}
                </label>
                <div class="input-group" [ngClass]="{
                  'ng-invalid': settingsForm.get('redirect_general_link').invalid && (settingsForm.get('redirect_general_link').touched || settingsForm.get('redirect_general_link').dirty),
                  'ng-valid': settingsForm.get('redirect_general_link').valid && (settingsForm.get('redirect_general_link').touched || settingsForm.get('redirect_general_link').dirty),
                  }">
                  <span class="input-group-text text-primary-light">
                    <i class="fa fa-link" aria-hidden="true"></i>
                  </span>
                  <input class="form-control" type="text" formControlName="redirect_general_link"
                    id="redirect_general_link" placeholder="https://mycompany.com/culture/profiling/" />
                </div>
                <div *ngIf="
                settingsForm.get('redirect_general_link').errors?.pattern &&
                (settingsForm.get('redirect_general_link').touched || settingsForm.get('redirect_general_link').dirty)
              " class="invalid-feedback">
                  {{ t('content_management.url_validation_error') }}
                </div>
                <div class="form-text">{{ t('platform.cco_general_link_info') }}</div>
              </div>

              <div class="input-self mb-3">
                <label for="redirect_personalised_links" class="form-label">{{ t('platform.personalised_links') }}
                </label>
                <!-- We should have ng-valid or ng-invalid class next to the class input-group -->
                <div class="input-group" [ngClass]="{
                  'ng-invalid': settingsForm.get('redirect_personalised_links').invalid && (settingsForm.get('redirect_personalised_links').touched || settingsForm.get('redirect_personalised_links').dirty),
                  'ng-valid': settingsForm.get('redirect_personalised_links').valid && (settingsForm.get('redirect_personalised_links').touched || settingsForm.get('redirect_personalised_links').dirty),
                  }">
                  <span class="input-group-text text-primary-light">
                    <i class="fa fa-link" aria-hidden="true"></i>
                  </span>
                  <input class="form-control" type="text" formControlName="redirect_personalised_links"
                    id="redirect_personalised_links" placeholder="https://mycompany.com/culture/profiling/" />
                </div>
                <div *ngIf="
                settingsForm.get('redirect_personalised_links').errors?.pattern &&
                (settingsForm.get('redirect_personalised_links').touched || settingsForm.get('redirect_personalised_links').dirty)
              " class="invalid-feedback">
                  {{ t('content_management.url_validation_error') }}
                </div>
                <div class="form-text">{{ t('platform.cco_personalised_links_info') }}</div>
              </div>
            </div>

            <div class="col-lg-4 mb-4">
              <a class="py-1" (click)="openModal()">
                <img [src]="
                cloudinary_base_url +
                'w_250,c_fill/' +
                cco_links_screenshot_path
              " alt="CCO links in a feedback screen" class="w-100 w-md-50 w-sm-50 w-lg-50" />
              </a>
            </div>

          </div>
        </form>



        <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
        </multisite-loader-globe-animation-detail>


        <h4 class="mt-4">{{t('hubs.cco.referral_code')}}</h4>
        <div *ngIf="!referral_code" class="mt-2">
          <alert *ngIf="settingsForm && settingsForm.get('hide_links').value" [type]="'warning'">
            <p>{{t('hubs.cco.no_code')}}</p>
          </alert>
          <p>{{t('hubs.cco.referral_code_info')}}</p>
          <p>{{t('common.request_here')}}: <a
              href="https://www.argonautonline.com/partners/">https://www.argonautonline.com/partners/</a></p>
          <p class="text-muted mt-2"><i class="fa-regular fa-info-circle mr-2"></i>{{t('hubs.cco.argonaut_codes')}}
          </p>
        </div>

        <div *ngIf="referral_code" class="mt-2">
          <small class="text-muted">{{t('common.your_code_is')}}:</small>
          <h5>{{referral_code}}</h5>
          <p>{{t('hubs.cco.give_discounts_here')}}: <a href="https://www.cultureconnector.com/#/shop">CultureConnector
              shop</a></p>
        </div>

        <h5 class="mt-3">{{t('common.useful_links')}}</h5>
        <ul>
          <li>{{t('common.home')}} <a
              [href]="cco_home_url+(referral_code?'?coupon='+referral_code:'')">{{cco_home_url+(referral_code?'?coupon='+referral_code:'')}}</a>
          </li>
          <li>{{t('navigation.shop')}} <a
              [href]="cco_shop_url+(referral_code?'?coupon='+referral_code:'')">{{cco_shop_url+(referral_code?'?coupon='+referral_code:'')}}</a>
          </li>
          <li>{{t('common.product')}} <a
              [href]="cco_shop_product_url+(referral_code?'?coupon='+referral_code:'')">{{cco_shop_product_url+(referral_code?'?coupon='+referral_code:'')}}</a>
          </li>
        </ul>

      </div>

      <div class="col-lg-4">
        <div class="flex-column align-items-center justify-content-center w-100 h-100">
          <multisite-link-type-six *ngFor="let link of links"
            [imageTransformations]="'w_100,c_thumb/'"
            [titleText]="link.label"
            [description]="link.description"
            [imageUrl]="link.media?.base_url"
            [media]="link.media"
            [cta_label]="link.cta_label"
            [cta_type]="link.cta_type"
            [url]="link.url"
            >
          </multisite-link-type-six>
        </div>
      </div>
    </div>




    <p *ngIf="!loading && !hub">{{ t('common.not_found') }}</p>
  </div>
</div>
<ng-template #imageEnlargedModal>
  <div class="modal-header" *transloco="let t">
    <h4 id="dialog-static-name" class="modal-title pull-left">
      {{ t('platform.general_link') }} +
      {{ t('platform.personalised_links') }}
    </h4>
    <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </button>
  </div>
  <div class="modal-body d-flex flex-row justify-content-center">
    <img class="img-responsive w-sm-100" [src]="cloudinary_base_url + 'w_1000,c_fill/' +cco_links_screenshot_path"
      alt="CCO links in a feedback screen" />
  </div>
</ng-template>