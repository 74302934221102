import { Injectable } from '@angular/core';
import { CarouselItem } from '@frontend/common';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromoContentService {

  carouselItems : CarouselItem[];
  carouselItemSelections : any; // {[string]:number[]}[]
  translationKey: string;

  // TODO - this service is duplicated in IC Pendulum and Culture TTT

  constructor() {

    this.carouselItemSelections = [
      {key:"login",items:[0,1,2]},
      {key:"register",items:[0,1,2]},
      {key:"forgot-password",items:[0,1,2]},
      {key:"password-reset",items:[0,1,2]},
      {key:"home",items:[0,1,2]},
      {key:"design/10",items:[3,4,5]},
    ]

  }

  generateCarouselItems (appKey : string /*'icpendulum' or 'culurettt'*/){
    if (this.carouselItems?.length){
      return this.carouselItems;
    };
    return [
      {
        title :appKey+'_content.promotion.highlight01_title',
        description :appKey+'_content.promotion.highlight01_description',
        bgImageUrl :'https://res.cloudinary.com/cebt/image/upload/w_1000/v1674431439/concepts/woman-in-black-tank-top-whiteboard-trainer-training-pexels-thisisengineering-3861962-flipped_t3wyej.jpg',
        bgImageStyles :'linear-gradient(165deg, rgba(0, 0, 0, 0.6) 33%, rgba(0, 0, 0, 0) 80%)',
        bgImageContainerStyles :'height: 100vh',
        bgImageAlt :'A woman stands in front of a whiteboard with a pen and smiles',
        interval : 8000,
        recommendedTransformations : 'ar_16:9,e_brightness:-60,c_crop,w_1000,g_face',
        // https://res.cloudinary.com/cebt/image/upload/ar_16:9,c_crop,c_thumb,w_200,g_face/cld-sample.jpg
      },
      {
        title :appKey+'_content.promotion.highlight02_title',
        description :appKey+'_content.promotion.highlight02_description',
        bgImageUrl :'https://res.cloudinary.com/cebt/image/upload/w_1000/v1674436813/concepts/three-young-professionals-pexels-alexander-suhorucov-6457508_up07mv.jpg',
        bgImageStyles :'linear-gradient(165deg, rgba(0, 0, 0, 0.6) 33%, rgba(0, 0, 0, 0) 80%)',
        bgImageContainerStyles :'height: 100vh',
        bgImageAlt :'A speaker stands in front of an audience with a sympathetic expression',
        interval : 8000,
        recommendedTransformations : 'ar_16:9/',

      },{
        title :appKey+'_content.promotion.highlight03_title',
        description :appKey+'_content.promotion.highlight03_description',
        bgImageUrl :'https://res.cloudinary.com/cebt/image/upload/w_1000/v1674240799/concepts/training-event-29008145962_26494a5434_k_d_jkdbau.jpg',
        bgImageStyles :'linear-gradient(165deg, rgba(0, 0, 0, 0.6) 33%, rgba(0, 0, 0, 0) 80%)',
        bgImageContainerStyles :'height: 100vh',
        bgImageAlt :'A man enjoying the outdoors',
        interval : 8000,
        recommendedTransformations : 'ar_16:9/',
      },
      {
        title :appKey+'_content.promotion.highlight01_title',
        description :appKey+'_content.promotion.highlight01_description',
        bgImageUrl :'https://res.cloudinary.com/cebt/image/upload/v1674240799/concepts/training-event-29008145962_26494a5434_k_d_jkdbau.jpg',
        bgImageStyles :'linear-gradient(165deg, rgba(0, 0, 0, 0.6) 33%, rgba(0, 0, 0, 0) 80%)',
        bgImageContainerStyles :'height: 100vh',
        bgImageAlt :'Smiling buinessman in an office',
        interval : 8000,
        recommendedTransformations : 'ar_16:9,e_brightness:-60,c_crop,w_1000,g_face/',
        // https://res.cloudinary.com/cebt/image/upload/ar_16:9,c_crop,c_thumb,w_200,g_face/cld-sample.jpg
        media: {
          id: 145,
          medially_type: "App\\Models\\Tool",
          medially_id: 23,
          file_url: 'https://res.cloudinary.com/cebt/image/upload/v1667417181/persons/smiling-businessperson-globalintegration-flickr-cc2-5347195359_8b3a32e4ab_o_y5tcks.jpg',
          file_name: "persons/smiling-businessperson-globalintegration-flickr-cc2-5347195359_8b3a32e4ab_o_y5tcks.jpg",
          file_type: "image",
          size: 734560,
          uploader_id: 1,
          description: "A happy person",
          credits: "Flickr",
          permission: null,
          category: "intercultural",
          type: "concepts",
          created_at: "2022-10-31T13:00:03.000000Z",
          updated_at: "2022-10-31T13:00:03.000000Z",
          base_url: "https://res.cloudinary.com/cebt/image/upload/",
          transformations_url: "c_thumb,w_200,g_face/",
          asset_url: "v1611931415/persons/smiling-businessperson-globalintegration-flickr-cc2-5347195359_8b3a32e4ab_o_y5tcks.jpg"
        }
      },
      {
        title :appKey+'_content.promotion.highlight02_title',
        description :appKey+'_content.promotion.highlight02_description',
        bgImageUrl :'https://res.cloudinary.com/cebt/image/upload/v1667424780/persons/businessperson-in-conversation-wayamo-flickr-cc2-sa-24095958881_7b87542e39_k_c0yleg.jpg',
        bgImageStyles :'linear-gradient(165deg, rgba(0, 0, 0, 0.6) 33%, rgba(0, 0, 0, 0) 80%)',
        bgImageContainerStyles :'height: 100vh',
        bgImageAlt :'A man enjoying the outdoors',
        interval : 8000,
        recommendedTransformations : 'ar_16:9',
        media: {
          id: 145,
          medially_type: "App\\Models\\Tool",
          medially_id: 23,
          file_url: 'https://res.cloudinary.com/cebt/image/upload/v1667424780/persons/businessperson-in-conversation-wayamo-flickr-cc2-sa-24095958881_7b87542e39_k_c0yleg.jpg',
          file_name: "persons/businessperson-in-conversation-wayamo-flickr-cc2-sa-24095958881_7b87542e39_k_c0yleg.jpg",
          file_type: "image",
          size: 734560,
          uploader_id: 1,
          description: "A happy person",
          credits: "Flickr",
          permission: null,
          category: "intercultural",
          type: "concepts",
          created_at: "2022-10-31T13:00:03.000000Z",
          updated_at: "2022-10-31T13:00:03.000000Z",
          base_url: "https://res.cloudinary.com/cebt/image/upload/",
          transformations_url: "c_thumb,w_200,g_face/",
          asset_url: "v1667424780/persons/businessperson-in-conversation-wayamo-flickr-cc2-sa-24095958881_7b87542e39_k_c0yleg.jpg"
        }

      },{
        title :appKey+'_content.promotion.highlight03_title',
        description :appKey+'_content.promotion.highlight03_description',
        bgImageUrl :'https://res.cloudinary.com/cebt/image/upload/v1667424553/persons/smiling-businessperson-globalintegration-flickr-cc2-5347846470_6441319448_o_bprdzo.jpg',
        bgImageStyles :'linear-gradient(165deg, rgba(0, 0, 0, 0.6) 33%, rgba(0, 0, 0, 0) 80%)',
        bgImageContainerStyles :'height: 100vh',
        bgImageAlt :'A man enjoying the outdoors',
        interval : 8000,
        recommendedTransformations : 'ar_16:9',
        media: {
          id: 145,
          medially_type: "App\\Models\\Tool",
          medially_id: 23,
          file_url: 'https://res.cloudinary.com/cebt/image/upload/v1667424553/persons/smiling-businessperson-globalintegration-flickr-cc2-5347846470_6441319448_o_bprdzo.jpg',
          file_name: "persons/smiling-businessperson-globalintegration-flickr-cc2-5347195359_8b3a32e4ab_o_y5tcks.jpg",
          file_type: "image",
          size: 734560,
          uploader_id: 1,
          description: "A happy person",
          credits: "Flickr",
          permission: null,
          category: "intercultural",
          type: "concepts",
          created_at: "2022-10-31T13:00:03.000000Z",
          updated_at: "2022-10-31T13:00:03.000000Z",
          base_url: "https://res.cloudinary.com/cebt/image/upload/",
          transformations_url: "c_thumb,w_200,g_face/",
          asset_url: "v1667424553/persons/smiling-businessperson-globalintegration-flickr-cc2-5347846470_6441319448_o_bprdzo.jpg",
        }
      }
    ];

  }

  getCarouselItemSelection (key:string) : number[]{
    return this.carouselItemSelections.find(cis=>cis.key === key).items;
  }
  getCarouselItemsByIndex (arrayOfItemIndexes : number[], appKey : string){
    this.carouselItems = this.generateCarouselItems(appKey);
    return this.carouselItems.filter((ci,index)=>{
      return arrayOfItemIndexes.indexOf(index)>-1;
    })
  }

}
