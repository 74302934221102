import { Component, OnInit } from '@angular/core';
import { DataProcessingService, LanguageService, PageTitleService } from '@frontend/common';
import { FaqService } from '../faqs.service';
import { Subscription } from 'rxjs';
import { Faq } from '@frontend/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'multisite-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  loading : boolean = false;
  faq: Faq;
  faqSubscription : Subscription;
  activeLanguageSubscription: Subscription;
  error = null;
  appKey : string = null;
  pageType : string = 'faq';

  constructor(
    private faqService : FaqService,
    private route : ActivatedRoute, 
    private languageService : LanguageService,
    private titleService: PageTitleService,
    private dataProcessingService: DataProcessingService,
    ) { }

  gotoTop() {
    debugger; // update this method to the new service
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }


  getFaq (slug: string){
    this.loading = true;
    this.error = null;
    this.faqSubscription = this.faqService.getFaq(slug)
      .subscribe(
        response => {
          this.faq = response;
          let shortenedQuestion = this.faq?.question?.length > 125 ? this.dataProcessingService.truncateText(this.faq?.question,100)+'?' : this.faq.question;
          this.titleService.setTitle(shortenedQuestion);
          this.loading = false;
        },
        error => {
          this.error = error;  
          this.loading = false;
        }
      );
  }

  ngOnInit(): void {
    this.gotoTop();
    if(this.route.snapshot.params['faq']){
      this.getFaq (this.route.snapshot.params['faq']);
    }
    this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( () => {
      if(this.route.snapshot.params['faq']){
        this.getFaq (this.route.snapshot.params['faq']);
      }
    })
  }

  ngOnDestroy (){
    this.faqSubscription.unsubscribe();
  }

}
