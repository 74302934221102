<div class="card-slimmable" [ngClass]="slimMode ? 'slim' : 'wide'" (click)="onSelect($event)">
  <div class="card-body">
    <div class="content-left" *transloco="let t">
      <div class="card-status-icon" [ngClass]="iconBackgroundClass" [style]="'--status-background-color: var(--'+getBackgroundColour(progressPercent)+');'">
        <div class="progress-bar-circle" [style]="'--progress-bar-circle-value: '+progressPercent+'%'" *ngIf="icon">
          <i [ngClass]="icon.identifier_1" *ngIf="icon.identifier_1"></i>
          <img *ngIf="!icon.identifier_1 && icon.identifier_2" [src]="cloudinary_base_url+'w_32,c_fill,ar_1:1,r_max,f_auto/'+icon.identifier_2+'.svg'">

        </div>
      </div>
      <div class="card-text text-wrap">
        <!-- <p class="card-title">{{ translationKey ? t(translationKey) : text }}</p> -->
        <!-- <div class="little-red-box">???</div> -->
        <p class="card-title">{{ text }}</p>
        <p class="card-value">{{ round(progressPercent) }}%</p>
      </div>
    </div>
    <div class="content-right">
      <button class="btn" aria-label="Go to subject of this progress indicator" (click)="onSelect($event)">
        <i class="fa-solid fa-angle-left"></i>
      </button>
    </div>
  </div>
</div>