<!-- <a (click)="onItemSelected()">
  <ul class="list-group">
    <li class="list-group-item d-flex justify-content-between align-items-start">
      <div class="ms-2 me-auto">
        <div class="fw-bold">Title</div>
        {{content.title}}
      </div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start">
      <div class="ms-2 me-auto">
        <div class="fw-bold">Description</div>
        {{content.description}}
      </div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start">
      <div class="ms-2 me-auto">
        <div class="fw-bold">Image transformations</div>
        {{imageTransformations}}
      </div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start">
      <div class="ms-2 me-auto w-100">
        <div class="fw-bold">Banner image</div>
        <p class="text-truncate">{{content.media?.file_url}}</p>
        <p class="text-truncate">{{content.media?.asset_url && imageTransformations ? content.media.base_url +
          imageTransformations + content.media.asset_url : content.media.file_url}} (with transformations)</p>
        <img class="img-responsive w-100" *ngIf="content.media"
          [src]="content.media.asset_url && imageTransformations ? content.media.base_url + imageTransformations + content.media.asset_url : content.media.file_url"
          [alt]="content.media.description">
      </div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start">
      <div class="ms-2 me-auto">
        <div class="fw-bold">Rating (fake data)</div>
        <p>{{content.rating}}</p>
      </div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start">
      <div class="ms-2 me-auto">
        <div class="fw-bold">Number of completions</div>
        <p>{{content.meta.completions}}</p>
      </div>
    </li>
  </ul>
</a> -->


<a class="card-list-item-promo" (click)="onItemSelected()">
  <div class="card">
    <div class="card-img-overlay-color"></div>
    <img src="..." class="card-img" *ngIf="content.media"
      [src]="content.media.asset_url && imageTransformations ? content.media.base_url + imageTransformations + content.media.asset_url : content.media.file_url"
      [alt]="content.media.description">
    <div class="card-img-overlay">
      <div class="main-content">
        <p class="main-title" *ngIf="content.title">{{content.title}}</p>
        <p class="card-title" *ngIf="content.subtitle">{{content.subtitle}}</p>
        <!-- <p class="card-text">Lorem ipsum dolor sit amet.</p> -->
        <div class="social-proof">
          <!-- <div class="social-proof-item" title="Completions">
            {{content.meta.completions}}<i class="ml-2 fa-solid fa-circle-check"></i>
          </div> -->
          <div class="social-proof-item" title="Rating">
            <span class="fw-bold">{{content.rating}}</span>/5<i class="ml-2 fa-solid fa-star"></i>
          </div>
          <div class="social-proof-item" title="Participants" *ngIf="content.meta?.completions > 10">
            {{content.meta.completions}}<i class="ml-2 fa-solid fa-users"></i>
          </div>
        </div>
      </div>
      <div class="after-hover">
        <!-- Some body content that will be displayed on hover -->
        <p>{{content.description}}</p>
      </div>
    </div>
  </div>
</a>