export class UserProfile {
    constructor(
        public id: number,
        public job_title: string,
        public summary: string,
        public bio: string,
        public user_id: any,
        public created_at: Date,
        public updated_at: Date,
    ) {}

  }