import { Component, OnInit, Input } from '@angular/core';

import { Page } from '@frontend/core';


@Component({
  selector: 'frontend-page-display-type-one',
  templateUrl: './page-display-type-one.component.html',
  styleUrls: ['./page-display-type-one.component.scss']
})
export class PageDisplayTypeOneComponent implements OnInit {

  // THIS PAGE TYPE IS GOOD FOR SIMPLE PAGES - all content comes from one single page, with one body field

  @Input() page : Page;
  @Input() showVersion : boolean;
  @Input() showPublishedDate : boolean;
  @Input() showUpdatedDate : boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
