import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LearnerCertification } from '../learner-certification.model';

interface LearnerCertificationsResponse {
  id : number;
  category : string; // 'Accreditation' | 'Pass' | 'Completion'
  subject : {name : string} ; // for example from the Topic or Course or Programme
  approved_at : string; // '2023-02-01 13:00:03'
  expired_at : string; // '2026-02-01 13:00:03'
}

@Injectable({
  providedIn: 'root'
})
export class CertificationDisplayService {


  myLearnerCertifications : LearnerCertification[]

  constructor(private http: HttpClient) { }

  getPlaceholder () { // generate a dummy LearnerCertification

    let learnerCertification : LearnerCertification = {
      id: 100000001,
      category : 'accreditation',
      subject : {name : 'Intercultural Pendulum'},
      approved_at : '2023-02-01 13:00:03',
      expired_at : '2026-02-01 13:00:03',
    }
    return learnerCertification;
  };
  transformLearnerCertification (learnerCertificationResponse){
    let learnerCertification : LearnerCertification = learnerCertificationResponse;
    return learnerCertification;
  }
  transformLearnerCertifications (learnerCertificationsFromBackend) : LearnerCertification[]{
    let transformedlearnerCertifications : LearnerCertification[] = [];
    learnerCertificationsFromBackend.forEach(t=>{
      transformedlearnerCertifications.push(this.transformLearnerCertification(t));
    });
    return transformedlearnerCertifications;
  }
  getMyLearnerCertifications (){
    // let cachedLearnerCertifications : LearnerCertification[] = this.getCachedLearnerCertifications();
    let cachedLearnerCertifications = [this.getPlaceholder()];
    if (cachedLearnerCertifications){
      return of(cachedLearnerCertifications);
    };

    return this.http.get<{'data' : LearnerCertificationsResponse}>(
      'api/v1/learnerCertifications/')
        .pipe(
          map(response =>{
            let learnerCertifications : LearnerCertification[] = this.transformLearnerCertifications(response.data);
            this.cacheMyLearnerCertifications(learnerCertifications);
            return learnerCertifications;
          })
        )
  };
  getCachedMyLearnerCertifications (){
    let learnerCertifications : LearnerCertification[] = null;
    // do something here
    return learnerCertifications;
    }
  cacheMyLearnerCertifications (learnerCertifications: LearnerCertification[]){
    // do something here
    this.myLearnerCertifications = learnerCertifications;
  };
}
