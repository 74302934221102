import { Component, Input } from '@angular/core';
import { Link } from '@frontend/core';


@Component({
  selector: 'multisite-link-type-seven-list',
  templateUrl: './link-type-seven-list.component.html',
  styleUrls: ['./link-type-seven-list.component.scss']
})
export class LinkTypeSevenListComponent {
  // A very simple bulleted list of text-only links (max one line per link)

  
  @Input() links : Link[] = [];

  // @Output() linkClicked = new EventEmitter<any>();

  // doLinkClick (){
  //   this.linkClicked.emit();
  // }

}
