import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { TipService } from '../../content/tips/tip.service';
import { ContentItem, PaginatedContentItems, TopicService } from '../../content';
import { AvailableLanguage, LanguageService } from '../../language';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SortCriterion } from '../sort-criterion.model';
import { BreadcrumbService } from '../../navigation';
import { WindowService } from '../../utilities';
import { Tag, TagService } from '@frontend/core';
import { Culture, CultureService } from '../../cultures';
import { QueryParams, QueryParamsService } from '../query-params-service.service';
import { IDemoModeConfigObject } from './demo-mode-config.interface';

type filterParams = {
  category:string, // 'geographic'
  type:string, // 'national'
  variableName : string, // 'nationalCultures'
}

@Component({
  selector: 'content-item-listing-with-search-filters',
  templateUrl: './content-item-listing-with-search-filters.component.html',
  styleUrls: ['./content-item-listing-with-search-filters.component.scss']
  })
  export class ContentItemListingWithSearchAndFilters implements OnInit, OnDestroy {
    
    @Input() demoModeConfigObject: IDemoModeConfigObject = null; // demo mode is for the DesignerZone, where the component is not configured by the router

    contentClassName : string; // class of the content we'll be listing, for example 'tips' or 'episodes'
    relatedModelInstanceSlug : string; // slug of (optional) related class; for example, if tips should all be related to the topic 'culturequestion' or 'cultureconnector', the slug will be 'culturequestion' or 'cultureconnector'. Other example: 'germany'
    relatedModelClassName : string; // related class; for example, if the tips should all be related to the topic 'cultureconnector', the relatedModelClassName is 'topic'. Other example: 'culture'.
  
    private subscriptions: Subscription[] = [];
    loadingObject: {[key:string]:boolean} = {}; // .accreditations .topic
    paginatedContentItems: PaginatedContentItems;
    imageTransformations: string = 'w_700,c_fill,ar_16:9/';
    activeLanguageSubscription: Subscription;
    activeLanguageObject: AvailableLanguage;
    searchForm : FormGroup;
    default_sort_criteria : SortCriterion[];
    sort_criteria : SortCriterion[];
    maxWidthMediaQuery : string; // '(max-width: 991.98px)'
    @HostListener('window:resize', ['$event'])
    resizeWindow() {
      this.refreshCollapse();
    }
    areFiltersCollapsed: boolean;
    queryParams : QueryParams // 'cultures=france,germany'
    maxQueryStringLength : number = 50;
    tagTypeFilters : string[] = [];
    filterTags : Tag[] = [];
    filterTagsByType : {type:string,tags:Tag[]}[] = [];
    cultureFilters : filterParams[] = null; // [{category:'geographic', type:'national', variableName: 'nationalCultures'}]
    topicFilters : filterParams[] = null; // [{category:'interculturalist', type:null, variableName: 'interculturalistTopics'}]
    nationalCultures: Culture[]; // original list from backend with selectedCultures removed
    selectedNationalCultures: Culture[] = [];
  
  
    constructor(
      private router: Router,
      private route: ActivatedRoute,
      private breadcrumbService : BreadcrumbService,
      private tipService : TipService,
      private tagService : TagService,
      private cultureService : CultureService,
      private topicService : TopicService,
      private languageService: LanguageService,
      private windowService: WindowService,
      private queryParamsService: QueryParamsService,
    ) {
      this.maxWidthMediaQuery = '(max-width: 767px)'; // If more than this max-width, we open the filter
    }
  
  
    isAnythingLoading(){
      for (let something in this.loadingObject){
        if (this.loadingObject[something]){
          return true;
        }
      }
      return false;
    }
    doAlert(message: string = 'You clicked something'){
      alert(message); 
    }
    selectCulture(culture:Culture){
      this.doAlert('Not implemented yet');
    }
    deselectCulture(culture:Culture){
      this.doAlert('Not implemented yet');
    }
    goToContentItem (contentItem: ContentItem){
      if(this.demoModeConfigObject){
        this.doAlert();
      } else if(this.contentClassName === 'tips'){
        this.router.navigate(['/'+contentItem.slug]);
      }
    }
  
    setSortOrderParams(sortable:SortCriterion, getContentImmediately : boolean = false){
      
      if(this.isAnythingLoading() || this.paginatedContentItems?.meta?.total === 1){return;};

      if(!sortable){
        sortable = this.resetSortCriteria();
      }
      let updatedSettings = this.queryParamsService.updateSortOrderParams(sortable,this.sort_criteria,this.queryParams);
      
      this.sort_criteria = updatedSettings.sort_criteria;
      this.queryParams = updatedSettings.queryParams;

      // let queryParamsHandling : QueryParamsHandling = sortable ? 'merge' : 'preserve';

      this.queryParamsService.navigateAfterUpdatingQueryParams(this.queryParams);

      if (getContentImmediately){
        this.getContent();
      }
    
    }
  
    gotoContentItem(contentItem: ContentItem){
      if (!contentItem){return;};
      if (this.contentClassName === 'tips'){
        this.router.navigate(['/'+contentItem.slug],{relativeTo:this.route})
      }
    }
    doGetPaginatedContent(whichPage){
      this.queryParams['page']=whichPage;
      this.getContent();
    }
    onSubmit() {
      if (this.searchForm.invalid) {
        // If the form is invalid, mark all controls as touched to trigger validation messages
        this.searchForm.markAllAsTouched();
        return;
      }
      this.getContent();
    }
    getContent(freshFromServer:boolean = false) {
      const queryParams : QueryParams = this.queryParams;
      let page = queryParams['page'] ? +queryParams['page'] : 1;
      this.queryParams = queryParams;
      this.queryParamsService.navigateAfterUpdatingQueryParams(queryParams);
  
      this.loadingObject.content = true;
      if (this.contentClassName === 'tips') {
        const contentSubscription = this.tipService.getPaginatedTips(this.relatedModelInstanceSlug,queryParams,page,freshFromServer).subscribe(result => {
          this.loadingObject.content = false;
          this.paginatedContentItems = {
            data : this.tipService.makeContentItems(result.data),
            links : result.links,
            meta : result.meta,
          }
        },
        error => {
          this.loadingObject.content = false;
        });
        this.subscriptions.push(contentSubscription);
      }
  
    }

    getFilters (tagTypeFilters : string[] = [], excludeTagsWithEmptyResults : boolean, cultureFilters : filterParams[], topicFilters : filterParams[], freshFromServer : boolean = false) {

      const observables : {[key:string]:Observable<any>} = {};

      if (tagTypeFilters?.length){
        const tags$ = this.tagService.getTagsByType(tagTypeFilters, freshFromServer);
        observables.filterTags = tags$;
      }

      if (excludeTagsWithEmptyResults){
        if (this.contentClassName === 'tips' && this.relatedModelInstanceSlug){
          const tipTagIdsWithResults$ = this.tipService.getTagIdsByTopic(this.relatedModelInstanceSlug);
          observables.tipTagIdsWithResults = tipTagIdsWithResults$;

        }
      }

      if(cultureFilters?.length){
        
        cultureFilters.forEach(filter=> { 
          // e.g. {category:'geographic', type:'national', variableName: 'nationalCultures'}
          const cultureObservable$ = this.cultureService.getCultures(filter.category, filter.type, freshFromServer);
          observables[filter.variableName] = cultureObservable$;
        });

      }
      if(topicFilters?.length){

        topicFilters.forEach(filter=> { 
          const topicObservable$ = this.topicService.getTopics(filter.category, filter.type, freshFromServer);
          observables[filter.variableName] = topicObservable$;
        })
      }

      const combinedSubscription = forkJoin(observables).pipe().subscribe((result) => {
        
        if(result.filterTags){
          if(result.tipTagIdsWithResults){
            this.filterTags = result.filterTags.filter(t=> result.tipTagIdsWithResults.includes(t.id) || this.queryParamsService.isTagIncludedInQueryParams(t,this.queryParams)); // we need to check isTagIncludedInQueryParams in case a user has opened a URL with queryParams for tags with empty results
          } else {
            this.filterTags = result.filterTags;
          }
          this.tagTypeFilters.forEach(type=>{
            this.filterTagsByType.push({type:type,tags:this.filterTags.filter(t=>t.type === type)});
          })
        }
        if(result.nationalCultures){
          this.nationalCultures = result.nationalCultures;
        }
        this.generateForm(result.filterTags)

      });
      this.subscriptions.push(combinedSubscription);
      
    }

    isTagInQueryParams(tag:Tag) : boolean {
      return this.queryParamsService.isValueInQueryParams(this.queryParams,tag.type,tag.slug);
    }

  
    generateForm (filterTags : Tag[] = []){

      let queryString : string = (this.queryParams['query'] && this.queryParams['query'].length) ? this.queryParams['query'].length <= this. maxQueryStringLength ? (this.queryParams['query'] as string) : null : null;

      this.searchForm = new FormGroup(
        {
          query: new FormControl(queryString, [
            Validators.minLength(3),
            Validators.maxLength(this.maxQueryStringLength),
          ]),
        },
      );
      filterTags.forEach(tag => {
        this.searchForm.addControl('tag_'+tag.id, new FormControl(this.isTagInQueryParams(tag)));
        this.searchForm.controls['tag_'+tag.id].valueChanges.subscribe((value) => {
          if(value){
            this.queryParams = this.queryParamsService.updateQueryParams(tag.type,tag.slug,true);
          } else {
            this.queryParams = this.queryParamsService.updateQueryParams(tag.type,tag.slug,false);
          }
          this.queryParamsService.navigateAfterUpdatingQueryParams(this.queryParams);
        });
      });
      this.searchForm.controls.query.valueChanges.subscribe((value) => {
        this.queryParams['query'] = value ? value.trim() : value;
        this.queryParamsService.navigateAfterUpdatingQueryParams(this.queryParams);
      });
    }
    refreshCollapse() {
      if (!window.matchMedia(this.maxWidthMediaQuery).matches) {
        this.areFiltersCollapsed = false;
      } else {
        this.areFiltersCollapsed = true;
      }
    }
    getTopic(relatedModelInstanceSlug : string, freshFromBackend : boolean) {
      this.loadingObject.topic = true;
      const topicSubscription = this.topicService.getTopic(relatedModelInstanceSlug,freshFromBackend).subscribe((topic) => {
        this.breadcrumbService.setBreadcrumbFragment({urlFragment:'topic',fragmentName:topic.name});
        this.loadingObject.topic = false;
        this.getContent(freshFromBackend);
          // this.titleService.setTitleWithTranslationFirstConcatenation(
          //   'navigation.accreditations',
          //   ': ' + topic.name,
          //   null
          // );
        },
        error => {
          this.loadingObject.topic = false;
        });
      this.subscriptions.push(topicSubscription);
    }
    resetSortCriteria() : SortCriterion {
      if(this.demoModeConfigObject){
        this.default_sort_criteria = this.demoModeConfigObject.sort_criteria;
      } else {
        this.default_sort_criteria = this.route.routeConfig?.data?.sortCriteria;
      }
      this.sort_criteria = [...this.default_sort_criteria];
      return this.sort_criteria[0];
    }
    initialise (relatedModelInstanceSlug:string,freshFromServer: boolean){
      this.getFilters(
        this.tagTypeFilters,
        !!this.route.routeConfig?.data?.excludeTagsWithEmptyResults,
        this.cultureFilters,
        this.topicFilters,
        freshFromServer
      );
      if(this.contentClassName){
        if(relatedModelInstanceSlug && !this.demoModeConfigObject){
          if(this.relatedModelClassName === 'topic'){
            this.getTopic(relatedModelInstanceSlug,freshFromServer);
          } /* else {
            // TODO when needed: add logic for other kinds of related models here
          }
           */
        } else {
          this.getContent(freshFromServer);
        }
      }
    }
    ngOnInit(): void {
      this.windowService.goToTop();
      this.contentClassName = this.route.routeConfig?.data?.contentClassName; // 'tips'
      this.relatedModelClassName = this.route.routeConfig?.data?.relatedModelClassName; // 'topic'
      this.resetSortCriteria();
      if(this.relatedModelClassName){
        this.relatedModelInstanceSlug = this.route.snapshot.params[this.relatedModelClassName]?.toLowerCase() // this.route.snapshot.params['topic']?.toLowerCase();
      } /*else { // relatedModelClassName is not required
        // Add some custom logic here if you are not getting the relatedModelClassName and slug from the route config
      }*/
      this.tagTypeFilters = this.route.routeConfig?.data?.tagTypeFilters ?? []; // ['interculturalist_fields']
      this.cultureFilters = this.route.routeConfig?.data?.cultureFilters ?? [];
      this.topicFilters = this.route.routeConfig?.data?.topicFilters ?? [];
      if(this.demoModeConfigObject){
        this.contentClassName = this.demoModeConfigObject.contentClassName;
        this.relatedModelClassName = this.demoModeConfigObject.relatedModelClassName;
        this.relatedModelInstanceSlug = this.demoModeConfigObject.relatedModelInstanceSlug;
        this.tagTypeFilters = this.demoModeConfigObject.tagTypeFilters;
        this.contentClassName = this.demoModeConfigObject.contentClassName;
      }
      this.refreshCollapse();
      this.queryParams = {...this.route.snapshot.queryParams}; // alternative way to get params: this.router.routerState.snapshot.root.queryParams;
      if(this.queryParams['sort_by']){
        const sortable : SortCriterion = this.sort_criteria.find(sc=>sc.sort_by === this.queryParams['sort_by']);
        if(sortable){
          sortable.sort_order = ['asc','desc'].includes((this.queryParams['sort_order'] as string)) ? (this.queryParams['sort_order'] as string) : (sortable.default_sort_order as string);
        }
        this.setSortOrderParams(sortable);
      }
      this.initialise(this.relatedModelInstanceSlug,false);
      this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
      this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
        // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
        if (newActiveLanguage?.languageKey !== this.activeLanguageObject.languageKey){
          this.activeLanguageObject = newActiveLanguage;
          this.initialise(this.relatedModelInstanceSlug,true);
        }
      });
    }
  
    ngOnDestroy() {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
  
    }

}
