

<nav aria-label="Page navigation">
  <div class="pagination">
    <!-- <pagination [totalItems]="68" [rotate]="rotate" [maxSize]="maxSize"></pagination> -->
    <pagination
      ngClass="d-none"
      [totalItems]="meta.total"
      (numPages)="meta.last_page = $event"
    >
    </pagination>
    <div class="pagination-input-group">
      <div class="pull-left">
        <p class="mr-1" *transloco="let t">{{t('navigation.page')}}</p>
        <span class="input-group">
          <input
            type="text"
            class="form-control curent-page"
            value="{{ meta.current_page }}"
            aria-label="This is a current page"
          />
          <button class="btn-input-icon btn btn-primary btn-sm" type="button">
            <i class="fa-solid fa-angle-right"></i>
          </button>
        </span>
        <p *transloco="let t">
          {{t('navigation.page_x_of_y')}} <span>{{ meta.last_page }}</span>
        </p>
      </div>
      <div class="pull-right">
        <pager
          ngClass="pagination-control"
          [totalItems]="meta.total"
          [align]="true"
          [(ngModel)]="meta.current_page"
          (pageChanged)="pageChanged($event)"
          pageBtnClass="btn"
          [itemsPerPage]="10"
        >
        </pager>
      </div>
    </div>
  </div>
</nav>
