import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressIndicatorDoughnutComponent } from './progress-indicator-doughnut.component';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    ProgressIndicatorDoughnutComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,

  ],
  exports : [ProgressIndicatorDoughnutComponent]
})
export class ProgressIndicatorDoughnutModule { }
