import { Component, Input, OnInit } from '@angular/core';
import { Snapshot } from '../snapshot.model';
import { DatetimeProcessingService } from '../../../utilities/datetime-processing.service';


@Component({
  selector: 'multisite-snapshot-list-item',
  templateUrl: './snapshot-list-item.component.html',
  styleUrls: ['./snapshot-list-item.component.scss']
})
export class SnapshotListItemComponent implements OnInit {

  @Input() snapshot : Snapshot;
  imageTransformations : string ="c_fill,g_auto,h_240,w_800/"

  constructor(
    private datetimeProcessingService : DatetimeProcessingService
  ) { }

  isDateWithinXDays(date: Date, x : number): boolean {
    return this.datetimeProcessingService.isDateWithinXDays(date,x);
   }

  ngOnInit(): void {

  }

}
