import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaceholderCardComponent } from './placeholder-card/placeholder-card.component';



@NgModule({
  declarations: [
    PlaceholderCardComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PlaceholderCardComponent
  ]
})
export class PlaceholdersModule { }
