<div class="single-news">

  <!-- Grid row -->
  <div class="row">

    <!-- Grid column -->
    <div class="col-md-3">

      <!--Image-->
      <div class="view overlay rounded z-depth-1 mb-md-0 mb-4">
        <frontend-media-image
            [classes]="'img-fluid'"
            [media]="media"
            [transformations]="imageTransformations"
          >
          </frontend-media-image>
        <a>
          <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
        </a>
      </div>

    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-md-9">

      <!-- Excerpt -->
      <h5 class="card-title">

        <a class="title-link">
          <span *ngIf="titleTextTranslation; else elseBlock1">
            <span *transloco="let t">
                <span> {{ t(titleTextTranslation) }} </span>
            </span>
          </span>
          <ng-template #elseBlock1>
            {{ titleText }}
          </ng-template>

        </a>


      </h5>
      <div class="d-flex justify-content-between">
        <div class="col-11 pl-0 mb-lg-3" [ngClass]="{'text-truncate': truncateLinkText}">
          <a class="dark-grey-text">
            <span *ngIf="linkTextTranslation; else elseBlock2">
              <span *transloco="let t">
                  <span> {{ t(linkTextTranslation) }} </span>
              </span>
            </span>
            <ng-template #elseBlock2>
              {{ linkText }}
            </ng-template>
  
          </a>
        </div>
        <div class="d-flex flex-column">
          <a class="my-auto">
            <i class="fas fa-angle-double-right"></i>
          </a>
        </div>
      </div>

    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->
  <hr class="mt-1" *ngIf="!hideBottomBorder">
</div>