import { Component, Input, OnInit, WritableSignal, input, signal } from '@angular/core';
import { ProcessStep } from '../process-step.model';
import { MediaService } from '../../media';

@Component({
  selector: 'multisite-process-visualisation-promo',
  templateUrl: './process-visualisation-promo.component.html',
  styleUrls: ['./process-visualisation-promo.component.scss']
})
export class ProcessVisualisationPromoComponent implements OnInit {

  // @Input() steps : ProcessStep[] = [];
  // steps = input<ProcessStep[]>([]);
  steps = input.required<ProcessStep[]>();
  thumbnailTransformations = input<string>('w_64/');
  addBackgroundToStandaloneIcons = input<boolean>(true);
  cloudinary_base_url : WritableSignal<string> = signal<string>(null);

  constructor(private mediaService : MediaService) {
    this.cloudinary_base_url.set(this.mediaService.cloudinary_base_url);
  }

  ngOnInit(): void {
  }

}
