import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { GalleryCtaComponent } from './gallery-cta.component';



@NgModule({
  declarations: [
    GalleryCtaComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ],
  exports: [
    GalleryCtaComponent
  ]
})
export class GalleryCtaModule { }
