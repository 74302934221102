import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingBasicComponent } from './rating-basic/rating-basic.component';
import { RatingModule as NgxRatingModule } from 'ngx-bootstrap/rating';
import { FormsModule } from '@angular/forms';

import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingSingleFactorWithResultsComponent } from './rating-single-factor-with-results/rating-single-factor-with-results.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ModalModule } from 'ngx-bootstrap/modal';



@NgModule({
  declarations: [
    RatingBasicComponent,
    RatingSingleFactorWithResultsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxRatingModule,
    TranslocoModule,
    ProgressbarModule,
    ModalModule,
  ],
  exports: [
    RatingBasicComponent,
    RatingSingleFactorWithResultsComponent,
  ]
})
export class RatingModule { }
