import { Component, OnInit } from '@angular/core';
import { Link } from '@frontend/core';
import { LinksService } from '@frontend/shared';
import { Subscription } from 'rxjs';
import { ContentItem } from '../../content';
import { PageTitleService } from '../../navigation/title/title.service';
import { Hub } from '../hub.model';
import { HubService } from '../hub.service';

@Component({
  selector: 'multisite-hubs-listing',
  templateUrl: './hubs-listing.component.html',
  styleUrls: ['./hubs-listing.component.scss']
})
export class HubsListingComponent implements OnInit {

  hubs : Hub[];
  hubsAsContentItems : ContentItem[];
  hubsSubscription : Subscription;
  linksSubscription : Subscription;
  loading : boolean;
  links: Link[] = [];

  constructor(
    private hubService : HubService,
    private pageTitleService : PageTitleService,
    private linksService: LinksService,
    ) { }

    getLinks(){
      let linksRequest = [
        {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
        {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
        {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      ]
      this.linksSubscription = this.linksService.getDynamicLinks(linksRequest).subscribe(links =>{
        this.links = links;
      })
    }

  getManagedHubs (){
    this.loading = true;
    this.hubsSubscription = this.hubService.getManagedHubs(false).subscribe(
      response => {
        this.hubsAsContentItems = this.hubService.makeContentItems(response)
        this.hubs = response;
        this.loading = false;
        this.getLinks();
      }
    )
  }

  ngOnDestroy () : void {
    if (this.hubsSubscription){
      this.hubsSubscription.unsubscribe();
    }
    if (this.linksSubscription){
      this.linksSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.getManagedHubs();
    this.pageTitleService.setTitleWithTranslationFirstConcatenation('navigation.hubs',null,null);
  }

}
