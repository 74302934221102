<div class="items">
  <accordion [isAnimated]="true" [closeOthers]="true">
    <accordion-group *ngFor="let itemGroup of itemGroups; let i = index" [isDisabled]="itemGroup.locked"
      [isOpen]="i === initiallyOpenItemGroupIndex">
      <div class="accordion-heading justify-content-between align-items-center d-flex w-100 shadow-none"
        accordion-heading>
        <div class="pull-left float-left item-group-title">
          <p class="title">
            {{itemGroup.name}}
          </p>
          <div class="group-info">
            <div class="items-sum" *ngIf="showItemGroupProgress">
              <span class="done">{{getTotalConsumed(itemGroup)+'/'+itemGroup.items?.length}}</span>
            </div>
            <span class="divider" *ngIf="showItemGroupProgress"></span>
            <div class="items-time-sum" *transloco="let t">
              {{convertMillisecondsToMinutes(itemGroup.duration)}}{{t('common.minutes_abbreviated')}}
            </div>
          </div>
        </div>
        <span class="float-right pull-right">
          <i class="fa-solid fa-lock" *ngIf="itemGroup.locked"></i>
          <i class="fa-solid fa-angle-down"></i>
        </span>
      </div>
      <div class="content-holder">

        <button class="btn item" *ngFor="let item of itemGroup?.items" (click)="onSelected(item)"
          [ngClass]="{'highlight': highlightItem?.identifier_string === item.identifier_string && highlightItem?.duration === item.duration}"
          [disabled]="highlightItem?.identifier_string === item.identifier_string && highlightItem?.duration === item.duration && disableHighlightItem">
          <div class="pull-left">
            <i class="{{item.completed || item.consumed ? 'fa-solid fa-circle-check' : 'fa-regular fa-circle'}}"
              [ngClass]="{'text-success': item.completed}"></i>
            <p>
              {{item.name}}
            </p>
          </div>
          <div class="pull-right" *transloco="let t">
            <i class="fa-solid fa-lock" *ngIf="item.locked"></i>
            <span class="badge badge-pill" [ngClass]="item.badge.classes" *ngIf="item.badge">{{ item.badge.text ?
              item.badge.text : t(item.badge.translationKey)}}</span>
            <span>{{convertMillisecondsToMinutes(item.duration)}}{{item.duration > minMillisecondsMinuteFormat ?
              t('common.minutes_abbreviated') : t('common.seconds_abbreviated')}}</span>
            <i class="fa-solid fa-circle-play"></i>
          </div>
        </button>
      </div>
      <hr>
    </accordion-group>
  </accordion>
</div>