<div *transloco="let t">
  <!-- <h4 class="h4 font-weight-bold">
    {{t('survey.snapshot_context_question')}}
  </h4> -->
  <form [formGroup]="snapshotForm" role="form" class="d-flex flex-column gap-1 gap-lg-3 create-snapshot-form">


    <div class="input-self">
      <!-- <label for="title" class="form-label"
        >{{ t('content_management.title_very_short')
        }}<span class="required-sign">*</span>
      </label> -->
      <div class="form-group" [ngClass]="{
          'ng-invalid':
            snapshotForm.get('title').invalid && snapshotForm.get('title').touched,
          'ng-valid':
            snapshotForm.get('title').valid &&
            snapshotForm.get('title').touched &&
            snapshotForm.get('title').dirty
        }">

        <label for="title">Title:</label>
        <input type="text" formControlName="title" id="title"
          placeholder="{{ 'content_management.title_very_short' | transloco }}" class="form-control"
          [disabled]="loading || disabled">

        <div *ngIf="
            snapshotForm.get('title').errors?.required &&
            snapshotForm.get('title').touched
          " class="invalid-feedback">
          {{ t('common.required') }}
        </div>
        <div *ngIf="
            snapshotForm.get('title').errors?.minlength &&
            snapshotForm.get('title').touched
          " class="invalid-feedback">
          {{ t('common.too_short') }}
        </div>
        <div *ngIf="
            snapshotForm.get('title').errors?.maxlength &&
            snapshotForm.get('title').touched
          " class="invalid-feedback">
          {{ t('common.too_long') }}
        </div>
      </div>
      <!-- <div class="form-text">
        {{ t('common.some_useful_info_nere') }}
      </div> -->
    </div>




    <div class="input-self">
      <!-- <label for="description" class="form-label"
        >{{ t('common.description')
        }}<span class="required-sign">*</span>
      </label> -->
      <div class="form-group" [ngClass]="{
          'ng-invalid':
            snapshotForm.get('description').invalid && snapshotForm.get('description').touched,
          'ng-valid':
            snapshotForm.get('description').valid &&
            snapshotForm.get('description').touched &&
            snapshotForm.get('description').dirty
        }">
        <label for="description">Description:</label>
        <textarea class="form-control" formControlName="description" id="description" rows="3"
          placeholder="{{ 'survey.snapshot_context_description_help' | transloco }}" [disabled]="loading || disabled">
      </textarea>

        <div *ngIf="
            snapshotForm.get('description').errors?.required &&
            snapshotForm.get('description').touched
          " class="invalid-feedback">
          {{ t('common.required') }}
        </div>
        <div *ngIf="
            snapshotForm.get('description').errors?.minlength &&
            snapshotForm.get('description').touched
          " class="invalid-feedback">
          {{ t('common.too_short') }}
        </div>
        <div *ngIf="
            snapshotForm.get('description').errors?.maxlength &&
            snapshotForm.get('description').touched
          " class="invalid-feedback">
          {{ t('common.too_long') }}
        </div>
      </div>
      <!-- <div class="form-text">
        {{ t('common.some_useful_info_nere') }}
      </div> -->
    </div>



    <div class="input-self" *ngIf="!hideCultureInputs">
      <div class="form-group" [ngClass]="{
        'ng-invalid':
        snapshotForm.get('cultures').invalid && snapshotForm.get('title').valid && snapshotForm.get('description').valid,
      }">

        <label for="cultures">{{ t('common.national_cultures')
          }}<span class="required-sign">*</span>
        </label>
        <culture-selector-type-one [cultures]="filterCultures(allCultures,'geographic','national')" [loading]="loading"
          (selected)="selectCulture($event)" (deselected)="deselectCulture($event)"
          [selectedCultures]="filterCultures(selectedCultures,'geographic','national')"
          [placeholderTranslationKey]="'common.relevant_national_cultures'"></culture-selector-type-one>

        <input type="text" formControlName="cultures" id="cultures" aria-hidden="true" [disabled]="true" class="d-none">

        <div *ngIf="
            snapshotForm.get('cultures').errors?.required && snapshotForm.get('title').valid && snapshotForm.get('description').valid,
          " class="invalid-feedback">
          {{ t('common.required') }}
        </div>
      </div>
      <!-- <div class="form-text">
        {{ t('common.some_useful_info_nere') }}
      </div> -->
    </div>

    <alert [type]="'warning'" [dismissOnTimeout]="5000" *ngIf="error"><span *transloco="let t">{{t(error)}}</span>
    </alert>
    <div class="d-flex flex-column align-items-end gap-2 mt-2" *ngIf="!hideCultureInputs">
      <multisite-loader-globe-animation-detail *ngIf="loading" width="40" height="40"
        class="d-inline-block align-middle"></multisite-loader-globe-animation-detail>
      <span class="w-100" *ngIf="showChoosePrimaryCultureOptions">
          <h6 class="font-weight-bold" *transloco="let t">{{t('survey.snapshot_primary_culture_question')}}</h6>
          <p *transloco="let t">{{t('survey.snapshot_primary_culture_info')}}</p>
          <p class="font-weight-bold mb-2 mt-3" >{{t('survey.snapshot_primary_choose')}}:</p>
          <div class="d-flex align-items-center gap-2 flex-wrap">
            <a *ngFor="let culture of selectedCultures" (click)="setPrimaryCulture(culture)">
              <span class="flag tooltip-custom">
                <img [src]="getFlagUrl(culture,'w_24,c_fill,ar_1:1,r_max,f_auto/','.png')"
                  [ngClass]="{primary: culture.id === primary_culture_id}" [alt]="culture.name" class="mr-1">
                <p *ngIf="culture.id !== primary_culture_id" class="tooltiptext-custom-top">{{culture.name}}</p>
                <p class="name">{{culture.name}}</p>
                <i class="icon fa-solid fa-check"></i>
              </span>

            </a>
          </div>

      </span>
      <button type="button" class="btn btn-sm btn-primary" *ngIf="!hideCultureInputs" (click)="submitData()"
        [disabled]="snapshotForm.invalid">
        <span
          *ngIf="selectedCultures.length > 1 && (primary_culture_id !== 0 && !primary_culture_id)">{{t('common.continue')}}</span>
        <span
          *ngIf="selectedCultures.length < 2 || (primary_culture_id === 0 || primary_culture_id)">{{t('common.submit')}}</span>
      </button>
    </div>
  </form>

  <hr>

  <h5 class="h5 font-weight-bold">{{t('survey.snapshot_context_good_examples')}}</h5>
  <div class="carousel py-3" style="height: max-content;">
    <div *ngFor="let example of exampleSnapshotContent; let i = index" [@carouselAnimation]="i === exampleCurrentIndex">
      <div class="card" *ngIf="i === exampleCurrentIndex">
        <div class="card-body p-4">
          <div class="title-group flag-align-center">
            <h5 class="card-title font-weight-bold">{{t(exampleSnapshotContent[i]?.title)}}</h5>
            <span class="icons-holder" *ngIf="exampleSnapshotContent[i]?.cultures.length">
              <span *ngFor="let culture of exampleSnapshotContent[i].cultures" class="icons-holder tooltip-custom">
                <img [src]="'https://cultureconnector.com/app/images/flags/'+culture.iso+'.png'" [alt]="culture.name">
                <p class="tooltiptext-custom-bottom">{{culture.name}}</p>
              </span>
            </span>
          </div>
          <p class="card-text mt-2">
            <!-- <span class="text-muted mr-1" *transloco="let t">{{t('survey.snapshot_context')}}</span> -->
            {{t(exampleSnapshotContent[i]?.description)}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>