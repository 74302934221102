import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, computed, signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, User } from '../../../auth';
import { ConsentService } from '../consent.service';
import { Consent } from '../consent.model';
import { AvailableLanguage, LanguageService } from '../../../language';
import { ConsentSet, ConsentStatus, ConsentStatusAttributeKey } from '../consent-set.model';
import { DataProcessingService } from '../../../utilities';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'user-account-consent',
  templateUrl: './user-account-consent.component.html',
  styleUrls: ['./user-account-consent.component.scss']
})
export class UserAccountConsentComponent implements OnInit, OnDestroy {

  activeLanguageObject = signal<AvailableLanguage | undefined>(undefined);
  subscriptions = signal<Subscription[]>([]);
  loadingObject = signal <{[key:string]:boolean}>({}); // .consents
  consents = this.consentService.consents;
  currentConsentSet = signal<ConsentSet|undefined>(undefined);
  showCurrentConsentSetMoreInfo = signal<boolean>(false);
  showingDeclineWarning = signal<boolean>(false);
  activityTimeStampAttributesUser = this.consentService.activityTimeStampAttributesUser;
  selectedFilterOption = signal<string|undefined>(undefined);
  filteredConsents = computed(() => {
    return this.consents() ? this.consents().filter(consent => this.selectedFilterOption() ? consent.category === this.selectedFilterOption() : true) :[];
  });
  displayConsents = computed(() => {
    const consentSets : ConsentSet[] = this.makeConsentSets(this.filteredConsents());
    const needingAttentionConsentSets = consentSets.filter(consentSet => {
      const latestConsent = consentSet.latest;
      return this.activityTimeStampAttributesUser().every(attribute => latestConsent[attribute] === null);
    });
    const userHasBeenActiveConsentSets = consentSets.filter(consentSet => {
      const latestConsent = consentSet.latest;
      return this.activityTimeStampAttributesUser().some(attribute => latestConsent[attribute] !== null);
    });
    return [...needingAttentionConsentSets, ...userHasBeenActiveConsentSets];
  });
  termIds = computed(() => {
    return this.consents() ? this.getTermIds(this.consents()) : [];
  });
  filterOptions = computed(() => {
    return this.consents() ? this.consents().map(consent => consent.category).filter((value, index, self) => self.indexOf(value) === index) : [];
  });
  user = signal<User|undefined>(undefined);
  error = signal<string|{message:string,meta:any}|undefined>(undefined);
  // slugs = signal<string[]>([]);
  @ViewChild('declineModal') declineModal;
  modalRef?: BsModalRef;

  constructor(
    private languageService: LanguageService,
    private consentService: ConsentService,
    private authService: AuthService,
    private dataProcessingService: DataProcessingService,
    private modalService: BsModalService,
  ) {}

  closeModal(){
    if (this.modalRef){
      this.modalRef.hide();
    }
    this.showingDeclineWarning.set(false);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.onHidden.subscribe((reason : string | any) => {
      this.showingDeclineWarning.set(false);
    });
  }

  getTermIds(consents: Consent[]) : number[] {
    return consents.map(consent => consent.term_id).filter((value, index, self) => self.indexOf(value) === index);
  }

  mapConsentStatuses(consents: Consent[]){
    const consentStatuses : ConsentStatus[] = [];
    consents.forEach(consent => {
      this.activityTimeStampAttributesUser().forEach(attribute => {
        if (consent[attribute]){
          consentStatuses.push(
            {
              attributeKey: attribute as ConsentStatusAttributeKey,
              dateStamp: consent[attribute],
              date: new Date(consent[attribute]),
              principal: consent.principal,
            }
          );
        }
      });
    });
    return this.dataProcessingService.sortArrayOfObjectsByProperty(consentStatuses, 'dateStamp');
  }

  makeConsentSets(consents: Consent[]): ConsentSet[]{
    const termIds = this.getTermIds(consents);
    return termIds.map(termId => this.makeConsentSet(consents.filter(consent => consent.term_id === termId)));
  }
  makeConsentSet(consents: Consent[]): ConsentSet{
    const sortedConsents = this.consentService.sortByLatestActivity(consents);
    const latest = sortedConsents[0];
    const history = sortedConsents.slice(1);
    const statuses = this.mapConsentStatuses(consents);
    return {latest, history, statuses, hideMoreInfo: true, collapsed: true};
  }

  isAnythingLoading(){
    for (let something in this.loadingObject()){
      if (this.loadingObject()[something]){
        return true;
      }
    }
    return false;
  }

  getConsents(freshFromServer: boolean){
    this.error.set(null);
    this.loadingObject()['consents'] = true;

    const consentSubscription = this.consentService.getConsents(freshFromServer).subscribe(
      (consents) => {
        // this.consents.set(consents);
        this.loadingObject()['consents'] = false;
      },
      (error) => {
        this.loadingObject()['consents'] = false;
      }
    );

    this.subscriptions().push(consentSubscription);

  }

  updateConsentStatus(consentSet: ConsentSet, status: 'given' | 'declined' | 'withdrawn'){
    if(!this.showingDeclineWarning() && (status === 'declined' || status === 'withdrawn')){
      this.showDeclineWarning(consentSet);
    } else {
      this.handleConsentStatusUpdate(consentSet.latest, status);
    }
  }
  handleConsentStatusUpdate(consent: Consent, status: 'given' | 'declined' | 'withdrawn'){
    this.error.set(null);
    this.loadingObject()['consents'] = true;
    const consentSubscription = this.consentService.updateConsentStatus(consent, status).subscribe(
      (updatedConsent) => {
        this.loadingObject()['consents'] = false;
        this.closeModal();
      },
      (error) => {
        this.loadingObject()['consents'] = false;
        this.error.set(error);
      }
    );
    this.subscriptions().push(consentSubscription);
  }

  showDeclineWarning(consentSet: ConsentSet){
    this.showingDeclineWarning.set(true);
    this.currentConsentSet.set(consentSet);
    this.openModal(this.declineModal); // Important! Make sure you don't open the model when it is already open. Causes horrible bugs.
  }


  ngOnInit(): void {
    this.authService.user.subscribe(user => this.getConsents(false));
    this.activeLanguageObject.set(this.languageService.activeLanguageObjectSynchronously);
    const activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
        // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
        if (this.activeLanguageObject() && newActiveLanguage?.languageKey !== this.activeLanguageObject().languageKey){
          this.activeLanguageObject.set(newActiveLanguage);
          this.getConsents(true);
        }
    });

    // DEV - For testing
    // this.subscriptions().push(activeLanguageSubscription);
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe(event => {
    //   console.log(event);
    // });
    // this.route.paramMap.subscribe(params => {
    //   const slugs = params.get('slugs');
    //   if (slugs) {
    //     this.slugs.set(slugs.split(','));
    //     // Now you have an array of slugs that you can use to display the consents
    //     if (this.slugs().length){
    //       let thing = this.slugs();
    //     }
    //   }
    // });
    // END OF DEV - For testing
  }

  ngOnDestroy(): void {
    this.subscriptions().forEach(subscription => subscription.unsubscribe());
  }

}
