import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CloudinaryMediaAsset } from '../../../models';
import { ContentItem } from '../../content-item.model';
import { MediaService } from '../../media';

@Component({
  selector: 'multisite-carousel-card-quotations',
  templateUrl: './carousel-card-quotations.component.html',
  styleUrls: ['./carousel-card-quotations.component.scss']
})
export class CarouselCardQuotationsComponent implements OnChanges {


  @Input() content: ContentItem[];
  @Input() ratingMax: number;
  @Input() title_text : string; // 'Tips'
  @Input() title_translationKey : string; // 'common.tips'
  @Input() loading : boolean; // 'content.go' 
  currentIndex : number;
  minMillisecondsMinuteFormat = 59000; // highest number of milliseconds before we start to round to the nearest minute

  constructor(private mediaService : MediaService) { }

  convertMillisecondsToMinutes (milliseconds:number){
    
    return milliseconds > this.minMillisecondsMinuteFormat ? Math.round(milliseconds/60000) : ((milliseconds/100000) % 60).toFixed(2).slice(-2);
  }

  get avatarUrl (){
    return this.content[this.currentIndex].user.picture ?? this.mediaService.fallback_avatar_url;
  }

  updateIndex (currentIndex){
    this.currentIndex = currentIndex+1;
  }
  next(){
    this.currentIndex = this.currentIndex >= this.content.length - 1 ? 0:this.currentIndex+1;
  }
  previous(){
    this.currentIndex = this.currentIndex === 0 ? this.content.length - 1 :this.currentIndex-1;
  }

  ngOnChanges(changesObject): void {

    if(changesObject.content?.currentValue?.length){
      this.currentIndex = 0;
    }
    
  }


}
