<span class="bar-parent" [style]="
'--bar-background:'+barBackground+
';--bar-width:'+barWidth+
';--bar-position:'+barPosition+
';--bar-min-width:'+barMinWidth
">
  <span class="indicator" [style]="'--score-value:'+progressPercent+'%'">
    <i class="fa-solid fa-triangle" [style]="'--indicator-color:'+indicatorColorStyle"></i>
  </span>
  <div class="bar"></div>
</span>