import { PlaylistTextItem } from "./playlist-text-item.model";

export class PlaylistTextItemGroup {

    constructor (
        public name: string,
        public duration: number,
        public locked: boolean,
        public items: PlaylistTextItem[]
    ){
        this.name = name;
        this.duration = duration;
        this.locked = locked;
        this.items = items;
    }
    
    
}