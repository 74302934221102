import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataProcessingService } from '../../../utilities/data-processing.service';
import { ContentItem } from '../../content-item.model';
import { MediaService } from '../../media';
import { Subscription } from 'rxjs';
import { VideoService } from '../../video';
import { Video } from '@frontend/core';

@Component({
  selector: 'multisite-carousel-card',
  templateUrl: './carousel-card.component.html',
  styleUrls: ['./carousel-card.component.scss']
})
export class CarouselCardComponent{


  @Input() content: ContentItem[];
  @Input() ratingMax: number;
  @Input() imageTransformations : string; // 'w_275,ar_16:9/'
  @Input() title_text : string; // 'Tips'
  @Input() title_translationKey : string; // 'common.tips'
  @Input() cta_text : string; // 'Go to Tip' 
  @Input() cta_translationKey : string; // 'content.go' 
  @Input() loading : boolean; // 'content.go' 
  @Output() actionTriggered = new EventEmitter<ContentItem>();
  currentIndex : number = 0;
  minMillisecondsMinuteFormat = 59000; // highest number of milliseconds before we start to round to the nearest minute
  videoThumbnailUrl : string;
  videoThumbnailSubscription : Subscription;
  videoThumbnailLoading : boolean;

  constructor(
    private dataProcessingService : DataProcessingService,
    private mediaService : MediaService,
    private videoService: VideoService,
  ) { }

  convertMillisecondsToMinutes (milliseconds:number){
    
    return this.dataProcessingService.convertMillisecondsToMinutes(milliseconds, this.minMillisecondsMinuteFormat)

  }
  get thumbnailUrl(){
    return this.videoThumbnailUrl ?? this.getMediaUrl(this.content?.[this.currentIndex]);
  }

  getMediaUrl (item){
    return item.media ?  (item.media?.asset_url && this.imageTransformations ? item.media.base_url + this.imageTransformations + item.media.asset_url : item.media.file_url) : this.mediaService.fallback_scene_url;
  }
  getVideoThumbnail(video : Video){ // The video thumbnail logic needs refactoring. Too much duplication with other places where we use a video thumbnail, because we cannot set it synchronously when we create the content item.
    let solvedVimeoCORSproblem = false;
    if (solvedVimeoCORSproblem){
      this.videoThumbnailLoading = true;
      this.videoThumbnailSubscription = this.videoService.getVideoThumbnailAsynchronously(video).subscribe(thumbnail =>{
        this.videoThumbnailUrl = thumbnail;
        this.videoThumbnailLoading = false;
  
      },
      error => this.videoThumbnailLoading = false
      )
    } else {
      this.videoThumbnailUrl = this.videoService.getVideoThumbnailSynchronously(video);
    }
  };
  updateVideoThumbnail (){
    if(this.content[this.currentIndex].video){
      this.getVideoThumbnail(this.content[this.currentIndex].video);
    } else {
      this.videoThumbnailUrl = null;
    }
  }

  next(){
    this.currentIndex = this.currentIndex >= this.content.length - 1 ? 0:this.currentIndex+1;
    this.updateVideoThumbnail();
  }
  previous(){
    this.currentIndex = this.currentIndex === 0 ? this.content.length - 1 :this.currentIndex-1;
    this.updateVideoThumbnail();
  }

  doAction(contentItem : ContentItem){
    this.actionTriggered.emit(contentItem);
  }
  
  ngOnChanges(changesObject): void {

    if(changesObject.content?.currentValue?.length){
      this.currentIndex = 0;
      this.updateVideoThumbnail();
    }
    
  }
  ngOnDestroy(): void {

    if(this.videoThumbnailSubscription){
      this.videoThumbnailSubscription.unsubscribe();

    }
    
  }

}
