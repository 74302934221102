import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { DataProcessingService } from '@frontend/common';

import { Media } from '@frontend/core';


@Component({
  selector: 'multisite-link-type-six',
  templateUrl: './link-type-six.component.html',
  styleUrls: ['./link-type-six.component.scss']
})
export class LinkTypeSixComponent implements OnChanges {
  // A visually strong link element, good for external links and Calls-To-Action (CTA)

  
  @Input() imageTransformations : string; // 'w_275,ar_16:9/'
  @Input() titleText : string;
  @Input() titleTextTranslation : string; // not in use yet
  @Input() description : string; // optional
  @Input() imageUrl : string; // if no media Object
  @Input() url : string; // target page
  @Input() cta_label : string; // Translated custom text
  @Input() cta_type : string; // generic action, translation key
  @Input() media : Media;
  @Input() descriptionTruncateLength : number = 110;
  @Output() linkClicked = new EventEmitter<Event>();

  constructor(
    private dataProcessingService : DataProcessingService,
  ) { }

  doLinkClick (event : Event){
    if (this.url){
      window.location.href = this.url;
    }
    this.linkClicked.emit(event);
  }

  ngOnChanges(changesObject): void {
    if (changesObject?.description?.currentValue?.length){
      if (changesObject.description.currentValue.length > this.descriptionTruncateLength){
        this.description = this.truncateText(changesObject.description.currentValue,this.descriptionTruncateLength-10);
      };
    }
  }

  truncateText(sourceText : string, length : number){
    return this.dataProcessingService.truncateText(sourceText, length);
  }

}
