import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuestImportComponent } from './guest-import.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TranslocoModule } from '@jsverse/transloco';
import { CollapseModule } from 'ngx-bootstrap/collapse';
// import { LoadersModule } from '@frontend/common'; // TODO - this causes an error; investigate



@NgModule({
  declarations: [
    GuestImportComponent
  ],
  exports: [
    GuestImportComponent
  ],
  imports: [
    CommonModule,
    // LoadersModule,
    AlertModule,
    TranslocoModule,
    CollapseModule
  ]
})
export class GuestImportModule { }
