import { Tag } from "@frontend/core";
import { ContentItem } from "../content";
import { CcoRecommendation } from "../cultureconnector";
import { Culture } from "../cultures";
import { Difference } from "../differences/difference.model";

export class CiResult {
    id: number; // 1,
    user_id: number; // 1,
    guest_id: number; // 1,
    snapshot_id: number; // 1,
    rater_adjusted: boolean; // false,
    total: number; // 55.52
    know: number; // 55.52
    values: number; // 55.52
    behaviour: number; // 55.52
    blindspots: number; // 55.52
    translations: number; // 55.52
    love: number; // 55.52
    internal: number; // 55.52
    external: number; // 55.52
    resilience: number; // 55.52
    mastery: number; // 55.52
    think: number; // 55.52
    preparing: number; // 55.52
    noticing: number; // 55.52
    reviewing: number; // 55.52
    expecting: number; // 55.52
    act: number; // 55.52
    performing: number; // 55.52
    reformulating: number; // 55.52
    countering_intuition: number; // 55.52
    authenticity: number; // 55.52
    range: number; // 55.52
    ambassadorial: number; // 55.52
    stereotypes: number; // 55.52
    growth: number; // 55.52
    certainty: number; // 55.52
    meta: {
        certainty_by_domain: {
            act: number; // 42
            know: number; // 42
            love: number; // 42
            think: number; // 42
        },
        ranges_by_cultural_difference_id : {
            cd_id : number, // 15 
            range : number[] // [20,45] // Cultural Difference id 15 has a range (width) of 20%; the range is centred on 45% (45th percentile of a 100-point scale); this means it starts on 35 and ends on 55.
            difference?: Difference
        }[],
        cco_recommendations: CcoRecommendation[],
        priorities_as_content_items ? : ContentItem[]
    };
    priorities : string[]; // ['values','authenticity']
    created_at: string; // "2023-12-06T07:09:44.000000Z"
    updated_at: string; // "2023-12-06T07:10:51.000000Z"
}