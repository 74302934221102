  <!-- Testing mixins -->
  <!-- <div>
    <h2 class="mt-3">Component styles</h2>

<div class="demoBox">
  <p>The Happy Component</p>
  <small>I am responsive and use mixins</small>
</div>

<div class="mt-3">
  <h5>I have a badge <span class="badge badge-primary">New</span></h5>
  <p>I am overriding Bootstrap styles at component level</p>
</div>
  </div> -->


  <h2 class="mt-3">Text</h2>

  <h3>Default paragraph with default links</h3>

  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    <a href="#">default Link</a> quis nostrud exercitation ullamco laboris nisi
    ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
    in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
    sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
    mollit anim <a href="#">default Link</a> id est laborum.
  </p>
  <hr />
  <h4>Minor heading</h4>
  <p class="text">
    Arcu tortor, dui dictum orci tincidunt dignissim aliquet amet. Malesuada eu
    erat facilisis ultrices elit at ac euismod ultrices. Neque arcu nec et
    consequat type.
  </p>
  <p class="text">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minus corporis
    repellat facilis <a href="">important link</a> Repudiandae ratione velit non
    unde pariatur fugiat, natus eligendi vitae cupiditate tempora quam
    laudantium possimus quae dolores aliquid!
  </p>
  <p>
    Lorem, ipsum dolor <a href="">also significant</a> sit amet consectetur
    adipisicing elit. Voluptatum labore eum porro vel, inventore aspernatur
    rerum beatae, delectus tenetur error officiis reiciendis alias dolorum
    praesentium sequi vero consequuntur sint dicta!
  </p>

  <h2 class="mt-3">Buttons</h2>
  <h4>Standard buttons:</h4>
  <button type="button" class="btn btn-primary">Primary</button>
  <button type="button" class="btn btn-secondary">Secondary</button>
  <button type="button" class="btn btn-success">Success</button>
  <button type="button" class="btn btn-danger">Danger</button>
  <button type="button" class="btn btn-warning">Warning</button>
  <button type="button" class="btn btn-info">Info</button>
  <button type="button" class="btn btn-light">Light</button>
  <button type="button" class="btn btn-dark">Dark</button>
  <button type="button" class="btn btn-link">Link</button>
  <br />
  <hr />
  <h4>Buttons disabled:</h4>
  <button type="button" class="btn btn-primary" disabled>Primary</button>
  <button type="button" class="btn btn-secondary" disabled>Secondary</button>
  <button type="button" class="btn btn-success" disabled>Success</button>
  <button type="button" class="btn btn-danger" disabled>Danger</button>
  <button type="button" class="btn btn-warning" disabled>Warning</button>
  <button type="button" class="btn btn-info" disabled>Info</button>
  <button type="button" class="btn btn-light" disabled>Light</button>
  <button type="button" class="btn btn-dark" disabled>Dark</button>
  <button type="button" class="btn btn-link" disabled>Link</button>
  <br />
  <hr />
  <h4>Buttons with icon:</h4>
  <button type="button" class="btn btn-primary">
    Primary <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-secondary">
    Secondary <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-success">
    Success <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-danger">
    Danger <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-warning">
    Warning <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-info">
    Info <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-light">
    Light <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-dark">
    Dark <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-link">
    Link <i class="fa-solid fa-arrow-right"></i>
  </button>
  <br />
  <hr />
  <h4>Buttons with icon disabled:</h4>
  <button type="button" class="btn btn-primary" disabled>
    Primary <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-secondary" disabled>
    Secondary <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-success" disabled>
    Success <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-danger" disabled>
    Danger <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-warning" disabled>
    Warning <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-info" disabled>
    Info <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-light" disabled>
    Light <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-dark" disabled>
    Dark <i class="fa-solid fa-arrow-right"></i>
  </button>
  <button type="button" class="btn btn-link" disabled>
    Link <i class="fa-solid fa-arrow-right"></i>
  </button>
  <br />
  <hr />
  <h4>Outline:</h4>
  <button type="button" class="btn btn-outline-primary">Primary</button>
  <button type="button" class="btn btn-outline-secondary">Secondary</button>
  <button type="button" class="btn btn-outline-success">Success</button>
  <button type="button" class="btn btn-outline-danger">Danger</button>
  <button type="button" class="btn btn-outline-warning">Warning</button>
  <button type="button" class="btn btn-outline-info">Info</button>
  <button type="button" class="btn btn-outline-light">Light</button>
  <button type="button" class="btn btn-outline-dark">Dark</button>
  <br />
  <hr />
  <h4>Outline disabled:</h4>
  <button type="button" class="btn btn-outline-primary" disabled>
    Primary
  </button>
  <button type="button" class="btn btn-outline-secondary" disabled>
    Secondary
  </button>
  <button type="button" class="btn btn-outline-success" disabled>
    Success
  </button>
  <button type="button" class="btn btn-outline-danger" disabled>Danger</button>
  <button type="button" class="btn btn-outline-warning" disabled>
    Warning
  </button>
  <button type="button" class="btn btn-outline-info" disabled>Info</button>
  <button type="button" class="btn btn-outline-light" disabled>Light</button>
  <button type="button" class="btn btn-outline-dark" disabled>Dark</button>

  <h2 class="mt-3">Alerts</h2>

  <alert [type]="'success'" class="my-2">
    <strong class="mr-1">Success </strong>
    This appears when something good happens.
    <p>This text should be easy to read</p>
  </alert>

  <alert [type]="'info'" class="my-2">
    <strong class="mr-1">Info </strong>
    This appears when we need to communicate some neutral information.
    <p>This text should be easy to read</p>
  </alert>

  <alert [type]="'warning'" class="my-2">
    <strong class="mr-1">Warning </strong>
    This appears when some error or other problem happens.
    <p>This text should be easy to read</p>
  </alert>

  <alert [type]="'danger'" class="my-2">
    <strong class="mr-1">Danger </strong>
    This appears when there is a serious risk or error.
    <p>This text should be easy to read</p>
  </alert>

  <h2 class="mt-3">Mega buttons</h2>

  <div class="row">
    <div class="col-md-6 mt-2">
      <button class="btn button-wide btn-warning">
        <p>Warning button</p>
        <i class="fa-solid fa-angle-right"></i>
      </button>
    </div>
    <div class="col-md-6 mt-2">
      <button class="btn button-wide btn-primary">
        <p>Primary button</p>
        <i class="fa-solid fa-angle-right"></i>
      </button>
    </div>
  </div>

  <h2 class="mt-3">Feed</h2>

  <div class="row mt-3 feed-with-filters">
    <div class="col-md-12">
      <div class="feed-card-holder">
        <multisite-card-cta-horizontal
          [content]="demoCardContent"
          [imageTransformations]="'w_275,ar_16:9/'"
          >
        </multisite-card-cta-horizontal>
      </div>
    </div>
  </div>

  <h2 class="mt-3">Comments</h2>
  <div class="comments">
    <p class="h3 my-5">Comments</p>
    <div class="comment">
      <div class="img-holder">
        <img
          src="https://i.pravatar.cc/300"
          alt="Alexander Howells"
        />
      </div>
      <div class="content-holder">
        <div class="d-flex flex-direction-row justify-content-between">
          <p class="name">Robin Fisk</p>
          <a href="#"><i class="fa-solid fa-pen"></i> Edit</a>
        </div>
        <p class="text">
          Arcu tortor, dui dictum orci tincidunt dignissim aliquet amet.
          Malesuada eu erat facilisis ultrices elit at ac euismod ultrices.
          Neque arcu nec et consequat type.
        </p>
        <div class="actions">
          <div class="left">
            <a class="like" href="#">
              <i class="fa-regular fa-thumbs-up"></i> Like
            </a>
            <a class="replay" href="#"> Replay </a>
            <p class="time"><span class="value">3</span>h ago</p>
            <p class="likes"><span class="value">22</span> likes</p>
          </div>
          <div class="right">
            <button type="button" class="w-100 toggle-replies">
              (<span class="value">2</span>) Hide replies<i
                class="ml-2 fa-solid fa-angle-up"
              ></i>
            </button>
          </div>
        </div>
        <hr />
        <div class="replies" id="collapseReplies">
          <!-- Comment -->
          <div class="comment">
            <div class="img-holder">
              <img
                src="https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Alexander Howells"
              />
            </div>
            <div class="content-holder">
              <p class="name">Alexander Howells</p>
              <p class="text">
                Arcu tortor, dui dictum orci tincidunt dignissim aliquet amet.
                Malesuada eu erat facilisis ultrices elit at ac euismod
                ultrices. Neque arcu nec et consequat type.
              </p>
              <div class="actions">
                <div class="left">
                  <a class="like" href="#">
                    <i class="fa-regular fa-thumbs-up"></i> Like
                  </a>
                  <a class="replay" href="#"> Replay </a>
                  <p class="time"><span class="value">3</span>h ago</p>
                  <p class="likes"><span class="value">22</span> likes</p>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <!-- Comment -->
          <div class="comment">
            <div class="img-holder">
              <img
                src="https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Alexander Howells"
              />
            </div>
            <div class="content-holder">
              <p class="name">Alexander Howells</p>
              <p class="text">
                Arcu tortor, dui dictum orci tincidunt dignissim aliquet amet.
                Malesuada eu erat facilisis ultrices elit at ac euismod
                ultrices. Neque arcu nec et consequat type.
              </p>
              <div class="actions">
                <div class="left">
                  <a class="like" href="#">
                    <i class="fa-regular fa-thumbs-up"></i> Like
                  </a>
                  <a class="replay" href="#"> Replay </a>
                  <p class="time"><span class="value">3</span>h ago</p>
                  <p class="likes"><span class="value">22</span> likes</p>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <!-- Comment Submit -->
          <div class="comment">
            <div class="img-holder">
              <img
                src="https://i.pravatar.cc/300"
                alt="Alexander Howells"
              />
            </div>
            <div class="content-holder">
              <p class="name">Robin Fisk</p>
              <form action="" method="post">
                <div class="d-flex flex-column">
                  <textarea
                    placeholder="Your comment"
                    class="form-control"
                    rows="3"
                  ></textarea>
                  <input
                    type="submit"
                    class="btn btn-primary align-self-end mt-2 text-capitalize btn-sm"
                    value="Comment"
                  />
                </div>
              </form>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="mt-3">Base styles</h2>

    <h3>Headings</h3>

    <h1 class="display-1">Display 1</h1>
    <h1 class="display-2">Display 2</h1>
    <h1 class="display-3">Display 3</h1>
    <h1 class="display-4">Display 4</h1>
    <h1 class="display-5">Display 5</h1>
    <h1 class="display-6">Display 6</h1>

    <hr />
    <h1>h1 element Bootstrap heading</h1>
    <h2 class="mt-3">h2 element Bootstrap heading</h2>
    <h3>h3 element Bootstrap heading</h3>
    <h4>h4 element Bootstrap heading</h4>
    <h5>h5 element Bootstrap heading</h5>
    <h6>h6 element Bootstrap heading</h6>
    <hr />
    <p class="h1">h1 style Bootstrap heading</p>
    <p class="h2">h2 style Bootstrap heading</p>
    <p class="h3">h3 style Bootstrap heading</p>
    <p class="h4">h4 style Bootstrap heading</p>
    <p class="h5">h5 style Bootstrap heading</p>
    <p class="h6">h6 style Bootstrap heading</p>
    <hr />

    <h3>Lists</h3>


    <ul class="list-group mb-3">
      <li class="list-group-item">A first item</li>
      <li class="list-group-item">A second item</li>
      <li class="list-group-item">A third item</li>
      <li class="list-group-item active" aria-current="true">An active item</li>
      <li class="list-group-item">And a fifth one</li>
    </ul>

    <div class="list-group mb-3">
      <a (click)="demoDialog()" class="list-group-item list-group-item-action active" aria-current="true">
        The current clickable item
      </a>
      <a (click)="demoDialog()" class="list-group-item list-group-item-action">A second clickable link item</a>
      <a (click)="demoDialog()" class="list-group-item list-group-item-action">A third clickable link item</a>
      <a (click)="demoDialog()" class="list-group-item list-group-item-action">A fourth clickable link item</a>
      <a class="list-group-item list-group-item-action disabled">A disabled link item</a>
    </div>

    <div class="list-group">
      <button (click)="demoDialog()" type="button" class="list-group-item list-group-item-action">A first button item</button>
      <button (click)="demoDialog()" type="button" class="list-group-item list-group-item-action active" aria-current="true">
        The current button
      </button>
      <button (click)="demoDialog()" type="button" class="list-group-item list-group-item-action">A third button item</button>
      <button (click)="demoDialog()" type="button" class="list-group-item list-group-item-action">A fourth button item</button>
      <button (click)="demoDialog()" type="button" class="list-group-item list-group-item-action" disabled>A disabled button item</button>
    </div>

    <h3>Input</h3>

    <div class="row">
      <div class="col-md-6 offset-md-3">
        <form action="" method="post">
          <div *ngIf="showSuccessAlert" class="">
            <alert
              [type]="'success'"
              [dismissible]="true"
              (onClosed)="onClosed('warning')"
            >
              <strong>The request was successfully sent!</strong> Check your
              email inbox.
            </alert>
          </div>
          <!-- Organisation* -->
          <div class="input-self">
            <label for="e-mail" class="form-label"
              >Organisation<span class="required-sign">*</span></label
            >
            <div class="input-group mb-3">
              <span class="input-group-text bg-primary">
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </span>
              <input
                type="email"
                class="form-control"
                id="e-mail"
                placeholder="Enter your organisation"
                required
              />
            </div>
          </div>
          <!-- Name* -->
          <div class="input-self">
            <label for="name" class="form-label"
              >Name<span class="required-sign">*</span>
            </label>
            <div class="input-group mb-3">
              <span class="input-group-text">
                <i class="fa fa-person" aria-hidden="true"></i>
              </span>
              <input
                type="email"
                class="form-control"
                disabled
                id="name"
                placeholder="Enter your name here"
                required
              />
            </div>
          </div>
          <!-- E-mail* -->
          <div class="input-self">
            <label for="e-mail" class="form-label"
              >E-mail<span class="required-sign">*</span>
            </label>
            <div class="input-group mb-3">
              <span class="input-group-text bg-danger">
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </span>
              <input
                type="email"
                class="form-control is-invalid"
                id="e-mail"
                placeholder="example@email.com"
                required
              />
              <div class="invalid-feedback">Please enter your email!</div>
            </div>
          </div>
          <!-- Password* -->
          <div class="input-self">
            <label for="password" class="form-label"
              >Password<span class="required-sign">*</span></label
            >
            <div class="input-group mb-3">
              <span class="input-group-text bg-success">
                <i class="fa fa-unlock-alt" aria-hidden="true"></i>
              </span>
              <input
                type="password"
                class="form-control is-valid"
                id="password"
                placeholder="Enter your password"
                required
              />
              <div class="valid-feedback">Your password is correct!</div>
            </div>
          </div>
          <!-- Terms of use | Checkbox -->
          <div class="input-self">
            <div class="form-check">
              <div class="d-block">
                <div class="w-50 d-inline">
                  <a
                    class="float-right text-decoration-underline"
                    routerLink="#"
                    >Major link</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Sign In | Button -->
          <div class="input-self">
            <div class="input-group my-3">
              <button class="btn-primary w-100 py-2">Submit</button>
            </div>
          </div>
          <!-- Link to login -->
          <p class="text-center ">
            Need more info? <a routerLink="/design/05">Minor link</a>
          </p>
        </form>
      </div>
    </div>
    <hr />
  </div>
