import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SurveyHomeComponent } from './survey-home/survey-home.component';
import { QuestionModeHomeTypeOneComponent } from './survey-questions/question-mode-home-type-one/question-mode-home-type-one.component';
import { SurveyListingComponent } from './survey-listing/survey-listing.component';
import { SurveyLanguagePreferencesComponent } from './survey-language-preferences';

const routes: Routes = [
  {
    path: '',
    component: SurveyListingComponent,
  },
  {
    path: ':survey',
    data: { label: 'Survey homepage', translationKey: null, labelSource: 'titleService'},
    children: [
      {
        path: '',
        component: SurveyHomeComponent,
      },
      {
        path: 'questions',
        component: QuestionModeHomeTypeOneComponent,
        data: { label: 'Questions', translationKey: 'content.questions', labelSource: null,
        },
      },
      {
        path: 'language',
        component: SurveyLanguagePreferencesComponent,
        data: { label: 'Language', translationKey: 'navigation.language_preferences', labelSource: null
        },
      },
      {
        path: 'feedback',
        loadChildren: () =>
          import('@frontend/common').then((m) => m.SurveyFeedbackModule),
        data: { label: 'Feedback', translationKey: 'content.feedback', labelSource: null,},
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SurveyRoutingModule { }
