import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@frontend/common'; // Note, for some reason this import causes errors when imported from relative path'../auth';
import { DataProcessingService } from '../utilities/data-processing.service';
import { Experience } from './experience.model';
import { Progressable } from './progressable.model';

export interface ActivityLogParams {
    model: string // 'lesson',
    identifier : string, // 'intro-to-cco',
    identifier_type : string, //  'slug',
    activity_type : string, // 'viewed',
    properties : any
}

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  experiences : Experience[];  
  private totalProgressWeighted: BehaviorSubject<number>;
  public totalProgress: Observable<number>; // total progress for all the interventions in the Programme
  private _progressables: BehaviorSubject<Progressable[]>;
  public progressables: Observable<Progressable[]>;
  constructor(
    private http: HttpClient,
    private dataProcessingService: DataProcessingService,
    private authService: AuthService
    ) {
      this.totalProgressWeighted = new BehaviorSubject<number>(0);
      this.totalProgress = this.totalProgressWeighted.asObservable();
      this._progressables = new BehaviorSubject<Progressable[]>([]);
      this.progressables = this._progressables.asObservable();
      this.experiences = [];
  };
  clearData (){
    this.experiences = [];
    this.totalProgressWeighted.next(0);
    this._progressables.next([]);
  }
  calculateTotalProgress (progessExperiences : Experience[]){
    let result = 0;
    if (progessExperiences?.length){

      let totalActionsMax : number = progessExperiences.reduce((accumulator,currentValue)=>{
        return accumulator + (currentValue.meta?.actions_count_weighted > 0 ? currentValue.meta.actions_count_weighted : currentValue.meta?.actions_count ? currentValue.meta?.actions_count : 0)},
      0);
      let totalWeightedActionsDone : number = progessExperiences.reduce((accumulator,currentValue)=>{
        return accumulator + 
          (currentValue.meta?.actions_count_weighted > 0 ? // if there is a weighting
            (currentValue.experience_value/100) * (currentValue.meta.actions_count_weighted) : // use the weighted actions count
            currentValue.meta?.actions_count ? // otherwise as long as we have the unweighted actions count
            (currentValue.experience_value/100) * currentValue.meta.actions_count : // use that
             0 // and if the unweighted actions count is missing, use 0, just to prevent errors
            )
        },
      0)
      result = (totalWeightedActionsDone/totalActionsMax)*100;
    };
    this.totalProgressWeighted.next(Math.round(result));
    return Math.round(result);
  }
  removeDeletedExperience(modelNameSingular : string, model_id : number, experience_verb: string){

    let backendClassNameWithPath : string;

    if (modelNameSingular){
      backendClassNameWithPath = this.dataProcessingService.convertModelNameToBackendClass(modelNameSingular);
    }

    let cachedExperienceIndex = this.getCachedExperienceIndex (null, modelNameSingular, backendClassNameWithPath, model_id, experience_verb, null); // archive_id must be null becaue we cannot delete an archived experience
    
    if (cachedExperienceIndex > -1){
      this.experiences.splice(cachedExperienceIndex, 1);
    };
  }
  getTotalProgressCoursesOnly (){
    let url = 'api/v1/experience/progress/course/0';
    let user = this.authService.user.getValue();
    let guest = this.authService.guest.getValue();
    if (!user && guest){
      url+='?guest_uuid='+guest.uuid;
    };
    return this.http.get<{data: Experience[]}>(url)
        .pipe(
          map(response =>{
            if (response?.data?.length ){
              this.cacheMultipleExperiences(response.data);
              this.calculateTotalProgress(response.data);
            };
          })
        )
  }
  getProgressables (modelNameSingular : string, model_id : number){
    model_id = model_id ? model_id : 0; // 0 will get a response will ALL progressables for that class
    let url = 'api/v1/experience/progressables/'+modelNameSingular+'/'+model_id;
    let user = this.authService.user.getValue();
    let guest = this.authService.guest.getValue();
    if (!user && guest){
      url+='?guest_uuid='+guest.uuid;
    };
    return this.http.get<{data: Progressable[]}>(url)
        .pipe(
          map(response =>{
            if (response?.data?.length ){
              this.cacheMultipleProgressables(response.data);
            };
          })
        )
  }

  submitExperience (modelNameSingular : string, model_id : number, slug : string, experience_value : number, experience_verb: string, experience_description: string){
    if (!slug && !model_id){
      throw new Error("You must provide an identifier");
    }
    let params = {
      'experienceable_type' : modelNameSingular,
      'experienceable_identifier' : model_id ? model_id : slug,
      'experience_value' : experience_value,
      'experience_verb' : experience_verb,
      'experience_description' : experience_description,
    };
    let url = 'api/v1/experience';

    let user = this.authService.user.getValue();
    let guest = this.authService.guest.getValue();
    if (!user && guest){
      url+='?guest_uuid='+guest.uuid;
    };
    return this.http.post<{data: Experience}>(
      url, params)
        .pipe(
          map(response =>{
            if (response ){
              if (!experience_value && response.data === null){
                this.removeDeletedExperience(modelNameSingular, model_id, experience_verb);
              } else {
                this.cacheSingleExperience (response.data)
                return response;
              }
            };
          })
        )
  }
  cacheSingleProgressable (progressable : Progressable, storedProgressablesCopy : Progressable[]){
    let cachedProgressableIndex = this.getCachedProgressableIndex (storedProgressablesCopy,null,progressable.type,progressable.id);
    if (cachedProgressableIndex === -1){
      storedProgressablesCopy.push(progressable);
    } else {
      storedProgressablesCopy[cachedProgressableIndex] = progressable;
    };
    return storedProgressablesCopy;
  }
  cacheMultipleProgressables (newProgressables : Progressable[]){
    let storedProgressablesCopy = this._progressables.getValue();
    if (!newProgressables?.length){return};
    newProgressables.forEach(p=>{
      storedProgressablesCopy = this.cacheSingleProgressable(p,storedProgressablesCopy)
    });
    this._progressables.next(storedProgressablesCopy);
  }
  getCachedProgressableIndex (storedProgressablesCopy : Progressable[], modelNameSingular : string, backendClassNameWithPath: string, model_id : number) : number {
    if (modelNameSingular && !backendClassNameWithPath){
      backendClassNameWithPath = this.dataProcessingService.convertModelNameToBackendClass(modelNameSingular);
    }
    return storedProgressablesCopy.findIndex(p => p.type === backendClassNameWithPath && p.id === model_id);
  };
  transformExperience(experience : Experience){
    if (experience){
      experience.updated = new Date(experience.updated_at);
    }
    return experience;
  }
  cacheSingleExperience (experience : Experience){
    experience = this.transformExperience(experience);
    let cachedExperienceIndex = this.getCachedExperienceIndex (experience.id,null,experience.experienceable_type,experience.experienceable_id,experience.experience_verb,experience.archive_id);
    if (cachedExperienceIndex === -1){
      this.experiences.push(experience);
    } else {
      this.experiences[cachedExperienceIndex] = experience;
    };
    return experience;
  }
    cacheMultipleExperiences (experiences : Experience[]){
      let cachedExperiences : Experience[] = [];
      if (!experiences?.length){return};
      experiences.forEach(e=>cachedExperiences.push(this.cacheSingleExperience(e)));
      return cachedExperiences;
  }
  getCachedExperienceIndex (experience_id : number, modelNameSingular : string, backendClassNameWithPath: string, model_id : number, experience_verb : string, archive_id : number) : number {
    let foundIndex : number = this.experiences.findIndex(e => e.id === experience_id);
    if (foundIndex >-1) {
      return foundIndex;
    };
    if (modelNameSingular && !backendClassNameWithPath){
      backendClassNameWithPath = this.dataProcessingService.convertModelNameToBackendClass(modelNameSingular);
    }
    return this.experiences.findIndex(e => e.experienceable_type === backendClassNameWithPath && e.experienceable_id === model_id && e.experience_verb === experience_verb && e.archive_id == archive_id);
  };
  getCachedExperienceMultiple (modelNameSingular : string, backendClassNameWithPath: string, model_id : number, experience_verb : string, withArchivedExperiences : boolean = false) : Experience[] {
    
    if (modelNameSingular && !backendClassNameWithPath){
      backendClassNameWithPath = this.dataProcessingService.convertModelNameToBackendClass(modelNameSingular);
    }

    return this.experiences.filter(e =>
        e.experienceable_type === backendClassNameWithPath &&
        e.experienceable_id === model_id &&
        (experience_verb ? e.experience_verb === experience_verb : true) &&
        (!withArchivedExperiences ? e.archive_id === null : true)
    );

  };
  
  isTheOnlyExperienceProgress (relatedExperiences){
    return relatedExperiences.length ===1 && relatedExperiences.find(e=>e.experience_verb === 'progressed' && !e.archive_id)
    // if there is only one experience and it's progress, that means we have not requested all related experiences yet
  }
  getProgressFromProgressables (progressables : Progressable[], modelName: string /*'survey' or 'course'*/, slug: string){
    let result = progressables.find(p=>p.type === this.dataProcessingService.convertModelNameToBackendClass(modelName) && p.slug === slug)?.progress;
    return result === 0 || result > 0 ? Math.round(result) : null;
  }
}
