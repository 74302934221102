import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { AuthService, User, UserRole } from '../../auth';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { MediaService } from '../../content';
import { Progressable } from '../../tracking';
import { AccountDropdownLink, AccountDropdownLinks } from '@frontend/common';


@Component({
  selector: 'multisite-sidenav',
  templateUrl: './multisite-sidenav.component.html',
  styleUrls: ['./multisite-sidenav.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class MultisiteSidenavComponent {
  @Input() user: User;
  @Input() mode: string; // 'slim', 'wide' 'hidden'?
  @Output() changeMode = new EventEmitter<string>();
  @Output() logout = new EventEmitter();
  userNameCharacterLimit : number = 35;
  userTitleCharacterLimit : number = 25;
  showAllProgressables : boolean = false;
  progressables : Progressable[] = [];
  progressablesDefaultListMaxLength = 2;
  accountDropdownLinks : AccountDropdownLink[];

  constructor(private authService: AuthService, private mediaService : MediaService) {
    this.accountDropdownLinks = AccountDropdownLinks;
  }

  handleClick(functionName:string){
    if(functionName){
      this[functionName]();
    }
  }

  doLogout(){
    this.logout.emit('');
  }
  filteredAccountDropdownLinks(accountDropdownLinks: AccountDropdownLink[], userRoles : UserRole[]) {
    return accountDropdownLinks && userRoles ? accountDropdownLinks.filter(link => link.filteredRoles?.length < 1 || this.authService.getMatchingUserRoles(userRoles,link.filteredRoles).length) : [];
  }
  toggleShowAllProgressables (event : Event){
    event.stopPropagation();
    this.showAllProgressables = !this.showAllProgressables;
  }

  getDefaultAvatar (){
    return this.mediaService._fallback_avatar_url;
  }

  isAdmin (){
    return this.authService.checkRole("Admin");
  }
  getCharacterLimits (){
    this.userNameCharacterLimit = this.mode !== 'wide' ? 20:35;
    this.userTitleCharacterLimit = this.mode !== 'wide' ? 20:25;
  }
  getUserNameWithTruncation () {
    this.getCharacterLimits ();
    let fullName = this.user?.fname +' '+this.user?.lname;
    return fullName?.length > this.userNameCharacterLimit ? fullName.substring(0, this.userNameCharacterLimit-5)+'...' : fullName ? fullName : '';
  }
  getUserTitleWithTruncation () {
    this.getCharacterLimits ();
    let fullName = this.getUserNameWithTruncation();
    let title = this.user?.profile?.job_title;  
    return fullName?.length + title?.length > this.userNameCharacterLimit + this.userTitleCharacterLimit ? title.substring(0, this.userTitleCharacterLimit-5)+'...' : title ? title : '' ;
  }
  toggleViaSidenavBackground(/*event : Event*/){ // TODO consider removing this
      this.doModeChange(''); // toggle
  }
  toggleSlimOpenNotClose (event : Event){
    event.stopPropagation();
    if (this.mode === 'slim'){
      this.doModeChange('wide');
    }
  }

  doModeChange (newMode: string, event : Event = null){
    if (event){
      event.stopPropagation();
    }
    this.changeMode.emit(newMode);
  }
}
