import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'multisite-list-item-icon',
  templateUrl: './list-item-icon.component.html',
  styleUrls: ['./list-item-icon.component.scss']
})
export class ListItemIconComponent implements OnInit {

  @Input() iconClasses : string; // 'fa-solid fa-clock icon-size'
  iconClassesFallback : string = 'fa-solid fa-check';
  @Input() titleTranslationKey : string; // 'common.help'
  @Input() descriptionTranslationKey : string; // 'common.lengthier_help_text'
  @Input() titleText : string; // 'A word of advice'
  @Input() descriptionText : string; // 'common.lengthier_help_text'

  constructor() { }

  ngOnInit(): void {
    if (!this.iconClasses){
      this.iconClasses = this.iconClassesFallback;
    }
  }

}
