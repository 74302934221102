<h4>Buttons:</h4>
<button type="button" class="btn btn-primary">Primary</button>
<button type="button" class="btn btn-secondary">Secondary</button>
<button type="button" class="btn btn-success">Success</button>
<button type="button" class="btn btn-danger">Danger</button>
<button type="button" class="btn btn-warning">Warning</button>
<button type="button" class="btn btn-info">Info</button>
<button type="button" class="btn btn-light">Light</button>
<button type="button" class="btn btn-dark">Dark</button>
<button type="button" class="btn btn-link">Link</button>
<br>
<hr>
<h4>Buttons disabled:</h4>
<button type="button" class="btn btn-primary" disabled>Primary</button>
<button type="button" class="btn btn-secondary" disabled>Secondary</button>
<button type="button" class="btn btn-success" disabled>Success</button>
<button type="button" class="btn btn-danger" disabled>Danger</button>
<button type="button" class="btn btn-warning" disabled>Warning</button>
<button type="button" class="btn btn-info" disabled>Info</button>
<button type="button" class="btn btn-light" disabled>Light</button>
<button type="button" class="btn btn-dark" disabled>Dark</button>
<button type="button" class="btn btn-link" disabled>Link</button>
<br>
<hr>
<h4>Buttons with icon:</h4>
<button type="button" class="btn btn-primary">Primary <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-secondary">Secondary <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-success">Success <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-danger">Danger <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-warning">Warning <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-info">Info <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-light">Light <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-dark">Dark <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-link">Link <i class="fa-solid fa-arrow-right"></i></button>
<br>
<hr>
<h4>Buttons with icon disabled:</h4>
<button type="button" class="btn btn-primary" disabled>Primary <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-secondary" disabled>Secondary <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-success" disabled>Success <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-danger" disabled>Danger <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-warning" disabled>Warning <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-info" disabled>Info <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-light" disabled>Light <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-dark" disabled>Dark <i class="fa-solid fa-arrow-right"></i></button>
<button type="button" class="btn btn-link" disabled>Link <i class="fa-solid fa-arrow-right"></i></button>
<br>
<hr>
<h4>Links:</h4>
<a class="btn btn-primary" href="#" role="button">Link</a>
<a class="btn btn-secondary" href="#" role="button">Link</a>
<a class="btn btn-success" href="#" role="button">Link</a>
<a class="btn btn-danger" href="#" role="button">Link</a>
<a class="btn btn-warning" href="#" role="button">Link</a>
<a class="btn btn-info" href="#" role="button">Link</a>
<a class="btn btn-light" href="#" role="button">Link</a>
<a class="btn btn-dark" href="#" role="button">Link</a>
<br>
<hr>
<h4>Form:</h4>
<button class="btn btn-primary" type="submit">Button</button>
<input class="btn btn-primary" type="button" value="Input">
<input class="btn btn-primary" type="submit" value="Submit">
<input class="btn btn-primary" type="reset" value="Reset">
<br>
<hr>
<h4>Form disabled:</h4>
<button class="btn btn-primary" type="submit" disabled>Button</button>
<input class="btn btn-primary" type="button" value="Input" disabled>
<input class="btn btn-primary" type="submit" value="Submit" disabled>
<input class="btn btn-primary" type="reset" value="Reset" disabled>
<br>
<hr>
<h4>Outline:</h4>
<button type="button" class="btn btn-outline-primary">Primary</button>
<button type="button" class="btn btn-outline-secondary">Secondary</button>
<button type="button" class="btn btn-outline-success">Success</button>
<button type="button" class="btn btn-outline-danger">Danger</button>
<button type="button" class="btn btn-outline-warning">Warning</button>
<button type="button" class="btn btn-outline-info">Info</button>
<button type="button" class="btn btn-outline-light">Light</button>
<button type="button" class="btn btn-outline-dark">Dark</button>
<br>
<hr>
<h4>Outline disabled:</h4>
<button type="button" class="btn btn-outline-primary" disabled>Primary</button>
<button type="button" class="btn btn-outline-secondary" disabled>Secondary</button>
<button type="button" class="btn btn-outline-success" disabled>Success</button>
<button type="button" class="btn btn-outline-danger" disabled>Danger</button>
<button type="button" class="btn btn-outline-warning" disabled>Warning</button>
<button type="button" class="btn btn-outline-info" disabled>Info</button>
<button type="button" class="btn btn-outline-light" disabled>Light</button>
<button type="button" class="btn btn-outline-dark" disabled>Dark</button>
