import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Subscription, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService, LanguageService, LessonExtraLite, MediaService, PlaylistTextItem } from '@frontend/common';
import { Lesson } from './lesson/lesson.model'
import { CourseModule } from './course-module.model';

interface LessonWithModules {
  course: {slug:string,name:string},
  modules: CourseModule[],
  lesson: Lesson,
}

@Injectable({
  providedIn: 'root'
})
export class LessonService {

  lessons : Lesson[] = [];
  activeLanguageSubscription: Subscription;


  constructor(
    private http: HttpClient,
    private authService : AuthService,
    private languageService : LanguageService,
  ) {
    this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe(() => {
      this.clearTranslations();
    });
  }
  clearTranslations (){
    this.lessons = [];
  }
  // clearData (){
  //   this.clearTranslations();
  // }

  // getPlaceholder () { // generate a dummy Lesson

  //   let lesson : Lesson = {
  //     id: 100000001,
  //     course_module_id: 100000001,
  //     course_slug: 'using-cultureconnector',
  //     name : 'Inviting participants to CultureConnector in less than 2 minutes',
  //     description: '<p>Sit ut dolor pariatur irure ex. Aliquip dolore qui nulla est sunt ad cillum eiusmod duis esse magna do aute. <a href="http://www.cultureconnector.com">cco online</a></p>',
  //     slug: 'inviting-participants-to-cultureconnector-in-less-than-2-minutes',
  //     videos : [{name:'Kathryn Libioulle-Clutz, toolbox tool: favourite shoe', id: 10000, host_identifier: '558020191', host_params: 'h=be064152b1'}], // '758075392',
  //     progress: null, // [{user_id : 4, complete : datetime}]
  //     notes: `<p>
  //     Nunc, netus neque malesuada sapien. Quam dui pulvinar egestas sit ultrices eget amet, tristique.
  //     Nam vitae pretium fringilla turpis maecenas lorem purus.
  //   </p>
  //   <ul>
  //     <li>Item #1</li>
  //     <li>Item #2</li>
  //     <li>Item #3</li>
  //     <li>Item #4</li>
  //   </ul>
  //   <p>There is a link to the....:</p>
  //   <a href="https://cultureconnector.com/#/signup" target="_blank">CultureConnector</a>`,
  //     links: null,
  //     tags: null,
  //     duration: null,
  //     access: null, // required role etc
  //     created_at: '2023-01-07 13:00:03',
  //     updated_at: '2023-01-07 13:00:03',
  //     published_at: '2023-01-07 13:00:03',
  //   };
  //   return lesson;
  // };
   transformLesson (lessonFromBackend){
    let transformedlesson : Lesson = lessonFromBackend;
    // debugger; // we probably don't need thi courseSlug; remove it
    // transformedlesson.course_slug = null;
    return transformedlesson;
  }

  getLesson (lessonSlug: string, freshFromBackend : boolean){

    if (lessonSlug) {
      lessonSlug = lessonSlug.toLowerCase();
    }

    let cachedLesson : Lesson = this.getCachedLesson(lessonSlug);

    if (cachedLesson && !freshFromBackend){
      return of(cachedLesson);
    };

    let url = 'api/v1/lesson/'+lessonSlug;

    let user = this.authService.user.getValue();
    let guest = this.authService.guest.getValue();
    if (!user && guest){
      url+='?guest_uuid='+guest.uuid;
    };

    return this.http.get<{'lesson' : Lesson}>(url)
        .pipe(
          map(response =>{
            let lesson : Lesson = this.transformLesson(response.lesson);
            this.cacheLesson(lesson);
            return lesson;
          }),
          catchError((error)=>{
            if (error.error?.message == 'Guest authentication error') {
              this.authService.removeGuestLocally();
              return [];
            } else {
              this.handleError(error);
            }
          }),
        )
  };
  getNextLessonToResume (courseSlug: string){
    
    let url = 'api/v1/lesson/next/course/'+courseSlug;

    let user = this.authService.user.getValue();
    let guest = this.authService.guest.getValue();
    if (!user && guest){
      url+='?guest_uuid='+guest.uuid;
    };

    return this.http.get<LessonExtraLite>(url)
      .pipe(
        map(response =>{
          return response; // this.transformLesson(response);
        })
      )
  };
  getCachedLesson (lessonSlug: string){
    return this.lessons.find(l=>l.slug===lessonSlug);
  }

  cacheLesson (lesson: Lesson){
    let foundIndex = this.lessons.findIndex(l=>l.slug === lesson.slug && l.course_slug === lesson.course_slug);
    if (foundIndex >-1){
      this.lessons[foundIndex] = lesson;
    } else {
      this.lessons.push(lesson);
    }
  };
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    if (errorResponse.error.message == 'Guest authentication error') {
      this.authService.removeGuestLocally();
      return;
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }
}
