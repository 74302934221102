import { CiResultFixedData } from "./ciresult-fixed-data.model";

const subdomainKey = (subdomain: string) => `ciprofile_domains.subdomains.${subdomain}`;

export const CiResultConstants : {domains :CiResultFixedData[],topics :CiResultFixedData[]} = {

    domains : [
        { slug: "know", iconClasses: 'fa-kit fa-solid-globe-magnifying-glass', titleTranslationKey: "ciprofile_domains.know", children : 
          [
            { slug: "values", titleTranslationKey: subdomainKey("values_and_beliefs"), iconClasses: 'fa-solid fa-person-praying' },
            { slug: "behaviour", titleTranslationKey: subdomainKey("behaviour"), iconClasses: 'fa-solid fa-person-circle-check' },
            { slug: "blindspots", titleTranslationKey: subdomainKey("blindspots"), iconClasses: 'fa-solid fa-eye-slash' },
            { slug: "translations", titleTranslationKey: subdomainKey("translations"), iconClasses: 'fa-solid fa-right-left-large' },
          ]
        },
        { slug: "love", iconClasses: 'fa-solid fa-heart', titleTranslationKey: "ciprofile_domains.love", children :[
          { slug: "internal", titleTranslationKey: subdomainKey("internal"), iconClasses: 'fa-kit fa-solid-face-smile-circle-arrow-right fa-2xl'},
          { slug: "external", titleTranslationKey: subdomainKey("external"), iconClasses: 'fa-kit fa-solid-face-smile-circle-arrow-left fa-2xl'},
          { slug: "resilience", titleTranslationKey: subdomainKey("resilience"), iconClasses: 'fa-regular fa-seedling'},
          { slug: "mastery", titleTranslationKey: subdomainKey("mastery"), iconClasses: 'fa-regular fa-badge-check'},
          ]
        },
        { slug: "act", iconClasses: 'fa-solid fa-person-running', titleTranslationKey: "ciprofile_domains.act", children : [
          { slug: "performing", titleTranslationKey: subdomainKey("performing"), iconClasses: 'fa-solid fa-masks-theater'},
          { slug: "reformulating", titleTranslationKey: subdomainKey("reformulating"), iconClasses: 'fa-solid fa-swap fa-rotate-90'},
          { slug: "authenticity", titleTranslationKey: subdomainKey("authenticity"), iconClasses: 'fa-solid fa-hand-holding-heart'},
          { slug: "countering_intuition", titleTranslationKey: subdomainKey("countering_intuition"), iconClasses: 'fa-duotone fa-person-falling-burst'},
          ]
        },
        { slug: "think", iconClasses: 'fa-solid fa-eye', titleTranslationKey: "ciprofile_domains.think", children :[
          { slug: "preparing", titleTranslationKey: subdomainKey("preparing"), iconClasses: 'fa-solid fa-list-check'},
          { slug: "noticing", titleTranslationKey: subdomainKey("noticing"), iconClasses: 'fa-solid fa-camera-cctv'},
          { slug: "reviewing", titleTranslationKey: subdomainKey("reviewing"), iconClasses: 'fa-solid fa-face-thinking'},
          { slug: "expecting", titleTranslationKey: subdomainKey("expecting"), iconClasses: 'fa-solid fa-calendar-xmark'},
          ]
        },
      ],
      topics : [
        { slug: "range", iconClasses: 'fa-solid fa-arrows-left-right', titleTranslationKey: "ciprofile_topics.range"},
        { slug: "growth", iconClasses: 'fa-solid fa-seedling', titleTranslationKey: "ciprofile_topics.growth"},
        { slug: "stereotypes", iconClasses: 'fa-kit fa-regular-people-group-tag fa-2xl', titleTranslationKey: "ciprofile_topics.stereotypes"},
        { slug: "authenticity", iconClasses: 'fa-solid fa-hand-holding-heart', titleTranslationKey: "ciprofile_topics.authenticity"},
        { slug: "ambassadorial", iconClasses: 'fa-solid fa-house-flag', titleTranslationKey: "ciprofile_topics.ambassadorial"},
      ]
}