<main>
  <div class="container p-5" *transloco="let t">

    <multisite-loader-globe-animation-detail *ngIf="isAnythingLoading()" [width]="100" [height]="100">
    </multisite-loader-globe-animation-detail>

    <div class="card promo-card" *ngFor="let course of coursesAsContent">
      <a (click)="navigateTo(course.slug, $event)">
      <div class="row">
        <div class="col-md-4">
          <a (click)="navigateTo(course.slug, $event)">
          <img class="img-fluid rounded-start w-100" *ngIf="course.media"
            [src]="course.media.asset_url && thumbnailTransformations ? course.media.base_url + thumbnailTransformations + course.media.asset_url : course.media.file_url"
            [alt]="course.media.description">
          </a>
        </div>
        <div class="col-md-8">
          <div class="card-body p-0">
            <a (click)="navigateTo(course.slug, $event)">
              <h5 class="card-title mt-4 mt-md-0">{{course.title}}</h5>
            </a>  
            <p class="card-text text-muted mt-2">{{course.description}}</p>
            <div class="badges mt-2">
              <span *ngFor="let tag of course.tags" class="m-1" [ngClass]=" [tag.type == 'topic' ? 'badge-info' : 'badge-primary', 'badge badge-pill']">
                <i *ngIf="tag.icon_type === 'fa' && tag.icon_identifier" [class]="'mr-1 ' + tag.icon_identifier + (tag.icon_identifier.includes(' ') ? '' :'fa-solid')"></i>
                <span *ngIf="tag.icon_type === 'emoji' && tag.icon_identifier" [class]="'mr-1'">{{tag.icon_identifier}}</span>
                <img *ngIf="tag.icon_type === 'cloudinary_flag' && tag.icon_identifier" [class]="'mr-1'" [src]="getFlagUrlFromHash(tag.icon_identifier, 'w_250,c_fill/')">
                <img *ngIf="tag.icon_type === 'cloudinary_icon' && tag.icon_identifier" [class]="'mr-1'" [src]="getFlagUrlFromHash(tag.icon_identifier, 'w_250,c_fill/')">
                <img *ngIf="tag.img_url && !tag.icon_identifier" [class]="'mr-1'" [src]="tag.img_url">
                {{tag.name}}
              <!-- <span class="badge badge-pill badge-more" (click)="action()">
                  <i class="fa-solid fa-angle-right"></i>
              </span> -->
              </span>
          </div>
            <div class="col-12 my-2">
              <div class="col-12 my-2" *ngIf="course.rating">
                <span style="margin:15px 0;"> {{roundRating(course.rating)}} </span>
                <rating ngClass="text-warning" [(ngModel)]="course.rating" [max]="5" [readonly]="true"></rating>
              </div>
            </div>
            <p class="card-text">
              <small class="text-muted">{{t('common.updated')}} {{course.date | date : 'longDate'}}</small>
            </p>
            <div class="mt-4">
              <button class="btn btn-primary d-block d-sm-inline w-sm-100"
                [routerLink]="['./'+course.slug]">{{t(course.cta_translationKey)}}</button>
            </div>
          </div>
        </div>
      </div>
    </a>
    </div>



    <p *ngIf="!isAnythingLoading() && !coursesAsContent?.length">{{t('common.no_results')}}</p>


  </div>
</main>