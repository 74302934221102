import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Media } from '@frontend/core';
import { UserExtraLite } from '@frontend/common';


@Component({
  selector: 'frontend-link-type-four',
  templateUrl: './link-type-four.component.html',
  styleUrls: ['./link-type-four.component.scss']
})
export class LinkTypeFourComponent implements OnInit {

  // Good for internal links/actions where the model has media

  @Input() title : string;
  @Input() description : string;
  @Input() date : Date;
  @Input() media : Media;
  @Input() users : UserExtraLite[];
  @Input() user_list_prefix_translation : string; // 'TOOLS.ADDED_BY'
  @Input() slug : string;
  @Input() cta_translation : string;
  @Output() linkClicked = new EventEmitter<any>();
  thumbnailImageTransformation : string = 'c_thumb,w_700/';

  constructor() { }

  doLinkClick (){
    this.linkClicked.emit();
  }

  ngOnInit(): void {
  }

}
