import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
import { AuthConstants } from '../auth.constants';
import { WebsiteFamilyConstants } from '../../sites/website-family.constants';
import { environment } from '../../../../../../apps/culturettt/src/environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'multisite-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {

  @ViewChild('successModal') successModal;
  modalRef?: BsModalRef;
  registrationForm: FormGroup;
  loading: boolean;
  errorMessage: string;
  hideBackendErrorMessage: boolean; // TODO: do we need this?
  registrationSubscription: Subscription;
  showWarningAlert : boolean = false;
  nameMinimumCharacterLimit : number;
  languagesWhere1CharacterNameIsAllowed : string[];
  authConstants : any;
  relatedWebsites : {appKey:string,appUrl:string,name:string}[];
  termsViewed : boolean;
  termsCollapsed : boolean = true;
  showViewTermsLink : boolean;
  environment : any;
  registeredEmail : string;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: BsModalService
  ) {
    this.languagesWhere1CharacterNameIsAllowed = ['ja','zh'];
    this.nameMinimumCharacterLimit = this.findLanguage(this.languagesWhere1CharacterNameIsAllowed) ? 1 : 2;
    this.authConstants = AuthConstants;
    this.environment = environment;
    this.relatedWebsites = this.getRelatedWebsites(this.environment.appKey);
  }
  openTheModalPlease (){
    this.openModal(this.successModal);
  }
  findLanguage (arrayOfLanguages){
    return arrayOfLanguages.find(l=>navigator.language.toLowerCase().indexOf(l)===0);
  }
  
  navigateTo(target){
    this.router.navigateByUrl(target);
  }
  closeModal(){
    // this.successModal.hide();
    this.modalRef.hide();
    this.navigateTo('/');
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  getRelatedWebsites(thisAppKey) {
    return WebsiteFamilyConstants.websites.filter(w=>w.appKey !== thisAppKey);
  }

  viewTermsAndRegister(){
    if (!this.termsViewed){
      this.termsViewed = true;
      this.termsCollapsed = !this.termsCollapsed;
    } else {
      this.registerWithEmail();
    }
  }

  resetErrorMessages() {
    this.showWarningAlert = false;
    this.errorMessage = null;
    this.showViewTermsLink = false;
}
checkRegex (string : string){
  if (!string){return false ;};
  return this.authConstants.passwordRegexPattern.test(string);
 }
 validatePassword(control: AbstractControl) {
  if (!this.checkRegex(control.value)) {
    return { invalidPasswordFormat: true };
  }
  return null;
}
registerWithEmail() {

  if (!this.registrationForm.valid || !this.termsViewed) {
    return;
  }

  this.loading = true;
  this.termsCollapsed = true;
  this.resetErrorMessages();
  

  let registerObs = this.authService.register(
    this.registrationForm.controls.email.value,
    this.registrationForm.controls.password.value,
    this.registrationForm.controls.fname.value,
    this.registrationForm.controls.lname.value,
  );
  
  this.registrationSubscription = registerObs.subscribe(
    response => {
      this.loading = false;
      this.registeredEmail = this.registrationForm.controls.email.value;
      this.registrationForm.reset(this.registrationForm.value);
      this.openTheModalPlease()
      // this.successModal.show()
      // form.reset();
    },
    errorMessage => {
      this.loading = false;
      this.showWarningAlert = true;
      this.errorMessage = errorMessage;
      this.showViewTermsLink = true;
    }
  );

}
emailMatchValidator(g: FormGroup) {
  return g.get('email').value === g.get('repeatEmail').value
     ? null : {'mismatch': true};
}
ngOnInit(): void {
  this.loading = false;
  this.registrationForm = new FormGroup({
    fname: new FormControl(null, [Validators.required, Validators.minLength(this.nameMinimumCharacterLimit), Validators.maxLength(40)]),
    lname: new FormControl(null, [Validators.required, Validators.minLength(this.nameMinimumCharacterLimit), Validators.maxLength(40)]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    repeatEmail: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(20), this.validatePassword.bind(this)]),
  }, this.emailMatchValidator);
  this.registrationForm.controls.password.valueChanges.subscribe(
    (value) => {
    this.checkRegex(value);
    }
  );
  this.registrationForm.controls.email.valueChanges.subscribe(()=> this.resetErrorMessages());
}
ngOnDestroy () : void {
  if (this.registrationSubscription){
    this.registrationSubscription.unsubscribe();
  };
}

}
