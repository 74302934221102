<div class="row" *ngIf="paginatedContent">
    <div class="col">
        <div class="" *ngIf="paginatedContent?.data">

            <card-list-item-promo *ngFor="let item of paginatedContent.data"
                [content]="item"
                [imageTransformations]="imageTransformations"
                (itemSelected)="onItemSelected($event)">
            </card-list-item-promo>

            <div class="my-3">
                <frontend-pagination-type-three
                    *ngIf="paginatedContent?.meta && paginatedContent.meta.last_page > 1"
                    [classes]="'pg-purple justify-content-center'"
                    [loading]="loading"
                    [meta]="paginatedContent.meta"
                    [lastPage]="paginatedContent.meta.last_page"
                    [links]="paginatedContent.links"
                    (changePage)="doGetPaginatedContent($event)"
                >
                </frontend-pagination-type-three>
            </div>
        </div>
    </div>
</div>
<div class="row">
        <card-list-item-promo [ngClass]="'col-lg-4'" *ngFor="let item of contentItems" [content]="item"
            [imageTransformations]="imageTransformations" (itemSelected)="onItemSelected($event)">
        </card-list-item-promo>

</div>
<div class="row">
    <button class="btn btn-link show-more-link" *ngIf="contentItemsChunkSize && contentItems?.length > contentItemsChunkSize" (click)="showMoreOrLess()">
            <i class="fa-solid fa-angle-up" *ngIf="contentItemsDisplay.length == contentItems.length"></i>
            <span *transloco="let t">{{ contentItemsDisplay.length < contentItems.length ? t('common.show_more') : t('common.show_less')}}</span>
            <i class="fa-solid fa-angle-down" *ngIf="contentItemsDisplay.length < contentItems.length"></i>
        </button>
</div>