<div *ngIf="progressables">

  <progress-indicator-slimmable-single *ngFor="let progressable of progressablesShown; let i = index"
    [ngClass]="{
      'stacked-shadows': darkModeActive () &&
        i ===progressablesShown.length-1 && progressablesShown.length < progressables.length,
      'stacked-shadows-light': !darkModeActive () &&
        i ===progressablesShown.length-1 && progressablesShown.length < progressables.length
      }"
    [slimMode]="slimMode"
    [text]="progressable.name"
    [progressPercent]="progressable.progress"
    [icon]="progressable.icon"
    [iconBackgroundClass]="'bg-'+progressable.statusClass"
    (selected)="onItemSelected(progressable)"
    >
  </progress-indicator-slimmable-single>

      <!-- <a type="button" class="btn btn-link text-muted text-small" (click)="toggleShowAll()" *ngIf="progressables.length > defaultListMaxLength">
        <span *transloco="let t">{{showAll ? t('common.show_less') : t('common.show_more')}}</span>
      </a> -->

  
</div>
