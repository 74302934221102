import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenScienceComponent } from './open-science.component';
import { TranslocoModule } from '@jsverse/transloco';
import { OpenScienceRoutingModule } from './open-science-routing.module';



@NgModule({
  declarations: [
    OpenScienceComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    OpenScienceRoutingModule
  ],
  exports: [
    OpenScienceComponent
  ]
})
export class OpenScienceModule { }
