import { Component, OnInit, Input } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

import { Video } from '@frontend/core';

@Component({
  selector: 'frontend-video-player-type-two',
  templateUrl: './video-player-type-two.component.html',
  styleUrls: ['./video-player-type-two.component.scss']
})
export class VideoPlayerTypeTwoComponent implements OnInit {
 
  @Input() video : Video;
  @Input() classes : string;// 'mb-3'
  @Input() hostPlayerParams : string; // 'badge=0&autopause=0&player_id=0&app_id=58479'
  @Input() iframeAllowParams : string; // 'autoplay; fullscreen; picture-in-picture' // This was deprecated in Angular v15. Now "allow" will not accept dynamic variables in the iframe element. If you want to dynamically control these params, you must use ngIf instead. https://angular.io/errors/NG0910
  @Input() title : string; // 'Inviting participants to CultureConnector in less than 2 minutes'

  iframeSrc : SafeResourceUrl;

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    private sanitizer: DomSanitizer
    ) { }

  generateParams (){
    let params : string = '';
    if (this.video.host_params){
      params += this.video.host_params;
    };
    if (!this.hostPlayerParams){
      this.hostPlayerParams = 'badge=0&autopause=0&player_id=0&app_id=58479';
    };
    if (params?.length){
      params += '&'
    };
    return params + this.hostPlayerParams;
  }
  

  ngOnInit(): void {
    if (this.video?.host_identifier){
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/'+this.video.host_identifier+'?'+this.generateParams());
      /* var options = {
        url: "https://vimeo.com/558020191",
        // width: 800
      };
      var videoPlayer = new Vimeo.Player('myVideo', options);

      videoPlayer.on('play', function() {
        console.log('Played the video');
      }); */
      
      const s = this.renderer2.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://player.vimeo.com/api/player.js'
      this.renderer2.appendChild(this._document.body, s);
    }
  }

}
