import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ContentItem } from '../../content/content-item.model';
// import { PageTitleService } from '../../navigation/title/title.service';
import { Survey } from '../survey.model';
import { SurveysService } from '../surveys.service';
import { AvailableLanguage, LanguageService } from '@frontend/common';

@Component({
  selector: 'culturequestion-survey-listing',
  templateUrl: './survey-listing.component.html',
  styleUrls: ['./survey-listing.component.scss']
})
export class SurveyListingComponent implements OnInit {
// Added by milos for rating component
max = 5;
rate = 5;

contentSubscription: Subscription;
loading: boolean;
topicSlug: string; // icpendulum or cultureconnector
contentClassName: string; // tips or episodes
surveys: Survey[] = [];
surveysAsContent: ContentItem[];
thumbnailTransformations: string = 'w_375/';
activeLanguageSubscription: Subscription;
activeLanguageObject: AvailableLanguage;

constructor(
  private router: Router,
  private route: ActivatedRoute,
  private surveysService: SurveysService,
  // private titleService: PageTitleService,
  private languageService: LanguageService,
) {}
gotoSurvey(surveysAsContentItem: ContentItem){
  if (surveysAsContentItem){
    this.router.navigate([surveysAsContentItem.slug], {relativeTo: this.route});
  }
}
roundRating (num: number){
  return num ? Number(num).toFixed(1): 0;
}
getContent(freshFromServer:boolean) {
  this.loading = true;

  this.contentSubscription = this.surveysService
    .getSurveys('cultures','knowledge',null,freshFromServer)
    .subscribe(
      (response) => {
        this.surveys = response;
        this.loading = false;
        this.surveysAsContent = this.surveysService.convertSurveysToContentItems(this.surveys);
      },
      (error) => {
        this.loading = false;
      }
    );
}

ngOnInit(): void {
  this.route.url.subscribe(() => {
    let fullUrlParts = this.router.routerState.snapshot.url
      .toLowerCase()
      .split('/')
      .filter((s) => s);
    this.topicSlug = fullUrlParts[0];
    this.contentClassName = fullUrlParts[1];
    this.getContent(false);
  });
  this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
  this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
    // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
    if (newActiveLanguage?.languageKey !== this.activeLanguageObject.languageKey){
      this.activeLanguageObject = newActiveLanguage;
      this.getContent(true);
    }
  })
}
ngOnDestroy () {
  if (this.activeLanguageSubscription){
    this.activeLanguageSubscription.unsubscribe();
  }
}

}
