import { Component, Input } from '@angular/core';
import { Media } from '@frontend/core';


@Component({
  selector: 'frontend-media-image',
  templateUrl: './media-image.component.html',
  styleUrls: ['./media-image.component.scss']
})
export class MediaImageComponent {

  @Input() classes: string;
  @Input() transformations: string; // Cloudinary transformations
  @Input() media: Media;
  @Input() altText: string;
  @Input() altTextTranslation: string; // 'common.log_in' // ****NOT**** currently supported. It will not parse in the template!

  constructor() { }

}
