import { UserExtraLite } from "../auth";
import { CloudinaryMediaAsset } from "../models";

export class HubLite {

    id : number;
    name : string;
    slug : string;
    strapline : string;
    description : string;
    type : string;
    contact_persons : UserExtraLite[];
    media: CloudinaryMediaAsset[];
    created_at : string;
    primary_colour? : string;
    primary_brand_slug? : string;
    logo_banner? : string;
    programmes_count? : number;
    cohorts_count? : number; // temporary; may be removed later; this is part of 'social proof' statistics for Lite listings of Hubs
}