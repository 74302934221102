import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'multisite-avatar-pill',
  templateUrl: './avatar-pill.component.html',
  styleUrls: ['./avatar-pill.component.scss']
})
export class AvatarPillComponent implements OnInit {

  @Input() imageUrl : string;
  @Input() fallbackImageUrl : string;
  @Input() translationKey : string; // 'language.de'
  @Input() text : string; // ' Mohammed Bin Salman
  @Input() fallbackTranslationKey : string; // 'social.verified_user'
  @Input() removalMode : boolean; // means that clicking the pill will remove it from the set
  @Input() clickable : boolean; // mouse effects even when not in an <a> tag
  @Input() disabled : boolean; // greyed-out effects
  @Input() active : boolean; // highlight effect
  @Input() cursorNone : boolean; // greyed-out effects
  @Input() hideBackground : boolean; // only show background on hover
  @Input() compact : boolean; // smaller, less padding, less margin
  @Output() selected = new EventEmitter();


  constructor() { }

  onSelected(){
    this.selected.emit();
  }

  ngOnInit(): void {
  }

}
