import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkTypeOneComponent } from './link-type-one.component';
import { ImagesModule } from '../../images';



@NgModule({
  declarations: [LinkTypeOneComponent],
  exports: [LinkTypeOneComponent],
  imports: [
    CommonModule,
    ImagesModule
  ]
})
export class LinkTypeOneModule { }
