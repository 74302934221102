<div class="container">
  <!-- <div class="row">
    <progress-indicator-card-simple *ngIf="surveyProgressValue === 0 || surveyProgressValue > 0"
      [titleTranslationKey]="'tracking.progress'"
      [value]="surveyProgressValue"
      [unit]="'%'"
      ></progress-indicator-card-simple>
  </div> -->
  <div class="row">
    <multisite-question-presenter *ngIf="questions?.length"
        [questions]="questions"
        [questionFeedbacks]="questionFeedbacks"
        [loading]="loadingQuestionResponse"
        [surveyProgressValue]="surveyProgressValue"
        [error_message_translation_key]="surveyResponseError"
        (submitResponse)="doSubmitSurveyResponse($event)"
        [showNextButton]="showNextButton"
        [showSnapshotContext]="showSnapshotContext"
        (setSnapshot)="setSnapshot($event)"
        [showMultiRaters]="showMultiRaters"
        [showTryAgainButtonWithFeedback]="showTryAgainButtonWithFeedback"
        [hideProgressIndicator]="hideProgressIndicator"
        [disableNextButton]="loadingQuestions || loadingQuestionResponse"
        (next)="getQuestions()"
        ></multisite-question-presenter>
  </div>
</div>
