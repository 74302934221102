<div id="carouselCard" class="carousel-card slide bg-light-dark" *transloco="let t">
    <div class="header">
        <p class="title">{{title_translationKey ? t(title_translationKey) : title_text}}</p>
        <div class="carousel-control" *ngIf="content?.length > 1">
            <button class="carousel-control-prev" role="button" (click)="previous()">
                <i class="fa-solid fa-angle-left"></i>
                <span class="sr-only">{{t('common.previous')}}</span>
            </button>
            <p class="indicator" *ngIf="content?.length">
                <span>{{currentIndex+1}}</span>/<span>{{content?.length}}</span>
            </p>
            <button class="carousel-control-next"role="button" (click)="next()">
                <i class="fa-solid fa-angle-right"></i>
                <span class="sr-only">{{t('common.next')}}</span>
            </button>
        </div>
    </div>
    <div class="carousel-inner">
        <div class="carousel-item active" *ngIf="content?.length">
            <div class="text-content">
                <p class="title">
                    {{content[currentIndex].title}}
                </p>
                <p class="text position-relative border-left-decoration mt-2 quotation">
                    <span class="quote-decoration"><i class="fa-solid fa-quote-left"></i></span>
                    "{{content[currentIndex].body}}"
                </p>
                <div class="action">
                    <div class="profile" *ngIf="content[currentIndex].user">
                        <img class="profile-img" [src]="avatarUrl">
                        <div class="text">
                            <p class="name">
                                {{content[currentIndex].user.fname}}
                            </p>
                            <p class="position" *ngIf="content[currentIndex].user.job_title">
                                {{content[currentIndex].user.job_title}}
                            </p>
                        </div>
                    </div>
                    <span class="splitter"></span>
                    <span *ngIf="content[currentIndex].rating">
                        <div class="rating">
                            <pre class="rate-value"><b>{{content[currentIndex].rating}}</b></pre>
                            <rating [(ngModel)]="content[currentIndex].rating" [max]="ratingMax" [readonly]="true"></rating>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
    
</div>