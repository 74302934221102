import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormGroup, Validators, FormArray, FormBuilder, AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { Language } from '@frontend/common';

type contentVersions = {
  [key:string] : string
}

@Component({
  selector: 'multisite-form-text-multilingual',
  templateUrl: './form-text-multilingual.component.html',
  styleUrls: ['./form-text-multilingual.component.scss']
})
export class FormTextMultilingualComponent implements OnChanges {

    multilingualForm : FormGroup;
    @Input() rows : number; // shows a textarea if > 0 or a text input if falsy
    @Input() labelTextTranslationKey : string;
    @Input() labelText : string;
    @Input() minLength : number;
    @Input() maxLength : number;
    @Input() requiredLanguages : string[]; // ['en']
    validators: Validators[];
    @Input() placeholderTranslationKey : string;
    @Input() placeholderText : string;
    @Input() errorTranslationKey : string;
    @Input() loading : boolean;
    @Output() formSubmitted = new EventEmitter<{[key:string]: FormArray}>();
    @Input() languageVersions: Language[];
    @Input() languageVersionsCount: number;
    @Input() draftVersions: contentVersions;

  constructor(private formBuilder: FormBuilder) { 
    this.multilingualForm = this.formBuilder.group({
      languages: this.formBuilder.array([]) as FormArray
    });
    this.multilingualForm.get('languages').valueChanges.subscribe(() => {
      this.multilingualForm.updateValueAndValidity();
    });
  }
  
  submit(){
    this.formSubmitted.emit(this.multilingualForm.get('languages')['controls']);
  }
  getControlLanguageProperty(control:FormControl,propertyName:string){
    return this.languageVersions?.find(l=>l.iso === Object.keys(control)?.[0])?.[propertyName];
  }
  updateValidity(){
    this.multilingualForm.get('languages').updateValueAndValidity();
    this.multilingualForm.updateValueAndValidity();
  }
  makeValidators(){
    let validators : ValidatorFn[] = [];
    if (this.minLength){
      validators = [...validators, Validators.minLength(this.minLength)];
    }
    if (this.maxLength){
      validators = [...validators, Validators.maxLength(this.maxLength)];
    }
    return validators;
  }
  existingControlLacksInitialValue (existingControl,draftVersion){ // we got the initial value from the back end after we created the existingControl
    if (existingControl && draftVersion){
      return existingControl.value === null || existingControl.value === undefined;
    }
    return false;
  }

  addControlToFormArray(languages: Language[]) {
    const validators = this.makeValidators();
    for (const language of languages) {
      const existingControl = this.multilingualForm.get('languages')['controls'].find(c=>c.controls[language.iso])?.controls[language.iso];
      if (!existingControl){
        this.addFormControl(language, this.draftVersions?.[language.iso],validators)
      } else if (this.existingControlLacksInitialValue(existingControl,this.draftVersions?.[language.iso])){
        existingControl.setValue(this.draftVersions[language.iso]);
      }
    }
    this.multilingualForm.get('languages').updateValueAndValidity();
    this.multilingualForm.updateValueAndValidity();
  }
  removeControlFromFormArray(languages: Language[]) {
    const languageCodes = languages.map(l=>l.iso);
    this.multilingualForm.get('languages')['controls'] = this.multilingualForm.get('languages')['controls'].filter(c => Object.keys(c.controls).some(controlName => languageCodes.includes(controlName)));
    this.multilingualForm.get('languages').updateValueAndValidity();
    this.multilingualForm.updateValueAndValidity();
  }
  generateFormArrayFromLanguages(languages: Language[]) {
    const validators = this.makeValidators();
    for (const language of languages) {
      this.addFormControl(language, this.draftVersions?.[language.iso],validators)
    }
    this.multilingualForm.get('languages').updateValueAndValidity();
    this.multilingualForm.updateValueAndValidity();
  }

  addFormControl(language: Language, initialValue: string, validators) {
    let customValidators = validators;
    if (this.requiredLanguages?.includes(language.iso)){
      customValidators = [...customValidators, Validators.required];
    }
    const formControlsConfig = {};
    formControlsConfig[language.iso] = [initialValue,Validators.compose(customValidators)];
    this.multilingualForm.get('languages')['controls'].push(this.formBuilder.group(formControlsConfig));
  }

  ngOnChanges(changesObject): void {
    if (changesObject.languageVersionsCount?.currentValue === 0 || changesObject.languageVersionsCount?.currentValue > 0){
      if (!changesObject.languageVersionsCount.previousValue && changesObject.languageVersionsCount.previousValue !== 0){ // it is the first change, but firstChange() for some reason does not work
        this.generateFormArrayFromLanguages(this.languageVersions);
      } else if (changesObject.languageVersionsCount.currentValue > changesObject.languageVersionsCount?.previousValue){
        this.addControlToFormArray(this.languageVersions);
      } else if (changesObject.languageVersionsCount.currentValue < changesObject.languageVersionsCount?.previousValue){
        this.removeControlFromFormArray(this.languageVersions);
      }
    }
  }

}
