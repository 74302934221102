import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'multisite-website-logos',
  templateUrl: './website-logos.component.html',
  styleUrls: ['./website-logos.component.scss']
})
export class WebsiteLogosComponent implements OnInit {

  @Input() logo : string; // 'culturettt' or 'icpendulum' or 'culturequestion' etc
  @Input() lightColourOn: boolean; // the logo will always be light grey in dark mode and light mode
  @Input() darkColourOn: boolean; // the logo will always be light grey in dark mode and light mode

  constructor() { }

  ngOnInit(): void {
  }

}
