import { Component, OnInit, Input } from '@angular/core';

import { Page } from '@frontend/core';
import { BlockTypeOne } from '@frontend/core';


@Component({
  selector: 'frontend-page-display-type-two',
  templateUrl: './page-display-type-two.component.html',
  styleUrls: ['./page-display-type-two.component.scss']
})
export class PageDisplayTypeTwoComponent implements OnInit {

  // THIS PAGE TYPE IS GOOD FOR PAGES WITH BLOCKS PARSED FROM THE BODY

  @Input() page : Page;
  @Input() showVersion : boolean;
  @Input() showPublishedDate : boolean;
  @Input() showUpdatedDate : boolean;
  constructor() { 

/*      this.staticBlocks = [


      { 'type' : 'mdbCard',
      'background_image_url' : 'https://www.argonautonline.com/wp-content/uploads/2018/01/shutterstock_334291010-woman-individual-space-networking-16x9.jpg',
      'heading' : {'classes' : 'card-title h2', 'content' : 'Inclusively exclusive' },
      'classes' : 'text-white text-center rgba-stylish-strong py-5 px-4',
      'title' : 'For interculturalists',
      'subtitle' : null,
      'content' : 'If you\'re doing intercultural work, you\'re welcome here. And if you\'re just curious, you\'re welcome too. But the Intercultural Toolbox was created for interculturalists, so there are some extras for you here, if you fit into that category. Are you an interculturalist? If you are not sure, you\'re probably not. If you want to be one, then there is lots for you to explore here too.',
        'buttons' : [
          {
            'color':'white',
            'outline':true,
            'linkText': 'Yes, I\'m one',
            'icon': {'category': 'fas','type': 'check'},
            'routerLink':['/profile-editor'],
            'url': null
          }
        ]
      },
      { 'type' : 'jumbotron1',
        'background_image_url' : null,
        'heading' : null, // {'classes' : 'card-title h2', 'content' : 'In celebration of those who make global diversity work' },
        'classes' : 'jumbotron text-center mdb-color purple lighten-1 white-text my-4',
        'title' : 'In celebration of those who make global diversity work',
        'subtitle' : '- Richard Farkas, creator & curator of the Intercultural Toolbox',
        'content' : 'In the course of my job developing the CultureConnector service, I get to meet the most talented and inspiring people - the interculturalists. I\'ve learnt a huge amount from my conversations with them and have always been impressed with the work they do, for its complexity, sensitivity and for its importance to the development of a harmonious and diverse global community of cultures. This site is inspired by and made for the people who make crossing cultures work.',
        'buttons' : [
          {
            'color':'white',
            'outline':true,
            'linkText': 'Profile',
            'icon': {'category': 'fas','type': 'user'},
            'routerLink':['/hosts/richard_farkas_international'],
            'url': null
          },
          {
            'color':'white',
            'outline':true,
            'linkText': 'Start a conversation',
            'icon': {'category': 'far','type': 'envelope'},
            'routerLink':['/help'],
            'url': null
          }
        ]
      },
      { 'type' : 'jumbotron1',
        'background_image_url' : null,
        'heading' : null, // {'classes' : 'card-title h2', 'content' : 'In celebration of those who make global diversity work' },
        'classes' : 'jumbotron text-center mdb-color purple lighten-1 white-text my-4',
        'title' : 'Getting involved',
        'subtitle' : null,
        'content' : 'First, <a class=\"text-link\" href=\"/register\"><span class=\"text-white\">register</span></a> and get your interculturalist status with  <a class=\"text-link\" href=\"https://cebt.fi\" target=\"_blank\"><span class=\"text-white\">CEBT</span></a> (the organisation which produces the Intercultural Toolbox). When you are registered add your contributions via the comments on tools and episodes here. No comments today? Then please show some love by liking tools and episodes in this site or let our episode guests and tool contributors know you appreciated their input. If you have ideas for who or what should be in the Intercultural Toolbox in future, let us know.',
        'buttons' : [
          {
            'color':'white',
            'outline':true,
            'linkText': 'Comment on an episode',
            'icon': {'category': 'far','type': 'comment'},
            'routerLink':['/episodes'],
            'url': null
          },
          {
            'color':'white',
            'outline':true,
            'linkText': 'Comment on a tool',
            'icon': {'category': 'far','type': 'comment'},
            'routerLink':['/tools'],
            'url': null
          },
          {
            'color':'white',
            'outline':true,
            'linkText': 'Send a private message',
            'icon': {'category': 'fas','type': 'reply'},
            'routerLink':['/help'],
            'url': null
          }
        ]
      }
     
     ] */


  }

  ngOnInit(): void {
  }

}
