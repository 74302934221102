<div class="quiz">
  <div class="quiz-content">
    <div class="text-content">
      <p class="title">
        {{question.text}}
      </p>
      <p *ngIf="question.context">
        {{question.context}}
      </p>
    </div>
    <img class="img-responsive" *ngIf="question.media"
      [src]="question.media.asset_url && imageTransformations ? question.media.base_url + imageTransformations + question.media.asset_url : question.media.file_url"
      [alt]="question.media.description"
      (click)="openModal(imageEnlargedModal)"
      >
  </div>
  <form [formGroup]="multipleChoiceForm" role="form">
    <p>
      {{question.help}}
    </p>
    <ng-container *ngFor="let option of question.options; let i = index">
      <div class="form-check btn btn-light btn-sm" [ngClass]="{
          'correct-hit':feedbackObject[option.id]?.response_was_correct,
          'correct-miss': feedbackObject[option.id]?.response_was_missing,
          'incorrect':feedbackObject[option.id]?.response_was_correct === false
        }">
        <input class="form-check-input" type="radio" formControlName="options" id="{{option.id}}" [value]="option.id"
          [ngClass]="{
            'correct-hit':feedbackObject[option.id]?.response_was_correct,
            'correct-miss': feedbackObject[option.id]?.response_was_missing,
            'incorrect':feedbackObject[option.id]?.response_was_correct === false
          }">
        <div class="answer-icon" *ngIf="feedbackObject[option.id]?.response_was_correct"><i
            class="fa-solid fa-check"></i></div>
        <div class="answer-icon" *ngIf="feedbackObject[option.id]?.response_was_correct === false"><i
            class="fa-solid fa-xmark"></i></div>
        <div class="answer-icon" *ngIf="feedbackObject[option.id]?.response_was_missing"><i
            class="fa-solid fa-exclamation"></i></div>
        <label class="form-check-label" for="{{option.id}}">
          {{option.value}}
        </label>
      </div>
    </ng-container>
    <!-- <textarea placeholder="Write your answer here" disabled></textarea> -->
    <p *ngIf="feedbackText" class="multiple-choice-question-feedback my-2 p-3">
      <span class="icon-holder">
        <i class="fa-regular fa-lightbulb"></i>
      </span>
      <span>
        {{feedbackText}}
      </span>
    </p>
    <alert [type]="'info'" *ngIf="error_message_translation_key" class="py-2 w-100">
      <button
        (click)="clearError()"
        type="button" class="close float-right" aria-label="Close"
        >
        <span aria-hidden="true">×</span>
      </button>
      <p *transloco="let t" class="my-3">
        {{ t('error.'+error_message_translation_key) }}<br>
        <span  *ngIf="error_message_translation_key.indexOf('login_required') > -1">
          <a [routerLink]="['/login']">
            <strong>{{ t('common.log_in') }}</strong>
          </a>
        </span>
      </p>
    </alert>
    <button class="btn btn-primary my-4" type="submit" [disabled]="!multipleChoiceForm.value.options" *ngIf="!feedbackText && (!feedback || (feedback && showTryAgainButtonWithFeedback))"
      (click)="doSubmitResponse()">
      <span class="pull-left float-left" *ngIf="loading">
        <multisite-loader-globe-animation-detail [width]="24" [height]="24">
        </multisite-loader-globe-animation-detail>
      </span>
      <span *transloco="let t">
        {{feedback ? t(showTryAgainButtonWithFeedback) : t('common.submit')}}
      </span>
    </button>
  </form>
</div>



<!-- Modal -->

<ng-template #imageEnlargedModal>
  <div class="modal-header">
    <h4 id="dialog-static-name" class="modal-title pull-left">{{question.media.description}}</h4>
    <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">
        <i class="fa-solid fa-xmark"></i>
    </span>
    </button>
  </div>
  <div class="modal-body">
    <img class="img-responsive" *ngIf="question.media"
    [src]="question.media.file_url"
    [alt]="question.media.description"
    >
  </div>
</ng-template>