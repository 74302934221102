import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyHomeComponent } from './survey-home.component';
import { FormsModule } from '@angular/forms';
import { RatingModule } from 'ngx-bootstrap/rating';
import { AvatarsModule, PillsModule } from '@frontend/shared';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { LoadersModule } from '../../loaders/loaders.module';
import { AlertModule } from 'ngx-bootstrap/alert';



@NgModule({
  declarations: [
    SurveyHomeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RatingModule,
    AvatarsModule,
    PillsModule,
    RouterModule,
    TranslocoModule,
    LoadersModule,
    AlertModule
  ],
  exports : [
    SurveyHomeComponent
  ]
})
export class SurveyHomeModule { }
