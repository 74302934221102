<div class="container">
    <div class="mt-5 pt-5">
    
    </div>
    
    <p class="text-success">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quibusdam, molestias.</p>
    
    <div class="was-validated">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, corrupti.
    </div>
    
    <div class="btn btn-success">TEST</div>
    
    <div class="btn btn-outline-success">TEST 2</div>
    
    <a href="#" class="link-success"> TEST </a>
    
    <div class="border-success" style="width: 100px; height: 100px;"> LOREM </div>
    
    <p class="text-success">
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis, tempore?
    </p>
    
    <p class="bg-success">
        Lorem ipsum dolor sit amet.
    </p>
</div>