import { Component, OnInit, OnChanges, Input } from '@angular/core';

import { Link } from '@frontend/core';

@Component({
  selector: 'frontend-link-type-one',
  templateUrl: './link-type-one.component.html',
  styleUrls: ['./link-type-one.component.scss']
})
export class LinkTypeOneComponent implements OnInit, OnChanges {

  @Input() link : Link;
  @Input() hideButton : boolean;
  @Input() hideImage : boolean;
  @Input() imageTransformations : string = 'w_150,ar_16:9/';
  translationPath : string = "COMMON.";
  ctaTranslation : string;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changesObject): void {
    if (changesObject.link && changesObject.link.cta_type && changesObject.link.cta_type.currentValue){
      this.ctaTranslation = this.translationPath + changesObject.link.cta_type.toUpperCase();
    }
  }

}
