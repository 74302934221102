import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { IconTextListComponent } from './icon-text-list/icon-text-list.component';
import { ImageTextListComponent } from './image-text-list/image-text-list.component';



@NgModule({
  declarations: [
    IconTextListComponent,
    ImageTextListComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
  ],
  exports: [
    IconTextListComponent,
    ImageTextListComponent,
  ]
})
export class TextListsModule { }
