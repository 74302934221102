import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import {
    Translation,
    TranslocoLoader,
  } from '@jsverse/transloco';

@Injectable()
export class TranslocoCustomLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}
    
    getTranslation(lang: string): Observable<{ [key: string]: any }> {
        
        if (lang.includes('/')){ // for example, in the case of lazy-loaded 'hubs' translations, lang === 'hubs/en'
            return this.http.get<Translation>(`/assets/i18n/${lang}.json`)
        }

        const commonTranslations$ = this.http.get<Translation>(`/assets/i18n/multisite/${lang}.json`);
        const appTranslations$ = this.http.get<Translation>(`/assets/i18n/${lang}.json`);

        return forkJoin([commonTranslations$, appTranslations$]) // Using forkJoin to wait for both observables to complete
            .pipe(map(([commonTranslations, appTranslations]) => {
            const mergedTranslations = { ...commonTranslations, ...appTranslations };
            return mergedTranslations;
          })
        );
      }
}
