export class PlaylistTextItem {

    constructor (
        public identifier_numeric: number,
        public identifier_string: string,
        public name: string,
        public duration: number,
        public completed: boolean,
        public consumed: boolean,
        public locked: boolean,
        public badge: {classes: string, text: string, translationKey: string},
        ){
            this.identifier_numeric = identifier_numeric;
            this.identifier_string = identifier_string;
            this.name = name;
            this.duration = duration;
            this.completed = completed;
            this.consumed = consumed;
            this.locked = locked;
            this.badge = badge;
    }

  }