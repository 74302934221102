import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'multisite-brand-slug-editor',
  templateUrl: './brand-slug-editor.component.html',
  styleUrls: ['./brand-slug-editor.component.scss'],
})
export class BrandSlugEditorComponent implements OnInit {
  @Input() slugMinLength: number;
  @Output() cancelNewBrandMode = new EventEmitter<boolean>();
  @Output() submitNameAndSlug = new EventEmitter<{name:string,slug:string}>(); // 'easy' or 'advanced' or 'expert'
  loading: boolean;
  @Input() error: string;
  slugForm: FormGroup;
  rulesCollapsed : boolean = true;
  collapsedContent : {[key:string]:boolean} = {
    rules: true,
    help: true,
  }
  slugRuleCount:number = 3;
  slugHelpCount:number = 3;
  slugRulesEmptyIterator: number[];
  slugHelpEmptyIterator: number[];

  constructor(
  ) {}

  setUpForm() {
    this.slugForm = new FormGroup({
      slug: new FormControl(null, [Validators.required, Validators.minLength(this.slugMinLength), Validators.maxLength(30), Validators.pattern(/^(?![\s\S]*[ .\/\\\\])[\s\S]*$/)]), // regex for spaces and dots
      name: new FormControl(null, [Validators.required, Validators.minLength(this.slugMinLength), Validators.maxLength(100)]),
    });
    this.slugForm.controls.slug.valueChanges.subscribe((value) => {
      this.error = null;
    });
    this.slugForm.controls.name.valueChanges.subscribe((value) => {
      this.error = null;
    });
  }
  doCancelNewBrandMode (){
    this.cancelNewBrandMode.emit(true);
  }
  doSave (){
    this.submitNameAndSlug.emit({name:this.slugForm.controls.name.value,slug:this.slugForm.controls.slug.value});
  }
  toggleCollapsedContent (contentName){
    for (let content in this.collapsedContent){
      if (contentName === content){
        this.collapsedContent[content] = !this.collapsedContent[content];
      } else {
        this.collapsedContent[content] = true;
      }
    }
  }

  ngOnInit(): void {
    this.error = null;
    this.slugRulesEmptyIterator = new Array(this.slugRuleCount).fill(0).map((_, index) => index + 1);
    this.slugHelpEmptyIterator = new Array(this.slugHelpCount).fill(0).map((_, index) => index + 1);
    this.setUpForm();
    
  }

}
