import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataProcessingService {

  constructor() { }

  matchTwoComplexObjects(object1: { [key: string]: any }, object2: { [key: string]: any }): boolean {
    // returns true if the two objects have the same properties and values, regardless of order and checks if any arrays have the same values, regardless of order

    if (!object1 && !object2) {
        return true;
    }
    if (!object1 || !object2) {
        return false;
    }

    const object1Keys = Object.keys(object1).filter(key => key !== '$$hashKey'); // $$hashKey is an AngularJS thing; remove this filter after NgUpgrade
    const object2Keys = Object.keys(object2).filter(key => key !== '$$hashKey'); // $$hashKey is an AngularJS thing; remove this filter after NgUpgrade
    if (object1Keys.length !== object2Keys.length) {
        return false;
    }
    for (let key of object1Keys) {
        if (!object2Keys.includes(key)) {
            return false;
        }
        const object2ChildValue = object2[key];
        const object1ChildValue = object1[key];
        if (Array.isArray(object2ChildValue) && Array.isArray(object1ChildValue)) {
            const matchArrays = this.matchTwoArrays(object1ChildValue, object2ChildValue);
            if (!matchArrays) {
                return false;
            }
        } else if (object2ChildValue !== object1ChildValue) {
            if (typeof object2ChildValue === 'object' && typeof object1ChildValue === 'object') {
                const matchObjects = this.matchTwoObjects(object1ChildValue, object2ChildValue);
                if (!matchObjects) {
                    return false;
                }
            } else {
                return false;
            }
        }
    }
    return true;
}

matchTwoObjects(object1: { [key: string]: any }, object2: { [key: string]: any }): boolean {

    if (!object1 && !object2) {
        return true;
    } else if (!object1 || !object2) {
        return false;
    }

    if (typeof object1 !== 'object' && typeof object2 === 'object') {
        return false;
    } else if (typeof object1 === 'object' && typeof object2 !== 'object') {
        return false;
    }

    const object2ChildKeys = Object.keys(object2).filter(key => key !== '$$hashKey'); // $$hashKey is an AngularJS thing; remove this filter after NgUpgrade;
    const object1ChildKeys = Object.keys(object1).filter(key => key !== '$$hashKey'); // $$hashKey is an AngularJS thing; remove this filter after NgUpgrade;

    if (object2ChildKeys.length !== object1ChildKeys.length) {
        return false;
    }

    for (let childKey of object2ChildKeys) {
        if (!object1ChildKeys.includes(childKey)) {
            return false;
        }
        const object2ChildValue = object2[childKey];
        const object1ChildValue = object1[childKey];

        if (Array.isArray(object2ChildValue) && Array.isArray(object1ChildValue)) {
            const matchArrays = this.matchTwoArrays(object1ChildValue, object2ChildValue);
            if (!matchArrays) {
                return false;
            }
        } else if (object2ChildValue !== object1ChildValue) {
            if (typeof object2ChildValue === 'object' && typeof object1ChildValue === 'object') {
                const matchObjects = this.matchTwoObjects(object1ChildValue, object2ChildValue);
                if (!matchObjects) {
                    return false;
                }
            } else {
                return false;
            }
        }
    }
    return true;
};

matchTwoArrays(array1: any[], array2: any[]): boolean {

    // returns true if the two arrays have the same values, regardless of order
    // Will compare primitive values or arrays of arrays, but not objects or arrays of objects
    // Matching arrays of arrays has some limitations (uses Javascript default sort for matching the child arrays)

    if (!array1 && !array2) {
        return true;
    } else if (!array1 || !array2) {
        return false;
    } else if (Array.isArray(array1) && !Array.isArray(array2)) {
        return false;
    } else if (!Array.isArray(array1) && Array.isArray(array2)) {
        return false;
    }

    if (array1.length !== array2.length) {
        return false;
    }

    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();


    for (let i = 0; i < sortedArray1.length; i++) {

        if (Array.isArray(sortedArray2[i]) && Array.isArray(sortedArray1[i])) {
            let childArray2 = sortedArray2[i];
            let childArray1 = sortedArray1[i];
            let matchArrays = this.matchTwoArrays(childArray1, childArray2);
            if (!matchArrays) {
                return false;
            }
        } else if (sortedArray1[i] !== sortedArray2[i]) {
            if (typeof sortedArray2[i] === 'object' && typeof sortedArray1[i] === 'object') {
                const matchObjects = this.matchTwoObjects(sortedArray1[i], sortedArray2[i]);
                if (!matchObjects) {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    return true;
}

  shuffleArray(arr : any[] = []) {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  }
  getRandomInteger(min : number, max : number) : number {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  cleanString (string : string, allowKebab : boolean) : string {
    if (typeof string !== 'string'){
      return '';
    }
    let regex = (allowKebab) ? /[^a-zA-Z0-9- ]/g : /[^a-zA-Z0-9 ]/g
    return string.replace(regex, '').split(" ").join("");
  }
  convertModelNameToBackendClass (modelNameSingular : string){
    return 'App\\Models\\' + modelNameSingular.charAt(0).toUpperCase() + modelNameSingular.slice(1); 
  }
  convertBackendClassToModelName (classNameWithPath : string){
    if (!classNameWithPath) {return ''};
    return classNameWithPath.slice(classNameWithPath.lastIndexOf("\\")).toLowerCase(); 
  }
  convertMillisecondsToMinutes (milliseconds:number, minMillisecondsMinuteFormat: number){

    minMillisecondsMinuteFormat = minMillisecondsMinuteFormat ? minMillisecondsMinuteFormat : 59000; // highest number of milliseconds before we start to round to the nearest minute
    return milliseconds > minMillisecondsMinuteFormat ? Math.round(milliseconds/60000) : +((milliseconds/100000) % 60).toFixed(2).slice(-2);
  }
  truncateText(sourceText : string, length : number){ // TODO replace deprecated substr
    let truncatedText = sourceText.substr(0, length);
    return truncatedText.trim()+'...';
  }
  getRandomNumberWithinRange(min : number, max : number) {
    const floatRandom = Math.random()
    const difference = max - min
    const random = Math.round(difference * floatRandom)
    return random + min;
  }
  convertPlainObjectToFormData(obj: any): FormData {
    const formData = new FormData();
  
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
  
        if (Array.isArray(value)) {
          value.forEach((item: any) => formData.append(key, item));
        } else {
          formData.append(key, value);
        }
      }
    }
  
    return formData;
  }
  calculateStepsFromPercentScore (
    percentScore: number, // the user's score
    minimumScore : number = 50, // user score below which the user will score 0 steps
    maximumScore : number = 90, // user score above (or equal to) which the user will score maximumSteps
    maximumSteps : number = 8 // steps (increments or scoring bands between the lowest and highest)
      ): number {
    // converts a percent score into a number on scale between arbitrary lowest score and highest score. e.g. 55% = 1 and 65% = 3 and 90% = 8
    if (percentScore < minimumScore){
      return 0;
    } else if (percentScore >= maximumScore) {
      return maximumSteps;
    }
    return Math.ceil((percentScore-minimumScore)/((maximumScore-minimumScore)/(maximumSteps-1)));
  }

  mergeTwoArraysWithUniqueIdentifiersAvoidingDuplicates (array1 : any[], array2 : any[], identifierPropertyName:string){
      const combinedArray = [...array1, ...array2];
      const uniqueItems = new Set();

      const mergedArray = combinedArray.filter((item) => {
        if (!uniqueItems.has(item[identifierPropertyName])) {
          uniqueItems.add(item[identifierPropertyName]);
          return true;
        }
        return false;
      });

      return mergedArray;
  }
  sortArrayOfObjectsByProperty (array : any[], propertyKey : string){
    if (!array?.length || !propertyKey) {
      return array;
    }
  
    return array.sort((a, b) => {
      const valueA = a[propertyKey];
      const valueB = b[propertyKey];
  
      // Handle null values
      if (valueA === null && valueB !== null) {
        return -1;
      } else if (valueA !== null && valueB === null) {
        return 1;
      }
  
      // If both values are strings, use localeCompare for string comparison
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      }
  
      // Otherwise, handle numerical values
      return (valueA || 0) - (valueB || 0);
    });

  }
  get urlRegex (){
    return /^((http|https|ftp):\/\/)?([\w-]+\.)+[\w-]+(\/[\w- ;,./?%&=]*)?$/;
  }

  pickRandomItems(array, numberOfItems) {
    if(!array || !numberOfItems){return []};
    const shuffled = array.slice().sort(() => Math.random() - 0.5);
    return shuffled.slice(0, numberOfItems);
  }
  areSearchParamsEqual(params1 : URLSearchParams, params2 : URLSearchParams) {
   
    const sortedParams1 = Array.from((params1 as any).entries()) // TODO - the (params as any) hack is because TypeScript is out of date??
      .sort((a, b) => a[0].localeCompare(b[0]))
      .toString();
    const sortedParams2 = Array.from((params2 as any).entries()) // TODO - the (params as any) hack is because TypeScript is out of date??
      .sort((a, b) => a[0].localeCompare(b[0]))
      .toString();
  
    return sortedParams1 === sortedParams2;
  }
  areSearchParamsEqualDisregardingPageNumbers(params1 : URLSearchParams, params2 : URLSearchParams) {
   
    const page1 = params1.get('page');
    const page2 = params2.get('page');
    params1.delete('page');
    params2.delete('page');
  
    const result = this.areSearchParamsEqual(params1,params2);

    if(page1){ // TODO - review is this necessary ? is this argument a reference to the object in the calling function's context?
      params1.set('page',page1);
    }
    if(page2){
      params2.set('page',page2);
    }
    return result;
  }
  convertObjectToSearchParams(filters : {[key:string]:string|string[]}) {
    const searchParams = new URLSearchParams();

    for (const key in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, key)) {
          const value = filters[key];
          if (Array.isArray(value)) {
            searchParams.set(key, value.join(','));
          } else {
            searchParams.set(key, value);
          }
        }
    }

    return searchParams;
  
  }
  
  
}
