<div class="status-indicator-listing" *transloco="let t">
  <div class="header justify-content-center">
    <h5>{{titleTranslationKey ? t(titleTranslationKey) : titleText}}</h5>
  </div>
  <p class="text-muted small my-2 d-flex align-items-start gap-1" *ngIf="helpTranslationKey || helpText">
    <i *ngIf="helpIconClasses" [class]="'mr-1 pt-1 '+helpIconClasses"></i>
    {{helpTranslationKey ? t(helpTranslationKey) : helpText}}
  </p>
  <div class="status-indicator-listing-item" [ngClass]="{clickable: item[clickableProperty]}"
    *ngFor="let item of displayedItems()"
    (click)="doLinkClick(item)">
    <div class="indicator-holder">
      <progress-indicator-doughnut [elementWidth]="100"
        [fillClass]="item.fillClass"
        [progressPercent]="item.progress"
        [icon]="item.icon">
      </progress-indicator-doughnut>
    </div>
    <div class="text-start" *transloco="let t">
      <p>{{item.titleTranslationKey ? t(item.titleTranslationKey) : item.titleText}}</p>
    </div>
  </div>
  <div class="footer" *ngIf="initialListDisplayLength && items?.length > initialListDisplayLength">
      <a class="link-primary" (click)="showMore = !showMore">
        <span *ngIf="!showMore"><i class="fa-solid fa-angle-down mr-2"></i>{{t('common.show_more')}}</span>
        <span *ngIf="showMore"><i class="fa-solid fa-angle-up mr-2"></i>{{t('common.show_less')}}</span>
      </a>
  </div>
</div>