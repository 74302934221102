import { 
  // ChangeDetectionStrategy,
  HostBinding,
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild, Renderer2 } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { LickertQuestion } from './question-lickert.model';
import { LickertResponse } from '@frontend/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'multisite-question-lickert',
  templateUrl: './question-lickert.component.html',
  styleUrls: ['./question-lickert.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush, // this solves the harmless ExpressionChangedAfterItHasBeenCheckedError error in the browser. But ChangeDetectionStrategy.OnPush causes the offsetHeight and offsetWidth properties in the template to stop working. TODO: we probably need a custom change detection strategy for this component, to solve the ExpressionChangedAfterItHasBeenCheckedError error. Low priority.
})
export class QuestionLickertComponent implements OnInit, OnChanges {

  @Input() question : LickertQuestion;
  @Input() feedback : any;
  @Input() feedbackObject : any = {};
  @ViewChild('sliderInput') sliderInput: ElementRef;
  @ViewChild('labelHolder') labelHolder: ElementRef;
  @Input() showTryAgainButtonWithFeedback : string; // a translationKey, for example 'survey.try_again', if null, don't show the button at all
  @Input() imageTransformations : string; // 'w_275,ar_16:9/'
  @Output() submitResponse = new EventEmitter<LickertResponse>()
  @Input() surveyProgressValue : number; // 22
  @Input() surveyProgressUnit : string; // '%'
  @Input() loading : boolean;
  @Input() hideSubmitButton : boolean; // for example in demo mode we don't want the user to submit a response
  @Input() error_message_translation_key : string;
  lickertForm: FormGroup;
  feedbackText : string;
  @ViewChild('imageEnlargedModal') imageEnlargedModal;
  modalRef?: BsModalRef;
  initialValue : number = 50;
  inputValue : number;
  shapePosition : number = 0;
  bellCurveRelativeWidth = 25; // 25% of the parent container
  bellCurveRelativeScaleY = .25; // 25% of the parent container
  // isAnimationActive = false;
  sliderActive : boolean = false; // the user is moving the range input control

  constructor( private modalService: BsModalService, private renderer: Renderer2, private el: ElementRef) { 
      this.inputValue = this.initialValue;
  }

  doSubmitResponse(){
    // TODO - support multiselect (use formArray instead of formGroup?)
    this.feedback = null;
    this.feedbackText = null;
    this.feedbackObject = {};
    this.submitResponse.emit(new LickertResponse(this.question.id,this.question.question_id,this.question.survey_id,this.lickertForm.get('response').value))
  }
  clearError (){
    this.error_message_translation_key = null;
  }

  onSliderMouseDown() {
    this.sliderActive = true;
  }
  onSliderMouseUp() {
    this.sliderActive = false;
  }

  setProperty(height){
    this.renderer.setStyle(this.el.nativeElement, '--element-height', height + 'px');
  }

  isAnchorActive(anchorIndex){
    if (anchorIndex === 0 && this.lickertForm.get('response').value === 0){
      // console.log('scenario 1');
      return true;
    } else if (anchorIndex === this.question.anchor_set.length-1 && this.lickertForm.get('response').value === 100){
      // console.log('scenario 2');
      return true;
    } else if (
      (this.lickertForm.get('response').value < (100/this.question.anchor_set.length)*(anchorIndex+1)) &&
      (this.lickertForm.get('response').value >= (100/this.question.anchor_set.length)*(anchorIndex)) &&
      this.lickertForm.get('response').value // this last condition prevents true when the value is null
      ){
      // console.log('scenario 3');
      return true;
      }
      // console.log('scenario 4');
      return false;
  }

  setSliderActiveBriefly (){
    this.sliderActive = true;
    setTimeout(()=>{
      this.sliderActive = false;
    },500);
  }

  setValueByLabel(labelIndex){
    let newValue = this.initialValue ?? 0;
    let anchorValue = (100/this.question.anchor_set.length);
    if (this.question.anchor_set.length === 2){
      newValue = labelIndex ? 100 : 0;
    } else {
      newValue = labelIndex ? (anchorValue*labelIndex) + (anchorValue/2) : (anchorValue/2);
    } 
    this.lickertForm.get('response').setValue(Math.round(newValue));
    this.setSliderActiveBriefly();
  }
  setValueByValue(newValue:number){
    this.lickertForm.get('response').setValue(Math.round(newValue));
    this.setSliderActiveBriefly();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'image-enlarge-modal' })
    );
  }

  isValidValue(): boolean {
    return this.lickertForm.get('response').value === 0 || this.lickertForm.get('response').value > 0;
  }
  whatIsTheValue(){
    return this.lickertForm.get('response').value;
  }

  ngOnChanges(changesObject) {
    if (changesObject.feedback && changesObject.feedback.currentValue?.correction?.length){
      this.feedback.correction.forEach(f=>{
        this.feedbackObject[f.id]= f;
      });
      this.feedbackText = this.feedback.feedback;
    }

  }
  // setElementHeightProperty (height:number){
  //   // const height = this.labelHolder.nativeElement.offsetHeight;
  //   this.renderer.setStyle(this.el.nativeElement, '--element-height', height + 'px');
  // }

  ngAfterViewInit() {
    this.setProperty(0);
  }
  ngOnInit(): void {
    this.lickertForm = new FormGroup({
      response: new FormControl()
    });
    this.lickertForm.get('response').valueChanges.subscribe((value) => {
      this.inputValue = value;
    });
  }

}
