<div class="d-md-flex flex-md-fill px-1">
  <div class="d-flex justify-content-center mr-md-5 mt-md-5 mt-4">
    <img src="{{poster.picture || defaultAvatar}}" [alt]="poster.fname+' '+poster.lname" class="card-img-100 z-depth-1 rounded-circle">
  </div>
    <div class="md-form w-100">
      <textarea
        class="form-control md-textarea pt-0"
        id="commentTextArea"
        rows="5"
        name="message"
        [disabled]="loading"
        [(ngModel)]="message"
        placeholder="{{'COMMON.WRITE_SOMETHING_HERE' | transloco}}">
      </textarea>
    </div>
</div>
<div class="text-center">
  <button mdbBtn color="primary" rounded="true" mdbWavesEffect
    (click)="doPostComment()"
    [disabled]="loading"
  *transloco="let t">{{ t('COMMON.POST_A_COMMENT')}}</button>
</div>