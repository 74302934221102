import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiRaterPanelComponent } from './multi-rater-panel/multi-rater-panel.component';
import { AvatarsModule } from '@frontend/shared';



@NgModule({
  declarations: [
    MultiRaterPanelComponent
  ],
  imports: [
    CommonModule,
    AvatarsModule
  ],
  exports: [
    MultiRaterPanelComponent
  ],
})
export class SurveyRatersModule { }
