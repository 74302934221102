import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkTypeSixComponent } from './link-type-six/link-type-six.component';
import { LinkTypeSevenListComponent } from './link-type-seven-list/link-type-seven-list.component';
import { LinkTypeEightComponent } from './link-type-eight/link-type-eight.component';
import { TranslocoModule } from '@jsverse/transloco';
import { LinkTypeNineComponent } from './link-type-nine/link-type-nine.component';
import { LinkTypeTenComponent } from './link-type-ten/link-type-ten.component';
import { LinkTypeElevenComponent } from './link-type-eleven/link-type-eleven.component';
// import { LinkTypeOneComponent } from './link-type-one/link-type-one.component';
import { ImagesModule } from '../images';
import { LinkTypeOneModule } from './link-type-one/link-type-one.module';
import { LinkTypeThreeOverlayComponent } from './link-type-three-overlay/link-type-three-overlay.component';
// import { LinkTypeFiveComponent } from './link-type-five/link-type-five.component';



@NgModule({
  declarations: [
    LinkTypeSixComponent,
    LinkTypeSevenListComponent,
    LinkTypeEightComponent,
    LinkTypeNineComponent,
    LinkTypeTenComponent,
    LinkTypeElevenComponent,
    // LinkTypeFiveComponent, // TODO - add these link type if still needed when IC Toolbox has been upgraded
    LinkTypeThreeOverlayComponent,
    // LinkTypeTwoComponent,
    // LinkTypeOneComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    ImagesModule,
    LinkTypeOneModule,
  ],
  exports: [
    // LinkTypeFiveComponent,
    LinkTypeThreeOverlayComponent,
    LinkTypeSixComponent,
    LinkTypeSevenListComponent,
    LinkTypeEightComponent,
    LinkTypeNineComponent,
    LinkTypeTenComponent,
    LinkTypeElevenComponent,
  ],
})
export class LinksModule { }
