<div class="row mb-3">
  <div class="col-lg mb-3">
    <div class="card status-list-item-base flex-column flex-md-row">
      <div class="infobar flex-row flex-md-column">
        <div class="media-holder">
      
          @if(media && !statusIconClass){
            <img [src]="
              media.asset_url && imageTransformations
                ? media.base_url + imageTransformations + media.asset_url
                : media.file_url
            "
            [alt]="media.description"
          />
          }
          @if(icon && !media && !statusIconClass){
            <i [class]="icon.identifier_1" *ngIf="icon.identifier_1"></i>
            <img *ngIf="!icon.identifier_1 && icon.identifier_2" [src]="cloudinary_base_url+'w_32,c_fill,ar_1:1,r_max,f_auto/'+icon.identifier_2+'.svg'" class="mr-1">
          }

          <span *ngIf="statusIconClass">
            <i [class]="statusIconClass"></i>
          </span>
          <span *ngIf="statusLabelTranslationKey || statusLabelText">
            <span *transloco="let t">{{statusLabelTranslationKey ? t(statusLabelTranslationKey) : statusLabelText }}</span>
          </span>
        </div>

        <!-- Place your icon code here -->

        <div class="timer" *ngIf="minutes">
          <i class="fa-solid fa-hourglass-half"></i>
          <span class="ml-2">{{round(minutes)}}:00</span>
          <span class="ml-1" *transloco="let t">{{t('common.minutes_abbreviated')}}</span>
        </div>
        <!-- <hr class="w-50 my-0" /> -->
        <!-- <div class="award-star">
          <span class="value">+2</span>
          <i class="fa-solid fa-star"></i>
        </div> -->
      </div>
      <div
        class="card-body p-2 d-flex flex-column justify-content-between gap-2"
      >
        <div class="content">
          <div class="title-group">
            <h5 class="card-title font-weight-bold">
              {{ title }}
            </h5>
            <span class="icons-holder" *ngIf="titleIconClass">
              <span *ngIf="titleIconClass"><i [class]="titleIconClass"></i></span>
            </span>
          </div>
          <p class="card-text">{{ description }}</p>
        </div>
        <div class="actions flex-column flex-md-row mt-2">
          <div
            class="mini-progress-bar"
            [style]="'--progress-value: ' + progressValue + '%'"
            [attr.aria-valuenow]="progressValue"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-labelledby="progresslabel"
            *ngIf="progressValue && progressValue < 100"
          >
            <div class="bar">
              <div class="fill"></div>
            </div>
          </div>

          <button type="button" class="btn btn-primary align-self-end">
            <span *transloco="let t">
              <span>
                {{
                  buttonTextTranslationKey
                    ? t(buttonTextTranslationKey)
                    : buttonText
                }}
              </span>
            </span>
            <i class="ml-2 fa-solid fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
