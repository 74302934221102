import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormTextMultilingualComponent } from './form-text-multilingual/form-text-multilingual.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { LoadersModule } from '../../../../../common/src/lib/loaders';



@NgModule({
  declarations: [
    FormTextMultilingualComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    LoadersModule
  ],
  exports: [
    FormTextMultilingualComponent
  ]
})
export class MultilingualFormsModule { }
