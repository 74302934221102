import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailInputFormComponent } from './email-input-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { LoadersModule } from '../../../../../../common/src/lib/loaders';



@NgModule({
  declarations: [
    EmailInputFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    LoadersModule,
  ],
  exports: [
    EmailInputFormComponent
  ]
})
export class EmailInputFormModule { }
