import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    UrlTree
  } from '@angular/router';
  import { Injectable } from '@angular/core';
  import { Observable, of } from 'rxjs';
  import { AuthService } from '@frontend/common';
  import { map, filter, timeout, catchError, take } from 'rxjs/operators';
  
  @Injectable({ providedIn: 'root' })
  export class AuthOrGuestGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}
    canActivate(
      route: ActivatedRouteSnapshot,
      router: RouterStateSnapshot
    ): boolean | Promise<boolean> | Observable<boolean | UrlTree> {
      const guest = JSON.parse(localStorage.getItem('guest'));
      if (guest?.uuid){
        return of(true);
      }
      return this.authService.user.pipe(
        filter(user => user !== undefined),
        take(1),
        map(user => {
          const isAuth = !!user;
  
          if (isAuth) {
            return true;
          }
  
          return this.router.createUrlTree(['/login']);
        }),
        timeout(15000), // TODO - improve this logic; if this.authService.user takes longer than these milliseconds on first load, we'll redirect to login without waiting for the response. Not good.
        catchError(()=>{
          return of(this.router.createUrlTree(['/login']))
        })
      );
    }
  }