import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'multisite-brand-font-editor',
  templateUrl: './brand-font-editor.component.html',
  styleUrls: ['./brand-font-editor.component.scss']
})
export class BrandFontEditorComponent implements OnInit {

  @Input() font : string;
  @Output() updateFont = new EventEmitter<string>();
  @Input() disabled : boolean;
  fontForm: FormGroup;
  error : string;

  constructor() {}

  setUpForm (){
    this.fontForm = new FormGroup({
      fontFormat: new FormControl(this.font, [Validators.required])
    });
    this.fontForm.controls.fontFormat.valueChanges.subscribe(
      (value) => {
        this.updateFont.emit(value);
      }
    );
  }


  ngOnInit(): void {
    this.setUpForm ();
  };
  
}
