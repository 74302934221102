import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessVisualisationPromoComponent } from './process-visualisation-promo.component';
import { ProgressIndicatorDoughnutModule } from '../../../tracking';
import { TranslocoModule } from '@jsverse/transloco';
import { CertificationDisplayListsModule } from '../../../certification/certification-display/certification-display-lists/certification-display-lists.module';



@NgModule({
  declarations: [
    ProcessVisualisationPromoComponent
  ],
  imports: [
    CommonModule,
    ProgressIndicatorDoughnutModule,
    TranslocoModule,
    CertificationDisplayListsModule
  ],
  exports: [
    ProcessVisualisationPromoComponent
  ]
})
export class ProcessVisualisationPromoModule { }
