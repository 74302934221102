<alert [type]="'warning'" [dismissOnTimeout]="3500" *ngIf="error"><span *transloco="let t">{{t(error)}}</span></alert>

  <div class="row">
    <div class="col">
      <div *transloco="let t">
  
        <form [formGroup]="visibilityForm" role="form" class="mt-4" *ngIf="visibilityForm">
          <div class="form-check">
            <input
              value="high_hub_visibiity"
              formControlName="visibility"
              class="form-check-input"
              type="radio"
              id="high"
              [attr.disabled]="disabled ? true : null"
            />
            <label class="form-check-label" for="high">
              {{t('branding.high_visibility')}}
            </label>
          </div>
          <div class="form-check">
            <input
              value="low_hub_visibility"
              formControlName="visibility"
              class="form-check-input"
              type="radio"
              id="low"
              [attr.disabled]="disabled ? true : null"
            />
            <label class="form-check-label" for="low">
              {{t('branding.low_visibility')}}
            </label>
          </div>
        </form>
        <p class="multiple-choice-question-feedback my-2 p-3">
          <span class="icon-holder">
            <i class="fa-regular fa-lightbulb"></i>
          </span>
          <span>
            {{t('branding.low_visibility_help')}}
          </span>
        </p>
      </div>
    </div>
  </div>
