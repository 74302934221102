import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BrandColourSwatch } from '../brand-colour-swatch.model';

@Component({
  selector: 'multisite-colour-swatches',
  templateUrl: './colour-swatches.component.html',
  styleUrls: ['./colour-swatches.component.scss']
})
export class ColourSwatchesComponent implements OnInit {

  @Input() swatches : BrandColourSwatch[];
  @Input() showHexCodes : boolean;
  @Input() type : string; // 'default', 'draft' or 'saved'
  @Input() editMode : string; // null or 'normal' or 'advanced'
  @Output() swatchChange = new EventEmitter<{swatch: BrandColourSwatch,variant:string}>();

  constructor() { }

  onInputChange (swatch : BrandColourSwatch, variant){
    this.swatchChange.emit({swatch,variant}
    )
  }

  ngOnInit(): void {
  }

}
