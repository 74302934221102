import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime } from "rxjs/operators";

import { Link } from '@frontend/core';

@Component({
  selector: 'frontend-form-input-single-link',
  templateUrl: './form-input-single-link.component.html',
  styleUrls: ['./form-input-single-link.component.scss']
})
export class FormInputSingleLinkComponent implements OnInit, OnChanges {

  @Input() link : Link;
  @Input() debounceTime : number = 7500;
  @ViewChild('lForm', { static: false }) linkForm: NgForm;
  @Output() inputValueChanged = new EventEmitter<Link>();

  linkChanged = new Subject<Link>();
  linkPivotType : string;


  constructor() {
    this.linkChanged.pipe(debounceTime(this.debounceTime)).subscribe(() => {this.doOutput ();});
   }

  changedInputValue (){
    this.linkChanged.next(this.link)
  }
  doOutput (){
    if (this.linkForm.form.status == 'VALID'){
      this.inputValueChanged.emit(this.link);
    };
    
  }

  ngOnChanges(changesObject): void {
    if (changesObject.link && changesObject.link.currentValue?.pivot?.type){
      this.linkPivotType = changesObject.link.currentValue.pivot.type.toUpperCase();
    }
  }
  ngOnInit(): void {
  }

}
