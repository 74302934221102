import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveysSnapshotStepperComponent } from './surveys-snapshot-stepper.component';
import { TranslocoModule } from '@jsverse/transloco';
import { SteppersModule } from '@frontend/shared';
import { RouterModule } from '@angular/router';
import { SnapshotsModule } from '../snapshots';
import { FormsModule } from '@angular/forms';
import { LoadersModule } from '../../loaders';
import { AccessCodeFormModule } from '@frontend/shared';
import { BannersModule } from '@frontend/common';
import { GalleryCtaModule } from '../../content/banners/gallery-cta/gallery-cta.module';



@NgModule({
  declarations: [
    SurveysSnapshotStepperComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslocoModule,
    SteppersModule,
    SnapshotsModule,
    LoadersModule,
    AccessCodeFormModule,
    GalleryCtaModule,
  ],
  exports: [
    SurveysSnapshotStepperComponent
  ]
})
export class SurveysSnapshotStepperModule { }
