import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { CloudinaryMediaAsset, ContentItem, DataProcessingService, LanguageService, MediaService } from '@frontend/common';
import { Topic } from './topic.model'
import { IModelsToContentItemsConverter, Link, Tag } from '@frontend/core';
import { Icon, Review } from '@frontend/shared';

interface TopicFromBackend {
  id: number, //4
  name: string, // "Intercultural Pendulum
  description: string, //"The IC Pendulum helps coaches get faster,....<a href=\\\"https:\\\/\\\/www.icp
  meta: any, // {"published_at":"2023-01-28 13:00:03"}
  category: string, //"interculturalist"
  type: string,
  weight: number,
  icon: Icon,
  slug: string, //"icpendulum"
  links: Link[],
  media: CloudinaryMediaAsset[], //[{"id":33,"medially_type":"App\\Models\\Topic","medially_id":4,"file_url":"https:\/\/res.cloudinary.com\/cebt\/image\/upload\/v1674243951\/concepts\/meditating-woman-sitting-in-pose-of-lotus-against-clear-sky-outdoors-SBI-300731584_ie8o5r.jpg","file_name":"concepts\/meditating-woman-sitting-in-pose-of-lotus-against-clear-sky-outdoors-SBI-300731584_ie8o5r.jpg","file_type":"image","size":5250000,"uploader_id":1,"description":"Woman sits cross-legged, apparently meditating at sunset","credits":"Storyblocks","permission":"","category":"thumbnail","type":"main","created_at":"2023-02-06T18:47:23.000000Z","updated_at":"2023-02-06T18:47:23.000000Z"}]
  reviews: Review[],
  created_at: '2023-01-07 13:00:03',
  updated_at: '2023-01-07 13:00:03',
}


@Injectable({
  providedIn: 'root'
})
export class TopicService implements IModelsToContentItemsConverter {

  topics : Topic[] = [];
  standaloneTopics : Topic[] = [];
  activeLanguageSubscription: Subscription;
  allTopicsReceived : Date; // have we requested and got all topics (no category and no type) ?

  constructor(
    private languageService: LanguageService,
    private http: HttpClient,
    private mediaService : MediaService,
    private dataProcessingService : DataProcessingService,
  ) 
  {
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
  }

    clearTranslations (){
      this.topics = [];
      this.standaloneTopics = [];
    }

  // getPlaceholder () { // generate a dummy Topic

  //   let topic : Topic = {
  //     id: 100000001,
  //     name : 'Dolore ex consequat ipsum aute ad',
  //     description: '<p>Sit ut dolor pariatur irure ex. Aliquip dolore qui nulla est sunt ad cillum eiusmod duis esse magna do aute. <a href="http://www.cultureconnector.com">cco online</a></p>',
  //     slug: JSON.stringify('this-is-the-topic-slug'+Math.random()*1000),
  //     media : null, // get this from the media.service
  //     reviews: null,
  //     created_at: '2023-01-07 13:00:03',
  //     updated_at: '2023-01-07 13:00:03',
  //   };
  //   return topic;
  // };
  transformTopic (topicResponse : TopicFromBackend){
    let mediaArray : CloudinaryMediaAsset[] = [];
    if (topicResponse.media?.length){
      topicResponse.media.forEach(m => {
        mediaArray.push(Object.assign(m, this.mediaService.setupCloudinaryImageMediaUrls(m)));
      });
    }
    let topic : Topic = Object.assign(topicResponse, {media:mediaArray})
    return topic;
  }
  getTopic (slug : string, freshFromBackend : boolean){

    // At the moment it seems that a 'lite' topic (backend TopicLiteResource) is adequate (missing links, but we don't use them yet)

    let cachedTopic : Topic;
    if (!freshFromBackend){
      cachedTopic = this.getCachedTopic(slug);
    }
    if (cachedTopic){
      return of(cachedTopic);
    };

    return this.http.get<{'data' : TopicFromBackend}>(
      'api/v1/topics/'+slug)
        .pipe(
          map(response =>{
            let topic : Topic = this.transformTopic(response.data);
            this.cacheTopic(topic,true);
            return topic;
          })
        )
  };
  getCachedTopic (slug: string){
    return this.topics.concat(this.standaloneTopics).find(t=>t.slug === slug);
  }
  cacheTopic (topic : Topic, asStandaloneTopic : boolean = false){
    const targetArray = asStandaloneTopic ? this.standaloneTopics : this.topics;
    let cachedTopicIndex = targetArray.findIndex(t=>t.id === topic.id);
    if (cachedTopicIndex >-1){
      targetArray[cachedTopicIndex] = topic;
    } else {
      targetArray.push(topic);
    }
  };
  cacheTopics(topics:Topic[]){
    if (this.topics.length){
      this.topics = this.dataProcessingService.mergeTwoArraysWithUniqueIdentifiersAvoidingDuplicates(topics,this.topics,'id');
    } else {
      this.topics = topics;
    }
    this.removeStandaloneTopics(topics);
  }
  removeStandaloneTopics(newTopics : Topic[] = []){
    const newTopicIds = newTopics.map(t=>t.id);
    this.standaloneTopics = this.standaloneTopics.filter(topic=>!newTopicIds.includes(topic.id));
  }

  getImage (topicMedia : CloudinaryMediaAsset[], type : string){
    if (!topicMedia?.length){ return null ;};
    if (type === 'thumbnail'){
      return topicMedia.find(m=>m.category==='thumbnail' && m.type==='main');
    }
  }
  convertTopicToTag (topic : Topic, preserveId : boolean, flagTransformations = 'w_32,c_fill,ar_1:1,r_max,f_auto/'){
    return new Tag(
      preserveId ? topic.id : null,
      topic.name,
      topic.slug,
      'topic',
      topic.icon?.identifier_1 ? 'fa' : topic.icon?.identifier_2 ? 'cloudinary_icon' : '',
      topic.icon?.identifier_1 ?? topic.icon?.identifier_2 ?? '',
      topic.icon?.identifier_2 ? this.mediaService.getIconUrlFromIconIdentifier2(topic.icon.identifier_2,'w_32,c_fill,ar_1:1,r_max,f_auto/'):null
    )
  }
  convertTopicsToTags (topics : Topic[], preserveId : boolean = false, iconTransformations? : string){
    if(!topics.length){return [];};
    return topics.map(c => this.convertTopicToTag(c,preserveId,iconTransformations));
  }
  getTopics(category:string, type:string, freshFromServer: boolean = false) {
    if(!category && !type && this.allTopicsReceived && !freshFromServer){
      return of (this.topics);
    }
    let filteredTopics : Topic[] = [];
    if (category){
      filteredTopics = this.topics.filter(t=> t.category===category);
    };
    if (type){
      let topics = filteredTopics.length ? filteredTopics : this.topics;
      filteredTopics = topics.filter(t=> t.type===type);
    };
    if (filteredTopics.length && !freshFromServer) {
      return of(filteredTopics);
    }
    let url = 'api/v1/topics?';

    let params = new URLSearchParams();
    
    if (category){
      params.append('category',category.toString())
    };
    if (type){
      params.append('type',type)
    };

    return this.http.get<{data:Topic[]}>(url+params.toString()).pipe(
      map((response) => {
        if (response?.data?.length) {
          // response.data = this.transformTopics(response.data);
          this.cacheTopics(response.data);
          if(!category && !type){
            this.allTopicsReceived = new Date();
          }
          return response.data;
        }
      })
    );
  }
  convertTopicToContentItem(topic: Topic): ContentItem {
    return new ContentItem( // THIS IS VERY SIMPLE - WE NEED TO ADD MORE PROPERTIES
      topic.id,
      topic.name,
      null,
      null,
      null,
      topic.description,
      null,
      null,
      topic.slug,
      null,
      null,
      topic.media?.find(m=>m.category==='thumnail' && m.type==='main'),
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  }

  makeContentItems(topics : Topic[]) : ContentItem[] {
    return topics.map(t=>this.convertTopicToContentItem(t));
  }

}
