import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Page } from '@frontend/core';
import { PageService } from '../../../../../../apps/toolbox/src/app/common/page.service';
import { environment } from 'apps/toolbox/src/environments/environment';

@Component({
  selector: 'frontend-page-overview',
  templateUrl: './page-overview.component.html',
  styleUrls: ['./page-overview.component.scss']
})
export class PageOverviewComponent implements OnInit {

  loading : boolean = false;
  pages : Page[];
  pagesSubscription;
  error = null;
  blocks : any = [];
  appKey : string;
  pageType : string = null;

  constructor(
    private pageService : PageService,
    private route : ActivatedRoute,
    ) { }


  getPages (category: string, type : string){
    this.loading = true;
    this.error = null;
    this.pagesSubscription = this.pageService.getPagesByCategoryAndType(category, type)
      .subscribe(
        response => {
          this.pages = response;
          this.loading = false;
        },
        error => {
          this.error = error;  
          this.loading = false;
        }
      );
  }

  ngOnInit(): void {
    let category : string = this.route.snapshot.url[0].path;
    let type : string = this.route.snapshot.params['type'];
    this.appKey = environment.appKey;
    if (category && type){
      this.getPages(category,type);
    };
    if (category && !type){
      this.getPages(this.appKey, category);
    };
  }

  ngOnDestroy (){
    this.pagesSubscription.unsubscribe();
  }

}
