import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { UserExtraLite } from '@frontend/common';
import { LikesSummary } from '../../models';

interface likesResponse {
  'data':{'likes': UserExtraLite[],'count':number, 'liked': boolean}
}

@Injectable()
export class LikesService {

  likesByModel : any;
  likesSummary : LikesSummary = null;
  
  constructor(
    private http: HttpClient
    ) {
      this.likesByModel = {};
    }


  getCachingObject (modelNamePlural,identifier){
    if (!this.likesByModel[modelNamePlural]){
      this.likesByModel[modelNamePlural] = {};
    };
    if (!this.likesByModel[modelNamePlural][identifier]){
      this.likesByModel[modelNamePlural][identifier] = {};
    };
    return this.likesByModel[modelNamePlural][identifier];
  }
  toggleLike (modelNamePlural,identifier){
    return this.http.post<likesResponse>(
      'api/v1/'+modelNamePlural+'/toggle-like/'+identifier,{})
        .pipe(
          map(response =>{
            if (response && response.data ){
              let cachingObject = this.getCachingObject(modelNamePlural,identifier);
              cachingObject.likes = response.data.likes;
              cachingObject.count = response.data.count;
              cachingObject.liked = response.data.liked;
              return cachingObject;
            };
          })
        )
  };

  getLikes (modelNamePlural,identifier){
    return this.http.get<likesResponse>(
      'api/v1/'+modelNamePlural+'/likes/'+identifier)
        .pipe(
          map(response =>{
            if (response && response.data ){
              let cachingObject = this.getCachingObject(modelNamePlural,identifier);
              cachingObject.likes = response.data.likes;
              cachingObject.count = response.data.count;
              cachingObject.liked = response.data.liked;
              return cachingObject;
            };
          })
        )
  };
  getLikesSummary (modelSetName:string,randomLikersCount:number){
    if (this.likesSummary){
      return of(this.likesSummary);
    }
    return this.http.get<LikesSummary>(
      'api/v1/likes/summary/'+modelSetName+'?randomLikersCount='+randomLikersCount)
        .pipe(
          map(response =>{
            this.likesSummary = response;
            return response;
          })
        )
  };
  setCachedLikeForAfterAuthentication (modelNamePlural : string, identifier){
    localStorage.setItem('cachedLikeForAfterAuthentication',JSON.stringify([modelNamePlural, identifier]))
  }
  getCachedLikeForAfterAuthentication (){
    let cachedLike = localStorage.getItem('cachedLikeForAfterAuthentication');
    if (cachedLike){
      localStorage.removeItem('cachedLikeForAfterAuthentication');
      return {
        'modelNamePlural': JSON.parse(cachedLike)[0],
        'identifier': JSON.parse(cachedLike)[1]
      };
    };
  }

}
