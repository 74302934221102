<div class="row">
  <div class="col-xs-12 col-md-6 offset-md-3">
    <!-- Material form login -->
  <div class="card">
    <div class="card-body">

      <h5 class="card-title" *transloco="let t">
        <strong>{{ t('authentication.verify') }}</strong>
    </h5>




    <!--Card content-->
    <div class="px-lg-5 pt-0">


      <div *ngIf="error" class="alert alert-warning my-5" role="alert">
        <p *transloco="let t">
          {{ t('authentication.verification_error') }}
        </p>
      </div>

    </div>

    </div>
  </div>
<!-- Material form login -->
  </div>
</div>
