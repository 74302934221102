import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConsentRoutingModule } from './consent-routing.module';
import { TranslocoModule } from '@jsverse/transloco';
import { LoadersModule } from '../../loaders';
import { UserAccountConsentComponent } from './user-account-consent/user-account-consent.component';
import { PillsModule, VideoPlayersModule } from '@frontend/shared';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ConsentRequestModalComponent } from './consent-request-modal/consent-request-modal.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ConsentUpdaterCardComponent } from './consent-updater-card/consent-updater-card.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { LanguageModule } from '../../language';


@NgModule({
  declarations: [
    UserAccountConsentComponent,
    ConsentUpdaterCardComponent,
    ConsentRequestModalComponent,
  ],
  imports: [
    CommonModule,
    ConsentRoutingModule,
    TranslocoModule,
    LoadersModule,
    PillsModule,
    CollapseModule,
    VideoPlayersModule,
    ModalModule,
    AlertModule,
    BsDropdownModule,
    // LanguageModule, // For the language dropdown component (not implemented yet)
  ],
  exports: [
    ConsentRequestModalComponent,
  ]
})
export class ConsentModule { }
