      <div *transloco="let t">
          <h1>
            
            <span *ngIf="(verificationSuccess && !isAuthenticated) || !verificationSuccess">{{ t('common.log_in') }}</span>
            <span *ngIf="verificationSuccess && isAuthenticated">{{ t('authentication.verified') }}</span>
          </h1>

          <div *ngIf="verificationSuccess">
            <alert [type]="'success'">
              <button
            *ngIf="!isAuthenticated"
            (click)="clearVerificationSuccess()"
            type="button" class="close float-right" aria-label="Close"
            >
            <span aria-hidden="true">×</span>
          </button>
          <p *transloco="let t" class="my-3">
            {{ t('authentication.verification_success') }}<br>
            <strong *ngIf="!isAuthenticated">{{ t('authentication.log_in_now_polite') }}</strong>
          </p>
          </alert>
          </div>
        <div class="registration-form">
          <form [formGroup]="loginForm" (submit)="loginWithEmail()" role="form">
            <!-- E-mail* -->
            <div class="input-self">
              <label for="email" class="form-label">{{ t('common.email') }}<span class="required-sign">*</span>
                </label>
              <div class="input-group mb-3" [ngClass]="{
                'ng-invalid' : loginForm.get('email').invalid && loginForm.get('email').touched,
                'ng-valid' : loginForm.get('email').valid && loginForm.get('email').touched && loginForm.get('email').dirty
              }">
                <span class="input-group-text text-primary-light">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </span>
                <input
                  class="form-control"
                  type="email"
                  formControlName="email"
                  id="email"
                  placeholder="example@email.com"
                  />
                <div
                  *ngIf="loginForm.get('email').errors?.required && loginForm.get('email').touched"
                  class="invalid-feedback">
                  {{ t('common.required') }}
                </div>
                <div
                  *ngIf="loginForm.get('email').errors?.email && loginForm.get('email').touched"
                  class="invalid-feedback">
                 <!--  -->
                </div>
              </div>
            </div>
            <!-- Password* -->
            <div class="input-self">
              <label for="password" class="form-label"> {{ t('authentication.password') }}<span class="required-sign">*</span></label>
              <div class="input-group mb-3" [ngClass]="{
                'ng-invalid' : loginForm.get('password').invalid && loginForm.get('password').touched,
                'ng-valid' : loginForm.get('password').valid && loginForm.get('password').touched && loginForm.get('password').dirty
              }">
                <span class="input-group-text text-primary-light">
                  <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                </span>
                <input
                class="form-control input-lg"
                type="password"
                id="password"
                formControlName="password"
              />
              <div
                *ngIf="loginForm.get('password').errors && loginForm.get('password').touched"
                class="invalid-feedback">
                <div *ngIf="loginForm.get('password').errors.required">{{ t('common.required') }}</div>
                <div *ngIf="loginForm.get('password').errors.minlength">{{ t('authentication.8char_1digit') }}</div>
              </div>
              </div>
            </div>
            
            <!-- Terms of use | Checkbox -->
            <div class="input-self">
              <div class="form-check justify-content-end">
                    <a class=" text-decoration-underline" routerLink="/forgot-password" *transloco="let t">{{t('authentication.forgot_password_question')}}</a>
                  
              </div>
            </div>
            <!-- Sign In | Button -->
            <div class="input-self">
              <multisite-loader-globe-animation-detail
                *ngIf="loading"
                [width]="100"
                [height]="100"
                >
              </multisite-loader-globe-animation-detail>
              <div class="input-group my-3">
                <button
                  type="submit"
                  class="btn btn-lg btn-primary w-100"
                  *ngIf="!loading"
                  [disabled]="loginForm.invalid"
                  >
                  {{ t('common.log_in') }}
                </button>
                <alert class="mt-1 w-100" [type]="'warning'" *ngIf="errorMessage && !hideBackendErrorMessage">{{errorMessage | transloco}}</alert>
              </div>
            </div>
            <!-- Link to login -->
            <p class="text-center">{{t('authentication.not_registered_question')}} 
              <a routerLink="/register" routerLinkActive="active" *transloco="let t" class="text-decoration-underline">{{t('common.register')}}</a></p>
          </form>
        </div>
      </div>
