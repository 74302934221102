import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressIndicatorSlimmableSingleComponent } from './progress-indicator-slimmable-single/progress-indicator-slimmable-single.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ProgressIndicatorSlimmableNumberComponent } from './progress-indicator-slimmable-number/progress-indicator-slimmable-number.component';



@NgModule({
  declarations: [
    ProgressIndicatorSlimmableSingleComponent,
    ProgressIndicatorSlimmableNumberComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ],
  exports: [
    ProgressIndicatorSlimmableSingleComponent,
    ProgressIndicatorSlimmableNumberComponent
  ]
})
export class ProgressIndicatorsSlimmableModule { }
