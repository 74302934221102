import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BrandDisplayService } from '../brand-display.service';
import { ContentItem } from '../../content/content-item.model';
import { Tag } from '@frontend/core';
// import { Link } from '@frontend/core';
import { Video } from '@frontend/core';

@Component({
  selector: 'multisite-branding-demo',
  templateUrl: './branding-demo.component.html',
  styleUrls: ['./branding-demo.component.scss']
})
export class BrandingDemoComponent implements OnInit {

  showSuccessAlert : boolean = false;
  pauseLoop : boolean = false;
  showWarningAlert : boolean = false;
  max : number;
  rate : number;
  isReadonly : boolean;
  demoCardContent : ContentItem; // 


  constructor(
    private router:Router,
    private brandDisplayService : BrandDisplayService
    ) { 

      let tags : Tag[] = [{"id":4,"name":"Global mobility","slug":"global-mobility","type":"interculturalist_fields","icon_type":null,"icon_identifier":null,"img_url":null},{"id":201,"name":"Tanzania","slug":"tanzania","type":"culture","icon_type":null,"icon_identifier":null,"img_url":null},{"id":495,"name":"CultureConnector","slug":"cultureconnector","type":"topic","icon_type":null,"icon_identifier":null,"img_url":null}];
      let videos : Video[] = [{"name":"Brenda Turnnidge on episode 4 of the Intercultural Toolbox podcast","host_identifier":"559454176","host_params":"h=e45ccbc2b8","duration":2708000}];
      this.demoCardContent = {"id":4,"title":"Dignissimos placeat nesciunt nemo error asperiores dolores.","titleTranslationKey":null,"subtitle":null,"subtitleTranslationKey":null,"description":"Quae velit sed hic possimus odit laboriosam quo rerum.","descriptionTranslationKey":null,"body":"<h3>Doloremque voluptatum modi minus harum ad atque.</h3><p>Laboriosam est sapiente odit amet. Deleniti ea dolorum non perferendis ea. Velit porro modi earum quia aut autem est dicta. Est quam id maxime ea molestiae commodi id. Expedita quo eaque perferendis dolorum ipsa adipisci.</p>","slug":"dignissimos-placeat-nesciunt-nemo-error-asperiores-dolores98816","tags": tags,"media":{"id":23,"medially_type":"App\\\\Models\\\\Tip","medially_id":4,"file_url":"https://res.cloudinary.com/cebt/image/upload/v1611931415/Culture/Tools/red-shoes.jpg","file_name":"Culture/Tools/red-shoes","file_type":"image","size":249000,"uploader_id":1,"description":"Red shoes","credits":"SolarisGirl (sanmitrakale), Flickr","permission":"Creative Commons 2.0, Share Alike","category":"thumbnail","type":"main","created_at":"2023-02-09T07:53:03.000000Z","updated_at":"2023-02-09T07:53:03.000000Z","base_url":"https://res.cloudinary.com/cebt/image/upload/","transformations_url":null,"asset_url":"v1611931415/Culture/Tools/red-shoes.jpg"},"additional_media":null,"rating":2,"video":videos[0],"date":new Date("2023-02-09T07:53:03.000000Z"),"cta_text": "Read your feed",cta_translationKey: null, duration: 1010101010,user:null,"meta":null};

    }

  goToTarget(tab){
    this.router.navigate(tab.target);
  }

  onClosed (alertType:string){
    if (alertType=== 'success'){
      this.showSuccessAlert = false;
      if (!this.pauseLoop){
        setTimeout(()=>{
          this.showSuccessAlert = true;
        },5000)
      }
    };
    if (alertType=== 'warning'){
      this.showWarningAlert = false;
        setTimeout(()=>{
          this.showWarningAlert = true;
        },5000)
    };
  }

  // toggleLoop (){
  //   this.pauseLoop = !this.pauseLoop;
  // }
  demoDialog (){
    alert('You clicked');
  }

  ngOnInit(): void {

    this.max = 5;
    this.rate = 5;
    this.isReadonly = true;
    
  }

}
