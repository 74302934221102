import { Media } from './media.model'

export class CallToAction {
  constructor(
    public label: string, // "Brenda Turnnidge's World of Photography"
    public url: string, // "https:\/\/www.brenda-turnnidge.fr"
    public description: string, // "A personal blog and archive of cross-cultural images by the interculturalist and photographer Brenda Turnnidge",
    public cta_label: string, // "Explore" // should be lower case: a translation key; but if it is upper case or has a space, we will just display it
    public cta_type: string, // "Resource"
    public imageUrl: string,
    public media: Media,
    public link_id?: number, // if the CTA is generated from a link, we can identify the link here
  ){}
}