import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserConnectionsRoutingModule } from './user-connections-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { VerticalMenuTypeOneModule } from '../../navigation';
import { EmailInputFormModule } from '@frontend/shared';
import { AlertModule } from 'ngx-bootstrap/alert';
import { LoadersModule } from '../../loaders';
import { UserConnectionsHomeComponent } from './user-connections-home/user-connections-home.component';
import { UserConnectionsDetailsUpdateComponent } from './user-connections-details-update/user-connections-details-update.component';
import { RoleHoldersModule } from '../../roles/role-holders/role-holders.module';


@NgModule({
  declarations: [
    UserConnectionsHomeComponent,
    UserConnectionsDetailsUpdateComponent
  ],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'connections' }],
  imports: [
    CommonModule,
    UserConnectionsRoutingModule,
    ReactiveFormsModule,
    TranslocoModule,
    VerticalMenuTypeOneModule,
    EmailInputFormModule,
    AlertModule,
    LoadersModule,
    RoleHoldersModule
  ]
})
export class UserConnectionsModule { }
