import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificationDisplayListSlimmableComponent } from './certification-display-list-slimmable/certification-display-list-slimmable.component';
import { CertificationDisplayListItemSlimmableComponent } from '../certification-display/certification-display-list-item-slimmable/certification-display-list-item-slimmable.component';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    CertificationDisplayListSlimmableComponent,
    CertificationDisplayListItemSlimmableComponent

  ],
  imports: [
    CommonModule,
    TranslocoModule,
  ],
  exports: [
    CertificationDisplayListSlimmableComponent,
    CertificationDisplayListSlimmableComponent,
  ]
})
export class CertificationDisplayListsModule { }
