export class Difference {
    public id : number;
    public name : string;
    public short_name : string;
    public description : string;
    public slug : string;
    public pole1 : string; // 'Silence'
    public pole2 : string; // 'Talk'
    public category : string; // 'geographic'
    public type : string; // 'national'
    public icon : {identifier_1:string}; // 'fa-solid fa-comment-exclamation'
}