import { HubMicro } from "../hubs/hub-micro.model";
import { BrandColour } from "./brand-colour.model";

export interface UnsavedPendingChanges { // held locally in browser memory or localstorage and sent to the backend
    logo_banner?: {file: File, file_url : string, removeSaved: boolean},
    logo_banner_inverse?: {file: File, file_url : string, removeSaved: boolean},
    logo_square?: {file: File, file_url : string, removeSaved: boolean},
    logo_square_inverse?: {file: File, file_url : string, removeSaved: boolean},
}
export interface SavedPendingChanges { // received from the backend, the url of a Cloudinary image
    logo_banner?: string,
    logo_banner_inverse?: string,
    logo_square?: string,
    logo_square_inverse?: string
}

export class Brand {

    constructor (
        public id : number,
        public name: string, // "Training Company 1",
        public slug: string, // "tco1",
        public colours: BrandColour[], // [{\"name': \"primary-dark\", \"hex\": \"#FF00FF\"}]",
        public font: string, // "serif" or "sans-serif",
        public slogan: string, // "Catch the train to successville",
        public pending: SavedPendingChanges,
        public logo_square: string, // "https://www.argonautonline.com/wp-content/uploads/2021/02/audi-logo-square.jpg",
        public logo_banner: string, // "https://www.argonautonline.com/wp-content/uploads/2016/02/tcs-tata-consultancy-services-logo-svg.png",
        public logo_square_inverse: string, // "https://www.argonautonline.com/wp-content/uploads/2021/02/audi-logo-square.jpg",
        public logo_banner_inverse: string, // "https://www.argonautonline.com/wp-content/uploads/2016/02/tcs-tata-consultancy-services-logo-svg.png",
        public created_at: string, // "2021-06-14 07:18:55",
        public updated_at: string, // "2021-06-14 07:18:55",
        public deleted_at: string, // null,
        public loaded_at: Date, // the last time we got this from the server
        public hubMicro?: HubMicro,
        public pending_updates?: UnsavedPendingChanges,
        public completion?: number,
        public category?: string,
    ){
        this.id = id;
        this.name = name;
        this.slug = slug;
        this.colours = colours;
        this.font = font;
        this.slogan = slogan;
        this.pending = pending;
        this.logo_square = logo_square;
        this.logo_square_inverse = logo_square_inverse;
        this.logo_banner = logo_banner;
        this.logo_banner_inverse = logo_banner_inverse;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
        this.loaded_at = loaded_at;
        this.hubMicro = hubMicro;
        this.pending_updates = pending_updates;
        this.completion = completion;
        this.category = category;
    }
}