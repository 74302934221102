
<div class="container">
  <div class="row mt-4">
    <div class="col-md-4 offset-md-4">
      <div class="card" style="width: 18rem;">
        <img src="https://res.cloudinary.com/cebt/image/upload/w_700,ar_16:9/v1674244171/concepts/side-view-of-meditating-woman-sitting-in-pose-of-lotus-against-clear-sky-outdoors-SBI-300731602_wawmgp.jpg" class="card-img-top" alt="A meditating woman">
        <div class="card-body">
          <h5 class="card-title">Instrument: {{surveyName}}</h5>
          <p class="card-text">Some more useful and encouraging info, video etc.</p>
          <a routerLink="create" class="btn btn-primary mt-2">Start</a>
        </div>
      </div>
  </div>
</div>
