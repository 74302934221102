import { Injectable } from '@angular/core';
import { UserExtraLite } from '../auth';
import { Language } from '../language';
import { DataProcessingService } from './data-processing.service';
import { DesignService } from '../design/design.service';

@Injectable({
  providedIn: 'root',
})
export class PlaceholderService {
  placeholderUsers: UserExtraLite[];
  languages: Language[];

  constructor(private dataProcessingService : DataProcessingService, private designService : DesignService) {
    this.placeholderUsers = [
      {
        id: null,
        fname: 'Kimi',
        lname: 'Sagato',
        pre_honorific: '',
        post_honorific: '',
        name_full_honorific: 'Kimi Sagato',
        picture: 'https://randomuser.me/api/portraits/men/98.jpg',
        slug: 'kimi-sagato',
        bio: this.designService.generateLoremIpsum(5+Math.round(Math.random()*10),true),
      },
      {
        id: null,
        fname: 'June',
        lname: 'Bird',
        pre_honorific: '',
        post_honorific: '',
        name_full_honorific: 'June Bird',
        picture: 'https://randomuser.me/api/portraits/women/44.jpg',
        slug: 'june-bird',
        bio: this.designService.generateLoremIpsum(5+Math.round(Math.random()*10),true),
      },
      {
        id: null,
        fname: 'Mario',
        lname: 'Fratelli',
        pre_honorific: '',
        post_honorific: '',
        name_full_honorific: 'Mario Fratelli',
        picture: 'https://randomuser.me/api/portraits/men/20.jpg',
        slug: 'mario-fratelli',
        bio: this.designService.generateLoremIpsum(5+Math.round(Math.random()*10),true),
      },
      {
        id: null,
        fname: 'Sveta',
        lname: 'Dolinski',
        pre_honorific: '',
        post_honorific: '',
        name_full_honorific: 'Sveta Dolinski',
        picture: 'https://randomuser.me/api/portraits/women/23.jpg',
        slug: 'sveta-dolinski',
        bio: this.designService.generateLoremIpsum(5+Math.round(Math.random()*10),true),
      },
      {
        id: null,
        fname: 'Mawar',
        lname: 'Wahyuni',
        pre_honorific: '',
        post_honorific: '',
        name_full_honorific: 'Mawar Wahyuni',
        picture: 'https://randomuser.me/api/portraits/women/27.jpg',
        slug: 'mawar-wahyuni',
        bio: this.designService.generateLoremIpsum(5+Math.round(Math.random()*10),true),
      },
    ];
    this.languages = [
      {
        id: 41,
        name: 'English',
        iso: 'en',
        flag: {
          hash: 'Vy9orn',
          emoji: '\ud83c\uddec\ud83c\udde7',
        },
      },
      {
        id: 53,
        name: 'Greek',
        iso: 'el',
        flag: {
          hash: 'KMJbZ0',
          emoji: '🇬🇷',
        },
      },
      {
        id: 48,
        name: 'French',
        iso: 'fr',
        flag: {
          hash: 'gy4FJr',
          emoji: '\ud83c\uddeb\ud83c\uddf7',
        },
      },
    ];
    this.placeholderUsers.forEach(user=>{
      user.advancedLanguages = user.advancedLanguages ?? this.dataProcessingService.shuffleArray(this.languages).slice(0,this.dataProcessingService.getRandomInteger(1,this.languages.length));
    })
  }

  makeAvatarFile() {
    return (
      'https://randomuser.me/api/portraits/' +
      (Math.random() > 0.5 ? 'wo' : '') +
      'men/' +
      Math.ceil(Math.random() * 95) +
      '.jpg'
    );
  }
  getUsers(count : number):UserExtraLite[]{
    return this.dataProcessingService.shuffleArray([...this.placeholderUsers]).slice(0,count);
  }
}
