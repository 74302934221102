export const AccessCodeConstants = {

    /* Regex */
    // Angular's regex validators don't work!!!! Validators are very unreliable when used in forms. Angular offers various ways to implement pattern validation and they all fail in some scenario. Build your own!

    // Test expressions on regex101.com
   regex : /^(?=.{5,100}$)(?=\S+ \S+ \S+$).*$/ // NOTE!! Angular will add at the ^ at the start and the $ at the end
   /*
    Explanation:

    Has at least 5 and at most 100 characters.
    Contains exactly two space characters that are not at the beginning or end of the string.

    ^: Asserts the start of the string.
    (?=.{5,100}$): Positive lookahead to ensure the string has at least 5 and at most 100 characters.
    (?=\S+ \S+ \S+$): Positive lookahead to ensure there are exactly two spaces in the string, where \S matches any non-whitespace character. This ensures that the spaces are not at the beginning or end of the string.
    .*: Matches any remaining characters in the string.
    $: Asserts the end of the string.
   */

}