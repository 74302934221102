<div class="container-fluid">
    <nav id="breadcrumb" aria-label="breadcrumb" class="main-breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">The Intercultural Pendulum</a></li>
            <li class="breadcrumb-item"><a href="#">Topic Tips</a></li>
            <li class="breadcrumb-item active" aria-current="page">Inviting participants to CultureConnector in less
                than 2 minutes</li>
        </ol>
    </nav>
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-4">
                <img class="w-100"
                    src="https://images.pexels.com/photos/2387418/pexels-photo-2387418.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="Inviting participants">
            </div>
            <div class="col-md-8">
                <h1 class="font-weight-bold text-break">
                    Inviting participants to CultureConnector in less than 2 minutes
                </h1>
                <p>
                    You have a training coming and you need to get your participants to create cultural profiles.
                    Let's
                    skip
                    some details and options. This is how to do it, in a nutshell.
                </p>
            </div>
            <p class="h4 mt-5">Lead Text</p>
            <p class="lead">
                In ultricies fermentum aliquet. Pellentesque dui magna, condimentum non ullamcorper at, cursus in sem.
                Nunc condimentum, purus ac enenatis commodo libero, am auctor porta tortor, nec consequat nibh finibus
                a. Sed ultrices risus eget iaculis luctus. Mauris vel gravida magna.
            </p>
            <div class="comments">
                <p class="h3 my-5">Comments</p>
                <div class="comment">
                    <div class="img-holder">
                        <img src="https://i1.sndcdn.com/avatars-000469688319-4bp07i-t200x200.jpg"
                            alt="Alexander Howells">
                    </div>
                    <div class="content-holder">
                        <div class="d-flex flex-direction-row justify-content-between">
                            <p class="name">Elon Musk</p><a href="#"><i class="fa-solid fa-pen"></i> Edit</a>
                        </div>
                        <p class="text">Arcu tortor, dui dictum orci tincidunt dignissim aliquet amet. Malesuada eu erat
                            facilisis ultrices elit at ac euismod ultrices. Neque arcu nec et consequat type.</p>
                        <div class="actions">
                            <div class="left">
                                <a class="like" href="#">
                                    <i class="fa-regular fa-thumbs-up"></i> Like
                                </a>
                                <a class="replay" href="#">
                                    Replay
                                </a>
                                <p class="time">
                                    <span class="value">3</span>h ago
                                </p>
                                <p class="likes">
                                    <span class="value">22</span> likes
                                </p>
                            </div>
                            <div class="right">
                                <button type="button" class="w-100 toggle-replies" (click)="isCollapsed = !isCollapsed"
                                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseReplies">(<span
                                        class="value">2</span>) Hide replies<i class="ml-2 fa-solid fa-angle-up"></i>
                                </button>
                            </div>
                        </div>
                        <hr>
                        <div class="replies" id="collapseReplies" [collapse]="isCollapsed" [isAnimated]="true">
                            <!-- Comment -->
                            <div class="comment">
                                <div class="img-holder">
                                    <img src="https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        alt="Alexander Howells">
                                </div>
                                <div class="content-holder">
                                    <p class="name">Alexander Howells</p>
                                    <p class="text">Arcu tortor, dui dictum orci tincidunt dignissim aliquet amet.
                                        Malesuada eu erat
                                        facilisis ultrices elit at ac euismod ultrices. Neque arcu nec et consequat
                                        type.</p>
                                    <div class="actions">
                                        <div class="left">
                                            <a class="like" href="#">
                                                <i class="fa-regular fa-thumbs-up"></i> Like
                                            </a>
                                            <a class="replay" href="#">
                                                Replay
                                            </a>
                                            <p class="time">
                                                <span class="value">3</span>h ago
                                            </p>
                                            <p class="likes">
                                                <span class="value">22</span> likes
                                            </p>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                            <!-- Comment -->
                            <div class="comment">
                                <div class="img-holder">
                                    <img src="https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        alt="Alexander Howells">
                                </div>
                                <div class="content-holder">
                                    <p class="name">Alexander Howells</p>
                                    <p class="text">Arcu tortor, dui dictum orci tincidunt dignissim aliquet amet.
                                        Malesuada eu erat
                                        facilisis ultrices elit at ac euismod ultrices. Neque arcu nec et consequat
                                        type.</p>
                                    <div class="actions">
                                        <div class="left">
                                            <a class="like" href="#">
                                                <i class="fa-regular fa-thumbs-up"></i> Like
                                            </a>
                                            <a class="replay" href="#">
                                                Replay
                                            </a>
                                            <p class="time">
                                                <span class="value">3</span>h ago
                                            </p>
                                            <p class="likes">
                                                <span class="value">22</span> likes
                                            </p>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                            <!-- Comment Submit -->
                            <div class="comment">
                                <div class="img-holder">
                                    <img src="https://i1.sndcdn.com/avatars-000469688319-4bp07i-t200x200.jpg"
                                        alt="Alexander Howells">
                                </div>
                                <div class="content-holder">
                                    <p class="name">Elon Musk</p>
                                    <form action="" method="post">
                                        <div class="d-flex flex-column">
                                            <textarea placeholder="Your comment" class="form-control"
                                                rows="3"></textarea>
                                            <input type="submit"
                                                class="btn btn-primary align-self-end mt-2 text-capitalize btn-sm"
                                                value="Comment">
                                        </div>
                                    </form>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Main comment -->
                <div class="comment">
                    <div class="img-holder">
                        <img src="https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                            alt="Alexander Howells">
                    </div>
                    <div class="content-holder">
                        <p class="name">Alexander Howells</p>
                        <p class="text">Arcu tortor, dui dictum orci tincidunt dignissim aliquet amet. Malesuada eu erat
                            facilisis ultrices elit at ac euismod ultrices. Neque arcu nec et consequat type.</p>
                        <div class="actions">
                            <div class="left">
                                <a class="like" href="#">
                                    <i class="fa-solid fa-thumbs-up"></i> Liked
                                </a>
                                <a class="replay" href="#">
                                    Replay
                                </a>
                                <p class="time">
                                    <span class="value">3</span>h ago
                                </p>
                                <p class="likes">
                                    <span class="value">22</span> likes
                                </p>
                            </div>
                        </div>
                        <hr>

                    </div>
                </div>
                <!-- Comment Submit -->
                <div class="comment">
                    <div class="img-holder">
                        <img src="https://i1.sndcdn.com/avatars-000469688319-4bp07i-t200x200.jpg"
                            alt="Alexander Howells">
                    </div>
                    <div class="content-holder">
                        <p class="name">Elon Musk</p>
                        <form action="" method="post">
                            <div class="d-flex flex-column">
                                <textarea placeholder="Your comment" class="form-control" rows="3"></textarea>
                                <input type="submit" class="btn btn-primary align-self-end mt-2 text-capitalize btn-sm"
                                    value="Comment">
                            </div>
                        </form>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>