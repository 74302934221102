import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentItem } from '../../content-item.model';

@Component({
  selector: 'card-list-item-promo',
  templateUrl: './card-list-item-promo.component.html',
  styleUrls: ['./card-list-item-promo.component.scss']
})
export class CardListItemPromoComponent implements OnInit {

  @Input() content : ContentItem;
  @Input() imageTransformations : string; // 'w_700,c_fill,ar_16:9/'
  @Output() itemSelected = new EventEmitter<ContentItem>();



  constructor() { }

  onItemSelected(){
    this.itemSelected.emit(this.content);
  }


  ngOnInit(): void {
  }

}
