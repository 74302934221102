import { CloudinaryMediaAsset } from "../../../models";
import { MultipleChoiceQuestionOption } from "./question-multiple-choice-option.model";

export class MultipleChoiceQuestion {
    constructor (
        public id : number,
        public text : string,
        public context : string,
        public feedback : string,
        public help : string,
        public label : string,
        public question_id : number, // this is the backend "Question" type, not the backend "MultipleChoiceQuestion" type
        public hide_context : boolean, // if the survey by default requires that we show snapshot context, we will hide the snapshot context for this quesetion
        public hide_raters : boolean, // if the survey by default requires that we show multi-raters, we will hide the raters for this question
        public survey_id : number,
        public media : CloudinaryMediaAsset,
        public options : MultipleChoiceQuestionOption[]
    ){}
}