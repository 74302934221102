export class Tag {
  constructor(
    public id: number,
    public name: string, // "Expat support"
    public slug: string, // expat-support
    public type: string, // "interculturalist_fields"
    public icon_type: string, // "fa"
    public icon_identifier: string, // "fa-unlock"
    public img_url: string, // "http://server.com/avatar.jpg"
  ){}
}

