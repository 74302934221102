<div id="carouselCard" class="carousel-card bg-light-dark slide" *transloco="let t">
    <div class="header">
        <p class="title">{{title_translationKey ? t(title_translationKey) : title_text}}</p>
        <div class="carousel-control" *ngIf="content?.length > 1">
            <button class="carousel-control-prev" role="button" (click)="previous()">
                <i class="fa-solid fa-angle-left"></i>
                <span class="sr-only">{{t('common.previous')}}</span>
            </button>
            <p class="indicator" *ngIf="content?.length">
                <span>{{currentIndex+1}}</span>/<span>{{content?.length}}</span>
            </p>
            <button class="carousel-control-next"role="button" (click)="next()">
                <i class="fa-solid fa-angle-right"></i>
                <span class="sr-only">{{t('common.next')}}</span>
            </button>
        </div>
    </div>
    <div class="carousel-inner">
        <div class="carousel-item active" *ngIf="content?.length">
            <div class="row">
                <div class="col-sm-4 col-md-12 col-lg-4">
                    <a (click)="doAction(content[currentIndex])" class="img-holder">
                        <img class="main-img" *ngIf="content[currentIndex].media" [src]="thumbnailUrl" width="100%">
                        <!-- </div> -->
                        <div class="rating" *ngIf="content[currentIndex].rating">
                            <pre class="rate-value"><b>{{content[currentIndex].rating}}</b></pre>
                            <rating [(ngModel)]="content[currentIndex].rating" [max]="ratingMax" [readonly]="true"></rating>

                        </div>
                    </a>
                </div>
                <div class="col-sm-8 col-md-12 col-lg-8 text-content">

                    <a (click)="doAction(content[currentIndex])" class="title mt-2">
                        {{content[currentIndex].title}}
                    </a>
                    <div class="badges">
                        <span *ngFor="let tag of content[currentIndex].tags" class="badge pill"
                            [ngClass]=" [tag.type == 'topic' ? 'badge-info' : 'badge-primary', 'badge badge-pill']">{{tag.name}}</span>
                    </div>
                    <div class="action">
                        <button (click)="doAction(content[currentIndex])">
                            <p>{{content[currentIndex].video ? t('common.watch') : t('common.read')}}</p>
                            <i class="fa-solid fa-angle-right"></i>
                        </button>
                        <p class="reading-time" *ngIf="content[currentIndex].video">
                            <span
                                class="mx-1">{{convertMillisecondsToMinutes(content[currentIndex].video.duration)}}</span>{{content[currentIndex].video.duration
                            > minMillisecondsMinuteFormat ? t('common.minutes_abbreviated') :
                            t('common.seconds_abbreviated')}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>