import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CultureSelectorTypeOneComponent } from './culture-selector-type-one.component';
import { AvatarsModule } from '@frontend/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';


@NgModule({
  declarations: [
    CultureSelectorTypeOneComponent,
  ],
  imports: [
    CommonModule,
    AvatarsModule,
    ReactiveFormsModule,
    TranslocoModule,
    TypeaheadModule,
  ],
  exports: [
    CultureSelectorTypeOneComponent,
  ]
})
export class CultureSelectorTypeOneModule { }
