import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommunitySearchComponent } from './search.component';
import { RouterModule } from '@angular/router';
import { ContentListFormatsModule } from '../../../content/lists/content/content-list-formats';
import { LoadersModule } from '../../../loaders';
import { CommunitySearchRoutingModule } from './search-routing.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CardsModule } from '../../../content';
import { PaginationModule } from '@frontend/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { AlertModule } from 'ngx-bootstrap/alert';


@NgModule({
  declarations: [
    CommunitySearchComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    CardsModule,
    PaginationModule,
    LoadersModule,
    CommunitySearchRoutingModule,
    CollapseModule,
    TranslocoModule,
    AlertModule,
  ]
})
export class CommunitySearchModule { }
