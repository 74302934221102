import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'multisite-icon-array',
  templateUrl: './icon-array.component.html',
  styleUrls: ['./icon-array.component.scss']
})
export class IconArrayComponent implements OnInit {

  @Input() iconClasses: string; // 'fa-solid fa-person'
  @Input() sliderActive: boolean; // the user has started moving the input slider
  @Input() selectedValue: number;
  @Input() numberOfIcons: number = 25; // must result in an integer when we divide 100 by this number
  @Input() max: number = 100; // maximum number on the scale (usually 0 to 100)
  @Output() setValue = new EventEmitter <number>();
  step: number
  iconData: { value: number, classes: string }[] = [];
  currentIconValue : number;

  constructor() { }

  doSetValue(newValue:number){
    this.setValue.emit(newValue);
  }

  ngOnInit(): void {
    this.step = this.max/this.numberOfIcons;
    // Generate the array of icon data

    for (let i = 0; i < this.numberOfIcons+1; i++) {
      const value = i * this.step;
      this.iconData.push({ value, classes: this.iconClasses});
    }
  }

}
