 <!-- Content holder is card with flexible styling it can be reusable at the same parent -->
 <div class="content-holder" *transloco="let t">
  <div class="header align-items-center">
    <p class="title">
      <span *ngIf="detailsForm == 'email'">{{t('authentication.change_email')}}</span>
      <span *ngIf="detailsForm == 'password'">{{t('authentication.change_password')}}</span>
    </p>
    <div class="subtitle" *ngIf="detailsForm == 'email' && user">{{user.email}}</div>
    <div class="subtitle" *ngIf="detailsForm == 'password'">{{t('authentication.make_new_password')}}</div>
  </div>
  <!-- 
      Use bootstrap grid system for content placment inside of body element
     -->
  <div class="row body">
    <!-- Place your contet here -->

    <div *ngIf="detailsForm == 'email'" class="mt-4"> 
      <multisite-email-input-form
        [labelTextTranslationKey]="'authentication.new_email'"
        (emailSubmitted)="doChangeEmail($event)"
        [errorTranslationKey]="error"
        [loading]="loading" ngClass="w-100">
      </multisite-email-input-form>
      <div class="my-2" *ngIf="!loading">
        <alert [type]="'warning'" [dismissible]="true" *ngIf="error && detailsForm !== 'email'">
          <span [innerHTML]="t(error)"></span>
        </alert>
        <alert [type]="'success'" [dismissible]="true" *ngIf="user?.email_pending">
          <p >{{ t('authentication.check_your_email_or_spam', {email:user?.email_pending}) }}</p>
        </alert>
      </div>
    </div>
    <div *ngIf="detailsForm == 'password'" class="mt-4">

      <form [formGroup]="changePasswordForm" role="form">

        <!-- Old Password* -->
        <div class="input-self">
          <label for="oldPassword" class="form-label">
            {{ t('authentication.old_password')
            }}<span class="required-sign">*</span></label
          >
          <div
            class="input-group mb-3"
            [ngClass]="{
              'ng-invalid':
                (changePasswordForm.get('oldPassword').invalid) &&
                (changePasswordForm.get('oldPassword').dirty || changePasswordForm.get('oldPassword').touched),
              'ng-valid':
                changePasswordForm.get('oldPassword').valid &&
                changePasswordForm.get('oldPassword').touched &&
                changePasswordForm.get('oldPassword').dirty
            }"
          >
            <span class="input-group-text text-primary-light">
              <i class="fa fa-unlock-alt" aria-hidden="true"></i>
            </span>
            <input
              class="form-control input-lg"
              type="password"
              id="oldPassword"
              formControlName="oldPassword"
            />
            <div id="oldPasswordHelpBlock" class="form-input-feedback">
              <!-- TODO this element disappears when there input is valid -->
              <a (click)="oldPasswordForgotten = !oldPasswordForgotten" class="text-link text-small">{{t('authentication.forgot_old_password_question')}}</a>
            </div>
            <div
              *ngIf="
                (changePasswordForm.get('oldPassword').errors) &&
                (changePasswordForm.get('oldPassword').touched ||
                  changePasswordForm.get('oldPassword').dirty)
              "
              class="invalid-feedback"
            >
              <div *ngIf="changePasswordForm.get('oldPassword').errors?.required && !oldPasswordForgotten">
                {{ t('common.required') }}
              </div>
            </div>
          </div>
        </div>

        <button class="btn btn-primary my-3" *ngIf="oldPasswordForgotten" (click)="requestNewPasswordLink()" [disabled]="loading">
            <span>{{t('authentication.get_new_password')}}</span>
        </button>

        <!-- Password* -->
        <div class="input-self" *ngIf="!oldPasswordForgotten">
          <label for="password" class="form-label">
            {{ t('authentication.new_password')
            }}<span class="required-sign">*</span></label
          >
          <div
            class="input-group mb-3"
            [ngClass]="{
              'ng-invalid':
                (changePasswordForm.get('password').invalid ||
                  !patternValid.password) &&
                (changePasswordForm.get('password').dirty || changePasswordForm.get('password').touched),
              'ng-valid':
                changePasswordForm.get('password').valid &&
                patternValid.password &&
                changePasswordForm.get('password').touched &&
                changePasswordForm.get('password').dirty
            }"
          >
            <span class="input-group-text text-primary-light">
              <i class="fa fa-unlock-alt" aria-hidden="true"></i>
            </span>
            <input
              class="form-control input-lg"
              type="password"
              id="password"
              formControlName="password"
            />
            <div
              *ngIf="
                (changePasswordForm.get('password').errors ||
                  !patternValid.password) &&
                (changePasswordForm.get('password').touched ||
                  changePasswordForm.get('password').dirty)
              "
              class="invalid-feedback"
            >
              <div *ngIf="changePasswordForm.get('password').errors?.required">
                {{ t('common.required') }}
              </div>
              <div
                *ngIf="
                  changePasswordForm.get('password').errors?.minlength ||
                  !patternValid.password
                "
              >
                {{ t('authentication.8char_1digit') }}
              </div>
              <div *ngIf="changePasswordForm.get('password').errors?.maxlength">
                {{ t('common.too_long') }}
              </div>
            </div>
          </div>
        </div>
        <!-- Repeat password* -->
        <div class="input-self" *ngIf="!oldPasswordForgotten">
          <label for="repeatPassword" class="form-label">
            {{ t('authentication.password_again')
            }}<span class="required-sign">*</span></label
          >
          <div
            class="input-group mb-3"
            [ngClass]="{
              'ng-invalid':
                changePasswordForm.get('repeatPassword').invalid &&
                (changePasswordForm.get('repeatPassword').touched ||
                  changePasswordForm.get('repeatPassword').dirty),
              'ng-valid':
                changePasswordForm.get('repeatPassword').valid &&
                
                  changePasswordForm.get('repeatPassword').dirty
            }"
          >
            <span class="input-group-text text-primary-light">
              <i class="fa fa-unlock-alt" aria-hidden="true"></i>
            </span>
            <input
              class="form-control input-lg"
              type="password"
              id="repeatPassword"
              formControlName="repeatPassword"
            />
            <div
              *ngIf="
                (changePasswordForm.get('repeatPassword').errors ||
                  passwordMatchValidator(changePasswordForm) !== null) &&
                changePasswordForm.get('repeatPassword').dirty
              "
              class="invalid-feedback"
            >
              <div *ngIf="changePasswordForm.get('repeatPassword').errors?.required">
                {{ t('common.required') }}
              </div>
              <div
                *ngIf="changePasswordForm.get('repeatPassword').errors?.minlength"
              >
                {{ t('authentication.8char_1digit') }}
              </div>
              <div
                *ngIf="changePasswordForm.get('repeatPassword').errors?.maxlength"
              >
                {{ t('common.too_long') }}
              </div>
              <div *ngIf="passwordMatchValidator(changePasswordForm) !== null &&
              changePasswordForm.get('repeatPassword').touched">
                {{ t('authentication.password_confirmation_error') }}
              </div>
            </div>
          </div>
        </div>
        <!-- Submit | Button -->
        <div class="input-self" *ngIf="!oldPasswordForgotten">
          <div class="input-group my-3">
            <button 
              *ngIf="!loading"
              class="btn btn-lg btn-primary w-100"
              [disabled]="
                changePasswordForm.status === 'INVALID' || !patternValid.password
              "
              (click)="changePassword()"
            >
              {{ t('common.confirm') }}
            </button>
            <multisite-loader-globe-animation-detail
              *ngIf="loading"
              [width]="100"
              [height]="100"
            >
            </multisite-loader-globe-animation-detail>
          </div>
        </div>
      </form>
      <alert [type]="'success'" [dismissOnTimeout]="9000" *ngIf="showPasswordSuccessAlert" class="my-3">
        <strong>{{ t('common.success')! }}</strong><br>
        <p *ngIf="showCheckInboxText">{{ t('authentication.check_your_email',{ email: user?.email }) }}</p>
        <p >{{ t(passwordSuccessTranslationKey) }}</p>
      </alert>
    </div>
    <multisite-loader-globe-animation-detail *ngIf="loading && detailsForm !== 'email'" width="80" height="80" class="my-3"></multisite-loader-globe-animation-detail>



  </div>
  <!-- 
      Footer: justify-content-start, justify-content-center or justify-content-end
     -->
  <!-- <div class="footer justify-content-end">
    <button class="btn-sm btn-primary primary-dark" *ngIf="detailsForm == 'password'">Save changes</button>
  </div> -->
</div>