import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserExtraLite } from '../../../auth';

type RoleHolder = {
  name:string,
  email:string
}

@Component({
  selector: 'multisite-role-holder-editor',
  templateUrl: './role-holder-editor.component.html',
  styleUrls: ['./role-holder-editor.component.scss']
})
export class RoleHolderEditorComponent implements OnInit {

  @Input() roleHolderTitleSingularTranslationKey : string; // 'common.rater'
  @Input() roleHolderTitlePluralTranslationKey : string; // 'common.raters'
  @Input() roleHolderSubtitleTranslationKey : string; // 'common.raters_help'
  @Input() roleHolders : UserExtraLite[] = [];
  @Output() remove = new EventEmitter<string>();
  @Output() add = new EventEmitter<RoleHolder>();
  @Input() error : string;
  @Input() locked : boolean;
  @Input() loading : boolean;
  @Input() allowRoleHolderRemovalMode : boolean;
  showNewRoleHolderForm : boolean = false;

  constructor() { }

  removeRoleHolder (email:string){
    this.remove.emit(email);
  }
  addRoleHolder (newRoleHolder : RoleHolder){
    this.add.emit(newRoleHolder);
  }


  toggleRoleLock(){
    this.error = null;
    this.locked = !this.locked;
  }


  ngOnInit(): void {
  }

}
