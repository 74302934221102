<div class="card snapshot-context">
  <div class="media-holder">
    <div class="date">
      <p class="year">{{ date | date:'yyyy' }}</p>
      <p class="month-day">
        <span class="month">
          {{ date | date:'MMM' }}
        </span>
        <!-- <span class="day">
          {{ yourDateObject | date:'d' }}
        </span> -->
      </p>
    </div>
  </div>
  <div class="card-body p-2">
    <div class="title-group">
      <span class="icons-holder" *ngIf="cultures?.length">
        <div class="d-flex align-items-center gap-1 flex-wrap flex-row">
          <h5 class="card-title font-weight-bold mx-0">{{title}}</h5>
          <span *ngFor="let culture of cultures" class="flag tooltip-custom">
            <img [src]="'https://cultureconnector.com/app/images/flags/'+culture.iso+'.png'" [alt]="culture.name"
              [ngClass]="{primary: culture.id === primary_culture_id || culture.pivot?.type === 'primary'}">
            <p *ngIf="culture.id !== primary_culture_id && (culture.pivot?.type !== 'primary')" class="tooltiptext-custom-top">{{culture.name}}</p>
            <p class="name">{{culture.name}}</p>
            <i class="icon fa-solid fa-check"></i>
          </span>
        </div>
      </span>
    </div>
    <p class="card-text"><span class="text-muted mr-1"
        *transloco="let t">{{t('survey.snapshot_context')}}</span>{{description}}</p>
  </div>
</div>