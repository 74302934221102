import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProgrammeEditorComponent } from '../programmes/editor/programme-editor.component';
import { ProgrammeListPromoComponent } from '../programmes/list-promo/programme-list-promo.component';
import { HubCultureConnectorComponent } from './cultureconnector/hub-cco.component';
import { HubHomeComponent } from './hub-home/hub-home.component';
import { HubMarketingComponent } from './hub-marketing/hub-marketing.component';
import { HubNewComponent } from './hub-new/hub-new.component';
import { HubProfileEditComponent } from './hub-profile-edit/hub-profile-edit.component';
import { HubProfilesComponent } from './hub-profiles/hub-profiles.component';
import { HubsListingComponent } from './hubs-listing/hubs-listing.component';
import { HubRolesComponent } from './roles/hub-roles.component';

const routes: Routes = [
  {
    path: '',
    component: HubsListingComponent,
  },
  {
    path: 'new',
    data: { label: 'New hub', translationKey: 'navigation.hub_new', labelSource: null},
    children: [
      {
        path: '',
        component: HubNewComponent,
      },
    ],
  },
  {
    path: ':hub',
    data: { label: 'Hub homepage', translationKey: null, labelSource: 'titleService'},
    children: [
      {
        path: '',
        component: HubHomeComponent,
      },
      {
        path: 'programmes',
        data: { label: 'Programmes', translationKey: 'navigation.programmes', labelSource: null},
        children: [
          {
            path: '',
            component: ProgrammeListPromoComponent,
          },
          {
            path: ':programme',
            data: { label: 'Programme editor', translationKey: null, labelSource: 'titleService'},
            children: [
              {
                path: '',
                component: ProgrammeEditorComponent,
              }
            ],
          },
        ],
      },
      {
        path: 'marketing',
        data: { label: 'Marketing', translationKey: 'navigation.marketing', labelSource: null},
        children: [
          {
            path: '',
            component: HubMarketingComponent,
          },
          {
            path: 'profiles',
            data: { label: 'Profiles', translationKey: 'common.profiles', labelSource: null},
            children: [
              {
                path: '',
                component: HubProfilesComponent,
              },
              {
                path: ':profile',
                data: { label: 'Profile editor', translationKey: 'common.general', labelSource: null},
                children: [
                  {
                    path: '',
                    component: HubProfileEditComponent,
                  }
                ],
              },
            ],
          },
          {
            path: 'brands',
            data: { label: 'Brands', translationKey: 'navigation.brands', labelSource: null},

            loadChildren: () =>
              import('@frontend/common').then((m) => m.BrandingModule),
          },
        ],
      },
      {
        path: 'roles',
        component: HubRolesComponent,
        data: { label: 'Roles', translationKey: 'common.roles', labelSource: null},
      },
      {
        path: 'cultureconnector',
        component: HubCultureConnectorComponent,
        data: { label: 'CCO', translationKey: 'platform.cco', labelSource: null},
      },

      {
        path: 'branding',
        loadChildren: () =>
          import('@frontend/common').then((m) => m.BrandingModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HubsRoutingModule { }
