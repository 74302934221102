import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { Media } from '@frontend/core';


@Component({
  selector: 'frontend-person-card-testimonial-type-two',
  templateUrl: './person-card-testimonial-type-two.component.html',
  styleUrls: ['./person-card-testimonial-type-two.component.scss']
})
export class PersonCardTestimonialTypeTwoComponent implements OnChanges {

  @Input() cardStyles : string = 'max-width: 22rem;cursor:pointer' // good default value
  @Input() cardBannerClasses : string = 'purple lighten-1';  // good default value. 'aqua-gradient' is another good one
  @Input() imgUrl : string; // provide a simple URL here or a full media element. We don't need both.
  @Input() transformations : Media;
  @Input() name : string;
  @Input() subtitle : string; // for example, the jobtitle
  @Input() identifier : string | number;
  @Input() nameClickable : string; // if provided, the name will be a link
  @Input() bodyText : string; // if provided, the name will be a link
  @Output() nameClicked = new EventEmitter<any>();


  constructor() { }

  doNameClick (){
    this.nameClicked.emit(this.identifier);
  }
  truncateText(sourceText, length){
    let truncatedText = sourceText.substr(0, length);
    return truncatedText.trim()+'...';
  }

  ngOnChanges(changesObject) {
    if (changesObject?.bodyText?.currentValue?.length){
      if (changesObject.bodyText.currentValue.length > 190){
        this.bodyText = this.truncateText(changesObject.bodyText.currentValue,180);
      };
    }
  }

}
