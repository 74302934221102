<div class="card survey-raters">
  <div class="card-body">
    <h5 class="card-title font-weight-bold">Your raters</h5>
    <p class="card-text">We may ask your contacts to answer these questions about you too</p>
    <p class="px-0 py-1">
      <span *ngFor="let rater of raters">
        <multisite-avatar-pill [fallbackImageUrl]="
          'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'
        " [imageUrl]="rater.picture" [text]="
          rater.pre_honorific +
          ' ' +
          rater.fname +
          ' ' +
          rater.lname +
          '
    ' +
          rater.post_honorific
        "></multisite-avatar-pill>
      </span>
    </p>
  </div>
  <div class="star-decoration">
    <i class="fa-solid fa-star"></i>
  </div>
</div>