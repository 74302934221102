import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PromoContentService } from '../../promo-content/promo-content.service';
import { CarouselItem } from '../../models';
import { WindowService } from '@frontend/common';

@Component({
  selector: 'multisite-authentication-overview',
  templateUrl: './authentication-overview.component.html',
  styleUrls: ['./authentication-overview.component.scss']
})
export class AuthenticationOverviewComponent implements OnInit {
  
  email: string;
  token: string;
  mode: string;
  carouselItems : CarouselItem[];
  interval: number; // milliseconds

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private promoContentService : PromoContentService,
    private windowService: WindowService,
    @Inject('appKey') private appKey: string,
  ) { }

  preparePromo (){
    let carouselItemSelections = this.promoContentService.getCarouselItemSelection(this.mode);
    if (!carouselItemSelections?.length){
      carouselItemSelections = [0,1,2];
    }
    this.carouselItems = this.promoContentService.getCarouselItemsByIndex(carouselItemSelections, this.appKey);
    this.interval = 2000;
  }
  preparePasswordReset (){
    this.email = this.route.snapshot.params['email'];
    this.token = this.route.snapshot.params['hash'];
  }

  ngOnInit(): void {
    this.windowService.goToTop();
    this.mode = this.router.url.substring(1); // for example 'login' or 'register'
    if (this.mode?.indexOf('password/reset')===0) {
      this.mode = 'password-reset'
      this.preparePasswordReset();
    }
    this.preparePromo();
  }
}
