<div class="container-fluid">
  <div class="row">
    <div class="wide-card mt-4">
      <div class="card" *ngIf="topic">
        <div class="row">
          <div class="col-md-3">
            <img
              class="card-img-left"
              *ngIf="thumbnail"
              [src]="
                thumbnail.asset_url && thumbnailTransformations
                  ? thumbnail.base_url +
                    thumbnailTransformations +
                    thumbnail.asset_url
                  : thumbnail.file_url
              "
              [alt]="thumbnail.description"
            />
          </div>
          <div class="col-md-9">
            <div class="card-body" *transloco="let t">
              <h5 class="card-title">{{ topic.name }}</h5>
              <p class="card-text" [innerHTML]="topic.description"></p>
              <div class="info-group flex-wrap gap-2">
                <div class="text-badge">
                  <p class="title">
                    {{ t('navigation.courses') }}
                  </p>
                  <p class="value">
                    {{ courses?.length }}
                  </p>
                </div>
                @if (tipCount){
                <span class="splitter" *ngIf="tipCount"></span>
                <div class="text-badge" *ngIf="tipCount">
                  <p class="title">
                    {{ t('navigation.tips') }}
                  </p>
                  <p class="value">
                    {{ tipCount }}
                  </p>
                </div>
                } @if (accreditations?.length){
                <span class="splitter"></span>
                <button type="button" class="img-badge btn btn-primary" (click)="gotoRelation('accreditations')">
                  <i class="fa-solid fa-file-certificate fa-xl"></i>
                  <p class="title">
                    {{ t('marketing.accreditation_available') }}
                  </p>
                  <i class="fa-solid fa-arrow-right"></i>
                </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *transloco="let t">
    <div class="col-md-6 mt-4">
      <button
        class="btn button-wide btn-warning h-100"
        [routerLink]="['tips']"
        routerLinkActive="router-link-active"
      >
        <p>{{ t('content.get_tips') }}</p>
        <i class="fa-solid fa-angle-right"></i>
      </button>
    </div>
    <div class="col-md-6 mt-4">
      <button
        class="btn button-wide btn-primary h-100"
        (click)="gotoRelation('courses')"
      >
        <p>{{ t('content.explore_courses') }}</p>
        <i class="fa-solid fa-angle-right"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mt-4">
      <multisite-loader-globe-animation-detail *ngIf="loadingObject.tips" [width]="100" [height]="100">
      </multisite-loader-globe-animation-detail>
      <multisite-carousel-card *ngIf="!loadingObject.tips && latestTipsAsGenericContent?.length"
        [content]="latestTipsAsGenericContent"
        [loading]="loadingObject.tips"
        [ratingMax]="5"
        [imageTransformations]="'w_275/'"
        [title_translationKey]="'content.latest_tips'"
        [cta_translationKey]="'content.go_to_tip'"
        (actionTriggered)="gotoTip($event)"
      >
      </multisite-carousel-card>
    </div>
    <div class="col-md-6 mt-4">
      <multisite-loader-globe-animation-detail *ngIf="loadingObject.reviews" [width]="100" [height]="100">
      </multisite-loader-globe-animation-detail>
      <multisite-carousel-card-quotations *ngIf="!loadingObject.reviews && topPublicReviewsAsGenericContent?.length"
        [content]="topPublicReviewsAsGenericContent"
        [loading]="loadingObject.reviews"
        [ratingMax]="5"
        [title_translationKey]="'content.people_saying'"
      >
      </multisite-carousel-card-quotations>
    </div>
  </div>
</div>
