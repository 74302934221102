<!--Grid row-->
<div class="container-fluid" *transloco="let t">  
  <div class="row">
    <div class="col">
      <div class="text-center mt-5 pt-5">
        <h1 class="display-3">{{t('culturettt_content.promotion.highlight01_title')}}</h1>
        <p class="lead w-75 text-center mx-auto mt-3">{{t('culturettt_content.promotion.highlight01_description')}}</p>

      </div>
    </div>
  </div>

  <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
  </multisite-loader-globe-animation-detail>


  <div class="d-flex flex-wrap gap-3 subhero-cards">

    @for(topic of topicsByWeight(); track topic.id) {
    <div class="flex-grow-1 col-12 col-sm-4 col-lg-3 align-items-start">

      <div class="card d-flex flex-column">

        @if(getThumbnail(topic)){

        <img
        [src]="
          cloudinary_base_url() +
          thumbnailTransformations() +
          getThumbnail(topic).file_name +
          '.jpg'
        "
        [alt]="getThumbnail(topic).description"
        class="card-img-top"
      />


        }
        <div class="card-body flex-grow-1">
          <div class="text">
            <h2 class="card-title">{{topic.name}}</h2>
            <p class="card-text">{{topic.description}}</p>
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-start mt-2">
            <button [routerLink]="['/'+topic.slug]" type="button" class="btn btn-primary">{{t('common.explore')}}</button>
            <button [routerLink]="['/'+topic.slug+'/tips']" type="button" class="btn btn-primary">{{t('content.get_tips')}}</button>
          </div>
        </div>
      </div>
    </div>
    }


  </div>


  <div class="row">
    <div class="col">
      <div class="text-center my-5">
        <h3 class="display-4">{{t('culturettt_content.promotion.highlight04_title')}}</h3>
        <p class="lead w-75 text-center mx-auto mt-3">{{t('culturettt_content.promotion.highlight04_description')}}</p>

      </div>
    </div>
  </div>

  <!-- Unused content -->

  <!-- 
  "highlight02_title" : "Professional development for interculturalists",
  "highlight02_description" : "Continue your progress towards being among the best in a competitive environment",
  "highlight03_title" : "Save your time and increase your impact",
  "highlight03_description" : "Learn practical ways to achieve more in less time for your intercultural training clients" -->


</div>