import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Subscription, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService, CloudinaryMediaAsset, ContentItem, UserExtraLite, LanguageService, MediaService, MetaText, Topic } from '@frontend/common';
import { IModelsToContentItemsConverter, Link, Tag, Video } from '@frontend/core';
import { Accreditation } from './accreditation.model';
import { TrackingService } from '../tracking/tracking.service';
import { Experience } from '../tracking/experience.model';
import { DataProcessingService } from '../utilities/data-processing.service';
import { Icon } from '@frontend/shared';

interface AccreditationResponse {
  id: number;
  name: string;
  description: string;
  title: string;
  slug: string;
  category: string;
  type: string;
  videos : Video[];
  topicSlugs : string[];
  links: Link[];
  icon: Icon;
  tags: Tag[];
  period: number;
  reviews: any ;
  supervisors: UserExtraLite[];
  metaTexts: MetaText[];
  access: any; // required role; required subscription; required purchases etc
  media: any[]; // 
}

@Injectable({
  providedIn: 'root'
})
export class AccreditationService implements IModelsToContentItemsConverter {

  accreditations : Accreditation[] = [];
  allAccreditationsForTopic : {topicSlug:string,loaded_at:Date}[] = []; // TODO - we should get rid of this when add pagination or other filters
  activeLanguageSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private mediaService : MediaService,
    // private trackingService : TrackingService,
    private dataProcessingService: DataProcessingService,
    private authService: AuthService,
    private languageService: LanguageService,
    ) {
      this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
  }

  clearTranslations (){
    this.accreditations = [];
    this.allAccreditationsForTopic = [];
  }
  clearData (){
    this.clearTranslations();
  }
  transformAccreditation (accreditationResponse : AccreditationResponse){
    let mediaArray : CloudinaryMediaAsset[] = [];
    if (accreditationResponse.media?.length){
      accreditationResponse.media.forEach(m => {
        mediaArray.push(Object.assign(m, this.mediaService.setupCloudinaryImageMediaUrls(m)));
      });
    }
    let accreditation : Accreditation = Object.assign(accreditationResponse, {media:mediaArray});
    if(accreditationResponse.metaTexts){
      accreditation.learnings = accreditationResponse.metaTexts.filter(m=>m.category==='benefits' && m.type==='learn');
      accreditation.target_groups = accreditationResponse.metaTexts.filter(m=>m.category==='people' && m.type==='target_groups');
      accreditation.process = accreditationResponse.metaTexts.filter(m=>m.category==='process');
    }
    if(accreditationResponse.supervisors?.length){
      accreditation.supervisors = accreditationResponse.supervisors.map(s=> {
        s.languages = s.advancedLanguages;
        return s;
      });
    }
    return accreditation;
  }
  transformAccreditations (accreditationsFromBackend : AccreditationResponse[]){
    let transformedAccreditations : Accreditation[] = [];
    accreditationsFromBackend.forEach(t=>{
      transformedAccreditations.push(this.transformAccreditation(t));
    });
    return transformedAccreditations;
  }
  getCachedAccreditation (slug: string){
    return this.accreditations.find(a=>a.slug === slug);
  }
  getCachedAccreditations (topicSlug){
    let cachedAccreditations : Accreditation[] = null;
    if (topicSlug){
      cachedAccreditations = this.accreditations.filter(a=>{ return a.topicSlugs.includes(topicSlug);});
    } else {
      cachedAccreditations = this.accreditations;
    }
    return cachedAccreditations
  };
  cacheAccreditations (accreditations: Accreditation[]){
    accreditations.forEach(a=>{
      this.cacheAccreditation(a);
    });
  };
  cacheAccreditation (accreditation: Accreditation){
    let cachedAccreditationIndex = this.accreditations.findIndex(a=>a.id === accreditation.id);
    if (cachedAccreditationIndex >-1){
      this.accreditations[cachedAccreditationIndex] = accreditation;
    } else {
      this.accreditations.push(accreditation);
    }
  };
  insertTopicsIntoAccreditation(accreditation : Accreditation, topics:Topic[]){
    accreditation.topics = topics.filter(t=>accreditation.topicSlugs.includes(t.slug));
    return accreditation;
  }
  insertTopicsIntoCachedAccreditations(topics:Topic[]){
    if(!topics?.length || this.accreditations[0]?.topics){return;};
    this.accreditations.forEach (accreditation => {
      accreditation = this.insertTopicsIntoAccreditation(accreditation,topics);
    });
  }
  getAccreditation (slug : string, freshFromBackend : boolean){
    if (!slug){alert('Check the address');};
    let cachedAccreditation : Accreditation = this.getCachedAccreditation(slug);
    if (cachedAccreditation?.videos && !freshFromBackend){
      return of(cachedAccreditation);
    };
    
    return this.http.get<AccreditationResponse>('api/v1/accreditations/'+slug)
        .pipe(
          map(response =>{
            if (response ){
              let accreditation : Accreditation =  this.transformAccreditation(response)
              this.cacheAccreditation(accreditation);
              return accreditation;
            };
          })
        )
  };

  // getRelatedExperiences (slug : string, withArchivedExperiences : boolean = false){
  //   if (!slug){alert('Check the address');};
  //   slug = slug.toLowerCase()
  //   let cachedAccreditation : Accreditation = this.getCachedAccreditation(slug);
  //   let relatedExperiences : Experience[];
  //   if (cachedAccreditation?.id){
  //     relatedExperiences =  this.trackingService.getCachedExperienceMultiple('accreditation', null, cachedAccreditation.id, null, withArchivedExperiences)
  //   };
  //   let relatedExperiencesIncludeSomeArchivedExperiences : boolean; // TODO - this is not a good approach!! If we need to view the archive and it does not exist on the backend, we have to call this every time to discover the non-existence

  //   if (relatedExperiences?.length){
  //     relatedExperiencesIncludeSomeArchivedExperiences = !!relatedExperiences.find(e=>e.archive_id);
  //   };
  //   if (relatedExperiences?.length && !this.trackingService.isTheOnlyExperienceProgress (relatedExperiences) && (!withArchivedExperiences || (withArchivedExperiences && relatedExperiencesIncludeSomeArchivedExperiences))){
  //     return of(relatedExperiences);
  //   };

  //   let params = new URLSearchParams();
  //   let url = 'api/v1/accreditations/experiences/'+slug+'?';
    
  //   // if (snapshot_id){
  //   //   params.append('snapshot_id',snapshot_id.toString())
  //   // };
  //   if (withArchivedExperiences){
  //     params.append('with_archive','true');
  //   };
  //   let user = this.authService.user.getValue();
  //   let guest = this.authService.guest.getValue();
  //   if (!user && guest){
  //     params.append('guest_uuid',guest.uuid)
  //   };
  //   if (!user && !guest){
  //     throw 'Log in or create guest';
  //   };
    
  //   return this.http.get<{relatedExperiences: Experience[]}>(url+params.toString())
  //       .pipe(
  //         map(response =>{
  //           if (response && response.relatedExperiences ){
  //             this.trackingService.cacheMultipleExperiences(response.relatedExperiences);
  //             return response.relatedExperiences;
  //           };
  //         }),
  //         catchError((error)=>{
  //           if (error.error?.message == 'Guest authentication error') {
  //             this.authService.removeGuestLocally();
  //             return [];
  //           } else {
  //             this.handleError(error);
  //           }
  //         }),
  //       )
  // };
  getAccreditationExperience(accreditation_id : number, experiences: Experience[] = [], experience_verbs : string[] = [], includeArchived : boolean = false){
    // returns the first one it finds
    const backendClass = this.dataProcessingService.convertModelNameToBackendClass('accreditation');
    return Boolean (experiences.find(e=>
      e.experienceable_type === backendClass &&
      (accreditation_id ? e.experienceable_id === accreditation_id : true) &&
      (includeArchived ? e.archive_id : true) &&
      experience_verbs.includes(e.experience_verb) &&
      e.experience_value
    ));
  };
  getAccreditations (topicSlug : string, freshFromServer : boolean){
    
    let cachedAccreditations : Accreditation[] = this.getCachedAccreditations(topicSlug);
    
    if (!freshFromServer && this.allAccreditationsForTopic.find(a=>a.topicSlug==topicSlug)) {
      return of(cachedAccreditations);
    }

    let url = 'api/v1/accreditations?topics='+topicSlug; // potentially an array of slugs in future
    return this.http.get<{data: AccreditationResponse[]}>(url)
        .pipe(
          map(response =>{
            let accreditations : Accreditation[] = null;
            if (response?.data ){
              accreditations = this.transformAccreditations(response.data);
              this.cacheAccreditations(accreditations);
              if (!this.allAccreditationsForTopic.find(a=>a.topicSlug==topicSlug)){
                this.allAccreditationsForTopic.push({topicSlug:topicSlug,loaded_at:new Date()});
              }
              return accreditations;
            };
          }),
          catchError(this.handleError),
        )
  };
  getCachedAccreditationsByTopicSlugs (topicSlugs : string[]) : Accreditation[]{

    return this.accreditations.filter(a=>topicSlugs.find(topicSlug=>a.topicSlugs.includes(topicSlug)));
    
  };
  makeContentItems(accreditations : Accreditation[]) : ContentItem[] {
    let accreditationsAsContent: ContentItem[] = [];
    accreditations.forEach((a) => {
      accreditationsAsContent.push(
        new ContentItem(
          a.id,
          a.name,
          null,
          a.title,
          null,
          a.description,
          null,
          null,
          a.slug,
          'Go to accreditation',
          'common.go',
          a.media?.find(m=>m.category==='accreditation' && m.type==='badge'),
          null,
          null,
          null,
          null,
          null,
          a.tags,
          null,
          null
        )
      );
    });
    return accreditationsAsContent;
  }

  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    if (errorResponse.error.message == 'Guest authentication error') {
      this.authService.removeGuestLocally();
      return 
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }
}
