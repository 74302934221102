<div *transloco="let t" class=" mx-auto my-2 access-code">
  <div [formGroup]="accessCodeForm" class="access-code-form" [ngClass]="{dark:backgroundFill}">
    <input type="text" formControlName="accessCode" aria-label="Access code"
      class="form-control" id="ac" [placeholder]="t('events.enter_code')" (keydown)="onKeyDown($event)">
    <button type="button" class="btn btn-sm btn-primary"
      [disabled]="accessCodeForm.get('accessCode').invalid || loading" (click)="submit()"><i
        class="fa-solid fa-arrow-right"></i></button>
  </div>
  <div class="validation-message">
    <div *ngIf="error" class="message-format error">
      <i class="fa-regular fa-circle-xmark"></i>
      {{t(error)}}
    </div>
    <div
      *ngIf="accessCodeForm.get('accessCode').invalid && (accessCodeForm.get('accessCode').dirty || accessCodeForm.get('accessCode').touched)">
      <div class="message-format warning" *ngIf="accessCodeForm.get('accessCode').errors.pattern">
        <i class="fa-solid fa-triangle-exclamation"></i>
        {{t('events.invalid_code')}}
      </div>
    </div>
  </div>


</div>