import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators';

import { SingleRatingResponse } from './rating-response-single.model';

@Injectable({
  providedIn: 'root'
})
export class RatingService {

  myRatings : SingleRatingResponse[];
  
  constructor(
    private http: HttpClient
    ) {
      this.clearData()
    }

  clearData (){
    this.myRatings = [];
  }

  rate (modelNameSingular : string, id : number, slug : string, rating : number, rating_type: string){
    if (!slug && !id){
      throw new Error("You must provide an identifier");
    }
    let params = {
      'ratable_type' : modelNameSingular,
      'ratable_identifier' : id ? id : slug,
      'rating' : rating,
      'rating_type' : rating_type
    };
    return this.http.post<SingleRatingResponse>(
      'api/v1/rating', params)
        .pipe(
          map(response =>{
            if (response ){
              this.cacheMyRating (response)
              return response;
            };
          })
        )
  }
  cacheMyRating (response : SingleRatingResponse){
    let cachedRatingIndex = this.getCachedMyRatingIndex (response.ratable_type, response.ratable_id, response.ratable_slug, response.rating_type);
    if (cachedRatingIndex === -1){
      this.myRatings.push(response);
    } else {
      this.myRatings[cachedRatingIndex] = response;
    };
  }
  getCachedMyRatingIndex (modelNameSingular : string, id : number, slug : string, rating_type : string) : number {
    return this.myRatings.findIndex(r => r.ratable_type === modelNameSingular && (r.ratable_id == id || r.ratable_slug == slug) && r.rating_type == rating_type);
  };
  getMyRating (modelNameSingular : string, id: number, slug : string , rating_type : string){
  
    let cachedMyRating = this.myRatings[this.getCachedMyRatingIndex (modelNameSingular, id, slug, rating_type)];
    
    if (cachedMyRating){
      return of(cachedMyRating);
    };

    if (modelNameSingular){
      modelNameSingular =  modelNameSingular.toLowerCase();
    } else {
      throw new Error("You must provide the class name");      
    }
    let identifier = id ? id : slug;
    let url = 'api/v1/rating/class/'+modelNameSingular+'/'+identifier+'?&rating_type='+rating_type+'&my_rating=1';
    /* for example 
      api/v1/rating/class/tip/libero-quibusdam-iure-quia-similique-iusto7555?rating_type=usefulness&my_rating=true
    */
    return this.http.get<SingleRatingResponse>(url)
        .pipe(
          map(response =>{
            if (response ){
              if (response.my_rating){
                this.cacheMyRating (response)
              }
              return response;
            };
          })
        )
  };
}
