
<div class="container">
  <div class="row mt-4">
    <div class="col-md-4 offset-md-4">
      <p>The true home page for creating your Pendulum</p>

      <div class="card my-3" *ngFor="let survey of surveys">
        <div class="card-header">
          Create a snapshot
        </div>
        <div class="card-body">
          <h5 class="card-title">{{survey.name}}</h5>
          <p class="card-text">Some informative and motivating thing here.</p>
          <a [routerLink]="['./'+survey.slug]"  class="btn btn-primary mt-2">Go</a>
        </div>
      </div>
    </div>
  </div>
</div>