import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DifferenceScaleComponent } from './difference-scale/difference-scale.component';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    DifferenceScaleComponent
  ],
  exports: [
    DifferenceScaleComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
  ]
})
export class DifferencesModule { }
