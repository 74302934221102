import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'frontend-link-type-twelve',
  templateUrl: './link-type-twelve.component.html',
  styleUrls: ['./link-type-twelve.component.scss']
})
export class LinkTypeTwelveComponent implements OnInit {

  // Icon and CTA card link

  @Input() titleText : string;
  @Input() titleTranslationKey : string; // 'common.instructions'
  @Input() descriptionText : string;
  @Input() descriptionTranslationKey : string; // 'common.instructions'
  @Input() iconClasses : string; // 'fa fa-heart'
  @Input() cta_text : string; // 'Go to Tip' 
  @Input() cta_translationKey : string; // 'content.go'
  @Input() ctaButtonClasses : string = 'btn btn-sm btn-success';

  @Output() linkClicked = new EventEmitter();


  constructor() { }


  doLinkClick (){
    this.linkClicked.emit();
  }

  ngOnInit(): void {
  }

}
