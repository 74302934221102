<alert [type]="'warning'" [dismissOnTimeout]="3500" *ngIf="error"><span *transloco="let t">{{t(error)}}</span></alert>

  <div class="row">
    <div class="col">
      <div *transloco="let t">
  
        <form [formGroup]="fontForm" role="form" class="mt-4" *ngIf="fontForm">
          <div class="form-check">
            <input
              value="sans-serif"
              formControlName="fontFormat"
              class="form-check-input"
              type="radio"
              id="sans-serif"
              [attr.disabled]="disabled ? true : null"
            />
            <label class="form-check-label" for="sans-serif">
              Sans serif
            </label>
          </div>
          <div class="form-check">
            <input
              value="serif"
              formControlName="fontFormat"
              class="form-check-input"
              type="radio"
              id="serif"
              [attr.disabled]="disabled ? true : null"
            />
            <label class="form-check-label" for="serif"> Serif </label>
          </div>
        </form>
      </div>
    </div>
  </div>
