import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Difference, DataProcessingService, LanguageService } from '@frontend/common';

@Injectable({
  providedIn: 'root',
})
export class DifferenceService {
  activeLanguageSubscription: Subscription;
  differences: Difference[] = [];
  allDifferencesReceived : Date; // have we requested and got all differences (no category and not type) ?

  constructor(
    private languageService: LanguageService,
    private http: HttpClient,
    private dataProcessingService : DataProcessingService,
  ) 
  {
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
  }

  clearTranslations() {
    this.differences = [];
  }
  cacheDifferences(differences:Difference[]){
  
    if (this.differences.length){
      this.differences = this.dataProcessingService.mergeTwoArraysWithUniqueIdentifiersAvoidingDuplicates(differences,this.differences,'id');
    } else {
      this.differences = differences;
    }
  }

  getDifferences(category:string,type:string) {
    if(!category && !type && this.allDifferencesReceived){
      return of (this.differences);
    }
    let filteredDifferences : Difference[] = [];
    if (category){
      filteredDifferences = this.differences.filter(d=> d.category===category);
    };
    if (type){
      let differences = filteredDifferences.length ? filteredDifferences : this.differences;
      filteredDifferences = differences.filter(d=> d.type===type);
    };
    if (filteredDifferences.length) {
      return of(filteredDifferences);
    }
    let url = 'api/v1/differences?';

    let params = new URLSearchParams();
    
    if (category){
      params.append('category',category.toString())
    };
    if (type){
      params.append('type',type)
    };
    return this.http.get<{data:Difference[]}>(url+params.toString()).pipe(
      map((response) => {
        if (response?.data?.length) {
          // response.data = this.transformDifferences(response.data);
          this.cacheDifferences(response.data);
          if(!category && !type){
            this.allDifferencesReceived = new Date();
          }
          return response.data;
        }
      })
    );
  }
}
