import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoService } from '../../video';
import { ContentItem } from '../../content-item.model';
import { Subscription } from 'rxjs';
import { Video } from '@frontend/core';

@Component({
  selector: 'multisite-card-cta-horizontal',
  templateUrl: './card-cta-horizontal.component.html',
  styleUrls: ['./card-cta-horizontal.component.scss'],
})
export class CardCtaHorizontalComponent implements OnChanges, OnDestroy {

  @Input() content: ContentItem;
  @Input() ratingMax: number;
  @Input() breakpoint: string = 'lg'; // or 'md' // When displaying this component in a wide layout (for example when it is not sitting next to a sidebar), we will replace the col-lg-4 and col-lg-8 classes col-md-4 and col-md-8 classes:
  thumbnailTransformations: string = 'w_295/';
  @Input() imageTransformations : string; // 'w_275,ar_16:9/'

  videoThumbnailUrl : string;
  videoThumbnailSubscription : Subscription;
  videoThumbnailLoading : boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private videoService: VideoService,
  ) {
    
    this.ratingMax = this.ratingMax ? this.ratingMax : 5;
    
  }
  gotoContent(){
    this.router.navigate(['./'+this.content.slug], { relativeTo: this.route });
  };
  action (){
    alert('You clicked');
  }
  getMinutesFromSeconds (seconds : number){
    return Math.round(seconds/60);
  }
  getVideoThumbnail(video : Video){
    let solvedVimeoCORSproblem = false;
    if (solvedVimeoCORSproblem){
      this.videoThumbnailLoading = true;
      this.videoThumbnailSubscription = this.videoService.getVideoThumbnailAsynchronously(video).subscribe(thumbnail =>{
        this.videoThumbnailUrl = thumbnail;
        this.videoThumbnailLoading = false;
  
      },
      error => this.videoThumbnailLoading = false
      )
    } else {
      this.videoThumbnailUrl = this.videoService.getVideoThumbnailSynchronously(video);
    }
  };
  

  ngOnChanges(changesObject): void {

    if(changesObject.content?.currentValue?.video && !this.videoThumbnailLoading){
      this.getVideoThumbnail(this.content.video);
    }
    
  }
  ngOnDestroy(): void {

    if(this.videoThumbnailSubscription){
      this.videoThumbnailSubscription.unsubscribe();

    }
    
  }
}
