import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CloudinaryMediaAsset, Hub, MediaService } from '@frontend/common';
import { Programme } from './programme.model'
import { PaginatedProgrammes } from './paginated-programmes.model';
import { ContentItem } from '@frontend/common';
import { ContentService } from '../content/content.service';
import { IModelsToContentItemsConverter } from '@frontend/core';

@Injectable({
  providedIn: 'root'
})
export class ProgrammeService implements IModelsToContentItemsConverter {

  paginatedProgrammesByHubId = {};

  constructor(private http: HttpClient, private mediaService : MediaService, private contentService : ContentService) { }

  convertProgrammeToContentItem (programme: Programme): ContentItem {
    if (!programme) {return null;};
    let contentItem = new ContentItem (
      programme.id,
      programme.name,
      null,
      null,
      null,
      programme.description,
      null,
      null,
      programme.slug,
      null, null,
      null,null,
      null,null,null,
      new Date(programme.start_at),
      programme.tags,
      null,
      null
      );
      return contentItem;

  }
  makeContentItems (programmes: Programme[]) : ContentItem[] {
    let content : ContentItem[] = [];
    if (programmes?.length){
      programmes.forEach(programme => {
        content.push(this.convertProgrammeToContentItem(programme));
      });
    }
    return content;
  }
  transformProgramme (programmeResponse){
    if (programmeResponse.media?.length){
      if (programmeResponse.media.find(m=>m.category === 'thumbnail' && m.type === 'main')) {
        programmeResponse.media = programmeResponse.media.find(m=>m.category === 'thumbnail' && m.type === 'main');
      } else {
        programmeResponse.media = programmeResponse.media[0];
      };
      programmeResponse.media = Object.assign(programmeResponse.media, this.mediaService.setupCloudinaryImageMediaUrls(programmeResponse.media));
    } else {
      programmeResponse.media = null;
    }
    programmeResponse.rating = Math.round(programmeResponse.rating);
    let programme : Programme = programmeResponse;
    return programme;
  }
  transformProgrammes (programmesFromBackend){
    let transformedprogrammes : Programme[] = [];
    programmesFromBackend.forEach(p=>{
      transformedprogrammes.push(this.transformProgramme(p));
    });
    return transformedprogrammes;
  }
  getProgrammeForEditing (slug: string, freshFromServer: boolean){

    let cachedProgramme : Programme = this.getCachedProgramme(slug);
    if (cachedProgramme?.trainers && !freshFromServer){ // trainers will only be present if it is the full editable resource
      return of(cachedProgramme);
    };

    return this.http.get<{'data' : Programme}>(
      'api/v1/programmes/edit/'+slug)
        .pipe(
          map(response =>{
            let programme : Programme = this.transformProgramme(response.data);
            this.cacheHubProgramme(programme);
            return programme;
          })
        )
  };
  getCachedProgramme (slug){
    let pageNumber : number = 1;
    let programme : Programme = null;

    for (const hubObject in this.paginatedProgrammesByHubId) {
      while (this.paginatedProgrammesByHubId[hubObject][pageNumber]){
        programme = this.paginatedProgrammesByHubId[hubObject][pageNumber].data.find(p=>p.slug == slug);
        if (programme){
          return programme;
        } else {
          pageNumber++;
        }
      }
    }
  };
  getCachedProgrammes (hub_id:number,page:number){
    let cachedProgrammes : PaginatedProgrammes = null;
    if (this.paginatedProgrammesByHubId[hub_id] && this.paginatedProgrammesByHubId[hub_id][page]){
      return this.paginatedProgrammesByHubId[hub_id][page];
    }
    return cachedProgrammes
  };
  cacheProgrammes (programmes,hub_id,page){
    let paginatedProgrammes :  Record<number, PaginatedProgrammes> = {};
    if (!this.paginatedProgrammesByHubId[hub_id]){
      this.paginatedProgrammesByHubId[hub_id] = paginatedProgrammes;
    }
    this.paginatedProgrammesByHubId[hub_id][page] = programmes;
  };
  cacheHubProgramme (programme: Programme){
    if (!programme?.hub_id){return;};
    if (this.paginatedProgrammesByHubId[programme.hub_id]){
      for (const page in this.paginatedProgrammesByHubId[programme.hub_id]) {
        let cachedProgrammeIndex = this.paginatedProgrammesByHubId[programme.hub_id][page].data.findIndex(p=>p.slug == programme.slug);
        if (cachedProgrammeIndex >-1){
          this.paginatedProgrammesByHubId[programme.hub_id][page].data[cachedProgrammeIndex] = programme;
          return;
        };
      };
    }
  };
  getProgrammes (hub : Hub, page : number, freshFromServer : boolean){

    page = page ? page : 1;

    let cachedProgrammes : PaginatedProgrammes;
    
    if (!freshFromServer) {
      cachedProgrammes = this.getCachedProgrammes (hub.id,page);
    }
    if (cachedProgrammes){
      return of(cachedProgrammes);
    };
    let url = 'api/v1/hubs/programmes/'+hub.slug+'/?page='+page;
    /* for example 
      'api/v1/hubs/programmes/merolion-company&page=1;
    */
    return this.http.get<PaginatedProgrammes>(url)
        .pipe(
          map(response =>{
            if (response && response.data ){
              response.data = this.transformProgrammes(response.data);
              this.cacheProgrammes(response, hub.id, page);
              return response;
            };
          })
        )
  };
  getImage (programmeMedia : CloudinaryMediaAsset[], type : string){
    if (!programmeMedia?.length){ return null ;};
    if (type === 'thumbnail'){
      return programmeMedia.find(m=>m.category==='thumbnail' && m.type==='main');
    }
  }
}
