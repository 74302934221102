import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@jsverse/transloco';
import { ProgrammeListPromoComponent } from './programme-list-promo.component';
import { LoadersModule } from '../../loaders';
import { RatingModule } from 'ngx-bootstrap/rating';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from '@frontend/shared';

@NgModule({
  declarations: [
    ProgrammeListPromoComponent,
  ],
  exports: [
    ProgrammeListPromoComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    RouterModule,
    LoadersModule,
    FormsModule,
    RatingModule,
    PaginationModule
  ],
})
export class ProgrammeListModule { }
