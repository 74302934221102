import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionPresenterComponent } from './question-presenter/question-presenter.component';
import { QuestionMultipleChoiceComponent } from './question-multiple-choice/question-multiple-choice.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { LoadersModule } from '../../loaders/loaders.module';
import { AlertModule } from 'ngx-bootstrap/alert';
import { RouterModule } from '@angular/router';
import { QuestionModeHomeTypeOneComponent } from './question-mode-home-type-one/question-mode-home-type-one.component';
import { ProgressIndicatorCardSimpleModule } from '../../tracking/progress/progress-indicator-card-simple/progress-indicator-card-simple.module';
import { QuestionLickertComponent } from './question-lickert/question-lickert.component';
import { BellCurveModule, IconArrayModule } from '@frontend/shared';
import { SurveyContextModule } from '../survey-context';
import { SurveyRatersModule } from '../survey-raters/survey-raters.module';



@NgModule({
  declarations: [
    QuestionPresenterComponent,
    QuestionMultipleChoiceComponent,
    QuestionLickertComponent,
    QuestionModeHomeTypeOneComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    LoadersModule,
    AlertModule,
    RouterModule,
    ProgressIndicatorCardSimpleModule,
    BellCurveModule,
    IconArrayModule,
    SurveyContextModule,
    SurveyRatersModule,
  ],
  exports: [
    QuestionPresenterComponent,
    QuestionMultipleChoiceComponent,
    QuestionLickertComponent,
    QuestionModeHomeTypeOneComponent,
  ]
})
export class SurveyQuestionsModule { }
