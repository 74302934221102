import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaService } from '@frontend/common';
import { Icon } from '@frontend/shared';

@Component({
  selector: 'progress-indicator-slimmable-single',
  templateUrl: './progress-indicator-slimmable-single.component.html',
  styleUrls: ['./progress-indicator-slimmable-single.component.scss']
})
export class ProgressIndicatorSlimmableSingleComponent implements OnInit {

  @Input() slimMode : boolean;
  // @Input() translationKey : string; // 'topics.cco'
  @Input() text : string; // 'CultureConnector'
  @Input() progressPercent : number; // 44
  @Input() iconBackgroundClass : string; //  'bg-success'
  @Input() icon : Icon;
  @Output() selected = new EventEmitter<any>();
  cloudinary_base_url : string; // 'https://res.cloudinary.com/cebt/image/upload/'
  
  constructor(private mediaService : MediaService) { 
    this.cloudinary_base_url = this.mediaService.cloudinary_base_url;
  }


  round(number:number){
    return Math.round(number)
  }

  getBackgroundColour(progressPercent:number){
    return progressPercent < 50 ? 'danger' : progressPercent < 100 ? 'warning' : 'success';
  }

  onSelect(event : Event){
    event.stopPropagation();
    this.selected.emit();
  }

  ngOnInit(): void {
  }

}
