import { HubMicro } from "../hubs/hub-micro.model";
import { SavedPendingChanges } from "./brand.model";

export class BrandResponse {
        id: number; // 1,
        name: string; // "Training Company 1",
        slug: string; // "tco1",
        colours: string; // "[\"#FF00FF\",and 21 more]",
        font: string; // "sans-serif",
        slogan: string; // "Catch the train to successville",
        pending: SavedPendingChanges; // "Catch the train to successville",
        logo_square: string; // "https://www.argonautonline.com/wp-content/uploads/2021/02/audi-logo-square.jpg",
        logo_banner: string; // "https://www.argonautonline.com/wp-content/uploads/2021/02/audi-logo-square.jpg",
        logo_square_inverse: string; // "https://www.argonautonline.com/wp-content/uploads/2016/02/tcs-tata-consultancy-services-logo-svg.png",
        logo_banner_inverse: string; // "https://www.argonautonline.com/wp-content/uploads/2016/02/tcs-tata-consultancy-services-logo-svg.png",
        created_at: string; // "2021-06-14 07:18:55",
        updated_at: string; // "2021-06-14 07:18:55",
        deleted_at: string; // null,
        hubMicro?: HubMicro; // null
}