import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileUserHomeComponent } from './profile-user-home/profile-user-home.component';

const routes: Routes = [
  {
    path: '',
    component: ProfileUserHomeComponent,
    children : [
      // { path: 'password', component: UserAccountDetailsUpdateComponent},
      // { path: 'email', component: UserAccountDetailsUpdateComponent},
      { path: '', redirectTo: 'password', pathMatch: 'full'},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileUserRoutingModule { }
