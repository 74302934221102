import { Component, Input, OnInit } from '@angular/core';
import { LearnerCertification } from '../../../learner-certification.model';

@Component({
  selector: 'certification-display-list-item-slimmable',
  templateUrl: './certification-display-list-item-slimmable.component.html',
  styleUrls: ['./certification-display-list-item-slimmable.component.scss']
})
export class CertificationDisplayListItemSlimmableComponent implements OnInit {

  @Input() slimMode : boolean;
  @Input() certification : LearnerCertification;

  constructor() { }

  ngOnInit(): void {
  }

}
