<div class="row">
  <div class="col-lg-4 mb-4">
    <div class="view overlay z-depth-1">

      <frontend-media-image
            [classes]="'img-fluid'"
            [media]="media"
            [transformations]="thumbnailImageTransformation"
          >
          </frontend-media-image>
      <a (click)="doLinkClick()">
      </a>
    </div>
  </div>
  <div class="col-lg-7 mb-4">
    <!--Excerpt-->
    <a (click)="doLinkClick()" class="title-link">
      <h4 class="mb-4">
        <strong *transloco="let t">{{ title }}</strong>
      </h4>
    </a>
    <!-- Array of tags coming here -->
    <!-- <a href="" class="teal-text">
      <h6 class="pb-1">
        <mdb-icon fas icon="podcast"></mdb-icon>
        <strong>The topic will appear here</strong>
      </h6>
    </a> -->
    <p>{{ description }}</p>
    <p *ngIf="users && users.length">
      <span *transloco="let t">{{ t(user_list_prefix_translation) }}</span>
        <span *ngFor="let user of users; let i = index">
          <strong>{{user.name_full_honorific}}</strong>
          <span *ngIf="i<users.length-1">&nbsp;&&nbsp;</span>
        </span>
          {{ date | date : 'longDate' }}
    </p>
    <button class="btn btn-primary" (click)="doLinkClick()" *transloco="let t">{{ t(cta_translation) }}</button>
  </div>
  <!--Grid column-->
</div>