import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ttt-design02',
  templateUrl: './design02.component.html',
  styleUrls: ['./design02.component.scss']
})
export class Design02Component implements OnInit {

  constructor() { }


  ngOnInit(): void {
  }

}
