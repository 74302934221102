import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Link } from '@frontend/core';
import { LinksService } from '@frontend/shared';
import { Subscription } from 'rxjs';
import { Hub, HubService } from '..';
import { AuthService, User } from '../../auth';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
// import { PageTitleService } from '../../navigation/title/title.service';

type ShowHideObject = {
  [key: string]: boolean;
};

@Component({
  selector: 'multisite-hub-roles',
  templateUrl: './hub-roles.component.html',
  styleUrls: ['./hub-roles.component.scss'],
})
export class HubRolesComponent implements OnInit, OnDestroy {

  userSubscription: Subscription;
  linksSubscription: Subscription;
  editRoleSubscription: Subscription;
  hub_trainersSubscription: Subscription;
  event_and_programme_trainersSubscription: Subscription;
  hubSubscription: Subscription;
  loading: boolean;
  loading_event_and_programme_trainers: boolean;
  loading_hub_trainers: boolean;
  loadingRoleUpdate : boolean;
  hubSlug: string;
  hub: Hub;
  user: User;
  error : string = null;
  showNewRoleForm : ShowHideObject = {};
  unlockUserRole : ShowHideObject = {};
  editManagerMode : boolean; // allows managers to be removed
  links: Link[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hubService: HubService,
    // private titleService: PageTitleService,
    private breadcrumbService : BreadcrumbService,
    private linksService: LinksService,
    private authService: AuthService,
  ) {}

  
  getTrainers(role) {
    this['loading'+role] = true;

    this[role+'Subscription'] = this.hubService
      .getTrainers(this.hub,role)
      .subscribe(
        (response) => {
          this.hub = response;
          this['loading'+role] = false;
          this.getLinks();
        },
        (error) => {
          this['loading'+role] = false;
        }
      );
  }
  addRoleHolder(role:string,email:string){
    this.error = null;
    this.loadingRoleUpdate = true;
    this.editRoleSubscription = this.hubService.addRole(this.hub.slug,role,email).subscribe(response => {
      this.hub = response;
      this.loadingRoleUpdate = false;
      if (role === 'hub_trainers'){
        this.getTrainers('hub_trainers');
      } else if (role === 'event_and_programme_trainers'){
        this.getTrainers('event_and_programme_trainers');
      } else if (role === 'client_trainers'){
        this.getTrainers('client_trainers');
      }
      this.toggleNewRoleHolderForm(role);
    },
      error=> {
        this.loadingRoleUpdate = false;
        this.error = error;
      }
    )
  }
  removeRoleHolder(role:string,email:string){
    if (!this.unlockUserRole[role]) {return;};
    this.error = null;
    this.loadingRoleUpdate = true;
    this.editRoleSubscription = this.hubService.removeRole(this.hub.slug,role,email).subscribe(response => {
      this.hub = response;
      this.loadingRoleUpdate = false;
      if (role === 'hub_trainers'){
        this.getTrainers('hub_trainers');
      } else if (role === 'event_and_programme_trainers'){
        this.getTrainers('event_and_programme_trainers');
      } else if (role === 'client_trainers'){
        this.getTrainers('client_trainers');
      }
      this.toggleRoleLock(role);
    },
      error=> {
        this.loadingRoleUpdate = false;
        this.error = error;
      }
    )
  }
  toggleNewRoleHolderForm(role:string){
    this.error = null;
    this.showNewRoleForm[role]= this.showNewRoleForm[role] ? false:true;
    for (let roleKey in this.showNewRoleForm){
      if (role !== roleKey){
        this.showNewRoleForm[roleKey] = false;
      }
    }
  }
  toggleRoleLock(role:string){
    this.error = null;
    this.unlockUserRole[role]= this.unlockUserRole[role] ? false:true;
    for (let roleKey in this.unlockUserRole){
      if (role !== roleKey){
        this.unlockUserRole[roleKey] = false;
      }
    }
  }
  getLinks(){
    let linksRequest = [
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
    ]
    this.linksSubscription = this.linksService.getDynamicLinks(linksRequest).subscribe(links =>{
      this.links = links;
    })
  }
  isHubOwner(){
    return !! this.hub && this.hub.owners?.[0].id === this.user?.id;
  }
  getHub (slug){
    this.hubService.getManagedHub(slug).subscribe(response => {
      this.hub = response;
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'hub',fragmentName:this.hub.name});
      if (this.hub && !this.hub.hub_trainers){
        this.getTrainers('hub_trainers');
      }
      if (this.hub && !this.hub.event_and_programme_trainers){
        this.getTrainers('event_and_programme_trainers');
      }
    })
  }
  isAdmin (){
    return this.authService.checkRole("Admin");
  }
  ngOnInit(): void {
    this.route.url.subscribe(() => {
      let fullUrlParts = this.router.routerState.snapshot.url
        .toLowerCase()
        .split('/')
        .filter((s) => s);
      this.getHub(fullUrlParts[1]);
    });
    this.userSubscription = this.authService.user.subscribe(
      (user) => {
        this.user = user;
      }
    );
  }
  ngOnDestroy () : void {
    if (this.hubSubscription){
      this.hubSubscription.unsubscribe();
    }
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    }
    if (this.editRoleSubscription){
      this.editRoleSubscription.unsubscribe();
    }
    if (this.event_and_programme_trainersSubscription){
      this.event_and_programme_trainersSubscription.unsubscribe();
    }
    if (this.hub_trainersSubscription){
      this.hub_trainersSubscription.unsubscribe();
    }
       if (this.linksSubscription){
      this.linksSubscription.unsubscribe();
    }
  }
}
