import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LockedContentMaskComponent } from './locked-content-mask.component';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    LockedContentMaskComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ],
  exports: [
    LockedContentMaskComponent
  ]
})
export class LockedContentMaskModule { }
