<div class="testimonial">
  <!--Avatar-->
  <div class="avatar mx-auto" *ngIf="media">
    <frontend-media-image
      [classes]="'rounded-circle z-depth-1 img-fluid'"
      [media]="media"
      [transformations]="'w_300/'"
    ></frontend-media-image>
  </div>
  <!--Content-->
  <h4 class="font-weight-bold dark-grey-text mt-4" *ngIf="originator">{{originator}}</h4>
  <h6 class="font-weight-bold grey-text my-3" *ngIf="originator_description">{{originator_description}}</h6>
  <p class="font-weight-normal dark-grey-text">
    <i class="fa-solid fa-quote-left"></i>
    <!-- <mdb-icon fas icon="quote-left" class="pr-2"></mdb-icon> -->
  {{quotationText}}</p>
  <!--Review-->
  <div class="cyan-text" *ngIf="source">
    {{source}}
  </div>
</div>