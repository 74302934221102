import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'multisite-alert-type-two',
  templateUrl: './alert-type-two.component.html',
  styleUrls: ['./alert-type-two.component.scss']
})
export class AlertTypeTwoComponent implements OnChanges {

  @Input() iconClasses : string; // 'fa-regular fa-lightbulb'
  @Input() translationKey : string; // "'COMMON.SOMETHING_IMPORTANT'"
  @Input() text : string; // 'This is just raw text'
  hideIcon : boolean = true; // because Fontawesome icons cannot be changed by changing the classes; must be removed from the DOM first

  constructor() { }

  ngOnChanges(changesObject): void {
    if(changesObject.iconClasses?.currentValue){
      this.hideIcon = true;
      setTimeout(()=>{
        this.hideIcon = false;
      }) // NOTE no timeout delay is set, but it still works!
    }
  }

}
