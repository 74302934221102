import { Tag } from "@frontend/core";
import { Icon } from "@frontend/shared";
import { Contributor } from "../auth/user/contributor.model";
import { MetaText, Topic } from "../content";
import { Culture } from "../cultures";
import { CloudinaryMediaAsset } from "../models";

export class Survey {
    id : number;
    name : string;
    slug : string;
    subheading : string;
    description : string;
    description_short : string;
    instructions : MetaText[];
    rating: number;
    duration: number; // TODO - covert to a Temporal object or similar
    category: string;
    type: string; // a type of "profiling" will by default show the snapshot context element
    allow_guests: boolean;
    correction: string;
    meta: {
        available_languages? : {iso:string,grammatical_forms:string[]}[], // ["en": {iso : 'en', grammatical_forms : {'first_person_form_1','third_person_form_1'}},
        allow_question_skipping? : boolean
        allow_first_person_questions?: boolean,
        multi_raters_required_by_default?: boolean,
        allow_locales_not_included_in_available_languages?: boolean, // in other words, the UI language can be different from the fallback content language
    };
    media: CloudinaryMediaAsset;
    tags: Tag[];
    topics: Topic[];
    topic_slugs: string[];
    cultures: Culture[];
    culture_slugs: string[];
    icon: Icon;
    expired_at : string;
    updated_at : string;
    contributors: Contributor[];
    additional_media?: CloudinaryMediaAsset[];
    completions?: number;
}