<!-- 

  ==== README ====
  
    lightColourOn and darkColourOn will force the dark/light version, no matter how darkMode/lightMode in the website is affecting the "logo-primary" and "logo-blank" classes.

    You must put [attr.fill]="light/darkColourOn INSIDE the elements which have "logo-primary" and "logo-blank". So the [attr.fill] elements should always be child elements of logo-primary / logo-blank classes. A normal ternary function causes problems: [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : 'var(--primary-dark-static)'

    So use this structure:
        <g class="logo-primary">
          <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
            <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''"> 
              <path> paths etc here
            </g>
          </g>
        </g>
  
  -->
<span *ngIf="logo==='culturettt'">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="48px" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="300 360 400 80" style="enable-background:new 0 0 1000 800;" xml:space="preserve">
   <style type="text/css" *ngIf="!lightColourOn">
     .logo-primary{fill:var(--primary-dark);}
     .logo-blank{fill:var(--gray-100)}
   </style>
   <style type="text/css" *ngIf="lightColourOn">
    .logo-primary{fill:var(--primary-light-static);}
    .logo-blank{fill:var(--primary-dark-static)}
  </style>
   <g>
     <g class="logo-text">
       <g>
        <g class="logo-primary">
          <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
            <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">
           <path d="M442.56,394.73c-0.4,5.49-2.43,9.82-6.08,12.98c-3.66,3.16-8.48,4.73-14.47,4.73
             c-6.55,0-11.7-2.2-15.45-6.61c-3.75-4.41-5.63-10.46-5.63-18.15v-3.12c0-4.91,0.86-9.23,2.6-12.98c1.73-3.74,4.2-6.61,7.42-8.61
             c3.21-2,6.95-3,11.21-3c5.89,0,10.64,1.58,14.24,4.73c3.6,3.16,5.68,7.59,6.24,13.29h-10.52c-0.26-3.3-1.17-5.69-2.75-7.17
             c-1.58-1.48-3.98-2.23-7.21-2.23c-3.51,0-6.13,1.26-7.87,3.77c-1.74,2.51-2.64,6.41-2.68,11.7v3.86c0,5.52,0.84,9.55,2.51,12.1
             c1.67,2.55,4.31,3.82,7.91,3.82c3.25,0,5.68-0.74,7.28-2.23c1.6-1.48,2.52-3.78,2.75-6.89H442.56z"/>
           <path d="M488.11,360.68v33.63c0,5.59-1.75,10.01-5.24,13.26c-3.5,3.25-8.27,4.88-14.33,4.88
             c-5.96,0-10.7-1.58-14.2-4.73c-3.51-3.16-5.3-7.49-5.37-13.01v-34.02h10.52v33.7c0,3.34,0.8,5.78,2.4,7.31
             c1.6,1.53,3.82,2.3,6.65,2.3c5.91,0,8.92-3.11,9.01-9.33v-33.98H488.11z"/>
           <path d="M507.26,403.29h22.34v8.45h-32.86v-51.06h10.52V403.29z"/>
           <path d="M566.88,369.2h-15.64v42.54h-10.52V369.2h-15.43v-8.52h41.59V369.2z"/>
           <path d="M611.53,360.68v33.63c0,5.59-1.75,10.01-5.24,13.26c-3.5,3.25-8.27,4.88-14.33,4.88
             c-5.96,0-10.7-1.58-14.2-4.73c-3.51-3.16-5.3-7.49-5.37-13.01v-34.02h10.52v33.7c0,3.34,0.8,5.78,2.4,7.31
             c1.6,1.53,3.82,2.3,6.65,2.3c5.92,0,8.92-3.11,9.01-9.33v-33.98H611.53z"/>
           <path d="M639.06,393.05h-8.38v18.69h-10.52v-51.06h18.97c6.03,0,10.68,1.35,13.96,4.03
             c3.27,2.69,4.91,6.49,4.91,11.4c0,3.48-0.75,6.39-2.26,8.71c-1.51,2.33-3.79,4.18-6.86,5.56l11.05,20.87v0.49h-11.29
             L639.06,393.05z M630.67,384.53h8.49c2.64,0,4.69-0.67,6.14-2.02s2.18-3.2,2.18-5.56c0-2.41-0.68-4.3-2.05-5.68
             c-1.37-1.38-3.47-2.07-6.3-2.07h-8.45V384.53z"/>
           <path d="M696.71,389.61h-20.2v13.68h23.71v8.45h-34.23v-51.06h34.16v8.52h-23.64v12.17h20.2V389.61z"/>
         </g>
         </g>
         </g>
       </g>
       <g>
        <g class="logo-primary">
          <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
            <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">
           <path d="M415.45,425.27h-5.48v14.9h-3.68v-14.9h-5.4v-2.99h14.57V425.27z"/>
           <path d="M428.19,433.62h-2.94v6.55h-3.68v-17.88h6.65c2.11,0,3.74,0.47,4.89,1.41c1.15,0.94,1.72,2.27,1.72,3.99
             c0,1.22-0.26,2.24-0.79,3.05c-0.53,0.81-1.33,1.46-2.4,1.95l3.87,7.31v0.17h-3.96L428.19,433.62z M425.26,430.63h2.97
             c0.93,0,1.64-0.23,2.15-0.71c0.51-0.47,0.76-1.12,0.76-1.95c0-0.84-0.24-1.51-0.72-1.99c-0.48-0.48-1.21-0.73-2.2-0.73h-2.96
             V430.63z"/>
           <path d="M451.75,436.48h-6.46l-1.23,3.68h-3.92l6.66-17.88h3.41l6.69,17.88h-3.92L451.75,436.48z M446.28,433.49
             h4.47l-2.25-6.69L446.28,433.49z"/>
           <path d="M466.52,440.16h-3.68v-17.88h3.68V440.16z"/>
           <path d="M488.5,440.16h-3.68l-7.17-11.77v11.77h-3.68v-17.88h3.68l7.19,11.79v-11.79h3.67V440.16z"/>
           <path d="M518.76,425.27h-5.48v14.9h-3.68v-14.9h-5.4v-2.99h14.57V425.27z"/>
           <path d="M539.44,440.16h-3.68v-7.67h-7.18v7.67h-3.68v-17.88h3.68v7.25h7.18v-7.25h3.68V440.16z"/>
           <path d="M557.44,432.41h-7.07v4.79h8.3v2.96h-11.99v-17.88h11.96v2.99h-8.28v4.26h7.07V432.41z"/>
           <path d="M587.87,425.27h-5.48v14.9h-3.69v-14.9h-5.4v-2.99h14.57V425.27z"/>
           <path d="M600.62,433.62h-2.94v6.55h-3.69v-17.88h6.65c2.11,0,3.74,0.47,4.89,1.41c1.15,0.94,1.72,2.27,1.72,3.99
             c0,1.22-0.26,2.24-0.79,3.05c-0.53,0.81-1.33,1.46-2.4,1.95l3.87,7.31v0.17h-3.95L600.62,433.62z M597.68,430.63h2.97
             c0.92,0,1.64-0.23,2.15-0.71c0.51-0.47,0.76-1.12,0.76-1.95c0-0.84-0.24-1.51-0.72-1.99c-0.48-0.48-1.21-0.73-2.2-0.73h-2.96
             V430.63z"/>
           <path d="M624.17,436.48h-6.46l-1.23,3.68h-3.92l6.66-17.88h3.41l6.69,17.88h-3.92L624.17,436.48z M618.7,433.49
             h4.47l-2.25-6.69L618.7,433.49z"/>
           <path d="M638.94,440.16h-3.68v-17.88h3.68V440.16z"/>
           <path d="M660.93,440.16h-3.69l-7.17-11.77v11.77h-3.69v-17.88h3.69l7.19,11.79v-11.79h3.67V440.16z"/>
           <path d="M678.93,432.41h-7.07v4.79h8.3v2.96h-11.99v-17.88h11.96v2.99h-8.28v4.26h7.07V432.41z"/>
           <path d="M692.96,433.62h-2.94v6.55h-3.69v-17.88h6.65c2.11,0,3.74,0.47,4.89,1.41c1.15,0.94,1.72,2.27,1.72,3.99
             c0,1.22-0.26,2.24-0.79,3.05c-0.53,0.81-1.33,1.46-2.4,1.95l3.87,7.31v0.17h-3.95L692.96,433.62z M690.03,430.63H693
             c0.92,0,1.64-0.23,2.15-0.71c0.51-0.47,0.76-1.12,0.76-1.95c0-0.84-0.24-1.51-0.72-1.99c-0.48-0.48-1.21-0.73-2.2-0.73h-2.96
             V430.63z"/>
         </g>
       </g>
       </g>
       </g>
     </g>
     <g>
      <g class="logo-primary">
        <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
          <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">
            <circle cx="339.89" cy="400" r="40.16"/>
          </g>
        </g>
      </g>

       <g class="logo-blank">
        <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
          <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">
         <polygon class="logo-blank" points="341.54,384.32 341.54,420.87 348.13,420.87 348.13,390.91 369.9,390.91 369.9,384.32 			"/>
         <polygon class="logo-blank" points="309.88,390.91 330.91,390.91 330.91,420.87 337.5,420.87 337.5,384.32 309.88,384.32 			"/>
         <polygon class="logo-blank" points="309.88,401.55 315.01,401.55 315.01,420.87 321.6,420.87 321.6,401.55 326.88,401.55 326.88,394.96 
           309.88,394.96 			"/>
         <polygon class="logo-blank" points="352.17,401.55 357.44,401.55 357.44,420.87 364.03,420.87 364.03,401.55 369.16,401.55 
           369.16,394.96 352.17,394.96 			"/>
       </g>
       </g>
       </g>
     </g>
   </g>
   </svg>
</span>
<span *ngIf="logo==='interculturalist'">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="48px" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 443.05 103.28" style="enable-background:new 0 0 1000 800;" xml:space="preserve">
      <style type="text/css" *ngIf="!lightColourOn">
        .logo-primary{fill:var(--primary-dark);}
        .logo-blank{fill:var(--gray-100)}
      </style>
      <style type="text/css" *ngIf="lightColourOn">
        .logo-primary{fill:var(--primary-light-static);}
        .logo-blank{fill:var(--primary-dark-static)}
      </style>
   <g>
    <g class="logo-text">
      <g class="logo-primary">
        <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
          <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">
            <path class="cls-1" d="m119.29,35.84c-.56-.56-.84-1.23-.84-2.01s.28-1.46.84-2.01c.56-.56,1.23-.84,2.02-.84.78,0,1.46.28,2.01.84s.84,1.23.84,2.01-.28,1.46-.84,2.01c-.56.56-1.23.84-2.01.84-.79,0-1.46-.28-2.01-.84Zm-.53,36.29v-30.99s5.21,0,5.21,0v30.99s-5.21,0-5.21,0Z"/>
            <path class="cls-1" d="m147.16,72.13v-18.6c0-1.86-.68-3.47-2.04-4.83-1.32-1.32-2.94-1.98-4.84-1.98-1.86,0-3.47.68-4.83,2.04-.62.62-1.12,1.35-1.49,2.2-.37.85-.56,1.7-.56,2.57v18.6s-5.39,0-5.39,0v-30.25s5.39,0,5.39,0v2.54c.78-.95,1.82-1.74,3.1-2.36,1.28-.62,2.54-.93,3.78-.93,1.65,0,3.23.32,4.74.96,1.51.64,2.84,1.54,4,2.7,1.12,1.12,1.99,2.43,2.63,3.94.64,1.51.96,3.11.96,4.8v18.6s-5.46,0-5.46,0Z"/>
            <path class="cls-1" d="m164.04,46.72v25.41h-5.27v-25.41s-4.65,0-4.65,0v-5.58h4.65s0-10.23,0-10.23h5.27v10.23s4.65,0,4.65,0v5.58h-4.65Z"/>
            <path class="cls-1" d="m175.4,58.94c0,.99.36,2.02,1.08,3.1.72,1.08,1.56,1.98,2.51,2.73,1.74,1.36,3.76,2.05,6.07,2.05,3.68,0,6.67-1.78,8.99-5.33l4.71,2.79c-1.61,2.6-3.6,4.61-5.95,6.01-2.36,1.41-4.94,2.11-7.75,2.11-2.11,0-4.11-.4-6.01-1.21-1.9-.81-3.57-1.93-5.02-3.38-1.45-1.45-2.57-3.12-3.38-5.02-.81-1.9-1.21-3.91-1.21-6.01s.4-4.12,1.21-6.04c.81-1.92,1.93-3.6,3.38-5.05,1.4-1.45,3.07-2.56,4.99-3.35,1.92-.78,3.94-1.18,6.04-1.18s4.12.39,6.04,1.18c1.92.79,3.58,1.9,4.99,3.35,3.06,3.14,4.59,6.78,4.59,10.91,0,.74-.06,1.53-.19,2.36h-25.1Zm4.9-11.59c-1.49.74-2.68,1.73-3.56,2.94-.89,1.22-1.33,2.51-1.33,3.87h19.34c0-1.36-.44-2.65-1.33-3.87-.89-1.22-2.08-2.2-3.56-2.94-1.49-.74-3.08-1.12-4.77-1.12s-3.29.37-4.77,1.12Z"/>
            <path class="cls-1" d="m216.77,47.05c-.99-.45-1.88-.68-2.67-.68-1.65,0-3.02.58-4.09,1.74-1.16,1.24-1.74,2.67-1.74,4.28v19.71s-5.33,0-5.33,0v-19.71c0-2.23.57-4.28,1.71-6.14,1.14-1.86,2.7-3.31,4.68-4.34,1.49-.7,3.08-1.05,4.77-1.05,1.36,0,2.67.25,3.9.74,1.24.5,2.54,1.3,3.91,2.42l-5.15,3.04Z"/>
            <path class="cls-1" d="m228.43,71.19c-1.88-.81-3.54-1.93-4.99-3.38-1.41-1.4-2.51-3.07-3.32-4.99-.81-1.92-1.21-3.94-1.21-6.04s.4-4.12,1.21-6.04c.81-1.92,1.91-3.58,3.32-4.99,1.45-1.45,3.11-2.57,4.99-3.38,1.88-.81,3.87-1.21,5.98-1.21,4.88,0,9.01,2.07,12.4,6.2l-4.83,2.79c-2.15-2.27-4.67-3.41-7.56-3.41-1.36,0-2.66.26-3.87.77-1.22.52-2.28,1.23-3.19,2.14-.91.91-1.63,1.98-2.17,3.22-.54,1.24-.81,2.54-.81,3.9s.27,2.67.81,3.91c.54,1.24,1.26,2.31,2.17,3.22.91.91,1.97,1.62,3.19,2.14,1.22.52,2.51.77,3.87.77,2.89,0,5.41-1.14,7.56-3.41l4.83,2.79c-3.39,4.13-7.52,6.2-12.4,6.2-2.11,0-4.1-.4-5.98-1.21Z"/>
            <path class="cls-1" d="m269.87,71.9v-2.6c-.83.87-1.87,1.59-3.13,2.17-1.26.58-2.51.87-3.75.87-1.65,0-3.23-.32-4.74-.96-1.51-.64-2.84-1.54-4-2.7-1.12-1.16-1.99-2.49-2.63-4-.64-1.51-.96-3.11-.96-4.8v-18.78s5.46,0,5.46,0v18.78c0,1.9.68,3.53,2.04,4.9s2.98,2.05,4.83,2.05c.87,0,1.74-.19,2.6-.56.87-.37,1.61-.87,2.23-1.49,1.36-1.36,2.05-3,2.05-4.9v-18.78s5.39,0,5.39,0v30.81s-5.39,0-5.39,0Z"/>
            <path class="cls-1" d="m279.99,72.09V30.87s4.96,0,4.96,0v41.22s-4.96,0-4.96,0Z"/>
            <path class="cls-1" d="m298.71,46.68v25.41h-5.27v-25.41s-4.65,0-4.65,0v-5.58h4.65s0-10.23,0-10.23h5.27v10.23s4.65,0,4.65,0v5.58h-4.65Z"/>
            <path class="cls-1" d="m326.43,71.91v-2.6c-.83.87-1.87,1.59-3.13,2.17-1.26.58-2.51.87-3.75.87-1.65,0-3.23-.32-4.74-.96-1.51-.64-2.84-1.54-4-2.7-1.12-1.16-1.99-2.49-2.63-4-.64-1.51-.96-3.11-.96-4.8v-18.78s5.46,0,5.46,0v18.78c0,1.9.68,3.53,2.04,4.9,1.36,1.36,2.97,2.05,4.83,2.05.87,0,1.74-.19,2.6-.56.87-.37,1.61-.87,2.23-1.49,1.36-1.36,2.05-3,2.05-4.9v-18.78s5.39,0,5.39,0v30.81s-5.39,0-5.39,0Z"/>
            <path class="cls-1" d="m349.24,47.05c-.99-.45-1.88-.68-2.67-.68-1.65,0-3.02.58-4.09,1.74-1.16,1.24-1.74,2.67-1.74,4.28v19.71h-5.33v-19.71c0-2.23.57-4.28,1.71-6.14,1.14-1.86,2.7-3.31,4.68-4.34,1.49-.7,3.08-1.05,4.77-1.05,1.36,0,2.67.25,3.9.74,1.24.5,2.54,1.3,3.91,2.42l-5.15,3.04Z"/>
            <path class="cls-1" d="m377.15,72.1v-4.71c-1.32,1.53-2.85,2.75-4.59,3.66s-3.58,1.36-5.52,1.36c-2.15,0-4.16-.39-6.04-1.18-1.88-.78-3.54-1.9-4.99-3.35-1.45-1.4-2.57-3.07-3.38-4.99-.81-1.92-1.21-3.94-1.21-6.04s.4-4.12,1.21-6.04c.81-1.92,1.93-3.6,3.38-5.05s3.11-2.56,4.99-3.35c1.88-.78,3.89-1.18,6.04-1.18,1.98,0,3.85.41,5.61,1.24,1.76.83,3.25,1.98,4.49,3.47v-4.09h5.58s0,30.25,0,30.25h-5.58Zm-14.04-24.58c-1.26.52-2.35,1.23-3.25,2.14-.91.91-1.62,1.99-2.14,3.25-.52,1.26-.77,2.57-.77,3.94s.26,2.67.77,3.91,1.23,2.31,2.14,3.22c.91.91,1.99,1.62,3.25,2.14,1.26.52,2.57.77,3.94.77s2.67-.26,3.91-.77c1.24-.52,2.31-1.23,3.22-2.14s1.62-1.98,2.14-3.22c.52-1.24.78-2.54.78-3.91s-.26-2.67-.77-3.94c-.52-1.26-1.23-2.34-2.14-3.25-.91-.91-1.98-1.62-3.22-2.14s-2.54-.78-3.91-.78-2.68.26-3.94.77Z"/>
            <path class="cls-1" d="m386.55,72.1V30.88s4.96,0,4.96,0v41.22s-4.96,0-4.96,0Z"/>
            <path class="cls-1" d="m396.2,35.8c-.56-.56-.84-1.23-.84-2.02s.28-1.46.84-2.01c.56-.56,1.23-.84,2.01-.84s1.46.28,2.01.84c.56.56.84,1.23.84,2.01s-.28,1.46-.84,2.01c-.56.56-1.23.84-2.01.84s-1.46-.28-2.01-.84Zm-.53,36.29v-30.99h5.21v30.99h-5.21Z"/>
            <path class="cls-1" d="m420.25,48.23c-.79-1.36-2.33-2.05-4.65-2.05-1.24,0-2.19.15-2.85.43-.87.33-1.52.81-1.95,1.43-.43.62-.65,1.3-.65,2.05,0,.91.33,1.74.99,2.48.7.79,1.84,1.2,3.41,1.24,2.77.12,5.01.46,6.73.99,1.71.54,3.04,1.47,3.97,2.79s1.39,3.18,1.39,5.58-.93,4.55-2.79,6.32c-.91.87-1.97,1.57-3.19,2.11-1.22.54-2.49.81-3.81.81h-.93c-3.31,0-5.97-.95-8-2.85-1.65-1.53-2.69-3.68-3.1-6.45h5.52c.21.99.62,1.8,1.24,2.42.95.91,2.27,1.36,3.97,1.36.45,0,.78-.02.99-.06,1.45-.08,2.58-.48,3.41-1.21.83-.72,1.24-1.62,1.24-2.7,0-1.49-.46-2.46-1.36-2.91-.91-.45-2.67-.68-5.27-.68-3.27,0-5.77-1.03-7.5-3.1-1.4-1.57-2.11-3.55-2.11-5.95,0-.54.02-.95.06-1.24.33-2.69,1.44-4.74,3.32-6.17,1.88-1.43,4.37-2.14,7.47-2.14,2.27,0,4.26.5,5.95,1.49,1.2.74,2.23,1.78,3.1,3.1.83,1.32,1.36,2.98,1.61,4.96l-.12.06h-5.27c-.12-.74-.39-1.45-.81-2.11Z"/>
            <path class="cls-1" d="m438.4,46.69v25.41h-5.27v-25.41s-4.65,0-4.65,0v-5.58h4.65s0-10.23,0-10.23h5.27v10.23s4.65,0,4.65,0v5.58h-4.65Z"/>
          </g>
        </g>
      </g>
    </g>
    <g class="logo-primary">
      <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
        <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">
          <path class="cls-1" d="m103.27,51.64c0-7.53-1.65-15.01-4.86-21.88h.02c-.47-1-.97-1.98-1.49-2.93h0c-.44-.81-.9-1.59-1.38-2.36-.03-.04-.05-.08-.07-.12h-.01c-.34-.54-.69-1.08-1.05-1.61l-3.06,13.68c1.82,4.72,2.81,9.86,2.81,15.22,0,17.23-10.25,32.07-24.98,38.76-3.31,1.5-6.85,2.59-10.55,3.2-2.28.38-4.62.58-7.01.58-1.43,0-2.85-.07-4.24-.21-3.25-.32-6.39-1.01-9.38-2.01-3.27-1.11-6.36-2.6-9.22-4.42-3.05-1.95-5.83-4.27-8.29-6.91-7.08-7.6-11.41-17.79-11.41-28.99,0-23.5,19.05-42.54,42.54-42.54,9.73,0,18.7,3.26,25.86,8.77l2.15-9.61C71.59,3.03,61.96,0,51.64,0,23.12,0,0,23.12,0,51.64c0,15.76,7.06,29.87,18.2,39.34,2.62,2.24,5.47,4.21,8.51,5.89,2.94,1.63,6.05,2.97,9.31,4,3.01.96,6.15,1.65,9.37,2.04,2.05.24,4.13.37,6.25.37,1.65,0,3.28-.08,4.89-.23,3.55-.33,7-1.02,10.3-2.04,15.87-4.88,28.48-17.21,33.76-32.89v-.02c.3-.89.58-1.79.83-2.7.01-.02.01-.04.02-.06,1.2-4.37,1.85-8.96,1.85-13.7h-.02Z"/>
        <path class="cls-1" d="m36.54,52.97l-7.74,34.57-2.09,9.33c-3.04-1.68-5.89-3.65-8.51-5.89l2.31-10.35,6.2-27.66h9.83Z"/>
        <path class="cls-1" d="m62.38,41.94l-10.38,46.37-2.01,8.94c-3.22-.39-6.36-1.08-9.37-2.04l2-8.91,9.93-44.36h9.83Z"/>
        <path class="cls-1" d="m79.46,27c2.99,2.84,5.57,6.12,7.64,9.73l-8.59,38.34-3.77,16.11c-3.3,1.02-6.75,1.71-10.3,2.04l3.52-14.95,11.5-51.27Z"/>
        <path class="cls-1" d="m91.27,18.55l-2.68,12c-2.07-3.62-4.65-6.9-7.65-9.75l2.25-10.04c2.97,2.3,5.68,4.91,8.08,7.79Z"/>
        </g>
      </g>
    </g>
  </g>
   </svg>
</span>
<span *ngIf="logo==='culturequestion'">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="48px" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="300 350 400 100" style="enable-background:new 0 0 1000 800;" xml:space="preserve">
      <style type="text/css" *ngIf="!lightColourOn">
        .logo-primary{fill:var(--primary-dark);}
        .logo-blank{fill:var(--gray-100)}
      </style>
      <style type="text/css" *ngIf="lightColourOn">
        .logo-primary{fill:var(--primary-light-static);}
        .logo-blank{fill:var(--primary-dark-static)}
      </style>
   <g>
    <g class="logo-text">
      <g class="logo-primary">
        <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
          <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">
        <path d="M470.88,380.16c-0.33,4.57-2.02,8.16-5.06,10.79c-3.04,2.62-7.05,3.94-12.03,3.94c-5.44,0-9.72-1.83-12.84-5.5
          c-3.12-3.66-4.68-8.69-4.68-15.09v-2.59c0-4.08,0.72-7.68,2.16-10.79c1.44-3.11,3.49-5.5,6.17-7.16
          c2.67-1.66,5.78-2.49,9.32-2.49c4.9,0,8.84,1.31,11.84,3.94c2.99,2.62,4.72,6.31,5.19,11.05h-8.75
          c-0.21-2.74-0.98-4.73-2.29-5.96s-3.31-1.85-5.99-1.85c-2.92,0-5.1,1.05-6.55,3.13c-1.45,2.09-2.19,5.33-2.23,9.72v3.21
          c0,4.59,0.69,7.94,2.08,10.06c1.39,2.12,3.58,3.18,6.57,3.18c2.7,0,4.72-0.62,6.05-1.85c1.33-1.23,2.09-3.14,2.29-5.73H470.88z"
          />
      <  <path d="M508.76,351.85v27.96c0,4.65-1.45,8.32-4.36,11.02c-2.91,2.7-6.88,4.05-11.91,4.05c-4.96,0-8.89-1.31-11.81-3.94
          c-2.92-2.62-4.4-6.23-4.46-10.82v-28.28h8.75v28.02c0,2.78,0.67,4.81,2,6.08c1.33,1.27,3.17,1.91,5.52,1.91
          c4.92,0,7.42-2.59,7.49-7.76v-28.25H508.76z"/>
        <path d="M524.68,387.27h18.57v7.03h-27.32v-42.45h8.75V387.27z"/>
        <path d="M574.24,358.93h-13v35.37h-8.75v-35.37h-12.83v-7.08h34.58V358.93z"/>
        <path d="M611.36,351.85v27.96c0,4.65-1.45,8.32-4.36,11.02c-2.91,2.7-6.88,4.05-11.91,4.05c-4.96,0-8.89-1.31-11.81-3.94
          c-2.92-2.62-4.4-6.23-4.46-10.82v-28.28h8.75v28.02c0,2.78,0.67,4.81,2,6.08c1.33,1.27,3.17,1.91,5.52,1.91
          c4.92,0,7.42-2.59,7.49-7.76v-28.25H611.36z"/>
        <path d="M634.25,378.76h-6.97v15.54h-8.75v-42.45h15.77c5.01,0,8.88,1.12,11.6,3.35c2.72,2.24,4.08,5.39,4.08,9.48
          c0,2.9-0.63,5.31-1.88,7.25c-1.25,1.93-3.15,3.47-5.7,4.62l9.18,17.35v0.41h-9.39L634.25,378.76z M627.28,371.67h7.06
          c2.2,0,3.9-0.56,5.1-1.68c1.2-1.12,1.81-2.66,1.81-4.62c0-2-0.57-3.58-1.71-4.72c-1.14-1.15-2.88-1.72-5.23-1.72h-7.03V371.67z"
          />
        <path d="M682.18,375.9h-16.79v11.37h19.71v7.03h-28.46v-42.45h28.4v7.08h-19.65v10.12h16.79V375.9z"/>
      </g>
      </g>
      </g>
      <g class="logo-primary">
        <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
          <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">
        <path d="M468.85,424.27c0,3.48-0.56,6.51-1.69,9.08c-1.13,2.57-2.69,4.63-4.69,6.19l6.2,4.87l-4.89,4.33l-7.94-6.38
          c-0.91,0.15-1.84,0.23-2.82,0.23c-3.09,0-5.85-0.74-8.28-2.23c-2.43-1.49-4.3-3.61-5.64-6.37c-1.33-2.76-2.01-5.93-2.02-9.52
          v-1.84c0-3.67,0.66-6.9,1.99-9.7c1.32-2.79,3.19-4.94,5.61-6.43c2.42-1.49,5.18-2.24,8.29-2.24c3.11,0,5.87,0.75,8.29,2.24
          c2.42,1.49,4.29,3.64,5.61,6.43c1.32,2.79,1.98,6.02,1.98,9.67V424.27z M461.06,422.58c0-3.91-0.7-6.88-2.1-8.92
          c-1.4-2.03-3.4-3.05-5.99-3.05c-2.58,0-4.57,1-5.97,3.01c-1.4,2.01-2.11,4.95-2.13,8.83v1.82c0,3.81,0.7,6.76,2.1,8.86
          c1.4,2.1,3.42,3.15,6.05,3.15c2.58,0,4.56-1.01,5.94-3.04c1.38-2.02,2.08-4.97,2.1-8.85V422.58z"/>
        <path d="M502.71,404.77v24.57c0,4.08-1.28,7.31-3.83,9.68s-6.04,3.56-10.47,3.56c-4.36,0-7.81-1.15-10.38-3.46
          c-2.56-2.31-3.87-5.47-3.92-9.5v-24.85h7.69v24.62c0,2.44,0.58,4.22,1.76,5.34c1.17,1.12,2.79,1.68,4.85,1.68
          c4.32,0,6.52-2.27,6.58-6.82v-24.82H502.71z"/>
        <path d="M531.46,425.91H516.7v9.99h17.32v6.17h-25v-37.3h24.95V411H516.7v8.89h14.76V425.91z"/>
        <path d="M557.87,432.29c0-1.45-0.51-2.57-1.54-3.34c-1.02-0.78-2.87-1.6-5.53-2.46c-2.66-0.86-4.77-1.71-6.33-2.55
          c-4.24-2.29-6.35-5.37-6.35-9.25c0-2.02,0.57-3.81,1.7-5.39c1.14-1.58,2.77-2.81,4.89-3.7c2.13-0.89,4.51-1.33,7.16-1.33
          c2.66,0,5.04,0.48,7.12,1.45c2.08,0.96,3.7,2.33,4.85,4.09c1.15,1.76,1.73,3.76,1.73,6h-7.69c0-1.71-0.54-3.04-1.61-3.98
          c-1.08-0.95-2.59-1.42-4.53-1.42c-1.88,0-3.34,0.4-4.38,1.19c-1.04,0.79-1.56,1.84-1.56,3.14c0,1.21,0.61,2.23,1.83,3.05
          c1.22,0.82,3.02,1.59,5.39,2.31c4.37,1.32,7.56,2.95,9.56,4.89c2,1.95,3,4.37,3,7.28c0,3.23-1.22,5.76-3.66,7.6
          c-2.44,1.84-5.73,2.75-9.86,2.75c-2.87,0-5.48-0.53-7.84-1.58c-2.36-1.05-4.15-2.49-5.39-4.32c-1.24-1.83-1.86-3.95-1.86-6.35
          h7.71c0,4.12,2.46,6.17,7.38,6.17c1.83,0,3.25-0.37,4.28-1.11C557.36,434.66,557.87,433.62,557.87,432.29z"/>
        <path d="M598.86,411h-11.43v31.08h-7.69V411h-11.27v-6.22h30.38V411z"/>
        <path d="M611.42,442.07h-7.69v-37.3h7.69V442.07z"/>
        <path d="M649.18,424.27c0,3.67-0.65,6.89-1.95,9.66c-1.3,2.77-3.16,4.9-5.57,6.4c-2.42,1.5-5.19,2.25-8.31,2.25
          c-3.09,0-5.85-0.74-8.27-2.23c-2.43-1.49-4.3-3.61-5.64-6.37c-1.33-2.76-2.01-5.93-2.02-9.52v-1.84c0-3.67,0.66-6.9,1.99-9.7
          c1.32-2.79,3.19-4.94,5.61-6.43c2.42-1.49,5.18-2.24,8.29-2.24s5.87,0.75,8.29,2.24c2.42,1.49,4.29,3.64,5.61,6.43
          c1.32,2.79,1.99,6.02,1.99,9.67V424.27z M641.39,422.58c0-3.91-0.7-6.88-2.1-8.92c-1.4-2.03-3.4-3.05-5.99-3.05
          c-2.58,0-4.57,1-5.97,3.01c-1.4,2.01-2.11,4.95-2.13,8.83v1.82c0,3.81,0.7,6.76,2.1,8.86c1.4,2.1,3.42,3.15,6.05,3.15
          c2.58,0,4.56-1.01,5.94-3.04s2.08-4.97,2.1-8.85V422.58z"/>
        <path d="M685.1,442.07h-7.69l-14.96-24.54v24.54h-7.69v-37.3h7.69l14.99,24.59v-24.59h7.66V442.07z"/>
      </g>
      </g>
      </g>
    </g>
    <g class="logo-primary">
      <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
        <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">
      <path d="M366.29,441.97c-14.08,0-26.57-6.97-34.18-17.64c-2.23-3.12-4.04-6.56-5.35-10.23
        c-1.58-4.41-2.44-9.15-2.44-14.09c0-5.91,1.23-11.53,3.44-16.64c1.52-3.51,3.51-6.77,5.89-9.7c7.69-9.53,19.47-15.64,32.64-15.64
        c4.78,0,9.37,0.8,13.65,2.28l7.2-7.28c-6.37-2.85-13.42-4.43-20.85-4.43c-15.76,0-29.88,7.13-39.31,18.33
        c-2.41,2.86-4.51,5.98-6.26,9.32c-3.72,7.11-5.82,15.19-5.82,23.76c0,7.62,1.67,14.86,4.66,21.37c1.59,3.47,3.56,6.73,5.85,9.73
        c9.39,12.32,24.22,20.29,40.88,20.29c8.36,0,16.26-2,23.24-5.56l-7.04-7.12C377.51,440.82,372.03,441.97,366.29,441.97z"/>
      <path d="M347.41,393.21c0.48-1.33,1.09-2.59,1.83-3.77c2.33-3.76,5.86-6.68,10.05-8.24l6.68-6.76l3.7-3.74
        c-1.11-0.13-2.24-0.19-3.38-0.19c-9.76,0-18.44,4.77-23.8,12.11c-2.31,3.15-4,6.77-4.91,10.68c-0.51,2.16-0.78,4.4-0.78,6.71
        c0,1.25,0.08,2.48,0.24,3.69c0.52,4.19,1.93,8.11,4.03,11.57c5.17,8.53,14.54,14.24,25.22,14.24c2.2,0,4.34-0.24,6.39-0.7
        l-3.37-3.41l-5.39-5.46c-7.1-0.84-13.08-5.4-15.92-11.68c-0.87-1.9-1.44-3.95-1.67-6.11c-0.07-0.7-0.11-1.42-0.11-2.14
        c0-2.38,0.42-4.67,1.19-6.79V393.21z"/>
      <path d="M405.61,433.08c1.74-2.07,3.32-4.27,4.73-6.61c4.66-7.72,7.35-16.78,7.35-26.46c0-10.57-3.2-20.41-8.7-28.58
        c-1.5-2.24-3.17-4.35-5-6.33c-2.14-2.31-4.48-4.41-7.01-6.31l-6.71,6.79c2.59,1.81,4.97,3.89,7.09,6.24
        c1.81,1.99,3.44,4.15,4.84,6.47c3.85,6.35,6.07,13.78,6.07,21.72c0,7.02-1.74,13.67-4.82,19.5c-1.27,2.43-2.78,4.7-4.5,6.82
        l-8.85-8.94c1.65-2.25,2.99-4.74,3.95-7.39c1.12-3.12,1.74-6.49,1.74-9.99c0-4.44-0.99-8.67-2.76-12.44
        c-1.16-2.49-2.67-4.8-4.46-6.86c-2.06-2.39-4.51-4.45-7.25-6.07l-2.84,2.88l-4.09,4.13c2.95,1.3,5.53,3.3,7.53,5.78
        c1.77,2.17,3.08,4.73,3.81,7.53c0.42,1.61,0.64,3.3,0.64,5.05c0,0.7-0.04,1.42-0.11,2.11c-0.32,3.08-1.34,5.95-2.9,8.45
        l-4.02-4.07l-4.19-4.24h-13.26l10.75,10.87l4.02,4.06l3.95,4l2.79,2.82l8.85,8.95l6.66,6.75l0.01-0.01l11.57,11.7h13.24
        L405.61,433.08z"/>
    </g>
    </g>
    </g>
  </g>
   </svg>
</span>
<span *ngIf="logo==='icpendulum'">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="48px" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="230 350 540 100" style="enable-background:new 0 0 1000 800;" xml:space="preserve">
      <style type="text/css" *ngIf="!lightColourOn">
        .logo-primary{fill:var(--primary-dark);}
        .logo-blank{fill:var(--gray-100)}
      </style>
      <style type="text/css" *ngIf="lightColourOn">
        .logo-primary{fill:var(--primary-light-static);}
        .logo-blank{fill:var(--primary-dark-static)}
      </style>
    <g class="logo-text">
      <g class="logo-primary">
        <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
          <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">


          <path d="M365.57,384.03v-32.3h6.85v32.3H365.57z"/>
          <path d="M411.93,384.03h-8.71l-14.05-24.44h-0.2c0.06,1.02,0.11,2.04,0.15,3.07c0.04,1.03,0.09,2.06,0.13,3.08
            c0.04,1.02,0.09,2.05,0.13,3.08v15.2h-6.12v-32.3h8.64l14.03,24.19h0.15c-0.03-1-0.07-2-0.11-2.98
            c-0.04-0.99-0.08-1.97-0.12-2.96c-0.04-0.99-0.07-1.97-0.1-2.96v-15.29h6.17V384.03z"/>
          <path d="M435.24,384.03h-6.85v-26.6h-8.77v-5.7h24.39v5.7h-8.77V384.03z"/>
          <path d="M470.3,384.03h-18.6v-32.3h18.6v5.61h-11.76v7.09h10.94v5.61h-10.94v8.33h11.76V384.03z"/>
          <path d="M489.17,351.73c2.93,0,5.35,0.35,7.26,1.06c1.91,0.71,3.33,1.78,4.25,3.2c0.93,1.43,1.39,3.23,1.39,5.41
            c0,1.47-0.28,2.76-0.84,3.87c-0.56,1.1-1.3,2.04-2.21,2.81c-0.91,0.77-1.9,1.39-2.96,1.88l9.5,14.08h-7.6l-7.71-12.4h-3.65v12.4
            h-6.85v-32.3H489.17z M488.68,357.34h-2.08v8.73h2.21c2.27,0,3.89-0.38,4.87-1.14c0.98-0.76,1.47-1.87,1.47-3.35
            c0-1.53-0.53-2.62-1.58-3.27C492.52,357.67,490.89,357.34,488.68,357.34z"/>
          <path d="M526.07,356.97c-1.31,0-2.47,0.25-3.48,0.76c-1.01,0.51-1.86,1.24-2.54,2.2s-1.2,2.11-1.56,3.47
            c-0.35,1.36-0.53,2.88-0.53,4.57c0,2.28,0.28,4.23,0.85,5.84c0.57,1.61,1.45,2.84,2.64,3.69c1.19,0.85,2.73,1.27,4.62,1.27
            c1.31,0,2.62-0.15,3.94-0.44c1.32-0.29,2.75-0.71,4.3-1.26v5.74c-1.43,0.59-2.84,1.01-4.22,1.27c-1.39,0.26-2.94,0.39-4.66,0.39
            c-3.33,0-6.07-0.69-8.21-2.07c-2.14-1.38-3.73-3.31-4.76-5.79c-1.03-2.48-1.55-5.38-1.55-8.69c0-2.45,0.33-4.68,0.99-6.72
            s1.64-3.79,2.92-5.28c1.28-1.49,2.86-2.64,4.75-3.45c1.89-0.81,4.05-1.21,6.5-1.21c1.61,0,3.22,0.2,4.83,0.61
            s3.16,0.96,4.63,1.67l-2.21,5.57c-1.21-0.58-2.42-1.08-3.65-1.5S527.24,356.97,526.07,356.97z"/>
          <path d="M570.19,351.73v20.9c0,2.23-0.49,4.23-1.47,6.02c-0.98,1.79-2.46,3.21-4.44,4.25c-1.98,1.05-4.47,1.57-7.48,1.57
            c-4.27,0-7.53-1.09-9.77-3.27c-2.24-2.18-3.36-5.07-3.36-8.66v-20.81h6.83v19.78c0,2.67,0.55,4.54,1.64,5.61
            c1.09,1.08,2.7,1.61,4.84,1.61c1.5,0,2.72-0.26,3.66-0.77c0.94-0.52,1.62-1.31,2.07-2.39c0.44-1.08,0.66-2.45,0.66-4.11v-19.73
            H570.19z"/>
          <path d="M580.81,384.03v-32.3h6.85v26.65h13.1v5.66H580.81z"/>
          <path d="M621.56,384.03h-6.85v-26.6h-8.77v-5.7h24.39v5.7h-8.77V384.03z"/>
          <path d="M664.31,351.73v20.9c0,2.23-0.49,4.23-1.47,6.02c-0.98,1.79-2.46,3.21-4.44,4.25c-1.98,1.05-4.47,1.57-7.48,1.57
            c-4.27,0-7.53-1.09-9.77-3.27c-2.24-2.18-3.36-5.07-3.36-8.66v-20.81h6.83v19.78c0,2.67,0.55,4.54,1.64,5.61
            c1.09,1.08,2.7,1.61,4.84,1.61c1.5,0,2.72-0.26,3.66-0.77c0.94-0.52,1.62-1.31,2.07-2.39c0.44-1.08,0.66-2.45,0.66-4.11v-19.73
            H664.31z"/>
          <path d="M684.35,351.73c2.93,0,5.35,0.35,7.26,1.06c1.91,0.71,3.33,1.78,4.25,3.2c0.93,1.43,1.39,3.23,1.39,5.41
            c0,1.47-0.28,2.76-0.84,3.87c-0.56,1.1-1.3,2.04-2.21,2.81c-0.91,0.77-1.9,1.39-2.96,1.88l9.5,14.08h-7.6l-7.71-12.4h-3.65v12.4
            h-6.85v-32.3H684.35z M683.86,357.34h-2.08v8.73h2.21c2.27,0,3.89-0.38,4.87-1.14c0.98-0.76,1.47-1.87,1.47-3.35
            c0-1.53-0.53-2.62-1.58-3.27C687.7,357.67,686.07,357.34,683.86,357.34z"/>
          <path d="M727.3,384.03l-2.34-7.69h-11.78l-2.34,7.69h-7.38l11.4-32.44h8.37l11.45,32.44H727.3z M723.32,370.6l-2.34-7.51
            c-0.15-0.5-0.34-1.14-0.58-1.93c-0.24-0.79-0.49-1.59-0.73-2.41c-0.24-0.82-0.44-1.53-0.58-2.13c-0.15,0.6-0.35,1.35-0.61,2.24
            c-0.26,0.89-0.5,1.74-0.74,2.53c-0.24,0.8-0.41,1.36-0.51,1.7l-2.32,7.51H723.32z"/>
          <path d="M741.46,384.03v-32.3h6.85v26.65h13.1v5.66H741.46z"/>


          </g>
        </g>

      </g>
      <g class="logo-primary">
        <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
          <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">

            <path d="M381.75,397.41c6.53,0,11.3,1.4,14.3,4.21c3,2.81,4.5,6.67,4.5,11.58c0,2.22-0.33,4.34-1,6.36
            c-0.67,2.02-1.76,3.81-3.27,5.39s-3.53,2.81-6.04,3.72c-2.52,0.91-5.62,1.37-9.32,1.37h-4.61v18.01h-10.74v-50.63H381.75z
            M381.19,406.21h-4.88v15.03h3.53c2.01,0,3.75-0.27,5.23-0.8c1.48-0.53,2.62-1.36,3.43-2.49c0.81-1.13,1.21-2.59,1.21-4.36
            c0-2.49-0.69-4.35-2.08-5.56C386.25,406.81,384.1,406.21,381.19,406.21z"/>
          <path d="M439.27,448.04h-29.16v-50.63h29.16v8.8h-18.42v11.12h17.14v8.8h-17.14v13.06h18.42V448.04z"/>
          <path d="M494.75,448.04H481.1l-22.03-38.3h-0.31c0.09,1.59,0.17,3.2,0.24,4.81c0.07,1.62,0.14,3.23,0.21,4.83
            c0.07,1.61,0.14,3.22,0.21,4.83v23.83h-9.59v-50.63h13.54l21.99,37.92h0.24c-0.05-1.57-0.1-3.13-0.17-4.68
            c-0.07-1.55-0.13-3.09-0.19-4.64c-0.06-1.55-0.11-3.09-0.16-4.64v-23.96h9.66V448.04z"/>
          <path d="M549.5,422.24c0,5.7-1.09,10.46-3.27,14.29c-2.18,3.82-5.34,6.7-9.47,8.62c-4.13,1.93-9.11,2.89-14.93,2.89h-14.34
            v-50.63h15.9c5.31,0,9.92,0.94,13.82,2.82c3.9,1.88,6.93,4.66,9.07,8.35C548.43,412.26,549.5,416.82,549.5,422.24z
            M538.35,422.52c0-3.74-0.55-6.82-1.64-9.23s-2.71-4.2-4.85-5.35c-2.14-1.15-4.77-1.73-7.91-1.73h-5.71v32.97h4.61
            c5.24,0,9.14-1.4,11.69-4.19C537.07,432.19,538.35,428.04,538.35,422.52z"/>
          <path d="M601.21,397.41v32.76c0,3.49-0.77,6.63-2.3,9.44s-3.86,5.03-6.96,6.67c-3.11,1.64-7.01,2.46-11.72,2.46
            c-6.7,0-11.8-1.71-15.31-5.13c-3.51-3.42-5.26-7.94-5.26-13.58v-32.62h10.7v31c0,4.18,0.85,7.11,2.56,8.8
            c1.71,1.69,4.24,2.53,7.58,2.53c2.35,0,4.26-0.4,5.73-1.21c1.47-0.81,2.55-2.05,3.24-3.74c0.69-1.68,1.04-3.83,1.04-6.44v-30.93
            H601.21z"/>
          <path d="M613.61,448.04v-50.63h10.74v41.77h20.54v8.87H613.61z"/>
          <path d="M694.89,397.41v32.76c0,3.49-0.77,6.63-2.3,9.44s-3.86,5.03-6.96,6.67c-3.11,1.64-7.01,2.46-11.72,2.46
            c-6.7,0-11.8-1.71-15.31-5.13c-3.51-3.42-5.26-7.94-5.26-13.58v-32.62h10.7v31c0,4.18,0.85,7.11,2.56,8.8
            c1.71,1.69,4.24,2.53,7.58,2.53c2.35,0,4.26-0.4,5.73-1.21c1.47-0.81,2.55-2.05,3.24-3.74c0.69-1.68,1.04-3.83,1.04-6.44v-30.93
            H694.89z"/>
          <path d="M728.72,448.04l-12.19-39.72h-0.31c0.05,0.95,0.12,2.37,0.23,4.28c0.1,1.9,0.2,3.93,0.29,6.08s0.14,4.09,0.14,5.82v23.55
            h-9.59v-50.63h14.61l11.98,38.72h0.21l12.71-38.72h14.62v50.63H751.4v-23.97c0-1.59,0.03-3.43,0.09-5.51
            c0.06-2.08,0.13-4.06,0.23-5.94c0.09-1.88,0.16-3.3,0.21-4.24h-0.31l-13.06,39.65H728.72z"/>
          </g>
        </g>
      </g>
    </g>
    <g class="logo-primary">
      <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
        <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">
          <path d="M300.98,353.15c-0.37-0.11-0.74-0.21-1.11-0.31c-0.39-0.11-0.78-0.21-1.18-0.3c-1.18-0.28-2.39-0.52-3.6-0.72
            c-0.54-0.09-1.09-0.16-1.64-0.23v8.1v7.25v9c2.71,0.68,5.25,1.82,7.53,3.32c6.72,4.44,11.15,12.05,11.15,20.71
            c0,13.69-11.09,24.79-24.78,24.79c-13.69,0-24.79-11.1-24.79-24.79c0-8.65,4.43-16.27,11.15-20.71c2.28-1.5,4.81-2.64,7.53-3.32
            v-9v-7.25v-8.1c-0.55,0.06-1.09,0.14-1.63,0.23c-1.21,0.2-2.42,0.43-3.6,0.72c-0.4,0.09-0.79,0.2-1.19,0.3
            c-0.37,0.1-0.74,0.2-1.11,0.31c-20.29,5.89-35.12,24.62-35.12,46.82c0,26.93,21.83,48.76,48.76,48.76
            c26.93,0,48.76-21.83,48.76-48.76C336.11,377.77,321.27,359.05,300.98,353.15z M287.35,440.71c-22.5,0-40.74-18.23-40.74-40.73
            c0-17.71,11.3-32.79,27.1-38.39v7.69c-11.75,5.23-19.95,17.01-19.95,30.7c0,18.55,15.04,33.58,33.59,33.58
            c18.54,0,33.58-15.03,33.58-33.58c0-13.69-8.2-25.47-19.95-30.7v-7.7c15.79,5.61,27.1,20.68,27.1,38.4
            C328.08,422.47,309.84,440.71,287.35,440.71z"/>
          <circle cx="287.35" cy="400.17" r="16.46"/>
        </g>
      </g>
    </g>
   </svg>
</span>
<span *ngIf="logo==='ciprofile'">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="48px" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 511.24 103.28" style="enable-background:new 0 0 1000 800;" xml:space="preserve">
    <style type="text/css" *ngIf="!lightColourOn">
      .logo-primary{fill:var(--primary-dark);}
      .logo-blank{fill:var(--gray-100)}
    </style>
    <style type="text/css" *ngIf="lightColourOn">
      .logo-primary{fill:var(--primary-light-static);}
      .logo-blank{fill:var(--primary-dark-static)}
    </style>
    <g class="logo-text">
      <g class="logo-primary">
        <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
          <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">
            
            <path class="cls-2" d="m162.72,57.03c-.71,6.09-2.96,10.78-6.74,14.09-3.78,3.31-8.81,4.96-15.08,4.96-6.8,0-12.25-2.44-16.34-7.31-4.1-4.87-6.15-11.39-6.15-19.56v-5.53c0-5.35.96-10.05,2.87-14.11,1.91-4.06,4.62-7.17,8.12-9.35,3.5-2.17,7.56-3.26,12.17-3.26,6.11,0,11.01,1.71,14.7,5.12,3.69,3.41,5.84,8.14,6.44,14.17h-7.63c-.66-4.6-2.09-7.93-4.29-9.99-2.2-2.06-5.28-3.09-9.23-3.09-4.85,0-8.65,1.79-11.4,5.38-2.75,3.59-4.13,8.69-4.13,15.31v5.58c0,6.25,1.3,11.22,3.91,14.91s6.26,5.54,10.95,5.54c4.21,0,7.45-.96,9.7-2.87,2.25-1.91,3.75-5.24,4.49-9.98h7.63Z"/>
            <path class="cls-2" d="m181.22,75.29h-7.59V17.75h7.59v57.54Z"/>
            <path class="cls-2" d="m211.48,55.77v19.52h-13.87V17.75h22.96c4.4,0,8.29.81,11.68,2.43,3.38,1.62,6.01,3.93,7.87,6.92,1.86,2.99,2.79,6.38,2.79,10.18,0,5.61-2.01,10.1-6.03,13.46-4.02,3.36-9.53,5.04-16.54,5.04h-8.85Zm0-10.71h9.09c2.69,0,4.74-.67,6.15-2.02,1.41-1.34,2.11-3.24,2.11-5.69,0-2.69-.72-4.83-2.17-6.44-1.45-1.61-3.43-2.42-5.93-2.45h-9.25v16.6Z"/>
            <path class="cls-2" d="m271.67,54.94h-7.51v20.35h-13.87V17.75h22.64c6.82,0,12.16,1.51,16.01,4.54,3.85,3.03,5.77,7.3,5.77,12.83,0,4-.81,7.32-2.43,9.95s-4.16,4.76-7.61,6.4l12.01,23.23v.59h-14.86l-10.16-20.35Zm-7.51-10.67h8.77c2.63,0,4.62-.69,5.95-2.08,1.33-1.39,2-3.32,2-5.8s-.67-4.43-2.02-5.84c-1.34-1.41-3.32-2.12-5.93-2.12h-8.77v15.85Z"/>
            <path class="cls-2" d="m351.82,47.71c0,5.61-1.04,10.58-3.12,14.9-2.08,4.32-5.04,7.65-8.87,9.98-3.83,2.33-8.2,3.5-13.1,3.5s-9.25-1.13-13.04-3.38-6.75-5.47-8.87-9.66c-2.12-4.19-3.23-9-3.34-14.42v-3.24c0-5.64,1.03-10.61,3.1-14.92s5.03-7.63,8.89-9.98c3.86-2.34,8.25-3.52,13.18-3.52s9.22,1.16,13.04,3.48c3.82,2.32,6.79,5.62,8.91,9.9,2.12,4.28,3.19,9.19,3.22,14.72v2.65Zm-14.11-2.41c0-5.72-.96-10.06-2.87-13.02-1.91-2.96-4.64-4.45-8.2-4.45-6.96,0-10.63,5.22-11.03,15.65l-.04,4.23c0,5.64.93,9.97,2.81,13,1.87,3.03,4.65,4.54,8.34,4.54s6.2-1.49,8.1-4.47c1.9-2.98,2.86-7.26,2.88-12.84v-2.65Z"/>
            <path class="cls-2" d="m395.21,52.37h-22.37v22.92h-13.87V17.75h38.49v10.71h-24.62v13.24h22.37v10.67Z"/>
            <path class="cls-2" d="m418.02,75.29h-13.83V17.75h13.83v57.54Z"/>
            <path class="cls-2" d="m441.53,64.62h24.15v10.67h-38.02V17.75h13.87v46.87Z"/>
            <path class="cls-2" d="m507.21,50.87h-21.82v13.75h25.77v10.67h-39.64V17.75h39.72v10.71h-25.85v12.13h21.82v10.28Z"/>
          </g>
        </g>    
      </g>    
    </g>    
    <g class="logo-primary">
      <g [attr.fill]="lightColourOn ? 'var(--primary-light-static)' : ''">
        <g [attr.fill]="darkColourOn ? 'var(--primary-dark-static)' : ''">
          <path d="m93.73,51.64c0,21.61-16.29,39.41-37.26,41.81v-12.15c14.3-2.31,25.22-14.69,25.23-29.65h-9.66c-.01,11.26-9.13,20.39-20.4,20.39s-20.38-9.13-20.39-20.39h-9.66c0,14.96,10.93,27.34,25.22,29.65v12.15c-20.96-2.4-37.25-20.2-37.25-41.81,0-23.24,18.84-42.09,42.08-42.09,17.28,0,32.13,10.42,38.6,25.31l8.19-5.11C90.19,12.17,72.34,0,51.64,0,23.12,0,0,23.12,0,51.64s23.12,51.64,51.64,51.64,51.65-23.12,51.65-51.64h-9.56Z"/>
          <circle cx="51.65" cy="51.65" r="8.37"/>
        </g>    
      </g>    
    </g>
  </svg>
</span>