export class BrandColourSwatch {

    constructor (
        public translationKey: string, // 'branding.primarydark'
        public colourKey: string, // 'primary-dark'
        public hex: string,// #0D0D0D
        public textColour: string, // #FFffff
        public shadowLabel: string, // 'branding.shadow'
        public shadowHex: string, // 
        public baseColour: boolean, // is this one of the seven basic colours from which we make the variants? same as libs/common/src/lib/branding/default-brand.constants.ts > baseColourKeys
    ) {
        this.translationKey = translationKey;
        this.colourKey = colourKey;
        this.hex = hex;
        this.textColour = textColour;
        this.shadowLabel = shadowLabel;
        this.shadowHex = shadowHex;
        
    }
}