<h2>
  {{page.title}}
</h2>
<h4 class="text-muted">
  {{page.subtitle}}
</h4>
<p class="my-4">
  <span class="mr-3" *ngIf="showPublishedDate">
    <span *transloco="let t">
      {{t('COMMON.PUBLISHED')}}
    </span>
    {{page.published_at | date : 'longDate'}}
  </span>
  <span class="mr-3" *ngIf="showUpdatedDate">
    <span *transloco="let t">
      {{t('COMMON.UPDATED')}}
    </span>
    {{page.updated_at | date : 'longDate'}}
  </span>
  <span class="mr-3" *ngIf="showVersion">
    <span *transloco="let t">
      {{t('COMMON.VERSION')}}
    </span>
    {{page.version}}
  </span>
</p>
<div [innerHTML]="page.body">
</div>
