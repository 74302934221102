import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'multisite-email-input-form',
  templateUrl: './email-input-form.component.html',
  styleUrls: ['./email-input-form.component.scss']
})
export class EmailInputFormComponent implements OnInit {

  emailForm : FormGroup;
  @Input() labelTextTranslationKey : string;
  @Input() labelText : string;
  @Input() errorTranslationKey : string;
  @Input() loading : boolean;
  @Output() emailSubmitted = new EventEmitter<string>();


  constructor() { }

  submit(){
    this.emailSubmitted.emit(this.emailForm.controls.email.value);
  }

  ngOnInit(): void {
    this.emailForm = new FormGroup({
      email: new FormControl(null, [Validators.required,Validators.email]),
    });
  }

}
