<div class="bell-curve bell-position" style="--bell-height: 64px">
  <svg
    [attr.width]="widthRelativeToParentContainer  + '%'"
    [attr.viewBox]="setViewBox()"
    preserveAspectRatio="none"
  >
    <g [attr.fill]="darkModeActive() ? 'var(--primary-light-static)' : 'var(--primary-dark-static)'">
      <path [attr.d]="setCurvePath()"></path>
    </g>
  </svg>
</div>