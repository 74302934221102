import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Brand } from './brand.model';
import { BrandResponse } from './brand-response.model';
import { ColourService } from './colour.service';
import { DefaultBrandConstants } from './default-brand.constants';
import { BrandColour } from './brand-colour.model';
import { HubMicro } from '../hubs/hub-micro.model';
import { CloudinaryMediaAsset } from "../models";
import { MediaService } from '../content';

interface luminosityAdjustment {
  name: string, // dark
  adjustment: number, // for example 10 or -10,
  relativeAdjustment : boolean // are we changing luminosity by a relative value or an absolute value?
}

type metaTextColumnAlias = {column:string, alias:string};
type hexColour = { name: string, hex: string}; // {name : '--gray-500', hex : '#CCCCCC'}

@Injectable({
  providedIn: 'root',
})
export class BrandDisplayService {
  brands: any[]; // TODO - this seems to be unused. Try removing it.
  // brand: any;
  subdomain: string;
  brandableColourKeys : any;
  luminosityAdjustments : luminosityAdjustment[];
  defaultBrandConstants : any;
  _darkMode : boolean;
  _textSize : number;
  _new_brand_default_name: string;
  private currentHubMicroSubject: BehaviorSubject<HubMicro>;
  public currentHubMicro: Observable<HubMicro>; 
  private currentBrandSubject: BehaviorSubject<Brand>;
  public currentBrand: Observable<Brand>; // currentBrand holds the brand which could be a preview when editing the brand, or could be the normal branding for ordinary users
  hubMetaTextColumnAliasDirectory: {category:string, type:string, aliases : metaTextColumnAlias[]}[];
  staticColoursForRgbVersions : hexColour[];

    constructor(
      @Inject(DOCUMENT) private document: Document,
      private http: HttpClient,
      private colourService : ColourService,
      private mediaService : MediaService,
      @Inject('appDomain') private appDomain: string,
      ) {
        
    this.defaultBrandConstants = DefaultBrandConstants;
    this.colourService = colourService;
    this.brandableColourKeys = {
      0:'--primary',
      1:'--secondary',
      2:'--bs-success',
      3:'--bs-info',
      4:'--bs-warning',
      5:'--bs-danger',
    }
    this.luminosityAdjustments = [
      {name : 'shadow', adjustment : -49, relativeAdjustment: true},
      {name : 'dark', adjustment : -49, relativeAdjustment: true}, // decrease the luminosity by and absolute 15 points on the HSL scale (there are some exceptions if this goes darker than black)
      {name : 'dark-shadow', adjustment : -30, relativeAdjustment: true},
      {name : 'light', adjustment : 3, relativeAdjustment: true},
      {name : 'light-shadow', adjustment : 10, relativeAdjustment: true},
    ];
    this._new_brand_default_name = 'new-edit';
    let storedBrand = this.getNamedBrandFromLocalStorage(this.subdomain,true);
    this.currentBrandSubject = new BehaviorSubject<Brand>(storedBrand);
    this.currentBrand = this.currentBrandSubject.asObservable();
    this.currentHubMicroSubject = new BehaviorSubject<HubMicro>(null);
    this.currentHubMicro = this.currentHubMicroSubject.asObservable();
    this._darkMode = this.darkMode;
    this.hubMetaTextColumnAliasDirectory = [
      {category:'profile',type:'general',aliases:[{column:'s', alias:'strapline'},{column:'l', alias:'description'}],}
    ];
    if (storedBrand){
      this.applyBrand(storedBrand);
    };
    this.staticColoursForRgbVersions = [
      {name: "--gray-100", hex: "#F2F2F2"},
      {name: "--gray-200", hex: "#e9ecef"},
      {name: "--gray-300", hex: "#dee2e6"},
      {name: "--gray-400", hex: "#ced4da"},
      {name: "--gray-500", hex: "#adb5bd"},
      {name: "--gray-600", hex: "#6c757d"},
      {name: "--gray-700", hex: "#495057"},
      {name: "--gray-800", hex: "#343a40"},
      {name: "--gray-900", hex: "#212529"},
      {name: "--black", hex: "#000000"},
      {name: "--white", hex: "#FFFFFF"},
    ];
    this.createRgbVersionsOfStaticColours(this.staticColoursForRgbVersions);
  }
  clearData (){
    // TODO - clear something from localstorage
  }

  get new_brand_default_name (){
    return this._new_brand_default_name;
  }

  get darkMode (){
    this._darkMode = Boolean(JSON.parse(localStorage.getItem('darkMode')));
    return this._darkMode;
  }
  set darkMode (darkMode : boolean) {

    if (darkMode !== this._darkMode){
      this._darkMode = darkMode;
      localStorage.setItem('darkMode',JSON.stringify(darkMode));
      this.applyDarkModeGreyScale(this._darkMode);
      let appliedActiveBrand = this.applyActiveBrand();
      if (!appliedActiveBrand){ // we could not find an active brand - probably there was not subdomain
        let defaultBrand : Brand = this.getNewDraftBrand();
        this.applyBrand(defaultBrand);
      }
    }
  }
  get textSize (){
    this._textSize = +localStorage.getItem('textSize');
    return this._textSize;
  }
  set textSize (size : number) {
    if (size !== this._textSize){
      this._textSize = size;
      localStorage.setItem('textSize',JSON.stringify(size));
      this.document.documentElement.style.setProperty('--font-size-base',size+'rem');
    }
  }
  setSubdomain(subdomain) {
    this.subdomain = subdomain;
  }
  handleInvalidSubdomain (){
    const targetUrl : string = (this.appDomain.includes('localhost') ? 'http://':'https://') + this.appDomain;
    // TODO, consider adding the current route to the targetUrl; For example https://culturettt.com/courses/course-about-cheese not just https://culturettt.com/
    window.open(targetUrl, '_self');
  }

  transformMediaResponse (mediaResponse){
    let mediaArray : CloudinaryMediaAsset[] = [];
    if (mediaResponse?.length){
      mediaResponse.forEach(m => {
        mediaArray.push(Object.assign(m, this.mediaService.setupCloudinaryImageMediaUrls(m)));
      });
    }
    return mediaArray;
  }
  transformBackendHubMetaTexts (hub, metatextsResponse){
    let profileGeneralMetaTextColumnAliases = this.hubMetaTextColumnAliasDirectory.find(a=>a.category==='profile'&& a.type==='general')?.aliases;
    if (profileGeneralMetaTextColumnAliases){
      let metaTextColumns = ['s','l'];
      metaTextColumns.forEach(column => {
        const columnAlias = profileGeneralMetaTextColumnAliases.find(a=>a.column === column)?.alias;
        if (columnAlias){
          hub[columnAlias] = metatextsResponse.find(m=>m.category==='profile' && m.type==='general')?.[column];
        }
      });
    }
    return hub;
  }

  transformBackendHubMicro (hubMicroResponse){
    let hubMicro : HubMicro = {
      id : hubMicroResponse.id,
      name : hubMicroResponse.name,
      slug : hubMicroResponse.slug,
      category : hubMicroResponse.pivot?.category,
      media: this.transformMediaResponse(hubMicroResponse.media),
      settings: hubMicroResponse.settings,
      strapline: null,
      description: null,
    };
    hubMicro = this.transformBackendHubMetaTexts(hubMicro,hubMicroResponse.metaTexts)
    return hubMicro;
  }

  getBranding() {

    if (!this.subdomain){
      let defaultBrand : Brand = this.getNewDraftBrand();
      this.applyBrand(defaultBrand);
      return null; // TODO - review if this line is needed. Probably not.
    }

    let storedBrand = this.getNamedBrandFromLocalStorage(this.subdomain,true);
    if (storedBrand){
      this.applyBrand(storedBrand);
    };
    // continue to get the brand from the server too, in case it has been updated or missing from localStorage

    return this.http
      .get('api/v1/branding/' + this.subdomain)
      .pipe(
        map((responseData:{data: BrandResponse}) => {
          if(this.subdomain !== responseData.data.slug){
            this.handleInvalidSubdomain();
          }
          let hubMicro = new HubMicro();
          if (responseData.data.hubMicro){
            hubMicro = this.transformBackendHubMicro(responseData.data.hubMicro);
            this.currentHubMicroSubject.next(hubMicro);
          }
          let brand = new Brand(
            responseData.data.id,
            responseData.data.name,
            responseData.data.slug,
            this.transformBackendColours(JSON.parse(responseData.data.colours)),
            responseData.data.font ? responseData.data.font : 'sans-serif',
            responseData.data.slogan,
            responseData.data.pending,
            responseData.data.logo_square,
            responseData.data.logo_banner,
            responseData.data.logo_square_inverse,
            responseData.data.logo_banner_inverse,
            responseData.data.created_at,
            responseData.data.updated_at,
            responseData.data.deleted_at,
            new Date(),
            this.currentHubMicroSubject.getValue(),
            null,
            null,
            null,
          );
          return {data:brand}; // TODO - where does this go and how is it used?
        }),
        tap((response: { data: Brand }) => {
          this.putBrandInLocalStorage(response.data);
          this.applyBrand(response.data);
          return response;
        }),
        catchError((errorResponse) => {
          return throwError(errorResponse);
        })
      )
  };
  getNewDraftBrand (){
    return new Brand(null,'My draft brand',this._new_brand_default_name,JSON.parse(JSON.stringify(this.defaultBrandConstants.colours)),'sans-serif','Let\'s connect the local and global',null,null,null,null,null,null,null,null,null,null,null,60,null);
  }
  transformBackendColours (colours : string | string[]){
    let brandColours : BrandColour[] =  JSON.parse(JSON.stringify(this.defaultBrandConstants.colours));

    let colourArray : string[] =  typeof colours === 'string' ? JSON.parse(colours) : colours; // the argument transformBackendColours (colours : string) type seems inconsistent. Is it sometimes an array of string?
    colourArray.forEach((c,i)=>{
      brandColours[i].hex = c;
    })
    return brandColours;
  }
  transformBackendHubBranding (hubResponse){
    if (!hubResponse.primary_colour || !hubResponse.logo_banner){
      let primaryBrandBeforeTransformingForEditing = hubResponse.brands.find(b=>b.pivot.category==='primary');
      if (primaryBrandBeforeTransformingForEditing){
        hubResponse.primary_colour = primaryBrandBeforeTransformingForEditing.colours?.[1];
        hubResponse.logo_banner = primaryBrandBeforeTransformingForEditing.logo_banner;
      }
    };
    return hubResponse;
  }
  generateDarkModeBrandColours (brandColours : BrandColour[]) : BrandColour[] {
    let colourArray : BrandColour[] = [];
    brandColours.forEach((bc,i,array)=>{
      let switchVariant : {lightModeVariant:string,darkModeVariant:string} = this.defaultBrandConstants.darkModeBrandColourSwitches.find(dms=>dms.lightModeVariant === bc.name);
      if (switchVariant){
        colourArray.push(new BrandColour(bc.name,array.find(c=>c.name == switchVariant.darkModeVariant).hex)); 
      } else {
        colourArray.push(bc);
      }
    });
    return colourArray;
  }
  applyDarkModeGreyScale (darkMode:boolean){
    if (darkMode){
      let reverseGreyScaleValues : string[] = this.defaultBrandConstants.greyScale.map(g=>g.hex).reverse();
      this.defaultBrandConstants.greyScale.forEach(((g,i)=>this.document.documentElement.style.setProperty('--'+g.name,reverseGreyScaleValues[i])));
    } else {
      this.defaultBrandConstants.greyScale.forEach((g=>this.document.documentElement.style.setProperty('--'+g.name,g.hex)));
    }
  }
  addStaticColours (brandColours : BrandColour[], staticVersions : string[]) {
    let staticColours : BrandColour[] = [];
    brandColours.filter(c=>staticVersions.indexOf(c.name)>-1)
                .forEach(c => staticColours.push( new BrandColour(c.name+'-static',c.hex)));
    return staticColours;
  }
  addRGBvalues (brandColours : BrandColour[], rgbVersions : string[], suffix : string) {
    let rgbValues : BrandColour[] = []; // BrandColour has the wrong property name ('hex'), but it works!
    brandColours.filter(c=>rgbVersions.indexOf(c.name)>-1)
                .forEach(c => rgbValues.push( new BrandColour(c.name+suffix,this.colourService.convertHEXtoRGBstring(c.hex))));
    return rgbValues;
  }
  applyBrand(brand:Brand) {
    let colourArray : BrandColour[] = [];
    if (this._darkMode) {
      colourArray = this.generateDarkModeBrandColours(brand.colours);
    } else {
      colourArray = brand.colours;
    };
    let brandColoursRequiringStaticVerions = [
      'primary-dark',
      'primary-light',
      'primary',
      'secondary-dark',
      'secondary-light'
    ];
    colourArray = colourArray.concat(this.addStaticColours(brand.colours,brandColoursRequiringStaticVerions));
    // colourArray = colourArray.concat(this.addRGBvalues(colourArray,['primary-dark-static','primary-static'],'-rgb-values'));
    colourArray = colourArray.concat(this.addRGBvalues(colourArray,this.defaultBrandConstants.colours.map(c=>c.name),'-rgb'));
    this.currentBrandSubject.next(brand);
    colourArray.forEach((c=>this.document.documentElement.style.setProperty('--'+c.name,c.hex)));
    this.document.documentElement.style.setProperty('--dynamic-font-family',brand.font);
    localStorage.setItem('activeBrandSlug',brand.slug);
    this.applyDarkModeGreyScale (this._darkMode);
  }
  createRgbVersionsOfStaticColours(hexColours:hexColour[]) {
    if (!hexColours){ return; };
    hexColours.forEach(c=>this.document.documentElement.style.setProperty(c.name+'-rgb',this.colourService.convertHEXtoRGBstring(c.hex)));
  }
  applyActiveBrand (){ // refreshing the branding after a change e.g. dark mode
    let activeBrandSlug = localStorage.getItem('activeBrandSlug');
    if (activeBrandSlug){
      let activeBrand = this.getNamedBrandFromLocalStorage(activeBrandSlug,false);
      if (activeBrand){
        this.applyBrand(activeBrand);
        return true;
      }
    };
  }
  applyStyle(name : string, value: any) {

    this.document.documentElement.style.setProperty(name,value);
  }
  parseDateObject (stringifiedDateObject){
    if (stringifiedDateObject){
      return new Date (stringifiedDateObject);
    }
  }
  getBrandsFromLocalStorage (){
    let storedBrandsString : string = localStorage.getItem('storedBrands');
    if (storedBrandsString){
      let storedBrands = JSON.parse(storedBrandsString);
      for (let b in storedBrands){
        storedBrands[b].loaded_at = this.parseDateObject(storedBrands[b].loaded_at);
      };
      return storedBrands;
    };
    return null;
  }
  getColourVariants (colourKey : string, hex : string){
    let variants : BrandColour[] = [{name: colourKey, hex: hex}]; // include the original
    let requiredVariants = this.defaultBrandConstants.requiredColourVariants.find(rcv=>rcv.key === colourKey).variants;
    requiredVariants.forEach(rv => {
      let la = this.luminosityAdjustments.find(las=>las.name === rv);
      // if (la.name == 'dark' || rv == 'dark'){
      // }
      variants.push({name: colourKey+'-'+rv, hex: this.colourService.adjustColourLuminosity(hex,la.adjustment,la.relativeAdjustment)});
    })
    return variants;
  }
  putBrandInLocalStorage (brand){
    if (!brand){return;};
    let storedBrandsObject = this.getBrandsFromLocalStorage()
    if (!storedBrandsObject){
      storedBrandsObject = {};
    };
    storedBrandsObject[brand.slug]=brand;
    localStorage.setItem('storedBrands',JSON.stringify(storedBrandsObject));
  }
  getNamedBrandFromLocalStorage (slug : string, skipDraftBrand : boolean) : Brand{ // If slug not provided, will return the first brand it finds.
    let storedBrandsObject = this.getBrandsFromLocalStorage ();
    if (!slug && storedBrandsObject){
      let keys = Object.keys(storedBrandsObject);
      if (keys.length) {
          for (let b in storedBrandsObject) {
            if (skipDraftBrand && storedBrandsObject[b].slug?.endsWith('-edit')){
                // do nothing
            } else {
              return storedBrandsObject[b]; 
            }
          }
      }
    }
    if (storedBrandsObject?.[slug]){
      return storedBrandsObject[slug];
    };
    return null;
  }
  convertObjectToArray (brandsObject) : Brand[]{
    let brands : Brand[] = [];
    if (!brandsObject) {return brands};
    for (let b in brandsObject){
      brands.push(brandsObject[b]);
    };
    return brands;
  }
  getAllBrandsFromLocalStorage (skipDraftBrand : boolean) : Brand[]{
    let storedBrandsArray : Brand[] = this.convertObjectToArray( this.getBrandsFromLocalStorage ());
    if (skipDraftBrand){
      storedBrandsArray = storedBrandsArray.filter(b=>b.slug !=='draft');
    };
    return storedBrandsArray;
  }

  
}
