import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommentsPostDisplayPaginationAllInOneComponent } from './comments-post-display-pagination-all-in-one/comments-post-display-pagination-all-in-one.component';
import { CommentFormTypeTwoComponent } from './comment-form-type-two/comment-form-type-two.component';
import { CommentTypeTwoComponent } from './comment-type-two/comment-type-two.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from '../../ui/pagination/pagination.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    CommentFormTypeTwoComponent,
    CommentTypeTwoComponent,
    CommentsPostDisplayPaginationAllInOneComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AlertModule,
    TranslocoModule,
    PaginationModule,
    CollapseModule,

  ],
  exports: [
    CommentFormTypeTwoComponent,
    CommentTypeTwoComponent,
    CommentsPostDisplayPaginationAllInOneComponent
  ]
})
export class CommentsModule { }
