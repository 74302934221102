import { LatLngBounds, LatLngTuple } from "leaflet";
import { FlagExtraLite } from "../flags";

export class Cluster {
    id : number;
    name : string;
    slug : string;
    description : string;
    category : string;
    type : string;
    flag : FlagExtraLite;
    geometry_meta : {
        bounds: LatLngBounds, // [[10, 10], [40, 60]],
        center: LatLngTuple, // [50, 30],
        zoom: number, //3,
    };
}