<div class="row mb-4">
  <div class="col-sm-7">
    <ng-template transloco let-t>
      <span class="{{titleClass}}"> {{ likes && likes.length ? t('SOCIAL.LIKED_BY') : t('FEEDBACK.LET_US_KNOW_HOW_WE_ARE_DOING') }} </span>
    </ng-template>
  </div>
  <div class="col-sm-5 text-center">
    <button
      mdbBtn type="button" color="primary" mdbWavesEffect rounded="true"
      (click)="toggle()"
      [disabled]="loading"
      >
      <i class="fa-solid fa-{{liked ? 'heart-crack':'heart'}}"></i>
      <!-- <mdb-icon fas icon="{{liked ? 'heart-broken':'heart'}}" class="mr-1"></mdb-icon> -->
      <span *transloco="let t">{{ liked ? t('SOCIAL.UNLIKE') : t('SOCIAL.LIKE') }}</span>
    </button>
  </div>
</div>
<ng-container *ngFor="let like of likes; let i=index">
  <div class="chip" style="cursor: default;">
    <img *ngIf="!maxLikesDefaultView || (maxLikesDefaultView && i < maxLikesDefaultView)"
      [src]="like.picture ? like.picture : 'https://res.cloudinary.com/cebt/image/upload/v1616972586/users/avatars/default-avatar.gif'" [alt]="like.fname+' '+like.lname">{{like.fname+' '+like.lname}}
  </div>
</ng-container>
<div *ngIf="maxLikesDefaultView && likes && likes.length > maxLikesDefaultView">
  ... and {{likes.length - maxLikesDefaultView}} more
</div>