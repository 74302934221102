import { Tag } from "@frontend/core";
import { Icon } from "@frontend/shared";

export class Progressable { // a thing where the user can make progress, for example a course
    id: number; // 2,
    type: string; // "App\\Models\\Course",
    slug: string; // "autem-facilis-et-est-impedit-ratione79606",
    name: string; // "Autem facilis et est impedit ratione.",
    tags: Tag[];
    progress: number; // 40
     // Added here in the frontend:
    icon?: Icon;
    statusClass?: string; // 'warning' or 'success' or 'danger'
}