<div class="my-4">

    
  <div class="d-flex flex-column gap-4">
    <!-- BANNER -->

      <multisite-brand-logo-editor
        *ngIf="disabled || (!disabled && (!collapsedContent['banner'].collapsed || allContentIsCollapsed()))"
        [logoDescriptionShortTranslationKey]="'branding.logo_banner_description_short'"
        [logoDescriptionTranslationKey]="'branding.logo_banner_description'"
        [fallbackLogoPreviewLightColourOn]="false"
        [fallbackLogoPreviewHideLogoText]="false"
        [logo]="logo_banner"
        [logoPreviewClass]="'preview-logo'"
        [navbarPreviewClass]="'preview-bg'"
        [pending_logo]="pending?.logo_banner"
        [cloudImagesBaseUrl]="logoExampleImageBaseUrl"
        [logoBlankExampleImageAssetUrl]="logoBlankExampleImages.banner.asset_url"
        (toggleCollapsed)="toggleCollapsedContent('banner')"
        [collapsed]="collapsedContent['banner'].collapsed"
        (removePendingItem)="onRemovePendingItem('banner')"
        (formDataChanged)="onFormDataChanged($event,'logo_banner')"
        [resetFormOnChange]="formResetTriggerOnChange.logo_banner.counter"
        [helpTextIterator]="[1,2,3,4]"
        [helpTextIterationKey]="'branding.logo_banner_guide_'"
        [logoExampleImagesAssetUrlTransparency]="logoExampleImages.banner_transparency.asset_url"
        [logoExampleImagesAssetUrlMargin]="logoExampleImages.banner_margin.asset_url"
        [pendingUpdateRemoveSavedLogo]="pending_updates?.logo_banner?.removeSaved"
        (markSavedLogoForDeletion)="toggleMarkSavedLogoForDeletion('logo_banner')"
        [disabled]="disabled"
        [loading]="loading"
        [error]="error"

  ></multisite-brand-logo-editor>



    <!-- BANNER INVERSE -->

    <multisite-brand-logo-editor
      *ngIf="disabled || (!disabled && (!collapsedContent['banner_inverse'].collapsed || allContentIsCollapsed()))"
      [logoDescriptionShortTranslationKey]="'branding.logo_banner_inverse_description_short'"
      [logoDescriptionTranslationKey]="'branding.logo_banner_inverse_description'"
      [fallbackLogoPreviewLightColourOn]="true"
      [fallbackLogoPreviewHideLogoText]="false"
      [logo]="logo_banner_inverse"
      [logoPreviewClass]="'preview-logo'"
      [navbarPreviewClass]="'preview-bg'"
      [pending_logo]="pending?.logo_banner_inverse"
      [cloudImagesBaseUrl]="logoExampleImageBaseUrl"
      [logoBlankExampleImageAssetUrl]="logoBlankExampleImages.banner_inverse.asset_url"
      (toggleCollapsed)="toggleCollapsedContent('banner_inverse')"
      [collapsed]="collapsedContent['banner_inverse'].collapsed"
      (removePendingItem)="onRemovePendingItem('banner_inverse')"
      (formDataChanged)="onFormDataChanged($event,'logo_banner_inverse')"
      [resetFormOnChange]="formResetTriggerOnChange.logo_banner_inverse.counter"
      [helpTextIterator]="[1,2,3,4]"
      [helpTextIterationKey]="'branding.logo_banner_inverse_guide_'"
      [logoExampleImagesAssetUrlTransparency]="logoExampleImages.banner_inverse_transparency.asset_url"
      [logoExampleImagesAssetUrlMargin]="logoExampleImages.banner_inverse_margin.asset_url"
      [pendingUpdateRemoveSavedLogo]="pending_updates?.logo_banner_inverse?.removeSaved"
      (markSavedLogoForDeletion)="toggleMarkSavedLogoForDeletion('logo_banner_inverse')"
      [disabled]="disabled"
      [loading]="loading"
      [error]="error"
  ></multisite-brand-logo-editor>


    <!-- SQUARE -->

      <multisite-brand-logo-editor
        *ngIf="disabled || (!disabled && (!collapsedContent['square'].collapsed || allContentIsCollapsed()))"
        [logoDescriptionShortTranslationKey]="'branding.logo_square_description_short'"
        [logoDescriptionTranslationKey]="'branding.logo_square_description'"
        [fallbackLogoPreviewLightColourOn]="false"
        [fallbackLogoPreviewHideLogoText]="true"
        [logo]="logo_square"
        [logoPreviewClass]="'preview-logomark'"
        [navbarPreviewClass]="'preview-bg w-lg-100 w-50'"
        [pending_logo]="pending?.logo_square"
        [cloudImagesBaseUrl]="logoExampleImageBaseUrl"
        [logoBlankExampleImageAssetUrl]="logoBlankExampleImages.square.asset_url"
        (toggleCollapsed)="toggleCollapsedContent('square')"
        [collapsed]="collapsedContent['square'].collapsed"
        (removePendingItem)="onRemovePendingItem('square')"
        (formDataChanged)="onFormDataChanged($event,'logo_square')"
        [resetFormOnChange]="formResetTriggerOnChange.logo_square.counter"
        [helpTextIterator]="[1,2,3,4]"
        [helpTextIterationKey]="'branding.logo_square_guide_'"
        [logoExampleImagesAssetUrlTransparency]="logoExampleImages.square_transparency.asset_url"
        [logoExampleImagesAssetUrlMargin]="logoExampleImages.square_margin.asset_url"
        [pendingUpdateRemoveSavedLogo]="pending_updates?.logo_square?.removeSaved"
        (markSavedLogoForDeletion)="toggleMarkSavedLogoForDeletion('logo_square')"
        [disabled]="disabled"
        [loading]="loading"
        [error]="error"
  ></multisite-brand-logo-editor>


    <!-- SQUARE INVERSE -->

      <multisite-brand-logo-editor
        *ngIf="disabled || (!disabled && (!collapsedContent['square_inverse'].collapsed || allContentIsCollapsed()))"
        [logoDescriptionShortTranslationKey]="'branding.logo_square_inverse_description_short'"
        [logoDescriptionTranslationKey]="'branding.logo_square_inverse_description'"
        [fallbackLogoPreviewLightColourOn]="true"
        [fallbackLogoPreviewHideLogoText]="true"
        [logo]="logo_square_inverse"
        [logoPreviewClass]="'preview-logomark'"
        [navbarPreviewClass]="'preview-bg w-lg-100 w-50'"
        [pending_logo]="pending?.logo_square_inverse"
        [cloudImagesBaseUrl]="logoExampleImageBaseUrl"
        [logoBlankExampleImageAssetUrl]="logoBlankExampleImages.square_inverse.asset_url"
        (toggleCollapsed)="toggleCollapsedContent('square_inverse')"
        [collapsed]="collapsedContent['square_inverse'].collapsed"
        (removePendingItem)="onRemovePendingItem('square_inverse')"
        (formDataChanged)="onFormDataChanged($event,'logo_square_inverse')"
        [resetFormOnChange]="formResetTriggerOnChange.logo_square_inverse.counter"
        [helpTextIterator]="[1,2,3,4]"
        [helpTextIterationKey]="'branding.logo_square_inverse_guide_'"
        [logoExampleImagesAssetUrlTransparency]="logoExampleImages.square_inverse_transparency.asset_url"
        [logoExampleImagesAssetUrlMargin]="logoExampleImages.square_inverse_margin.asset_url"
        [pendingUpdateRemoveSavedLogo]="pending_updates?.logo_square_inverse?.removeSaved"
        (markSavedLogoForDeletion)="toggleMarkSavedLogoForDeletion('logo_square_inverse')"
        [disabled]="disabled"
        [loading]="loading"
        [error]="error"
  ></multisite-brand-logo-editor>


  </div>


</div>