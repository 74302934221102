import { CloudinaryMediaAsset } from "../models";
import { Setting } from "../settings";

export class HubMicro { // a simplified Hub just for branding websites, usually a child property of Brand

    id : number;
    name : string;
    slug : string;
    strapline : string;
    description : string;
    category : string;
    media: CloudinaryMediaAsset[];
    settings: Setting[];
}