import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService, SnapshotRequestData, Snapshot, SnapshotService, WindowService, FormCachingService, GalleryService, ApplicationService } from '@frontend/common';
import { Media } from '@frontend/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-snapshots-listing',
  templateUrl: './snapshots-listing.component.html',
  styleUrls: ['./snapshots-listing.component.css']
})
export class SnapshotsListingComponent implements OnInit {

  modalRef?: BsModalRef;
  snapshots : Snapshot[];
  loading: boolean;
  gallerySubscription: Subscription;
  galleryCtaMedia: Media[];
  activeLanguageSubscription: Subscription;
  snapshotsSubscription: Subscription;
  imageTransformations : string ="w_100,ar_16:9/"
  snapshotForm : FormGroup;
  error : string; // a translation string, such as 'common.something_went_wrong'
  error_meta : {accepted_snapshot_ids:number[]}; // something sent with the error, in this case the ID of an existing Snapshot
  formCachingKey: string = 'snapshot_create_edit';

  
  constructor(
    private modalService: BsModalService,
    private snapshotService: SnapshotService,
    private languageService: LanguageService,
    private router: Router,
    private route: ActivatedRoute,
    private formCachingService: FormCachingService,
    private windowService: WindowService,
    private galleryService: GalleryService,
    private applicationService: ApplicationService,
    @Inject('appKey') private appKey: string,
    ) { }

  getSnapshotStatus (){
    // TODO this is fake for the time being

  }
  closeModal(){
    if (this.modalRef){
      this.modalRef.hide();
    }
  }

  currentAcceptedSnaphot (){
    return this.snapshots?.length ? this.snapshots.find(s=>s.current_accepted) : null;
  }

  getStepperMedia(){
    // let appKey = this.applicationService.getAppKey();
    this.gallerySubscription = this.galleryService.getGallery(this.appKey,'snapshot_intro').subscribe(result=>{
      if(result?.data.length > 4){
        this.galleryCtaMedia = result.data.slice(0,3);
      } else {
        this.galleryCtaMedia = result.data;
      }
    })
  }

  getSnapshots(freshFromServer: boolean){
    this.loading = true;
    this.snapshotsSubscription = this.snapshotService.getSnapshots(freshFromServer).subscribe( response =>{
      this.snapshots = response;
      if(!response?.length){
        this.getStepperMedia();
      }
      this.loading = false;
  },
    error => {
      this.loading = false;
    })
  }

  storeNewSnapshot(snapshotData:SnapshotRequestData){
    this.loading = true;
    this.error = null;
    this.error_meta = null;
    this.snapshotsSubscription = this.snapshotService.storeNewSnapshot(snapshotData).subscribe(snapshot=>{
      this.closeModal();
      this.formCachingService.clearDataItem(this.formCachingKey);
      this.router.navigate(['./'+snapshot.id], {relativeTo: this.route});
      this.loading = false;
    },
    (error : string | {message:string,meta:any}) => {
      if (typeof error === 'string'){
        this.error = error;
      } else {
        this.error = error.message;
        this.error_meta = error.meta;
        setTimeout(()=>{
          if (this.error_meta?.accepted_snapshot_ids?.length){
              this.closeModal();
              this.router.navigate(['./'+this.error_meta.accepted_snapshot_ids[0]], {relativeTo: this.route});
            };
        },5000);
        this.loading = false;
      };
    })
  };
  
  // openModal(template: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(template);
  // }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-lg' })
    );
  }

  ngOnInit(): void {
    this.windowService.goToTop();
    this.getSnapshots(true);
    this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( () => {
      this.getSnapshots(true);
    })
  }

  ngOnDestroy () {
    if (this.activeLanguageSubscription){
      this.activeLanguageSubscription.unsubscribe();
    }
    if (this.snapshotsSubscription){
      this.snapshotsSubscription.unsubscribe();
    }
    if(this.gallerySubscription){
      this.gallerySubscription.unsubscribe();
    }
  }

}
