import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'multisite-survey-response-list',
  templateUrl: './survey-response-list.component.html',
  styleUrls: ['./survey-response-list.component.scss']
})
export class SurveyResponseListComponent implements OnInit {


  surveys : {name: string, slug : string}[];

  constructor() { 

    this.surveys = [
      {name : 'Affective', slug: 'affective'},
      {name : 'Behavioral', slug: 'behavioral'},
      {name : 'Cognitive', slug: 'cognitive'}
    ]

  }

  ngOnInit(): void {
  }

}
