import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertaintyScoreCardComponent } from './certainty-score-card/certainty-score-card.component';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    CertaintyScoreCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    NgxChartsModule,
    CollapseModule.forRoot(),
  ],
  exports: [
    CertaintyScoreCardComponent
  ]
})
export class CertaintyModule { }
