<form #lForm="ngForm">
  <div class="md-form">
    <frontend-link-social-icon class="prefix" [link]="link">
    </frontend-link-social-icon>
    <!-- <mdb-icon fab icon="linkedin" class="prefix linkedin-color"></mdb-icon> -->
    <input
        type="text"
        name="url_link"
        id="url_link"
        class="form-control"
        mdbInput
        mdbValidate
        [(ngModel)]="link.url"
        (ngModelChange)="changedInputValue()"
        [placeholder]="'COMMON.PASTE_LINK' | transloco"
        #url_link="ngModel"
        minlength="15"
    >
    <label for="url_link" *transloco="let t">
      <span *ngIf="link.category == 'social' && link.type !== 'linkedin'">{{t('ACCOUNT.PROFILE.MY_PROFILE_ON') +' '+link.type}}</span>
      <span *ngIf="link.category == 'social' && link.type == 'linkedin'">{{t('ACCOUNT.PROFILE.SOCIAL_PROFILE_LINKEDIN')}}</span>
      <span *ngIf="link.category == 'profile'">{{t('ACCOUNT.PROFILE.'+linkPivotType)}}</span>
    </label>
    <alert
    *ngIf="url_link.invalid && url_link.touched"
     [type]="'warning'" [dismissible]="true">
     <div *ngIf="url_link.errors.minlength">
      <span *transloco="let t">
        <span>{{t('COMMON.TOO_SHORT')}}</span>
      </span>
    </div>
    </alert>
    <!-- <mdb-error *ngIf="url_link.invalid && url_link.touched">
      <div *ngIf="url_link.errors.minlength">
        <span *transloco="let t">
          <span>{{t('COMMON.TOO_SHORT')}}</span>
        </span>
      </div>
    </mdb-error> -->
  </div>
</form>