import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Design01Component } from './design01/design01.component';
import { Design02Component } from './design02/design02.component';
import { Design03Component } from './design03/design03.component';
import { Design04Component } from './design04/design04.component';
import { Design05Component } from './design05/design05.component';
import { Design06Component } from './design06/design06.component';
import { Design08Component } from './design08/design08.component';
import { Design07Component } from './design07/design07.component';
import { Design09Component } from './design09/design09.component';
import { Design10Component } from './design10/design10.component';
import { AuthModule } from '@frontend/common';

const routes: Routes = [
  { path: '', redirectTo : '01', pathMatch : 'full' },
  { path: '01', component:  Design01Component},
  { path: '02', component:  Design02Component},
  { path: '03', component:  Design03Component},
  { path: '04', component:  Design04Component},
  { path: '05', component:  Design05Component},
  { path: '06', component:  Design06Component},
  { path: '07', component:  Design07Component},
  { path: '08', component:  Design08Component},
  { path: '09', component:  Design09Component},
  { path: '10', component:  Design10Component},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    AuthModule,
  ],
  exports: [RouterModule]
})
export class DesignRoutingModule { }
