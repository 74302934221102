       <div class="editable-row" *transloco="let t">
        <p class="title">
          <span *ngIf="roleHolderTitleSingularTranslationKey || roleHolderTitlePluralTranslationKey">

            {{
            roleHolders.length === 1
            ? t(roleHolderTitleSingularTranslationKey)
            : t(roleHolderTitlePluralTranslationKey)
            }}
          </span>

          <button class="btn btn-sm btn-primary primary-dark" (click)="toggleRoleLock()"
            *ngIf="roleHolders.length">
            <span *ngIf="!locked">
              <i class="fa-solid fa-lock-open mr-2"></i>{{t('common.lock')}}
            </span>
            <span *ngIf="locked">
              <i class="fa-solid fa-lock mr-2"></i>{{t('common.unlock')}}
            </span>
          </button>
        </p>
        <p *ngIf="roleHolderSubtitleTranslationKey">{{ t(roleHolderSubtitleTranslationKey) }}</p>
        <div *ngIf="roleHolders.length" class="mt-3">
          <div class="contributors">
            <span *ngFor="let roleHolder of roleHolders">
                <multisite-avatar-pill (selected)="removeRoleHolder(roleHolder.email)" [cursorNone]="locked"
                  [removalMode]="allowRoleHolderRemovalMode" [fallbackImageUrl]="
                'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'
              " [fallbackTranslationKey]="'social.verified_user'" [imageUrl]="roleHolder.picture" [text]="
                roleHolder.pre_honorific +
                ' ' +
                roleHolder.fname +
                ' ' +
                roleHolder.lname +
                '
          ' +
                roleHolder.post_honorific
              "></multisite-avatar-pill>
            </span>
          </div>

        </div>
        <button (click)="showNewRoleHolderForm = !showNewRoleHolderForm" class="btn btn-primary" [disabled]="loading">
          <div class="icon-left">
            <i class="fa-solid fa-plus"></i>
          </div>
          {{
            showNewRoleHolderForm
          ? t('common.cancel')
          : t('common.new')
          }}
        </button>

        <multisite-email-input-form *ngIf="showNewRoleHolderForm" [labelTextTranslationKey]="
        'common.enter_person_email_address'
      " (emailSubmitted)="addRoleHolder({name:'a fake name', email: $event})" [errorTranslationKey]="error"
          [loading]="loading" ngClass="w-100">
        </multisite-email-input-form>
        <alert [type]="'warning'" [dismissible]="true" *ngIf="error">
          <span *transloco="let t">{{ t(error) }}</span>
        </alert>
      </div>