<div class="container">


  <div class="row d-flex flex-column gap-2 my-5 pb-5">
    <multisite-link-type-nine
      [iconClasses]="'fa-solid fa-swatchbook mr-2'"
      [headingClass]="'h5'"
      [linkLabelPart1TranslationKey]="'common.go'"
      [linkLabelPart2Text]="': '"
      [linkLabelPart3TranslationKey]="'navigation.brands'"
      [routerLink]="'brands'">
    </multisite-link-type-nine>


    <multisite-link-type-nine
      [iconClasses]="'fa-solid fa-pen-to-square mr-2'"
      [headingClass]="'h5'"
      [linkLabelPart1TranslationKey]="'content_management.edit_profile'"
      [linkLabelPart2Text]="': '"
      [linkLabelPart3TranslationKey]="'hubs.hub_general_profile'"
      [routerLink]="'profiles/general'">
    </multisite-link-type-nine>
  </div>

</div>