<div class="card" *transloco="let t">
  <div class="card-header d-flex justify-content-between align-items-center" (click)="consentSet().collapsed = !consentSet().collapsed" style="cursor: pointer;" [attr.aria-expanded]="!consentSet().collapsed" aria-controls="collapseBody">
    {{consentSet().latest.title}}
    @if(consentSet().collapsed){
      <span><i class="fa-solid fa-chevron-down ml-1"></i></span>
    } @else {
      <span><i class="fa-solid fa-chevron-up ml-1"></i></span>
    }
  </div>      
  @if(!consentSet().latest.given_at && !consentSet().latest.declined_at && !consentSet().latest.withdrawn_at){
      <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
        <!-- Alert: needs attention -->
        <span class="visually-hidden">New alerts</span>
      </span>
  }

  <div class="card-body" [collapse]="consentSet().collapsed" [isAnimated]="true" id="collapseBody">
    <h3 class="card-title lead h3">{{consentSet().latest.question}}</h3>
    <p class="card-text my-2">{{consentSet().latest.description}}</p>
    @if(consentSet().latest.links?.length){
      <div class="text-end">
        @for(link of consentSet().latest.links; track link.id){
          <span class="d-block-inline mx-3"><i class="fa-regular fa-info-circle mr-1"></i><a class="card-link" [href]="link.url">{{link.label}}<i class="fa-solid fa-arrow-up-right-from-square ml-1"></i></a></span>
        }
      </div>
    }
    @if((!consentSet().latest.given_at && !consentSet().latest.declined_at) || (consentSet().latest.withdrawal_allowed && consentSet().latest.given_at && !consentSet().latest.declined_at && !consentSet().latest.withdrawn_at)){
      <hr class="my-3">
    }
    <div class="d-flex align-items-center">

      <!-- Duplicated in the Modal. If you change something here, change it in the Modal too. -->
      @if(!consentSet().latest.given_at && !consentSet().latest.declined_at){
        <button class="btn btn-warning" (click)="doUpdateStatus(consentSet(),'declined')">{{t(consentSet().latest.verb_reject)}}</button>
        <button class="btn btn-primary" (click)="doUpdateStatus(consentSet(),'given')">{{t(consentSet().latest.verb_accept)}}</button>
      }
      @if(consentSet().latest.withdrawal_allowed && consentSet().latest.given_at && !consentSet().latest.declined_at && !consentSet().latest.withdrawn_at){
        <button class="btn btn-primary" (click)="doUpdateStatus(consentSet(),'withdrawn')">{{t('common.withdraw')}}</button>
      }
      <!-- END of duplicated in the Modal -->

      <multisite-loader-globe-animation-detail
        *ngIf="loading()"
        class="ml-2 d-block-inline" [width]="40" [height]="40">
      </multisite-loader-globe-animation-detail>
    </div>
    <alert [type]="'warning'" [dismissOnTimeout]="9000" *ngIf="error()" class="my-3">
      <strong>{{ t('common.problem')! }}</strong><br>
      <p >{{ t(error()) }}</p>
    </alert>
    <h6 class="mt-4 d-flex justify-content-between align-items-center" (click)="consentSet().hideMoreInfo = !consentSet().hideMoreInfo"
      style="cursor: pointer;" [attr.aria-expanded]="!consentSet().hideMoreInfo" aria-controls="collapseBasic">
      <span><i class="fa-solid fa-circle-info mr-2"></i>{{t('common.more_info')}}</span>
      @if(consentSet().hideMoreInfo){
        <span><i class="fa-solid fa-chevron-down ml-1"></i></span>
      } @else {
        <span><i class="fa-solid fa-chevron-up ml-1"></i></span>
      }
    </h6>
    <div class="mt-3" [collapse]="consentSet().hideMoreInfo" [isAnimated]="true" id="collapseBasic">
      <strong>{{t('account.what_if_say_no')}}</strong>
      <p class="card-text mb-4">{{consentSet().latest.decline_info}}</p>
      <div class="embed-responsive" *ngIf="consentSet().latest.videos?.[0]">
        <frontend-video-player-type-two
          [video]="consentSet().latest.videos[0]"
          [classes]="'mb-3'"
        ></frontend-video-player-type-two>
      </div>
    </div>
  </div>

  @if(consentSet().statuses.length){
    <div class="card-footer" [collapse]="consentSet().collapsed">
      @for(status of consentSet().statuses; track status){
        <p class="text-muted">{{ t('account.consent_status_from_timestamp.'+status.attributeKey)}} {{status.date | date : 'longDate'}}</p>
      }
    </div>
  }
</div>