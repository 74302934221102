import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FaqsRoutingModule } from './faqs-routing.module';
import { FaqListingComponent } from './faq-listing/faq-listing.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { LoadersModule } from '../../loaders';
import { FaqComponent } from './faq/faq.component';
import { TranslocoModule } from '@jsverse/transloco';


@NgModule({
  declarations: [
    FaqListingComponent,
    FaqComponent
  ],
  exports: [
    FaqListingComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    FaqsRoutingModule,
    AccordionModule,
    LoadersModule
  ]
})
export class FaqsModule { }
