<span class="avatar-pill" [ngClass]="{
    'removal-mode': removalMode,
    'clickable': clickable,
    'disabled': disabled,
    'active': active,
    'cursor-none': cursorNone,
    'compact' :compact,
    'no-background' :hideBackground,
  }" (click)="onSelected()">
  <img class="profile-img" [src]="imageUrl ? imageUrl : fallbackImageUrl" [alt]="text">
  <span *transloco="let t">{{translationKey ? t(translationKey) : text ? text : t(fallbackTranslationKey)}}</span>

  <button *ngIf="removalMode" type="button" aria-label="Remove" class="btn btn-dismiss py-0">
    <i class="fa-solid fa-xmark"></i>
  </button>

</span>

<!-- <span class="avatar-pill">
  <img class="profile-img" src="" alt="...">
  <span>...</span>
</span>

<span class="avatar-pill removal-mode">
  <img class="profile-img" src="" alt="...">
  <span>...</span>

  <button type="button" aria-label="Remove" class="btn-dismiss">
    <i class="fa-solid fa-xmark"></i>
  </button>
</span>

<span class="avatar-pill disabled">
  <img class="profile-img" src="" alt="...">
  <span>...</span>
</span> -->