export const DefaultBrandConstants = {

    requiredColourVariants: [
        {key: 'primary', variants: ['shadow','dark','dark-shadow','light','light-shadow']},
        {key: 'secondary', variants: ['shadow','dark','dark-shadow','light','light-shadow']},
        {key: 'success', variants: ['shadow']},
        {key: 'info', variants: ['shadow']},
        {key: 'warning', variants: ['shadow']},
        {key: 'danger', variants: ['shadow']},
        {key: 'link', variants: ['shadow']},
    ], // these are our seven base brand colours. All others are variants (Shadow, Light, Dark, Dask--Shadow etc)
    baseColourKeys: ['primary','secondary','success','info','warning','danger','link'], // these are our seven basic brand colours. All others are variants (Shadow, Light, Dark, Dark--Shadow etc)
    // colours.map(c=>.name): ['primary-dark', 'primary', 'primary-light', 'primary-dark-shadow', 'primary-light-shadow', 'primary-shadow', 'secondary-dark', 'secondary', 'secondary-light', 'secondary-dark-shadow', 'secondary-light-shadow', 'secondary-shadow', 'success', 'info', 'warning', 'danger', 'success-shadow', 'info-shadow', 'warning-shadow', 'danger-shadow', 'link', 'link-shadow'],
    colours: [

        // !!DUPLICATED!! - These values are duplicated in the scss. If you change a value here, you must also change the value in libs/common/src/lib/styles/abstract/_variables.scss
 
        {name: 'primary-dark', hex: '#070c29'}, // we add 'primary-dark-static' on the fly, not here
        {name: 'primary', hex: '#101a5f'}, 
        {name: 'primary-light', hex: '#F9F9FF'},  // we add 'primary-light-static' on the fly, not here
        {name: 'primary-dark-shadow', hex: '#05091e'}, 
        {name: 'primary-light-shadow', hex: '#929dd5'}, 
        {name: 'primary-shadow', hex: '#0c1347'}, 
        {name: 'secondary-dark', hex: '#54595e'},  // we add 'secondary-dark-static' on the fly, not here
        {name: 'secondary', hex: '#6c757d'}, 
        {name: 'secondary-light', hex: '#abb5be'},   // we add 'secondary-light-static' on the fly, not here
        {name: 'secondary-dark-shadow', hex: '#3e4246'}, 
        {name: 'secondary-light-shadow', hex: '#788896'}, 
        {name: 'secondary-shadow', hex: '#51575d'}, 
        {name: 'success', hex: '#198754'},
        {name: 'info', hex: '#0dcaf0'},
        {name: 'warning', hex: '#ffc107'},
        {name: 'danger', hex: '#dc3545'},
        {name: 'success-shadow', hex: '#12653e'},
        {name: 'info-shadow', hex: '#0997b4'},
        {name: 'warning-shadow', hex: '#c49300'},
        {name: 'danger-shadow', hex: '#ae1e2c'},
        {name: 'link', hex: '#0267FD'},
        {name: 'link-shadow', hex: '#013E98'},
    ],
    greyScale: [

        // !!DUPLICATED!! - These values are duplicated in the scss. If you change a value here, you must also change the value in libs/common/src/lib/styles/abstract/_variables.scss

        // {name: 'white', hex: '#FFFFFF'}, 
        {name: 'gray-100', hex: '#F2F2F2'},
        {name: 'gray-200', hex: '#e9ecef'},
        {name: 'gray-300', hex: '#dee2e6'},
        {name: 'gray-400', hex: '#ced4da'},
        {name: 'gray-500', hex: '#adb5bd'},
        {name: 'gray-600', hex: '#6c757d'},
        {name: 'gray-700', hex: '#495057'},
        {name: 'gray-800', hex: '#343a40'},
        {name: 'gray-900', hex: '#212529'},
        // {name: 'black', hex: '#000000'},

        /* For Dark Mode, we just reverse the order of the hex values in this array */
  
    ],
    darkModeBrandColourSwitches : [
        {lightModeVariant: 'primary-dark', darkModeVariant: 'primary-light'},
        {lightModeVariant: 'primary-dark-shadow', darkModeVariant: 'primary-light-shadow'},
        {lightModeVariant: 'primary-light', darkModeVariant: 'primary-dark'},
        {lightModeVariant: 'primary-light-shadow', darkModeVariant: 'primary-dark-shadow'},
        {lightModeVariant: 'link', darkModeVariant: 'primary-light'},
        {lightModeVariant: 'link-shadow', darkModeVariant: 'primary-dark'},
    ],
    /* colours with '-static' in the name do not switch between light mode and dark mode */
}