import { Injectable } from '@angular/core';
import { ContentItem } from './content-item.model';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor() { }

  insert_cta_call_to_action_term ( logic : string, contentItem: ContentItem){
    if (logic === 'watchVideoOrView'){
      contentItem.cta_text = contentItem.video ? 'Watch' : 'View';
      contentItem.cta_translationKey = contentItem.video ? 'common.watch' : 'common.watch';
    } else {
      contentItem.cta_text = "Go";
      contentItem.cta_translationKey = "common.go";
    }
    return contentItem;
  }
}
