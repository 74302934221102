import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService, Guest, User } from '@frontend/common';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';


@Component({
  selector: 'multisite-guest-login',
  templateUrl: './guest-login.component.html',
  styleUrls: ['./guest-login.component.scss']
})
export class GuestLoginComponent implements OnInit, OnDestroy {

  user: User;
  guest: Guest;
  guestInLocalStorage: any;
  @ViewChild('guestLoginModal') guestLoginModal;
  modalRef?: BsModalRef;
  private promptSubscription : Subscription;
  private modalSubscription : Subscription;
  navigating : boolean; // the user did not close the modal by the close button or the background
     
  constructor(
    private modalService: BsModalService,
    private authService : AuthService,
    private router : Router,
  ) { }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalSubscription = this.modalRef.onHide.subscribe((reason: string | any) => {
      debugger;
      if(!this.navigating){
        this.authService.setRouteForRedirectAfterAuth(null);
      }
      this.navigating = false;
    })
  }
  closeModal(){
    if (this.modalRef){
      this.modalRef.hide();
    }
  }

  navigateTo(route :string[]){
    this.router.navigate(route);
    this.navigating = true;
    this.closeModal();
  }



  ngOnInit(): void {
    this.authService.promptAuthentication.subscribe(()=>this.openModal(this.guestLoginModal));
  }

  ngOnDestroy(): void { // if this component is in the footer, ngOnDestroy will never run

    if(this.promptSubscription){
      this.promptSubscription.unsubscribe();
    }
    if(this.modalSubscription){
      this.modalSubscription.unsubscribe();
    }
  }


}
