import { Term } from "./term.model";

export class TermVersion {
    id : number;
    title : string;
    question : string;
    decline_info : string;
    description : string;
    verb_accept : string;
    verb_reject : string;
    withdrawal_allowed : boolean;
    term? : Term;
}