<div class="left-bordered-card" *transloco="let t" (click)="card_clickable && !disabled ? onCardSelected() : null" [ngClass]="{'clickable':card_clickable}">
    <div class="card">
      <div class="card-body">
        <div class="top-part">
          <h5 class="card-title">{{ title_text_translation_key ? t(title_text_translation_key) : title_text}}</h5>
          <h6 class="card-subtitle mt-3 mb-2 fw-bold" *ngIf="subtitle_text_translation_key || subtitle_text">{{ subtitle_text_translation_key ? t(subtitle_text_translation_key) : subtitle_text}}</h6>
          <div class="status-list" *ngIf="statusItems">
            <div
                *ngFor="let statusItem of statusItems"
                class="list-item"
                [ngClass]="{
                    'ng-valid': statusItem.status == 'success',
                    'ng-info': statusItem.status == 'info',
                    'ng-warning': statusItem.status == 'warning',
                    'ng-invalid': statusItem.status == 'danger',
                }"
                >
                <span *ngIf="!statusItem.type && !statusItem.status"><i class="fa-regular fa-circle"></i></span>
                <span *ngIf="statusItem.type === 'check' || (!statusItem.type && statusItem.status == 'success')"><i class="fa-solid fa-check"></i></span>
                <span *ngIf="statusItem.type === 'cancel' || (!statusItem.type && statusItem.status == 'danger')"><i class="fa-solid fa-xmark"></i></span>
                <span *ngIf="statusItem.type === 'warning' || (!statusItem.type && statusItem.status == 'warning')"><i class="fa-solid fa-triangle-exclamation"></i></span>
                <span *ngIf="statusItem.type === 'info' || (!statusItem.type && statusItem.status == 'info')"><i class="fa-solid  fa-circle-info"></i></span>
                {{ statusItem.translationKey ? t(statusItem.translationKey) : statusItem.text}}
            </div>
          </div>
        </div>
        <div *ngIf="body" [innerHTML]="body"></div>
        <button
            type="button"
            class="btn btn-sm btn-primary primary-dark"
            *ngIf="button_text || button_text_translation_key"
            [disabled]="disabled"
            (click)="onButtonSelected($event)"
            [routerLink]="button_link_route"
            >
            {{ button_text_translation_key ? t(button_text_translation_key) : button_text}} 
            <i class="fa-solid fa-chevron-right ml-2"></i>
            
        </button>
      </div>
    </div>
  </div>