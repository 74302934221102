import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { Tag } from '@frontend/core';
import { Faq } from '@frontend/core';

import { environment } from './../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class FaqService {

  faqs = {};
  appKey : string = environment.appKey;


  constructor(private http : HttpClient) { }


  cacheFaqs (faqs : Faq[]) {
    if (!faqs || !faqs.length) { return; };
    faqs.forEach(faq => {
      this.faqs[faq.id] = faq;
    });
  };

  getDefaultSiteHelpTags (){
    let tagSlugs : string[] = [];
    tagSlugs.push(this.appKey);
    return tagSlugs;
  }

  getFaqsByTags (tagSlugs : string[]){

    let params = tagSlugs && tagSlugs.length ? '?tags='+tagSlugs.toString() : '';
    return this.http.get<{'data': Faq[]}>(
      'api/v1/faqs'+params)
        .pipe(
          map(response =>{
            if (response && response.data ){
              this.cacheFaqs(response.data);
              return response.data;
            };
          })
        )
  };

};