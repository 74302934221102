import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BrandDisplayService } from '../../branding';

@Component({
  selector: 'multisite-footer',
  templateUrl: './multisite-footer.component.html',
  styleUrls: ['./multisite-footer.component.scss']
})
export class MultisiteFooterComponent implements OnInit {

  darkModeToggleForm : FormGroup;
  textSizeForm : FormGroup;
  @Input() logo : string; // 'culturettt' or 'icpendulum'
  @Input() summaryText : string; // 'A sentence about the site, which will not be translated'
  @Input() summaryTranslationKey : string; // 'Translation Key for a sentence about the site'
  @Input() darkModeOn: boolean; // overrides the dark mode styles for certain elements

  constructor(
    private brandDisplayService : BrandDisplayService
  ) { }

    getYear(){
      return new Date().getFullYear();
    }

  ngOnInit(): void {
    this.darkModeToggleForm = new FormGroup({
      mode: new FormControl(this.brandDisplayService.darkMode, [Validators.required])
    });
    this.darkModeToggleForm.controls.mode.valueChanges.subscribe(
      (value) => {
       this.brandDisplayService.darkMode = value;
      }
    );
    this.textSizeForm = new FormGroup({
      size: new FormControl(this.brandDisplayService.textSize)
    });
    this.textSizeForm.controls.size.valueChanges.subscribe(
      (value) => {
       this.brandDisplayService.textSize = value;
      }
    );
  }

}
