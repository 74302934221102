import { Component, input, output, signal } from '@angular/core';
import { ConsentSet } from '../consent-set.model';



@Component({
  selector: 'consent-updater-card',
  templateUrl: './consent-updater-card.component.html',
  styleUrls: ['./consent-updater-card.component.scss']
})
export class ConsentUpdaterCardComponent {

  consentSet = input.required<ConsentSet>();
  error = input<string|{message:string,meta:any}|undefined>(undefined);
  loading = input<boolean>();
  updateStatus = output<{consentSet: ConsentSet, status: 'given' | 'declined' | 'withdrawn'}>();


  doUpdateStatus(consentSet: ConsentSet, status: 'given' | 'declined' | 'withdrawn'){
      this.updateStatus.emit({consentSet, status});
  }


}
