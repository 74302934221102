<div class="media d-block d-md-flex mt-4">
  <img
    class="card-img-64 rounded-circle z-depth-1 d-flex mx-auto mb-3"
    [src]="comment.commenter.picture ? comment.commenter.picture : 'https://res.cloudinary.com/cebt/image/upload/v1616972586/users/avatars/default-avatar.gif'"
    [alt]="comment.commenter.fname+' '+comment.commenter.lname"
>
  <div class="media-body text-center text-md-left ml-md-3 ml-0">
    <h5 class="font-weight-bold mt-0">
      <span class="text-default">{{comment.commenter.name_full_honorific}}</span>
      <small class="mx-1 text-muted">&middot;&nbsp;{{comment.created_at | date : 'longDate'}}</small>
      <!-- Comment replies are disabled. TODO - enable comment replies -->
      <!-- <a (click)="toggleReplyFormVisibility()" class="pull-right text-default">
        <mdb-icon fas icon="reply"></mdb-icon>
      </a> -->
      <!-- End of comment reply disabling -->
    </h5>
    {{comment.comment}}
    <!-- Replies
    
    
    
        <div class="media d-block d-md-flex mt-4">
      <img class="card-img-64 rounded-circle z-depth-1 d-flex mx-auto mb-3" src="https://mdbootstrap.com/img/Photos/Avatars/img (27).jpg"
        alt="Generic placeholder image">
      <div class="media-body text-center text-md-left ml-md-3 ml-0">
        <h5 class="font-weight-bold mt-0">
          <a class="text-default" href="">Tommy Smith</a>
          <a href="" class="pull-right text-default">
            <mdb-icon fas icon="reply"></mdb-icon>
          </a>
        </h5>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
        totam rem aperiam, eaque
        ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
      </div>
    </div>
    
    
    
    
    -->

    <!-- Quick Reply -->
    <div class="md-form mt-4" *ngIf="showReplyForm">
      <label for="quickReplyFormComment">Your comment</label>
      <textarea class="form-control md-textarea" id="quickReplyFormComment" rows="3" mdbInput></textarea>

      <div class="text-center my-4">
        <button mdbBtn color="default" size="sm" rounded="true" mdbWavesEffect type="submit">Post</button>
      </div>
    </div>


  </div>
</div>
