import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnapshotHomeComponent } from './snapshot-home/snapshot-home.component';
import { SurveyContextModule } from '../survey-context';
import { RouterModule } from '@angular/router';
import { LoadersModule } from '../../loaders';
import { TranslocoModule } from '@jsverse/transloco';
import { SnapshotCreateEditFormSimpleComponent } from './snapshot-create-edit-form-simple/snapshot-create-edit-form-simple.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertsCustomModule, AvatarsModule, LinksModule, ListItemModule, StatusListItemModule, UploadersModule } from '@frontend/shared';
import { CultureSelectorTypeOneModule } from '../../cultures';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SnapshotListItemComponent } from './snapshot-list-item/snapshot-list-item.component';
import { SnapshotsListingComponent } from './listing/snapshots-listing.component';
import { CiprofileWheelModule } from '../../ciprofile';
import { GalleryCtaModule } from '../../content/banners/gallery-cta/gallery-cta.module';



@NgModule({
  declarations: [
    SnapshotHomeComponent,
    SnapshotCreateEditFormSimpleComponent,
    SnapshotListItemComponent,
    SnapshotsListingComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    SurveyContextModule,
    RouterModule,
    LoadersModule,
    ReactiveFormsModule,
    ListItemModule,
    AvatarsModule,
    CultureSelectorTypeOneModule,
    StatusListItemModule,
    AlertModule,
    AlertsCustomModule,
    UploadersModule,
    CiprofileWheelModule,
    LinksModule,
    GalleryCtaModule,
  ],
  exports: [
    SnapshotHomeComponent,
    SnapshotListItemComponent,
    SnapshotCreateEditFormSimpleComponent,
    SnapshotsListingComponent
  ]
})
export class SnapshotsModule { }
