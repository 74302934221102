import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'frontend-comment-form-type-two',
  templateUrl: './comment-form-type-two.component.html',
  styleUrls: ['./comment-form-type-two.component.scss'],
})
export class CommentFormTypeTwoComponent implements OnChanges {
  @Input() poster;
  @Input() loading;
  @Input() clearCommentForm: boolean;
  @Output() postComment = new EventEmitter<string>();

  message: string;
  defaultAvatar =
    'https://res.cloudinary.com/cebt/image/upload/v1616972586/users/avatars/default-avatar.gif';

  constructor() {}

  doPostComment() {
    this.postComment.emit(this.message);
  }

  ngOnChanges(changesObject) {
    if (changesObject.poster && !changesObject.poster.currentValue) {
      this.poster = {
        fname: 'Not yet logged in',
        lname: 'Guest',
        picture: this.defaultAvatar,
      };
    }
    if (changesObject.clearCommentForm) {
      this.message = null;
    }
  }
}
