import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

import { Page } from '@frontend/core';
import { LanguageService } from '@frontend/common';



@Injectable({
  providedIn: 'root'
})
export class PageService {
  
  pagesByCategoryAndType = {
    // child property: category, grandchild property: type
    // pages are always in an array
    /* 'privacy':{'pages':[arrayOfPagesWithoutType],'notice':[arrayContaningOneSinglePage]} */
  };
  activeLanguageSubscription: Subscription;

  constructor(
    private http : HttpClient,
    private languageService: LanguageService,

    ) {
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
  }
  
  clearTranslations() {
     this.pagesByCategoryAndType = {};
  }
  clearData() {
    this.clearTranslations();
  }

   transformPage (page) : Page {
    page.created_at = new Date(page.created_at);
    page.updated_at = new Date(page.updeated_at);
    // p.published_at = p.published_at ? new Date(p.published_at) : null;
    // p.updated_at = p.updated_at ? new Date(p.updated_at) : null;
    let transformedPage : Page = page;
    return transformedPage;
  };
  transformPages (pages) : Page[] {
    let transformedPages : Page[] = [];
    pages.forEach(p=>{
      transformedPages.push(this.transformPage(p));
    });
    return transformedPages;
  }

  cachePages (pages : Page[], category : string,  type : string){
    if (!pages || !category){return;};
    if (!this.pagesByCategoryAndType[category]){
      this.pagesByCategoryAndType[category] = {};
    }
    this.pagesByCategoryAndType[category][type] = pages;
    return pages;
  };

  getPagesByCategoryAndType (category: string, type : string){
    if (!category) { throw 'category is required';};
    let url = 'api/v1/pages?category='+category+'&type=';
    if (type) {
      url+=type;
    };
    if (this.pagesByCategoryAndType[category] && this.pagesByCategoryAndType[category][type]){
      return of(this.pagesByCategoryAndType[category][type]);
    };
    return this.http.get<{'data': Page[]}>(url)
        .pipe(
          map(response =>{
            if (response && response.data ){
              let pages = this.transformPages(response.data);
              return this.cachePages(pages,category,type);
            };
          })
        )
  };
};