import { Component, OnInit, OnChanges, Input } from '@angular/core';

import { Link } from '@frontend/core';

@Component({
  selector: 'frontend-link-type-three-overlay',
  templateUrl: './link-type-three-overlay.component.html',
  styleUrls: ['./link-type-three-overlay.component.scss']
})
export class LinkTypeThreeOverlayComponent implements OnInit, OnChanges {

  // Good for external links

  @Input() link : Link;
  @Input() hideButton : boolean;
  @Input() hideImage : boolean;
  @Input() transformations : string; // [transformations]="'w_700/'"
  ctaTranslation : string;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changesObject): void {
    if (changesObject?.link && changesObject.link.currentValue){
      if (changesObject.link.currentValue.description?.length > 180){
        this.link.description = this.truncateText(changesObject.link.currentValue.description,170);
      };
      if (changesObject.link.currentValue.media?.asset_url && this.transformations){   
        this.link.media.file_url = this.link.media.base_url + this.transformations + this.link.media.asset_url;
      };
    }
  }

  truncateText(sourceText, length){
    let truncatedText = sourceText.substr(0, length);
    return truncatedText.trim()+'...';
  }

}
