import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BreadcrumbService } from '../../../navigation/breadcrumb/breadcrumb.service';


@Component({
  selector: 'multisite-survey-start',
  templateUrl: './survey-start.component.html',
  styleUrls: ['./survey-start.component.scss']
})
export class SurveyStartComponent implements OnInit {

  surveyName : string;

  constructor() {};

  ngOnInit(): void {
  }

}
