export class Experience {
    id: number; // 9
    experienceable_id: number; // 55
    experienceable_type: string; // App\\Models\\Lesson
    experience_verb: string; // "consumed"
    experience_value: number; // 1
    experience_description: string; // "satisfied"
    snapshot_id: number; // 46
    archive_id: number; // 7889
    meta: {actions_count: number, actions_count_weighted?: number, }; // if the experienceable_type is an intervention and the experience_verb is "progressed", it may have an actions_count_weighted value
    updated_at: string; // "2023-02-24T12:40:24.000000Z"
    updated: Date;
}