import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaylistAccordionTextComponent } from './playlist-accordion-text.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule as NgxTabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule as NgxCollapseModule } from 'ngx-bootstrap/collapse';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    PlaylistAccordionTextComponent
  ],
  imports: [
    CommonModule,
    AccordionModule,
    NgxTabsModule,
    NgxCollapseModule,
    TranslocoModule
  ],
  exports: [
    PlaylistAccordionTextComponent
  ]
})
export class PlaylistAccordionTextModule { }
