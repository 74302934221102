<div class="progress-card" [ngClass]="slimMode ? 'slim' : 'wide'" *ngIf="totalProgress === 0 || totalProgress">
  <p class="title" id="progresslabel" *transloco="let t">
    {{t('tracking.total_progress')}}
  </p>
  <p class="progress-value">
    {{totalProgress}}%
  </p>
  <div class="progress">
    <div class="progress-bar" role="progressbar" [style]="'width: '+totalProgress+'%'" [attr.aria-valuenow]="totalProgress" aria-valuemin="0"
      aria-valuemax="100" aria-labelledby="progresslabel"></div>
  </div>
</div>