import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from './language.service';

@Injectable()
export class LanguageInterceptorService implements HttpInterceptor {

  constructor(private languageService : LanguageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let languageKey = this.languageService.getActiveLang();
    if(request.url.indexOf('api/v') === 0 && languageKey){
        let modifiedRequest;
        modifiedRequest = request.clone(
            {
                headers: request.headers.append('Accept-Language', languageKey)
            });
        return next.handle(modifiedRequest);
    }
    return next.handle(request);
  }
}
