<div>
  <div class="container" *transloco="let t">
    <div *ngIf="survey">
      <p class="h1 font-weight-bold mt-5">
        {{ t('survey.general_advice') }}
      </p>

      <multisite-link-type-ten *ngIf="videoLink && hasRole('Developer')"
        [titleText]="videoLink.label"
        [cta_label]="videoLink.cta_label"
        [cta_type]="videoLink.cta_type"
        [description]="videoLink.description"
        [descriptionTruncateLength]="110"
        [imageUrl]="videoLink.media?.file_url"
        [imageTransformations]="'w_450/'"
        [media]="videoLink.media"
        [url]="videoLink.url"
        (linkClicked)="videoLinkClicked()"
      ></multisite-link-type-ten>

      <div class="mt-2" *ngIf="survey?.instructions?.length">
        <div class="row" *ngIf="filterInstructions(survey.instructions, 'general')?.length">

          <div class="list-box-item col-lg-6" *ngFor="
                let instruction of filterInstructions(
                  survey.instructions,
                  'general'
                )
              ">
            <div class="content-wrap">
              <div class="icon-holder">
                <i class="fa-solid {{instruction.icon?.identifier_1 ?? 'fa-check'}} icon-size"></i>
                <span class="splitter"></span>
              </div>
              <div class="content">
                <strong>{{ instruction.s }}</strong><span *ngIf="instruction.m"><br />{{ instruction.m }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="filterInstructions(survey.instructions, 'scale')?.length">
        <p class="h1 font-weight-bold mt-5">
          {{ t('survey.scale_advice') }}
        </p>
        <div class="row">
          <div class="list-box-item" *ngFor="
                let instruction of filterInstructions(
                  survey.instructions,
                  'scale'
                )
              ">
            <div class="content-wrap">
              <div class="icon-holder">
                <i class="fa-solid {{instruction.icon?.identifier_1 ?? 'fa-check'}} icon-size"></i>

                <span class="splitter"></span>
              </div>
              <div class="content">
                <strong>{{ instruction.s }}</strong><span *ngIf="instruction.m"><br />{{ instruction.m }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <multisite-question-lickert *ngIf="exampleScaleQuestion" [question]="exampleScaleQuestion"
            [hideSubmitButton]="'demo'">
          </multisite-question-lickert>
        </div>
      </div>
      <div *ngIf="filterInstructions(survey.instructions, 'person_comparison')?.length">
        <p class="h1 font-weight-bold mt-5">
          {{ t('survey.person_comparison_advice') }}
        </p>
        <div class="row">
          <div class="list-box-item col-lg-6" *ngFor="
                let instruction of filterInstructions(
                  survey.instructions,
                  'person_comparison'
                )
              ">
            <div class="content-wrap">
              <div class="icon-holder">
                <i class="fa-solid {{instruction.icon?.identifier_1 ?? 'fa-check'}} icon-size"></i>
                <span class="splitter"></span>
              </div>
              <div class="content">
                <strong>{{ instruction.s }}</strong><span *ngIf="instruction.m"><br />{{ instruction.m }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <multisite-question-lickert *ngIf="examplePersonComparisonQuestion"
            [question]="examplePersonComparisonQuestion" [hideSubmitButton]="'demo'">
          </multisite-question-lickert>
        </div>
      </div>
      <div *ngIf="filterInstructions(survey.instructions, 'context')?.length">
        <p class="h1 font-weight-bold mt-5">
          {{ t('survey.snapshot_advice') }}
        </p>
        <div class="row">
          <div class="list-box-item" *ngFor="
                let instruction of filterInstructions(
                  survey.instructions,
                  'context'
                )
              ">
            <div class="content-wrap">
              <div class="icon-holder">
                <i class="fa-solid {{instruction.icon?.identifier_1 ?? 'fa-check'}} icon-size"></i>
                <span class="splitter"></span>
              </div>
              <div class="content">
                <strong>{{ instruction.s }}</strong><span *ngIf="instruction.m"><br />{{ instruction.m }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <snapshot-context *ngIf="snapshot" [date]="snapshot.date" [title]="snapshot.title"
            [description]="snapshot.description" [cultures]="snapshot.cultures" [primary_culture_id]="snapshot.primary_culture_id"></snapshot-context>
        </div>
        <div class="row" *ngIf="snapshot?.primary_culture_id">
          <div class="list-box-item" *ngFor="
                let instruction of filterInstructions(
                  survey.instructions,
                  'context_primary'
                )
              ">
            <div class="content-wrap">
              <div class="icon-holder">
                <i class="fa-solid {{instruction.icon?.identifier_1 ?? 'fa-check'}} icon-size"></i>
                <span class="splitter"></span>
              </div>
              <div class="content">
                <strong>{{ instruction.s }}</strong><span *ngIf="instruction.m"><br />{{ instruction.m }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          &nbsp;
        </div>
        <div class="col-auto">
          <button class="btn btn-primary" (click)="startSurvey()">{{t('common.start')}}<i
              class="fa-solid fa-arrow-right ml-2"></i></button>
        </div>
      </div>
    </div>

    <div *ngIf="loading">
      <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
      </multisite-loader-globe-animation-detail>
    </div>

  </div>

  <div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 id="dialog-static-name" class="modal-title pull-left">General advice ENG</h4>
          <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="staticModal.hide()">
            <span aria-hidden="true">
              <i class="fa-solid fa-xmark"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe
              src="https://player.vimeo.com/video/616013154?h=f214e58206&color=ab47bc&byline=0&portrait=0"
              style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>