import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { debounceTime, merge, Subscription } from 'rxjs';
import { Culture, CultureService } from '../../../cultures';
import {
  trigger,
  style,
  transition,
  animate,
} from '@angular/animations';
import { SnapshotRequestData } from '../snapshot.service';
import { Snapshot } from '../snapshot.model';
import { MediaService } from '../../../content';
import { FormCachingService } from '../../../utilities';

type FormValidStatus = {
  title : boolean,
  description : boolean,
  cultures : boolean,
}

@Component({
  selector: 'snapshot-create-edit-form-simple',
  templateUrl: './snapshot-create-edit-form-simple.component.html',
  styleUrls: ['./snapshot-create-edit-form-simple.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('0 => 1, 1 => 2, 2 => 0', [
        style({ transform: 'translateX(10%)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(0)' })),
      ]),
      transition('0 => 2, 1 => 0, 2 => 1', [
        style({ transform: 'translateX(-10%)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
})
export class SnapshotCreateEditFormSimpleComponent implements OnInit,OnDestroy,OnChanges {

  snapshotForm: FormGroup;
  @Output() submit = new EventEmitter<SnapshotRequestData>();
  @Output() submitFormStatus = new EventEmitter<FormValidStatus>();
  @Input() disabled : boolean;
  @Input() hideCultureInputs : boolean;
  @Input() loading : boolean;
  @Input() error : string;
  @Input() snapshot : Snapshot;
  allCultures: Culture[]; // original list from backend
  selectedCulture: Culture;
  selectedCultures: Culture[] = [];
  primary_culture_id: number;
  showChoosePrimaryCultureOptions: boolean = false;
  private subscriptions: Subscription[] = [];
  exampleCurrentIndex: number = 0;
  exampleCarouselInterval: any;
  formCachingKey: string = 'snapshot_create_edit';

  exampleSnapshotContent : {title:string,description:string,cultureIsos:string[],cultures:Culture[]}[] = [];

  constructor(private cultureService: CultureService, private mediaService : MediaService, private formCachingService: FormCachingService) { 
    this.exampleSnapshotContent = [
      {title:'survey.snapshot_context_title_example0',description:'survey.snapshot_context_description_example0',cultureIsos:['VN','TH'],cultures:[]},
      {title:'survey.snapshot_context_title_example1',description:'survey.snapshot_context_description_example1',cultureIsos:['GH','NG'],cultures:[]},
      {title:'survey.snapshot_context_title_example2',description:'survey.snapshot_context_description_example2',cultureIsos:['PE','EC','US'],cultures:[]},
      {title:'survey.snapshot_context_title_example3',description:'survey.snapshot_context_description_example3',cultureIsos:['DE','JP'],cultures:[]},
    ]
  }
  populateExampleSnapshotContent(){
    this.exampleSnapshotContent.forEach(example => {
      example.cultures = this.allCultures.filter(c => example.cultureIsos.includes(c.iso));
    });
  }

  startCarousel() {
    this.exampleCarouselInterval = setInterval(() => {
      this.exampleCurrentIndex = (this.exampleCurrentIndex + 1) % this.exampleSnapshotContent.length;
    }, 6000);
  }

  filterCultures(allCultures:Culture[],category:string,type:string){
    return allCultures?.length ? allCultures.filter(c=>c.category===category && c.type===type) : [];
  }

  updateCulturesFormControl (){
    this.snapshotForm.get('cultures').patchValue(this.selectedCultures.length ? 'not-empty':'');
  }

  selectCulture (culture: Culture){
    this.selectedCultures.push(culture);
    this.updateCulturesFormControl();
  }
  deselectCulture (culture: Culture){
    this.selectedCultures = this.selectedCultures.filter(c=>c.id !== culture.id);
    this.updateCulturesFormControl();
    if(this.primary_culture_id === culture?.id || this.selectedCultures.length < 2){
      this.primary_culture_id = null;
      this.showChoosePrimaryCultureOptions = false;
    }
  }
  doSubmitFormStatus(){ // needed by the parent Stepper component to determine whether user can progress to next step
    this.submitFormStatus.emit({
      title: this.snapshotForm.controls.title.valid,
      description: this.snapshotForm.controls.description.valid,
      cultures: this.snapshotForm.controls.cultures.valid,
    })
  }
  cacheFormData(){
    this.formCachingService.cacheDataItem({
      title: this.snapshotForm.controls.title.value,
      description: this.snapshotForm.controls.description.value,
      cultures: this.selectedCultures, // NOT: this.snapshotForm.controls.cultures.value,
    },this.formCachingKey);
  }

  submitData (){
    if (this.showChoosePrimaryCultureOptions === true || this.selectedCultures.length < 2 || this.primary_culture_id === 0 || this.primary_culture_id){
      this.submit.emit({
        title:this.snapshotForm.controls.title.value,
        description:this.snapshotForm.controls.description.value,
        cultures:this.selectedCultures,
        primary_culture_id:this.primary_culture_id
      })
    } else {
      this.showChoosePrimaryCultureOptions = true;
    }
  }
  populatePreSelectedCultures (cultures : Culture[]){
    if(cultures?.length){
      cultures.forEach(c=>{
        this.selectCulture(c);
      });
      // this.selectedCultures = this.snapshot.cultures;
      // this.updateCulturesFormControl();
    }
  }
  getFlagUrl(culture:Culture,transformations:string, file_extension:string){
    return this.mediaService.getFlagUrlFromHash(culture?.flag.hash,transformations,true,file_extension);
  }
  setPrimaryCulture(culture:Culture){
    if (!culture || (culture && this.primary_culture_id === culture.id)){
      this.primary_culture_id = 0;
      return;
    }
    this.primary_culture_id = culture.id;
  }

  ngOnInit(): void {

    let cachedFormData = this.formCachingService.getDataItem(this.formCachingKey);

    let prepopulatedTitle : string = this.snapshot?.title ?? cachedFormData?.title ?? null;
    let prepopulatedDescription : string = this.snapshot?.description ?? cachedFormData?.description ?? null;

    this.snapshotForm = new FormGroup({
      title: new FormControl(prepopulatedTitle, [Validators.required, Validators.minLength(10), Validators.maxLength(75)]),
      description: new FormControl(prepopulatedDescription, [Validators.required, Validators.minLength(20), Validators.maxLength(200)]),
      cultures: new FormControl(null, [Validators.required]),
    });

    const titleChanges = this.snapshotForm.controls.title.valueChanges;
    const descriptionChanges = this.snapshotForm.controls.description.valueChanges;
    const culturesChanges = this.snapshotForm.controls.cultures.valueChanges;

    const formStatusSubscription : Subscription = merge(titleChanges,descriptionChanges,culturesChanges).subscribe(() => {this.doSubmitFormStatus()});
    this.subscriptions.push(formStatusSubscription);

    const formCachingSubscription : Subscription = merge(titleChanges,descriptionChanges,culturesChanges).pipe(
      debounceTime(3000)
    ).subscribe(() => {this.cacheFormData()});
    this.subscriptions.push(formCachingSubscription);
    
    this.loading = true;
    const nationalCultureSubscription = this.cultureService.getCultures('geographic','national',false).subscribe((cultures : Culture[])=>{
      this.allCultures = cultures;
      if (this.snapshot?.cultures?.length){
        this.populatePreSelectedCultures(this.snapshot.cultures);
      } else if (cachedFormData?.cultures?.length){
        this.populatePreSelectedCultures(cachedFormData.cultures);
      }
      this.populateExampleSnapshotContent();
      this.startCarousel();
      this.loading = false;
    },
    error => this.loading = false
    );
    this.subscriptions.push(nationalCultureSubscription);
  }

  ngOnChanges(changesObject){
    if(changesObject.snapshot?.currentValue){
      this.primary_culture_id = changesObject.snapshot.currentValue.primary_culture_id;
      if(changesObject.snapshot.currentValue.primary_culture_id)
      this.showChoosePrimaryCultureOptions = true;
    }
  }

  ngOnDestroy (){
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    clearInterval(this.exampleCarouselInterval);
  }
}
