<!-- Just add class "container" for CultureQuestion next to the class "main-breadcrumb" -->
<nav
  id="breadcrumb"
  aria-label="breadcrumb"
  class="main-breadcrumb"
  [ngClass]="{'container': breadcrumbFixedWidth}"
  *ngIf="breadcrumbs?.length"
>
  <!-- <h1 *ngIf="pageTitle; else preventsJumpiness" class="page-title">
    {{pageTitle}}
  </h1><ng-template #preventsJumpiness><h1 class="opacity-0">|</h1></ng-template> -->
  <h1 class="page-title" *transloco="let t">
    <span *ngIf="pageTitle">{{pageTitle}}</span>
    <span *ngIf="!pageTitle && pageTitleTranslationKey">{{t(pageTitleTranslationKey)}}</span>
    <span *ngIf="!pageTitle && !pageTitleTranslationKey" class="opacity-0">...</span>
  </h1>
  <!-- DEBUGGING: <ul><li *ngFor="let b of breadcrumbs">{{b | json}}</li></ul> -->
  <ol class="breadcrumb" *transloco="let t">
    <li *ngIf="breadcrumbs?.length" class="breadcrumb-item"><a [routerLink]="['/']">
      <span>{{t('common.home')}}</span>
      <!-- <i class="fa-solid fa-house"></i> -->
    </a></li>
    <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; index as i">
      <span *ngIf="i === breadcrumbs.length-1 ; else notTheLastOne">{{
        breadcrumb.labelSource === 'titleService'
          ? pageTitle : breadcrumb.translationKey
          ? t(breadcrumb.translationKey) 
          :  breadcrumb.translationKey
          ? t(breadcrumb.translationKey)
          : breadcrumb.label
        }}</span>
      <ng-template #notTheLastOne>
        <a
        [routerLink]="breadcrumb.url"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <span>{{
          breadcrumb.labelSource === 'titleService'
            ? breadcrumb?.paramName && getFragmentName(breadcrumb.paramName) : getFragmentName(breadcrumb.paramName)
            ? t(breadcrumb.translationKey)
            : breadcrumb.translationKey
            ? t(breadcrumb.translationKey) : breadcrumb.label
        }}</span>
      </a>
      </ng-template>
    </li>
  </ol>
  <!-- <div>
    <p *ngFor="let breadcrumb of breadcrumbs">source:{{breadcrumb.labelSource}} | label: {{breadcrumb.label}} | key: {{breadcrumb.translationKey}}<br>-----------------</p>
  </div> -->
</nav>
