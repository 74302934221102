import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkTypeFourComponent } from './link-type-four.component';
import { ImagesModule } from '../../images';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [LinkTypeFourComponent],
  exports: [LinkTypeFourComponent],
  imports: [
    CommonModule,
    ImagesModule,
    TranslocoModule
  ]
})
export class LinkTypeFourModule { }
