import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'ttt-design08',
  templateUrl: './design08.component.html',
  styleUrls: ['./design08.component.scss']
})
export class Design08Component implements OnInit {

  constructor() { }

  isCollapsed = false;
  
  ngOnInit(): void {
  }

}
