import { Culture } from "../../cultures";
import { CloudinaryMediaAsset } from "../../models";

export class Snapshot {

    public id : number;
    public title : string;
    public description : string;
    public label : string; // like the title, but this was probably created by the trainer. translatable
    public info : string; // like the decription, but this was probably created by the trainer. translatable
    public cultures : Culture[];
    public date : Date;
    public cohort_id : number;
    public event_id : number;
    public media : CloudinaryMediaAsset;
    public surveys: {name:string,slug:string}[];
    public current_accepted?: boolean; // for the current user this is accepted and not yet archived
    public primary_culture_id?: number;
}