<div class="container mb-5" *transloco="let t">
  <!-- Editable title component -->
  <div>
    <multisite-loader-globe-animation-detail *ngIf="!hub && loading"
    [width]="300" [height]="300"></multisite-loader-globe-animation-detail>
  </div>
  <div class="title-holder" *ngIf="hub">
    <div class="editable-title">
      <h1 class="title">
        <span *ngIf="!nameEditable">{{hub.name}}</span>
        <button type="button" class="btn btn-sm btn-link p-0" (click)="toggleNameEditState()" [popover]="t('common.edit')"  *ngIf="!nameEditable"
        triggers="mouseenter:mouseleave"><i class="fa-solid fa-pen mr-2"></i></button>
      </h1>
      <form class="w-100 d-flex flex-row align-items-center mb-1" [formGroup]="hubNameForm" *ngIf="nameEditable">
        <div class="input-group-button w-100">
          <input
          
          class="form-control "
          type="text"
          formControlName="name"
          id="name"
          [placeholder]="t('hubs.name')"
          [disabled]="loading"
          
          aria-label="Hub name edit" aria-describedby="hub-name-addon2" #hubNameInput>
          <div class="input-group-append">
            <button class="btn btn-sm btn-primary primary-dark" [disabled]="hubNameForm.get('name').invalid" (click)="updateManagedHub(hubNameForm.controls.name.value)" type="button" id="hub-name-addon2">{{t('common.save')}}</button>
          </div>
        </div>
        <button class="btn btn-sm btn-danger ml-2" (click)="toggleNameEditState()" type="button" id="hub-name-addon2">
          <i class="fa-solid fa-xmark"></i>
          <!-- {{t('common.cancel')}} -->
        </button>
        <multisite-loader-globe-animation-detail *ngIf="loading" height="40" width="40" class="d-block-inline"></multisite-loader-globe-animation-detail>
      </form>
    </div>
  </div>
  <!-- END OF Editable title component -->

  <!-- Alert -->

  <div *ngIf="!alertsDismissed">
    <alert [type]="masterTask.status" [dismissible]="alertsCollapsed" (onClosed)="dismissTaskAlert()" *ngIf="tasks?.length > 1">
      <div class="d-flex justify-content-between"><span>{{t(masterTask.alert_text_translation_key)}}&nbsp;{{tasks.length}}</span>
         <a (click)="alertsCollapsed = !alertsCollapsed" class="text-nowrap link-primary-dark fw-bold d-flex align-items-center gap-2">{{
          alertsCollapsed
            ? t('common.show_more')
            : t('common.show_less')
        }} 
        <span *ngIf="alertsCollapsed"><i class="fa-solid fa-angle-down"></i></span>
        <span *ngIf="!alertsCollapsed"><i class="fa-solid fa-angle-up"></i></span>
      </a>
      </div>
    </alert>
    <div *ngFor="let alert of tasks" [collapse]="alertsCollapsed" [isAnimated]="true">
      <alert [type]="alert.status" [dismissible]="tasks?.length === 1" (onClosed)="dismissTaskAlert()">
        <div class="d-flex justify-content-between">
          <strong>{{t(alert.alert_category_label_translation_key)}}</strong>
          <span *ngIf="alert.alert_text_interpolated_variable || alert.alert_text_interpolated_variable === 0">{{t(alert.alert_text_translation_key, {variable  : alert.alert_text_interpolated_variable})}}</span>
          <span *ngIf="!alert.alert_text_interpolated_variable && alert.alert_text_interpolated_variable !== 0">{{t(alert.alert_text_translation_key)}}</span>
          <a [routerLink]="alert.route" class="text-nowrap link-primary-dark fw-bold d-flex align-items-center gap-2">{{t(alert.link_label_translation_key)}} <i class="fa-solid fa-angle-right"></i></a>
        </div>
      </alert>
    </div>
    <alert [type]="'warning'" *ngIf="error" [dismissOnTimeout]="3500" ><span>{{t(error)}}</span>
  </alert>
  </div>
  <!-- END OF Alert -->

  <!-- I used Bootstrap to create layout for those cards -->
  <div class="row gap-lg-0 gap-4" *ngIf="hub">
    <div class="col-lg-4">
      <!-- Row horizontal card -->
      <div class="row-horizontal-card">
        <div class="card">
          <div class="card-body">
            <p class="card-name">
              <i class="fa-solid fa-tag mr-1"></i>
              Type
            </p>
            <div class="card-value">
              {{hub.type}}
            </div>
          </div>
        </div>
      </div>
      <!-- END OF Row horizontal card -->
    </div>
    <div class="col-lg-4">
      <!-- Row horizontal card -->
      <div class="row-horizontal-card">
        <div class="card clickable" [routerLink]="'roles'">
          <div class="card-body">
            <p class="card-name">
              <i class="fa-solid fa-user-tie mr-1"></i>
              {{hub.managers.length===1 ? t('common.owner'):t('common.owners')}}
            </p>
            <div class="card-value">
              <multisite-avatar-pill *ngIf="hub?.owners?.[0]"
                [fallbackImageUrl]="'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'"
                [fallbackTranslationKey]="'social.verified_user'" [imageUrl]="hub.owners[0].picture" [text]="hub.owners[0].pre_honorific+' '+hub.owners[0].fname+' '+hub.owners[0].lname+'
            '+hub.owners[0].post_honorific"></multisite-avatar-pill>
            </div>
          </div>
        </div>
      </div>
      <!-- END OF Row horizontal card -->
    </div>
    <div class="col-lg-4">
      <!-- Row horizontal card -->
      <div class="row-horizontal-card">
        <div class="card">
          <div class="card-body">
            <p class="card-name">
              <i class="fa-solid fa-fingerprint mr-1"></i>
              Brands
            </p>
            <div class="card-value">
              <button class="btn btn-sm btn-primary" [routerLink]="['marketing/brands']">
                Go to brands <i class="ml-2 fa-solid fa-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END OF Row horizontal card -->
    </div>
  </div>

  <div class="row gap-lg-0 gap-4 mt-4" *ngIf="hub">
    <div class="col-lg-4">
      <!-- Row horizontal card -->
      <div class="row-horizontal-card">
        <div class="card clickable" [routerLink]="'programmes'">
          <div class="card-body">
            <p class="card-name">
              <i class="fa-solid fa-rectangle-list mr-1"></i>
              Programmes
            </p>
            <div class="card-value">
              {{hub.programmes_count}}
            </div>
          </div>
        </div>
      </div>
      <!-- END OF Row horizontal card -->
    </div>
    <div class="col-lg-4">
      <!-- Row horizontal card -->
      <div class="row-horizontal-card">
        <div class="card">
          <div class="card-body">
            <p class="card-name">
              <i class="fa-solid fa-calendar-days mr-1"></i>
              Created
            </p>
            <div class="card-value">
              {{hub.created_at | date : 'longDate'}}
            </div>
          </div>
        </div>
      </div>
      <!-- END OF Row horizontal card -->
    </div>
    <div class="col-lg-4">
      <!-- Row horizontal card -->
      <div class="row-horizontal-card">
        <div class="card">
          <div class="card-body">
            <p class="card-name">
              <i class="fa-solid fa-users mr-1"></i>
              Groups
            </p>
            <div class="card-value">
              {{hub.cohorts?.length}}
            </div>
          </div>
        </div>
      </div>
      <!-- END OF Row horizontal card -->
    </div>
  </div>
  <!-- END OF I used Bootstrap to create layout for those cards -->


  <div *ngIf="isAdmin()">

    <div class="row gap-lg-0 gap-4 mt-4" *ngIf="hub">
      <div class="col-lg-3">
        <!-- Col vcertical card -->
        <div class="col-vertical-card">
          <div class="card">
            <div class="card-body">
              <div class="card-icon">
                <i class="fa-solid fa-chart-pie"></i>
              </div>
              <div class="content-holder">
                <p class="card-name">
                  TASK COMPLETED
                </p>
                <div class="card-value">
                  32%
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END OF Col vcertical card -->
      </div>
      <div class="col-lg-3">
        <!-- Col vcertical card -->
        <div class="col-vertical-card">
          <div class="card clickable">
            <div class="card-body">
              <div class="card-icon">
                <i class="fa-solid fa-fire"></i>
              </div>
              <div class="content-holder">
                <p class="card-name">
                  NEW USERS <br>
                  ( This week )
                </p>
                <div class="card-value">
                  5
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END OF Col vcertical card -->
      </div>
      <div class="col-lg-3">
        <!-- Col vcertical card -->
        <div class="col-vertical-card">
          <div class="card">
            <div class="card-body">
              <div class="card-icon">
                <i class="fa-solid fa-user-plus"></i>
              </div>
              <div class="content-holder">
                <p class="card-name">
                  MOST-ACTIVE TRAINER
                </p>
                <div class="card-value">
                  <multisite-avatar-pill *ngIf="hub?.owners?.[0]"
                  [fallbackImageUrl]="'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'"
                  [fallbackTranslationKey]="'social.verified_user'" [imageUrl]="hub.owners[0].picture" [text]="hub.owners[0].pre_honorific+' '+hub.owners[0].fname+' '+hub.owners[0].lname+'
              '+hub.owners[0].post_honorific"></multisite-avatar-pill>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END OF Col vcertical card -->
      </div>
      <div class="col-lg-3">
        <!-- Col vcertical card -->
        <div class="col-vertical-card">
          <div class="card">
            <div class="card-body">
              <div class="card-icon">
                <i class="fa-solid fa-trophy"></i>
              </div>
              <div class="content-holder">
                <p class="card-name">
                  LATEST REVIEW
                </p>
                <div class="card-value">
                  5 <i class="fa-solid fa-star"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END OF Col vcertical card -->
      </div>
    </div>
  </div>



  <div class="row gap-lg-0 gap-4 mt-4" *ngIf="hub">
    <div class="col-lg-4">

      <multisite-left-bordered-card
        [title_text_translation_key]="'common.roles'"
        [button_link_route]="['roles']"
        [button_text_translation_key]="'common.manage'"
        [card_link_route]="['roles']"
        [card_clickable]="true"
        [statusItems]="statusItemsObject['roles']"
        [disabled]="loading"
      ></multisite-left-bordered-card>

    </div>
    <div class="col-lg-4">
      <multisite-left-bordered-card
      [title_text_translation_key]="'navigation.marketing'"
      [button_link_route]="['marketing']"
      [button_text_translation_key]="'common.manage'"
      [card_link_route]="['marketing']"
      [card_clickable]="true"
      [statusItems]="statusItemsObject['marketing']"
      [disabled]="loading"
    ></multisite-left-bordered-card>
    </div>
    <div class="col-lg-4">
      <multisite-left-bordered-card
      [title_text_translation_key]="'platform.cco'"
      [button_link_route]="['cultureconnector']"
      [button_text_translation_key]="'common.manage'"
      [card_link_route]="['cultureconnector']"
      [card_clickable]="true"
      [statusItems]="statusItemsObject['cultureconnector']"
      [disabled]="loading"
    ></multisite-left-bordered-card>
    </div>
  </div>


  <div *ngIf="isAdmin()">
    <hr class="my-4">

    <div class="row gap-lg-0 gap-4 mt-4" *ngIf="hub">
      <div class="col-lg-4">
        <!-- Blue bordered card -->
        <div class="left-bordered-card">
          <div class="card">
            <div class="card-body">
              <div class="top-part">
                <h5 class="card-title">Roles</h5>
                <h6 class="card-subtitle mt-3 mb-2 fw-bold">Settings:</h6>
                <div class="status-list">
                  <div class="list-item"><i class="fa-regular fa-circle"></i> Hub owner</div>
                  <div class="list-item"><i class="fa-regular fa-circle"></i> Hub managers</div>
                  <div class="list-item"><i class="fa-regular fa-circle"></i> Contact people</div>
                  <div class="list-item"><i class="fa-regular fa-circle"></i> Trainers</div>
                </div>
              </div>
              <button type="button" class="btn btn-sm btn-primary primary-dark" [routerLink]="['roles']">Manage <i
                  class="fa-solid fa-chevron-right ml-2"></i></button>
            </div>
          </div>
        </div>
        <!-- END OF Blue bordered card -->
      </div>
      <div class="col-lg-4">
        <!-- Blue bordered card -->
        <div class="left-bordered-card">
          <div class="card">
            <div class="card-body">
              <div class="top-part">
                <h5 class="card-title">Marketing</h5>
                <h6 class="card-subtitle mt-3 mb-2 fw-bold">Settings:</h6>
                <div class="status-list">
                  <div class="list-item"><i class="fa-solid fa-check"></i> Slogans</div>
                  <div class="list-item ng-invalid"><i class="fa-solid fa-xmark"></i> Descriptions</div>
                  <div class="list-item"><i class="fa-solid fa-check"></i> Banners and other images</div>
                  <div class="list-item"><i class="fa-solid fa-check"></i> Brands</div>
                  <div class="list-item"><i class="fa-solid fa-check"></i> Competences</div>
                </div>
              </div>
              <button type="button" class="btn btn-sm btn-primary primary-dark" [routerLink]="['marketing']">Manage <i
                  class="fa-solid fa-chevron-right ml-2"></i></button>
            </div>
          </div>
        </div>
        <!-- END OF Blue bordered card -->
      </div>
      <div class="col-lg-4">
        <!-- Blue bordered card -->
        <div class="left-bordered-card">
          <div class="card">
            <div class="card-body">
              <div class="top-part">
                <h5 class="card-title">Clients</h5>
                <h6 class="card-subtitle mt-3 mb-2 fw-bold">Settings:</h6>
                <div class="status-list">
                  <div class="list-item ng-invalid"><i class="fa-solid fa-xmark"></i> Customers</div>
                  <div class="list-item ng-warning"><i class="fa-solid fa-triangle-exclamation"></i> Classes</div>
                  <div class="list-item"><i class="fa-solid fa-check"></i> Conference participants</div>
                  <div class="list-item"><i class="fa-solid fa-triangle-exclamation"></i> Unknown visitors</div>
                  <div class="list-item"><i class="fa-solid fa-check"></i> etc</div>
                </div>
              </div>
              <button type="button" class="btn btn-sm btn-primary primary-dark">Manage <i
                  class="fa-solid fa-chevron-right ml-2"></i></button>
            </div>
          </div>
        </div>
        <!-- END OF Blue bordered card -->
      </div>
    </div>

    <div class="row gap-lg-0 gap-4 mt-4" *ngIf="hub">
      <div class="col-lg-4">
        <!-- Blue bordered card -->
        <div class="left-bordered-card">
          <div class="card">
            <div class="card-body">
              <div class="top-part">
                <h5 class="card-title">Events</h5>
                <h6 class="card-subtitle mt-3 mb-2 fw-bold">Settings:</h6>
                <div class="status-list">
                  <div class="list-item ng-valid"><i class="fa-solid fa-check"></i> Scheduling</div>
                  <div class="list-item"><i class="fa-solid fa-check"></i> Access codes</div>
                  <div class="list-item"><i class="fa-solid fa-check"></i> Activities</div>
                  <div class="list-item"><i class="fa-solid fa-check"></i> Participants</div>
                </div>
              </div>
              <button type="button" class="btn btn-sm btn-primary primary-dark">Manage <i
                  class="fa-solid fa-chevron-right ml-2"></i></button>
            </div>
          </div>
        </div>
        <!-- END OF Blue bordered card -->
      </div>
      <div class="col-lg-4">
        <!-- Blue bordered card -->
        <div class="left-bordered-card">
          <div class="card">
            <div class="card-body">
              <div class="top-part">
                <h5 class="card-title">Programmes</h5>
                <h6 class="card-subtitle mt-3 mb-2 fw-bold">Settings:</h6>
                <div class="status-list">
                  <div class="list-item"><i class="fa-solid fa-check"></i> Scheduling</div>
                  <div class="list-item ng-info"><i class="fa-solid fa-circle-info"></i> Trainers</div>
                  <div class="list-item"><i class="fa-solid fa-check"></i> Content</div>
                  <div class="list-item ng-info"><i class="fa-solid fa-circle-info"></i> Participants</div>
                </div>
              </div>
              <button type="button" class="btn btn-sm btn-primary primary-dark" [routerLink]="['marketing']">Manage <i
                  class="fa-solid fa-chevron-right ml-2"></i></button>
            </div>
          </div>
        </div>
        <!-- END OF Blue bordered card -->
      </div>
      <div class="col-lg-4">
        <!-- Blue bordered card -->
        <div class="left-bordered-card">
          <div class="card">
            <div class="card-body">
              <div class="top-part">
                <h5 class="card-title">Reviews</h5>
                <h6 class="card-subtitle mt-3 mb-2 fw-bold">Settings:</h6>
                <div class="status-list">
                  <div class="list-item"><i class="fa-solid fa-check"></i> Get feedback</div>
                  <div class="list-item"><i class="fa-solid fa-check"></i> Translate reviews</div>
                  <div class="list-item"><i class="fa-solid fa-check"></i> Boost reviews</div>
                </div>
              </div>
              <button type="button" class="btn btn-sm btn-primary primary-dark">Manage <i
                  class="fa-solid fa-chevron-right ml-2"></i></button>
            </div>
          </div>
        </div>
        <!-- END OF Blue bordered card -->
      </div>
    </div>
  </div>

  <p *ngIf="!loading && !hub">Could not load this hub</p>
</div>