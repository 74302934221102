import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListItemIconComponent } from './list-item-icon/list-item-icon.component';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    ListItemIconComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ],
  exports: [
    ListItemIconComponent
  ],
})
export class ListItemModule { }
