import { Injectable } from "@angular/core";
import { Title } from '@angular/platform-browser';

import { TranslocoService } from "@jsverse/transloco";
import { Observable, BehaviorSubject } from "rxjs";


@Injectable({providedIn:'root'})
export class PageTitleService {

    // THIS SERVICE HAS A DEPENDENCY !!! see setTitle note.

    private currentTitleSubject: BehaviorSubject<string>;
    public currentTitle: Observable<string>;
    subscribedTranslation : Observable<string>;
    translocoService: TranslocoService;
    angularTitleService: Title;

    constructor(
        angularTitleService: Title,
        translocoService: TranslocoService,

        ) {
            this.currentTitleSubject = new BehaviorSubject<string>('Starting...');
            this.currentTitle = this.currentTitleSubject.asObservable();
            this.translocoService = translocoService;
            this.angularTitleService = angularTitleService;
        }
        
    setTitle(title){ // Note: the breadcrumb component is setting the title to null after every navigation, because that component is subscribed to Navigation events. If we have no breadcrumb component, the title could be invalid
        this.angularTitleService.setTitle(title);
        this.currentTitleSubject.next(title);
    }; 
    getTitle() : string {
        return this.angularTitleService.getTitle();
    }; 
    setTitleWithTranslationFirstConcatenation (translation, text, scope){
        this.subscribedTranslation = this.translocoService
            .selectTranslate(translation, null, scope);
        this.subscribedTranslation.subscribe((value) => {
            this.setTitle(text ? value+text : value);  
            
        });
    }
};