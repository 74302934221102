import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { BrandDisplayService } from '../brand-display.service';
import { BrandEditingService } from '../brand-editing.service';
import { Brand } from '../brand.model';
import { DefaultBrandConstants } from '../default-brand.constants';
import { BrandColour, BrandColourSwatch } from '@frontend/common';

@Component({
  selector: 'multisite-brand-colour-editor',
  templateUrl: './brand-colour-editor.component.html',
  styleUrls: ['./brand-colour-editor.component.scss']
})
export class BrandColourEditorComponent implements OnInit,OnChanges {

  @Input () brand : Brand;
  @Input () draftBrand : Brand;
  defaultBrand : Brand;
  savedBrandColourVariants : string[];
  draftBrandPalette : BrandColourSwatch[];
  savedBrandPalette : BrandColourSwatch[];
  defaultBrandPalette : BrandColourSwatch[];
  @Input() draftBrandEditMode: string; // 'easy' or 'advanced' or 'expert'
  @Output() changeDraftBrandEditMode = new EventEmitter<string>(); // 'easy' or 'advanced' or 'expert'
  @Output() onChangedDraftBrand = new EventEmitter<Brand>();
  defaultBrandConstants : any;
  subdomain: string; // mycompany
  url: string; // culturettt.com
  loading : boolean;
  error : string;

  constructor(
    private brandEditingService : BrandEditingService,
    private brandDisplayService : BrandDisplayService,
    ) { 
      this.defaultBrandConstants = DefaultBrandConstants;
  }

  generatePalette (brand : Brand, type : string) : BrandColourSwatch[] {

    let swatches : BrandColourSwatch[] = [];
    brand.colours.forEach((c,index,array) => {
      let swatch : BrandColourSwatch;
      if (!c?.name){
        debugger;
      }
      if (c.name.indexOf('shadow')== -1){
        swatch = new BrandColourSwatch(
          'branding.'+c.name.replace('-',''),
          c.name,
          c.hex,
          c.name === 'link' ? c.hex : (c.name.indexOf('-light')>-1) ? '#8b8888' : '#FFFFFF', // set the text colour
          'branding.shadow',
          array.find(colour=>colour.name === c.name+'-shadow').hex,
          this.defaultBrandConstants.baseColourKeys.indexOf(c.name) > -1 ? true:false
          );
          swatches.push(swatch);
          if (c.name == 'link'){
            // this.renderer.setProperty(this.nativeElement,'--'+type+'-brand-link',c.hex); // TODO scope the styles to this component: see .scss file comment; need a reference to this component (this.nativeElement does not work)
            this.brandDisplayService.applyStyle('--'+type+'-brand-link',c.hex);
          }
        } else if (c.name.indexOf('link')===0){
          this.brandDisplayService.applyStyle('--'+type+'-brand-link-hover',c.hex);

      }
    })

    return swatches;
  }

  refreshColour (params : {swatch : BrandColourSwatch, variant : string}){
    let changedDraftBrand : Brand;
    if (this.draftBrandEditMode === 'expert'){
      let name : string, hex: string;
      if (params.variant === 'shadow'){
        name = params.swatch.colourKey+'-shadow';
        hex = params.swatch.shadowHex;
      } else {
        name = params.swatch.colourKey;
        hex = params.swatch.hex;
      }
      changedDraftBrand = this.refreshColourAndVariants([new BrandColour(name,hex)]);
    } else {
      let colourAndvariants = this.brandDisplayService.getColourVariants (params.swatch.colourKey,params.swatch.hex);
      changedDraftBrand = this.refreshColourAndVariants(colourAndvariants);
    }
    if (changedDraftBrand){
      this.onChangedDraftBrand.emit(changedDraftBrand);
    }
  }
  refreshColourAndVariants (colourAndvariants : BrandColour[]){
    this.draftBrand = this.brandEditingService.applyNewVariantsToBrand (this.draftBrand, colourAndvariants, this.draftBrandEditMode === 'easy');
    if (this.draftBrand){
      this.draftBrandPalette = this.generatePalette (this.draftBrand, 'draft');
    };
    return this.draftBrand;
  }
  setDraftEditingMode (event: Event){
    const selectedOption = (event.target as HTMLSelectElement).value;
    this.changeDraftBrandEditMode.emit(selectedOption);
  }

  getUrl (){
    if (window.location.host.split('.').length>1){
      this.subdomain = window.location.host.split('.')[0];
      this.url = window.location.host.split('.')[1];
    };
  }

  handleServerErrors (error){
    this.loading = false;
    this.error = error;
  }

  ngOnInit(): void {

    this.defaultBrand = this.brandDisplayService.getNewDraftBrand ();
    this.defaultBrandPalette = this.generatePalette (this.defaultBrand, 'default');
    if (this.brand){
      this.savedBrandPalette = this.generatePalette(this.brand,'saved');
    }
    if (this.draftBrand && !this.draftBrandPalette){
      this.draftBrandPalette = this.generatePalette(this.draftBrand,'draft');
    }
  }
  ngOnChanges(changesObject): void {

    if (changesObject.draftBrand?.currentValue && !changesObject.draftBrand.previousValue){
      this.draftBrandPalette = this.generatePalette(this.draftBrand,'draft');
    }
  }

}
