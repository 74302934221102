<alert [type]="'warning'" [dismissOnTimeout]="3500" *ngIf="error"><span *transloco="let t">{{t(error)}}</span></alert>


<div class="color-list mt-4">
  <div *transloco="let t">

    <div class="d-flex flex-row justify-content-between" *ngIf="draftBrand">
      <span>&nbsp;</span>
      <select class="select-height" aria-label="Default select example" (change)="setDraftEditingMode($event)">
        <option value="easy" [selected]="draftBrandEditMode==='easy'">{{t('branding.easy_editing')}}</option>
        <option value="advanced" [selected]="draftBrandEditMode==='advanced'">
          {{t('branding.advanced_editing')}}</option>
        <option value="expert" [selected]="draftBrandEditMode==='expert'">{{t('branding.expert_editing')}}
        </option>
      </select>
    </div>

    <div *ngIf="draftBrand && draftBrandPalette">

      <div *ngIf="draftBrandEditMode == 'easy' && draftBrandPalette[1]">
        <div class="simple-color-picker my-2">
          <div class="card">
            <div class="card-body">
              <div class="header">
                <h5 class="card-title font-weight-bold">
                  {{t('branding.set_primary_colour')}}
                </h5>
                <h6 class="card-subtitle">
                  {{t('branding.click_coloured_shape')}}
                </h6>
              </div>
              <div class="color-picker">
                <input type="color" [(ngModel)]="draftBrandPalette[1].hex" [disabled]="loading" (change)="
                    refreshColour({
                      swatch: draftBrandPalette[1],
                      variant: null
                    })
                  " class="color-picker-square" aria-label="Coloured square for choosing a colour" />
                <!-- <p class="color-name">
                  Primary
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="color-list my-2">
        <h4 class="font-weight-bold">{{ t('common.draft_changes') }}</h4>
        <multisite-colour-swatches [swatches]="draftBrandPalette" [type]="'draft'" [editMode]="draftBrandEditMode"
          [showHexCodes]="draftBrandEditMode !== 'easy'" (swatchChange)="refreshColour($event)">
        </multisite-colour-swatches>
      </div>
    </div>

    <div class="color-list my-4" *ngIf="savedBrandPalette">
      <h4 class="font-weight-bold">{{ t('branding.saved_brand') }} <small *ngIf="url"
          class="text-muted ml-1">{{brand.slug+'.'+url}}</small></h4>
      <div class="d-flex">
        <multisite-colour-swatches [swatches]="savedBrandPalette" [type]="'saved'"
          [showHexCodes]="draftBrandEditMode !== 'easy'">
        </multisite-colour-swatches>
      </div>

    </div>

    <div class="color-list mt-4" *ngIf="defaultBrandPalette">
      <h4 class="font-weight-bold">{{ t('branding.default_brand') }}</h4>

      <p>{{ t('branding.default_brand_description') }}</p>
      <div class="d-flex my-3">
        <multisite-colour-swatches [swatches]="defaultBrandPalette" [type]="'default'"
          [showHexCodes]="draftBrandEditMode !== 'easy'">
        </multisite-colour-swatches>
      </div>
    </div>
  </div>
</div>