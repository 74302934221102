import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthConstants, AuthService } from '../../auth'
import { filter, Subscription } from 'rxjs';
import { User } from '../../auth';

@Component({
  selector: 'multisite-profile-picture-settings',
  templateUrl: './profile-picture-settings.component.html',
  styleUrls: ['./profile-picture-settings.component.scss']
})
export class ProfilePictureSettingsComponent implements OnInit {

  @Input() user: User;
  @Input() loading: boolean;
  @Input() error: string; // the translationKey
  @Output() changeEmail = new EventEmitter<string>();
  routeSubscription: Subscription;
  showWarningAlert: boolean = false;
  showPasswordSuccessAlert: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

}
