import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';
import { AuthConstants } from '../auth.constants'; 
import { TrackingService } from '../../tracking';



@Component({
  selector: 'multisite-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading: boolean;
  verificationSuccess = false;
  errorMessage: string;
  hideBackendErrorMessage: boolean;
  loginSubscription: Subscription;
  userSubscription: Subscription;
  authConstants : any;
  isAuthenticated: boolean;

  constructor(
    private router: Router,
    private trackingService: TrackingService,
    private authService: AuthService
  ) {
    this.authConstants = AuthConstants;
  }

  navigateTo(target) {
    this.router.navigateByUrl(target);
  }
  clearVerificationSuccess() {
    this.verificationSuccess = false;
    this.authService.setVerificationSuccess(false);
  }
  resetLoginErrorMessages() {
    this.errorMessage = null;
    this.hideBackendErrorMessage = true;
  }
  loginWithEmail() {
    this.clearVerificationSuccess();
    if (!this.loginForm.valid) {
      return;
    }

    this.loading = true;
    this.resetLoginErrorMessages();
    let email = this.loginForm.controls.email.value;
    let password = this.loginForm.controls.password.value;

    this.resetLoginErrorMessages();

    if (!email || !password) {
      return;
    }

    this.loginSubscription = this.authService.login(email, password).subscribe(
      (response) => {
        this.loading = false;
        this.trackingService.clearData(); // guestExperiences (if any) should be imported from the backend to prevent conflicts; we can't just simply continue with the guest's experiences after logging in.
        // this.trackingService.getProgressables().subscribe(); // This possibility does not exist
        // this.trackingService.getTotalProgressCoursesOnly().subscribe(); // We need a general method for getting all progressables after logging in
        // this.trackingService.getProgressables('survey',null).subscribe(); // We need a general method for getting all progressables after logging in; We don't need this in most sites, so only the sites which need this should call this. Refactor
        this.navigateTo(this.authService.getRouteForRedirectAfterAuth());
        
      },
      (errorMessage) => {
        this.hideBackendErrorMessage = false;
        this.errorMessage = errorMessage;
        this.loading = false;
      }
    );
  }
  ngOnInit(): void {
    if (!this.authService.getRouteForRedirectAfterAuth()) {
      this.authService.setRouteForRedirectAfterAuth(['/']);
    }
    this.loading = false;
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
    this.loginForm.controls.email.valueChanges.subscribe((value) => {
      this.hideBackendErrorMessage = true;
    });
    this.loginForm.controls.password.valueChanges.subscribe((value) => {
      this.hideBackendErrorMessage = true;
    });
    this.verificationSuccess = this.authService.getVerificationSuccess();
    this.userSubscription = this.authService.user.subscribe(user => {
      this.isAuthenticated = !!user;
    });
  }
  ngOnDestroy () : void {
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    };
    if (this.loginSubscription){
      this.loginSubscription.unsubscribe();
    };
  }
}
