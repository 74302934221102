<nav aria-label="Page navigation">
  <ul class="pagination pagination-circle {{classes}}">
    <li class="page-item" [ngClass]="{'disabled':meta.current_page == 1 || loading}">
      <a class="page-link" mdbWavesEffect (click)="clickPage(1)">
        <span *transloco="let t">{{ t('COMMON.FIRST') }}</span>
      </a>
    </li>
    <li class="page-item" [ngClass]="{'disabled':meta.current_page == 1 || loading}">
      <a (click)="clickPage(meta.current_page-1)" class="page-link" aria-label="Previous" mdbWavesEffect>
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">
          <span *transloco="let t">{{ t('COMMON.PREVIOUS') }}</span>
        </span>
      </a>
    </li>
    <li *ngFor="let p of pageList" class="page-item" [ngClass]="{'active':meta.current_page==p}">
      <a (click)="clickPage(p)" class="page-link" mdbWavesEffect>{{p}}</a>
    </li>
    <li class="page-item" [ngClass]="{'disabled':meta.current_page == lastPage || loading}">
      <a (click)="clickPage(meta.current_page+1)" class="page-link" aria-label="Next" mdbWavesEffect>
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">
          <span *transloco="let t">{{ t('COMMON.NEXT') }}</span>
        </span>
      </a>
    </li>
    <li class="page-item" [ngClass]="{'disabled':meta.current_page == lastPage || loading }">
      <a class="page-link" mdbWavesEffect (click)="clickPage(lastPage)">
        <span *transloco="let t">{{ t('COMMON.LAST') }}</span>
      </a>
    </li>
  </ul>
</nav>