  <span *transloco="let t">

    <span class="link-box-dark fw-bold" [ngClass]="headingClass">
      <span>
        <i class="{{iconClasses}}"></i>
        <span>{{linkLabelPart1TranslationKey ? t(linkLabelPart1TranslationKey) : linkLabelPart1Text}}</span>
        <span>{{linkLabelPart2TranslationKey ? t(linkLabelPart2TranslationKey) : linkLabelPart2Text}}</span>
        <span>{{linkLabelPart3TranslationKey ? t(linkLabelPart3TranslationKey) : linkLabelPart3Text}}</span>
      </span>  
      <i class="fa-solid fa-angle-right"></i>
    </span>
  </span>