<div class="container">

  <div class="mt-5">

    <multisite-link-type-nine
      [iconClasses]="'fa-solid fa-swatchbook mr-2'"
      [headingClass]="'h5'"
      [linkLabelPart1TranslationKey]="'common.go'"
      [linkLabelPart2Text]="': '"
      [linkLabelPart3TranslationKey]="'hubs.hub_general_profile'"
      [routerLink]="'general'">
    </multisite-link-type-nine>
  </div>


</div>