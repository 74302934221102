  import { Component, OnDestroy, OnInit } from '@angular/core';
  import { ActivatedRoute, Params, Router } from '@angular/router';
  import { AuthService, AvailableLanguage, Experience, LanguageService, MediaService, MetaText, Progressable, Snapshot, SnapshotService, User, WindowService } from '@frontend/common';
  import { ActivityLogService } from '@frontend/core';
  import { catchError, Subscription, tap } from 'rxjs';
  import { Survey } from '../survey.model';
  import { LanguagePreferenceOptions, LanguagePreferences, SurveysService } from '../surveys.service';
  import { TrackingService } from '../../tracking/tracking.service';
  import { Guest } from '../../auth/user/guest.model';
  import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
  
  @Component({
    selector: 'multisite-survey-language-preferences',
    templateUrl: './survey-language-preferences.component.html',
    styleUrls: ['./survey-language-preferences.component.scss']
  })
  export class SurveyLanguagePreferencesComponent implements OnInit, OnDestroy {
  
    loadingObject: {[key:string]:boolean} = {}; // .snapshot .survey .starting .languagePreferenceOptions
    loadingRelatedExperiences: boolean;
    loadedRelatedExperiences: boolean; // to avoid requesting these twice
    started: boolean; // has the current user started the survey already
    survey : Survey;
    userSubscription: Subscription;
    guestSub: Subscription; // existing from storage
    surveySub: Subscription;
    loadingNewGuest: boolean;
    progressablesSubscription: Subscription;
    surveyProgressValue: number;
    user : User = null;
    guest : Guest = null;
    error: string = null;
    error_code: number = null;
    activeLanguageSubscription: Subscription;
    activeLanguageObject : AvailableLanguage;
    activeLanguageObjectForSurvey : AvailableLanguage;
    availableLanguages : AvailableLanguage[];
    form_of_words_array : string[];
    snapshotSubscription: Subscription;
    snapshot: Snapshot;
    languagePreferenceOptions : LanguagePreferenceOptions;
    languagePreferences : LanguagePreferences;
    languagePreferenceOptionsForCurrentSurvey : LanguagePreferenceOptions;
    languagePreferenceOptionsSubscription : Subscription;
    languagePreferencesSubscription : Subscription;
  
    constructor(
      private surveysService : SurveysService,
      private route : ActivatedRoute,
      private router : Router,
      private authService : AuthService,
      private activityLogService : ActivityLogService,
      private breadcrumbService : BreadcrumbService,
      private trackingService: TrackingService,
      private snapshotService : SnapshotService,
      private mediaService : MediaService,
      private languageService: LanguageService,
      private windowService: WindowService,
    ) {
      this.availableLanguages = this.languageService.availableLanguages;
     }
  
     displayError(error:any){
      if (error?.meta?.error_code){
        this.error_code = error.meta.error_code;
        this.error = error.message;
      } else {
        this.error_code = null;
        this.error = error;
      }
     }
     isAnythingLoading(){
      for (let something in this.loadingObject){
        if (this.loadingObject[something]){
          return true;
        }
      }
      if (this.loadingRelatedExperiences){
        return true;
      }
      return false;
     }

    filterInstructions (instructions : MetaText[], type : string){
      return instructions?.length ? instructions.filter(i => i.type === type) : [];
    }

    getFallbackFlagUrl (){
      return this.mediaService.fallback_flag_round_url;
    }

    hasRole(roleName: string) {
      return this.authService.checkRole(roleName); // for example "Admin"
    }

    selectGrammaticalForm(form:string){
      if (!this.activeLanguageObject?.languageKey || this.isAnythingLoading()) {
        return;
      }
      if (this.started){
        this.setLanguagePreferences(form).subscribe();
      } else {
        this.startSurveybySubmittingExperience().subscribe(()=>{
          this.setLanguagePreferences(form).subscribe();
        })
      }
    } 
  
    getRelatedExperiences (){
      if (!this.user && !this.guest){
        // do something or nothing
      } else if (this.survey && !this.loadedRelatedExperiences && !this.loadingRelatedExperiences) {
        this.loadingRelatedExperiences = true;
        this.surveysService.getRelatedExperiences (this.survey.slug,+this.route.snapshot.params['snapshot'])
          .subscribe(
            (response : Experience[]) => {
              this.loadingRelatedExperiences = false;
              this.loadedRelatedExperiences = true;
              this.started = this.surveysService.getBooleanStatusOfSurvey(response,this.survey.id,'started');
            },
            error => {
            this.displayError(error);
            this.loadingRelatedExperiences = false;
            }
          );
      }
    }
    redirectIfSnapshotNotAccepted(snapshot: Snapshot){
      if (this.snapshot && !this.snapshot.current_accepted){
        this.router.navigate(['/snapshots/'+snapshot.id], { relativeTo: this.route });
      }
    }
    getSnapshot(id:number, freshFromServer: boolean){
      this.loadingObject.snapshot = true;
      this.snapshotSubscription = this.snapshotService.getSnapshot(id,false).subscribe((snapshot)=>{
        this.snapshot = snapshot;
        this.redirectIfSnapshotNotAccepted(snapshot);
        this.breadcrumbService.setBreadcrumbFragment({urlFragment:'snapshot',fragmentName:snapshot.title});
        this.loadingObject.snapshot = false;
      }, (error)=>{
            this.displayError(error);
            this.loadingObject.snapshot = false;
      })
    }
  
    getSurvey (slug:string, freshFromServer: boolean){
      this.loadingObject.survey = true;
      this.error = null;
      slug = slug ? slug : this.route.snapshot.params['survey'];
      let activityLogParams = {
        'model':'survey',
        'identifier' : slug,
        'identifier_type' : 'slug',
        'activity_type' : 'viewed',
        'properties' : null
      };
      this.surveysService.getSurvey (slug,freshFromServer)
        .subscribe(
          response => {
            if (response){
              this.survey = response;
              this.getLanguagePreferences(this.survey.id);
              this.activityLogService.log(activityLogParams);
              // this.pageTitleService.setTitle(this.survey.name);
              this.breadcrumbService.setBreadcrumbFragment({urlFragment:'survey',fragmentName:this.survey.name});
              if (this.user || this.guest){
                this.getRelatedExperiences();
              }
              this.filterLanguagePreferenceOptions();
            }
            this.loadingObject.survey = false;
          },
          error => {
            this.displayError(error);
            this.loadingObject.survey = false;
          }
        );
    };
    findCurrentSurveyProgressFromProgressables (progressables : Progressable[]){
      if (!progressables?.length || !this.survey){
        return null;
      }
      return this.trackingService.getProgressFromProgressables(progressables,'survey',this.survey.slug);
    }
    getSurveyProgress (){
      this.loadingObject.progress = true;
      this.progressablesSubscription = this.trackingService.progressables.subscribe( progressables => {
        this.loadingObject.progress = false;
        this.surveyProgressValue = this.findCurrentSurveyProgressFromProgressables(progressables);
      }, error=>{
        this.loadingObject.progress = false;
      })
    }
    goToQuestions (){
      if (this.surveyProgressValue > 99){
        this.router.navigate(['../feedback'], { relativeTo: this.route });
      } else {
        this.router.navigate(['../questions'], { relativeTo: this.route });
      }
    }
    goToInstructions (){
      this.router.navigate(['../instructions'], { relativeTo: this.route });
    }
    goToLogin(){
      this.authService.setRouteForRedirectAfterAuth([window.location.pathname]); // TODO - get route here dynamically instead this hard-coded "quiz"
      this.router.navigate(['/login']);
    }

    startSurveybySubmittingExperience (){
      this.loadingObject.starting = true;
      return this.trackingService.submitExperience('survey',this.survey.id,this.survey.slug,1,'started',null).pipe(
        tap(() => {
          this.loadingObject.starting = false;
          this.getRelatedExperiences()
        }),
        catchError((error) => {
          this.loadingObject.languagePreferences = false;
          this.displayError(error);
          throw error; // Re-throw the error to continue handling it outside the function.
        })
      );
    }
    goToNextScreen(){
      if(this.surveyProgressValue || !this.survey?.instructions?.length){
        this.goToQuestions();
      } else {
        this.goToInstructions();
      }
    }

    goToNextStep(){
      if (!this.activeLanguageObject?.languageKey){return};
      if(!this.languagePreferences?.[this.activeLanguageObject.languageKey]){
        let forms = this.grammaticalFormsArrayForThisSurveyInCurrentLanguage();
        if (forms.length === 1){
            this.startSurveybySubmittingExperience().subscribe(()=>{
              this.setLanguagePreferences(forms[0]).subscribe(()=>{
                this.goToNextScreen();
              })
            })
        } else {
          // This scenario should never happen because in other scenarios we ensure that the selection is done in the template before the user can click "startSurvey"
          this.error = 'error.report_code_support'
          this['error_code']=2;
        }
      } else {
       this.goToNextScreen();
      }
      
    }
    startSurvey (){
      if (!this.activeLanguageObject?.languageKey){return};
      this.error = null;
      this.error_code = null;
      if (this.user || this.guest){
        if (this.started && this.languagePreferences && this.languagePreferences?.[this.activeLanguageObject.languageKey]){
          this.goToQuestions();
        } else {
          this.startSurveybySubmittingExperience().subscribe(()=>{
            this.goToNextStep();
          })
        };
      } else {
        this.goToLogin();
      }
    }
    setActiveLanguage(language : AvailableLanguage){
      if (!this.activeLanguageObject?.languageKey || this.isAnythingLoading()) {
        return;
      }
      this.languageService.setLanguage(language.languageKey);
    }
    availableLanguagesForThisSurvey (): AvailableLanguage[]{
      return this.availableLanguages?.length && this.survey?.meta?.available_languages?.length ? this.availableLanguages.filter(avLang => this.survey.meta.available_languages.map(al=>al.iso).includes(avLang.languageKey)): [];
    }
    grammaticalFormsArrayForThisSurveyInCurrentLanguage (){
      if(!this.survey || !this.activeLanguageObject ){
        return [];
      };
      return this.survey.meta?.available_languages?.find(al=>al.iso === this.activeLanguageObject.languageKey)?.grammatical_forms ?? [];
    }
    filterLanguagePreferenceOptions(){
      if(this.survey && this.languagePreferenceOptions && this.activeLanguageObject ){
        this.languagePreferenceOptionsForCurrentSurvey = {};
        let grammaticalFormsForThisLanguageForThisSurvey = this.survey.meta?.available_languages?.find(al=>al.iso === this.activeLanguageObject.languageKey)?.grammatical_forms; // ['first_person_form_1','first_person_form_2']
        if (grammaticalFormsForThisLanguageForThisSurvey){
          for (let grammatical_form in grammaticalFormsForThisLanguageForThisSurvey){
            let overlap = this.languagePreferenceOptions[grammaticalFormsForThisLanguageForThisSurvey[grammatical_form]];
            if (overlap){
              this.languagePreferenceOptionsForCurrentSurvey[grammaticalFormsForThisLanguageForThisSurvey[grammatical_form]]= overlap;
            }
          }
        };
      }
    }
    getLanguagePreferenceOptions(languageKey:string){
      this.error = null;
      this.error_code = null
      this.loadingObject.languagePreferenceOptions = true;
      this.languagePreferenceOptionsSubscription = this.surveysService.getLanguagePreferenceOptions(languageKey).subscribe(options => {
        this.languagePreferenceOptions = options;
        this.filterLanguagePreferenceOptions();
        this.loadingObject.languagePreferenceOptions = false;
      }, error => {
        this.loadingObject.languagePreferenceOptions = false;
        this.displayError(error);
      });
    }
    setLanguagePreferences(grammatical_form:string){
      this.error = null;
      this.error_code = null
        this.loadingObject.languagePreferences = true;
        return this.surveysService.setLanguagePreferences(this.survey.id,grammatical_form).pipe(
          tap((response) => {
          this.languagePreferences = response;
          this.loadingObject.languagePreferences = false;
        }),
        catchError((error) => {
          this.loadingObject.languagePreferences = false;
          this.displayError(error);
          throw error; // Re-throw the error to continue handling it outside the function.
        })
        );
    }
    getLanguagePreferences(survey_id:number){
      this.error = null;
      this.error_code = null
      this.loadingObject.languagePreferences = true;
      this.languagePreferencesSubscription = this.surveysService.getLanguagePreferences(survey_id).subscribe(response => {
        this.languagePreferences = response;
        this.loadingObject.languagePreferences = false;
      },
      (error)=>{
        this.loadingObject.languagePreferences = false;
        this.displayError(error);
      });
    }
    languagePreferenceOptionsAsArray() {
      return !this.languagePreferenceOptionsForCurrentSurvey ? [] : Object.keys(this.languagePreferenceOptionsForCurrentSurvey).map(key => ({ key, value: this.languagePreferenceOptionsForCurrentSurvey[key] }));
    }
    isPreferredGrammaticalForm(grammatical_form:string){
      return this.activeLanguageObject?.languageKey && this.languagePreferences?.[this.activeLanguageObject.languageKey] === grammatical_form;
    }
    isGrammaticalFormSelected(){
      return this.activeLanguageObject?.languageKey && (this.grammaticalFormsArrayForThisSurveyInCurrentLanguage().length < 2 || (this.grammaticalFormsArrayForThisSurveyInCurrentLanguage().length > 1 && this.languagePreferences?.[this.activeLanguageObject.languageKey]));
    }
  
    ngOnInit(): void {
      this.windowService.goToTop();
      this.userSubscription = this.authService.user.subscribe(user => {
        this.user = user;
        this.getRelatedExperiences();
      });
      this.guestSub = this.authService.guest.subscribe(guest => {
        this.guest = guest;
        this.getRelatedExperiences();
      });
      this.route.params.subscribe(
        (params : Params)=>{
          this.getSurvey (params['survey'], false);
        }
      );
      this.route.params.subscribe(
        (params : Params)=>{
          if (params['snapshot']){
            this.getSnapshot(+params['snapshot'],false);
          }
      });
      this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (activeLanguageObject) => {
        this.activeLanguageObject = activeLanguageObject;
        this.getLanguagePreferenceOptions(activeLanguageObject.languageKey);
        this.activeLanguageObjectForSurvey = activeLanguageObject;

        if (this.survey){
          this.getSurvey(this.survey.slug, true);
        }
        // this.getLanguagePreferenceOptions(this.activeLanguageObject.languageKey); // TODO - remove this line after testing. It duplicates a line in the same code block
      })
      this.getSurveyProgress ();

    }
    ngOnDestroy () {
      if (this.guestSub){
        this.guestSub.unsubscribe();
      };
      if (this.userSubscription){
        this.userSubscription.unsubscribe();
      }
      if (this.surveySub){
        this.surveySub.unsubscribe();
      }
      if (this.progressablesSubscription){
        this.progressablesSubscription.unsubscribe();
      }
      if (this.activeLanguageSubscription){
        this.activeLanguageSubscription.unsubscribe();
      }
      if (this.snapshotSubscription){
        this.snapshotSubscription.unsubscribe();
      }
      if (this.languagePreferenceOptionsSubscription){
        this.languagePreferenceOptionsSubscription.unsubscribe();
      }
      if (this.languagePreferencesSubscription){
        this.languagePreferencesSubscription.unsubscribe();
      }
    }
  
  }
  