import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest, forkJoin } from 'rxjs';
import { MediaService, Topic, TopicService } from '../../content';
import { ContentItem } from '../../content/content-item.model';
import { AvailableLanguage, LanguageService } from '../../language';
import { Course } from '../course.model';
import { CourseService } from '../course.service';
import { BreadcrumbService } from '../../navigation';
import { CultureService } from '../../cultures';
import { WindowService } from '../../utilities';

@Component({
  selector: 'ttt-courses',
  templateUrl: './course-list-promo.component.html',
  styleUrls: ['./course-list-promo.component.scss'],
})
export class CourseListPromoComponent implements OnInit {
  // Added by milos for rating component
  max = 5;
  rate = 5;

  private subscriptions: Subscription[] = [];
  loadingObject: {[key:string]:boolean} = {}; // .courses .topic
  modelClassName : string; // 'classes'
  topicSlug: string; // icpendulum or cultureconnector
  contentClassName: string; // tips or episodes
  courses: Course[] = [];
  coursesAsContent: ContentItem[];
  thumbnailTransformations: string = 'w_375/';
  activeLanguageObject : AvailableLanguage;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private courseService: CourseService,
    private topicService: TopicService,
    private languageService: LanguageService,
    private breadcrumbService : BreadcrumbService,
    private cultureService : CultureService,
    private mediaService : MediaService,
    private windowService : WindowService,
  ) {}

  isAnythingLoading(){
    for (let something in this.loadingObject){
      if (this.loadingObject[something]){
        return true;
      }
    }
    return false;
  }

  navigateTo(courseSlug:string, event : Event) {
    event.stopPropagation();
    this.router.navigate(['./'+courseSlug], { relativeTo: this.route });
  }
  getTopic(topicSlug : string, freshFromBackend : boolean) {
    this.loadingObject.topic = true;
    const topicSubscription = this.topicService.getTopic(topicSlug,freshFromBackend).subscribe((topic) => {
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'topic',fragmentName:topic.name});
      this.loadingObject.topic = false;
      this.getContent(topic,this.modelClassName,freshFromBackend);
        // this.titleService.setTitleWithTranslationFirstConcatenation(
        //   'navigation.courses',
        //   ': ' + topic.name,
        //   null
        // );
      },
      error => {
        this.loadingObject.topic = false;
      });
    this.subscriptions.push(topicSubscription);
  }
  roundRating (num: number){
    return num ? Number(num).toFixed(1): 0;
  }
  getFlagUrlFromHash(hash:string,transformations : string = ''){
    return this.mediaService.getFlagUrlFromHash(hash,'',true,'.png');
  }
  getDataFromServices(topic : Topic,freshFromServer: boolean) {
    const culture$ = this.cultureService.getCultures('geographic', 'national', freshFromServer);
    const topic$ = this.topicService.getTopics(null, null, freshFromServer);

    const combinedSubscription = forkJoin([culture$, topic$]).pipe(
    ).subscribe(([cultures, topics]) => {
      
      this.courseService.insertCulturesIntoCachedCourses(cultures);
      this.courseService.insertTopicsIntoCachedCourses(topics);

      const cachedCourses = this.courseService.getCachedCoursesBySlugs(topic.courseSlugs);

      const cachedCoursesWithCultureAndTopicTags = cachedCourses.map(cc => {

        const culturesAsTags = this.cultureService.convertCulturesToTags(cc.cultures,false);
        const topicsAsTags = this.topicService.convertTopicsToTags(cc.topics,false);
        const cultureAndTopicTags = culturesAsTags.concat(topicsAsTags);
        const newCourseObject = JSON.parse(JSON.stringify(cc));
        newCourseObject.tags = newCourseObject.tags ? [...newCourseObject.tags,...cultureAndTopicTags] : cultureAndTopicTags;
        return newCourseObject;
      })

      this.getCoursesAsContent(cachedCoursesWithCultureAndTopicTags,topic);

    });
    this.subscriptions.push(combinedSubscription);
  }
  getCoursesAsContent(courses : Course[], topic : Topic){
    if(topic.courseSlugs && topic.courseSlugs.length === courses.length){
      this.courses = courses;
      this.coursesAsContent = this.courseService.convertCoursesToContentItems(this.courses);
    }
  }
  getContent(topic : Topic, modelClassName : string, freshFromBackend : boolean) {

    if (modelClassName === 'courses'){

      const cachedCourses = this.courseService.getCachedCoursesBySlugs(topic.courseSlugs);

      if(topic.courseSlugs && topic.courseSlugs.length === cachedCourses.length){
        this.getCoursesAsContent(cachedCourses,topic);
        this.getDataFromServices(topic,freshFromBackend);
        return;
      }

      this.loadingObject.courses = true;

      const contentSubscription = this.courseService
        .getCoursesByTopic(topic.slug, freshFromBackend)
        .subscribe(
          (response) => {
            this.courses = response;
            this.loadingObject.courses = false;
            this.getCoursesAsContent(response,topic);
            this.getDataFromServices(topic,freshFromBackend);
          },
          (error) => {
            this.loadingObject.courses = false;
          }
        );
      this.subscriptions.push(contentSubscription);
    }

  }

  ngOnInit(): void {

    this.windowService.goToTop();
    this.modelClassName = this.route.routeConfig?.data?.className; // 'courses'
    this.topicSlug = this.route.snapshot.params['topic']?.toLowerCase();

    // this.route.url.subscribe(() => {
    //   let fullUrlParts = this.router.routerState.snapshot.url
    //     .toLowerCase()
    //     .split('/')
    //     .filter((s) => s);
    //   this.topicSlug = fullUrlParts[0];
    //   this.contentClassName = fullUrlParts[1];
    if(this.modelClassName && this.topicSlug){
      this.getTopic(this.topicSlug,false);
    }

    this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
    const activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
      // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
      if (newActiveLanguage?.languageKey !== this.activeLanguageObject.languageKey){
        this.activeLanguageObject = newActiveLanguage;
        if(this.modelClassName && this.topicSlug){
          this.getTopic(this.topicSlug,true);
        }
      }
    })
    this.subscriptions.push(activeLanguageSubscription);

  }
  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
