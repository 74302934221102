<!-- Registration Form -->
<div class="carousel-right-content">
  <h1 *transloco="let t">{{ t('common.register') }}</h1>
  <div class="registration-form" *transloco="let t">
    <form [formGroup]="registrationForm" role="form" class="mt-4">
      <!-- First Name* -->
      <div class="input-self">
        <label for="fname" class="form-label">
          {{ t('common.first_name') }}
          <span class="required-sign">*</span>
        </label>
        <div class="input-group mb-3" [ngClass]="{
          'ng-invalid':
            registrationForm.get('fname').invalid &&
            (registrationForm.get('fname').touched ||
              registrationForm.get('fname').dirty),
          'ng-valid':
            registrationForm.get('fname').valid &&
            registrationForm.get('fname').dirty
        }">
          <span class="input-group-text">
            <i class="fa fa-user" aria-hidden="true"></i>
          </span>
          <input class="form-control" type="text" formControlName="fname" id="fname"
            [placeholder]="t('common.first_name')" />
          <div *ngIf="
              registrationForm.get('fname').errors?.required &&
              registrationForm.get('fname').touched
            " class="invalid-feedback">
            {{ t('common.required') }}
          </div>
          <div *ngIf="
              registrationForm.get('fname').errors?.minlength &&
              registrationForm.get('fname').touched
            " class="invalid-feedback">
            {{ t('common.too_short') }}
          </div>
          <div *ngIf="
              registrationForm.get('fname').errors?.maxlength &&
              registrationForm.get('fname').touched
            " class="invalid-feedback">
            {{ t('common.too_long') }}
          </div>
        </div>
      </div>
      <!-- Last name* -->
      <div class="input-self">
        <label for="lname" class="form-label">
          {{ t('common.last_name') }}
          <span class="required-sign">*</span>
        </label>
        <div class="input-group mb-3" [ngClass]="{
          'ng-invalid':
            registrationForm.get('lname').invalid &&
            (registrationForm.get('lname').touched ||
              registrationForm.get('lname').dirty),
          'ng-valid':
            registrationForm.get('lname').valid &&
            registrationForm.get('lname').dirty
        }">
          <span class="input-group-text text-primary-light">
            <i class="fa fa-user" aria-hidden="true"></i>
          </span>
          <input class="form-control" type="text" formControlName="lname" id="lname"
            [placeholder]="t('common.last_name')" />
          <div *ngIf="
              registrationForm.get('lname').errors?.required &&
              registrationForm.get('lname').touched
            " class="invalid-feedback">
            {{ t('common.required') }}
          </div>
          <div *ngIf="
              registrationForm.get('lname').errors?.minlength &&
              registrationForm.get('lname').touched
            " class="invalid-feedback">
            {{ t('common.too_short') }}
          </div>
          <div *ngIf="
              registrationForm.get('lname').errors?.maxlength &&
              registrationForm.get('lname').touched
            " class="invalid-feedback">
            {{ t('common.too_long') }}
          </div>
        </div>
      </div>
      <!-- E-mail* -->
      <div class="input-self">
        <label for="email" class="form-label">{{ t('common.email') }}<span class="required-sign">*</span>
        </label>
        <div class="input-group mb-3" [ngClass]="{
            'ng-invalid':
              registrationForm.get('email').invalid &&
              (registrationForm.get('email').touched ||
                registrationForm.get('email').dirty),
            'ng-valid':
              registrationForm.get('email').valid &&
              registrationForm.get('email').touched &&
              registrationForm.get('email').dirty
          }">
          <span class="input-group-text text-primary-light">
            <i class="fa fa-envelope" aria-hidden="true"></i>
          </span>
          <input class="form-control" type="email" formControlName="email" id="email" placeholder="example@email.com" />
          <div *ngIf="
              registrationForm.get('email').errors?.email &&
              registrationForm.get('email').touched
            " class="invalid-feedback">
            {{ t('authentication.invalid_address') }}
          </div>
          <div *ngIf="
              registrationForm.get('email').errors?.required &&
              registrationForm.get('email').dirty
            " class="invalid-feedback">
            {{ t('common.required') }}
          </div>
        </div>
      </div>
      <!-- Repeat E-mail* -->
      <div class="input-self">
        <label for="repeatEmail" class="form-label">{{ t('authentication.email_again') }}<span
            class="required-sign">*</span>
        </label>
        <div class="input-group mb-3" [ngClass]="{
            'ng-invalid':
              (registrationForm.get('repeatEmail').invalid || registrationForm.hasError('mismatch')) && 
              (registrationForm.get('repeatEmail').touched ||
                registrationForm.get('repeatEmail').dirty),
            'ng-valid':
              (registrationForm.get('repeatEmail').valid && !registrationForm.hasError('mismatch')) &&
              (registrationForm.get('repeatEmail').touched ||
              registrationForm.get('repeatEmail').dirty)
          }">
          <span class="input-group-text text-primary-light">
            <i class="fa fa-envelope" aria-hidden="true"></i>
          </span>
          <input class="form-control" type="email" formControlName="repeatEmail" id="repeatEmail"
            placeholder="example@email.com" [ngClass]="{
              'ng-invalid':
                registrationForm.hasError('mismatch') && 
                registrationForm.get('repeatEmail').touched
            }" />

          <div *ngIf="
            registrationForm.hasError('mismatch') && registrationForm.get('repeatEmail').touched
            " class="invalid-feedback">
            {{ t('authentication.email_confirmation_error') }}
          </div>
        </div>
      </div>
      <!-- Password* -->
      <div class="input-self">
        <label for="password" class="form-label">
          {{ t('authentication.password')
          }}<span class="required-sign">*</span></label>
        <div class="input-group mb-3" [ngClass]="{
            'ng-invalid':
              registrationForm.get('password').invalid &&
              (registrationForm.get('password').touched || registrationForm.get('password').dirty),
            'ng-valid':
              registrationForm.get('password').valid &&
              (registrationForm.get('password').touched ||
              registrationForm.get('password').dirty)
          }">
          <span class="input-group-text text-primary-light">
            <i class="fa fa-unlock-alt" aria-hidden="true"></i>
          </span>
          <input class="form-control input-lg" type="password" id="password" formControlName="password"
            [placeholder]="t('authentication.make_new_password')" />
          <div *ngIf="
              registrationForm.get('password').errors &&
              (registrationForm.get('password').touched ||
                registrationForm.get('password').dirty)
            " class="invalid-feedback">
            <div *ngIf="registrationForm.get('password').errors?.required">
              {{ t('common.required') }}
            </div>
            <div *ngIf="
                registrationForm.get('password').errors?.minlength || registrationForm.get('password').errors?.invalidPasswordFormat
              ">
              {{ t('authentication.8char_1digit') }}
            </div>
            <div *ngIf="registrationForm.get('password').errors?.maxlength">
              {{ t('common.too_long') }}
            </div>
          </div>
        </div>
      </div>
      <!-- Terms of service -->


      <div>
        <div class="mt-3" id="termsOfService" [collapse]="termsCollapsed" [isAnimated]="true">
          <h6>Terms of service</h6>
          <small>
            <ol>
              <li>You {{registrationForm.value.fname ? registrationForm.value.fname+' '+registrationForm.value.lname :
                ''}} agree to use your real, offline identity; only upload images and text you have permission to use;
                act like an interculturalist towards other registered users</li>
              <li>We, CEBT, agree to be honest with you about what happens to your data here and stay within the laws of
                the European Union; you can read more about how we do this in our <a href="https://cebt.fi/privacy"
                  class="text-link" target="_blank">privacy statement</a></li>
            </ol>
          </small>
        </div>
      </div>
      <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
      </multisite-loader-globe-animation-detail>
      <div class="input-self" *ngIf="!loading && errorMessage !=='authentication.already_registered'">
        <p><a *ngIf="showViewTermsLink" (click)="termsCollapsed = !termsCollapsed" role="button"
            [attr.aria-expanded]="!termsCollapsed" aria-controls="termsOfService">
            {{termsCollapsed? t('authentication.view_terms') : t('authentication.hide_terms')}}
          </a></p>
        <div class="input-group">
          <button class="btn btn-lg btn-primary w-100 mt-4" [disabled]="termsViewed && !registrationForm.valid"
            (click)="viewTermsAndRegister()" *transloco="let t">
            {{ termsViewed ? t('authentication.agree_and_register') : t('authentication.view_terms') }}
          </button>
        </div>
      </div>
      <div class="my-4" *ngIf="errorMessage ==='authentication.already_registered'">
        <alert class="my-4 w-100" [type]="'warning'"><span [innerHTML]="errorMessage | transloco"></span></alert>
        <p class="text-muted">
          {{t('authentication.registered_at_related_website')}}
        </p>
        <ul *ngIf="relatedWebsites?.length">
          <li *ngFor="let site of relatedWebsites"><a [href]="site.appUrl" [innerHTML]="site.name"></a></li>
        </ul>
      </div>

      <!-- Link to login -->
      <p class="text-center mt-3" *ngIf="!termsViewed">
        {{t('authentication.already_have_account')}} <a [routerLink]="['/login']"
          class="text-decoration-underline">{{t('common.log_in')}}</a>
      </p>
    </form>

  </div>
</div>


 
<ng-template #successModal>
  <!--<div class="modal-header">
     <h4 class="modal-title pull-left">Modal</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">
                <i class="fa-solid fa-xmark"></i>
            </span>
    </button>
  </div> -->
  <div class="modal-header">
    <h4 id="dialog-static-name" class="modal-title pull-left" *transloco="let t">{{t('common.success')}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <strong *transloco="let t">{{t('authentication.check_your_email', { email: registeredEmail })}}</strong>
    <p *transloco="let t">
      <span class="mr-1">{{t('authentication.email_verification_sent')}}</span>
      <span class="mr-1">{{t('authentication.check_email_inbox')}}</span>
    </p>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn-primary-outline pull-right" aria-label="Close"
    (click)="successModal.hide()">
    Close
</button>
</div> -->
</ng-template>