import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';

import { of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Hub } from './hub.model';
import { HubLite } from './hub-lite.model';
import {
  AuthService,
  BrandDisplayService,
  BrandEditingService,
  CloudinaryMediaAsset,
  ColourService,
  ContentItem,
  DataProcessingService,
  MediaService,
  MetaText,
  PaginatedHubs,
  Setting,
  UserExtraLite,
  UserExtraLiteAdmin,
} from '@frontend/common';
import { MetaTextMultilingual } from '../content/metaText-multilingual.model';
import { Router } from '@angular/router';

interface newHubMetaTextsData {
  [key: string]: { s: string; l: string };
}
type metaTextColumnAlias = { column: string; alias: string };

export interface HubLiteResponse { // public hub listing in community search
  id : number;
  cohorts_count : number;
  contact_persons : UserExtraLite[];
  created_at: string;
  logo_banner: string;
  media: CloudinaryMediaAsset[];
  metaTexts : MetaText[];
  name: string;
  primary_colour: string;
  primary_brand_slug: string;
  slug: string;
  type: string;
}

export interface multilingualMetaTextsContent {
  [key: string]: string;
}
export interface HubManagementTask {
  alert_category_label_translation_key: string // 'hubs.brand'
  alert_text_translation_key : string, // 'tracking.incomplete_thing',
  alert_text_interpolated_variable : string, // 'Coca-Cola'
  link_label_translation_key : string, // 'common.get_details'
  route : string,
  status: string, // 'info','warning','danger','success' // These values should map onto the StatusItem.status values
  related_status_item?: string // 'slogans' 'banners_and_images'
}

export interface newHubRequestData {
  name: string;
  type: string;
  owner_email: string;
  metatexts: newHubMetaTextsData[];
}

@Injectable({
  providedIn: 'root',
})
export class HubService {

  managedHubs: Hub[];
  managedHubsFullListRequested: Date;
  metaTextColumnAliasDirectory: {
    category: string;
    type: string;
    aliases: metaTextColumnAlias[];
  }[];
  paginatedPublicHubs :  Record<number, PaginatedHubs> = {};
  currentURLSearchParamsForPaginatedPublicHubs : URLSearchParams;
  private _business_types = ['micro','business','agency'];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private brandDisplayService: BrandDisplayService,
    private brandEditingService: BrandEditingService,
    private colourService: ColourService,
    private mediaService: MediaService,
    private router: Router,
    private dataProcessingService: DataProcessingService,
  ) {
    this.metaTextColumnAliasDirectory = [
      {
        category: 'profile',
        type: 'general',
        aliases: [
          { column: 's', alias: 'strapline' },
          { column: 'l', alias: 'description' },
        ],
      },
    ];
  }
  clearData (){
    this.managedHubs = null;
    this.paginatedPublicHubs = {}; // should we also clear currentURLSearchParamsForPaginatedPublicHubs ? 
  }
  get brandSlugMinLengths() {
    return {
      personal: 6,
      micro: 6,
      business: 6,
      agency: 3,
    };
  };
  get business_types() : string[]{
    return this._business_types;
  }
  transformManagedHubBrands(hubResponse) {
    let hub: Hub = hubResponse;
    if (hubResponse.brands?.length) {
      hub = this.brandDisplayService.transformBackendHubBranding(hubResponse);
      hub.brands = this.brandEditingService.transformBackendBrands(
        hubResponse.brands
      );

      if (!hub.primary_colour || !hub.logo_banner) {
        let primaryBrand = hubResponse.brands.find(
          (b) => b.pivot.category === 'primary'
        );
        if (primaryBrand) {
          hub.primary_colour = primaryBrand.colours?.[1];
          hub.logo_banner = primaryBrand.logo_banner;
        }
      }
    }
    return hub;
  }
  transformManagedHub(hubResponse) {
    let hub: Hub = hubResponse;
    hub = this.brandDisplayService.transformBackendHubMetaTexts(
      hub,
      hubResponse.metaTexts
    );
    if (hubResponse.contact_persons?.length) {
      let contact_persons: UserExtraLiteAdmin[] =
        hubResponse.contact_persons.map((cp) =>
          this.authService.mergeUserProfileIntoUserExtraLiteAdmin(cp)
        );
      hub.contact_persons = contact_persons;
    }
    hub.media = this.brandDisplayService.transformMediaResponse(
      hubResponse.media
    );
    hub = this.transformManagedHubBrands(hubResponse);
    if (
      hubResponse.cohorts &&
      !hubResponse.cohorts_count &&
      hubResponse.cohorts_count !== 0
    ) {
      hub.cohorts_count = hubResponse.cohorts.length;
    }
    if (
      hubResponse.programmes &&
      !hubResponse.programmes_count &&
      hubResponse.programmes_count !== 0
    ) {
      hub.programmes_count = hubResponse.programmes.length;
    }
    return hub;
  }
  transformManagedHubs(hubsFromBackend) {
    let transformedhubs: Hub[] = [];
    hubsFromBackend.forEach((t) => {
      transformedhubs.push(this.transformManagedHub(t));
    });
    return transformedhubs;
  }
  removeRole(hub_slug: string, role: string, email: string) {
    return this.http
      .post<{ data: Hub }>('api/v1/hubs/remove-role/' + hub_slug, {
        role: role,
        email: email,
      })
      .pipe(
        map((response) => {
          let hub: Hub = this.transformManagedHub(response.data);
          this.cacheManagedHub(hub);
          if (
            [
              'hub_trainers',
              'event_and_programme_trainers',
              'client_trainers',
            ].includes(role)
          ) {
            hub = this.deleteCachedHubProperty(hub, role);
          }
          return hub;
        }),
        catchError((error) => {
          throw this.handleError(error);
        })
      );
  }
  addRole(hub_slug: string, role: string, email: string) {
    return this.http
      .post<{ data: Hub }>('api/v1/hubs/add-role/' + hub_slug, {
        role: role,
        email: email,
      })
      .pipe(
        map((response) => {
          let hub: Hub = this.transformManagedHub(response.data);
          this.cacheManagedHub(hub);
          if (
            [
              'hub_trainers',
              'event_and_programme_trainers',
              'client_trainers',
            ].includes(role)
          ) {
            hub = this.deleteCachedHubProperty(hub, role);
          }
          return hub;
        }),
        catchError((error) => {
          throw this.handleError(error);
        })
      );
  }
  storeNewHub(hubData: newHubRequestData) {
    return this.http.post<{ data: Hub }>('api/v1/hubs', hubData).pipe(
      map((response) => {
        let hub: Hub = this.transformManagedHub(response.data);
        this.cacheManagedHub(hub);
        return hub;
      }),
      catchError((error) => {
        throw this.handleError(error);
      })
    );
  }
  getManagedHub(slug) {
    let cachedHub: Hub = this.getCachedManagedHub(slug);
    if (cachedHub) {
      return of(cachedHub);
    }
    return this.http.get<{ data: Hub }>('api/v1/hubs/slug/' + slug).pipe(
      map((response) => {
        let hub: Hub = this.transformManagedHub(response.data);
        this.cacheManagedHub(hub);
        return hub;
      }),
      catchError((error) => {
        throw this.handleError(error);
      })
    );
  }
  updateManagedHub(slug:string,editedHubProperties: {name:string}) {

    return this.http.put<{ data: Hub }>('api/v1/hubs/slug/' + slug,editedHubProperties).pipe(
      map((response) => {
        let hub: Hub = this.transformManagedHub(response.data);
        this.cacheManagedHub(hub);
        return hub;
      }),
      catchError((error) => {
        throw this.handleError(error);
      })
    );
  }
  getCachedManagedHub(slug): Hub {
    if (!this.managedHubs?.length) {
      return null;
    }
    let cachedHub = this.managedHubs.find((h) => h.slug === slug);
    if (cachedHub?.managers) {
      // if there are managers, this is not just a 'lite' list item
      return cachedHub;
    } else {
      return null;
    }
  }

  cacheManagedHubs(hubs: Hub[]) {
    this.managedHubs = hubs;
    this.managedHubsFullListRequested = new Date();
  }
  cacheManagedHub(hub: Hub) {
    if (!this.managedHubs) {
      this.managedHubs = [hub];
    } else {
      let found: number = this.managedHubs.findIndex((h) => h.id === hub.id);
      if (found > -1) {
        this.managedHubs[found] = hub;
      } else {
        this.managedHubs.push(hub);
      }
    }
    
  }
  getManagedHubs(freshFromServer: boolean) {
    let cachedHubs: Hub[];

    if (!freshFromServer && this.managedHubsFullListRequested) {
      cachedHubs = this.managedHubs;
    }
    if (cachedHubs) {
      return of(cachedHubs);
    }
    let url = 'api/v1/hubs';
    return this.http.get<{ data: Hub[] }>(url).pipe(
      map((response) => {
        if (response && response.data) {
          response.data = this.transformManagedHubs(response.data);
          this.cacheManagedHubs(response.data);
          return response.data;
        }
      }),
      catchError((error) => {
        throw this.handleError(error);
      })
    );
  }
  cacheHubProperty(hub: Hub, propertyKey: string, propertyValue: any) {
    let cachedHub = this.getCachedManagedHub(hub.slug); // cachedHub and hub should refer to the same object in memory
    if (cachedHub) {
      cachedHub[propertyKey] = propertyValue;
      this.cacheManagedHub(cachedHub);
      return cachedHub;
    }
    return hub; // this line should never execute
  }
  deleteCachedHubProperty(hub: Hub, propertyKey: string) {
    let cachedHub = this.getCachedManagedHub(hub.slug); // cachedHub and hub should refer to the same object in memory
    if (cachedHub) {
      cachedHub[propertyKey] = null;
      this.cacheManagedHub(cachedHub);
      return cachedHub;
    }
    return hub;
  }
  getTrainers(hub: Hub, role: string) {
    role ? role : 'all_trainers'; // other possible values: 'hub_trainers','event_and_programme_trainers'
    return this.http
      .get<{ data: UserExtraLiteAdmin[] }>(
        'api/v1/hubs/trainers/' + hub.slug + '?role=' + role
      )
      .pipe(
        map((response) => {
          if (response && response.data) {
            return this.cacheHubProperty(hub, role, response.data);
          }
        }),
        catchError((error) => {
          throw this.handleError(error);
        })
      );
  }
  getSettings(hub: Hub) {
    return this.http
      .get<{ data: Setting[] }>(
        'api/v1/hubs/settings/' + hub.slug)
      .pipe(
        map((response) => {
          if (response && response.data) {
            return this.cacheHubProperty(hub, 'settings', response.data);
          }
        }),
        catchError((error) => {
          throw this.handleError(error);
        })
      );
  }
  updateSettings(hub: Hub,settings:Setting[]) {
    if(!settings?.length){ throw new Error('request must include settings'); };
    let settingsFormattedForBackend = settings.map(s => {
      return {
        id: s.id,
        setting_name: s.setting_name,
        setting_value: s.setting_value,
        category: s.category,
        type: s.type,
        deleted_at: s.deleted_at,
      }
    });
    return this.http
      .post<{ data: Setting[] }>(
        'api/v1/hubs/settings/' + hub.slug,{settings:settingsFormattedForBackend})
      .pipe(
        map((response) => {
          if (response && response.data) {
            return this.cacheHubProperty(hub, 'settings', response.data);
          }
        }),
        catchError((error) => {
          throw this.handleError(error);
        })
      );
  }
  getMultilingualMetaTexts(hub: Hub) {
    return this.http
      .get<{ data: MetaTextMultilingual[] }>(
        'api/v1/hubs/metatexts/' + hub.slug
      )
      .pipe(
        map((response) => {
          if (response && response.data) {
            return this.cacheHubProperty(
              hub,
              'multilingualMetaTexts',
              response.data
            );
          }
        }),
        catchError((error) => {
          throw this.handleError(error);
        })
      );
  }
  setMultilingualMetaTexts(
    hub: Hub,
    id_existing_texts: number,
    text_content: multilingualMetaTextsContent[],
    category: string,
    type: string,
    alias: string
  ) {
    let text_content_object: multilingualMetaTextsContent = {};
    text_content.forEach((language) => {
      text_content_object = { ...text_content_object, ...language };
    });
    const metaTextColumn = this.metaTextColumnAliasDirectory
      .find((a) => a.category === category && a.type === type)
      ?.aliases?.find((a) => a.alias === alias)?.column;
    return this.http
      .put<{ data: MetaTextMultilingual[] }>(
        'api/v1/hubs/metatexts/' + hub.slug,
        {
          text_content: text_content,
          id: id_existing_texts,
          category: category,
          type: type,
          column: metaTextColumn,
        }
      )
      .pipe(
        map((response) => {
          if (response && response.data) {
            return this.cacheHubProperty(
              hub,
              'multilingualMetaTexts',
              response.data
            );
          }
        }),
        catchError((error) => {
          throw this.handleError(error);
        })
      );
  }
  uploadFile(slug: string, formData: FormData) {
    return this.http
      .post('/api/v1/hubs/upload-media/' + slug, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((response) => {
          if (response.type === HttpEventType.Response) {
            const updatedData = response.body as {
              hub: Hub;
              media: CloudinaryMediaAsset;
            };
            if (updatedData.hub) {
              const hub: Hub = this.transformManagedHub(updatedData.hub);
              this.cacheManagedHub(hub);
            }
          }
          return response;
        }),
        catchError((error) => {
          throw this.handleError(error);
        })
      );
  }

  generateTaskList (hub : Hub, routeLevelsFromHubHome:number){
    let tasks : HubManagementTask[] = [];
    let brandTasks : HubManagementTask[] = this.generateBrandTaskList(hub,routeLevelsFromHubHome);
    let profileTasks : HubManagementTask[] = this.generateProfileTaskList(hub,routeLevelsFromHubHome);
    let roleTasks : HubManagementTask[] = this.generateRoleTaskList(hub,routeLevelsFromHubHome);
    tasks =tasks.concat(brandTasks,profileTasks,roleTasks);
    return this.generateMasterTaskList(tasks);
  }
  generateMasterTaskList (tasks : HubManagementTask[]){
    let tasksCombinedObject : {
      masterTask : HubManagementTask,
      tasks: HubManagementTask[],
    } = {
      masterTask : {
        alert_category_label_translation_key: null, // 'hubs.brand'
        alert_text_translation_key : 'tracking.tasks_awaiting_attention', // 'tracking.incomplete_thing',
        alert_text_interpolated_variable : tasks.length.toString(), // 'Coca-Cola'
        link_label_translation_key : null, // 'common.get_details'
        route : null,
        status: 'info', // 'info','warning','danger'
      },
      tasks : tasks
    };

    tasks.forEach(t=>{
      if (tasksCombinedObject.masterTask.status === 'info'){
        if (['warning','danger'].includes(t.status)){
          tasksCombinedObject.masterTask.status = t.status;
        }
      } else if (tasksCombinedObject.masterTask.status === 'warning' && t.status === 'danger'){
        tasksCombinedObject.masterTask.status = t.status;
      }
    });
    return tasksCombinedObject;
  }
  generateBrandTaskList (hub : Hub, routeLevelsFromHubHome:number) : HubManagementTask[]{
    let brandTasks : HubManagementTask[] = [];
    hub.brands.forEach(b=>{
      if (b.completion && b.completion< 100 && !(hub.type === 'personal' && b.category === 'primary')){
        brandTasks.push({
          alert_category_label_translation_key : 'hubs.brand',
          alert_text_translation_key : 'tracking.incomplete_thing',
          alert_text_interpolated_variable : b.name,
          link_label_translation_key : 'common.go',
          route : routeLevelsFromHubHome === 0 ? 'marketing/brands/'+b.slug : routeLevelsFromHubHome === 1 ? 'brands/'+b.slug : routeLevelsFromHubHome === 2 ? b.slug : null,
          status: b.completion < 70 ? 'warning' : 'info', // 'info','warning','danger'
          related_status_item: 'brands'
        })
      }
    });
    return brandTasks;
  }
  generateRoleTaskList (hub : Hub, routeLevelsFromHubHome:number) : HubManagementTask[]{
    let rolesTasks : HubManagementTask[] = [];
      if (!hub.contact_persons?.length){
        rolesTasks.push({
          alert_category_label_translation_key : 'common.roles',
          alert_text_translation_key : 'hubs.choose_first_contact_person',
          alert_text_interpolated_variable : null,
          link_label_translation_key : 'common.go',
          route : routeLevelsFromHubHome === 0 ? 'roles'  : null,
          status: 'info', // 'info','warning','danger'
          related_status_item: 'contact_persons'
        })
      }
    return rolesTasks;
  }
  generateProfileTaskList (hub : Hub, routeLevelsFromHubHome:number) : HubManagementTask[]{
    let profileTasks : HubManagementTask[] = [];
    
    if (!hub.media?.find(m=>m.category === 'profile' && m.type === 'general')){
      profileTasks.push({
        alert_category_label_translation_key : 'hubs.profile_general',
        alert_text_translation_key : 'content_management.missing_banner',
        alert_text_interpolated_variable : null,
        link_label_translation_key : 'common.go',
        route : routeLevelsFromHubHome === 0 ? 'marketing/profiles/general' : routeLevelsFromHubHome === 1 ? 'profiles/general' : routeLevelsFromHubHome === 2 ? 'general' : null,
        status: 'warning', // 'info','warning','danger'
        related_status_item: 'banners_and_other_images'
      });
    };
    
    if (!hub.media?.find(m=>m.category === 'profile' && m.type === 'feature')){
      profileTasks.push({
        alert_category_label_translation_key : 'hubs.profile_general',
        alert_text_translation_key : 'content_management.missing_feature_image',
        alert_text_interpolated_variable : null,
        link_label_translation_key : 'common.go',
        route : routeLevelsFromHubHome === 0 ? 'marketing/profiles/general' : routeLevelsFromHubHome === 1 ? 'profiles/general' : routeLevelsFromHubHome === 2 ? 'general' : null,
        status: 'info', // 'info','warning','danger'
        related_status_item: 'banners_and_other_images'
      });
    };

    if (!hub.strapline){
      profileTasks.push({
        alert_category_label_translation_key : 'hubs.profile_general',
        alert_text_translation_key : 'content_management.missing_strapline',
        alert_text_interpolated_variable : null,
        link_label_translation_key : 'common.go',
        route : routeLevelsFromHubHome === 0 ? 'marketing/profiles/general' : routeLevelsFromHubHome === 1 ? 'profiles/general' : routeLevelsFromHubHome === 2 ? 'general' : null,
        status: 'danger', // 'info','warning','danger'
        related_status_item: 'slogans'
      });
    };
    
    if (!hub.description){
      profileTasks.push({
        alert_category_label_translation_key : 'hubs.profile_general',
        alert_text_translation_key : 'content_management.missing_description',
        alert_text_interpolated_variable : null,
        link_label_translation_key : 'common.go',
        route : routeLevelsFromHubHome === 0 ? 'marketing/profiles/general' : routeLevelsFromHubHome === 1 ? 'profiles/general' : routeLevelsFromHubHome === 2 ? 'general' : null,
        status: 'danger', // 'info','warning','danger'
        related_status_item: 'descriptions'
      });
    };
   
    return profileTasks;
  }

  makeContentItems(hubs) {
    let hubsAsContent: ContentItem[] = [];
    hubs.forEach((hub) => {
      hubsAsContent.push(this.convertHubToContentItem(hub));
    });
    return hubsAsContent;
  }
  convertHubToContentItem(hub) {
    let hubBanner = hub.media?.find(
      (m) => m.category === 'profile' && m.type === 'general'
    );
    let hubContactPerson = hub.contact_persons?.[0];
    let hubAsContent = new ContentItem(
      hub.id,
      hub.name,
      null,
      hub.strapline,
      null,
      hub.description,
      null,
      null,
      hub.slug,
      'Go to hub',
      'common.go',
      hubBanner,
      hubBanner
        ? hub.media?.filter((m) => m.id !== hubBanner.id)
        : hub.media?.length
        ? hub.media
        : [],
      null,
      hub.rating,
      null,
      new Date(hub.published_at),
      hub.tags,
      hubContactPerson,
      {
        primary_colour_hex: hub.primary_colour,
        primary_colour_rgb: this.colourService.convertHEXtoRGBstring(hub.primary_colour),
        logo_banner: hub.logo_banner,
        cohorts_count: hub.cohorts_count,
      }
    );
    return hubAsContent;
  }
  areSearchParamsEqualDisregardingPageNumbers(params1 : URLSearchParams, params2 : URLSearchParams) {
   return this.dataProcessingService.areSearchParamsEqualDisregardingPageNumbers(params1,params2);
  }
  transformPublicHub(hubResponse:HubLiteResponse) : HubLite {
    const hub : HubLite = Object.assign(hubResponse,{strapline:null,description:null,owners:null,managers:null,brands:null,});
    if(!hub.media?.length){
      let backendClassName = this.dataProcessingService.convertModelNameToBackendClass('hub');
      hub.media = [Object.assign(this.mediaService.getPlaceholder('CloudinaryMediaItemBanner'), {medially_id: hub.id,medially_type:backendClassName,category:'profile',type:'general'})];
    }
    if(!hub.primary_colour){
      hub.primary_colour = this.brandDisplayService.defaultBrandConstants?.colours?.[1]['hex'];
    }
    this.metaTextColumnAliasDirectory.forEach(mt=>{
      if(hubResponse.metaTexts){
        const aliasableMetaText = hubResponse.metaTexts.find(metaText => metaText.category === mt.category && metaText.type === mt.type);
        if(aliasableMetaText){
          mt.aliases.forEach(aliasItem => {
            hub[aliasItem.alias] = aliasableMetaText[aliasItem.column];
          })
        }
      }
    })
    return hub;
  }
  transformPublicHubs(hubs:HubLiteResponse[]) : HubLite[]{
    return hubs.map( hub => this.transformPublicHub(hub));
  }
  cachePaginatedPublicHubs (paginatedHubs : PaginatedHubs, page : number, params : URLSearchParams){
    params.delete('page');
    if(this.currentURLSearchParamsForPaginatedPublicHubs && !this.areSearchParamsEqualDisregardingPageNumbers(params,this.currentURLSearchParamsForPaginatedPublicHubs)){
      this.currentURLSearchParamsForPaginatedPublicHubs = params;
      this.paginatedPublicHubs = {};
    }
    paginatedHubs.data = this.transformPublicHubs((paginatedHubs.data as HubLiteResponse[]));
    this.paginatedPublicHubs[page] = paginatedHubs;
    return this.paginatedPublicHubs[page];
  };
  getCachedPaginatedPublicHubs (params : URLSearchParams, page : number){

    if(
      !params ||
      !this.currentURLSearchParamsForPaginatedPublicHubs ||
      !this.areSearchParamsEqualDisregardingPageNumbers(params,this.currentURLSearchParamsForPaginatedPublicHubs)){
        return null;
      }
    return this.paginatedPublicHubs[page];
  };

  getPaginatedPublicHubs(filters : {[key:string]:string | string[]}, page: number, freshFromServer: boolean) {

    // filters example
    // {
    //   category: 'books',
    //   author: 'John Doe,Alice Smith,Bob Johnson',
    //   price: 'asc',
    // };
    // filters must NOT include the page param


    const params : URLSearchParams = this.dataProcessingService.convertObjectToSearchParams(filters);

    page = page ? page : 1;

    let cachedHubs: PaginatedHubs;

    if (!freshFromServer) {
      cachedHubs = this.getCachedPaginatedPublicHubs(params, page);
    }
    if (cachedHubs) {
      return of(cachedHubs);
    }
    params.set('page',page.toString())

    return this.http.get<PaginatedHubs>('api/v1/hub-search?'+params.toString()).pipe(
      map((response) => {
        if (response && response.data) {
          return this.cachePaginatedPublicHubs(response, page, params);
        }
      })
    );
  }
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return errorMessage;
    }
    switch (errorResponse.error.message) {
      case 'This action is unauthorized.':
        errorMessage = 'error.permissions_lacking';
        break;
      case 'Unauthenticated.':
        this.router.navigate(['/login']); // TODO - consider handling this by route guard instead (when we have solved the problem in route guard's timeout that slow get-user responses will redirect to login without waiting for an eror)
        break;
      case 'User not found':
        errorMessage = 'error.not_found_must_be_registered';
        break;
      case 'Only the current owner can set a new owner':
        errorMessage = 'error.permissions_lacking';
        break;
      case '400 Duplicate - already has this role in this hub':
        errorMessage = 'error.no_change_user_has_role_aready';
        break;
      case '403 Removing this role from the owner is not allowed':
        errorMessage = 'hubs.cannot_remove_owners_manager_role';
        break;
      case 'The given data was invalid.':
        if (
          errorResponse.error.errors?.file_url?.[0] ==
          'validation.ends_with_extension'
        ) {
          errorMessage = 'error.file_type';
        } else {
          errorMessage = 'error.data_invalid';
        }
        break;
      default:
      // no action needed. We already set the default error message.
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }
}
