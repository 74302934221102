import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkTypeTwoComponent } from './link-type-two.component';
import { ImagesModule } from '../../images';



@NgModule({
  declarations: [LinkTypeTwoComponent],
  exports: [LinkTypeTwoComponent],
  imports: [
    CommonModule,
    ImagesModule
  ]
})
export class LinkTypeTwoModule { }
