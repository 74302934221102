import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CiResult } from '../../ciresult.model';
import { Step } from '@frontend/shared';
import { Snapshot } from '../../../surveys';
import { ActivatedRoute, Router } from '@angular/router';
import { CiResultService } from '../../ciresult.service';

type Tour = {
  identifier : string; // 'concepts'
  titleTranslationKey : string;
  presentationSteps : PresentationStep [];
  stepperSteps : Step [];
}
type PresentationStep = {
  titleTranslationKey : string,
  textTranslationKey : string,
  highlightedElementSlug : string, // for example 'love' (domain to highlight) or 'values' (subdomain to highlight)
  stepperStepIndex : number,
  visual: any
  showScoreFeedback?: boolean,
  routeSlug? : string, // note: '' empty string works but null or undefined do not. Example ('think') which is the route-slug to click for more info for these four subdomains: preparing, noticing, reviewing, expecting
}

@Component({
  selector: 'ciprofile-wheel-presenter',
  templateUrl: './wheel-presenter.component.html',
  styleUrls: ['./wheel-presenter.component.scss']
})
export class CiprofileWheelPresenterComponent implements OnInit {

  @Input() chartSize : number = 64;
  @Input() snapshot :Snapshot;
  @Input() result : CiResult;
  @Input() highlightedElementSlug : string; // 'values'
  @Output() goHome = new EventEmitter();

  tourSelectorForm: UntypedFormGroup;
  tours : Tour[];
  currentTour: Tour;
  currentPresentationStepIndex: number;
  colours : number[];
  stopButtonAnimation : boolean;
  animationTimeout : any;

  constructor (
      private formBuilder: UntypedFormBuilder,
      private router : Router,
      private route: ActivatedRoute,
      private ciResultService: CiResultService) {

    this.tours = [
      {
        identifier : 'concepts',
        titleTranslationKey: 'ciprofile_guide.explain_ci',
        presentationSteps: [
          {
            titleTranslationKey: 'ciprofile_guide.what_is_ci',
            textTranslationKey: null,
            highlightedElementSlug: null,
            stepperStepIndex : 0,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_guide.four_domains_of_ci',
            textTranslationKey: null,
            highlightedElementSlug: null,
            stepperStepIndex : 1,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.know',
            textTranslationKey: 'ciprofile_domains.know_description_short',
            highlightedElementSlug: 'know',
            stepperStepIndex : 1,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.love',
            textTranslationKey: 'ciprofile_domains.love_description_short',
            highlightedElementSlug: 'love',
            stepperStepIndex : 1,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.think',
            textTranslationKey: 'ciprofile_domains.think_description_short',
            highlightedElementSlug: 'think',
            stepperStepIndex : 1,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.act',
            textTranslationKey: 'ciprofile_domains.act_description_short',
            highlightedElementSlug: 'act',
            stepperStepIndex : 1,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.know',
            textTranslationKey: 'ciprofile_domains.know_description',
            highlightedElementSlug: 'know',
            stepperStepIndex : 2,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.values_and_beliefs',
            textTranslationKey: 'ciprofile_domains.subdomains.values_and_beliefs_description',
            highlightedElementSlug: 'values',
            stepperStepIndex : 2,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.behaviour',
            textTranslationKey: 'ciprofile_domains.subdomains.behaviour_description',
            highlightedElementSlug: 'behaviour',
            stepperStepIndex : 2,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.blindspots',
            textTranslationKey: 'ciprofile_domains.subdomains.blindspots_description',
            highlightedElementSlug: 'blindspots',
            stepperStepIndex : 2,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.translations',
            textTranslationKey: 'ciprofile_domains.subdomains.translations_description',
            highlightedElementSlug: 'translations',
            stepperStepIndex : 2,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.love',
            textTranslationKey: 'ciprofile_domains.love_description',
            highlightedElementSlug: 'love',
            stepperStepIndex : 3,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.internal',
            textTranslationKey: 'ciprofile_domains.subdomains.internal_description',
            highlightedElementSlug: 'internal',
            stepperStepIndex : 3,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.external',
            textTranslationKey: 'ciprofile_domains.subdomains.external_description',
            highlightedElementSlug: 'external',
            stepperStepIndex : 3,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.resilience',
            textTranslationKey: 'ciprofile_domains.subdomains.resilience_description',
            highlightedElementSlug: 'resilience',
            stepperStepIndex : 3,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.mastery',
            textTranslationKey: 'ciprofile_domains.subdomains.mastery_description',
            highlightedElementSlug: 'mastery',
            stepperStepIndex : 3,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.think',
            textTranslationKey: 'ciprofile_domains.think_description',
            highlightedElementSlug: 'think',
            stepperStepIndex : 4,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.preparing',
            textTranslationKey: 'ciprofile_domains.subdomains.preparing_description',
            highlightedElementSlug: 'preparing',
            stepperStepIndex : 4,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.noticing',
            textTranslationKey: 'ciprofile_domains.subdomains.noticing_description',
            highlightedElementSlug: 'noticing',
            stepperStepIndex : 4,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.reviewing',
            textTranslationKey: 'ciprofile_domains.subdomains.reviewing_description',
            highlightedElementSlug: 'reviewing',
            stepperStepIndex : 4,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.expecting',
            textTranslationKey: 'ciprofile_domains.subdomains.expecting_description',
            highlightedElementSlug: 'expecting',
            stepperStepIndex : 4,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.act',
            textTranslationKey: 'ciprofile_domains.act_description',
            highlightedElementSlug: 'act',
            stepperStepIndex : 5,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.performing',
            textTranslationKey: 'ciprofile_domains.subdomains.performing_description',
            highlightedElementSlug: 'performing',
            stepperStepIndex : 5,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.reformulating',
            textTranslationKey: 'ciprofile_domains.subdomains.reformulating_description',
            highlightedElementSlug: 'reformulating',
            stepperStepIndex : 5,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.authenticity',
            textTranslationKey: 'ciprofile_domains.subdomains.authenticity_description',
            highlightedElementSlug: 'authenticity',
            stepperStepIndex : 5,
            visual : null,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.countering_intuition',
            textTranslationKey: 'ciprofile_domains.subdomains.countering_intuition_description',
            highlightedElementSlug: 'countering_intuition',
            stepperStepIndex : 5,
            visual : null,
          },
          
        ],
        stepperSteps : [
          { 
            text : null,
            translationKey : 'ciprofile.ci_short',
            // completed? : boolean
            // allowSkip? : boolean;}
            active : false
          },
          { 
            text : null,
            translationKey : 'ciprofile_domains.domain_name_plural',
            active : false
          },
          { 
            text : null,
            translationKey : 'ciprofile_domains.know',
            active : false
          },
          { 
            text : null,
            translationKey : 'ciprofile_domains.love',
            active : false
          },
          { 
            text : null,
            translationKey : 'ciprofile_domains.think',
            active : false
          },
          { 
            text : null,
            translationKey : 'ciprofile_domains.act',
            active : false
          },
        ]
      },
      {
        identifier : 'results',
        titleTranslationKey: 'ciprofile_guide.explain_results',
        presentationSteps: [
          {
            titleTranslationKey: 'common.colours',
            textTranslationKey: 'ciprofile_guide.colours_description',
            highlightedElementSlug: 'all',
            stepperStepIndex : 0,
            visual : 'colour_legend',
          },
          {
            titleTranslationKey: 'common.colours',
            textTranslationKey: 'ciprofile_guide.colours_accessible',
            highlightedElementSlug: 'all',
            stepperStepIndex : 0,
            visual : 'wheelchair',
          },
          {
            titleTranslationKey: 'ciprofile_guide.total_score',
            textTranslationKey: 'ciprofile_guide.total_score_description',
            highlightedElementSlug: 'total',
            routeSlug: '',
            stepperStepIndex : 1,
            visual : null,
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile.ci',
            textTranslationKey: 'ciprofile_guide.ci_snapshots',
            highlightedElementSlug: 'all',
            stepperStepIndex : 1,
            visual : 'snapshot',
          },
          {
            titleTranslationKey: 'ciprofile.ci',
            textTranslationKey: 'ciprofile.ci_measure_in_context',
            highlightedElementSlug: 'all',
            stepperStepIndex : 1,
            visual : 'snapshot',
          },
          {
            titleTranslationKey: 'ciprofile_domains.know',
            textTranslationKey: 'ciprofile_domains.know_description',
            highlightedElementSlug: 'know',
            routeSlug: 'know',
            stepperStepIndex : 2,
            visual : 'user_progress_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.values_and_beliefs',
            textTranslationKey: 'ciprofile_domains.subdomains.values_and_beliefs_description',
            highlightedElementSlug: 'values',
            routeSlug: 'know',
            stepperStepIndex : 2,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.behaviour',
            textTranslationKey: 'ciprofile_domains.subdomains.behaviour_description',
            highlightedElementSlug: 'behaviour',
            routeSlug: 'know',
            stepperStepIndex : 2,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.blindspots',
            textTranslationKey: 'ciprofile_domains.subdomains.blindspots_description',
            highlightedElementSlug: 'blindspots',
            routeSlug: 'know',
            stepperStepIndex : 2,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.translations',
            textTranslationKey: 'ciprofile_domains.subdomains.translations_description',
            highlightedElementSlug: 'translations',
            routeSlug: 'know',
            stepperStepIndex : 2,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.love',
            textTranslationKey: 'ciprofile_domains.love_description',
            highlightedElementSlug: 'love',
            routeSlug: 'love',
            stepperStepIndex : 3,
            visual : 'user_progress_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.internal',
            textTranslationKey: 'ciprofile_domains.subdomains.internal_description',
            highlightedElementSlug: 'internal',
            routeSlug: 'love',
            stepperStepIndex : 3,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.external',
            textTranslationKey: 'ciprofile_domains.subdomains.external_description',
            highlightedElementSlug: 'external',
            routeSlug: 'love',
            stepperStepIndex : 3,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.resilience',
            textTranslationKey: 'ciprofile_domains.subdomains.resilience_description',
            highlightedElementSlug: 'resilience',
            routeSlug: 'love',
            stepperStepIndex : 3,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.mastery',
            textTranslationKey: 'ciprofile_domains.subdomains.mastery_description',
            highlightedElementSlug: 'mastery',
            routeSlug: 'love',
            stepperStepIndex : 3,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.think',
            textTranslationKey: 'ciprofile_domains.think_description',
            highlightedElementSlug: 'think',
            routeSlug: 'think',
            stepperStepIndex : 4,
            visual : 'user_progress_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.preparing',
            textTranslationKey: 'ciprofile_domains.subdomains.preparing_description',
            highlightedElementSlug: 'preparing',
            routeSlug: 'think',
            stepperStepIndex : 4,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.noticing',
            textTranslationKey: 'ciprofile_domains.subdomains.noticing_description',
            highlightedElementSlug: 'noticing',
            routeSlug: 'think',
            stepperStepIndex : 4,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.reviewing',
            textTranslationKey: 'ciprofile_domains.subdomains.reviewing_description',
            highlightedElementSlug: 'reviewing',
            routeSlug: 'think',
            stepperStepIndex : 4,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.expecting',
            textTranslationKey: 'ciprofile_domains.subdomains.expecting_description',
            highlightedElementSlug: 'expecting',
            routeSlug: 'think',
            stepperStepIndex : 4,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.act',
            textTranslationKey: 'ciprofile_domains.act_description',
            highlightedElementSlug: 'act',
            routeSlug: 'act',
            stepperStepIndex : 5,
            visual : 'user_progress_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.performing',
            textTranslationKey: 'ciprofile_domains.subdomains.performing_description',
            highlightedElementSlug: 'performing',
            routeSlug: 'act',
            stepperStepIndex : 5,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.reformulating',
            textTranslationKey: 'ciprofile_domains.subdomains.reformulating_description',
            highlightedElementSlug: 'reformulating',
            routeSlug: 'act',
            stepperStepIndex : 5,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.authenticity',
            textTranslationKey: 'ciprofile_domains.subdomains.authenticity_description',
            highlightedElementSlug: 'authenticity',
            routeSlug: 'act',
            stepperStepIndex : 5,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
          {
            titleTranslationKey: 'ciprofile_domains.subdomains.countering_intuition',
            textTranslationKey: 'ciprofile_domains.subdomains.countering_intuition_description',
            highlightedElementSlug: 'countering_intuition',
            routeSlug: 'act',
            stepperStepIndex : 5,
            visual : 'user_step_score',
            showScoreFeedback: true,
          },
        ],
        stepperSteps: [
          { 
            text : null,
            translationKey : 'common.colours', // 'ciprofile.ci_short',
            // completed? : boolean
            // allowSkip? : boolean;}
            active : false
          },
          { 
            text : 'Total',
            translationKey : 'ciprofile_guide.total',
            active : false
          },
          { 
            text : null,
            translationKey : 'ciprofile_domains.know',
            active : false
          },
          { 
            text : null,
            translationKey : 'ciprofile_domains.love',
            active : false
          },
          { 
            text : null,
            translationKey : 'ciprofile_domains.think',
            active : false
          },
          { 
            text : null,
            translationKey : 'ciprofile_domains.act',
            active : false
          },
        ],
      },
    ];
    this.colours = [0,1,2,3,4,5,6,7,8];
   }

  navigateTo(slug:string){
    this.router.navigate(['../'+slug], {relativeTo: this.route});
  }

  handleClickFromWheel(selection : {subdomain:any , domain : any}){
    if (!this.currentTour){return;};
    let slug = selection?.subdomain?.slug ?? selection?.domain?.slug ?? null;
    if (slug){
      let selectedStepIndex = this.currentTour.presentationSteps.findIndex(ps=>ps.highlightedElementSlug === slug);
      this.goToPresentationStep(this.currentTour.presentationSteps[selectedStepIndex]);
    }
  }

  goToStepperStep (stepIndex: number, stepperStep : Step){
    this.resetPresentationStep ();
    let stepperStepIndex : number;
    if (stepIndex === 0 || stepIndex){
      stepperStepIndex = stepIndex;
    } else {
      stepperStepIndex = this.currentTour.stepperSteps.findIndex(ss => ss.translationKey === stepperStep.translationKey);
    }
    let foundFirstPresentationStep = this.currentTour.presentationSteps.find(ps => ps.stepperStepIndex === stepperStepIndex);
    if (foundFirstPresentationStep){
      this.goToPresentationStep(foundFirstPresentationStep);
    }
  }
  goToTour (tour : Tour){
    if(!tour){return;};
    this.currentTour = tour;
    this.currentPresentationStepIndex = 0;
  }
  selectStepperStep(stepData : {step :Step , stepIndex: number}){
    let selectedStep : Step = this.currentTour.stepperSteps[stepData.stepIndex];
    this.goToStepperStep(stepData.stepIndex, selectedStep);
  }

  resetPresentationStep (){
    if (!this.currentTour){
      this.currentTour = this.tours[0];
    }
    if(this.currentPresentationStepIndex === undefined || this.currentPresentationStepIndex === null){
      this.currentPresentationStepIndex = 0;
    }
  }
  setActiveStepperStep(){
    if (this.currentTour && this.currentPresentationStepIndex === 0 || this.currentPresentationStepIndex > 0){
      this.currentTour.stepperSteps.forEach(ss => {
        ss.active = false;
      })
      this.currentTour.stepperSteps[this.currentTour.presentationSteps[this.currentPresentationStepIndex].stepperStepIndex].active = true;
    }
  }
  getActiveStepperStep(tour : Tour){
    return tour?.stepperSteps?.find(s=>s.active)
  }

  goToPresentationStep(presentationStep : PresentationStep){
    this.resetPresentationStep();
    let thing = this.currentTour.presentationSteps.findIndex(ps => ps.stepperStepIndex == presentationStep.stepperStepIndex && (ps.titleTranslationKey === presentationStep.titleTranslationKey || ps.textTranslationKey === presentationStep.textTranslationKey));
    this.currentPresentationStepIndex = thing;
    this.setActiveStepperStep();
    this.forceStepProgressIndicatorChangeDetection();
  }
  getScaleSteps(resultPropertyKey : string,resultPropertyValue : number){
    return this.ciResultService.getScaleSteps(resultPropertyKey,resultPropertyValue);
  }
  nextPresentationStep(){
    this.resetPresentationStep();
    if (this.currentTour?.presentationSteps?.length > this.currentPresentationStepIndex+1){
      this.currentPresentationStepIndex++;
    } else {
      this.currentTour = this.nextTour(this.tours,this.currentTour.identifier);
      this.currentPresentationStepIndex = 0;
    }
    this.setActiveStepperStep();
    this.forceStepProgressIndicatorChangeDetection();
  }
  forceStepProgressIndicatorChangeDetection(){
    // for some reason change detection did not work when the progressSteps input value is changed, even when forcing change detection
    if (this.currentTour?.presentationSteps[this.currentPresentationStepIndex].visual === 'user_step_score'){
      this.currentTour.presentationSteps[this.currentPresentationStepIndex].visual = null;
      setTimeout(()=>{
        this.currentTour.presentationSteps[this.currentPresentationStepIndex].visual = 'user_step_score';
      })
    }
  }
  previousStep(){
    this.resetPresentationStep();
    if (this.currentPresentationStepIndex>0){
      this.currentPresentationStepIndex--;
    } else {
      this.currentTour = this.nextTour(this.tours.slice().reverse(),this.currentTour.identifier);
      this.currentPresentationStepIndex = this.currentTour.presentationSteps.length-1;
    }
    this.setActiveStepperStep();
    this.forceStepProgressIndicatorChangeDetection();
  }
  nextTour(tours : Tour[], currentTourIdentifier : string){
    // first skip all the earlier tours and try to find a tour after the currentTour
    let skippedEarlierTours : boolean = false;
    let nextTourFound: Tour;
    tours.forEach(tour => {
      if (tour.identifier === currentTourIdentifier){
        skippedEarlierTours = true;
      };
      if (!nextTourFound && skippedEarlierTours && tour.identifier !== currentTourIdentifier){
        nextTourFound = tour;
      }
    });
    if (nextTourFound){
      return nextTourFound;
    } else {
      // if no tour after the currentTour, return the first available alternative tour
      return tours.find(tour => tour.identifier !== currentTourIdentifier);
    }
  }
  goToSnapshotHome(){
    this.goHome.emit();
  }
  insertCompletionStep(tour:Tour){
    
    let stepperStep : Step = { 
      text : null,
      translationKey : 'tracking.completion',
      active : false
    };
    tour.stepperSteps.push(stepperStep);
    let presentationStep : PresentationStep = 
      {
        titleTranslationKey: '',//'survey.get_full_results',
        textTranslationKey: '',//'survey.complete_your_profile',
        highlightedElementSlug: 'all',
        stepperStepIndex : tour.stepperSteps.length-1,
        visual : 'completion',
      };
    tour.presentationSteps.push(presentationStep);

    return tour
  }
  removeIncompleteStepsFromTours(result: CiResult, tours : Tour[]){
    let allowedStepSlugInIncompleteExplainer = ['all',null];
    let resultPropertiesNotNull = Object.keys(result).filter(key => result[key] !== null).concat(allowedStepSlugInIncompleteExplainer);
    
    tours.forEach(tour => {
      tour.presentationSteps = tour.presentationSteps.filter(ps=>resultPropertiesNotNull.includes(ps.highlightedElementSlug));
      let remainingStepperStepIndices = tour.presentationSteps
                                        .map(ps=>ps.stepperStepIndex)
                                        .filter((value, index, array) => array.indexOf(value) === index); // filters returns only unique values

      // Now all the old step indices are wrong; let's update them: ///////
      let stepIndexMap : {oldIndex:number,newIndex:number}[] = [];
      remainingStepperStepIndices.forEach((rssi,index) => {
        stepIndexMap.push({oldIndex:rssi,newIndex:index});
      });
      tour.presentationSteps.forEach(ps=>{
        let remainingStep = stepIndexMap.find(rs=>rs.oldIndex === ps.stepperStepIndex);
        if (remainingStep /* should be 100% of cases */){
          ps.stepperStepIndex = remainingStep.newIndex;
        }
      })
      // end of updating old step indices /////////////////////////////////

      tour.stepperSteps = tour.stepperSteps.filter((_, index) => remainingStepperStepIndices.includes(index));
      
    });

    tours[tours.length-1] = this.insertCompletionStep(tours[tours.length-1]);

    return tours;

  }

  ngOnInit(): void {
    this.goToPresentationStep(this.tours[0].presentationSteps[0]);
    this.tourSelectorForm = this.formBuilder.group({
      radio: 1
    });
    this.tourSelectorForm.controls.radio.valueChanges.subscribe((value) => {
      this.currentTour = this.tours[value];
    });
    this.animationTimeout = setTimeout(()=>{
      this.stopButtonAnimation = true;
    },20000)
  }
  
  ngOnChanges(changesObject): void {
    if(changesObject.result?.currentValue && changesObject.result.currentValue.total === null){
      this.tours = this.removeIncompleteStepsFromTours(this.result, this.tours);
    }
  }
  ngOnDestroy () {
    if (this.animationTimeout){
      clearTimeout(this.animationTimeout);
    }
  }

}
