import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertTypeOneComponent } from './alert-type-one/alert-type-one.component';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    AlertTypeOneComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ],
  exports: [
    AlertTypeOneComponent,
  ],
})
export class AlertsLegacyCustomModule { }
