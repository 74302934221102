<div class="container">
  <div class="snapshots-listing" *transloco="let t">
    <div class="d-flex align-items-center gap-2 flex-row justify-content-between mt-3" *ngIf="snapshots?.length">
      <button class="btn btn-primary" (click)="openModal(snapshotEditModal)" [disabled]="currentAcceptedSnaphot()">
        <span>{{t('common.add_new')}}</span> <i class="pl-2 fa-solid fa-plus"></i>
      </button>
      <multisite-alert-type-two *ngIf="currentAcceptedSnaphot()"
        [iconClasses]="'fa-solid fa-arrow-rotate-right fa-spin'"
        [translationKey]="'survey.other_snapshot_active'"></multisite-alert-type-two>
      <hr>
    </div>
    <!-- List of snapshot context clickable -->
    <div class="row mt-4">


      <div class="col-12">
        <!-- Create new snapshot -->
        <multisite-gallery-cta
          *ngIf="!snapshots?.length && !loading"
          [titleTranslationKey]="'survey.snapshot_heading'"
          [descriptionTranslationKey]="'survey.snapshot_info1'"
          [buttonLabelTranslationKey]="'common.create'"
          [media]="galleryCtaMedia"
          (linkClicked)="openModal(snapshotEditModal)"
          >
        </multisite-gallery-cta>

        <!-- End of create new snapshot -->
 
        <a [routerLink]="snapshot.id" *ngFor="let snapshot of snapshots" class="clickable">

          <multisite-snapshot-list-item [snapshot]="snapshot"></multisite-snapshot-list-item>
        </a>
      </div>

    </div>
    <multisite-loader-globe-animation-detail *ngIf="loading" width="100" height="100" class="mx-auto"></multisite-loader-globe-animation-detail>

    <alert [type]="'danger'" [dismissOnTimeout]="3500" *ngIf="error">
      <span *transloco="let t">{{ t(error) }}</span>
    </alert>

  </div>





  <ng-template #snapshotEditModal>
    <div class="modal-header" *transloco="let t">
      <h4 class="h4 font-weight-bold mb-0">
        {{t('survey.snapshot_context_question')}}
      </h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true">
          <i class="fa-solid fa-xmark"></i>
        </span>
      </button>
    </div>
    <div class="modal-body overflow-hidden">


      <snapshot-create-edit-form-simple (submit)="storeNewSnapshot($event)" [error]="error" [loading]="loading">
      </snapshot-create-edit-form-simple>

    </div>
  </ng-template>



  <!-- <div class="row mt-4">
    <div class="col-md-4 offset-md-4">
      <div class="card my-3" *ngFor="let survey of surveys">
        <div class="card-header">
          Create a snapshot
        </div>
        <div class="card-body">
          <h5 class="card-title">{{survey.name}}</h5>
          <p class="card-text">Some informative and motivating thing here.</p>
          <a [routerLink]="['./'+survey.slug]"  class="btn btn-primary mt-2">Go</a>
        </div>
      </div>
    </div>
  </div> -->
</div>