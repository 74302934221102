import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusListItemComponent } from './status-list-item.component';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [StatusListItemComponent],
  exports: [StatusListItemComponent],
  imports: [
    CommonModule,
    TranslocoModule
  ]
})
export class StatusListItemModule { }
