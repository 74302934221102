import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'multisite-brand-hub-visibility-editor',
  templateUrl: './brand-hub-visibility-editor.component.html',
  styleUrls: ['./brand-hub-visibility-editor.component.scss']
})
export class BrandHubVisibilityEditorComponent implements OnInit, OnChanges {

  @Input() visibility : string;
  @Output() updateVisibility = new EventEmitter<string>();
  @Input() disabled : boolean;
  visibilityForm: FormGroup;
  error : string;

  constructor() {}

  setUpForm (){
    this.visibilityForm = new FormGroup({
      visibility: new FormControl(this.visibility, [Validators.required])
    });
    this.visibilityForm.controls.visibility.valueChanges.subscribe(
      (value) => {
        this.updateVisibility.emit(value);
      }
    );
  }


  ngOnInit(): void {
    this.setUpForm ();
  };
  ngOnChanges(changesObject): void {
    if (changesObject.visibility?.currentValue === null || changesObject.visibility?.currentValue === undefined || changesObject.visibility?.currentValue === 'primary'){
      this.visibility = 'high_hub_visibiity';
    }
  };
  
}
