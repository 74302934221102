<div class="container">
  <div class="d-flex justify-content-center">
    <ng-template transloco let-t class="mt-5">
    <div class="card mt-5" *ngFor="let page of pages">
      <div class="card-body">
        <h2 class="card-title"> {{ page.title }} </h2>
        <h4> {{ page.subtitle }} </h4>
        <p>{{ page.body }}</p>
      </div>
    </div>
  </ng-template>

  <p></p>

  </div>
  
</div>