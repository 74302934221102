import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DesignRoutingModule } from './design-routing.module';
import { DesignComponent } from './design.component';

import { RatingModule } from 'ngx-bootstrap/rating';
import { AlertModule } from 'ngx-bootstrap/alert';

import { Design01Component } from './design01/design01.component';
import { Design02Component } from './design02/design02.component';
import { Design03Component } from './design03/design03.component';
import { Design04Component } from './design04/design04.component';
import { Design05Component } from './design05/design05.component';
import { Design06Component } from './design06/design06.component';
import { Design07Component } from './design07/design07.component';
import { Design08Component } from './design08/design08.component';
import { Design09Component } from './design09/design09.component';
import { Design10Component } from './design10/design10.component';

import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FooterModule } from '@frontend/common';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco';

@NgModule({
  // providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'design' }],
  declarations: [
    DesignComponent,
    Design01Component,
    Design02Component,
    Design03Component,
    Design04Component,
    Design05Component,
    Design06Component,
    Design07Component,
    Design08Component,
    Design09Component,
    Design10Component,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DesignRoutingModule,
    AlertModule,
    CarouselModule,
    RatingModule,
    ModalModule.forRoot(),
    PaginationModule,
    CollapseModule,
    TabsModule,
    AccordionModule,
    FooterModule,
    TranslocoModule,

  ]
})
export class DesignModule { }
