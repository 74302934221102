<div class="container mb-5 pt-4" *transloco="let t">
  <h1 class="static-title h1 fw-bold">
    {{t('content_management.'+profileKeys[profile_slug].key)}}
  </h1>


  <div class="language-adding-card mt-4">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6">
            <h5 class="card-title">{{t('common.languages')}}</h5>
            <p>{{t('content_management.choose_languages_add_edit')}}</p>
          </div>
          <div class="col-lg-6">
            <div class="active-languages my-sm-3 my-lg-0">
              <p>{{t('content_management.currently_editing')}}</p>
              <div class="d-flex gap-2 flex-wrap">
                <span class="badge badge-pill badge-info"
                  [ngClass]="{'cancellable': language.iso !== englishLanguage.iso,'disabled': language.iso === englishLanguage.iso}"
                  (click)="onLanguageRemove(language,profileKeys[profile_slug].key)"
                  *ngFor="let language of languageVersions[profileKeys[profile_slug].key]">{{language.name}}
                  <button type="button" aria-label="Remove" class="btn btn-dismiss"
                    *ngIf="language.iso !== englishLanguage.iso">
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <form #language_form_profile="ngForm" class="">
          <input [(ngModel)]="selectedLanguageName" [typeahead]="languages" typeaheadOptionField="name" name="language"
            [placeholder]="t('common.start_typing')"
            (typeaheadOnSelect)="onLanguageSelect($event,profileKeys[profile_slug].key)" class="form-control mt-2">
        </form>

      </div>
    </div>
  </div>

  <div class="mt-4">
    <label class="form-label h3 fw-bold">
      {{t('content_management.strapline')}}
    </label>
    <p class="mb-2">{{t('content_management.strapline_help')}}</p>
    <multisite-form-text-multilingual
      [languageVersions]="languageVersions[profileKeys[profile_slug].key]"
      [languageVersionsCount]="languageVersions[profileKeys[profile_slug].key]?.length"
      [draftVersions]="multilingualMetaTextDraft?.s"
      [placeholderTranslationKey]="'common.write_something_here'"
      [labelTextTranslationKey]=""
      [minLength]=""
      [maxLength]="80"
      [requiredLanguages]="['en']"
      (formSubmitted)="updateMultilingualMetaTexts('strapline',multilingualMetaTextDraft?.id, $event, 'profile',profileKeys[profile_slug].type)"
      [errorTranslationKey]="error" [loading]="loadingMetaTextUpdate">
    </multisite-form-text-multilingual>
  </div>

  <div class="mt-4">
    <label class="form-label h3 fw-bold">
      {{t('common.description')}}
    </label>
    <p class="mb-2">{{t('content_management.description_help')}}</p>
    <multisite-form-text-multilingual
      [languageVersions]="languageVersions[profileKeys[profile_slug].key]"
      [languageVersionsCount]="languageVersions[profileKeys[profile_slug].key]?.length"
      [draftVersions]="multilingualMetaTextDraft?.l"
      [placeholderTranslationKey]="'common.write_something_here'"
      [labelTextTranslationKey]=""
      [minLength]=""
      [maxLength]="120"
      [rows]="2"
      [requiredLanguages]="['en']"
      (formSubmitted)="updateMultilingualMetaTexts('description',multilingualMetaTextDraft?.id, $event, 'profile',profileKeys[profile_slug].type)"
      [errorTranslationKey]="error" [loading]="loadingMetaTextUpdate">
    </multisite-form-text-multilingual>
  </div>

  <div class="mt-4">
    <label class="form-label h3 fw-bold">
      {{t('hubs.hub_banner')}}
    </label>
    <p class="mb-2">{{t('hubs.hub_banner_help')}}</p>
      <div class="d-flex flex-column align-items-center gap-4 flex-lg-row">
        <div class="danger-zone-mark">
          <div class="top-mark">
            <div class="left-part"></div>
            <!-- <div class="right-part"></div> -->
          </div>
          <div class="bottom-mark"></div>
          <img *ngIf="selectedMedia?.profile?.general?.file_url"
            [src]="imageWithTransformations(selectedMedia.profile.general.file_url, 'w_250,c_fill/')"
            alt="This is the banner you chose" />
        </div>
        <div>
          <p>{{t('hubs.banner_danger_zones')}} <a (click)="dangerZoneInfoCollapse = !dangerZoneInfoCollapse">{{
            dangerZoneInfoCollapse
            ? t('common.show_more')
            : t('common.show_less')
            }}
            <span *ngIf="dangerZoneInfoCollapse"><i class="fa-solid fa-angle-down"></i></span>
            <span *ngIf="!dangerZoneInfoCollapse"><i class="fa-solid fa-angle-up"></i></span>
          </a></p>
          <p class="mb-2" [collapse]="dangerZoneInfoCollapse">{{t('hubs.banner_danger_zones_help')}}</p>
        </div>
      </div>
    <p class="h5 my-3">{{t('content_management.upload_image')}}</p>
    <multisite-uploader-single-file [requiredFileType]="'image/*'" [gallery]="gallery?.data" [isAdmin]="isAdmin()"
      [selectedGalleryItemMediaFileUrl]="selectedMedia.profile?.general?.file_url"
      [imageTransformations]="'w_350,c_fill,ar_16:9/'"
      [fileInstructionsTranslationKey]="'content_management.banner_image_help'"
      [fileDescriptionTranslationKey]="'hubs.hub_banner'"
      [remoteFileDescriptionTranslationKey]="'content_management.image_already_published'"
      [askForLicenceAndCredit]="true"
      (fileFormData)="uploadFile($event,'profile',profileKeys[profile_slug].type)"
      (cancelUpload)="cancelUploadFile($event)"
      [errorMessage]="fileUploadError"
      [successMessage]="fileUploadSuccess"
      [uploadProgress]="uploadProgress['profile']?.[profileKeys[profile_slug].type]">
    </multisite-uploader-single-file>
  </div>
  <div class="mt-4">
    <label for="strapline" class="form-label h3 fw-bold">
      {{t('hubs.hub_feature_image')}}
    </label>
    <p class="mb-2">{{t('hubs.hub_feature_image_help')}}</p>
    <div>
      <img *ngIf="selectedMedia?.profile?.feature?.file_url"
        [src]="imageWithTransformations(selectedMedia.profile.feature.file_url, 'w_250,c_fill/')"
        alt="This is the banner you chose" />
    </div>
    <p class="h5 my-3">{{t('content_management.upload_image')}}</p>
    <multisite-uploader-single-file
      [requiredFileType]="'image/*'"
      [gallery]=""
      [imageTransformations]="'w_350,c_fill,ar_16:9/'"
      [fileInstructionsTranslationKey]="'hubs.hub_feature_image_help'"
      [fileDescriptionTranslationKey]="'hubs.hub_feature_image'"
      [remoteFileDescriptionTranslationKey]="'content_management.image_already_published'"
      [askForLicenceAndCredit]="true"
      (fileFormData)="uploadFile($event,'profile','feature')"
      (cancelUpload)="cancelUploadFile($event)"
      [errorMessage]="fileUploadError"
      [successMessage]="fileUploadSuccess"
      [uploadProgress]="uploadProgress['profile']?.['feature']">
    </multisite-uploader-single-file>
  </div>
</div>