import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'libs/common/src/lib/auth';
import { Subscription } from 'rxjs';
import { TrackingService } from '../../../tracking.service';

@Component({
  selector: 'progress-indicator-slimmable-number',
  templateUrl: './progress-indicator-slimmable-number.component.html',
  styleUrls: ['./progress-indicator-slimmable-number.component.scss']
})
export class ProgressIndicatorSlimmableNumberComponent implements OnInit {

  @Input() slimMode : boolean;
  totalProgressSubscription : Subscription;
  userSubscription : Subscription;
  totalProgress : number;
  
  constructor(
    private trackingService : TrackingService,
    private authService : AuthService
    ) { }

  ngOnInit(): void {
    this.totalProgressSubscription = this.trackingService.totalProgress.subscribe( (total)=>{
      if (total > 0){
        this.totalProgress = total;
      } else {
        this.totalProgress = null;
      }
    });
    this.userSubscription = this.authService.user.subscribe(user => {
      // this.user = user;
      if (user){
        this.trackingService.getTotalProgressCoursesOnly().subscribe();
      }
      // this.isAuthenticated = !!user;
    });
  }
  ngOnDestroy (){
    if (this.totalProgressSubscription){
      this.totalProgressSubscription.unsubscribe();
    }
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    }
  }

}
