<div class="side-menu" *transloco="let t">
  <div class="img-holder">
    <div class="editable">
      <img class="profile-img" *ngIf="picture" [src]="picture"
      alt="Profile image">
      <button class="edit-image" *ngIf="pictureEditable" (click)="onPictureEdit()">
        <i class="fa-solid fa-pen"></i>
      </button>
    </div>
    <p class="name">{{ nameTranslationKey ? t(nameTranslationKey) : nameText}}</p>
  </div>

  <nav class="nav d-none d-lg-block">
    <a
      *ngFor="let link of navigationLinks"  
      class="nav-link"
      [routerLink]="link.routerLinkValue"
      routerLinkActive="active"
      >
      {{link.labelLanguageKey ? t(link.labelLanguageKey) : link.labelText}}
    </a>
  </nav>

  <!-- Navigation on small devices -->
  <select class="form-select form-select-lg d-block d-lg-none" aria-label="nav-item-select" (change)="onMobileMenuSelect($event)" name="select-menu">
    <!-- <option selected>Active</option> -->
    <option [selected]="link.selected" [value]="link.routerLinkValue" *ngFor="let link of navigationLinks">{{link.labelLanguageKey ? t(link.labelLanguageKey) : link.labelText}}</option>
  </select>

</div>