import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Link } from '@frontend/core';
import { LinksService } from '@frontend/shared';
import { Subscription } from 'rxjs';
import { MediaService } from '../../content';
import { Hub, HubService } from '../../hubs';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
import { BrandEditingService } from '../brand-editing.service';
import { BrandResponse } from '../brand-response.model';

@Component({
  selector: 'multisite-brands-listing',
  templateUrl: './brands-listing.component.html',
  styleUrls: ['./brands-listing.component.scss']
})
export class BrandsListingComponent implements OnInit {

  hub : Hub;
  hubSubscription : Subscription;
  linksSubscription : Subscription;
  loading: boolean;
  links: Link[] = [];

  constructor(
    private hubService : HubService,
    private breadcrumbService : BreadcrumbService,
    private router : Router,
    private route : ActivatedRoute,
    private mediaService : MediaService,
    private brandEditingService : BrandEditingService,
    private linksService: LinksService,
    ) { }

  getHub (slug){
    this.loading = true;
    this.hubService.getManagedHub(slug).subscribe(response => {
      this.loading = false;
      this.hub = response;
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'hub',fragmentName:this.hub.name});
      this.getLinks();
    },error=> {
        this.loading = false;
    })
  }
  transformAndCacheHub (hub:Hub,allHubBrandsFromBackend:BrandResponse[]){
    let hubBeforeTransformingBrands = Object.assign(hub,{brands:allHubBrandsFromBackend})
    hub = this.hubService.transformManagedHubBrands(hubBeforeTransformingBrands);
    this.hubService.cacheManagedHub(hub);
    return hub;
  }
  setAsPrimary (brand){
    this.loading = true;
    this.brandEditingService.setBrandAsPrimary(brand,this.hub.id).subscribe(allHubBrands=>{
      this.hub = this.transformAndCacheHub(this.hub,allHubBrands);
      this.loading = false;
    },error=> {
        this.loading = false;
    })
  }
  getLinks(){
    let linksRequest = [
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
    ]
    this.linksSubscription = this.linksService.getDynamicLinks(linksRequest).subscribe(links =>{
      this.links = links;
    })
  }
  imageWithTransformations(file_url:string,transformations:string){
    return this.mediaService.insertCloudinaryTransformationsIntoMediaUrl(file_url,transformations);
  }

  ngOnInit(): void {
    this.route.url.subscribe(() => {
      let fullUrlParts = this.router.routerState.snapshot.url
        .toLowerCase()
        .split('/')
        .filter((s) => s);
      this.getHub(fullUrlParts[1]);
    });
  }
  ngOnDestroy () : void {
    if (this.hubSubscription){
      this.hubSubscription.unsubscribe();
    }
    if (this.linksSubscription){
      this.linksSubscription.unsubscribe();
    }
  }

}
