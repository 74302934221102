<div class="card"

        [style]="'background-image: url(' + link?.media?.file_url+')'"
      >
        <!-- Content -->
        <div
          class="text-white text-center d-flex align-items-center rgba-black-light py-5 px-4"
        >
          <div>
            <h6 class="purple-text">
              <span *ngIf="link?.type == 'video'"><i class="fa-solid fa-video"></i><!--<mdb-icon fas icon="video"></mdb-icon>--><strong> Video</strong></span>
              <span *ngIf="link?.type == 'podcast'"><i class="fa-solid fa-podcast"></i><!--<mdb-icon fas icon="podcast"></mdb-icon>--><strong> Podcast</strong></span>
            </h6>
            <h3 class="card-title pt-4 pb-3 font-bold">
              <strong>
                {{link.label}}
              </strong>
            </h3>
            <p>
              {{link.description}}
            </p>
            <a
              [href]="link.url"
              target="_blank"
            >
              <!-- <mdb-icon fas icon="play" class="left"></mdb-icon> -->
              <i class="fa-solid fa-play left"></i>
              {{link.cta_label}}</a
            >
          </div>
        </div>
        <!-- Content -->
      </div>