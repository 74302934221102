import { Link, Tag, Video } from "@frontend/core";

export class Lesson {
    id : number;
    course_module_id : number;
    name : string;
    slug : string;
    description : string;
    notes: string; // Html or md
    videos : Video[];
    duration: number; // calculated from the video duration
    tags: Tag[]
    links: Link[];
    access: any; // may be a boolean or a complex object; to be decided
    published_at: string; // '2023-01-07 13:00:03';
    created_at: string; // '2023-01-07 13:00:03';
    updated_at: string; // '2023-01-07 13:00:03';
    course_slug? : string;
    progress?: {user_id : number, complete : string}[] // complete : Date // trainer could be looking at learner's data, or could be unauthenticated
}