import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderGlobeAnimationDetailComponent } from './loader-globe-animation-detail/loader-globe-animation-detail.component';



@NgModule({
  declarations: [
    LoaderGlobeAnimationDetailComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LoaderGlobeAnimationDetailComponent
  ]
})
export class LoadersModule { }
