import { Component, Input, OnInit } from '@angular/core';
import { Culture, MediaService } from '@frontend/common';
import { Media } from '@frontend/core';
import { Icon } from '../../icons';

@Component({
  selector: 'multisite-status-list-item',
  templateUrl: './status-list-item.component.html',
  styleUrls: ['./status-list-item.component.scss']
})
export class StatusListItemComponent implements OnInit {

  @Input() media : Media;
  @Input() icon : Icon;
  @Input() title : string;
  @Input() description : string;
  @Input() date: Date;
  @Input() progressValue: number;
  @Input() buttonText : string; // 'Go'
  @Input() buttonTextTranslationKey : string; // 'common.go'
  @Input() cultures: Culture[];
  @Input() titleIconClass: string; // 'fa-solid fa-circle-arrow-right'
  @Input() minutes: number;
  @Input() statusClass: string; // 'bg-danger' or 'bg-warning' or 'bg-success' or null (default colour) - colours the icon square
  @Input() statusIconClass: string; // 'fa-solid fa-arrow-rotate-right' or null (default icon) - for the icon in the square
  @Input() statusLabelText: string; // 'Repeat'
  @Input() statusLabelTranslationKey: string; // 'common.expiring_soon'

  imageTransformations: string = 'w_150/'; // 'w_150/'
  cloudinary_base_url : string;

  constructor(
    private mediaService : MediaService,
  ) {
    this.cloudinary_base_url = this.mediaService.cloudinary_base_url;
   }

  round (number: number){
    return number && number !== 0 ? Math.ceil(number) : 0;
  }

  ngOnInit(): void {
  }

}
