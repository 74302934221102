import { User } from "../auth";

export class Setting {
    constructor(
        public id: number,
        public setting_name: string,
        public setting_value: any,
        public category: string,
        public type: string,
        public disabled: boolean,
        public deleted_at: Date,
        public settingable_type?: string,
        public settingable_id?: number,
        public handler_id?: number,
        public handler?: User,
    ){}
}