import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentListFormatsDuplicateComponent } from './content-list-formats.component';
import { CardsModule } from '../../content/cards';
import { PaginationModule } from '@frontend/shared';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    ContentListFormatsDuplicateComponent,
  ],
  exports: [
    ContentListFormatsDuplicateComponent,
  ],
  imports: [
    CommonModule,
    CardsModule,
    PaginationModule,
    TranslocoModule,
  ]
})
export class ContentListFormatsDuplicateModule { }
