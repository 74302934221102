<!-- <button class="arrow-open">
  <i class="fa-solid fa-angle-left"></i>
</button> -->
<main class="container" *transloco="let t" id="content">

  <alert [type]="'warning'" [dismissOnTimeout]="3500" *ngIf="error">
    <span *transloco="let t">
      <span *ngIf="error_code">{{t('error.report_code_support', { code: error_code})}}</span>
      <span *ngIf="!error_code">{{t(error)}}</span>
    </span>
  </alert>

  <div class="mb-4" *ngIf="survey">
    <div class="survey-header">
      <div class="row mt-5">
        <div class="col-lg-5">
          <img class="w-100 mb-4" *ngIf="survey.media"
            [src]="survey.media.asset_url && thumbnailTransformations ? survey.media.base_url + thumbnailTransformations + survey.media.asset_url : survey.media.file_url"
            [alt]="survey.media.description">
        </div>

        <div class="col-lg-7">
          <div class="">
            <h2 class="h1 font-weight-bold mt-0">{{survey.name}}</h2>
            <p>{{survey.description}}</p>

            <div class="d-flex flex-row align-items-center mt-3">
              <p class="card-text">
                <span *ngIf="survey?.updated_at >= survey?.updated_at; else updated" class="page-title">
                  <small>{{t('common.published')}} {{survey.updated_at | date :
                    'longDate'}}</small>
                </span>
                <ng-template #updated>
                  <small>{{t('common.updated')}} {{survey.updated_at | date : 'longDate'}}</small>
                </ng-template>
              </p>
              <div class="mx-2 text-muted">|</div>
              <div *ngIf="survey.rating">
                <span style="margin:15px 0;"> {{roundRating(survey.rating)}} </span>
                <rating ngClass="text-warning" [(ngModel)]="survey.rating" [max]="ratingMax" [readonly]="true">
                </rating>
              </div>
            </div>
            <multisite-loader-globe-animation-detail
            [width]="60"
            [height]="60"
            *ngIf="loadingObject.content">
          </multisite-loader-globe-animation-detail>
          <div class="d-flex flex-row align-items-center justify-content-start align-self-start flex-wrap gap-2 my-2" *ngIf="survey.cultures?.length + survey.topics?.length <= 6">
            <multisite-avatar-pill
            class="mt-1"
            *ngFor="let culture of survey.cultures && survey.cultures.length > 1 ? survey.cultures : []"
            [fallbackImageUrl]="getFallbackFlagUrl()"
            [fallbackTranslationKey]="'tracking.missing'"
            [clickable]="false"
            [imageUrl]="cloudinary_base_url+'w_32,c_fill,ar_1:1,r_max,f_auto/flags/round/'+culture.flag?.hash+'.png'"
            [text]="culture.name"
            [hideBackground]="false"
            [compact]="true"
            ></multisite-avatar-pill>
            <multisite-icon-pill *ngFor="let topic of survey.topics && survey.topics.length > 2 ? survey.topics : []"
              [iconClasses]="topic.icon?.identifier_1 ?? 'fa-solid fa-star'"
              [labelText]="topic.name ?? 'Fake name'"
              [selectable]="false"
              [inverted]="false"
              [shape]="'round'"
              [compact]="true"
              >
            </multisite-icon-pill>
    
    
          </div>

            <div class="mt-3" *transloco="let t">
              <p *ngIf="false" class="h1 font-weight-bold">$122.95</p>
              <div class="d-flex flex-column gap-3" *ngIf="!user && survey.allow_guests">
                <p>{{t('survey.log_in_for_extra_feedback')}}</p>
                <button class="btn btn-primary w-50 w-lg-100 btn-lg" (click)="goToLogin()">
                  <span>{{t('common.log_in')}}</span>
                </button>
              </div>
              <div class="row align-items-center mt-2" *ngIf="!user && !guest && survey.allow_guests">
                <a (click)="makeGuest()" class="link-secondary" *ngIf="!loadingNewGuestError">
                  <span>{{t('authentication.no_thanks_continue_without_login')}}</span>
                </a>
                <span *ngIf="loadingNewGuestError" class="text-warning">
                  {{t(loadingNewGuestError)}}
                </span>
                <span *ngIf="loadingObject.new_guest">
                  <multisite-loader-globe-animation-detail [width]="30"
                    [height]="30"></multisite-loader-globe-animation-detail>
                  <span>{{t('authentication.creating_guest')}}</span>
                </span>
                <!-- <button *ngIf="prefersToContinueWithoutLoggingIn && !loadingObject.new_guest" class="btn btn-primary w-50" (click)="startSurvey()">
                  <span *transloco="let t">{{t('authentication.continue_without_login')}}</span>
                </button> -->
              </div>
              <div class="row align-items-center mt-2" *ngIf="user || guest">
                <div class="col-lg-6">
                  <button class="btn btn-primary w-100 btn-lg" (click)="startSurvey()">

                    <span *ngIf="!user && !guest">{{t('common.start')}}</span>
                    <span *ngIf="user || guest">{{surveyProgressValue > 99 ? t('common.view_feedback') : started ?
                      t('common.continue') : t('common.start')}}</span>

                  </button>

                </div>
                <div class="col-lg-6">
                  <multisite-loader-globe-animation-detail [width]="60" [height]="60"
                    *ngIf="loadingObject.starting"></multisite-loader-globe-animation-detail>
                  <!-- <div class="d-flex align-items-center justify-content-center justify-content-md-start mt-2 mt-md-0">
                    <img width="32px" height="32px" src="https://iili.io/HH2EezN.png" alt="rosette">
                    <span class="ml-1">Required for accreditation</span>
                  </div> -->
                </div>
              </div>
              <div class="d-flex flex-wrap gap-2 my-2 align-items-start justify-content-start">
                <button class="btn btn-sm" routerLink="instructions"
                  *ngIf="survey?.instructions?.length && started && (!surveyProgressValue || surveyProgressValue < 99)">
                  <i class="fa fa-person-chalkboard" aria-hidden="true"></i>
                  {{t('common.instructions')}}
                </button>
                <button class="btn btn-sm" [routerLink]="['language']"
                  *ngIf="started && languagePreferenceOptionIsAvailable() && (!surveyProgressValue || surveyProgressValue < 99)">
                  <i class="fa fa-language" aria-hidden="true"></i>
                  {{t('navigation.language_preferences')}}
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center justify-content-start align-self-start flex-wrap gap-2 my-2" *ngIf="survey.cultures?.length + survey.topics?.length > 6">
      <multisite-avatar-pill
      class="mt-1"
      *ngFor="let culture of survey.cultures && survey.cultures.length > 1 ? survey.cultures : []"
      [fallbackImageUrl]="getFallbackFlagUrl()"
      [fallbackTranslationKey]="'tracking.missing'"
      [clickable]="false"
      [imageUrl]="cloudinary_base_url+'w_32,c_fill,ar_1:1,r_max,f_auto/flags/round/'+culture.flag?.hash+'.png'"
      [text]="culture.name"
      [hideBackground]="false"
      [compact]="true"
      ></multisite-avatar-pill>
      <multisite-icon-pill *ngFor="let topic of survey.topics && survey.topics.length > 2 ? survey.topics : []"
        [iconClasses]="topic.icon?.identifier_1 ?? 'fa-solid fa-star'"
        [labelText]="topic.name ?? 'Fake name'"
        [selectable]="false"
        [inverted]="false"
        [shape]="'round'"
        [compact]="true"
        >
      </multisite-icon-pill>


    </div>

  </div>





  <div class="mt-5 mx-3" *ngIf="contributors?.length">
    <p class="h4 font-weight-bold mb-3">{{contributors.length===1 ?
      t('common.contributor'):t('common.contributors')}}</p>
    <div class="mt-3">
      <!-- TODO group contributors by role: translations, creators etc -->
      <!-- <p class="text-muted mb-1">{{contributors.length===1 ?
        t('common.contributor'):t('common.contributors')}}</p> -->
      <div class="contributors">
        <multisite-avatar-pill *ngFor="let contributor of contributors"
          [fallbackImageUrl]="'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'"
          [fallbackTranslationKey]="'social.verified_user'" [imageUrl]="contributor.user.picture" [text]="contributor.user.pre_honorific+' '+contributor.user.fname+' '+contributor.user.lname+'
              '+contributor.user.post_honorific"></multisite-avatar-pill>
      </div>
    </div>
  </div>

  <div *ngIf="loadingObject.survey">
    <multisite-loader-globe-animation-detail [width]="100" [height]="100"></multisite-loader-globe-animation-detail>
  </div>


</main>

<ng-template #blockingSnapshotModal>
  <div class="modal-header" *transloco="let t">
    <h4 class="h4 font-weight-bold mb-0">
      {{t('survey.snapshot_too_many_same_time')}}
    </h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </button>
  </div>
  <div class="modal-body overflow-hidden" *transloco="let t">

    <p class="my-3">
      <span> {{t('survey.other_snapshot_active')}}</span>
      <span *ngIf="snapshot?.current_accepted"> {{t('survey.cancel_snapshot_help_current_accepted')}}</span>
    </p>

    <div class="my-3">

      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{t('survey.snapshot_active')}}</h5>
          <span class="card-text">{{blockingSnapshot?.label ?? blockingSnapshot?.title}}</span>
        </div>
      </div>
    </div>

    <button class="btn btn-primary" (click)="closeModal()">{{t('common.no_go_back')}}</button>
    <button class="btn btn-warning" (click)="goToSnapshot(blockingSnapshot)">{{t('common.go')}}</button>


  </div>
</ng-template>