import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalMenuTypeOneComponent } from './vertical-menu-type-one.component';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';


@NgModule({
  declarations: [
    VerticalMenuTypeOneComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule
  ],
  exports: [
    VerticalMenuTypeOneComponent,
  ]
})
export class VerticalMenuTypeOneModule { }
