import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ContentModule } from '@frontend/common';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { LoadersModule } from '@frontend/common';



@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    LoadersModule,
  ]
})
export class HomeCulturetttModule { }
