import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

interface ActivityParams {
  model?: string;
  identifier?: any;
  identifier_type?: string;
  activity_type: string;
  properties: {
    site: string;
    subject_type?: string; // for example "page", when the object
    subject_identfier?: any; // for example a slug or id
  };
}

@Injectable({
  providedIn: 'root',
})
export class ActivityLogService {
  sessionActivity = {};

  constructor(
    private http: HttpClient,
    @Inject('appKey') private appKey: string
  ) {}

  cacheLog(params: ActivityParams) {
    let activity_subject = params.model
      ? params.model
      : params.properties.subject_type;
    let activity_identifier = params.identifier
      ? params.identifier
      : params.properties.subject_identfier;
    if (!this.sessionActivity[activity_subject]) {
      this.sessionActivity[activity_subject] = {};
    }
    if (!this.sessionActivity[activity_subject][activity_identifier]) {
      this.sessionActivity[activity_subject][activity_identifier] = {};
    }
    this.sessionActivity[activity_subject][activity_identifier][
      params.activity_type
    ] = new Date();
  }

  log(params: ActivityParams) {
    if (
      (params.model &&
        this.sessionActivity[params.model] &&
        this.sessionActivity[params.model][params.identifier] &&
        this.sessionActivity[params.model][params.identifier][
          params.activity_type
        ]) ||
      (params.properties &&
        params.properties.subject_type &&
        this.sessionActivity[params.properties.subject_type] &&
        this.sessionActivity[params.properties.subject_type][
          params.properties.subject_identfier
        ] &&
        this.sessionActivity[params.properties.subject_type][
          params.properties.subject_identfier
        ][params.activity_type])
    ) {
      return; // we've already logged this, since the last refresh
    }

    if (params['properties']) {
      params['properties']['site'] = this.appKey;
    } else {
      params['properties'] = { site: this.appKey };
    }
    this.cacheLog(params); // see TODO
    return this.http
      .post<{ data: { message: string } }>('api/v1/log-activity', params)
      .pipe(
        map((response) => {
          if (response && response.data) {
            // this.cacheLog(params); // TODO - this is the correct place for this line. But we moved it to before the http request, because the components were sending rapid subsequent duplicate requests. A better solution would be to prevent the component from doing that.
            return response.data.message;
          }
        })
      )
      .subscribe();
  }

  logDefaultSiteVisit() {
    if (this.sessionActivity['site']) {
      return; // we've already logged this, since the last refresh
    }
    let params = {
      activity_type: 'visited',
      properties: {
        site: this.appKey,
        subject_type: 'site',
        subject_identfier: this.appKey,
      },
    };
    return this.log(params);
  }
}
