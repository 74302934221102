import { Component, OnInit } from '@angular/core';

import { FaqService } from '../../../../../../../apps/toolbox/src/app/common/faq.service'
import { Faq } from '@frontend/core';

@Component({
  selector: 'frontend-faq-listing',
  templateUrl: './faq-listing.component.html',
  styleUrls: ['./faq-listing.component.scss']
})
export class FaqListingComponent implements OnInit {

  
  faqs : Faq[] = null;
  faqsSubscription;
  loading = false;
  error = null;
  parser = new DOMParser();
  
  constructor(private faqService : FaqService) { }

  // !!!! FaqService comes from Toolbox!!!! TODO: Migrate to common (newer FaqService)

  getFaqs (){
    this.loading = true;
    this.error = null;
    this.faqsSubscription = this.faqService.getFaqsByTags (this.faqService.getDefaultSiteHelpTags())
      .subscribe(
        response => {
          this.faqs = response;
          this.loading = false;
        },
        error => {
          this.error = error;  
          this.loading = false;
        }
      );
  }

  ngOnInit(): void {;
    this.getFaqs();
  };

}
