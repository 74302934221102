import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PaginationDefaultComponent } from './pagination-default/pagination-default.component';
import { PaginationTypeTwoComponent } from './pagination-type-two/pagination-type-two.component';
import { PaginationTypeThreeComponent } from './pagination-type-three/pagination-type-three.component';
import { PaginationModule as NgxPaginationModule } from 'ngx-bootstrap/pagination';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [
    PaginationDefaultComponent,
    PaginationTypeTwoComponent,
    PaginationTypeThreeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxPaginationModule.forRoot(),
    TranslocoModule,
  ],
  exports: [
    PaginationDefaultComponent,
    PaginationTypeTwoComponent,
    PaginationTypeThreeComponent,
  ]
})
export class PaginationModule { }
