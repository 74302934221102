import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressIndicatorListingCardDoughnutComponent } from './progress-indicator-listing-card-doughnut.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ProgressIndicatorDoughnutModule } from '../progress-indicator-doughnut/progress-indicator-doughnut.module';



@NgModule({
  declarations: [
    ProgressIndicatorListingCardDoughnutComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    ProgressIndicatorDoughnutModule
  ],
  exports: [
    ProgressIndicatorListingCardDoughnutComponent
  ]
})
export class ProgressIndicatorListingCardDoughnutModule { }
