<div class="comment">
  <div class="img-holder">
    <img src="{{poster.picture || defaultAvatar}}" [alt]="poster.fname+' '+poster.lname">
  </div>
  <div class="content-holder">
      <p class="name">{{poster.fname+' '+poster.lname}}</p>
          <div class="d-flex flex-column">
            <textarea
            class="form-control md-textarea pt-0"
            id="commentTextArea"
            rows="5"
            name="message"
            [disabled]="loading"
            [(ngModel)]="message"
            placeholder="{{'common.write_something_here' | transloco}}">
          </textarea>
              <button
              (click)="doPostComment()"
              class="btn btn-primary align-self-end mt-2 text-capitalize btn-sm"
              [disabled]="loading"
                  *transloco="let t">{{t('common.post_a_comment')}}</button>
          </div>
      <hr>
  </div>
</div>