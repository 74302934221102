<div class="wheel-presenter" *transloco="let t">
    <div class="control-holder">
        <button class="btn btn-sm btn-primary" (mouseenter)="stopButtonAnimation = true" (click)="previousStep()">
            <i class="fa-solid fa-arrow-left mr-1"></i>
            <span class="d-none d-lg-inline">{{t('common.previous')}}</span>
        </button>
        <p class="text-center 56 d-block w-100 d-lg-none">
            {{getActiveStepperStep(currentTour)?.translationKey ? t(getActiveStepperStep(currentTour).translationKey) :
            getActiveStepperStep(currentTour)?.text}}
        </p>
        <form *ngIf="tourSelectorForm" [formGroup]="tourSelectorForm" class="form-inline d-none d-lg-block">
            <div class="btn-group tab-buttons" role="group" aria-label="Choose explainer tour: concepts or results">
                <button class="btn btn-sm" [ngClass]="{active: currentTour?.identifier === 'concepts'}"
                    (click)="goToTour(tours?.[0])">{{t('ciprofile_guide.explain_ci_short')}}</button>
                <button class="btn btn-sm" [ngClass]="{active: currentTour?.identifier === 'results'}"
                    (click)="goToTour(tours?.[1])">{{t('ciprofile_guide.explain_result_short')}}</button>
            </div>
        </form>
        <button class="btn btn-sm btn-primary animation-pulse-infinite" [ngClass]="{'animation-reset': stopButtonAnimation}"
            (mouseenter)="stopButtonAnimation = true" (click)="nextPresentationStep()">
            <span class="d-none d-lg-inline">{{t('common.next')}}</span>
            <i class="fa-solid fa-arrow-right ml-1"></i>
        </button>
    </div>
    <div class="stepper-holder" *ngIf="currentTour?.stepperSteps.length > 2">
        <multisite-stepper-type-one (selectStep)="selectStepperStep($event)"
            [steps]="currentTour ? currentTour.stepperSteps : null"></multisite-stepper-type-one>
    </div>
    <div class="row">
        <div class="col-lg-7 d-sm-flex justify-content-center">
            <div class="row  align-items-start">
                <div class="col-4 col-lg-12">
                    <div class="d-none d-lg-block">
                        <ciprofile-wheel [chartSize]="chartSize" [result]="result"
                            [highlightedElementSlug]="currentTour ? currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug : null"
                            (selected)="handleClickFromWheel($event)"></ciprofile-wheel>
                    </div>
                    <div class="d-block d-lg-none">
                        <ciprofile-wheel class="float-end" [chartSize]="100" [scale]="'micro'" [result]="result"
                            [showLabels]="false"
                            [highlightedElementSlug]="currentTour ? currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug : null"
                            (selected)="handleClickFromWheel($event)"></ciprofile-wheel>
                    </div>
                </div>
                <form *ngIf="tourSelectorForm" [formGroup]="tourSelectorForm"
                    class="form-inline d-block d-lg-none col-8">
                    <div class="btn-group tab-buttons" role="group"
                        aria-label="Choose explainer tour: concepts or results">
                        <button class="btn btn-sm w-100" [ngClass]="{active: currentTour?.identifier === 'concepts'}"
                            (click)="goToTour(tours?.[0])">{{t('ciprofile_guide.explain_ci_short')}}</button>
                        <button class="btn btn-sm w-100" [ngClass]="{active: currentTour?.identifier === 'results'}"
                            (click)="goToTour(tours?.[1])">{{t('ciprofile_guide.explain_result_short')}}</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-lg-5">
            <div class="presenter-content">
                <div class="content-holder">
                    <div class="content-body">
                        <h4 class="font-weight-bold"
                            *ngIf="currentTour?.presentationSteps[currentPresentationStepIndex]?.titleTranslationKey">
                            {{t(currentTour.presentationSteps[currentPresentationStepIndex].titleTranslationKey)}}</h4>


                        <div class="w-25 mx-auto"
                            *ngIf="currentTour?.presentationSteps[currentPresentationStepIndex].visual === 'user_progress_score'">
                            <progress-indicator-doughnut [elementWidth]="100"
                                [fillClass]="result && currentTour ? 'traffic-light-'+getScaleSteps(currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug,result?.[currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug]) : 'disabled'"
                                [progressPercent]="result?.[currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug]"
                                [icon]="">
                            </progress-indicator-doughnut>
                        </div>
                        <div
                            *ngIf="currentTour?.presentationSteps[currentPresentationStepIndex].visual === 'user_step_score'">
                            <multisite-step-progress-indicator [stepCount]="8"
                                [stepProgress]="getScaleSteps(currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug,result?.[currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug])"
                                [progressClasses]="'traffic-light-'+getScaleSteps(currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug,result?.[currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug])"></multisite-step-progress-indicator>
                        </div>
                        <p *ngIf="currentTour.presentationSteps[currentPresentationStepIndex].showScoreFeedback"
                            class="my-4">
                            <strong>{{t('test.score_level_feedback.'+getScaleSteps(currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug,result?.[currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug]))}}</strong>
                            <span>{{t('test.score_level_feedback.'+getScaleSteps(currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug,result?.[currentTour.presentationSteps[currentPresentationStepIndex].highlightedElementSlug])+'_comment')}}</span>
                        </p>



                        <p *ngIf="currentTour?.presentationSteps[currentPresentationStepIndex]?.textTranslationKey">
                            {{t(currentTour.presentationSteps[currentPresentationStepIndex].textTranslationKey)}}
                            <a class="link-primary-dark text-decoration-underline ml-2"
                                *ngIf="currentTour.presentationSteps[currentPresentationStepIndex].routeSlug !== undefined && currentTour.presentationSteps[currentPresentationStepIndex].routeSlug !== null"
                                (click)="navigateTo(currentTour.presentationSteps[currentPresentationStepIndex].routeSlug)">
                                <i class="fa-solid fa-circle-info mr-1"></i>{{t('common.more_info')}}
                            </a>
                        </p>
                        <div class="presenter-legend-labelled"
                            *ngIf="currentTour?.presentationSteps[currentPresentationStepIndex].visual === 'colour_legend'">
                            <div class="presenter-legend">
                                <div class="presenter-legend-item" *ngFor="let colour of colours">
                                    <span class="presenter-color-box {{'traffic-light-'+colour}}"></span>
                                    <!-- {{t('ciprofile_guide.traffic_light_colour_score_label_'+colour)}} -->
                                </div>
                            </div>
                            <div class="label-holder">
                                <p>{{t('test.lowest_score')}}</p>
                                <p>{{t('test.highest_score')}}</p>
                            </div>
                        </div>
                        <div class="icon-holder"
                            *ngIf="currentTour?.presentationSteps[currentPresentationStepIndex].visual === 'wheelchair'">
                            <i class="fa-solid fa-wheelchair fa-2xl"></i>
                        </div>
                        <div
                            *ngIf="currentTour?.presentationSteps[currentPresentationStepIndex].visual === 'snapshot' && snapshot">
                            <snapshot-context [date]="snapshot.date" [title]="snapshot.title"
                                [description]="snapshot.description" [cultures]="snapshot.cultures" [primary_culture_id]="snapshot.primary_culture_id"></snapshot-context>
                        </div>
                        <div class="mt-2" *ngIf="currentTour?.presentationSteps[currentPresentationStepIndex].visual === 'completion'">
                            <frontend-link-type-twelve
                            (click)="goToSnapshotHome()"
                            class="locked-content"
                            [titleTranslationKey]="'survey.get_full_results'"
                            [descriptionTranslationKey]="'survey.view_when_profile_complete'"
                            [cta_translationKey]="'survey.complete_your_profile'"
                            (linkClicked)="goToSnapshotHome()"
                            >
                            </frontend-link-type-twelve>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>