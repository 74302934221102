<ng-template #guestLoginModal>
  <div class="modal-header" *transloco="let t">
    <h4 class="modal-title pull-left">{{t('authentication.login_required')}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body" *transloco="let t">
    <h5 class="my-2">{{t('authentication.log_in_or_register')}}</h5>
    <div class="d-flex gap-2 flex-wrap">
      <button class="btn btn-secondary"
        (click)="navigateTo(['/register'])">{{t('common.register')}}</button>
      <button class="btn btn-primary"
        (click)="navigateTo(['/login'])">{{t('common.log_in')}}</button>
    </div>
  </div>
</ng-template>