<div class="container" *transloco="let t">
  <div class="">
    <div class="d-flex flex-md-row flex-column flex-wrap justify-content-between my-4 sticky-top">
      <div>
        <span *ngIf="draftBrand">
          <button *ngIf="!saveAsNewBrandModeActive" class="btn btn-light w-100"
            (click)="openModal(restoreDefaultBrandModal)" aria-label="Restore default brand" [disabled]="loading">
            {{ t('branding.restore_default_brand') }} <small>{{brand?.slug}}</small>
          </button>
        </span>
      </div>
      <div class="d-flex flex-row flex-wrap" role="group" aria-label="Group of buttons for editing the draft brand">
        <button class="btn btn-warning w-sm-100 w-lg-auto" *ngIf="draftBrand && !saveAsNewBrandModeActive" aria-label="Delete draft brand"
          (click)="deleteDraft()" [disabled]="loading">
          {{ t('common.cancel_changes') }}
        </button>

        <button class="btn btn-info w-sm-100 w-lg-auto" *ngIf="previewMode()" (click)="clearPreview()"
          aria-label="Clear preview of draft brand" [disabled]="loading">
          {{ t('common.clear_preview') }}
        </button>


        <button class="btn btn-primary w-sm-100 w-lg-auto" aria-label="Save as new brand" (click)="openModal(newBrandSlugInstructionModal)"
          [disabled]="loading || saveAsNewBrandModeActive">
          <multisite-loader-globe-animation-detail *ngIf="loading" width="24" height="24"
            class="d-inline-block align-middle"></multisite-loader-globe-animation-detail>
          {{ t('branding.save_as_new_brand') }}
        </button>


        <button class="btn btn-primary w-sm-100 w-lg-auto" *ngIf="draftBrand && !saveAsNewBrandModeActive"
          (click)="saveBrand(draftBrand, false)" aria-label="Save draft as my brand" [disabled]="loading">
          <multisite-loader-globe-animation-detail *ngIf="loading" width="24" height="24"
            class="d-inline-block align-middle"></multisite-loader-globe-animation-detail>
          {{ t('common.save_changes') }} <small>{{brand?.slug}}</small>
        </button>


        <button class="btn btn-primary w-sm-100 w-lg-auto" *ngIf="!draftBrand && !saveAsNewBrandModeActive"
          (click)="copySavedBrandToDraft()" [disabled]="loading">
          {{ t('common.edit') }}
        </button>
      </div>

    </div>
  </div>




  <!-- Alert -->

  <div *ngIf="!alertsDismissed" class="mt-3">
    <alert [type]="masterTask.status" [dismissible]="alertsCollapsed" (onClosed)="dismissTaskAlert()"
      *ngIf="tasks?.length > 1">
      <div class="d-flex justify-content-between">
        <span>{{t(masterTask.alert_text_translation_key)}}&nbsp;{{tasks.length}}</span>
        <a (click)="alertsCollapsed = !alertsCollapsed"
          class="text-nowrap link-primary-dark fw-bold d-flex align-items-center gap-2">{{
          alertsCollapsed
          ? t('common.show_more')
          : t('common.show_less')
          }}
          <span *ngIf="alertsCollapsed"><i class="fa-solid fa-angle-down"></i></span>
          <span *ngIf="!alertsCollapsed"><i class="fa-solid fa-angle-up"></i></span>
        </a>
      </div>
    </alert>
    <div *ngFor="let alert of tasks" [collapse]="alertsCollapsed" [isAnimated]="true">
      <alert [type]="alert.status" [dismissible]="tasks?.length === 1" (onClosed)="dismissTaskAlert()">
        <div class="d-flex justify-content-left">
          <strong class="mr-4">{{t(alert.alert_category_label_translation_key)}}</strong>
          <span>{{t(alert.alert_text_translation_key)}}</span>
        </div>
      </alert>
    </div>
    <alert [type]="'warning'" *ngIf="error" [dismissOnTimeout]="3500"><span>{{t(error)}}</span>
    </alert>
  </div>
  <!-- END OF Alert -->

  <div class="tabset">
    <multisite-tabs-basic [tabs]="tabs" [activeTabIndex]="activeTab?.id-1" (tabSelected)="activateTab($event,null)"
      *ngIf="tabs">
    </multisite-tabs-basic>
  </div>


  <div class="row" *ngIf="brand;else elseBlock1">
    <multisite-brand-colour-editor *ngIf="activeTab?.id === 1" [brand]="brand" [draftBrand]="draftBrand"
      [draftBrandEditMode]="draftBrandEditMode" (changeDraftBrandEditMode)="setDraftEditingMode($event)"
      (onChangedDraftBrand)="onDraftBrandChange($event)"></multisite-brand-colour-editor>
    <multisite-brand-font-editor *ngIf="activeTab?.id === 2" [font]="draftBrand ? draftBrand.font : brand.font"
      [disabled]="!draftBrand" (updateFont)="updateFont($event)"></multisite-brand-font-editor>
    <multisite-brand-hub-visibility-editor *ngIf="activeTab?.preTranslatedText=== 'Hub visibility'"
      [visibility]="draftBrand ? draftBrand.category : brand.category"
      [disabled]="!draftBrand || draftBrand.category === 'primary'"
      (updateVisibility)="updateHubVisibility($event)"></multisite-brand-hub-visibility-editor>
    <multisite-brand-logos *ngIf="activeTab?.id === 3" [logo_banner]="brand.logo_banner"
      [logo_square]="brand.logo_square" [logo_banner_inverse]="brand.logo_banner_inverse"
      [logo_square_inverse]="brand.logo_square_inverse" [pending]="draftBrand ? brand.pending:null"
      [pending_updates]="draftBrand?.pending_updates" [disabled]="!draftBrand" [loading]="loading" [error]="error"
      (formDataChanged)="onLogoFormDataChanged($event)" (removePendingItem)="removeTemporaryLogoFile($event)"
      (markSavedLogoForDeletion)="toggleMarkSavedLogoForDeletion($event)"></multisite-brand-logos>
    <multisite-brand-slug-editor *ngIf="activeTab?.preTranslatedText=== 'Slug editor'" [slugMinLength]="slugMinLength"
      (submitNameAndSlug)="saveDraftAsNew($event)" (cancelNewBrandMode)="setModeSaveAsNewBrand(false)"
      [error]="error"></multisite-brand-slug-editor>
    <multisite-branding-demo *ngIf="activeTab?.preTranslatedText === 'Preview'"></multisite-branding-demo>

  </div>
  <ng-template #elseBlock1>
    <div class="row">
      {{ t('common.no_results') }}
    </div>
  </ng-template>


</div>
<ng-template #restoreDefaultBrandModal>
  <div class="modal-header" *transloco="let t">
    <h4 id="dialog-static-name" class="modal-title pull-left">{{t('common.are_you_sure')}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModal()">
    </button>
  </div>
  <div class="modal-body" *transloco="let t">
    <p>{{ t('branding.restore_default_brand_help_part1', {'subdomain':brand?.slug}) }}</p>
    <p class="my-2">{{ t('branding.restore_default_brand_help_part2') }}</p>
    <div>
      <button type="button" class="btn btn-primary btn-sm mt-2" [disabled]="loading"
        (click)="closeModal()">{{t('common.back')}}</button>
      <multisite-loader-globe-animation-detail *ngIf="loading" width="60" height="60"
        class="d-inline-block align-middle"></multisite-loader-globe-animation-detail>
      <button type="button" class="btn btn-danger btn-sm mt-2 float-end" [disabled]="loading"
        (click)="restoreDefaultBrand()">{{t('common.restore')}}</button>
    </div>

  </div>
</ng-template>
<ng-template #newBrandSlugInstructionModal>
  <div class="modal-header" *transloco="let t">
    <h4 id="dialog-static-name" class="modal-title pull-left">{{t('branding.save_as_new_brand')}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModal()">
    </button>
  </div>
  <div class="modal-body" *transloco="let t">
    <p>{{ t('branding.first_create_web_address') }}</p>
    <p class="my-2">{{ t('branding.web_address_must_differ', {'slug':brand?.slug}) }}</p>
    <div>
      <button type="button" class="btn btn-primary btn-sm mt-2"
        (click)="closeModal()">{{t('common.cancel')}}</button>
      <button type="button" class="btn btn-warning btn-sm mt-2 float-end"
        (click)="setModeSaveAsNewBrand(true)">{{t('common.continue')}}</button>
    </div>

  </div>
</ng-template>