import { UserExtraLiteAdmin } from "../auth";
import { Brand } from "../branding";
import { MetaTextMultilingual } from "../content/metaText-multilingual.model";
import { CloudinaryMediaAsset } from "../models";
import { Programme } from "../programmes";
import { Setting } from "../settings";

export class Hub {

    id : number;
    name : string;
    strapline : string;
    description : string;
    type : string;
    owners : UserExtraLiteAdmin[];
    managers : UserExtraLiteAdmin[];
    contact_persons : UserExtraLiteAdmin[];
    brands : Brand[];
    media: CloudinaryMediaAsset[];
    programmes_count : number;
    programmes : Programme[];
    cohorts : any;
    slug : string;
    created_at : string;
    hub_trainers? : UserExtraLiteAdmin[];
    hub_client_trainers? : UserExtraLiteAdmin[];
    event_and_programme_trainers? : UserExtraLiteAdmin[];
    all_trainers? : UserExtraLiteAdmin[];
    primary_colour? : string;
    logo_banner? : string;
    cohorts_count? : number; // temporary; may be removed later; this is part of 'social proof' statistics for Lite listings of Hubs
    multilingualMetaTexts? : MetaTextMultilingual[];
    settings? : Setting[];
}