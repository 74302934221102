import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessCodeUserInputComponent } from './access-code-user-input/access-code-user-input.component';
import { TranslocoModule } from '@jsverse/transloco';
import { AccessCodeFormModule } from './access-code-form/access-code-form.module';



@NgModule({
  declarations: [
    AccessCodeUserInputComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    AccessCodeFormModule,
  ],
  exports: [
    AccessCodeUserInputComponent
  ]
})
export class AccessModule { }
