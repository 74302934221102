import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, MediaService } from '@frontend/common';

import { User } from '@frontend/common';
import { MainNavigationLink } from '@frontend/common';

@Component({
  selector: 'multisite-user-connections-home',
  templateUrl: './user-connections-home.component.html',
  styleUrls: ['./user-connections-home.component.scss']
})
export class UserConnectionsHomeComponent implements OnInit, OnDestroy {

  user: User = null;
  userSubscription;
  isAuthenticated: boolean = false;
  loading = false;
  error = null;
  navigationLinks: MainNavigationLink[];

  constructor(
    private authService: AuthService,
    private mediaService: MediaService,
  ) { 
    this.navigationLinks = [
      // TODO: investigate why Angular treats routerLinkValue: ['password'] as a primitive string, not as an array of strings
      {labelText: null, labelLanguageKey : 'common.raters', routerLinkValue: ['raters'], condition: ''},
      // {labelText: null, labelLanguageKey : 'common.email_address', routerLinkValue: ['email'], condition: ''},
    ];
  }
  getDefaultAvatar (){
    return this.mediaService._fallback_avatar_url;
  }

  ngOnInit() {
    this.userSubscription = this.authService.user.subscribe(
      (user) => {
        this.user = user;
      },
      (error) => {
        this.error = error;
      }
    );
    this.authService.getUser(true).subscribe(); // gets the full editable version, with email address  
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

}
