import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'frontend-likes-listing-mini',
  templateUrl: './likes-listing-mini.component.html',
  styleUrls: ['./likes-listing-mini.component.scss']
})
export class LikesListingMiniComponent implements OnInit {
  @Input() titleClass : string;
  @Input() liked : boolean;
  @Input() loading : boolean;
  @Input() likes : any[];
  @Input() maxLikesDefaultView : number;
  @Output() toggleLike = new EventEmitter();

  constructor() { }

  toggle(){
    this.toggleLike.emit();
  }

  ngOnInit(): void {
  }

}
