<form [formGroup]="cultureSelectorForm" role="form"class="d-flex flex-column position-relative gap-3" *transloco="let t">
  
  <label *ngIf="labelTranslationKey" for="name" class="form-label">{{ t(labelTranslationKey)
  }}<span class="required-sign">*</span></label>
  <input formControlName="culture"
     [typeahead]="availableCultures"
     [typeaheadOptionsLimit]="7"
     [typeaheadMinLength]="0"
     (typeaheadOnSelect)="onSelect($event)"
     name="name"
     typeaheadOptionField="name"
     [placeholder]="placeholderTranslationKey ? t(placeholderTranslationKey) + ' | ' + t('common.start_typing') : t('common.start_typing')"
     class="form-control"
     [optionsListTemplate]="customListTemplate"
     autocomplete="off" 
     >

    <ng-template #customListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
      <ul class="custom-list-group culture-group" style="overflow-y: auto;">
        <li class="custom-list-group-item"
            *ngFor="let match of matches"
            [class.active]="typeaheadTemplateMethods.isActive(match)"
            (click)="typeaheadTemplateMethods.selectMatch(match, $event)"
            (mouseenter)="typeaheadTemplateMethods.selectActive(match)">
            <img [src]="cloudinary_base_url+'w_32,c_fill,ar_1:1,r_max,f_auto/flags/'+match.item.flag?.hash+'.svg'" class="mr-1">
          {{ match.item.name }}
        </li>
      </ul>
    </ng-template>
  <div class="contributors" *ngIf="selectedCultures?.length">
    <span *ngFor="let culture of selectedCultures"
      [ngClass]="{ disabled: disabled || loading }">
      <multisite-avatar-pill
        (selected)="deSelectCulture(culture)"
        [removalMode]="!disabled && !loading"
        [fallbackImageUrl]="'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'"
        [fallbackTranslationKey]="'social.verified_user'"
        [cursorNone]="!disabled && !loading"
        [imageUrl]="'https://cultureconnector.com/app/images/flags/'+culture.iso+'.png'"
        [text]="culture.name"></multisite-avatar-pill>
  </span>
  </div>
</form>