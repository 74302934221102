import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'frontend-pagination-default',
  templateUrl: './pagination-default.component.html',
  styleUrls: ['./pagination-default.component.scss']
})
export class PaginationDefaultComponent implements OnInit {
  @Input() classes; // for example 'pg-blue justify-content-center'
  @Input() loading;
  @Input() meta;
  @Input() links;
  @Input() lastPage; // so we can detect changes
  @Output() changePage = new EventEmitter<number>();

  pageList;

  constructor() { }


  clickPage = function (newPage){
    this.changePage.emit(newPage);
  };
  buildPageList = function (){
      this.pageList = Array.from(Array(this.meta.last_page).keys()).map((x,index)=>index+1);
  };

  ngOnChanges (changesObject) {
      if (changesObject.lastPage && changesObject.lastPage.currentValue){
          this.buildPageList();
      };
  };

  ngOnInit(): void {
  }

}
