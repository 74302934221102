import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Link } from '@frontend/core';
import { LinksService } from '@frontend/shared';
import { Subscription } from 'rxjs';
import { Hub, HubService } from '../../hubs';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
import { BrandColour } from '../brand-colour.model';
import { BrandDisplayService } from '../brand-display.service';
import { BrandEditingService } from '../brand-editing.service';
import { BrandResponse } from '../brand-response.model';
import { Brand } from '../brand.model';

@Component({
  selector: 'multisite-brands-new',
  templateUrl: './brands-new.component.html',
  styleUrls: ['./brands-new.component.scss']
})
export class BrandsNewComponent implements OnInit {

  hub : Hub;
  hubSubscription : Subscription;
  savingBrandSubscription: Subscription;
  linksSubscription: Subscription;
  slugForm: FormGroup;
  slugAvailabilitySubscription: Subscription;
  slugMinLength: number;
  rulesCollapsed : boolean = true;
  collapsedContent : {[key:string]:boolean} = {
    rules: true,
    help: true,
  }
  slugRuleCount:number = 3;
  slugHelpCount:number = 3;
  slugRulesEmptyIterator: number[];
  slugHelpEmptyIterator: number[];
  error:string;
  loading: boolean = false;
  draftBrand: Brand;
  links: Link[] = [];

  constructor(
    private hubService : HubService,
    private breadcrumbService : BreadcrumbService,
    private router : Router,
    private route : ActivatedRoute,
    private brandDisplayService : BrandDisplayService,
    private brandEditingService : BrandEditingService,
    private linksService: LinksService,

  ) { }

  doCancelNewBrandMode (){
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  toggleCollapsedContent (contentName){
    for (let content in this.collapsedContent){
      if (contentName === content){
        this.collapsedContent[content] = !this.collapsedContent[content];
      } else {
        this.collapsedContent[content] = true;
      }
    }
  }

  makeColourVariants (newHex : string, draftBrand: Brand){
      let colourAndvariants  : BrandColour[] = this.brandDisplayService.getColourVariants ('primary',newHex);
      draftBrand = this.brandEditingService.applyNewVariantsToBrand (draftBrand, colourAndvariants, true);
      return draftBrand;
  }
  transformAndCacheHub (hub:Hub,allHubBrandsFromBackend:BrandResponse[]){
    let hubBeforeTransformingBrands = Object.assign(hub,{brands:allHubBrandsFromBackend})
    hub = this.hubService.transformManagedHubBrands(hubBeforeTransformingBrands);
    this.hubService.cacheManagedHub(hub);
    return hub;
  }

  submit(){
      this.draftBrand = this.makeColourVariants(this.slugForm.controls.primary_colour.value, this.draftBrand);
      this.draftBrand.slug = this.slugForm.controls.slug.value;
      this.draftBrand.name = this.slugForm.controls.name.value;
      this.saveBrand(this.draftBrand);
  }

  saveBrand(brand:Brand){
    this.error = null;
    this.loading = true;
    let newBrandObject : FormData = this.brandEditingService.prepareDraftBrandForSaving(brand,null);
    newBrandObject.append('hub_id',this.hub.id?.toString());
    this.savingBrandSubscription = this.brandEditingService.saveNewBrand(newBrandObject).subscribe(
      (allHubBrands)=>{
        this.hub = this.transformAndCacheHub(this.hub,allHubBrands);
        let newestBrand : Brand;
        if (this.hub.brands?.length){
          newestBrand = this.hub.brands.sort((a, b) =>  new Date(a.created_at).getMilliseconds() - new Date(b.created_at).getMilliseconds() ).reverse()[0];
        }
        this.loading = false;
        if (newestBrand){
          this.router.navigate(['../'+newestBrand.slug], {relativeTo:this.route});
        }
      },
      error => {
        this.error = error
      }
    );
  }

  setUpForm() {
    this.slugMinLength = this.hubService.brandSlugMinLengths[this.hub.type];
    this.slugForm = new FormGroup({
      slug: new FormControl(null, [Validators.required, Validators.minLength(this.slugMinLength), Validators.maxLength(30), Validators.pattern(/^(?![\s\S]*[ .\/\\\\])[\s\S]*$/)]), // regex for spaces and dots
      name: new FormControl(null, [Validators.required, Validators.minLength(this.slugMinLength), Validators.maxLength(100)]),
      primary_colour: new FormControl(this.draftBrand.colours[1].hex, [Validators.required, Validators.pattern(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)]),
    });
    this.slugForm.controls.slug.valueChanges.subscribe((value) => {
      this.error = null;
    });
    this.slugForm.controls.name.valueChanges.subscribe((value) => {
      this.error = null;
    });
  }

  getLinks(){
    let linksRequest = [
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
    ]
    this.linksSubscription = this.linksService.getDynamicLinks(linksRequest).subscribe(links =>{
      this.links = links;
    })
  }

  getHub (slug){
    this.hubService.getManagedHub(slug).subscribe(response => {
      this.hub = response;
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'hub',fragmentName:this.hub.name});
      this.setUpForm();
      this.getLinks();
    })
  }

  ngOnInit(): void {
    this.draftBrand = this.brandDisplayService.getNewDraftBrand();
    this.route.url.subscribe(() => {
      let fullUrlParts = this.router.routerState.snapshot.url
        .toLowerCase()
        .split('/')
        .filter((s) => s);
      this.getHub(fullUrlParts[1]);
    });
    this.error = null;
    this.slugRulesEmptyIterator = new Array(this.slugRuleCount).fill(0).map((_, index) => index + 1);
    this.slugHelpEmptyIterator = new Array(this.slugHelpCount).fill(0).map((_, index) => index + 1);
  }
  ngOnDestroy () : void {
    if (this.hubSubscription){
      this.hubSubscription.unsubscribe();
    }
    if (this.savingBrandSubscription){
      this.savingBrandSubscription.unsubscribe();
    }
    if (this.linksSubscription){
      this.linksSubscription.unsubscribe();
    }
  }

}
