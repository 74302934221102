import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'progress-indicator-card-simple',
  templateUrl: './progress-indicator-card-simple.component.html',
  styleUrls: ['./progress-indicator-card-simple.component.scss']
})
export class ProgressIndicatorCardSimpleComponent implements OnInit {

  @Input() titleText: string;
  @Input() titleTranslationKey: string;
  @Input() value: number;
  @Input() unit: string; // '%' or 'mins' or whatever

  constructor() { }

  ngOnInit(): void {
  }

}
