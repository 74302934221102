import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'multisite-verify-email-landing-page',
  templateUrl: './verify-email-landing-page.component.html',
  styleUrls: ['./verify-email-landing-page.component.scss'],
})
export class VerifyEmailLandingPageComponent implements OnInit, OnDestroy {

  urlFragments = {
    'basePath': 'api/v1/email/verify/',
    'id': null,
    'hash': null,
    'expires': null,
    'signature': null,
  };
  fullUrl : string = null;
  verifySub;
  loginSub;
  loading = false;
  success = null;
  error = null;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router : Router
    ) { }
  
  verify (){
    this.loading = true;
    this.verifySub = this.authService.verify(this.fullUrl).subscribe(
      response => {
        this.loading = false;
        this.authService.setVerificationSuccess(true);
        this.router.navigate(['/login']);
      },
      errorMessage => {
        this.error = errorMessage;
        this.loading = false;
      }
    );

  }

  generateUrl (){
    this.urlFragments.id = this.route.snapshot.params['user_id'];
    this.urlFragments.hash = this.route.snapshot.params['hash'];
    this.urlFragments.expires = this.route.snapshot.queryParams['expires'];
    this.urlFragments.signature = this.route.snapshot.queryParams['signature'];
    this.fullUrl = this.urlFragments.basePath+this.urlFragments.id+'/'+this.urlFragments.hash+'?expires='+this.urlFragments.expires+'&signature='+this.urlFragments.signature;
  }

  ngOnInit(): void {
   this.generateUrl();
   this.verify();
  }
  ngOnDestroy () : void {

  }
}
