
  <div class="container-fluid">
    <p>Paragraph 1</p>
    <p>Paragraph 2</p>
    <p>Paragraph 3</p>
    <p>Paragraph 4</p>
    <p>Paragraph 5</p>
    <p>Paragraph 6</p>
    <div *ngIf="showWarningAlert" class="m-5">
      <h2>This alert has a dismiss feature</h2>
      <alert [type]="'warning'" [dismissible]="true" (onClosed)="onClosed('warning')">This is a warning</alert>
    </div>
    <div *ngIf="showSuccessAlert" class="m-5">
      <h2>This alert is automatically dismissed after a timeout</h2>
      <alert [type]="'success'" [dismissOnTimeout]="3500" (onClosed)="onClosed('success')">This is good news!</alert>
    </div>
    <button class="btn btn-primary" (click)="toggleLoop()">Toggle loop</button>
  </div>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, facilis.</p>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, facilis.</p>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, facilis.</p>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, facilis.</p>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, facilis.</p>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, facilis.</p>
  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, facilis.</p>
