import { Component, Input, OnChanges } from '@angular/core';
import { Culture } from '../../../cultures';

@Component({
  selector: 'snapshot-context',
  templateUrl: './snapshot-context.component.html',
  styleUrls: ['./snapshot-context.component.scss']
})
export class SnapshotContextComponent implements OnChanges {

  @Input() date: Date;
  @Input() label: string;
  @Input() title: string;
  @Input() description: string;
  @Input() cultures: Culture[];
  @Input() primary_culture_id: number;

  constructor() { }

  ngOnChanges(changeObject): void {
    // put the primary culture first
    if((changeObject.cultures || changeObject.primary_culture_id) && this.cultures?.length > 1 && this.primary_culture_id){
      const primaryCultureIndex = this.cultures.findIndex(c=>c.id == this.primary_culture_id);
      if (primaryCultureIndex > 0){
        const primaryCulture = this.cultures[primaryCultureIndex];
        this.cultures = this.cultures.length === primaryCultureIndex-1 ? 
        this.cultures.slice(0,primaryCultureIndex) :
         [...this.cultures.slice(0, primaryCultureIndex), ...this.cultures.slice(primaryCultureIndex + 1)];
         this.cultures.unshift(primaryCulture);
      }
    }
  }

}
