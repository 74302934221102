import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiPaginationMeta } from '../api-pagination-meta.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'frontend-pagination-type-two',
  templateUrl: './pagination-type-two.component.html',
  styleUrls: ['./pagination-type-two.component.scss']
})
export class PaginationTypeTwoComponent implements OnInit {
  @Input() classes; // for example 'pg-blue justify-content-center'
  @Input() loading;
  @Input() meta: ApiPaginationMeta;
  @Input() links;
  @Input() lastPage; // so we can detect changes
  @Output() changePage = new EventEmitter<number>();

  pageList;

  constructor() { }


  // clickPage = function (newPage){
  //   this.changePage.emit(newPage);
  // };
  pageChanged(event: PageChangedEvent): void {
    this.changePage.emit(event.page);
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
  }
  buildPageList = function (){
      this.pageList = Array.from(Array(this.meta.last_page).keys()).map((x,index)=>index+1);
  };

  ngOnChanges (changesObject) {
      if (changesObject.lastPage && changesObject.lastPage.currentValue){
          this.buildPageList();
      };
  };

  ngOnInit(): void {
  }

}
