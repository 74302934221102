import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserScoreDisplayComponent } from './user-score-display.component';



@NgModule({
  declarations: [UserScoreDisplayComponent],
  exports: [UserScoreDisplayComponent],
  imports: [
    CommonModule
  ]
})
export class UserScoreDisplayModule { }
