<div [ngClass]="cardClasses()">
  <div class="row">
    @if(media()){
    <div class="col-lg-6">
      <img
        class="w-100 mt-2 rounded"
        [src]="
          media().asset_url && imageTransformations()
            ? media().base_url + imageTransformations() + media().asset_url
            : media().file_url
        "
        [alt]="media().description"
      />
    </div>
    }
    <div class="col-lg-{{ media() ? '6' : '12' }} mt-2 mt-lg-0">
      <div class="text-list border-0 p-0">
        @if(titleText() || titleTranslationKey()){
        <p class="mb-3 {{ titleClasses() }}" *transloco="let t">
          {{ titleTranslationKey() ? t(titleTranslationKey()) : titleText() }}
        </p>
        }
        @for (listItem of list(); track $index) {
          <div class="text-list-item">
            @if(listItem.icon){
              @if(listItem.icon.identifier_1){
                <i class="{{ listItem.icon.identifier_1 }} mt-1"></i>
              } @else if (listItem.icon.identifier_2) {
                <img
                  [src]="
                    cloudinary_base_url +
                    'w_32,c_fill,ar_1:1,r_max,f_auto/' +
                    listItem.icon.identifier_2 +
                    '.svg'
                  "
                  class="mr-1"
                />
              }
            } @else if(fallbackIcon()){
            <i class="{{ fallbackIcon() }} mt-1"></i>
            }
            <span>{{ listItem.text }}</span>
          </div>
        }
      </div>
    </div>
  </div>
</div>
