<div class="icon-cta-card-link" *transloco="let t">
  <div class="d-flex gap-3">
    <div class="icon-box" *ngIf="iconClasses">
      <i class="{{iconClasses}}"></i>
    </div>
    <div class="text">
      <p class="title h5 font-weight-bold">{{ titleTranslationKey ? t(titleTranslationKey) : titleText }}</p>
      <p>{{ descriptionTranslationKey ? t(descriptionTranslationKey) : descriptionText }}</p>
  </div>
  <button type="button" [class]="ctaButtonClasses" (click)="doLinkClick()">
    {{cta_translationKey ? t(cta_translationKey) : cta_text }}
    <i class="fa-solid fa-arrow-right ml-2"></i>
  </button>
</div>
