<div class="carousel-text-link-card" *transloco="let t">
  <div class="header mb-2">
    <p class="title">{{titleTranslationKey ? t(titleTranslationKey) : titleText }}</p>
    <div class="control">
      <button type="button" class="btn btn-link" (click)="gotoPrevious()">
        <i class="fa-solid fa-angle-left"></i>
      </button>
      <p class="pagination" *ngIf="items?.length > 1">
        <span>{{currentItemIndex+1}}</span>/<span class="static">{{items?.length}}</span>
      </p>
      <button type="button" class="btn btn-link" (click)="gotoNext()">
        <i class="fa-solid fa-angle-right"></i>
      </button>
    </div>
  </div>
  <div class="badges-holder" *ngIf="currentItem()?.tags?.length">
    <span class="badge badge-pill badge-info disabled" *ngFor="let tag of currentItem().tags">{{tag.name}}</span>
  </div>

  <div class="body">
    <h4 class="h4 font-weight-bold">{{currentItem()?.titleTranslationKey ? t(currentItem().titleTranslationKey) : currentItem()?.titleText }}</h4>
    <!-- <p>{{currentItem()?.descriptionTranslationKey ? t(currentItem().descriptionTranslationKey) : currentItem()?.descriptionText }}</p> -->
    <p>{{ currentItem()?.descriptionTranslationKey ? getTranslatedText(currentItem()?.descriptionTranslationKey) : currentItem()?.descriptionText }}</p>

  </div>

  <button class="btn btn-sm btn-light w-50 text-center" (click)="doLinkClick()">
    {{currentItem()?.cta_translationKey ? t(currentItem().cta_translationKey) : currentItem()?.cta_text }}
  </button>
</div>
