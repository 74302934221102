<div class="product-header" *transloco="let t">
  <div class="row flex-md-row-reverse mt-4 gap-4 gap-lg-0">
    <div class="col-lg-4">
      <div class="player-holder">
        <div class="embed-responsive" *ngIf="video()">
          <frontend-video-player-type-two
            [video]="video()"
            [classes]="'mb-3'"
          ></frontend-video-player-type-two>
        </div>
        <p *ngIf="price()" class="h1 font-weight-bold">{{price()}}</p>
        @if(progressValue()){
          <progress-indicator-card-simple
            [titleTranslationKey]="progressTitleTranslationKey()"
            [value]="progressValue()"
            [unit]="progressUnit()"
          ></progress-indicator-card-simple>
        }

        <div class="d-flex flex-column align-items-end w-100 gap-3 mt-3">
          @if(showLoginOption()){
            <p>{{t('survey.log_in_for_extra_feedback')}}</p>
            <button class="btn btn-primary w-100 btn-lg" (click)="onLoginButtonClicked()">
              <span>{{t('common.log_in')}}</span>
            </button>
          }
          @if(ctaButton()){
            <button class="{{ctaButton().classes}}" [disabled]="ctaButtonDisabled()" (click)="onButtonClicked()">
              @if(ctaButton().iconClasses){
                <i class="{{ctaButton().iconClasses}} mr-1"></i>
              }
              <span>{{t(ctaButton().labelTranslationKey)}}</span>
            </button>
          }
          @if(showContinueAsGuestOption()){

            <div class="row align-items-center mt-2">
              <a (click)="onMakeGuest()" class="link-secondary" *ngIf="!newGuestError()">
                <span>{{t('authentication.no_thanks_continue_without_login')}}</span>
              </a>
              <span *ngIf="newGuestError()" class="text-warning">
                {{t(newGuestError)}}
              </span>
              <div class="my-2" *ngIf="!loading() && generalError()">
                <alert [type]="'warning'" [dismissible]="true">
                  <span [innerHTML]="t(generalError())"></span>
                </alert>
              </div>
              <span *ngIf="guestLoading()">
                <multisite-loader-globe-animation-detail [width]="30"
                  [height]="30"></multisite-loader-globe-animation-detail>
                <span>{{t('authentication.creating_guest')}}</span>
              </span>
              <!-- <button *ngIf="prefersToContinueWithoutLoggingIn && !loadingObject.new_guest" class="btn btn-primary w-50" (click)="startSurvey()">
                <span *transloco="let t">{{t('authentication.continue_without_login')}}</span>
              </button> -->
            </div>
          }

        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="d-flex gap-1 flex-column">
        <div class="d-flex gap-1 flex-column">
          <h2 class="h1 font-weight-bold">{{title()}}</h2>
          @if(subtitle){
            <h4>{{subtitle()}}</h4>
          }
          <p class="text-muted">
            {{description()}}
          </p>
        </div>
        @if(tags()?.length){

          <div class="badges mt-3">
            @for (tag of tags(); track $index) {

              <span class="mr-1" [ngClass]="[tag.type == 'topic' ? 'badge-info' : 'badge-primary', 'badge badge-pill']">{{tag.name}}</span>
                <!-- <span class="badge badge-pill badge-more">
                  <i class="fa-solid fa-angle-right"></i>
              </span>  -->
            }

          </div>
        }
        <!-- 
                    <div
                class="d-flex flex-row align-items-center justify-content-start align-self-start flex-wrap gap-2 my-2">
                <multisite-avatar-pill class="mt-1"
                  *ngFor="let culture of course.cultures && course.cultures.length > 1 ? course.cultures : []"
                  [fallbackImageUrl]="getFallbackFlagUrl()" [fallbackTranslationKey]="'tracking.missing'"
                  [clickable]="false"
                  [imageUrl]="cloudinary_base_url+'w_32,c_fill,ar_1:1,r_max,f_auto/flags/round/'+culture.flag?.hash+'.png'"
                  [text]="culture.name" [hideBackground]="false" [compact]="true"></multisite-avatar-pill>
                <multisite-icon-pill
                  *ngFor="let topic of course.topics && course.topics.length > 1 ? course.topics : []"
                  [iconClasses]="topic.icon?.identifier_1 ?? 'fa-solid fa-star'" [labelText]="topic.name ?? 'Fake name'"
                  [selectable]="false" [inverted]="false" [shape]="'round'" [compact]="true">
                </multisite-icon-pill>

                <multisite-icon-pill *ngFor="let tag of course.tags?.length ? course.tags : []"
                  [iconClasses]="tag.icon_type === 'fa' ? tag.identifier_1 : 'fa-solid fa-star'"
                  [labelText]="tag.name ?? 'Unknown tag'" [selectable]="false" [inverted]="false" [shape]="'round'"
                  [compact]="true">
                </multisite-icon-pill>


              </div>
               -->
        @if(date() || rating){
          <div class="d-flex flex-row align-items-center">
            @if(date()){
              <p class="card-text">
                <span class="">
                  <small>{{t(dateLabelTranslationKey())}} {{date() | date : 'longDate'}}</small>
                </span>
              </p>
            }
            @if(date() && rating){
              <div class="mx-2 text-muted">|</div>
            }
            @if(rating){
              <span class="mr-2"> {{rating}} </span>
              <rating ngClass="text-warning" [(ngModel)]="rating" [max]="ratingMax()" [readonly]="true"></rating>
            }
          </div>
        }
        @if(iconLinks()?.length){
          <div>
            <p class="card-text">
              @if(iconLinksLabelTranslationKey()){
                @if(iconLinks()?.length === 1){
                  <a
                    class="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                    [tooltip]="iconLinks()[0].text"
                    (click)="onSelectIconLink(iconLinks()[0])">
                      {{t(iconLinksLabelTranslationKey())}}
                    </a>
                } @else {
                  <span>{{t(iconLinksLabelTranslationKey())}}</span>
                }
              }
              @for (iconLink of iconLinks(); track iconLinks) {
                  @if(iconLink.icon && (iconLink.icon.identifier_1 || iconLink.icon.identifier_2)){
                    <span class="icon-holder mx-1" [tooltip]="iconLink.text" (click)="onSelectIconLink(iconLink)">
                      
                      @if(iconLink.icon.identifier_1){  
                      
                        <i class="{{ iconLink.icon.identifier_1 }}"></i>
                      
                      } @else if (iconLink.icon.identifier_2) {
                      
                        <img
                          [tooltip]="iconLink.text"
                          [src]="
                            cloudinary_base_url +
                            'w_32,c_fill,ar_1:1,r_max,f_auto/' +
                            iconLink.icon.identifier_2 +
                            '.svg'
                            "
                        />
                    }
                  </span>
                  }
              }
            </p>
              
          </div>

        }
        @if(contributors()?.length){
          <div class="mt-3">
            @for (category of separateContributorCategories(); track $index) {
              @if(filteredContributors(category).length){
  
                @if(category === 'contributor' && customContributorCategoryTranslationKey()){
                  <p class="mb-1">{{t(customContributorCategoryTranslationKey())}}</p>
                } @else {
                  <p class="mb-1">{{filteredContributors(category).length===1 ? t('common.'+category):t('common.'+category+'s')}}</p>
                }
  
                <div class="contributors">
                  @for (contributor of filteredContributors(category); track contributor){
                    <multisite-avatar-pill
                      [fallbackImageUrl]="fallbackAvatar"
                      [fallbackTranslationKey]="'social.verified_user'"
                      [imageUrl]="contributor.user?.picture"
                      [text]="contributor.user?.name_full_honorific"
                      [clickable]="isSelectableContributorCategory(category)"
                      (selected)="onSelectContributor(contributor)"
                    ></multisite-avatar-pill>
                  }
                </div>
              }
            }
          </div>
        }
        <multisite-icon-text-list
          class="my-3"
          [titleTranslationKey]="listTitleTranslationKey()"
          [titleText]="listTitleText()"
          [list]="list()"
        >
        </multisite-icon-text-list>
        <!-- <div
          class="metatext-list border-0 p-0 mb-2"
          *ngIf="list()?.length"
        >
          <p class="h4 font-weight-bold mb-3">
            {{listTitleText() ?? t(listTitleTranslationKey())}}
          </p>
          <div
            class="metatext-list-item"
            *ngFor="let metatext of list()"
          >
            @if(metatext.icon?.identifier_1){
              <i class="fa-solid {{metatext.icon.identifier_1}} mt-1"></i>
            } @else {
              <i class="fa-solid fa-check mt-1"></i>
            }
            <span>
              {{metatext.text}}
            </span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>