import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ActivityLogService, Comment, Link, CallToAction } from '@frontend/core';
import { PaginatedComments, RatingService } from '@frontend/shared';
import { CommentsService } from '@frontend/shared';
import { LinksService } from '@frontend/shared';
import { Subscription } from 'rxjs';
import { AuthService, User, UserExtraLite } from '../../../auth';
import { Tip } from '../../../models';
import { TipService } from '../tip.service';
import { SingleRatingResponse } from '@frontend/shared';
import { PageTitleService } from '../../../navigation/title/title.service';
import { BreadcrumbService } from '../../../navigation';
import { TopicService } from '../../topics';
import { AvailableLanguage, LanguageService } from '../../../language';
import { WindowService } from '../../../utilities';


@Component({
  selector: 'multisite-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss']
})
export class TipComponent implements OnInit {

  tip : Tip;
  comment : Comment;
  loadingObject: {[key:string]:boolean} = {}; // .tip .links .rating etc
  subscriptions: Subscription[] = [];
  myRating: number = 0;
  ratingSavingCompleted: boolean; // true when the user acknowledges our feedback and closes the modal
  ratingTranslationKey: string;
  newRatingSaved: boolean;
  newRatingSaving: boolean;
  clearCommentForm: boolean = false;
  postingComment: boolean;
  user : User = null;
  isAuthenticated : boolean;
  interculturalistRoleAlertComments: boolean = false;
  error = null;
  // isCollapsed: boolean;
  paginatedComments : PaginatedComments;
  callsToAction : CallToAction[]; // a kind of link
  resourceLinks : Link[]; // all other links
  activeLanguageObject : AvailableLanguage;


  constructor(
    private tipService : TipService,
    private linksService: LinksService,
    private commentsService: CommentsService,
    private route : ActivatedRoute,
    private router : Router,
    private authService : AuthService,
    private activityLogService : ActivityLogService,
    private ratingService : RatingService,
    private pageTitleService : PageTitleService,
    private breadcrumbService : BreadcrumbService,
    private topicService : TopicService,
    private languageService : LanguageService,
    private windowService : WindowService,
  ) { 
      this.ratingTranslationKey = 'social.rate_this_tip';
    }

  // makeFakeTip () : Tip {
  //   let tip : Tip = this.tipService.getPlaceholder();
  //   tip.media = this.mediaService.getPlaceholder('CloudinaryMediaItem');
  //   tip.links = [this.linksService.getPlaceholder()];

  //   return tip;
  // }

  isAnythingLoading(){
    for (let something in this.loadingObject){
      if (this.loadingObject[something]){
        return true;
      }
    }
    return false;
  }

  ctaClicked(cta : CallToAction, event : Event){
    console.log('Thank you for choosing '+cta.label);
  }
  convertLinksToCta (links : Link[]){
    let callsToAction : CallToAction[] = [];
    if (links){
      links.filter(l=>l.category === 'call-to-action').forEach(link => {
        callsToAction.push(
          new CallToAction(link.label,link.url,link.description,link.cta_label,link.cta_type,null,link.media,link.id)
        )
      });
    }
    return callsToAction;
  }
  getTip (slug : string, freshFromBackend : boolean, topicSlug :string){
    this.loadingObject.tip = true;
    this.error = null;
    slug = slug ? slug : this.route.snapshot.params['slug'];
    let activityLogParams = {
      'model':'tip',
      'identifier' : slug,
      'identifier_type' : 'slug',
      'activity_type' : 'viewed',
      'properties' : null
    };
    this.tipService.getTip (slug, freshFromBackend, topicSlug)
      .subscribe(
        response => {
          if (response){
            this.tip = response;
          } /*else {
            this.tip = this.makeFakeTip();
          }*/
          this.getLinks(response);
          this.getComments(1, false);
          this.getMyRating(this.tip);
          this.activityLogService.log(activityLogParams);
          this.pageTitleService.setTitle(this.tip.title);
          this.loadingObject.tip = false;
        },
        error => {
          // this.tip = this.makeFakeTip();
          this.error = error;  
          this.loadingObject.tip = false;
          this.activityLogService.log(activityLogParams);
        }
      );
  };
  handleLinks (links:Link[]){
    this.callsToAction = this.convertLinksToCta(links);
    this.resourceLinks = links.filter(l=>l.category === 'resources');
  }
  getLinks (tip:Tip){
    if(tip.links){
      this.handleLinks(tip.links);
      return;
    }
    this.loadingObject.links = true;
    const linksSub = this.linksService.getLinks('tip',this.route.snapshot.params['slug'])
      .subscribe(
        response => {
          this.tip.links = response
          this.handleLinks(response);
          this.loadingObject.links = false;
        },
        error => {
          this.loadingObject.links = false;
        }
      );
    this.subscriptions.push(linksSub);
  };
  acknowledgeRatingFeedback (){
    this.ratingSavingCompleted = true;
    this.newRatingSaved = false;

  }
  applyMyRating (ratingResponse : SingleRatingResponse){
    if (ratingResponse.rating && ratingResponse.my_rating){// ratingResponse.rating can be null, meaning not yet rated; but if my_rating is false, we are looking at an average of all users' ratings
      if (this.newRatingSaving){
        this.newRatingSaved = true; // we must do this before we set this.myRating
      }
      this.myRating = Math.round(ratingResponse.rating);
      this.ratingTranslationKey = 'social.update_your_rating'; 
    } else {
      this.ratingTranslationKey = 'social.rate_this_tip';
    }
    this.loadingObject.rating = false;

  }
  getMyRating (tip:Tip){
    this.loadingObject.rating = true;
    const ratingSub = this.ratingService.getMyRating('tip',tip.id,tip.slug,'general').subscribe(ratingResponse =>{
      this.applyMyRating(ratingResponse);
    },
    error => {
      this.loadingObject.rating = false;
    });
    this.subscriptions.push(ratingSub);
  };
  rate (rating){
    if (!this.user){
      let returnUrl = this.router.url || '/';
      this.authService.setRouteForRedirectAfterAuth(returnUrl);
      this.router.navigate(['/login']);
      return;
    }
    this.loadingObject.rating = true;
    this.newRatingSaved = false;
    this.newRatingSaving = true;
    this.ratingSavingCompleted = false;
    const ratingSub = this.ratingService.rate('tip',this.tip.id,this.tip.slug,rating,'general').subscribe(ratingResponse =>{
      this.applyMyRating(ratingResponse);
    },
    error => {
      this.loadingObject.rating = false;
    });
    this.subscriptions.push(ratingSub);
  };
  isAdmin (){
    return this.authService.checkRole("Admin");
  }
  getComments (page, freshFromServer){
    this.loadingObject.comment = true;
    const commentsSub = this.commentsService.getComments('tip',this.route.snapshot.params['tip'],page, false, freshFromServer)
      .subscribe(
        response => {
          this.paginatedComments = response;
          this.loadingObject.comment = false;
        },
        error => {
          this.loadingObject.comment = false;
        }
      );
    this.subscriptions.push(commentsSub);
  };
  postComment (message,tip_id){
    tip_id = tip_id ? tip_id : this.tip.id;
    if (!this.isAuthenticated){
      this.commentsService.setCachedPostingOfCommentForAfterAuthentication('tip',tip_id,message);
      this.authService.setRouteForRedirectAfterAuth(['tips/'+this.tip.slug]);
      this.router.navigate(['/login']);
      return;
    }
    this.postingComment = true;
    const postCommentSub = this.commentsService.postAComment('tip',tip_id,message)
      .subscribe((result)=>{
        this.postingComment = false;
        this.clearCommentForm = !this.clearCommentForm;
        if (!this.authService.checkRole("Interculturalist")){
          this.interculturalistRoleAlertComments = true;
        }
        let current_page = this.paginatedComments?.meta.current_page ? this.paginatedComments?.meta.current_page : 1;
        this.getComments(current_page, true);
      });
    this.subscriptions.push(postCommentSub);
  };
  checkForCachedComment (){
    let cachedComment = this.commentsService.getCachedPostingOfCommentForAfterAuthentication();
    if (cachedComment && cachedComment.modelNameSingular === 'tip'){
      this.postComment(cachedComment.message,cachedComment.identifier);
    };
  };
  getTopic(topicSlug : string, freshFromBackend : boolean) {
    this.loadingObject.topic = true;
    const topicSubscription = this.topicService.getTopic(topicSlug,freshFromBackend).subscribe((topic) => {
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'topic',fragmentName:topic.name});
      this.loadingObject.topic = false;
      },
      error => {
        this.loadingObject.topic = false;
      });
    this.subscriptions.push(topicSubscription);
  }

  ngOnInit(): void {
    this.windowService.goToTop();
    const topicSlug = this.route.snapshot.params['topic']?.toLowerCase();
      
    this.getTip (this.route.snapshot.params['tip']?.toLowerCase(),false,topicSlug);
    this.getTopic(topicSlug,false)

    const userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
      this.isAuthenticated = !!user;
      this.checkForCachedComment();
    });
    this.subscriptions.push(userSubscription);
    this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
    const activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
      // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
      if (newActiveLanguage?.languageKey !== this.activeLanguageObject.languageKey){
        this.activeLanguageObject = newActiveLanguage;
        this.getTopic(topicSlug,true)
        this.getTip (this.route.snapshot.params['tip']?.toLowerCase(),true,topicSlug);
      }
    })
    this.subscriptions.push(activeLanguageSubscription);

  }
  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
