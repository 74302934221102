<div class="wrap">
  <main class="main-section">
    <header>
      <multisite-main-navigation [user]="user" [logo]="'culturettt'" [navLinks]="mainNavigationLinks"
        (changeSidenavMode)="changeSidenavMode(null)" (logout)="logout()" [loading]="loading" [sidenavMode]="sidenavMode">
      </multisite-main-navigation>
    </header>
    <multisite-breadcrumb></multisite-breadcrumb>

    <section>
      <router-outlet></router-outlet>
    </section>

    <footer>
      <multisite-footer [logo]="'culturettt'" [summaryTranslationKey]="'culturettt.about_summary'"></multisite-footer>
    </footer>
  </main>
  <!-- <div style="width: 360px;" class="sticky-top"> -->
    <multisite-sidenav
      *ngIf="user"
      [user]="user"
      [mode]="sidenavMode"
      (logout)="logout()"
      (changeMode)="changeSidenavMode($event)"
    ></multisite-sidenav>
  <!-- </div> -->
</div>