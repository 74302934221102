import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService, User } from '../../../auth';
import { Guest } from '../../../auth/user/guest.model';
import { LanguageService } from '../../../language';
import { BreadcrumbService } from '../../../navigation/breadcrumb/breadcrumb.service';
import { PageTitleService } from '../../../navigation/title/title.service';
import { Experience } from '../../../tracking';
import { DataProcessingService } from '../../../utilities';
import { Snapshot, SnapshotService } from '../../snapshots';
import { Survey } from '../../survey.model';
import { SurveysService } from '../../surveys.service';

@Component({
  selector: 'multisite-survey-feedback-home',
  templateUrl: './survey-feedback-home.component.html',
  styleUrls: ['./survey-feedback-home.component.scss']
})
export class SurveyFeedbackHomeComponent implements OnInit {

  loadingObject: {[key:string]:boolean} = {}; // .snapshot .survey .relatedExperiences

  survey : Survey;
  surveySub: Subscription;
  relatedExperiencesSub: Subscription;
  progressablesSubscription: Subscription;
  loadedRelatedExperiences: boolean; // to avoid requesting these twice
  user : User = null;
  guest : Guest = null;
  userSubscription: Subscription;
  guestSub: Subscription; // existing from storage
  activeLanguageSubscription: Subscription;
  snapshotSubscription :Subscription;
  snapshot: Snapshot;
  error_code : number = null;
  error = null;
  surveyResponseError = null;
  appKey : string;
  score: number; // 56.7%

  constructor(
    private breadcrumbService : BreadcrumbService,
    private surveysService: SurveysService,
    private route : ActivatedRoute,
    private router : Router,
    private authService : AuthService,
    private pageTitleService : PageTitleService,
    private snapshotService : SnapshotService,
    private dataProcessingService : DataProcessingService,
    private languageService: LanguageService,

  ) { }


  isAnythingLoading(){
    for (let something in this.loadingObject){
      if (this.loadingObject[something]){
        return true;
      }
    }
    return false;
   }

  getScore(experiences: Experience[], survey_id:number){
    if (!experiences?.length){return};
    let scoreExperience = experiences.find(e=>e.experienceable_type === this.dataProcessingService.convertModelNameToBackendClass('survey') && e.experienceable_id === survey_id && e.experience_verb === 'scored');
    return scoreExperience ? scoreExperience.experience_value : null;
  };

  getRelatedExperiences (){
    if (!this.user && !this.guest){
      // do something or nothing
    } else if (this.survey && !this.loadedRelatedExperiences && !this.loadingObject.relatedExperiences) {
      this.loadingObject.relatedExperiences = true;
      this.surveysService.getRelatedExperiences (this.survey.slug,+this.route.snapshot.params['snapshot'])
        .subscribe(
          (response : Experience[]) => {
            this.loadingObject.relatedExperiences = false;
            this.loadedRelatedExperiences = true;
            if (this.surveysService.getProgressValueOfSurvey(response,this.survey) < 100){
              this.router.navigate(['../'], {relativeTo:this.route});
            } else if (this.surveysService.getBooleanStatusOfSurvey(response,this.survey.id,'completed')){
              this.score = this.getScore(response,this.survey.id);
            }
          },
          error => {
            this.loadingObject.relatedExperiences = false;
          }
        );
    }
  }

  getSnapshot(id:number){
      this.loadingObject.snapshot = true;
      this.snapshotSubscription = this.snapshotService.getSnapshot(id,false).subscribe((snapshot)=>{
      this.loadingObject.snapshot = false;
      this.snapshot = snapshot;
      this.breadcrumbService.setBreadcrumbFragment({
        urlFragment: 'snapshot',
        fragmentName: this.snapshot.title,
      });
    }, error => {
      this.loadingObject.snapshot = false;
      if (typeof error === 'string'){
        this.error = error;
      } else {
        this.error = error.message;
        this.error_code = error.meta?.error_code;
      }
      if (this.error === 'error.permissions_lacking_view'){
        setTimeout(()=>{
          this.router.navigate(['/snapshots']);
        },5000);
      }
    })
  }


  handleSurveyLoadingSuccess (){
    if (this.user || this.guest){
      // do something with experiences?
    }
    let truncatedTitle = this.survey.name?.length > 60 ? this.survey.name.substring(0,45)+'...' : this.survey.name;
    this.pageTitleService.setTitle(truncatedTitle);
    this.breadcrumbService.setBreadcrumbFragment({urlFragment:'survey',fragmentName:this.survey.name});
    this.loadingObject.survey = false;
  }
  getSurvey (slug:string,freshFromServer: boolean){
    this.loadingObject.survey = true;
    this.error = null;
    
    this.surveysService.getSurvey (slug,freshFromServer)
      .subscribe(
        response => {
          if (response){
            this.survey = response;
            this.handleSurveyLoadingSuccess()
            if (this.user || this.guest){
              this.getRelatedExperiences();
            }
          }
        this.loadingObject.survey = false;
        },
        error => {
          this.error = error;  
          this.loadingObject.survey = false;
        }
      );
  };

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
      if (this.survey){
        this.getRelatedExperiences();
      }
    });
    this.guestSub = this.authService.guest.subscribe(guest => {
      this.guest = guest;
      if (this.survey){
        this.getRelatedExperiences();
      }
    });
    this.route.params.subscribe(
      (params : Params)=>{
        this.getSurvey (params['survey'],false);
      }
    );
    this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( () => {
      if(this.survey){
        this.getSurvey (this.survey.slug,true);
      }
    })
    this.route.params.subscribe(
      (params : Params)=>{
        if(params['snapshot']){
          this.getSnapshot (+params['snapshot']);
        }
      }
    );
    // this.appKey = process.env['NX_APP_KEY'];
  }
  ngOnDestroy () {
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    }
    if (this.guestSub){
      this.guestSub.unsubscribe();
    }
    if (this.surveySub){
      this.surveySub.unsubscribe();
    }
    if (this.relatedExperiencesSub){
      this.relatedExperiencesSub.unsubscribe();
    }
    if (this.progressablesSubscription){
      this.progressablesSubscription.unsubscribe();
    }
    if (this.activeLanguageSubscription){
      this.activeLanguageSubscription.unsubscribe();
    }
  }

}
