import { Tag, Link, Video } from '@frontend/core';
import { CloudinaryMediaAsset } from "../../models/cloudinary-media-asset.model"; // a simpler alternative: import { Media } from '@frontend/core';
import { Contributor } from '../../auth';

export class Tip {
    id : number;
    title : string;
    description : string;
    slug : string;
    body : string;
    tags : Tag[]; 
    videos : Video[];
    links: Link[];
    rating: number;
    created_at: string;
    updated_at: string;
    media?: CloudinaryMediaAsset[];
    contributors?: Contributor[];
  }