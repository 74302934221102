import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Subscription } from 'rxjs';
import { MediaService } from '../../../content';
import { Culture, CultureService } from '../../../cultures';

@Component({
  selector: 'culture-selector-type-one',
  templateUrl: './culture-selector-type-one.component.html',
  styleUrls: ['./culture-selector-type-one.component.scss']
})
export class CultureSelectorTypeOneComponent implements OnInit {

  cultureSelectorForm: FormGroup;
  @Input() disabled : boolean;
  @Input() loading : boolean;
  @Input() labelTranslationKey : string; // 'common.national_cultures'
  @Input() placeholderTranslationKey : string; // 'common.start_typing'
  @Output() selected = new EventEmitter<Culture>();
  @Output() deselected = new EventEmitter<Culture>();
  @Input('cultures') allCultures: Culture[]; // original list from backend
  availableCultures: Culture[]; // original list from backend with selectedCultures removed
  // selectedCulture: Culture;
  @Input() selectedCultures: Culture[] = [];
  selectedCulturesCopy: Culture[] = [];
  cloudinary_base_url : string; // 'https://res.cloudinary.com/cebt/image/upload/'

  constructor(private mediaService : MediaService) { 
    this.cloudinary_base_url = this.mediaService.cloudinary_base_url;
  }

  onSelect(event: TypeaheadMatch): void {
    // this.selectedCulture = event.item;
    // this.copySelectedCultures ();
    // this.selectedCultures = null;
    this.selected.emit(event.item);
    this.cultureSelectorForm.reset();
  }
  copySelectedCultures (){
    this.selectedCulturesCopy = [...this.selectedCultures];
  }
  // selectCulture (culture: Culture){
  //   debugger;
  //   this.selectedCultures.push(culture);
  //   this.availableCultures = this.availableCultures.filter(c=>c.id !== culture.id);
  // }
  deSelectCulture (culture: Culture){
    this.deselected.emit(culture);
    // this.selectedCultures = this.selectedCultures.filter(c=>c.id !== culture.id); // TODO - we should skip this
  }

  updateAvailableCultures(){
    if (!this.allCultures){return;};
    let selectedCultureIds = this.selectedCultures ? this.selectedCultures.map(c=> c.id) : [];
    this.availableCultures = this.allCultures.filter(c=> !selectedCultureIds.includes(c.id));
  }

  ngOnChanges(changesObject){
    if(changesObject.allCultures?.currentValue){
      if (!changesObject.allCultures.previousValue || (changesObject.allCultures.previousValue.length === 0 && changesObject.allCultures.currentValue.length>0))
      this.updateAvailableCultures();
    }
    if(changesObject.selectedCultures?.currentValue && (!changesObject.selectedCultures.previousValue || changesObject.selectedCultures.previousValue.length !== changesObject.selectedCultures.currentValue.length)) {
      this.updateAvailableCultures();
    }
  }

  ngOnInit(): void {
    this.cultureSelectorForm = new FormGroup({
      title: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(60)]),
      description: new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(200)]),
      culture: new FormControl(null, [Validators.required]),
    });
    // this.snapshotForm.controls.culture.valueChanges.subscribe(
    //   (value) => {
    //     // do something
    //   }
    // );
    // this.nationalCultureSubscription = this.cultureService.getCultures('geographic','national').subscribe((cultures : Culture[])=>{
    //   this.availableCultures = cultures;
    // });
    
  }

  ngOnDestroy (){
    // if (this.nationalCultureSubscription){
    //   this.nationalCultureSubscription.unsubscribe();
    // }
  }

}
