import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ttt-design09',
  templateUrl: './design09.component.html',
  styleUrls: ['./design09.component.scss']
})
export class Design09Component implements OnInit {

  constructor() { }

  isCollapsed = false;

  ngOnInit(): void {
  }

}
