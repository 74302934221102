<div class="rating rating-card">
  <!-- <span class="h5" *transloco="let t">{{ translationKey ? t(translationKey) : text }}</span> -->
  <div class="rating-body">
    <div class="top-part">
      <p class="rating-value h1 font-weight-bold mt-2 mb-1" [ngClass]="{'disabled' : loading}">
        <span [ngClass]="{'hidden' : !overStar && !rate}">{{overStar ? overStar : rate ? rate : '.' }}</span>
        <span [ngClass]="{'d-none' : overStar || rate}"><span class="h6 mr-4"*transloco="let t">{{ translationKey ? t(translationKey) : text }}</span></span>
        
      </p>
      <rating class="mb-2"  [ngClass]="{'disabled' : loading}"
        [(ngModel)]="rate" [max]="max"
        [readonly]="isReadonly || loading"
        (keyup)="confirmSelection()"
        (click)="confirmSelection()"
        (onHover)="hoveringOver($event)"
        (mouseleave)="resetStar()"
      >
      </rating>
      <button type="button" class="btn btn-secondary btn-sm w-100" (click)="enableEditMode()"
      *ngIf="rate && !editMode"
        [disabled]="loading">
        <span class="ml-1" *transloco="let t">{{ translationKey ? t(translationKey) : text }}
          <span *ngIf="rate"><i class="fa-solid fa-pen"></i></span>
        </span>
      </button>
    </div>
  </div>
  <ng-template #successModal>
    <div class="modal-header alert-success">
      <h4 class="modal-title pull-left text-success" *transloco="let t">{{t('common.thank_you')}}</h4>
      <button type="button" class="btn-close close pull-right text-success" aria-label="Close"
        (click)="modalRef?.hide()">
        <i aria-hidden="true" class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div class="modal-body p-4 d-flex justify-content-center align-items-center flex-column">
      <p class="font-weight-bold h5">
       <span *transloco="let t">{{t('social.'+ratingFeedbackTranslationKey)}}</span>
      </p>

      <p class="font-weight-bold display-4 text-success">
        {{rate}}
      </p>
      <div class="d-flex flex-row align-items-center">
        <i class="fa-regular fa-star h2 ml-2" [ngClass]="{'fa-solid text-success' : rate > 0}"></i>
        <i class="fa-regular fa-star h2 ml-2" [ngClass]="{'fa-solid text-success' : rate > 1}"></i>
        <i class="fa-regular fa-star h2 ml-2" [ngClass]="{'fa-solid text-success' : rate > 2}"></i>
        <i class="fa-regular fa-star h2 ml-2" [ngClass]="{'fa-solid text-success' : rate > 3}"></i>
        <i class="fa-regular fa-star h2 ml-2" [ngClass]="{'fa-solid text-success' : rate > 4}"></i>
      </div>
    </div>
  </ng-template>
</div>