<div class="snapshot-list-item">
  <div class="card img-holder">
    <div class="card-img-overlay-color"></div>
    <img class="card-img" *ngIf="snapshot.media"
      [src]="snapshot.media.asset_url && imageTransformations ? snapshot.media.base_url + imageTransformations + snapshot.media.asset_url : snapshot.media.file_url"
      [alt]="snapshot.media.description">
    <div class="card-img-overlay">
        <div class="status-holder" style="--status-color: var(--bs-danger);"
          *ngIf="isDateWithinXDays(snapshot.date,14)">
          <div class="status"
            [ngClass]="{bgWarning: isDateWithinXDays(snapshot.date,14),bgDanger: isDateWithinXDays(snapshot.date,7)}"
            *ngIf="isDateWithinXDays(snapshot.date,7)">
            <i class="fa-solid fa-clock icon"></i>
            <span *transloco="let t">{{t('common.expiring')}}</span>&nbsp;{{ snapshot.date | date : 'MMMM d' }}
          </div>
        </div>

        <div class="d-flex flex-row align-items-end justify-content-between w-100">

          <div class="d-flex flex-column-reverse flex-lg-column align-items-start gap-2 w-100">
            <div
              class="survey-holder d-none flex-row align-items-center justify-content-start align-self-start flex-wrap gap-2">
              <span class="survey-item" *ngFor="let survey of snapshot.surveys">
                <span class="icon" *ngIf="survey.icon?.identifier_1"><i class="fa-solid"
                    [ngClass]="survey.icon.identifier_1"></i></span>
                <span *ngIf="!survey.icon?.identifier_1"><i class="fa-solid fa-check"></i></span>
                {{survey.name}}
              </span>
            </div>

            <div class="d-flex flex-column flex-lg-row align-items-start gap-2">
              <div class="date-status d-flex flex-row gap-2">
                <div class="date">
                  <p class="year">{{ snapshot.date | date:'yyyy' }}</p>
                  <p class="month-day">
                    <span class="month">
                      {{ snapshot.date | date:'MMM' }}
                    </span>
                  </p>
                </div>
                <!-- <div class="status-holder">
              <div class="status" style="--status-color: var(--bs-danger);">
                <div class="content">
                  <i class="fa-solid fa-clock icon"></i>
                  Expiring in 7 days
                </div>
              </div>
            </div> -->
              </div>
              <div class="content d-flex flex-column">
                <h3 class="title">
                  <span class="mr-2">
                    {{snapshot.title ?? snapshot.label}}
                  </span>
                  <span class="icons-holder gap-2">
                    <span *ngFor="let culture of snapshot.cultures" class="icons-holder tooltip-custom">
                      <img [src]="'https://cultureconnector.com/app/images/flags/'+culture.iso+'.png'"
                        [alt]="culture.name">
                      <p class="tooltiptext-custom-top">{{culture.name}}</p>
                    </span>
                  </span>
                </h3>
                <p>{{snapshot.description ?? snapshot.info}}</p>
              </div>

            </div>

          </div>

          <button class="btn btn-light mt-2 mt-md-0 w-auto w-md-100 d-none d-lg-block"
            *transloco="let t">
            <span *ngIf="snapshot.current_accepted"><i class="fa-solid fa-arrow-rotate-right fa-spin mr-1"></i></span>
            {{snapshot.current_accepted ? t('common.continue') : t('common.open')}}
          </button>
        </div>
    </div>
  </div>
</div>