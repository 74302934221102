import { Component, OnInit, ViewChild,TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Subscription, take } from 'rxjs';
import { Hub, HubService } from '../../hubs';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
import { TabBasic } from '../../navigation/tabs/tabs-basic/tab-basic.model';
import { PageTitleService } from '../../navigation/title/title.service';
import { ApplicationService } from '../../utilities';
import { BrandDisplayService } from '../brand-display.service';
import { BrandEditingService, BrandEditingTask } from '../brand-editing.service';
import { Brand } from '../brand.model';
import { BrandResponse } from '../brand-response.model';
import { StatusItem } from '../../notifications';
import { LocalStorageNotificationStatus, NotificationService } from '../../notifications/notification.service';

@Component({
  selector: 'multisite-brand-home',
  templateUrl: './brand-home.component.html',
  styleUrls: ['./brand-home.component.scss'],
})
export class BrandHomeComponent implements OnInit {
  hub: Hub;
  brand: Brand;
  draftBrand: Brand;
  defaultBrand: Brand;
  hubSubscription: Subscription;
  tabs: TabBasic[];
  activeTab: TabBasic;
  slugTab: TabBasic; // for adding/removing dynamically
  loading: boolean;
  error: string;
  @ViewChild('restoreDefaultBrandModal') restoreDefaultBrandModal;
  modalRef?: BsModalRef;
  saveAsNewBrandModeActive : boolean;
  slugMinLength : number;
  savingBrandSubscription: Subscription;
  temporaryLogoSubscription: Subscription;
  draftBrandEditMode: string; // 'easy' or 'advanced' or 'expert'
  subdomain: string;
  statusItemsObject : {
    [key:string]:StatusItem[]
  }
  tasks : BrandEditingTask[];
  masterTask : BrandEditingTask;
  alertsCollapsed : boolean = true;
  alertsDismissed : LocalStorageNotificationStatus;

  constructor(
    private hubService: HubService,
    private breadcrumbService: BreadcrumbService,
    private pageTitleService: PageTitleService,
    private route: ActivatedRoute,
    private router: Router,
    private brandEditingService: BrandEditingService,
    private brandDisplayService: BrandDisplayService,
    private applicationService: ApplicationService,
    private modalService: BsModalService,
    private notificationService : NotificationService
    ) {
    this.tabs = [
      {
        id: 1,
        active: false,
        disabled: false,
        preTranslatedText: 'Colour Editor',
        translationKey: 'branding.colours',
        content: null,
        target: null,
      },
      {
        id: 2,
        active: false,
        disabled: false,
        preTranslatedText: 'Font Editor',
        translationKey: 'branding.font',
        content: null,
        target: null,
      },
      {
        id: 3,
        active: false,
        disabled: false,
        preTranslatedText: 'Logo Editor',
        translationKey: 'branding.logo',
        content: null,
        target: null,
      },
      {
        id: 5,
        active: false,
        disabled: false,
        preTranslatedText: 'Hub visibility',
        translationKey: 'branding.hub_visibility',
        content: null,
        target: null,
      },
      {
        id: 6,
        active: false,
        disabled: false,
        preTranslatedText: 'Preview',
        translationKey: 'common.preview',
        content: null,
        target: null,
      },
    ];
    this.activateTab(this.tabs[0], null);
    this.slugTab =    {
      id: 4,
      active: false,
      disabled: false,
      preTranslatedText: 'Slug editor',
      translationKey: 'content_management.web_address',
      content: null,
      target: null,
    };
    this.statusItemsObject = {
      logos : [
        {name: 'banner', translationKey: 'branding.logo_banner_description_short'},
        {name: 'banner_inverse', translationKey: 'branding.logo_banner_inverse_description_short'},
        {name: 'square', translationKey: 'branding.logo_square_description_short'},
        {name: 'square_inverse', translationKey: 'branding.logo_square_inverse_description_short'},
      ],
    }
  }
  closeModal(){
    if (this.modalRef){
      this.modalRef.hide();
    }
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  setTabActive (activeTab : TabBasic){
    if (!activeTab){return;};
    this.tabs.forEach(tab => {
      tab.active = false;
    });
    activeTab.active = true;
  }
  activateTab(tabSelected: TabBasic, tabId: number) {
    if (tabSelected) {
      this.activeTab = tabSelected;
    } else if (tabId) { // tabId is always >0
      const selectedTab = this.tabs.find((t) => t.id === tabId);
      if (selectedTab) {
        this.activeTab = selectedTab;
      }
    }
    if (this.activeTab?.preTranslatedText === 'Preview'){
      let currentBrand;
      if (this.draftBrand){
        currentBrand = this.draftBrand;
        const pending_properties = ['logo_banner','logo_square','logo_banner_inverse','logo_square_inverse'];
        pending_properties.forEach(pp=>{
          if (this.brand.pending?.[pp]){
            currentBrand[pp]=this.brand.pending[pp];
          }
        });
      } else {
        currentBrand = this.brand;
      }
      this.preview(currentBrand);
    }
    this.setTabActive(this.activeTab);
  }
  applyBrand(brand: Brand) {
    this.brandDisplayService.applyBrand(brand);
  }

  restoreDefaultBrandFinalStep (){

    const restoredBrand = this.brandDisplayService.getNewDraftBrand();

    const possibleLogos = ['logo_banner','logo_square','logo_banner_inverse','logo_square_inverse'];

    possibleLogos.forEach(pp=>{

      if (this.brand[pp]){
        if (!restoredBrand.pending_updates){
          restoredBrand.pending_updates = {[pp]:{removeSaved:true}};
        } else {
          restoredBrand.pending_updates[pp] = {removeSaved:true};
        }
      }
    });
    this.saveBrand(restoredBrand, false);
    this.closeModal();

  }
  restoreDefaultBrand (){

    this.loading = true;
    if (this.brand.pending){
      const logos = Object.keys(this.brand.pending).filter(l=>l.indexOf('logo_') === 0).map(l=> l.substring('logo_'.length));
      this.temporaryLogoSubscription = this.brandEditingService.removeTemporaryLogos(this.brand.slug,logos).subscribe(
        allHubBrands => {
          this.handleBrandUpdateSuccess (allHubBrands);
          this.deleteDraftLocally();
          this.restoreDefaultBrandFinalStep ();
        },
        error => {
          this.loading = false;
        }
      )
    } else {
      this.deleteDraftLocally();
      this.restoreDefaultBrandFinalStep ();
    }
  };
  openRestoreDefaultBrandModal (){
    this.openModal(this.restoreDefaultBrandModal);
  };
  handleTaskStatus (brand){
    this.alertsDismissed = this.notificationService.getLocalNotificationStatusFromLocalStorage('brand',brand.slug,'pending_tasks');
    let taskObject = this.brandEditingService.generateTaskList(brand);
    this.masterTask = taskObject.masterTask;
    this.tasks = taskObject.tasks;
    if (this.tasks?.length < 2){
        this.alertsCollapsed = false;
    };
    this.statusItemsObject = this.updateStatusItems(this.tasks,this.statusItemsObject);
  }
  dismissTaskAlert (){
    this.tasks = [];
    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    const brandForTasks = this.draftBrand ? this.draftBrand : this.brand; // TODO -or shall we have no tasks when there is a draft brand?
    this.notificationService.putNotificationStatusInLocalStorage('brand',brandForTasks.slug,'pending_tasks',{type_dismissed_until: futureDate})
  }
  updateStatusItems(tasks : BrandEditingTask[], statusItemsObject : {[key:string]:StatusItem[]}){
    for (let category in statusItemsObject){
      statusItemsObject[category].forEach(si=>{
        let task = tasks.find(t=>t.related_status_item === si.name);
        if (task){
          if( !si.status || si.status === 'info' || (si.status === 'warning' && task.status !== 'info')  ){
              si.status = task.status;
          }
        }
      })
    }
    return statusItemsObject;
  }
  updateFont(font){
    if (this.draftBrand){
      this.draftBrand.font = font;
      this.brandEditingService.putDraftBrandInLocalStorage(this.draftBrand);
    }
  }
  updateHubVisibility(visibility){
    if (this.draftBrand && this.draftBrand.category !=='primary'){
        this.draftBrand.category = visibility;
        
        this.brandEditingService.putDraftBrandInLocalStorage(this.draftBrand);
      }
  }
  setModeSaveAsNewBrand (active:boolean){
    if (!this.draftBrand){
      this.copySavedBrandToDraft();
    }
    this.saveAsNewBrandModeActive = active;
    // the final step before sending to the server
    this.closeModal();
    this.tabs.forEach(tab => {
      tab.disabled = active;
    });
    this.slugTab.disabled = false;
    this.showSlugEditingForm(active);
    this.error = null;
  }
  showSlugEditingForm (show:boolean){
    if (!this.tabs.find(t=>t.preTranslatedText === this.slugTab.preTranslatedText) && show){
      this.tabs.splice(3,null,this.slugTab);
    };
    if (!show){
      this.tabs = this.tabs.filter(t=>t.preTranslatedText !== this.slugTab.preTranslatedText);
      this.activateTab(this.tabs[0],null);
    } else {
      this.activateTab(this.slugTab,null);
    }
  }
  transformAndCacheHub (hub:Hub,allHubBrandsFromBackend:BrandResponse[]){
    let hubBeforeTransformingBrands = Object.assign(hub,{brands:allHubBrandsFromBackend})
    hub = this.hubService.transformManagedHubBrands(hubBeforeTransformingBrands);
    this.hubService.cacheManagedHub(hub);
    return hub;
  }
  saveTemporaryLogoFile(brand:Brand){
    // Temp files are always attached to the saved brand, not to the draft
    // In this request, the backend only saves the logos as pending URLs. It will not change the colours or fonts etc
    this.error = null;
    this.loading = true;
    brand = Object.assign(brand, {id:this.brand.id,name:this.brand.name,slug:this.brand.slug});
    let newBrandObject : FormData = this.brandEditingService.prepareDraftBrandForSaving(brand,null);
    this.savingBrandSubscription = this.brandEditingService.saveTemporaryLogo(newBrandObject).subscribe(
      (allHubBrands)=>{
        this.hub = this.transformAndCacheHub(this.hub,allHubBrands);
        let savedBrand = this.hub.brands.find(b=>b.slug=== brand.slug);
          this.brand = savedBrand; // TODO - this should not be necessary. this.brand should be overwritten by reference, but it is not. Why?
          if (savedBrand){
          if (savedBrand.slug === this.subdomain){
            this.brandDisplayService.applyBrand(savedBrand);
          }
          if (this.draftBrand){
            this.draftBrand.pending = savedBrand.pending;
          }
        }
        this.loading = false;
      },
      error => this.handleServerErrors(error)
    );
  }
  toggleMarkSavedLogoForDeletion($event : {logo_type:string, removeSaved:boolean}){
     if (!this.draftBrand){return;};
     if (!this.draftBrand.pending_updates){
       this.draftBrand.pending_updates = {[$event.logo_type]:{removeSaved:true}};
     } else if(!this.draftBrand.pending_updates[$event.logo_type]) {
        this.draftBrand.pending_updates[$event.logo_type] = {removeSaved:true};
      } else {
        this.draftBrand.pending_updates[$event.logo_type].removeSaved = !this.draftBrand.pending_updates[$event.logo_type].removeSaved;
     }
  }
  handleBrandUpdateSuccess (allHubBrands){
    this.hub = this.transformAndCacheHub(this.hub,allHubBrands);
    let savedBrand = this.hub.brands.find(b=>b.slug=== this.brand.slug);
    if (savedBrand){
      this.brand = savedBrand; // TODO - this should not be necessary. this.brand should be overwritten by reference, but it is not. Why?
      if (savedBrand.slug === this.subdomain){
        this.brandDisplayService.applyBrand(savedBrand);
      }
      if (this.draftBrand){
        this.draftBrand.pending = savedBrand.pending;
        this.draftBrand.pending_updates = null;
      }
    }
    this.handleTaskStatus(this.brand);
    this.loading = false;
  }
  removeTemporaryLogoFile(itemKey:string){ // itemKeys: 'banner','square','banner_inverse','square_inverse'
    // Temp files are always attached to the saved brand, not to the draft
    this.error = null;
    this.loading = true;
    this.savingBrandSubscription = this.brandEditingService.removeTemporaryLogos(this.brand.slug,[itemKey]).subscribe(
      (allHubBrands)=>{
        this.handleBrandUpdateSuccess(allHubBrands);
      },
      error => this.handleServerErrors(error)
    );
  }
  saveBrand(brand:Brand, saveAsNew: boolean){
    this.error = null;
    if (!saveAsNew){
      brand.id = this.brand.id;
      brand.name = this.brand.name;
      brand.slug = this.brand.slug;
    };
    this.loading = true;
    if (!saveAsNew) {
      let newBrandObject : FormData = this.brandEditingService.prepareDraftBrandForSaving(brand,null);
      this.savingBrandSubscription = this.brandEditingService.saveExistingBrand(newBrandObject).subscribe(
        (allHubBrands)=>{
          this.hub = this.transformAndCacheHub(this.hub,allHubBrands);
          let savedBrand = this.hub.brands.find(b=>b.slug=== brand.slug);
          if (savedBrand?.slug === this.subdomain){
            this.brandDisplayService.applyBrand(savedBrand);
            this.handleTaskStatus(savedBrand);
          }
          if (this.draftBrand){
            this.draftBrand.pending = savedBrand.pending;
            this.draftBrand.pending_updates = null;
          }
          this.brand = savedBrand; // TODO - this should not be necessary. this.brand should be overwritten by reference, but it is not. Why?
          this.loading = false;
        },
        error => this.handleServerErrors(error)
      );
    } else {
      let newBrandObject : FormData = this.brandEditingService.prepareDraftBrandForSaving(brand,this.brand.id);
      newBrandObject.append('hub_id',this.hub.id?.toString());
      this.savingBrandSubscription = this.brandEditingService.saveNewBrand(newBrandObject).subscribe(
        (allHubBrands)=>{
          this.hub = this.transformAndCacheHub(this.hub,allHubBrands);
          let newBrand = this.hub.brands.find(b=>b.slug=== brand.slug); // the backend may have modified the new brand's slug.
          if (this.draftBrand){ // we might save the default brand as a saved brand directly without ever creating a draft brand.
            this.deleteDraftLocally();
            this.setModeSaveAsNewBrand(false);
          }
          this.loading = false;
          if (newBrand){
            this.router.events
              .pipe(
                filter(event => event instanceof NavigationEnd),
                take(1))
              .subscribe((event: NavigationEnd) => {
                this.getBrand(newBrand.slug);
              });
            this.router.navigate(['../'+newBrand.slug], {relativeTo:this.route});
          }
        },
        error => this.handleServerErrors(error)
      );
    }    
  }
  copySavedBrandToDraft(){
    this.draftBrand = this.brandEditingService.copyBrandWithoutReference(this.brand,'-edit');
    this.brandEditingService.putDraftBrandInLocalStorage(this.draftBrand);
    if (this.activeTab?.preTranslatedText === 'Preview'){
      this.activateTab(this.tabs[0],null);
    }
  }
  onDraftBrandChange(draftBrand : Brand){
    this.draftBrand = draftBrand;
    this.brandEditingService.putDraftBrandInLocalStorage(this.draftBrand);
  }
  saveDraftAsNew(nameAndSlug: {name:string,slug:string}){
    Object.assign(this.draftBrand,nameAndSlug);
    this.saveBrand(this.draftBrand, true);
    this.error = null;
  }
  deleteDraft() {
    if (this.brand.pending){
      const logos = Object.keys(this.brand.pending).filter(l=>l.indexOf('logo_') === 0).map(l=> l.substring('logo_'.length));
      this.temporaryLogoSubscription = this.brandEditingService.removeTemporaryLogos(this.brand.slug,logos).subscribe(
        allHubBrands => {
          this.handleBrandUpdateSuccess (allHubBrands);
          this.deleteDraftLocally();
        }
      )
    } else {
      this.deleteDraftLocally();
    }
  }
  deleteDraftLocally() {
    this.brandEditingService.deleteBrandFromLocalStorage(this.brand.slug+'-edit'); // or this.draftBrand.slug
    this.draftBrand = null;
  }
  onLogoFormDataChanged (formData: FormData){
    if (!this.draftBrand){return;};
    const file_url = formData.get('file_url')?.toString();
    const file = formData.get('file') as File;
    if (!this.draftBrand.pending_updates){
      this.draftBrand.pending_updates = {[formData.get('logo_type')?.toString()]:{file: file, file_url:file_url}};
    } else {
      const removeSaved = !file_url && !file ? this.draftBrand.pending_updates[formData.get('logo_type')?.toString()]['removeSaved']:false; // if there is a pending update coming, then we don't need to mark the saved logo for deletion.
      this.draftBrand.pending_updates[formData.get('logo_type')?.toString()] =  {file: file, file_url:file_url, removeSaved:removeSaved};
    }
    this.saveTemporaryLogoFile(this.draftBrand);
  }
  preview(brand: Brand) {
    this.applyBrand(brand);
    this.brandDisplayService.putBrandInLocalStorage(this.draftBrand);
  }
  previewMode(){
    return (this.subdomain && this.subdomain !== localStorage.activeBrandSlug) || (!this.subdomain && localStorage.activeBrandSlug && localStorage.activeBrandSlug !== this.brandDisplayService.new_brand_default_name);
  }
  clearPreview() {
    this.brandDisplayService.getBranding();
  };
  setDraftEditingMode (newMode){
    this.brandEditingService.draftBrandEditMode = newMode;
    this.draftBrandEditMode = newMode;
    // for example 'branding.easy_editing','branding.advanced_editing', 'branding.expert_editing'
  }
  handleServerErrors (error){
    this.loading = false;
    this.error = error;
  }
  getBrand(brand_slug: string){
    this.brand = this.hub.brands.find((b) => b.slug === brand_slug);
    this.breadcrumbService.setBreadcrumbFragment({
      urlFragment: 'hub',
      fragmentName: this.hub.name,
    });
    if (this.brand) {
      this.handleTaskStatus(this.brand);
      this.pageTitleService.setTitle(this.brand.name);
      this.draftBrand = this.brandEditingService.getDraftBrandFromLocalStorage(this.brand)
    }
    this.slugMinLength = this.hubService.brandSlugMinLengths[this.hub.type];
  }
  getHub(slug: string, brand_slug: string) {
    this.hubService.getManagedHub(slug).subscribe((response) => {
      this.hub = response;
      this.getBrand(brand_slug);
    });
  }

  ngOnInit(): void {
    this.subdomain = this.applicationService.getSubdomain();
    this.route.params.subscribe((params) => {
      const brand_slug = params['brand'];
      const hub_slug = params['hub'];
      this.getHub(hub_slug, brand_slug);
    });
    this.draftBrandEditMode = this.brandEditingService.draftBrandEditMode;
  }
  ngOnDestroy(): void {
    if (this.hubSubscription) {
      this.hubSubscription.unsubscribe();
    }
    if (this.temporaryLogoSubscription) {
      this.temporaryLogoSubscription.unsubscribe();
    }
  }
}
