import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountHomeComponent } from './account-home/account-home.component';
import { UserAccountDetailsUpdateComponent } from './user-account-details-update/user-account-details-update.component';
import { UserAccountConsentComponent } from './consent/user-account-consent/user-account-consent.component';

const routes: Routes = [
  {
    path: '',
    component: AccountHomeComponent,
    children : [
      { path: '', redirectTo: 'password', pathMatch: 'full'},
      { path: 'password', component: UserAccountDetailsUpdateComponent},
      { path: 'email', component: UserAccountDetailsUpdateComponent},
      // { path: 'consent', component: UserAccountConsentComponent, data: { label: 'Consent', translationKey: 'account.consent', labelSource: null},
      // children: [
      //   {
      //     path: '',
      //     component: UserAccountConsentComponent,
      //   },
      //   {
      //     path: ':slugs',
      //     data: { label: 'ConsentXXXXX', translationKey: null, labelSource: 'titleService'},
      //     component: UserAccountConsentComponent,
      //     children: [
      //       {
      //         path: '',
      //         component: UserAccountConsentComponent,
      //       },
      //     ],
      //   },
      // ]},
      { path: 'consent', loadChildren: () => import('./consent/consent.module').then(m => m.ConsentModule),
        //  data: { label: 'ConsentXX', translationKey: 'account.consent', labelSource: null}
        },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserAccountRoutingModule { }
