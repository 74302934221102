<div class="stepper" *transloco="let t">
  <div class="stepper-header">
    <multisite-stepper-type-one [steps]="steps" (selectStep)="selectStepperStep($event)"></multisite-stepper-type-one>
    <div class="d-flex justify-content-between">
      <!-- <button class="btn btn-primary" (click)="previousStep()"
        [disabled]="currentPresentationStepIndex === 0 || loading || loadingNewGuest"
        >{{t('common.previous')}}
      </button> -->
      <!-- <button class="btn btn-primary" (click)="nextPresentationStep()" *ngIf="activeStep()?.allowSkip"
        [disabled]="currentPresentationStepIndex === presentationSteps?.length ||
                    (currentPresentationStepKeyIncludes(['login']) && !user && !guest) || loading || loadingNewGuest"
        >{{
          (currentPresentationStepKeyIncludes(['code','code-check']) && !accessCodeInChildForm) ||
          (currentPresentationStepKeyIncludes(['login']) && guest)
          ? t('common.skip') : t('common.next')
        }}
      </button> -->
    </div>
  </div>

  <!-- Slide 1 -->
  <!-- <div class="stepper-body" *ngIf="presentationSteps[currentPresentationStepIndex]"> -->
  <div class="stepper-body" *ngIf="currentPresentationStepKeyIncludes(['start'])">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div>
          <img class="promo-img" *ngIf="presentationSteps[currentPresentationStepIndex].imageFileName"
            [src]="imageWithTransformations(presentationSteps[currentPresentationStepIndex].imageFileName,'w_450,c_fill/')"
            [alt]="'Decorative image representing this step in the process'">
        </div>
      </div>
      <div class="col-lg-7 align-items-start mt-3 mt-lg-0">
        <p
          *ngIf="presentationSteps[currentPresentationStepIndex].captionTranslationKey || presentationSteps[currentPresentationStepIndex].captionTranslationKey">
          {{t(presentationSteps[currentPresentationStepIndex].captionTranslationKey)}}</p>
        <h4 class="title">{{t(presentationSteps[currentPresentationStepIndex].titleTranslationKey)}}</h4>
        <p>{{t(presentationSteps[currentPresentationStepIndex].textTranslationKey)}}</p>

        <div class="mt-5 d-flex gap-2 flex-wrap">
          <button class="btn btn-primary" (click)="nextPresentationStep()">{{t('common.start')}}</button>
          <button class="btn btn-outline-secondary" (click)="navigateTo(['/demo'])">{{t('common.not_yet')}}.
            {{t('navigation.demo_instead')}}</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Slide 2 -->
  <div class="stepper-body" *ngIf="currentPresentationStepKeyIncludes(['code','code-check'])">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <img class="promo-img" *ngIf="presentationSteps[currentPresentationStepIndex].imageFileName"
          [src]="imageWithTransformations(presentationSteps[currentPresentationStepIndex].imageFileName,'w_450,c_fill/')"
          [alt]="'Decorative image representing this step in the process'">
      </div>
      <div class="col-lg-7 align-items-start mt-3 mt-lg-0">
        <p
          *ngIf="presentationSteps[currentPresentationStepIndex].captionTranslationKey || presentationSteps[currentPresentationStepIndex].captionTranslationKey">
          {{t(presentationSteps[currentPresentationStepIndex].captionTranslationKey)}}</p>
        <h4 class="title">{{t(presentationSteps[currentPresentationStepIndex].titleTranslationKey)}}</h4>
        <p>{{t(presentationSteps[currentPresentationStepIndex].textTranslationKey)}}</p>

        <div class="mt-5">
          <div class="d-flex align-items-center gap-2 gap-lg-4">
            <div class="w-50">
              <multisite-access-code-form ngClass="w-100 w-lg-50" (submitted)="submitEventCode($event)"
                [error]="eventCodeError" [loading]="loading" [backgroundFill]="true"
                (codeUpdated)="accessCodeInChildForm = $event"></multisite-access-code-form>
            </div>
            <a (click)="nextPresentationStep()" class="link-secondary">
              {{t('common.skip')}}<i class="fa-solid fa-angle-right ml-2"></i>
            </a>
          </div>
          <div class="stepper-body py-2" *ngIf="currentPresentationStepKeyIncludes(['code-check'])">
            <button class="btn link-primary-dark" (click)="navigateTo(['/help/faqs/where-get-event-access-code'])">
              <i class="fa-solid fa-circle-info mr-2"></i>{{t('events.where_get_access_code')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Slide 3 -->
  <div class="stepper-body" *ngIf="currentPresentationStepKeyIncludes(['login'])">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <img class="promo-img" *ngIf="presentationSteps[currentPresentationStepIndex].imageFileName"
          src="https://images.unsplash.com/photo-1611329857570-f02f340e7378?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          [alt]="'Decorative image representing this step in the process'">
        <!-- <img class="promo-img" *ngIf="presentationSteps[currentPresentationStepIndex].imageFileName"
          [src]="imageWithTransformations(presentationSteps[currentPresentationStepIndex].imageFileName,'w_450,c_fill/')"
          [alt]="'Decorative image representing this step in the process'"> -->
      </div>
      <div class="col-lg-7 align-items-start mt-3 mt-lg-0">
        <p
          *ngIf="presentationSteps[currentPresentationStepIndex].captionTranslationKey || presentationSteps[currentPresentationStepIndex].captionTranslationKey">
          {{t(presentationSteps[currentPresentationStepIndex].captionTranslationKey)}}</p>
        <h4 class="title">{{t(presentationSteps[currentPresentationStepIndex].titleTranslationKey)}}</h4>
        <p>{{t(presentationSteps[currentPresentationStepIndex].textTranslationKey)}}</p>

        <div
          class="d-flex flex-wrap justify-content-center justify-content-lg-start align-items-center mt-3 gap-2 gap-lg-4"
          *ngIf="!user">
          <button class="btn btn-primary w-50 w-lg-100 btn-lg" (click)="goToLogin()">
            <span>{{t('authentication.log_in_or_register')}}</span>
          </button>
          <a *ngIf="guest" (click)="nextPresentationStep()" class="link-secondary">
            {{t('common.skip')}}<i class="fa-solid fa-angle-right ml-2"></i>
          </a>
        </div>
        <div class="row align-items-center mt-2" *ngIf="!user && !guest">
          <span>
            <a (click)="makeGuest()" class="link-secondary" *ngIf="!loadingNewGuestError">
              <span>{{t('authentication.no_thanks_continue_without_login')}}</span>
            </a>
          </span>
          <span *ngIf="loadingNewGuestError" class="text-warning">
            {{t(loadingNewGuestError)}}
          </span>
          <span *ngIf="loadingNewGuest">
            <multisite-loader-globe-animation-detail [width]="30"
              [height]="30"></multisite-loader-globe-animation-detail>
            <span>{{t('authentication.creating_guest')}}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- Slide 4 -->
  <div class="stepper-body" *ngIf="currentPresentationStepKeyIncludes(['snapshot-intro'])">
    <multisite-gallery-cta [titleTranslationKey]="'survey.snapshot_heading'"
      [descriptionTranslationKey]="'survey.snapshot_info1'" [buttonLabelTranslationKey]="'common.i_am_ready'"
      [media]="null" (linkClicked)="nextPresentationStep()">
    </multisite-gallery-cta>
  </div>
  <!-- Slide 5 -->
  <div class="stepper-body" *ngIf="currentPresentationStepKeyIncludes(['snapshot-existing'])">

    <p
      *ngIf="presentationSteps[currentPresentationStepIndex].captionTranslationKey || presentationSteps[currentPresentationStepIndex].captionTranslationKey">
      {{t(presentationSteps[currentPresentationStepIndex].captionTranslationKey)}}</p>
    <h4 class="title">{{t(presentationSteps[currentPresentationStepIndex].titleTranslationKey)}}</h4>
    <p class="mb-3">{{t(presentationSteps[currentPresentationStepIndex].textTranslationKey)}}</p>
    <div class="d-flex gap-2 align-items-center justify-content-center">
      <button class="btn btn-primary" (click)="navigateTo(['/snapshots'])">{{t('survey.snapshots')}}</button>
      <button class="btn btn-primary" (click)="navigateTo(['/snapshots/'+currentAcceptedSnaphot().id])"
        *ngIf="currentAcceptedSnaphot()">{{t('survey.snapshot_active')}}</button>
    </div>


  </div>

  <div class="stepper-body" *ngIf="currentPresentationStepKeyIncludes(['snapshot-description','snapshot-cultures'])">
    <p
      *ngIf="presentationSteps[currentPresentationStepIndex].captionTranslationKey || presentationSteps[currentPresentationStepIndex].captionTranslationKey">
      {{t(presentationSteps[currentPresentationStepIndex].captionTranslationKey)}}</p>
    <h4 class="title">{{t(presentationSteps[currentPresentationStepIndex].titleTranslationKey)}}</h4>
    <p class="mb-3">{{t(presentationSteps[currentPresentationStepIndex].textTranslationKey)}}</p>
    <snapshot-create-edit-form-simple (submit)="storeNewSnapshot($event)"
      (submitFormStatus)="handleNewSnapshotFormStatus($event)"
      [hideCultureInputs]="!newSnapshotFormStatus.title || !newSnapshotFormStatus.description" [error]="error"
      [loading]="loading"></snapshot-create-edit-form-simple>

  </div>
</div>