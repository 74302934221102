import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ttt-design10',
  templateUrl: './design10.component.html',
  styleUrls: ['./design10.component.scss']
})
export class Design10Component implements OnInit {

  constructor() { }


  ngOnInit(): void {
  }

}
