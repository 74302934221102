import { Component, OnInit, Input } from '@angular/core';
import { Comment } from '@frontend/core'

@Component({
  selector: 'frontend-comment-type-one',
  templateUrl: './comment-type-one.component.html',
  styleUrls: ['./comment-type-one.component.scss']
})
export class CommentTypeOneComponent implements OnInit {

  @Input() comment : Comment;

  showReplyForm = false;

  constructor() { }

  toggleReplyFormVisibility (){
    this.showReplyForm = !this.showReplyForm;
  }


  ngOnInit(): void {
  }

}
