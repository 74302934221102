import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoursesRoutingModule } from './courses-routing.module';
import { CourseListPromoComponent } from './list-promo/course-list-promo.component';
import { CourseHomeComponent } from './course-home/course-home.component';
import { LessonComponent } from './lesson/lesson.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule as NgxTabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CommentsModule, VideoPlayersModule, AvatarsModule, PeopleCardsModule, PillsModule } from '@frontend/shared';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { PlaylistAccordionTextModule } from '../content';
import { LoadersModule } from '../loaders/loaders.module';
import { AlertComponent, AlertModule, AlertModule as NgxAlertModule } from 'ngx-bootstrap/alert';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RatingModule } from 'ngx-bootstrap/rating';
import { ProgressIndicatorCardSimpleModule } from '../tracking/progress/progress-indicator-card-simple/progress-indicator-card-simple.module';
import { SurveyQuestionsModule } from '../surveys';
import { ProductHeadersModule } from '../products';
import { TextListsModule } from '../content/lists/text';


@NgModule({
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'courses' }],
  declarations: [
    CourseListPromoComponent,
    CourseHomeComponent,
    LessonComponent,
  ],
  imports: [
    CommonModule,
    CoursesRoutingModule,
    ModalModule.forRoot(),
    AccordionModule,
    NgxTabsModule,
    NgxAlertModule,
    CollapseModule,
    VideoPlayersModule,
    TranslocoModule,
    PlaylistAccordionTextModule,
    LoadersModule,
    CommentsModule,
    FormsModule,
    ReactiveFormsModule,
    RatingModule,
    ProgressIndicatorCardSimpleModule,
    AvatarsModule,
    PeopleCardsModule,
    SurveyQuestionsModule,
    PillsModule,
    AlertModule,
    ProductHeadersModule,
    TextListsModule,
  ],
  exports: [
    CourseHomeComponent,
    LessonComponent,
  ]
})
export class CoursesModule { }
