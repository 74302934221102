import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CarouselModule } from 'ngx-bootstrap/carousel';

import { TopicLearnerLandingPageComponent } from './topic-learner-landing-page/topic-learner-landing-page.component';
import { RatingModule as NgxRatingModule } from 'ngx-bootstrap/rating';
import { TranslocoModule } from '@jsverse/transloco';
import { CardsModule } from '../cards';
import { LoadersModule } from '../../loaders';



@NgModule({
  declarations: [
    TopicLearnerLandingPageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CarouselModule,
    NgxRatingModule,
    TranslocoModule,
    CardsModule,
    LoadersModule,
  ],
  exports: [
    TopicLearnerLandingPageComponent
  ]
})
export class TopicsModule { }
