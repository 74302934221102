import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { RatingModule as NgxRatingModule } from 'ngx-bootstrap/rating';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TranslocoModule } from '@jsverse/transloco';

import { CardCtaHorizontalComponent } from './card-cta-horizontal/card-cta-horizontal.component';
import { RouterModule } from '@angular/router';
import { CarouselCardComponent } from './carousel-card/carousel-card.component';
import { CarouselCardQuotationsComponent } from './carousel-card-quotations/carousel-card-quotations.component';
import { LoadersModule } from '../../loaders/loaders.module';
import { CardBannerListItemPromoComponent } from './card-banner-list-item-promo/card-banner-list-item-promo.component';
import { CardListItemPromoComponent } from './card-list-item-promo/card-list-item-promo.component';
import { CardLeftBorderedComponent } from './left-bordered-card/left-bordered-card.component';


@NgModule({
  declarations: [
    CardCtaHorizontalComponent,
    CarouselCardComponent,
    CarouselCardQuotationsComponent,
    CardBannerListItemPromoComponent,
    CardListItemPromoComponent,
    CardLeftBorderedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxRatingModule,
    CarouselModule,
    TranslocoModule,
    LoadersModule,
  ],
  exports : [
    CardCtaHorizontalComponent,
    CarouselCardComponent,
    CarouselCardQuotationsComponent,
    CardBannerListItemPromoComponent,
    CardListItemPromoComponent,
    CardLeftBorderedComponent,
  ]
})
export class CardsModule { }
