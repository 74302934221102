<div class="container">
  <div class="row mb-5">
    <div class="col-lg-8 mb-4">

      <div *transloco="let t">
        <h1 class="static-title h1 fw-bold mt-4">
          {{t('branding.add_new_brand')}}
        </h1>
        <!-- <p>Start by entering the basic information about the brand. Later you can add and edit the details.</p> -->
        <div class="d-flex gap-4 mt-2">
          <a class="btn-link"
            (click)="toggleCollapsedContent('rules')">{{t('common.rules')}}&nbsp;|&nbsp;{{collapsedContent.rules
            ? t('common.show'):t('common.hide')}}</a>
          <a class="btn-link"
            (click)="toggleCollapsedContent('help')">{{t('common.more_info')}}&nbsp;|&nbsp;{{collapsedContent.help
            ? t('common.show'):t('common.hide')}}</a>
        </div>
        <div class="mt-2" [collapse]="collapsedContent.rules">
          <div class="well well-lg card card-block card-header">
            <h4>{{t('branding.name_rules')}}</h4>
            <ul>
              <li *ngFor="let rule of slugRulesEmptyIterator; let i = index">{{t('branding.slug_rule_'+i)}}
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-2" [collapse]="collapsedContent.help">
          <div class="well well-lg card card-block card-header">
            <h4>{{t('branding.name_guide')}}</h4>
            <ul>
              <li *ngFor="let help of slugHelpEmptyIterator; let i = index">{{t('branding.slug_help_'+i)}}</li>
            </ul>
          </div>
        </div>

        <form [formGroup]="slugForm" role="form" class="mt-4" *ngIf="slugForm">
          <div class="input-self mb-3">
            <label for="slug" class="form-label">{{ t('content_management.web_address') }}<span
                class="required-sign">*</span>
            </label>
            <div class="input-group" [ngClass]="{
                'ng-invalid':
                  slugForm.get('slug').invalid && slugForm.get('slug').touched,
                'ng-valid':
                  slugForm.get('slug').valid &&
                  slugForm.get('slug').touched &&
                  slugForm.get('slug').dirty
              }">
              <span class="input-group-text text-primary-light">
                <i class="fa fa-info" aria-hidden="true"></i>
              </span>
              <input class="form-control" type="text" formControlName="slug" id="slug" placeholder="" />
              <div *ngIf="
                  slugForm.get('slug').errors?.required &&
                  slugForm.get('slug').touched
                " class="invalid-feedback">
                {{ t('common.required') }}
              </div>
              <div *ngIf="
                  slugForm.get('slug').errors?.minlength &&
                  slugForm.get('slug').touched
                " class="invalid-feedback">
                {{ t('common.too_short') }}
              </div>
              <div *ngIf="
                  slugForm.get('slug').errors?.maxlength &&
                  slugForm.get('slug').touched
                " class="invalid-feedback">
                {{ t('common.too_long') }}
              </div>
              <div *ngIf="
                  slugForm.get('slug').errors?.pattern &&
                  slugForm.get('slug').touched
                " class="invalid-feedback">
                {{ t('content_management.slug_validation_error') }}
              </div>
            </div>
            <div class="form-text">
              {{ t('branding.web_address_help') }}
            </div>
          </div>

          <div class="input-self my-4">
            <label for="name" class="form-label">{{ t('common.name') }}<span class="required-sign">*</span>
            </label>
            <div class="input-group" [ngClass]="{
                'ng-invalid':
                  slugForm.get('name').invalid && slugForm.get('name').touched,
                'ng-valid':
                  slugForm.get('name').valid &&
                  slugForm.get('name').touched &&
                  slugForm.get('name').dirty
              }">
              <span class="input-group-text text-primary-light">
                <i class="fa fa-info" aria-hidden="true"></i>
              </span>
              <input class="form-control" type="text" formControlName="name" id="name"
                placeholder="{{ 'branding.name' | transloco }}" />
              <div *ngIf="
                  slugForm.get('name').errors?.required &&
                  slugForm.get('name').touched
                " class="invalid-feedback">
                {{ t('common.required') }}
              </div>

            </div>
            <div class="form-text">
              {{ t('content_management.not_public') }}
            </div>
          </div>

          <div class="simple-color-picker my-4">
            <div class="card">
              <div class="card-body">
                <div class="header">
                  <h5 class="card-title font-weight-bold">{{t('branding.set_primary_colour')}}</h5>
                  <h6 class="card-subtitle">
                    {{t('branding.click_coloured_shape')}}
                  </h6>
                </div>
                <div class="color-picker">
                  <input type="color" formControlName="primary_colour" [disabled]="loading" class="color-picker-square"
                    aria-label="Coloured square for choosing a colour" />
                  <!-- <input class="form-control" type="text" placeholder="{{hub.brands[1].colours[1].hex}}"> -->
                  <!-- <p class="color-name">
                    Primary
                  </p> -->
                </div>
              </div>
            </div>
          </div>
        </form>


        <alert class="my-3" [type]="'warning'" [dismissible]="true" *ngIf="error">
          <span *transloco="let t">{{t(error)}}</span>
        </alert>


        <p class="multiple-choice-question-feedback my-4 p-3">
          <span class="icon-holder">
            <i class="fa-regular fa-lightbulb"></i>
          </span>
          <span>{{ t('branding.edit_more_later') }}</span>
        </p>

        <div class="d-flex flex-row gap-4 justify-content-end">
          <button class="btn btn-warning" (click)="doCancelNewBrandMode()" [disabled]="loading">
            {{ t('common.cancel') }}
          </button>
          <button class="btn btn-primary" (click)="submit()" [disabled]="!slugForm?.valid || loading">
            {{ t('common.save') }} New Brand
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <multisite-link-type-six *ngFor="let link of links"
        [imageTransformations]="'w_100,c_thumb/'"
        [titleText]="link.label"
        [description]="link.description"
        [imageUrl]="link.media?.base_url"
        [media]="link.media"
        [cta_label]="link.cta_label"
        [cta_type]="link.cta_type"
        [url]="link.url"
      >
      </multisite-link-type-six>
    </div>
  </div>
</div>