import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, QueryParamsHandling, NavigationExtras } from '@angular/router';
import { SortCriterion } from './sort-criterion.model';
import { Tag } from '@frontend/core';

export type QueryParams = {
  [key: string]: string | string[];
};

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {

  constructor(private router: Router, private route: ActivatedRoute) { }

  updateQueryParams(paramName: string, value: string, add: boolean) : QueryParams {
    // Get current query params
    const queryParams = { ...this.route.snapshot.queryParams };
    
    // Get current values for the specified paramName
    let values = queryParams[paramName] ? queryParams[paramName].split(',') : [];

    // Add or remove value
    if (add) {
      if (!values.includes(value)) {
        values.push(value);
      }
    } else {
      values = values.filter(v => v !== value);
    }

    // Update query params with the new values or remove the param if it's empty
    if (values.length > 0) {
      queryParams[paramName] = values.join(',');
    } else {
      delete queryParams[paramName];
    }

    return queryParams;

    // Recommended: now call navigateAfterUpdatingQueryParams

    }
    
    navigateAfterUpdatingQueryParams(queryParams : QueryParams, mergeExistingParams = true){
      const extras : NavigationExtras = { 
        relativeTo: this.route,
        queryParams: queryParams,
        // queryParamsHandling: queryParamsHandling, // 'merge' means merge with existing query parameters; alternatively, use 'preserve'
        replaceUrl: true // Replace the current URL without adding to browser history
      };
      if(mergeExistingParams){
        extras.queryParamsHandling = 'merge';
      }
      this.router.navigate([], extras);
    }

    isValueInQueryParams(queryParams : QueryParams, paramName: string, value: string): boolean {
      // const queryParams = this.route.snapshot.queryParams;
      const paramValues = queryParams[paramName];
  
      if (paramValues) {
        // Check if the paramValues string contains the value
        return (paramValues as string).toLowerCase().split(',').includes(value);
      }
  
      return false;
    }

    updateSortOrderParams(sortable:SortCriterion, sort_criteria : SortCriterion[], queryParams : QueryParams) : {sort_criteria:SortCriterion[],queryParams:QueryParams}{ // TODO this method is overly complicated and should be simplified
      if(!sortable){ // by current logic, we should always receive a 'sortable' value here
        sort_criteria.forEach(sc=>{
          sc.sort_order = null;
        })
        delete queryParams['sort_by'];
        delete queryParams['sort_order'];
      } else {
        if(!sortable.sort_order){
          sortable.sort_order = sortable.default_sort_order;
        } else {
          sortable.sort_order = sortable.sort_order === 'asc' ? 'desc':'asc'
        }
        sort_criteria.filter(sc=>sc.sort_by!==sortable.sort_by).forEach(sc=>{
          sc.sort_order = null;
        });
        queryParams.sort_by = sortable.sort_by;
        queryParams.sort_order = sortable.sort_order;
      }
      return {queryParams:queryParams,sort_criteria:sort_criteria};
    }

    isTagIncludedInQueryParams(tag:Tag,queryParams:QueryParams){
      if(!tag || !queryParams){return false};
      for (const key in queryParams) {
        if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
          const params = queryParams[key];
          if (tag.type === key && Array.isArray(params.toString().split(',')) && params.toString().split(',').includes(tag.slug)){
            return true;
          }
        }
      }
      return false;
    }

}
