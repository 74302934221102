<div *transloco="let t">
  <h1 class="mb-0">
    {{ t('authentication.new_password') }}
  </h1>
  <p>{{ t('authentication.make_new_password') }}</p>
  <div class="registration-form mt-4">
    <div class="reset-info" *ngIf="showSuccessAlert || showWarningAlert">
      <alert
        [type]="'success'"
        [dismissible]="true"
        (onClosed)="hideAlerts()"
        *ngIf="showSuccessAlert"
      >
        <div class="align-middle" *transloco="let t">
          <strong class="ml-1">{{t('common.success')}}</strong>
        <span>{{t('authentication.password_reset_success')}}</span>
        <button class="btn btn-primary m-2" [routerLink]="'/login'">{{t('common.log_in')}}</button>
        </div>

      </alert>
      <alert
        [type]="'warning'"
        [dismissible]="true"
        (onClosed)="hideAlerts()"
        *ngIf="showWarningAlert"
      >
        <strong>There was a problem with this. Please try again.</strong>
      </alert>
    </div>
    <div *ngIf="!showSuccessAlert">
       <form [formGroup]="PasswordResetForm" role="form" *transloco="let t">
      <!-- Password* -->
      <div class="input-self">
        <label for="password" class="form-label">
          {{ t('authentication.password')
          }}<span class="required-sign">*</span></label
        >
        <div
          class="input-group mb-3"
          [ngClass]="{
            'ng-invalid':
              (PasswordResetForm.get('password').invalid ||
                !patternValid.password) &&
              (PasswordResetForm.get('password').dirty || PasswordResetForm.get('password').touched),
            'ng-valid':
              PasswordResetForm.get('password').valid &&
              patternValid.password &&
              PasswordResetForm.get('password').touched &&
              PasswordResetForm.get('password').dirty
          }"
        >
          <span class="input-group-text text-primary-light">
            <i class="fa fa-unlock-alt" aria-hidden="true"></i>
          </span>
          <input
            class="form-control input-lg"
            type="password"
            id="password"
            formControlName="password"
          />
          <div
            *ngIf="
              (PasswordResetForm.get('password').errors ||
                !patternValid.password) &&
              (PasswordResetForm.get('password').touched ||
                PasswordResetForm.get('password').dirty)
            "
            class="invalid-feedback"
          >
            <div *ngIf="PasswordResetForm.get('password').errors?.required">
              {{ t('common.required') }}
            </div>
            <div
              *ngIf="
                PasswordResetForm.get('password').errors?.minlength ||
                !patternValid.password
              "
            >
              {{ t('authentication.8char_1digit') }}
            </div>
            <div *ngIf="PasswordResetForm.get('password').errors?.maxlength">
              {{ t('common.too_long') }}
            </div>
          </div>
        </div>
      </div>
      <!-- Repeat password* -->
      <div class="input-self">
        <label for="repeatPassword" class="form-label">
          {{ t('authentication.password_again')
          }}<span class="required-sign">*</span></label
        >
        <div
          class="input-group mb-3"
          [ngClass]="{
            'ng-invalid':
              (PasswordResetForm.get('repeatPassword').invalid ||
                !patternValid.repeatPassword ||
                passwordMatchValidator(PasswordResetForm)) &&
              (PasswordResetForm.get('repeatPassword').touched ||
                PasswordResetForm.get('repeatPassword').dirty),
            'ng-valid':
              PasswordResetForm.get('repeatPassword').valid &&
              patternValid.repeatPassword &&
              passwordMatchValidator(PasswordResetForm) === null &&
              
                PasswordResetForm.get('repeatPassword').dirty
          }"
        >
          <span class="input-group-text text-primary-light">
            <i class="fa fa-unlock-alt" aria-hidden="true"></i>
          </span>
          <input
            class="form-control input-lg"
            type="password"
            id="repeatPassword"
            formControlName="repeatPassword"
          />
          <div
            *ngIf="
              (PasswordResetForm.get('repeatPassword').errors ||
                passwordMatchValidator(PasswordResetForm) !== null) &&
              PasswordResetForm.get('repeatPassword').dirty
            "
            class="invalid-feedback"
          >
            <div *ngIf="PasswordResetForm.get('repeatPassword').errors?.required">
              {{ t('common.required') }}
            </div>
            <div
              *ngIf="PasswordResetForm.get('repeatPassword').errors?.minlength"
            >
              {{ t('authentication.8char_1digit') }}
            </div>
            <div
              *ngIf="PasswordResetForm.get('repeatPassword').errors?.maxlength"
            >
              {{ t('common.too_long') }}
            </div>
            <div *ngIf="passwordMatchValidator(PasswordResetForm) !== null &&
            PasswordResetForm.get('repeatPassword').touched">
              {{ t('authentication.password_confirmation_error') }}
            </div>
          </div>
        </div>
      </div>
      <!-- Submit | Button -->
      <div class="input-self">
        <div class="input-group my-3">
          <button 
            *ngIf="!loading"
            class="btn btn-lg btn-primary w-100"
            [disabled]="
              PasswordResetForm.status === 'INVALID' || !patternValid.password
            "
            (click)="sendRequest()"
          >
            {{ t('common.confirm') }}
          </button>
          <multisite-loader-globe-animation-detail
            *ngIf="loading"
            [width]="100"
            [height]="100"
          >
          </multisite-loader-globe-animation-detail>
        </div>
      </div>
    </form>
  </div>
  </div>
</div>
