import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ContentItem } from '../../content/content-item.model';
import { Hub, HubService } from '../../hubs';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
// import { PageTitleService } from '../../navigation/title/title.service';
import { PaginatedProgrammes } from '../paginated-programmes.model';
import { Programme } from '../programme.model';
import { ProgrammeService } from '../programme.service';

@Component({
  selector: 'ttt-programmes',
  templateUrl: './programme-list-promo.component.html',
  styleUrls: ['./programme-list-promo.component.scss'],
})
export class ProgrammeListPromoComponent implements OnInit {
  // Added by milos for rating component
  max = 5;
  rate = 5;

  contentSubscription: Subscription;
  topicSubscription: Subscription;
  loading: boolean;
  hubSlug: string;
  hub: Hub;
  // contentClassName: string; // programmes
  programmesAsContent: ContentItem[];
  thumbnailTransformations: string = 'w_375/';
  paginatedProgrammes : PaginatedProgrammes = null;
  error = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private programmeService: ProgrammeService,
    private hubService: HubService,
    // private titleService: PageTitleService,
    private breadcrumbService : BreadcrumbService,
  ) {}

  makeContentItems(programmes) {
    let programmesAsContent: ContentItem[] = [];
    programmes.forEach((c) => {
      programmesAsContent.push(
        new ContentItem(
          c.id,
          c.name,
          null,
          null,
          null,
          c.description,
          null,
          null,
          c.slug,
          'Go to programme',
          'common.manage',
          this.programmeService.getImage(c.media, 'thumbnail'),
          null,
          null,
          c.rating,
          null,
          c.start_at ? new Date(c.start_at): null,
          c.tags,
          null,
          null
        )
      );
    });
    return programmesAsContent;
  }
  navigateTo(programmeSlug:string, event : Event) {
    event.stopPropagation();
    this.router.navigate(['./'+programmeSlug], { relativeTo: this.route });
  }
  roundRating (num: number){
    return num ? Number(num).toFixed(1): 0;
  }
  doGetContent (page: number){
    this.getContent(this.hub, page, null);
  }
  getContent(hub:Hub, page: number, freshFromServer: boolean) {
    this.loading = true;

    this.contentSubscription = this.programmeService
      .getProgrammes(hub, page,freshFromServer)
      .subscribe(
        (response) => {
          this.paginatedProgrammes = response;
          this.loading = false;
          this.programmesAsContent = this.makeContentItems(this.paginatedProgrammes.data);
        },
        (error) => {
          this.loading = false;
        }
      );
  }
  getHub (slug){
    this.hubService.getManagedHub(slug).subscribe(response => {
      this.hub = response;
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'hub',fragmentName:this.hub.name});
      this.getContent(this.hub,null,null)

    })
  }

  ngOnInit(): void {
    this.route.url.subscribe(() => {
      let fullUrlParts = this.router.routerState.snapshot.url
        .toLowerCase()
        .split('/')
        .filter((s) => s);
      let slug = fullUrlParts[1];
      // this.contentClassName = fullUrlParts[2];
      this.getHub(slug);
    });
  }
}
