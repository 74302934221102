import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'multisite-step-progress-indicator',
  templateUrl: './step-progress-indicator.component.html',
  styleUrls: ['./step-progress-indicator.component.scss']
})
export class StepProgressIndicatorComponent implements OnChanges {

  @Input() stepCount : number; // 8
  @Input() stepProgress : number; // 3
  @Input() progressClasses : string; // 'traffic-light-9'
  steps: number [] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) { 
  }

  ngOnChanges(changesObject): void {

    if (changesObject.stepCount.currentValue && (changesObject.stepProgress.currentValue === 0 || changesObject.stepProgress.currentValue > 0)){
      this.steps = Array.from({ length: this.stepCount}, (_, index) => 1 + index);
    }
    if (changesObject.stepProgress.currentValue === 0 || changesObject.stepProgress.currentValue > 0){
      this.changeDetectorRef.detectChanges(); // we had a problem that the progress was not updating when changed 
    }
    if (changesObject.stepProgress){
      this.changeDetectorRef.detectChanges(); // we had a problem that the progress was not updating when changed 
    }
  }

}
