<div class="container">
    <div class="row mt-3 feed-with-filters">
    <div class="col-xl-9 col-lg-8 col-md-7">
      <div class="feed-card-holder">


        <div *ngFor="let hub of paginatedContentItems?.data">
          <card-banner-list-item-promo
            class="promo-link"
            [content]="hub"
            [imageTransformations]="'w_700,c_fill,ar_16:9/'"
            (click)="gotoHub(hub)"
          ></card-banner-list-item-promo>
        </div>

        <div class="my-3">
            <frontend-pagination-type-three
                *ngIf="paginatedContentItems?.meta && paginatedContentItems.meta.last_page > 1"
                [classes]="'pg-purple justify-content-center'" [loading]="loading"
                [meta]="paginatedContentItems.meta" [lastPage]="paginatedContentItems.meta.last_page"
                [links]="paginatedContentItems.links" (changePage)="doGetPaginatedContent($event)">
            </frontend-pagination-type-three>
        </div>

        <multisite-loader-globe-animation-detail
          *ngIf="loading"
          width="100"
          height="100"
          class="my-3"
        ></multisite-loader-globe-animation-detail>
        
        <alert [type]="'warning'" *ngIf="!loading && paginatedContentItems?.data && !paginatedContentItems.data.length">
          <div class="text-center my-5" *transloco="let t">{{ t('common.no_results') }}</div>
        </alert>

      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-5">
      <div class="feed-filters">
        
        <div
          class="filters-body"
          id="collapseFilters"
          [collapse]="areFiltersCollapsed"
          [isAnimated]="true"
        >

        <frontend-pagination-type-three
          *ngIf="paginatedContentItems?.meta && paginatedContentItems.meta.last_page > 1"
          [classes]="'pg-purple justify-content-center'"
          [loading]="loading"
          [meta]="paginatedContentItems.meta"
          [lastPage]="paginatedContentItems.meta.last_page"
          [links]="paginatedContentItems.links"
          [simplified]="true"
          (changePage)="doGetPaginatedContent($event)"
        >
        </frontend-pagination-type-three>

        <div *transloco="let t" class="my-2 text-center">
          <span>{{t('common.sort')}}</span>
          <span *ngFor="let sortable of sort_criteria" (click)="setSortOrderParams(sortable)" class="badge badge-pill clickable mr-1" [ngClass]="{'badge-primary': queryParams['sort_by'] === sortable.sort_by,'badge-secondary': queryParams['sort_by'] !== sortable.sort_by,'disabled':loading}">
            <span *ngIf="queryParams['sort_by'] === sortable.sort_by">
              <span *ngIf="sortable.sort_order === 'asc'"><i class="fa-solid fa-arrow-up mr-1"></i></span>
              <span *ngIf="sortable.sort_order === 'desc'"><i class="fa-solid fa-arrow-down mr-1"></i></span>
            </span>
            <span>{{queryParams['sort_by'] === sortable.sort_by && sortable.sort_order === 'asc' ? t(sortable.translationKeyAsc) : t(sortable.translationKeyDesc)}}</span>
          </span>
          <span class="badge badge-pill badge-secondary clickable" [ngClass]="{disabled:loading}" (click)="setSortOrderParams(null)">{{t('common.none')}}</span>
  
        </div>

        <button *transloco="let t"
          type="button"
          class="btn btn-primary w-100 toggle-filters"
          (click)="areFiltersCollapsed = !areFiltersCollapsed"
          [attr.aria-expanded]="!areFiltersCollapsed"
          aria-controls="collapseFilters"
        >
          {{t('common.filters')}} <i class="ml-2 fa-solid fa-angle-down"></i>
        </button>

        <form class="mt-3" [formGroup]="searchForm" role="form" class="mt-3" *ngIf="searchForm" (ngSubmit)="onSubmit()">
            <div class="input-group" *transloco="let t">
                <input
                  type="search"
                  class="form-control search"
                  [placeholder]="t('navigation.search')+': '+t('common.name')"
                  aria-label="Enter what you want to search"
                  formControlName="query"
                />
                <span class="input-group-text" id="keyword-add-on" [ngClass]="{'disabled':searchForm.get('query').errors}"><i class="fa-solid fa-magnifying-glass"></i></span>
            </div>
            <label for="selected-filters" class="mb-2" *transloco="let t" class="filters-title">{{t('social.member_type')}}</label>
            <div id="selected-filters" *transloco="let t">
                <div class="form-check">
                    <input
                      value="individual"
                      formControlName="individual"
                      class="form-check-input"
                      type="checkbox"
                      id="individual"
                      [attr.disabled]="loading ? true : null"
                    />
                    <label class="form-check-label" for="individual">
                      {{t('social.individual')}}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      value="business"
                      formControlName="business"
                      class="form-check-input"
                      type="checkbox"
                      id="business"
                      [attr.disabled]="loading ? true : null"
                    />
                    <label class="form-check-label" for="business" class="filters-title">{{t('social.business')}}</label>
                </div>
            </div>
            <div class="d-flex mt-3 justify-content-between align-items-center">
              <button class="btn btn-primary" [disabled]="loading || searchForm.invalid" (click)="getContent(queryParams)" *transloco="let t">{{t('common.search')}}</button>
              <div *ngIf="loading">
                <multisite-loader-globe-animation-detail width="40" height="40"></multisite-loader-globe-animation-detail>
              </div>
            </div>
            
        </form>

    

        </div>

      </div>
    </div>
  </div>
</div>
