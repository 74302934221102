import { Component, OnInit, Input } from '@angular/core';
import { Comment } from '@frontend/core'

@Component({
  selector: 'frontend-comment-type-two',
  templateUrl: './comment-type-two.component.html',
  styleUrls: ['./comment-type-two.component.scss']
})
export class CommentTypeTwoComponent implements OnInit {

  @Input() comment : Comment;
  hideReplyForm : boolean;
  allowEditing : boolean; // hard-coded for now. We'll implement later
  allowReplies : boolean; // hard-coded for now. We'll implement later

  constructor() { }

  toggleReplyFormVisibility (){
    this.hideReplyForm = !this.hideReplyForm;
  }


  ngOnInit(): void {
    this.hideReplyForm = true;
  }

}
