import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';

import { MediaService } from '@frontend/common';
import { Link } from '@frontend/core';
import { of, throwError } from 'rxjs';

interface linksResponse {
  'data': Link[]
}
export interface DynamicLinkRequest {
    class: string, // 'lesson' or 'course' or 'tip'
    slug: string, // "sint-illum-consectetur-rerum-hic-velit-omnis-assumenda3336",
    topic: string, // "cultureconnector" or "icpendulum",
    site: string, // "culturettt", NOTE!!!!!!! put 'demo' here if you want a demo placeholder response
    subdomain: string, // slug of a brand
}

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  linksByModel : any;
  userLinks : Link[];
  callToActionTypes : {cta_label: string, types : string[]}[];

  constructor(
    private http: HttpClient,
    private mediaService: MediaService
    ) {
    this.linksByModel = {};
    this.callToActionTypes = [
      {cta_label: 'get', types : ['resource','book']},
      {cta_label: 'view', types : ['publication','news','channel','journal','blog','profile','person']},
      {cta_label: 'connect', types : ['community','connect']},
      {cta_label: 'play', types : ['player']},
      {cta_label: 'listen', types : ['podcast']},
      {cta_label: 'watch', types : ['video']},
      {cta_label: 'start', types : ['course']},
      {cta_label: 'visit', types : ['organisation']},
    ];
   }


   getPlaceholder (){ // make a dummy link
    let link : Link = {
      id:244,
      name:"A podcast about virtual teams",
      label:"21st Century Work Life",
      url:"https:\/\/www.virtualnotdistant.com\/podcasts",
      description:"A podcast about virtual teams and other aspects of working life. Hosted by Pilar Orti.",
      cta_label:"Listen",
      cta_type:"podcast",
      category:"shownotes",
      type:"resources",
      media:{
        id:156,
        medially_type:"App\\Models\\Link",
        medially_id:244,
        file_url:"https:\/\/res.cloudinary.com\/cebt\/image\/upload\/v1671029175\/resources\/podcastlogo_yagluo.png",
        file_name:"resources\/podcastlogo_yagluo.png",
        file_type:"image",
        size:22870,
        uploader_id:1,
        description:"Podcast logo: 21st Century Work Life",
        credits:"Pilar Orti",
        permission:null,
        category:"intercultural",
        base_url:"https://res.cloudinary.com/cebt/image/upload/",
        transformations_url:"c_thumb,w_200,g_face/",
        asset_url:"v1611931415/persons/smiling-businessperson-globalintegration-flickr-cc2-5347195359_8b3a32e4ab_o_y5tcks.jpg",
        type:"concepts",
        created_at: new Date("2022-12-14T13:00:03.000000Z"),
        updated_at:new Date("2022-12-14T13:00:03.000000Z")
      },
      icon:{identifier_1:'fa-podcast',identifier_2:null}};
    return link;
   }

    transformLink (link){
      if (link?.media){
        link.media = Object.assign(link.media, this.mediaService.setupCloudinaryImageMediaUrls(link.media));
      };
      return link;
    };
    transformLinks (links){
      if (links?.length){
        links.forEach(link => {
          link = this.transformLink(link);
        });
      };
      return links;
    }

   cacheLinksDataByModel (modelNameSingular,identifier, data){
    let cachedIdentifier = !Number(identifier) ? identifier.replaceAll('-','') : identifier;    if (!this.linksByModel[modelNameSingular]){
      this.linksByModel[modelNameSingular] = {};
    };
    if (!this.linksByModel[modelNameSingular][cachedIdentifier]){
      this.linksByModel[modelNameSingular][cachedIdentifier] = {};
    };
      this.linksByModel[modelNameSingular][cachedIdentifier] = data;
    }

    getLinks (modelNameSingular,identifier){
      let cachedIdentifier = !Number(identifier) ? identifier.replaceAll('-','') : identifier;
      if (this.linksByModel[modelNameSingular] && this.linksByModel[modelNameSingular][cachedIdentifier]){
        return of(this.linksByModel[modelNameSingular][cachedIdentifier]);
      };
      return this.http.get<linksResponse>(
        'api/v1/links/'+modelNameSingular+'/'+identifier)
          .pipe(
            map(response =>{
              if (response && response.data ){
                response.data = this.transformLinks(response.data);
                this.cacheLinksDataByModel(modelNameSingular,identifier,response.data);
                return response.data;
              };
            })
          )
    };

    getUserLinks (){
      if (this.userLinks){
        return of(this.userLinks);
      };
      let filters="profile,social";
      return this.http.get<linksResponse>(
        'api/v1/user-links/filtered?categories='+filters)
          .pipe(
            map(response =>{
              if (response && response.data ){
                response.data = this.transformLinks(response.data);
                this.userLinks = response.data;
                return response.data;
              };
            })
          )
    };
    getDynamicLinks (links: DynamicLinkRequest[]){
      return this.http.post<linksResponse>(
        'api/v1/links/dynamic',{links:links})
          .pipe(
            map(response =>{
              if (response && response.data ){
                response.data = this.transformLinks(response.data);
                // no caching?
                return response.data;
              };
            })
          )
    };
    updateUserProfileLink (link : Link){
      return this.http.patch<{'data':Link,'message':string,'link_id':number}>(
        'api/v1/user-links/update/'+link.id,link)
          .pipe(
            catchError((error)=>{
              console.log('an error occurred: '+error);
              return throwError('a problem updating links');
            }),
            map(response =>{
              if (!response || !this.userLinks?.length){
                throw 'a problem updating links';
              };
              if (response.data){
                response.data = this.transformLink(response.data);
                let updatedLinkIndex = this.userLinks.findIndex(l=>l.id == response.data.id);
                this.userLinks[updatedLinkIndex] = response.data;
              } else if (response.message && response.message == 'link_deleted'){
                this.userLinks = this.userLinks.filter(l=>l.id !== link.id);
              };
              return this.userLinks;
            })
          )
    };
    createUserProfileLink (link : Link){
      return this.http.post<{'data':Link}>(
        'api/v1/user-links',link)
          .pipe(
            catchError((error)=>{
              console.log('an error occurred: '+error);
              return throwError('a problem creating links');
            }),
            map(response =>{
              if (response && response.data ){
                response.data = this.transformLink(response.data);
                if (!this.userLinks){
                  this.userLinks = [];
                };
                this.userLinks.push(response.data);
                return this.userLinks;
              };
            })
          )
    };

}
