import { Icon } from "../../icons";
import { IconLinkListLink } from "./icon-link-list-link.model";

export class IconLinkListLinkGroup {
  constructor(
    public links : IconLinkListLink[],
    public titleIconUrl? : string, // "https://res.cloudinary.com/cebt/image/upload/w_32,c_fill,ar_1:1,r_max,f_auto/flags/round/omnsfH.png"
    public titleIcon? : Icon, // {identifier_1 : 'fa-solid fa-star', identifier_1 : null}
    public titleText? : string, // "China"
    public titleTranslationKey? : string, // "tools.toolbox"
  ){ }
}