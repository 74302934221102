import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressIndicatorsSlimmableModule } from '../progress-indicators-slimmable/progress-indicators-slimmable.module';
import { ProgressableProgressSummariserComponent } from './progressable-progress-summariser/progressable-progress-summariser.component';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    ProgressableProgressSummariserComponent
  ],
  imports: [
    CommonModule,
    ProgressIndicatorsSlimmableModule,
    TranslocoModule
  ],
  exports: [
    ProgressableProgressSummariserComponent
  ]
})
export class ProgressTrackingModule { }
