import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SurveyQuestionResponseComponent } from './survey-question-response/survey-question-response.component';
import { SurveyResponseListComponent } from './survey-response-list/survey-response-list.component';
import { SurveyStartComponent } from './survey-start/survey-start.component';

const routes: Routes = [
  {
    path: '',
    component: SurveyResponseListComponent,
  },
  {
    path: ':survey',
    data: { label: 'Survey homepage', translationKey: null, labelSource: 'titleService'},
    children: [
      {
        path: '',
        // pathMatch : 'full',
        component: SurveyStartComponent,
      },
      {
        path: ':question',
        component: SurveyQuestionResponseComponent,
        data: { label: 'Question', translationKey: null, labelSource: 'titleService',
        },
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SurveyResponseRoutingModule {}
