import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ContentItem } from '../../../content/content-item.model';
import { PaginatedHubs } from '../../../hubs/paginated-hubs.interface';
import { HubService } from '../../../hubs/hub.service';
import { AvailableLanguage, HubLite, LanguageService, PaginatedContentItems, QueryParamsService, WindowService } from '@frontend/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SortCriterion } from '@frontend/common';

@Component({
  selector: 'culturequestion-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class CommunitySearchComponent implements OnInit {

  contentSubscription: Subscription;
  loading: boolean;
  paginatedHubs: PaginatedHubs;
  paginatedContentItems: PaginatedContentItems;
  imageTransformations: string = 'w_700,c_fill,ar_16:9/';
  activeLanguageSubscription: Subscription;
  activeLanguageObject: AvailableLanguage;
  searchForm : FormGroup;
  sort_criteria : SortCriterion[];
  maxWidthMediaQuery : string; // '(max-width: 991.98px)'
  @HostListener('window:resize', ['$event'])
  resizeWindow() {
    this.refreshCollapse();
  }
  areFiltersCollapsed: boolean;
  queryParams : {[key:string]:string | string[]} // 'cultures=france,germany'
  maxQueryStringLength : number = 50;

  constructor(
    @Inject('appDomain') private appDomain: string,
    private router: Router,
    private route: ActivatedRoute,
    private hubService: HubService,
    private languageService: LanguageService,
    private windowService: WindowService,
    private queryParamsService: QueryParamsService,
  ) {
    this.maxWidthMediaQuery = '(max-width: 767px)'; // If more than this max-width, we open the filter
    this.resetSortCriteria();
  }

  resetSortCriteria() : SortCriterion {
    let default_sort_criteria : SortCriterion[] = [ // The first of this will be the default
      {sort_by:'name',sort_order:null,default_sort_order:'asc',translationKeyAsc:'common.name',translationKeyDesc:'common.name'},
      {sort_by:'created_at',sort_order:null,default_sort_order:'asc',translationKeyAsc:'common.first',translationKeyDesc:'common.latest'},
    ];
    this.sort_criteria = [...default_sort_criteria];
    return this.sort_criteria[0];
  }

  setSortOrderParams(sortable:SortCriterion){
    if(this.loading || this.paginatedHubs.meta?.total === 1){return;};
    if(!sortable){
      this.updateSortOrderParams(this.resetSortCriteria());
    } else {
      this.updateSortOrderParams(sortable);
    }
    this.getContent(this.queryParams,true);
  }


  updateSortOrderParams(sortable:SortCriterion){
    let updatedSettings = this.queryParamsService.updateSortOrderParams(sortable,this.sort_criteria,this.queryParams);
    
    this.sort_criteria = updatedSettings.sort_criteria;
    this.queryParams = updatedSettings.queryParams;
  }

  gotoHub(hubAsContentItem: ContentItem){
    if (hubAsContentItem){
        const hub = (this.paginatedHubs?.data as HubLite[]).find((h : HubLite)=>h.id==hubAsContentItem.id);
      if(hub?.primary_brand_slug && this.appDomain){
        window.open((this.appDomain.includes('localhost') ? 'http://':'https://') + hub.primary_brand_slug +'.'+ this.appDomain, '_self');
      }
    }
  }
  doGetPaginatedContent(whichPage){
    this.queryParams['page']=whichPage;
    this.getContent(this.queryParams);
  }
  onSubmit() {
    if (this.searchForm.invalid) {
      // If the form is invalid, mark all controls as touched to trigger validation messages
      this.searchForm.markAllAsTouched();
      return;
    }
    this.getContent(this.queryParams);
  }
  getContent(queryParams : {[key:string]:string |string[]}, freshFromServer:boolean = false) {

    let page = queryParams['page'] ? +queryParams['page'] : 1;
    this.queryParams = queryParams;
    this.queryParamsService.navigateAfterUpdatingQueryParams(queryParams);
    this.loading = true;

    this.contentSubscription = this.hubService
      .getPaginatedPublicHubs(queryParams,page,freshFromServer)
      .subscribe(
        (response) => {
          this.paginatedHubs = response;
          this.loading = false;
          this.paginatedContentItems = {
            data : this.hubService.makeContentItems(this.paginatedHubs.data),
            links : this.paginatedHubs.links,
            meta : this.paginatedHubs.meta,
          }
        },
        (error) => {
          this.loading = false;
        }
      );
  }
  navigate(queryParams:{[key:string]:string}): void {
    this.router.navigate([], {
      queryParams: queryParams,
      queryParamsHandling: 'merge', // Merge with existing query parameters
      replaceUrl: true // Replace the current URL without adding to browser history
    });
  }

  generateForm (){
    let individualChecked : boolean = !this.queryParams['type'] || (this.queryParams['type'] && (this.queryParams['type'] as string).split(',')?.includes('personal')); 
    let businessChecked : boolean = !this.queryParams['type'] || (this.queryParams['type'] && (this.queryParams['type'] as string).split(',')?.some(value=>this.hubService.business_types.includes(value))); 
    let queryString = (this.queryParams['query'] && this.queryParams['query'].length) ? this.queryParams['query'].length <= this. maxQueryStringLength ? this.queryParams['query'] : null : null;
    this.searchForm = new FormGroup(
      {
        query: new FormControl(queryString, [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(this.maxQueryStringLength),
        ]),
        individual: new FormControl(individualChecked),
        business: new FormControl(businessChecked),
      },
    );
    this.searchForm.controls.individual.valueChanges.subscribe((value) => {
      if(value){
        if(this.queryParams['type'] && this.queryParams['type'].length){
          let types = (this.queryParams['type'] as string).split(',');
          if(!types.includes('personal')){
            this.queryParams['type']+=',personal';
          }
        } else {
          this.queryParams['type']='personal';
        }
      } else {
        if(this.queryParams['type'] && this.queryParams['type'].length){
          let types = (this.queryParams['type'] as string).split(',');
          if(types.includes('personal')){
            this.queryParams['type']= types.filter(t=>t!=='personal').join(',');
          }
        } else {
          this.queryParams['type']= this.hubService.business_types.join(',');
        }
      }
    });
    this.searchForm.controls.business.valueChanges.subscribe((value) => {
      if(value){
        if(this.queryParams['type'] && this.queryParams['type'].length){
          let types = (this.queryParams['type'] as string).split(',');
          this.queryParams['type'] = types.filter(t=>!this.hubService.business_types.includes(t)).concat(this.hubService.business_types).join(',');
        } else {
          this.queryParams['type']= this.hubService.business_types.join(',');
        }
      } else if(this.queryParams['type'] && this.queryParams['type'].length){
          let types = (this.queryParams['type'] as string).split(',');
          this.queryParams['type'] = types.filter(t=>!this.hubService.business_types.includes(t)).join(',');
      } else {
          this.queryParams['type']='personal';
      }
    });
    this.searchForm.controls.query.valueChanges.subscribe((value) => {
      this.queryParams['query'] = value ? value.trim() : value;
    });
  }
  refreshCollapse() {
    if (!window.matchMedia(this.maxWidthMediaQuery).matches) {
      this.areFiltersCollapsed = false;
    } else {
      this.areFiltersCollapsed = true;
    }
  }

  ngOnInit(): void {
    this.windowService.goToTop();
    this.refreshCollapse();
    this.queryParams = {...this.route.snapshot.queryParams}; // alternative way to get params: this.router.routerState.snapshot.root.queryParams;
    this.generateForm();
    if(this.queryParams['sort_by']){
      const sortable : SortCriterion = this.sort_criteria.find(sc=>sc.sort_by === this.queryParams['sort_by']);
      if(sortable){
        sortable.sort_order = ['asc','desc'].includes((this.queryParams['sort_order'] as string)) ? (this.queryParams['sort_order'] as string) : sortable.default_sort_order;
      }
      this.updateSortOrderParams(sortable);
    }
    this.getContent(this.queryParams,false);
    this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
    this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
      // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
      if (newActiveLanguage?.languageKey !== this.activeLanguageObject.languageKey){
        this.activeLanguageObject = newActiveLanguage;
        this.getContent(this.queryParams,true);
      }
    });
  }
  ngOnDestroy () {
    if (this.activeLanguageSubscription){
      this.activeLanguageSubscription.unsubscribe();
    }
    if (this.contentSubscription) {
      this.contentSubscription.unsubscribe();
    }
  }

}
