<a class="row mt-3" [href]="url">
  <div class="col-4 px-0 img-holder" *ngIf="media">
    <img class="img-responsive w-100"
      [src]="media.asset_url && imageTransformations ? media.base_url + imageTransformations + media.asset_url : media.file_url"
      [alt]="media.description">
  </div>
  <div [class]="media ? 'col-8 px-0 pl-2 d-flex align-items-start flex-column justify-content-between' : 'col-12 px-0'"
    *transloco="let t">
    <div>
      <p class="font-weight-bold">{{titleText}}</p>
      <p>
        {{description}}
      </p>
    </div>
    <button type="button" class="btn btn-primary align-self-end btn-sm mt-2"
      [attr.aria-label]="cta_label ? cta_label : t('call_to_action.'+cta_type)">
      {{cta_label ? cta_label : t('call_to_action.'+cta_type)}}
      <i class="fa-solid fa-arrow-right ml-1"></i>
    </button>

    <!-- This is button outline option -->
    <!-- <button type="button" class="btn btn-outline-primary align-self-end btn-sm mt-2"
    [attr.aria-label]="cta_label ? cta_label : t('call_to_action.'+cta_type)">
    {{cta_label ? cta_label : t('call_to_action.'+cta_type)}}
    <i class="fa-solid fa-arrow-right ml-1"></i>
  </button> -->
  </div>
</a>