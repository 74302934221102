<h1 *transloco="let t">
  {{ t('authentication.forgot_password') }}
</h1>
<p class="text-muted" *transloco="let t">
  {{ t('authentication.forgot_password_guide') }}
</p>
<div class="registration-form" *transloco="let t">
  <form [formGroup]="forgotPasswordForm" role="form" class="mt-4">
    <!-- E-mail* -->
    <div class="input-self">
      <label for="email" class="form-label"
        >{{ t('common.email') }}<span class="required-sign">*</span>
      </label>
      <div
        class="input-group mb-3"
        [ngClass]="{
          'ng-invalid':
            forgotPasswordForm.get('email').invalid &&
            (forgotPasswordForm.get('email').touched ||
              forgotPasswordForm.get('email').dirty),
          'ng-valid':
            forgotPasswordForm.get('email').valid &&
            forgotPasswordForm.get('email').touched &&
            forgotPasswordForm.get('email').dirty
        }"
      >
        <span class="input-group-text text-primary-light">
          <i class="fa fa-envelope" aria-hidden="true"></i>
        </span>
        <input
          class="form-control"
          type="email"
          formControlName="email"
          id="email"
          placeholder="example@email.com"
        />
        <div
          *ngIf="
            forgotPasswordForm.get('email').errors?.email &&
            forgotPasswordForm.get('email').touched
          "
          class="invalid-feedback"
        >
          {{ t('authentication.invalid_address') }}
        </div>
        <div
          *ngIf="
            forgotPasswordForm.get('email').errors?.required &&
            forgotPasswordForm.get('email').dirty
          "
          class="invalid-feedback"
        >
          {{ t('common.required') }}
        </div>
      </div>
    </div>
    <div class="input-self">
      <multisite-loader-globe-animation-detail
        *ngIf="loading"
        [width]="100"
        [height]="100"
      >
      </multisite-loader-globe-animation-detail>

      <div class="input-group my-3">
        <button
          type="submit"
          class="btn btn-lg btn-primary w-100"
          *ngIf="!loading && !showSuccessAlert"
          [disabled]="forgotPasswordForm.status === 'INVALID'"
          (click)="sendForgotPasswordRequest()"
        >
          {{ t('common.request_verb') }}
        </button>
      </div>
    </div>
  </form>
  <div class="reset-info">
    <!-- Success Message -->
    <div *ngIf="showSuccessAlert">
      <alert [type]="'success'">
          <strong class="mr-1">
            {{ t('common.success') }}
          </strong>
         {{ t('authentication.recover_password_sent')}}
         <p>{{ t('authentication.check_email_inbox')}}</p>
      </alert>
      <button
        class="btn btn-primary w-100"
        routerLink="/"
        *ngIf="showSuccessAlert"
      >
        {{ t('common.go_to_home') }}
      </button>
    </div>
    <alert
      *ngIf="showWarningAlert && errorMessage"
      [type]="'warning'"
      [dismissible]="true"
      class="mt-3 w-100"
      role="alert"
    >
        <!-- This div gives space for the close button -->
        <strong class="mr-1">
          {{ t('common.problem') }}
        </strong>
        {{ t(errorMessage) }}
    </alert>

    <!-- Link to login -->
    <p class="text-center mt-3" *ngIf="showWarningAlert">
      {{ t('authentication.not_registered_question') }}
      <a routerLink="/register" routerLinkActive="active" *transloco="let t" class="text-decoration-underline">{{
        t('common.register')
      }}</a>
    </p>
  </div>
</div>
