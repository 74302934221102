<main>
  <div class="container p-5" *transloco="let t">

    <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
    </multisite-loader-globe-animation-detail>


    <div *ngIf="programme">
      <h2>About this page</h2>
      <p>On this page we will</p>

      <ul>
        <li>Edit the programme's basic details</li>
        <li>Add interventions (activities)</li>
        <li>Make a schedule</li>
        <li>Manage participants and trainers</li>
        <li>Lots more</li>
      </ul>

    <h2>Data available so far:</h2>
    </div>
    <p>{{programme | json}}</p>

    <p *ngIf="!loading && !programme">{{t('common.not_found')}}</p>


  </div>
</main>