import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyInstructionsComponent } from './survey-instructions.component';
import { TranslocoModule } from '@jsverse/transloco';
import { SurveyContextModule } from '../survey-context';
import { LoadersModule } from '../../loaders';
import { SurveyQuestionsModule } from '../survey-questions';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LinksModule } from '@frontend/shared';


@NgModule({
  declarations: [
    SurveyInstructionsComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    SurveyContextModule,
    LoadersModule,
    SurveyQuestionsModule,
    ModalModule,
    TranslocoModule,
    LinksModule,
  ],
  exports: [
    SurveyInstructionsComponent
  ]
})
export class SurveyInstructionsModule { }
