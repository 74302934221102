import { Culture } from "../../cultures";
import { Language } from "../../language";

export class UserExtraLite {
    constructor(
        public id: number,
        public fname: string,
        public lname: string,
        public pre_honorific : string,
        public post_honorific : string,
        public name_full_honorific : string,
        public picture: string,
        public slug: string,
        public job_title?: string,
        public summary?: string,
        public bio?: string,
        public languages?: Language[],
        public cultures?: Culture[], // Not in use yet. Remove this comment when starting to use this property
        public advancedLanguages?: Language[],
    ) {}
  }