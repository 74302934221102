<div class="comment">
  <div class="img-holder">
      <img    [src]="comment.commenter.picture ? comment.commenter.picture : 'https://res.cloudinary.com/cebt/image/upload/v1616972586/users/avatars/default-avatar.gif'"
      [alt]="comment.commenter.fname+' '+comment.commenter.lname"
  >
  </div>
  <div class="content-holder">
      <div class="d-flex flex-direction-row justify-content-between">
          <p class="name">{{comment.commenter.name_full_honorific}}</p>
          <a *ngIf="allowEditing" href="#"><i class="fa-solid fa-pen"></i> Edit</a>
      </div>
      <p class="text">{{comment.comment}}</p>
      <div class="actions">
          <div class="left">
              <p class="time">
                {{comment.created_at | date : 'longDate'}}
              </p>
          </div>
          <div class="right" *ngIf="allowReplies">
              <button type="button" class="w-100 toggle-replies" (click)="toggleReplyFormVisibility()"
                  [attr.aria-expanded]="!hideReplyForm" aria-controls="collapseReplies">(<span
                      class="value">2</span>) <span *transloco="let t">{{hideReplyForm ? t('social.show_replies'): t('social.hide_replies')}}</span><i class="ml-2 fa-solid fa-angle-up"></i>
              </button>
          </div>
      </div>
      <hr>
  </div>
</div>
