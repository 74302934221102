 <!-- Content holder is card with flexible styling it can be reusable at the same parent -->
 <div class="content-holder" *transloco="let t">
  <div class="header align-items-center">
    <p class="title">
      <span *ngIf="page == 'raters'">{{t('common.raters')}}</span>
      <!-- <span *ngIf="page == 'somethingelse'">{{t('common.something_else')}}</span> -->
    </p>
    <div class="subtitle" *ngIf="page == 'raters'">{{t('connections.raters_help_my')}}</div>
    <!-- <div class="subtitle" *ngIf="page == 'somethingelse'">{{t('common.something_else')}}</div> -->
  </div>
  <!-- 
      Use bootstrap grid system for content placment inside of body element
     -->
  <div class="row body">
    <!-- Place your contet here -->


    <div *ngIf="page == 'raters'" class="mt-4"> 
      <multisite-role-holder-editor
      [error]="error"
      [loading]="loading"
      [locked]="ratersLocked"
      [roleHolders]=""
      [roleHolderTitleSingularTranslationKey]="''"
      [roleHolderTitlePluralTranslationKey]="''"
      [roleHolderSubtitleTranslationKey]="'survey.add_raters_you'"
      [allowRoleHolderRemovalMode]="true"
    ></multisite-role-holder-editor>

      <div class="my-2" *ngIf="!loading">
        <alert [type]="'warning'" [dismissible]="true" *ngIf="error">
          <span [innerHTML]="t(error)"></span>
        </alert>

        
      </div>
    </div>

    <multisite-loader-globe-animation-detail *ngIf="loading && page !== 'raters'" width="80" height="80" class="my-3"></multisite-loader-globe-animation-detail>



  </div>
  <!-- 
      Footer: justify-content-start, justify-content-center or justify-content-end
     -->
  <!-- <div class="footer justify-content-end">
    <button class="btn-sm btn-primary primary-dark" *ngIf="detailsForm == 'password'">Save changes</button>
  </div> -->
</div>