import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SurveySearchComponent } from './survey-search.component';
import { RouterModule } from '@angular/router';
import { ContentListFormatsModule } from '../../content/lists/content/content-list-formats';
import { LoadersModule } from '../../loaders';


@NgModule({
  declarations: [
    SurveySearchComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ContentListFormatsModule,
    LoadersModule,
  ]
})
export class SurveySearchModule { }
