import { Component, input } from '@angular/core';
import { CloudinaryMediaAsset, MediaService, MetaText } from '@frontend/common';

@Component({
  selector: 'multisite-image-text-list',
  templateUrl: './image-text-list.component.html',
  styleUrl: './image-text-list.component.scss'
})
export class ImageTextListComponent {
  media = input<CloudinaryMediaAsset | undefined >(undefined);
  imageTransformations = input<string >('w_700,c_fill,ar_16:9/');
  fallbackIcon = input<string>('fa-solid fa-check');
  list = input<MetaText[]>([]);
  titleText = input<string | undefined>();
  titleTranslationKey = input<string | undefined>();
  titleClasses = input<string>('h4 font-weight-bold');
  cardClasses = input<string>('card-gray-200');
  cloudinary_base_url : string; // 'https://res.cloudinary.com/cebt/image/upload/'
  
  constructor(private mediaService: MediaService) {

    this.cloudinary_base_url = this.mediaService.cloudinary_base_url;

  }


}
