import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject, of, Subject, Observable } from 'rxjs';

import { User } from './user/user.model';
import { Guest } from './user/guest.model';
import { UserProfile } from './user/user-profile.model';
import { UserRole } from './user/user-role.model';
import { ApplicationService, UserExtraLite, UserExtraLiteAdmin } from '@frontend/common';
import { PlaceholderService } from '../utilities/placeholder.service';

export interface AuthResponseData {
  id: number;
  name: string;
  fname: string;
  lname: string;
  pre_honorific: string;
  post_honorific: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  password_set: boolean;

}

interface RegistrationResponseData {

  created_at: string; // 2022-12-03 21:04:32;
  deleted_at: string; //: null;
  email: string; // richard@richardfarkas.net;
  email_pending: boolean; // null;
  email_verified_at: string; // null;
  fname: string; // Richard;
  id: number; // 7;
  lname: string; // Farkas;
  name: string; // null;
  picture: string; // null;
  post_honorific: string; // null;
  pre_honorific: string; // null;
  slug: string; // richard-farkas;
  team_id: number; // null;
  updated_at: string; // 2022-12-03 21:04:32;

}

interface UserResponseData {
  data: {
    created_at: string; // "2021-03-13 21:27:51"
    email: string; // "tester104@poweroflearning.net"
    email_verified_at: string; // "2021-03-13 21:31:59"
    fname: string; // "Tester104"
    pre_honorific: string; // Dr
    post_honorific: string; // PhD
    name_full_honorific: string; // Dr Tester104 Registering PhD
    id: number; // 50
    lname: string; // "Registering"
    picture: string; // url
    profile: UserProfile;
    roles: UserRole[];
    updated_at: string; // "2021-03-13 21:31:59",
    password_set: boolean;
  };
}

interface TokenResponse {
  token: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = new BehaviorSubject<User | null | undefined>(undefined);
  guest = new BehaviorSubject<Guest>(null);
  private _promptAuthentication = new Subject<number>();
  public promptAuthentication : Observable<number>;
  _token: string = null;
  verificationSuccess = null;
  routeForRedirectAfterAuth: any[] = null; // ['route',params]
  apiUrl : string = 'https://cebt.fi'; // temporary solution until we can resolve the circular dependency error between AuthService and environment variables in the Core library

  constructor(
    private http: HttpClient,
    private router: Router,
    private zone: NgZone,
    private applicationService : ApplicationService,
    private placeholderService : PlaceholderService,
    // @Inject('apiUrl') private apiUrl : string, // 'https://cebt.fi'; // does cebt-test.fi work on Staging? // TODO - this causes a circular dependency error. Move Environment variables into their own library; don't use Core library.
  ) {
    window['handleLinkedInCallback'] = (code, state) => {
      this.zone.run(() => {
        this.completeSocialOauth(code, state).subscribe(
          () => {
            // this.loading = false;
          },
          (errorMessage) => {
            // TODO - add error handling
            /* this.error = errorMessage;
                this.loading = false; */
          }
        );
      });
    };
    this.getSavedGuest();
    this._promptAuthentication = new Subject<number>();
    this.promptAuthentication = this._promptAuthentication.asObservable();
  }

  getSavedGuest(){
    if(localStorage.getItem('guest')){
      this.guest.next(JSON.parse(localStorage.getItem('guest')));
    }
  }

  getFrontendUrl (){
    // let subdomain = this.applicationService.getSubdomain();
    const subdomainAndDomain = window.location.hostname; // companybrand.culturettt.com
    // const domain = window.location.hostname;
    // const topLevelDomain = domain.split('.').slice(-2).join('.');; // culturettt.com
    // let result = subdomain ? 'https://' + subdomain + '.' + topLevelDomain : 'https://' + topLevelDomain; // TODO - get the domain from environment variables instead, when implemented
    return 'https://' + subdomainAndDomain;
  }

  register(email: string, password: string, fname: string, lname: string) {
    return this.http
      .post<RegistrationResponseData>('api/v1/register', {
        email: email,
        password: password,
        fname: fname,
        lname: lname,
        frontend_url: this.getFrontendUrl(),
      })
      .pipe(
        tap((responseData) => {
          // TODO - some better user experience than nothing?
        }),
        catchError((error)=>this.handleError(error)),
      );
  }

  get token() {
    return this._token || localStorage.getItem('token');
  }

  saveToken(tokenObject: TokenResponse) {
    this._token = tokenObject.token;
    localStorage.setItem('token', this._token);
  }

  login(email: string, password: string) {
    return this.http.get("sanctum/csrf-cookie", {responseType: 'text'})
      .pipe(
        switchMap(() => {
          return this.http.post<TokenResponse>('api/v1/frontend/login', {
            email: email,
            password: password,
          })
        }),
        map((responseData) => {
          // switchMap, mergeMap
          this.saveToken(responseData);
          this.getUser(null).subscribe();
        }),
        catchError((error)=>this.handleError(error)),
      );
  }
  changeEmail(newEmail: string) {
    return this.http
      .post<any>('api/v1/user/change-email', {
        frontend_url: this.getFrontendUrl(),
        email: newEmail,
      })
      .pipe(
        map((responseData) => {
          let userProfile;
          if (responseData.data.profile) {
            userProfile = this.transformProfileResponse(
              responseData.data.profile
            );
          }
          let user : User = this.transformUserResponse(responseData.data, userProfile);
          this.user.next(user);
          localStorage.setItem('user', JSON.stringify(user));
          return true;
        }),
        catchError((error)=>this.handleError(error)),
      );
  }
  changePassword(oldPassword: string, newPassword: string) {
    return this.http
      .post<any>('api/v1/change-password', {
        old_password: oldPassword,
        password: newPassword,
      })
      .pipe(
        map((responseData) => {
          let user = this.user.getValue();
          if (!user?.password_set) {
            user.password_set = true;
            this.user.next(user);
          }
        }),
        catchError((error)=>this.handleError(error)),
      );
  }
  forgotPassword(email: string) {
    let testing = this.getFrontendUrl();
    console.log('testing '+ testing);
    return this.http
      .post<any>('api/v1/forgot-password', {
        email: email,
        frontend_url: this.getFrontendUrl(),
      })
      .pipe(
        map((responseData) => {
          // TODO - review and remove?
        }),
        catchError((error)=>this.handleError(error)),

      );
  }
  resetPassword(
    email: string,
    password: string,
    password_confirmation: string,
    token: string
  ) {
    return this.http
      .post<any>('api/v1/reset-password', {
        password: password,
        password_confirmation: password_confirmation,
        email: email,
        token: token,
      })
      .pipe(
        map((responseData) => {
          // TODO - review and remove?
        }),
        catchError(error=>this.handleError(error))
      );
  }
  loginWithLinkedin() {
    window.open(
      this.apiUrl + '/auth/linkedin/redirect?site=' + this.getFrontendUrl(), // TODO - test on Staging and local. Is cebt.fi specifically required as this.apiUrl or does cebt-test.fi and localhost:800x also work?
      'cebtlinkedin',
      'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=600, height=600, left=100 top=100'
    );
  }

  completeSocialOauth(code: string, state: string) {
    let provider = 'linkedin';
    return this.http
      .get<TokenResponse>(
        'api/v1/signin-step-two-callback-url-' +
          provider +
          '?code=' +
          code +
          '&state=' +
          state
      )
      .pipe(
        map((responseData) => {
          this.saveToken(responseData);
          this.routeForRedirectAfterAuth = this.routeForRedirectAfterAuth
            ? this.routeForRedirectAfterAuth
            : ['./home'];
          this.getUser(false).subscribe();
        }),
        catchError(error=>this.handleError(error)),
      );
  }

  removeCachedUser() {
    this._token = null;
    this.user.next(undefined);
  }

  verify(url) {
    return this.http.get(url).pipe(catchError( error => this.handleError(error)));
  }
  mergeUserProfileIntoUserExtraLite(userLiteResponse): UserExtraLite {
    return new UserExtraLite(
      userLiteResponse.id,
      userLiteResponse.fname,
      userLiteResponse.lname,
      userLiteResponse.pre_honorific,
      userLiteResponse.post_honorific,
      userLiteResponse.name_full_honorific,
      userLiteResponse.picture,
      userLiteResponse.slug,
      userLiteResponse.profile.job_title,
      userLiteResponse.profile.summary,
      userLiteResponse.profile.bio
    );
  }
  mergeUserProfileIntoUserExtraLiteAdmin(userLiteResponse): UserExtraLiteAdmin {
    return new UserExtraLiteAdmin(
      userLiteResponse.id,
      userLiteResponse.email,
      userLiteResponse.fname,
      userLiteResponse.lname,
      userLiteResponse.pre_honorific,
      userLiteResponse.post_honorific,
      userLiteResponse.name_full_honorific,
      userLiteResponse.picture,
      userLiteResponse.slug,
      userLiteResponse.profile.job_title,
      userLiteResponse.profile.summary,
      userLiteResponse.profile.bio
    );
  }
  transformProfileResponse(profileResponse): UserProfile {
    return new UserProfile(
      profileResponse.id,
      profileResponse.job_title,
      profileResponse.summary,
      profileResponse.bio,
      new Date(profileResponse.created_at),
      new Date(profileResponse.updated_at),
      profileResponse.user_id
    );
  }
  transformUserResponse(userResponse, transformedProfile: UserProfile): User {
    return new User(
      userResponse.id,
      userResponse.email || this.getUserProperty('email'),
      userResponse.fname,
      userResponse.lname,
      userResponse.pre_honorific,
      userResponse.post_honorific,
      userResponse.name_full_honorific,
      userResponse.picture,
      transformedProfile,
      userResponse.roles,
      userResponse.created_at
        ? new Date(userResponse.created_at)
        : this.getUserProperty('created_at'),
      userResponse.password_set,
      userResponse.email_pending,
    );
  }

  getUser(fullEditable: boolean) {
    let url = 'api/v1/get-user';
    if (fullEditable) {
      url += '?mode=edit';
    }
    return this.http.get(url).pipe(
      tap((responseData: UserResponseData) => {
        if (!responseData || !responseData.data) {
          throw 'not the expected response';
        }
        let userProfile;
        if (responseData.data.profile) {
          userProfile = this.transformProfileResponse(
            responseData.data.profile
          );
        }
        let user : User = this.transformUserResponse(responseData.data, userProfile);
        this.user.next(user);
        localStorage.setItem('user', JSON.stringify(user));
        if (this.getRouteForRedirectAfterAuth()) {
          let navigateArray = [...this.getRouteForRedirectAfterAuth()];
          this.setRouteForRedirectAfterAuth(null);
          this.router.navigate(navigateArray);
        }
      }),
      catchError(error=>{
        this.user.next(null);
        return this.handleError(error)
      }),
    );
  }

  updateProfileField(fieldKey: string, fieldValue: string) {
    fieldValue = fieldValue ? fieldValue : null; // the back end wants 'null' not empty strings

    let params = { [fieldKey]: fieldValue };

    return this.http
      .post<{ message: string }>(
        'api/v1/user-profiles/my-account/update-field',
        params
      )
      .pipe(
        map((response) => {
          if (response && response.message == 'success') {
            let user = this.user.getValue();

            if (user) {
              user[fieldKey] = fieldValue;
              this.user.next(user);
            }
          }
        })
      );
  }
  public getMatchingUserRoles (roles : UserRole[],arrayOfRoleTitles : string[]) : UserRole[] {
      let filteredRoles = [];
      if(roles?.length && arrayOfRoleTitles?.length){
        arrayOfRoleTitles.forEach(t => {
          let role = roles.find(r=>r.title === t);
          if (role){
            filteredRoles.push(role);
          }
        });
      }
      return filteredRoles;
  }

  setVerificationSuccess(bool) {
    this.verificationSuccess = bool;
  }
  getVerificationSuccess() {
    return this.verificationSuccess;
  }
  setRouteForRedirectAfterAuth(navigateArray) {
    this.routeForRedirectAfterAuth = navigateArray;
    if (!navigateArray) {
      localStorage.removeItem('routeForRedirectAfterAuth');
    } else {
      localStorage.setItem(
        'routeForRedirectAfterAuth',
        JSON.stringify(navigateArray)
      );
    }
  }
  openLoginModal(routeForRedirectAfterAuth : string[] = null){
    if(routeForRedirectAfterAuth){
      this.setRouteForRedirectAfterAuth(routeForRedirectAfterAuth);
    }
    let value = Math.random()*100000;
    this._promptAuthentication.next(value);
  }
  getRouteForRedirectAfterAuth() {
    return localStorage.getItem('routeForRedirectAfterAuth')
      ? JSON.parse(localStorage.getItem('routeForRedirectAfterAuth'))
      : this.routeForRedirectAfterAuth;
  }
  checkRole(whichRole) {
    if (!this.user.getValue() || !this.user.getValue().roles) {
      return false;
    }
    let roles = this.user.getValue().roles;
    return Boolean(roles.find((r) => r.title === whichRole));
  }
  getUserProperty(propertyKey: string) {
    if (!this.user.getValue() || !this.user.getValue()[propertyKey]) {
      return null;
    }
    return this.user.getValue()[propertyKey];
  }
  makeGuest(guest_uuid: string,event_uuid: string,cohort_uuid: string){
    let subdomain = this.applicationService.getSubdomain();
    let url = 'api/v1/make-guest?subdomain='+subdomain+'&';
    if (event_uuid) {
      url += 'event='+event_uuid+'&';
    };
    if (cohort_uuid) {
      url += 'event='+cohort_uuid+'&';
    };
    return this.http.get<{data:Guest}>(url).pipe(
      catchError(error=>this.handleError(error)),
      map((responseData) => {
        let guest : Guest = responseData.data;
        localStorage.setItem('guest', JSON.stringify(guest));
        this.guest.next(guest);
      })
    );

  }
  importGuest(guest_uuid: string){
    if(!guest_uuid){this.handleError(new HttpErrorResponse({statusText:'not done'}))};
    return this.http.get<{data:any}>('api/v1/import-guest/'+guest_uuid).pipe(
      catchError(error=>{
        if(error?.status === 404 && error.error){
          return of({data: {success : true}});
        }
        return this.handleError(error);
      }),
      map((responseData) => {
        let response = responseData.data;
        this.removeGuestLocally();
        return response;
      })
    );
  }
  removeGuest(guest_uuid: string){
    if(!guest_uuid){this.handleError(new HttpErrorResponse({statusText:'not done'}))};
    return this.http.get<{data:any}>('api/v1/remove-guest/'+guest_uuid).pipe(
      catchError(error=>{
        if(error?.status === 404 && error.error){
          return of({data: {success : true}});
        }
        return this.handleError(error);
      }),
      map((responseData) => {
        let response = responseData.data;
        this.removeGuestLocally();
        return response;
      })
    );
  }
  removeGuestLocally(){
    localStorage.removeItem('guest');
    this.guest.next(null);
  }
  getDummyUsersExtraLite(count : number = 1) : UserExtraLite[]{
    let randomNames = ['Robin','Jo','Jay','Leslie',];
    let job_titles = ['Engineer','Teacher','Consultant','Care worker','Administrator',];
    let text = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, dolor';
    let users = [];
    Array.from({ length: count }, (_, index) => index).forEach(element => {
      let fname = randomNames[Math.ceil(Math.random()*randomNames.length-1)];
      let lname = randomNames[Math.ceil(Math.random()*randomNames.length-1)];
      let job_title = job_titles[Math.ceil(Math.random()*job_titles.length-1)];
      users.push(new UserExtraLite(null,fname,lname,null,null,fname+' '+lname,this.placeholderService.makeAvatarFile(),null,job_title,text,text+'. '+text))
    });
    return users;
  }
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    if (
      errorResponse.error.errors &&
      errorResponse.error.errors.email &&
      errorResponse.error.errors.email[0]
    ) {
      errorResponse.error.errors.email[0] = errorResponse.error.errors.email[0].replace(
        "'",
        ''
      ); // remove the hyphens ... We cant find a user with that e-mail address.
      switch (errorResponse.error.errors.email[0]) {
        case 'The provided credentials are incorrect.':
          errorMessage = 'authentication.login_credentials_wrong';
          break;
        case 'The email has already been taken.':
          errorMessage = 'authentication.already_registered_log_in';
          break;
        case 'This password reset token is invalid.':
          errorMessage = 'authentication.password_reset_invalid_link';
          break;
        case 'We cant find a user with that e-mail address.':
          errorMessage = 'authentication.forgot_password_not_found';
          break;
      }
    } else if (
      errorResponse.error.errors &&
      errorResponse.error.errors.password
    ) {
      if (
        errorResponse.error.errors.password[0].indexOf(
          'The password must be at least'
        ) > -1
      ) {
        errorMessage = 'authentication.password_length';
      } else if (
        errorResponse.error.errors.password[0] ==
        'The password confirmation does not match.'
      ) {
        errorMessage = 'authentication.password_confirmation_error';
      }
    } else if (errorResponse.error.message == 'password_incorrect') {
      errorMessage = 'authentication.password_wrong';
    } else if (errorResponse.error.message == 'Too many guests.'){
      errorMessage = 'authentication.guest_quota_full';
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }
}
