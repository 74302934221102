<div class="banner">
  <div class="row align-items-center">
    <div class="col-lg-3">
      <div class="img-cross-format">
        <img [src]="imageWithTransformations(media?.[0]?.file_url ?? fallbackMedia[0],imageTransformations)" [alt]="media?.[0]?.description">
        <img [src]="imageWithTransformations(media?.[1]?.file_url ?? fallbackMedia[1],imageTransformations)" [alt]="media?.[1]?.description">
      </div>
    </div>
    <div class="col-lg-9">
      <div class="row flex-lg-row-reverse align-items-center">
        <div class="col-lg-4">
          <div class="img-cross-format right">
            <img [src]="imageWithTransformations(media?.[2]?.file_url ?? fallbackMedia[2],imageTransformations)" [alt]="media?.[2]?.description">
            <img [src]="imageWithTransformations(media?.[3]?.file_url ?? fallbackMedia[3],imageTransformations)" [alt]="media?.[3]?.description">
          </div>
        </div>
        <div class="col-lg-8 mt-3 mt-lg-0 d-flex flex-wrap justify-content-center align-items-center text-center" *transloco="let t">
          <h4 *ngIf="titleText || titleTranslationKey" class="title">{{titleText ?? t(titleTranslationKey)}}</h4>
          <p *ngIf="descriptionText || descriptionTranslationKey">{{descriptionText ?? t(descriptionTranslationKey)}}</p>
          <button *ngIf="buttonLabelText || buttonLabelTranslationKey" class="btn btn-primary mt-2" (click)="doLinkClick()">{{buttonLabelText ?? t(buttonLabelTranslationKey)}}</button>
        </div>
      </div>
    </div>
  </div>
</div>