import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TipComponent } from './tip/tip.component';
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { AvatarsModule, RatingModule, UserScoreDisplayModule, VideoPlayersModule } from '@frontend/shared';
import { LoadersModule } from '../../loaders/loaders.module';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CommentsModule } from '@frontend/shared';
import { SearchModule } from '../../search';
import { LinksModule } from '@frontend/shared';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    TipComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CollapseModule,
    VideoPlayersModule,
    LoadersModule,
    AlertModule,
    CommentsModule,
    LinksModule,
    RatingModule,
    SearchModule,
    UserScoreDisplayModule,
    TranslocoModule,
    AvatarsModule,
  ],
  exports: [
    TipComponent,
  ],
})
export class TipsModule { }
