import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { StatisticNumerical } from '@frontend/core';
import { ContentItem } from '../../content-item.model';

@Component({
  selector: 'card-banner-list-item-promo',
  templateUrl: './card-banner-list-item-promo.component.html',
  styleUrls: ['./card-banner-list-item-promo.component.scss']
})
export class CardBannerListItemPromoComponent implements OnInit, OnChanges {

  @Input() content : ContentItem;
  @Input() imageTransformations : string; // 'w_700,c_fill,ar_16:9/'
  @Input() static : boolean; // removes the hover effect and simplifies the content
  randomAvatars : string[];
  primaryColourRgb : string;

  constructor() { }

  makeAvatars (){
    let avatars = [];
    for (let index = 0; index < 3; index++) {
      // example: https://randomuser.me/api/portraits/women/98.jpg
      avatars.push('https://randomuser.me/api/portraits/'+(Math.random() > 0.5 ? 'wo':'' )+'men/'+Math.ceil(Math.random()*95)+'.jpg')
    }
    return avatars;
  }

  ngOnChanges(changeObject): void {
    if (changeObject.content && changeObject.content.currentValue !== null && changeObject.content.currentValue != undefined){
    // this.primaryColourRgb = this.content?.meta?.primary_color;
    this.primaryColourRgb = this.content?.meta?.primary_colour_rgb;
    // debugger;
    }
  };
  ngOnInit(): void {
    this.randomAvatars = this.makeAvatars();
  };

}
