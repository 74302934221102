<nav aria-label="Page navigation">
  <ul class="pagination {{classes}}" *transloco="let t">
    <li *ngIf="!simplified" class="page-item" [ngClass]="{'disabled':meta.current_page == 1 || loading}">
      <a class="page-link" (click)="clickPage(1)">
        <span><i class="fa-solid fa-backward"></i></span>
      </a>
    </li>
    <li class="page-item" [ngClass]="{'disabled':meta.current_page == 1 || loading}">
      <a (click)="clickPage(meta.current_page-1)" class="page-link" aria-label="Previous">
        <span aria-hidden="true"><i class="fa-solid fa-arrow-left"></i>
          <span *ngIf="!simplified" class="pagination-link-text"> {{t('common.previous')}}</span>
        </span>
        <span class="sr-only">
          <span >{{ t('common.previous') }}</span>
        </span>
      </a>
    </li>
    <li *ngFor="let p of pageList" class="page-item" [ngClass]="{'active':meta.current_page==p, 'd-none':simplified}">
      <a (click)="clickPage(p)" class="page-link">{{p}}</a>
    </li>
    <li *ngIf="simplified">
      <div class="d-flex flex-wrap align-items-center justify-content-center mx-2 gap-1">
        <div class="d-inline-block">{{meta.from}}-{{meta.to}}</div>
        <div class="d-inline-block">{{t('navigation.page_x_of_y')}} {{meta.total}}</div>
    </div>
    <li class="page-item" [ngClass]="{'disabled':meta.current_page == lastPage || loading}">
      <a (click)="clickPage(meta.current_page+1)" class="page-link" aria-label="Next">
        <span aria-hidden="true">
          <span *ngIf="!simplified" class="pagination-link-text">{{t('common.next')}} </span>
          <i class="fa-solid fa-arrow-right"></i></span>
        <span class="sr-only">
          <span>{{ t('common.next') }}</span>
        </span>
      </a>
    </li>
    <li *ngIf="!simplified" class="page-item" [ngClass]="{'disabled':meta.current_page == lastPage || loading }">
      <a class="page-link" (click)="clickPage(lastPage)">
        <span><i class="fa-solid fa-forward"></i></span>
      </a>
    </li>
  </ul>
</nav>