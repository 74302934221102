import { Injectable } from '@angular/core';
import { CloudinaryMediaAsset } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  _cloudinary_base_url = "https://res.cloudinary.com/cebt/image/upload/";
  _vimeo_thumbnail_base_url = "https://vumnail.com/";
  custom_urls = {
    'base_url' : null, // https://res.cloudinary.com/cebt/image/upload/
    'transformations_url' : null, // c_thumb,w_200,g_face/
    'asset_url' : null // // v1611931415/Culture/Tools/red-shoes.jpg
  };
  _fallback_avatar_url = 'https://res.cloudinary.com/cebt/image/upload/v1616972586/users/avatars/default-avatar.gif';
  _fallback_flag_round_url = 'https://res.cloudinary.com/cebt/image/upload/v1695749181/flags/round/missing.png';
  _fallback_scene_file_name = 'scenes/banners/rikuwolf_photo-realistic_colourful_crowd_of_diverse_people_happ_394e86a5-012f-4483-b532-344ab3caeb30_ozyvsl';
  cloudinary_flags_folder_path = "flags/";
  cloudinary_icons_folder_path = "icons/";

  constructor() { }

  getPlaceholder (type: string = 'CloudinaryMediaItem'){
    if (type === 'CloudinaryMediaItem') {
      return {
        id: 145,
        medially_type: "App\\Models\\Tool",
        medially_id: 23,
        file_url: "https://res.cloudinary.com/cebt/image/upload/v1667242551/concepts/coaching-circles-podium.jpg",
        file_name: "concepts/coaching-circles-podium.jpg",
        file_type: "image",
        size: 734560,
        uploader_id: 1,
        description: "Businessperson stands by podium in front of abstract circles",
        credits: "Richard Farkas",
        permission: null,
        category: "intercultural",
        type: "concepts",
        created_at: "2022-10-31T13:00:03.000000Z",
        updated_at: "2022-10-31T13:00:03.000000Z",
        // we add the following here in the frontend:
        base_url: "https://res.cloudinary.com/cebt/image/upload/",
        transformations_url: "c_thumb,w_200,g_face/",
        asset_url: "v1611931415/Culture/Tools/red-shoes.jpg",
      }
    } else if (type === 'CloudinaryMediaItemBanner') {
      return {
        id: null,
        medially_type: null, //"App\\Models\\Tool",
        medially_id: null,
        file_url: "https://res.cloudinary.com/cebt/image/upload/v1713440007/maps/world_map-diverse_people-midjourney-fc108c43-2934-440a-af74-956514344ef4.png",
        file_name: "maps/world_map-diverse_people-midjourney-fc108c43-2934-440a-af74-956514344ef4",
        file_type: "image",
        size: 1760000,
        uploader_id: 1,
        description: "World map with people",
        credits: "Midjourney",
        permission: 'Attribution-NonCommercial 4.0 International',
        category: "gallery",
        type: "banners",
        created_at: "2024-04-18 13:00:03",
        updated_at: "2024-04-18 13:00:03",
        // we add the following here in the frontend:
        base_url: "https://res.cloudinary.com/cebt/image/upload/",
        transformations_url: "c_thumb,w_700/",
        asset_url: "maps/world_map-diverse_people-midjourney-fc108c43-2934-440a-af74-956514344ef4.png",
      }
    }
  }

  get fallback_avatar_url (){
    return this._fallback_avatar_url;
  }
  get fallback_flag_round_url (){
    return this._fallback_flag_round_url;
  }
  get fallback_scene_file_name (){
    return this._fallback_scene_file_name;
  }
  get fallback_scene_url (){
    return this._cloudinary_base_url + this._fallback_scene_file_name + '.jpg';
  }
  get cloudinary_base_url (){
    return this._cloudinary_base_url;
  }

  generateCloudinaryMediaAssetPlaceholderFromFileName (file_name, medially_id, medially_type, category:string,type:string, format :string = 'jpg'){
    const asset : CloudinaryMediaAsset = {
      id: null,
      medially_id : medially_id,
      medially_type : medially_type,
      file_name: file_name,
      file_url: this._cloudinary_base_url + file_name + '.' + format,
      base_url: this._cloudinary_base_url,
      asset_url: null, // TODO get asset_url from file_name
      description:'A placeholder',
      credits:'',
      category:category,
      type:type,
    }
    return asset;
  }

  setupCloudinaryImageMediaUrls (mediaObject) {
    let custom_urls = Object.assign({}, this.custom_urls);
    if (mediaObject && mediaObject.file_type == "image" && mediaObject.file_url && mediaObject.file_url.indexOf(this._cloudinary_base_url) > -1){
      custom_urls.base_url = this._cloudinary_base_url;
      custom_urls.asset_url = mediaObject.file_url.split(this._cloudinary_base_url)[1];
    }
    return custom_urls;
  }
  insertCloudinaryTransformationsIntoMediaUrl (url:string,transformations:string) { // transformations are like this: 'c_fill,g_face,ar_16:9/'
    if (!url || !transformations || url.indexOf(this._cloudinary_base_url)==-1){return url;};
    return this._cloudinary_base_url+transformations+url.split(this._cloudinary_base_url)[1];
  }
  getFlagUrlFromHash( hash:string, transformations:string, round:boolean, file_extension: string = null){
    
    // good transformations for flags: 'w_32,c_fill,ar_1:1,r_max,f_auto/'

    if (!hash){
      hash = 'missing';
      round = true;
    }
    
    file_extension = file_extension ?? '.png';
    
    let path = round ?  this.cloudinary_flags_folder_path + 'round/' : this.cloudinary_flags_folder_path;
    
    return this.cloudinary_base_url + transformations + path + hash + file_extension;
  }
  getIconUrlFromIconIdentifier2( identifier_2 : string = 'icons/information', transformations:string = '', file_extension: string = '.png'){
    
    // good transformations for flags: 'w_32,c_fill,ar_1:1,r_max,f_auto/'
            
    return this.cloudinary_base_url + transformations + identifier_2 + file_extension;
  }

}