<a class="explainer-banner" (click)="doLinkClick($event)" *transloco="let t"
  [attr.aria-label]="cta_label ? cta_label : t('call_to_action.'+cta_type)">
  <div class="row">
    <div class="col-lg-3">
      <div class="thumbnail">
        <button class="btn-play">
          <i class="fa-solid fa-circle-play"></i>
        </button>
        
        <img *ngIf="media"
        [src]="media.asset_url && imageTransformations ? media.base_url + imageTransformations + media.asset_url : media.file_url"
        [alt]="media?.description ? media.description : 'A background image suggesting wisdom'">
      </div>
    </div>
    <div class="content col-lg-9">
      <p class="h4 font-weight-bold">{{titleText}}</p>
      <p>{{description}}</p>
      <div class="alert alert-success mt-2" role="alert" *ngIf="alertText">
        <strong>{{alertText}}</strong>
      </div>
      <button type="button" class="btn btn-primary btn-sm"
      [attr.aria-label]="cta_label ? cta_label : t('call_to_action.'+cta_type)" *ngIf="cta_label || cta_type">
      {{cta_label ? cta_label : t('call_to_action.'+cta_label)}}
    </button>
    </div>
</div>
</a>