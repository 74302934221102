import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Step } from '../step.model';


@Component({
  selector: 'multisite-stepper-type-one',
  templateUrl: './stepper-type-one.component.html',
  styleUrls: ['./stepper-type-one.component.scss']
})
export class StepperTypeOneComponent implements OnInit {

  @Input() steps : Step[];
  @Output() selectStep = new EventEmitter<{step: Step, stepIndex : number}>;

  constructor() { }

  doSelectStep(step : Step, stepIndex : number){
    this.selectStep.emit({step:step,stepIndex:stepIndex});
  }

  ngOnInit(): void {
  }

}
