import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProgressIndicatorItem } from '../progress-indicator-item.model';


@Component({
  selector: 'progress-indicator-listing-card-doughnut',
  templateUrl: './progress-indicator-listing-card-doughnut.component.html',
  styleUrls: ['./progress-indicator-listing-card-doughnut.component.scss']
})
export class ProgressIndicatorListingCardDoughnutComponent {

  @Input() items : ProgressIndicatorItem[] = [];
  @Input() titleTranslationKey: string // 'common.hq'
  @Input() titleText: string; // 'Headquarters'
  @Input() helpTranslationKey: string // 'common.hq'
  @Input() helpText: string; // 'Headquarters'
  @Input() helpIconClasses: string; // 'fa-regular fa-info-circle'
  @Input() initialListDisplayLength: number; // 6
  @Input() clickableProperty: string; // 'slug' - for example, if item['slug'], we'll add the "clickable" class
  @Output() linkClicked = new EventEmitter<ProgressIndicatorItem>();
  showMore : boolean;

  constructor() { }

  doLinkClick (item : ProgressIndicatorItem){

    this.linkClicked.emit(item);
  }

  displayedItems (){
    return this.initialListDisplayLength && !this.showMore ? this.items.slice(0,this.initialListDisplayLength-1) : this.items;
  }


}
