import { Link, Video } from "@frontend/core";

export class Consent {
    id : number;
    term_id : number;
    term_version_id : number;
    user_id : number | null;
    target_type : string | null;
    target_id : number | null;
    locale : string; // 'en'. Only records the local for the latest action (given, declined, withdrawn)
    given_at : string;
    declined_at : string;
    withdrawn_at : string;
    created_at : string;
    slug : string;
    category : string;
    type : string;
    title : string;
    description : string;
    question : string;
    decline_info : string;
    verb_accept : string;
    verb_reject : string;
    withdrawal_allowed : boolean;
    principal : any; // Organisation | Cohort;
    links: Link[];
    videos: Video[];
}