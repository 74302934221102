import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService, CiResultService, Guest, SnapshotService, SurveysService, TrackingService, User } from '@frontend/common';
import { combineLatest, map, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'multisite-guest-import',
  templateUrl: './guest-import.component.html',
  styleUrls: ['./guest-import.component.scss']
})
export class GuestImportComponent implements OnInit {

  subscriptions : {[key:string]:Subscription} = {};
  user: User;
  guest: Guest;
  guestInLocalStorage: any;
  @ViewChild('guestImportModal') guestImportModal;
  modalRef?: BsModalRef;
  loading : boolean;
  error : string; // 'error.something_went_wrong'
  timeouts: any[] = [];
  infoCollapsed : boolean = true;
  confirmingRemoval : boolean;
     
  constructor(
    private modalService: BsModalService,
    private authService : AuthService,
    private trackingService : TrackingService,
    private snapshotService : SnapshotService,
    private surveysService : SurveysService,
    private ciResultService : CiResultService,
  ) { }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.subscriptions['modal'] = this.modalRef.onHide.subscribe((reason: string | any) => {
      this.confirmingRemoval = false;
    })
  }
  closeModal(){
    if (this.modalRef){
      this.modalRef.hide();
    }
  }

  clearTimeouts(){
    this.timeouts.forEach(timeoutId => {
      clearTimeout(timeoutId);
    });
  }
  clearData(){
    this.trackingService.clearData();
    this.subscriptions['tracking'] = this.trackingService.getTotalProgressCoursesOnly().subscribe(); // TODO - we probably need to track other kinds of progress too
    this.snapshotService.clearData();
    this.subscriptions['snapshot'] = this.snapshotService.getSnapshots(true).subscribe();
    this.surveysService.clearLanguagePreferences();
    this.ciResultService.clearCiResults();
  }

  importActivity(uuid : string){
    this.loading = true;
    this.error = null;
    this.clearTimeouts();
    this.subscriptions['guest'] = this.authService.importGuest(uuid).subscribe(response => {
      this.clearData();
      this.loading = false;
      this.closeModal();
    },
    error => {
      this.error = error?.meta?.errorMessage ?? error;
      this.loading = false;
      const timeoutId = setTimeout(()=>{
        this.error = null;
      },30000);
      this.timeouts.push(timeoutId);
    }); 
  }
  confirmRemoval(){
    this.confirmingRemoval = true;
  }
  removeActivity(uuid : string){
    this.loading = true;
    this.confirmingRemoval = false;
    this.error = null;
    this.clearTimeouts();
    this.subscriptions['guest'] = this.authService.removeGuest(uuid).subscribe(response => {
      this.clearData();
      this.loading = false;
      this.closeModal();
    },
    error => {
      this.error = error?.meta?.errorMessage ?? error;
      this.loading = false;
      const timeoutId = setTimeout(()=>{
        this.error = null;
      },30000);
      this.timeouts.push(timeoutId);
    }); 
  }

  getData(){
    return combineLatest({
      user: this.authService.user,
      guest: this.authService.guest
    }).pipe(
      map((result: { user: User, guest: Guest }) => {
        this.user = result.user;
        this.guest = result.guest;
        this.guestInLocalStorage = JSON.parse(localStorage.getItem('guest'));
        if(this.user && this.guest){
          this.openModal(this.guestImportModal);
        }
        return result;
      }));
  }

  ngOnInit(): void {
    this.getData().subscribe();
  }

  ngOnDestroy(): void { // if this component is in the footer, ngOnDestroy will never run

    for (let subscription in this.subscriptions){
      this.subscriptions[subscription].unsubscribe();
    }
    this.clearTimeouts();
    this.confirmingRemoval = false;
  }

}
