import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductHeaderTypeOneComponent } from './type-one/product-header-type-one.component';
import { AvatarsModule, VideoPlayersModule } from '@frontend/shared';
import { LoadersModule } from '../../loaders';
import { ProgressIndicatorCardSimpleModule } from '../../tracking/progress/progress-indicator-card-simple/progress-indicator-card-simple.module';
import { TranslocoModule } from '@jsverse/transloco';
import { RatingModule } from 'ngx-bootstrap/rating';
import { FormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TextListsModule } from '../../content/lists/text';
import { TooltipModule } from 'ngx-bootstrap/tooltip';



@NgModule({
  declarations: [ProductHeaderTypeOneComponent],
  exports: [ProductHeaderTypeOneComponent],
  imports: [
    CommonModule,
    FormsModule,
    VideoPlayersModule,
    LoadersModule,
    AvatarsModule,
    ProgressIndicatorCardSimpleModule,
    TranslocoModule,
    RatingModule,
    AlertModule,
    TextListsModule,
    TooltipModule,
  ]
})
export class ProductHeadersModule { }
