import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

export interface EnvironmentModuleConfig {
  appDomain: string; // 'culturequestion.com',
  appKey: string, // 'culturequestion',
  appUrl: string, // 'https://culturequestion.com',
  production: boolean,
  name: string, // name of the environment, for example 'staging'

}

@NgModule({
  imports: [CommonModule],
})
export class EnvironmentModule {
  static withConfig(config: EnvironmentModuleConfig): ModuleWithProviders<EnvironmentModule> {
    return {
      ngModule: EnvironmentModule,
      providers: [
        { provide: 'appDomain', useValue: config.appDomain },
        { provide: 'appKey', useValue: config.appKey },
        { provide: 'appUrl', useValue: config.appUrl },
        { provide: 'production', useValue: config.production },
        { provide: 'name', useValue: config.name },
      ],
    };
  }
}
