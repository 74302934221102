import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { of, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DataProcessingService, LanguageService, MediaService, QueryParams } from '@frontend/common';
import { Tip } from './tip.model';
import { PaginatedTips } from './paginated-tips.model';
import { ContentItem } from '../content-item.model';
import { ContentService } from '../content.service';
import { IModelsToContentItemsConverter } from '@frontend/core';

@Injectable({
  providedIn: 'root',
})
export class TipService implements IModelsToContentItemsConverter {

  paginatedTipsByTopic : { [key:string]: Record<number, PaginatedTips> } = {};
  tagIdsByTopic : { [key:string]: number[] } = {}; // ids of all the tags on tips which also have the topic tag
  activeLanguageSubscription: Subscription;
  currentURLSearchParamsForPaginatedTips : URLSearchParams;

  constructor(
    private http: HttpClient,
    private mediaService: MediaService,
    private contentService: ContentService,
    private languageService: LanguageService,
    private dataProcessingService: DataProcessingService,
  ) {
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
  }

  clearTranslations() {
    this.paginatedTipsByTopic = {};
  }
  clearData (){
    this.clearTranslations();
    // should we also clear currentURLSearchParamsForPaginatedPublicHubs ? 
  }

  // getPlaceholder () { // generate a dummy Tip

  //   let tipBodyParagraph = '<p>Sit ut dolor pariatur irure ex. Aliquip dolore qui nulla est sunt ad cillum eiusmod duis esse magna do aute.</p>';

  //   let tip : Tip = {
  //     id: 100000001,
  //     title : 'Dolore ex consequat ipsum aute ad',
  //     description: 'Occaecat deserunt tempor magna reprehenderit. Nisi irure elit tempor ut proident. Nulla do in quis magna incididunt dolor ex ea ut dolore sint enim.',
  //     slug: JSON.stringify('this-is-the-tip-slug'+Math.random()*1000),
  //     videos: [{name:'Kathryn Libioulle-Clutz, toolbox tool: favourite shoe', id: 10000, host_identifier: '558020191', host_params: 'h=be064152b1'}], // '758075392'
  //     body: '<h3>Irure ex ad aliqua sunt labore voluptate</h3>'+' '+tipBodyParagraph+' '+tipBodyParagraph+' '+tipBodyParagraph,
  //     links: null, // get them from the links.service
  //     media : null, // get this from the media.service
  //     tags: [{
  //       id: 2038,
  //       name: "Expat support",
  //       slug: "expat-support",
  //       type: "interculturalist_fields",
  //     }], // TODO - move this placeholder to a TagService
  //     rating: null,
  //     created_at: '2023-01-07 13:00:03',
  //     updated_at: '2023-01-07 13:00:03',
  //   };
  //   return tip;
  // };
  convertTipToContentItem(tip: Tip): ContentItem {
    let fallbackImageFileName = this.mediaService.fallback_scene_file_name;
    let contentItem = new ContentItem(
      tip.id,
      tip.title,
      null,
      null,
      null,
      tip.description,
      null,
      null,
      tip.slug,
      null,
      null,
      tip.media?.length ? ( tip.media.find(m=>m.category === 'thumbnail' && m.type === 'main') ?? tip.media[0] ) : this.mediaService.generateCloudinaryMediaAssetPlaceholderFromFileName(fallbackImageFileName,tip.id,'App\\Models\\Tip','thumbnail','main'),
      null,
      tip.videos?.[0],
      tip.rating,
      tip.videos?.[0]?.duration ? this.dataProcessingService.convertMillisecondsToMinutes(tip.videos[0].duration,45000) : null,
      new Date(tip.updated_at),
      tip.tags,
      null,
      null
    );
    return this.contentService.insert_cta_call_to_action_term(
      'watchVideoOrView',
      contentItem
    );
  }
  
  makeContentItems(tips: Tip[]): ContentItem[] {
    let content: ContentItem[] = [];
    if (tips?.length) {
      content = tips.map(tip => this.convertTipToContentItem(tip));
    }
    return content;
  }
  transformTip(tipResponse) {
    if (tipResponse.media?.length) {
      if (
        tipResponse.media.find(
          (m) => m.category === 'thumbnail' && m.type === 'main'
        )
      ) {
        tipResponse.media = tipResponse.media.find(
          (m) => m.category === 'thumbnail' && m.type === 'main'
        );
      } else {
        tipResponse.media = tipResponse.media[0];
      }
      tipResponse.media = Object.assign(
        tipResponse.media,
        this.mediaService.setupCloudinaryImageMediaUrls(tipResponse.media)
      );
    } else {
      tipResponse.media = null;
    }
    tipResponse.rating = Math.round(tipResponse.rating);
    let tip: Tip = tipResponse;
    return tip;
  }
  transformTips(tipsFromBackend) {
    let transformedtips: Tip[] = [];
    tipsFromBackend.forEach((t) => {
      transformedtips.push(this.transformTip(t));
    });
    return transformedtips;
  }
  getTip(slug : string, freshFromBackend: boolean, topicSlug ? : string) {
    let cachedTip: Tip = this.getCachedTip(slug,topicSlug);
    if (cachedTip && !freshFromBackend) {
      return of(cachedTip);
    }

    return this.http.get<{ data: Tip }>('api/v1/tips/slug/' + slug).pipe(
      map((response) => {
        let tip: Tip = this.transformTip(response.data);
        return tip;
      })
    );
  }

  cachePaginatedTips (topicSlug: string, paginatedTips : PaginatedTips, page : number, params : URLSearchParams){
    params.delete('page');
    if(this.currentURLSearchParamsForPaginatedTips && !this.dataProcessingService.areSearchParamsEqualDisregardingPageNumbers(params,this.currentURLSearchParamsForPaginatedTips)){
      this.currentURLSearchParamsForPaginatedTips = params;
      if(topicSlug){
        this.paginatedTipsByTopic[topicSlug] = {};
      }
    }
    if(!this.paginatedTipsByTopic[topicSlug]){
      this.paginatedTipsByTopic[topicSlug] = {};
    }
    paginatedTips.data = this.transformTips(paginatedTips.data);
    this.paginatedTipsByTopic[topicSlug][page] = paginatedTips;
    return this.paginatedTipsByTopic[topicSlug][page];
  };
  getTagIdsByTopic (topicSlug:string){
    if(!topicSlug){
      return this.handleError(null);
    }
    if (this.tagIdsByTopic[topicSlug]) {
      return of(this.tagIdsByTopic[topicSlug]);
    }

    let params = new URLSearchParams();
    params.append('topic',topicSlug);
    
    let url = 'api/v1/tips/tags-with-results?';

    return this.http.get<{data:number[]}>(url+params.toString()).pipe(
      catchError(error => this.handleError(error)),
      map((response) => {
        if (response.data) {
          this.tagIdsByTopic[topicSlug] = response.data;
          return this.tagIdsByTopic[topicSlug];
        }
      })
    );

  };

  getCachedPaginatedTips (topicSlug:string, params : URLSearchParams, page : number){

    if(
      !params ||
      !this.currentURLSearchParamsForPaginatedTips ||
      !this.dataProcessingService.areSearchParamsEqualDisregardingPageNumbers(params,this.currentURLSearchParamsForPaginatedTips)){
        return null;
      }
    return this.paginatedTipsByTopic[topicSlug][page];
  };

  getTipFromPages(tipSlug : string, pages : Record<number, PaginatedTips>){
    let tip: Tip = null;
  
    for (const page in pages) {
      while (pages[page]) {
        tip = pages[page].data.find(
          (t) => t.slug == tipSlug
        );
        if (tip) {
          return tip;
        }
      }
    }
  }

  getCachedTip(slug : string, topicSlug ? : string) {
    let tip : Tip;
    if(topicSlug && this.paginatedTipsByTopic[topicSlug]){
      tip = this.getTipFromPages(slug,this.paginatedTipsByTopic[topicSlug])
    } else {
      for (const topicObject in this.paginatedTipsByTopic) {
        tip = this.getTipFromPages(slug,this.paginatedTipsByTopic[topicObject]);
        if (tip) {
          return tip;
        }
      }
    }
    return tip;
  }
  getCachedTips(topic: string, page: number) {
    let cachedTips: PaginatedTips = null;
    if (this.paginatedTipsByTopic[topic] && this.paginatedTipsByTopic[topic][page]) {
      return this.paginatedTipsByTopic[topic][page];
    }
    return cachedTips;
  }

  getPaginatedTips(topic: string, filters : QueryParams, page: number, freshFromServer: boolean) {

    // filters example
    // {
    //   category: 'books',
    //   author: 'John Doe,Alice Smith,Bob Johnson',
    //   price: 'asc',
    // };

    filters['topic'] = topic;

    const params : URLSearchParams = this.dataProcessingService.convertObjectToSearchParams(filters);

    page = page ? page : 1;

    let cachedPaginatedTips: PaginatedTips;

    if (!freshFromServer) {
      cachedPaginatedTips = this.getCachedPaginatedTips(topic, params, page);
    }
    if (cachedPaginatedTips) {
      return of(cachedPaginatedTips);
    }


    params.set('page',page.toString());

    let url = 'api/v1/tips?';

    return this.http.get<PaginatedTips>(url+params.toString()).pipe(
      catchError(error => this.handleError(error)),
      map((tips) => {
        if (tips.data) {
          tips.data = this.transformTips(tips.data);
          this.cachePaginatedTips(topic, tips, page, params);
          return tips;
        }
      })
    );
  }
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse?.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    // switch (errorResponse.error.message) {
    //   case 'This action is unauthorized.':
    //     errorMessage = 'error.permissions_lacking';
    //     break;
    //   case 'Unauthenticated.':
    //     this.router.navigate(['/login']); // TODO - consider handling this by route guard instead (when we have solved the problem in route guard's timeout that slow get-user responses will redirect to login without waiting for an eror)
    //     break;
    //   case 'User not found':
    //     errorMessage = 'error.not_found_must_be_registered';
    //     break;
    //   case 'Only the current owner can set a new owner':
    //     errorMessage = 'error.permissions_lacking';
    //     break;
    //   case '400 Duplicate - already has this role in this hub':
    //     errorMessage = 'error.no_change_user_has_role_aready';
    //     break;
    //   case '403 Removing this role from the owner is not allowed':
    //     errorMessage = 'hubs.cannot_remove_owners_manager_role';
    //     break;
    //   case 'The given data was invalid.':
    //     if (
    //       errorResponse.error.errors?.file_url?.[0] ==
    //       'validation.ends_with_extension'
    //     ) {
    //       errorMessage = 'error.file_type';
    //     } else {
    //       errorMessage = 'error.data_invalid';
    //     }
    //     break;
    //   default:
    //   // no action needed. We already set the default error message.
    // }
    // if (errorResponse.error.meta){
    //   return throwError({message:errorMessage,meta:errorResponse.error.meta});
    // }
    return throwError(errorMessage);
  }
}
