<div class="container" *transloco="let t">
  <multisite-loader-globe-animation-detail *ngIf="loading"
    [width]="100" [height]="100">
  </multisite-loader-globe-animation-detail>
  <div class="card mt-5" *ngIf="faq">
    <div class="card-body">
      <h2 class="card-title"> {{ faq.question }} </h2>
      <!-- <h4> {{ page.subtitle }} </h4> -->
      <p>{{ faq.answer }}</p>
    </div>
  </div>
  
</div>