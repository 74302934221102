import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Media } from '@frontend/core';
import { MediaService } from './media.service';
import { map, of } from 'rxjs';

export interface Gallery {
  category:string,type:string,data:Media[]
}

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  
  galleries : Gallery[] = [];

  constructor(private mediaService : MediaService, private http : HttpClient) { }


  transformResponse (galleryResponse : Media[], category:string, type:string) : Gallery{
    let gallery : Gallery = {category:category,type:type,data:galleryResponse};
    return gallery;
  }
  transformMedia (media){
    let custom_urls = this.mediaService.setupCloudinaryImageMediaUrls(media);
    return new Media(media.id,null,null,media.file_url,custom_urls.base_url,custom_urls.transformations_url,custom_urls.asset_url,media.file_name,media.file_type,null,null,media.description,media.credits,null,media.category,media.type,null,null);
  }
  transformMediaArray (mediaFromBackend){
    let transformedMedia : Media[] = [];
    mediaFromBackend.forEach(m=>{
      transformedMedia.push(this.transformMedia(m));
    });
    return transformedMedia;
  }
  getGallery (category:string,type:string){

    let cachedGallery : Gallery = this.getCachedGallery(category,type);
    if (cachedGallery){
      return of(cachedGallery);
    };

    return this.http.get<{'data' : Media[]}>(
      'api/v1/media/gallery?category='+category+'&type='+type)
        .pipe(
          map(response =>{
            let media = this.transformMediaArray(response.data);
            let gallery : Gallery = this.transformResponse(media,category,type);
            this.cacheGallery(gallery);
            return gallery;
          })
        )
  };
  getCachedGallery (category:string,type:string) : Gallery {

    if (!this.galleries.length){
      return null;
    }
    let cachedGallery = this.galleries.find(g=>g.category===category&&g.type===type);
    if (cachedGallery?.data?.length){
      return cachedGallery;
    } else {
      return null;
    }

  };

  cacheGallery (gallery : Gallery){
    let index = this.galleries.findIndex(g=>g.category===gallery.category&&g.type===gallery.type);
    if (index >-1){
      this.galleries[index]= gallery;
    } else {
      this.galleries.push(gallery);
    }
  };


}