import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CiprofileWheelComponent } from './ciprofile-wheel/wheel.component';
import { CiprofileWheelPresenterComponent } from './ciprofile-wheel-presenter/wheel-presenter.component';
import { LinkTypeTwelveModule, SteppersModule } from '@frontend/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { SurveyContextModule } from '../../surveys';
import { ProgressIndicatorDoughnutModule, StepProgressIndicatorModule } from '../../tracking';



@NgModule({
  declarations: [
    CiprofileWheelComponent,
    CiprofileWheelPresenterComponent
  ],
  exports: [
    CiprofileWheelComponent,
    CiprofileWheelPresenterComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    SteppersModule,
    ButtonsModule,
    ReactiveFormsModule,
    SurveyContextModule,
    StepProgressIndicatorModule,
    ProgressIndicatorDoughnutModule,
    LinkTypeTwelveModule
  ]
})
export class CiprofileWheelModule { }
