import { Icon } from "@frontend/shared";

export class MetaText {

    id: number;
    text : string;
    s? : string;
    m? : string;
    l? : string;
    xl? : string;
    date? : string;
    num? : number;
    category? : string;
    type? : string;
    weight? : number;
    icon? : Icon;
}