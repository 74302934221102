<div>

  <div class="jumbotron jumbotron-fluid">
    <div class="container text-center">
      <h1 class="display-4">{{page.title}}</h1>
      <p class="lead">{{page.subtitle}}</p>
    </div>
  </div>
  <div *ngIf="page.blocks">
    <div *ngFor="let block of page.blocks">

      <!-- Jumbotron -->
      <div *ngIf="block.type == 'jumbotron1'"
        class="{{block.classes}}"
        [style]="block.background_image_url ? 'background-image: url('+block.background_image_url+')': ''">
  
        <!-- Title -->
        <h2 class="card-title h2">{{block.title}}</h2>
  
        <!-- Grid row -->
        <div class="row d-flex justify-content-center">
  
          <!-- Grid column -->
          <div class="col-xl-7 pb-2">
  
            <p class="card-text" [innerHTML]="block.content"></p>
  
          </div>
  
            <!-- Subtitle -->
            <p *ngIf="block.subtitle" class="my-4 h6">{{block.subtitle}}</p>
          <!-- Grid column -->
  
        </div>
        <!-- Grid row -->
  
        <hr class="my-4 rgba-white-light">
  
        <div class="pt-2">
          <button type="button"
          *ngFor="let b of block.buttons"
          class="btn btn-outline-primary"
          
          routerLink="{{b.routerLink}}"
          >
          <span [ngStyle]="{'color':b.color}">

            {{b.linkText}}
          </span>
          <!-- <mdb-icon *ngIf="b.icon && b.icon.category == 'far'" far icon="{{b.icon.type}}" class="ml-1"></mdb-icon> -->
         <i *ngIf="b.icon && b.icon.category == 'far'" class="fa-regular fa-{{b.icon.type}} ml-1"></i>
         <!-- <mdb-icon *ngIf="b.icon && b.icon.category == 'fas'" fas icon="{{b.icon.type}}" class="ml-1"></mdb-icon> -->
         <i *ngIf="b.icon && b.icon.category == 'fas'" class="fa-solid fa-{{b.icon.type}} ml-1"></i>
         <!-- <mdb-icon *ngIf="b.icon && b.icon.category == 'fab'" fab icon="{{b.icon.type}}" class="ml-1"></mdb-icon> -->
         <i *ngIf="b.icon && b.icon.category == 'fab'" class="fa-brands fa-{{b.icon.type}} ml-1"></i>
          </button>

          <!-- <button
            *ngFor="let b of block.buttons"
            type="button" mdbBtn
            color="{{b.color}}" outline="{{b.outline}}" mdbWavesEffect class="waves-light"
            routerLink="{{b.routerLink}}"
            >
            {{b.linkText}}
         <i *ngIf="b.icon && b.icon.category == 'far'" class="fa-regular fa-{{b.icon.type}} ml-1"></i>
         <i *ngIf="b.icon && b.icon.category == 'fas'" class="fa-solid fa-{{b.icon.type}} ml-1"></i>
         <i *ngIf="b.icon && b.icon.category == 'fab'" class="fa-brands fa-{{b.icon.type}} ml-1"></i>
          </button> -->
        </div>
  
      </div>
      <!-- Jumbotron -->
  
      <div class="card"
        *ngIf="block.type == 'mdbCard'"
        >
        <img [src]="block.background_image_url" class="card-img-top" alt="Sorry, no info about this image">
        <div class="{{block.classes}}">
          <div class="py-5">
      
            <!-- Content -->
            <h5 class="block.heading?.classes">{{block.heading?.content}}</h5>
            <h2 class="card-title h2 my-4 py-2">{{block.title}}</h2>
            <p class="mb-4 pb-2 px-md-5 mx-md-5" [innerHTML]="block.content"></p>
            <p *ngIf="block.subtitle" class="my-4 h6">{{block.subtitle}}</p>
            <button type="button"
          *ngFor="let b of block.buttons"
          class="btn btn-outline-primary"
          
          routerLink="{{b.routerLink}}"
          >
          <span [ngStyle]="{'color':b.color}">

            {{b.linkText}}
          </span>
          <!-- <mdb-icon *ngIf="b.icon && b.icon.category == 'far'" far icon="{{b.icon.type}}" class="ml-1"></mdb-icon> -->
         <i *ngIf="b.icon && b.icon.category == 'far'" class="fa-regular fa-{{b.icon.type}} ml-1"></i>
         <!-- <mdb-icon *ngIf="b.icon && b.icon.category == 'fas'" fas icon="{{b.icon.type}}" class="ml-1"></mdb-icon> -->
         <i *ngIf="b.icon && b.icon.category == 'fas'" class="fa-solid fa-{{b.icon.type}} ml-1"></i>
         <!-- <mdb-icon *ngIf="b.icon && b.icon.category == 'fab'" fab icon="{{b.icon.type}}" class="ml-1"></mdb-icon> -->
         <i *ngIf="b.icon && b.icon.category == 'fab'" class="fa-brands fa-{{b.icon.type}} ml-1"></i>
          </button>
      
          </div>
        </div>
      </div>
      
    </div>
  </div>

</div>

<div *ngIf="page.footer">
  <p class="text-muted small" [innerHTML]="page.footer"></p>
</div>
