import { Component, Input, input, output, signal } from '@angular/core';
import { Contributor } from '../../../auth';
import { MediaService, MetaText } from '../../../content';
import { Button, Tag, Video } from '@frontend/core';

@Component({
  selector: 'multisite-product-header-type-one',
  templateUrl: './product-header-type-one.component.html',
  styleUrl: './product-header-type-one.component.scss'
})
export class ProductHeaderTypeOneComponent {

  title = input.required<string>();
  subtitle = input<string>();
  description = input<string>();
  video = input<Video>();
  price = input<string>(); // '$125'
  contributors = input<Contributor[]>();
  makeGuest = output();
  newGuestError = input<string>();
  generalError = input<string>(); // mainly for the CTA button
  showContinueAsGuestOption = input<boolean>(false);
  showLoginOption = input<boolean>(true);
  goToLogin = output();
  loading = input<boolean>(false);
  guestLoading = input<boolean>(false);
  selectContributor = output<Contributor>();
  separateContributorCategories = input<string[]>(['supervisor','contributor']); // any category here will be displayed separately; include 'contributor' last to display miscellaneous other contributors
  selectableContributorCategories = input<string[]>(['supervisor','contributor']); // empty array or null means none is selectable, 'contributor' means any is selectable
  customContributorCategoryTranslationKey = input<string>(); // Show a title which is not 'Contributors' and not based on each contributor's category
  list = input<MetaText[]>();
  listTitleText = input<string>();
  listTitleTranslationKey = input<string>('marketing.what_learn');
  progressTitleTranslationKey = input<string>('tracking.progress');
  progressValue = input<number>();
  progressUnit = input<string>('%');
  ctaButtonDisabled = input<boolean>();
  ctaButton = input<Button>({
    classes:'btn btn-primary w-100 w-lg-100 btn-lg',
    iconClasses: 'fa-solid fa-cart-shopping',
    labelTranslationKey:'marketing.add_to_cart',
    type:'buy'}
  );
  date=input<Date>(undefined);
  dateLabelTranslationKey=input<string>(undefined);
  ratingMax=input<number>(5);
  // rating=input<number>(undefined);
  @Input() rating: number;
  tags = input<Tag[]>();
  buttonClicked = output();
  tagCategories = signal<{type:string,translationKey:string}[]>([
    {
      type:'culture',translationKey:'common.cultures',
    },
    {
      type:'language',translationKey:'common.languages',
    }
  ]);
  fallbackAvatar = signal<string | undefined>(undefined);
  cloudinary_base_url = signal<string | undefined>(undefined);
  iconLinks = input<MetaText[]>([]);
  iconLinksLabelTranslationKey = input<string | undefined>();
  selectIconLink = output<MetaText>();


  constructor(private mediaService : MediaService) {
    this.fallbackAvatar.set(this.mediaService.fallback_avatar_url);
    this.cloudinary_base_url.set(this.mediaService.cloudinary_base_url);
   }

  filterTags(type:string){
    return this.tags().filter(t=>t.type ===type);
  }
  onButtonClicked(){
    this.buttonClicked.emit();
  }
  onLoginButtonClicked(){
    this.goToLogin.emit();
  }

  onSelectContributor(contributor: Contributor) {
    if(!this.isSelectableContributorCategory(contributor.category)) return;
    this.selectContributor.emit(contributor);
  }
  onSelectIconLink(iconLink: MetaText) {
    this.selectIconLink.emit(iconLink);
  }
  onMakeGuest() {
    this.makeGuest.emit();
  }
  isSelectableContributorCategory(category: string) {
  return this.selectableContributorCategories().includes(category) || this.selectableContributorCategories().includes('contributor');
  // return computed(()=>this.selectableContributorCategories().includes(category) || this.selectableContributorCategories().includes('contributor'));
  }

  filteredContributors(category: string) : Contributor[]{
    return category === 'contributor' ? this.otherContributorsNotSeparated() : this.contributors()?.filter(contributor => contributor.category === category);
  }
  otherContributorsNotSeparated() : Contributor[] {
    return this.contributors()?.filter(contributor => !this.separateContributorCategories().includes(contributor.category));
  }

}
