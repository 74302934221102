import { FlagExtraLite } from "../flags";

export class AvailableLanguage {
    constructor(
        public languageKey : string, // de
        public name :string,  // 'LANGUAGE.DE
        public flag: FlagExtraLite,
        public flag_path: string, // 'app/images/flags/DE.png' // deprecated
        public flag_class: string, // 'app/images/flags/DE.png' // deprecated
        public change_language: string // 'Sprache wechseln'
    ) {}
  }