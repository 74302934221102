<!--Card-->
<div class="testimonial-card row my-4" >

  <!--Avatar-->
  <div class="col-md-2">
    <img *ngIf="imgUrl"
      [src]="imgUrl"
    />
  </div>

  <div class="card-body col-md-10">
    <!--Name-->
    <h4 class="card-title title-text">
      <span *ngIf="!nameClickable">{{ name }}</span>
      <a *ngIf="nameClickable"  (click)="doNameClick()">{{
        name
      }}</a>
      <span class="arrow"><i class="fa-solid fa-square-arrow-up-right"></i></span>
    </h4>
    <p class="text-muted">{{ subtitle }}</p>
    <hr />
    <!--Quotation-->
    <p class="card-text pt-2">
      {{ bodyText }}
    </p>
  </div>
</div>
<!--/.Card-->
