import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FaqListingComponent } from './faq-listing/faq-listing.component';
import { FaqComponent } from './faq/faq.component';

const routes: Routes = [
  {
    path: '',
    component: FaqListingComponent,
    data: { label: 'FAQs', translationKey: 'content.faqs', labelSource: null},
  },
  {
    path: 'search',
    data: { label: 'Search FAQs', translationKey: 'content.faqs', labelSource: null},
    component: FaqListingComponent,
  },
  {
    path: ':faq',
    data: { label: 'FAQ', translationKey: null, labelSource: 'titleService'},
    children: [
      {
        path: '',
        component: FaqComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FaqsRoutingModule { }
