import { Component, Input } from '@angular/core';

@Component({
  selector: 'multisite-icon-pill',
  templateUrl: './icon-pill.component.html',
  styleUrls: ['./icon-pill.component.scss']
})
export class IconPillComponent {

  @Input() labelTranslationKey: string; // 'quiz.test_your_knowledge'
  @Input() labelText: string; // 'Test your knowledge'
  @Input() iconClasses: string; // 'fa-solid fa-clock'
  @Input() selectable: boolean; // sets 'selectable' class
  @Input() active: boolean; // sets 'active' class
  @Input() compact: boolean; //
  @Input() shape: string = 'square'; // 'square' or 'round' sets the class icon-pill-square or icon-pill-round
  @Input() inverted: boolean; // sets 'inverted' class

  constructor() { }

}
