<div *transloco="let t">
  <ul class="{{'sidenav-menu sidenav-'+mode}}">

    <span class="change-sidenav-mode-icon" (click)="doModeChange('',$event)">
      <span *ngIf="mode !== 'wide'"><i class="fa-solid fa-chevron-left"></i></span>
      <span *ngIf="mode === 'wide'"><i class="fa-solid fa-chevron-right"></i></span>
    </span>
    <!-- SIDENAV TOP PART -->
    <li class="sidenav-top-content">
      <div class="sidenav-header">
        <a class="sidenav-close" (click)="doModeChange('slim',$event)" tabindex="0">
          <i class="fas fa-times"></i>
        </a>
        <div class="profile-dropdown" dropdown #dropdown="bs-dropdown" container="body" placement="bottom right">

          <button id="button-animated" dropdownToggle type="button" class="dropdown-toggle sidebar-toggle"
            aria-controls="dropdown-animated" *ngIf="user">


            <!-- USER DETAILS  -->
            <div class="user-details">
              <div class="profile-info">
                <p class="name h5 text-wrap">{{getUserNameWithTruncation()}}</p>
                <p class="profession h6 text-wrap" [ngClass]="{'d-none':mode !== 'wide'}">{{getUserTitleWithTruncation()}}
                </p>
              </div>
              <div class="d-flex flex-column align-items-center position-relative rounded-circle">
                <img class="user-profile-img" [src]="user?.picture ? user.picture : getDefaultAvatar()"
                  alt="Profile image">
                <span class="click-indicator animation-pulse-infinite"></span>
                <!-- ICON DROPDOWN - not needed -->
                <!-- <span class="icon" [ngClass]="{'dropdown-open':dropdown.isOpen}">
                  <i class="fa-solid fa-angle-down"></i>
                </span> -->
                <!-- END OF ICON DROPDOWN -->
              </div>
            </div>
            <!-- END OF USER DETAILS  -->
          </button>
          <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
            aria-labelledby="button-animated">
            <li *ngFor="let link of filteredAccountDropdownLinks(accountDropdownLinks, user?.roles)" [ngClass]="{'dropdown-header' : link.isHeader}">
              <p *ngIf="link.isHeader">{{t(link.labelTranslationKey)}}</p>
              <hr *ngIf="link.isDivider" class="dropdown-divider">
              <a class="dropdown-item large" [routerLink]="link.routerLinkArray" routerLinkActive="active" (click)="handleClick(link.clickHandler)" *ngIf="!link.isDivider && !link.isHeader">
                <span class="icon-holder">
                  <i [class]="link.iconClasses"></i>
                </span>
                <div>
                  <p>{{t(link.labelTranslationKey)}}</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <!-- 
      SIDENAV BOTTOM PART
      (One scrollable element - split into two parts) 
    -->
    <!-- <div style="width: 100%; padding: 0 1rem;"> -->
    <li class="sidenav-main-content" (click)="toggleViaSidenavBackground()">
      <!-- TOP PART OF MAIN CONTENT -->
      <div>
        <div class="card-group">
          <progressable-progress-summariser
            [defaultListMaxLength]="progressablesDefaultListMaxLength"
            [slimMode]="mode === 'slim'"
            (toggleSidenav)="doModeChange('')"
            [showAll]="showAllProgressables"
            (progressablesArray)="progressables = $event"
            >
          </progressable-progress-summariser>

          <a
            type="button"
            class="btn btn-link text-muted text-small"
            (click)="toggleShowAllProgressables($event)"
            *ngIf="progressables?.length > progressablesDefaultListMaxLength"
            >
            <span *transloco="let t">{{showAllProgressables ? t('common.show_less') : t('common.show_more')}}</span>
          </a>
        </div>

        <certification-display-list-slimmable *ngIf="isAdmin()" (click)="toggleSlimOpenNotClose($event)"
          [slimMode]="mode === 'slim'">
        </certification-display-list-slimmable>

      </div>
      <!-- BOTTOM PART OF MAIN CONTENT -->
      <div *transloco="let t">
        <hr *ngIf="isAdmin()">

        <progress-indicator-slimmable-number (click)="toggleSlimOpenNotClose($event)"
          [slimMode]="mode === 'slim'"></progress-indicator-slimmable-number>

        <div class="trainer-card" *ngIf="isAdmin()" (click)="toggleSlimOpenNotClose($event)">

          <carousel [interval]="false">
            <p class="trainer-title">{{t('common.trainers')}}</p>
            <slide>
              <div class="trainer-img">
                <img src="https://iili.io/yzAHJf.png" alt="">
                <div class="status-indicator"></div>
              </div>
              <div class="card-text">
                <p class="title">Richard Farkas</p>
                <p class="subtitle">
                  Head of ...
                </p>
              </div>
            </slide>
            <slide>
              <div class="trainer-img">
                <img src="https://iili.io/yzAHJf.png" alt="">
                <div class="status-indicator"></div>
              </div>
              <div class="card-text">
                <p class="title">Milos Usljebrka</p>
                <p class="subtitle">
                  UX/UI Designer
                </p>
              </div>
            </slide>
            <slide>
              <div class="trainer-img">
                <img src="https://iili.io/yzAHJf.png" alt="">
                <div class="status-indicator"></div>
              </div>
              <div class="card-text">
                <p class="title">John Doe</p>
                <p class="subtitle">
                  Senior Trainer
                </p>
              </div>
            </slide>
          </carousel>


          <!-- <div class="card-body bg-white">
              <div class="trainer-img">
                <img src="https://iili.io/yzAHJf.png" alt="">
                <div class="status-indicator"></div>
              </div>
              <div class="card-text">
                text
              </div>
            </div> -->
        </div>

        <!-- Sidenav collapse -->
        <button class="btn btn-link btn-sm text-decoration-none w-100 mt-2 text-muted opacity-hover-50 collapse" (click)="doModeChange('collapsed',$event)">
          <i class="fa-kit fa-light-sidebar-flip-circle-xmark fa-xl"></i> <span class="slim-hidden">{{t('common.hide')}}</span>
        </button>
        <!-- End of sidenav collapse -->


      </div>
    </li>
  </ul>
</div>