import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BellCurveComponent } from './bell-curve.component';



@NgModule({
  declarations: [
    BellCurveComponent
  ],
  exports: [
    BellCurveComponent
  ],
  imports: [
    CommonModule
  ]
})
export class BellCurveModule { }
