import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Media } from '@frontend/core';
import { Step } from '@frontend/shared';
import { Subscription } from 'rxjs';
import { AuthService, Guest, User } from '../../auth';
import { MediaService } from '../../content';
import { Snapshot, SnapshotRequestData, SnapshotService } from '../snapshots';

type SiteSpecificContent = {
    step:string, // 'ciprofile_content.stepper.take_time'
    title:string, // 'ciprofile_content.stepper.take_time_title'
    text:string, // 'ciprofile_content.stepper.take_time_text'
}

type FormValidStatus = {
  title : boolean,
  description : boolean,
  cultures : boolean,
}

type PresentationStep = {

  key : string, // an identifier for the presentation step
  titleTranslationKey : string,
  textTranslationKey : string,
  stepperStepTranslationKey : string,
  step : Step,
  captionTranslationKey ? : string,
  imageFileName? : string,
}

@Component({
  selector: 'multisite-surveys-snapshot-stepper',
  templateUrl: './surveys-snapshot-stepper.component.html',
  styleUrls: ['./surveys-snapshot-stepper.component.scss']
})
export class SurveysSnapshotStepperComponent implements OnInit {

  steps : Step[] = [];
  masterPresentationSteps: PresentationStep[];
  presentationSteps: PresentationStep[];
  currentPresentationStepIndex: number;
  @Input() loading: boolean;
  loadingNewGuest: boolean;
  loadingNewGuestError: string; // 'authentication.guest_quota_full'
  snapshotsSubscription: Subscription;
  newSnapshotFormStatus: FormValidStatus = {title:false,description:false,cultures:false};
  activeLanguageSubscription: Subscription;
  error: string;
  error_meta : {accepted_snapshot_ids:number[]}; // something sent with the error, in this case the ID of an existing Snapshot
  @Input() eventCodeError : string;
  @Input() snapshots: Snapshot[];
  @Input() snapshotIntroMedia: Media[];
  @Input() user : User = null;
  @Input() guest : Guest = null;
  @Input() siteSpecificStepOneTranslationKeys: SiteSpecificContent;
  @Output() eventCodeSubmitted = new EventEmitter<string>();
  accessCodeInChildForm: string; // (updated here only if valid or null)

  constructor(
    private snapshotService : SnapshotService,
    private router : Router,
    private route : ActivatedRoute,
    private authService : AuthService,
    private mediaService : MediaService,
  ) {

    this.steps = [
      {text : null , translationKey : 'ciprofile_content.stepper.take_time', completed : false, active : false, allowSkip : true}, // translationKey may be dynamically replaced by siteSpecificStepOneTranslationKeys.step
      {text : null , translationKey : 'survey.join_event', completed : false, active : false, allowSkip : true},
      {text : null , translationKey : 'survey.describe_situation', completed : false, active : false, requirements:{userOrGuest:true}},
      {text : null , translationKey : 'survey.choose_cultures', completed : false, active : false, requirements:{userOrGuest:true,snapshotFormTextValid:true}},
      {text : null , translationKey : 'survey.answer_questions', completed : false, active : false, requirements:{userOrGuest:true}},
      {text : null , translationKey : 'survey.get_feedback', completed : false, active : false, requirements:{userOrGuest:true}},
    ];
    let accessImageFile = 'https://res.cloudinary.com/cebt/image/upload/scenes/banners/entering-event-1cc9ad09-98fb-4873-a5c3-f7fec724f63f.jpg'
        
    this.masterPresentationSteps = [
      {
        key: 'start',
        titleTranslationKey: 'ciprofile_content.stepper.take_time_title', // titleTranslationKey may be dynamically replaced by siteSpecificStepOneTranslationKeys.title
        textTranslationKey: 'ciprofile_content.stepper.take_time_description', // textTranslationKey may be dynamically replaced by siteSpecificStepOneTranslationKeys.text
        stepperStepTranslationKey : 'ciprofile_content.stepper.take_time',
        step: null, // we'll add this dynamically, in case the step array changes
        imageFileName: 'https://res.cloudinary.com/cebt/image/upload/scenes/banners/man-in-foreground-of-crowd-midjourney-8cc16faf-e7e6-471a-812f-fb733210ce5f.jpg'
      },
      {
        key: 'code',
        titleTranslationKey: 'events.enter_code_if_available',
        textTranslationKey: 'events.enter_code_if_available_really',
        stepperStepTranslationKey : 'survey.join_event',
        step: null, // we'll add this dynamically, in case the step array changes
        imageFileName: accessImageFile
      },
      {
        key: 'code-check',
        titleTranslationKey: 'common.are_you_sure',
        textTranslationKey: 'events.join_before_answering',
        stepperStepTranslationKey : 'survey.join_event',
        step: null, // we'll add this dynamically, in case the step array changes
        imageFileName: accessImageFile
      },
      {
        key: 'login',
        titleTranslationKey: 'common.log_in',
        textTranslationKey: 'authentication.login_if_coach_employer',
        stepperStepTranslationKey : 'survey.join_event',
        step: null, // we'll add this dynamically, in case the step array changes
        imageFileName: accessImageFile
      },
      {
        key: 'snapshot-intro',
        titleTranslationKey: null,
        textTranslationKey: null,
        stepperStepTranslationKey : 'survey.describe_situation',
        step: null, // we'll add this dynamically, in case the step array changes
      },
      {
        key: 'snapshot-existing',
        titleTranslationKey: 'survey.snapshots_existing',
        textTranslationKey: 'survey.snapshots_existing_go',
        stepperStepTranslationKey : 'survey.describe_situation',
        step: null, // we'll add this dynamically, in case the step array changes
      },
      {
        key: 'snapshot-description',
        titleTranslationKey: 'survey.snapshots_description_help1',
        textTranslationKey: 'survey.snapshots_description_help2',
        stepperStepTranslationKey : 'survey.describe_situation',
        step: null, // we'll add this dynamically, in case the step array changes
      },
      {
        key: 'snapshot-cultures',
        titleTranslationKey: 'survey.snapshots_cultures_help1',
        textTranslationKey: null,
        stepperStepTranslationKey : 'survey.choose_cultures',
        step: null, // we'll add this dynamically, in case the step array changes
      },
      
    ]
  }

  setStepCompletionStatuses (steps:Step[]):Step[]{
    if(!steps?.length){return []};
    steps.forEach((step,index)=>{
      if(step.translationKey.includes('take_time')){
        step.completed = this.steps.findIndex(s=>s.active) > 0 ? true:false;
      } else if (step.translationKey.includes('join_event')){
        step.completed = (this.guest || this.user) && (this.steps.findIndex(s=>s.active) > index) ? true:false;
      } else if (step.translationKey.includes('describe_situation')){
        step.completed = this.newSnapshotFormStatus?.title && this.newSnapshotFormStatus?.description ? true:false;
      } else if (step.translationKey.includes('choose_cultures')){
        step.completed = this.newSnapshotFormStatus?.cultures ? true:false;
      }
    })
    return steps;
  }

  currentPresentationStepKeyIncludes(keys: string[]){
    return keys?.length ? keys.includes(this.presentationSteps[this.currentPresentationStepIndex]?.key) : false;
  }
  submitEventCode(code:string){
   this.eventCodeSubmitted.emit(code)
  }

  selectStepperStep(stepData : {step :Step , stepIndex: number}){
    let foundFirstPresentationStep : PresentationStep;
    if(stepData?.step.requirements?.userOrGuest && !this.user && !this.guest){// intercept if necessary
      foundFirstPresentationStep = this.presentationSteps.find(ps=>ps.key === 'login');
    } else if(stepData?.step.requirements?.snapshotFormTextValid && (!this.newSnapshotFormStatus.title && !this.newSnapshotFormStatus.description)){// intercept if necessary
      foundFirstPresentationStep = this.presentationSteps.find(ps=>ps.key === 'snapshot-intro');
    } else {
      foundFirstPresentationStep = this.presentationSteps.find(ps => ps.step.translationKey === stepData.step.translationKey);
    }
    if (foundFirstPresentationStep){
      this.goToPresentationStep(foundFirstPresentationStep);
    }
  }
  imageWithTransformations(file_url:string,transformations:string){
    return this.mediaService.insertCloudinaryTransformationsIntoMediaUrl(file_url,transformations);
  }
  goToLogin(){
    this.authService.setRouteForRedirectAfterAuth([window.location.pathname]);
    this.router.navigate(['/login']);
  }
  makeGuest(){
    this.loadingNewGuest = true;
      this.authService.makeGuest(null,null,null)
        .subscribe((response)=>{
          this.loadingNewGuest = false;
          this.guest = this.authService.guest.getValue(); // we need to do this because we'll move the wrong step before we get the new guest via the input
          let nextStep : PresentationStep = this.presentationSteps.find(ps=>ps.key === 'snapshot-intro');
          this.goToPresentationStep(nextStep);
        }, error => {
          this.loadingNewGuest = false;
          this.loadingNewGuestError = error;
        });
  }

  goToPresentationStep(presentationStep : PresentationStep){
    this.currentPresentationStepIndex = this.presentationSteps.findIndex(ps => ps.key === presentationStep.key);
    this.setActiveStepperStep();
  }

  nextPresentationStep(){
    // intercept if necessary
    if (this.currentPresentationStepKeyIncludes(['code','code-check']) && this.accessCodeInChildForm){
      this.submitEventCode(this.accessCodeInChildForm);
      return;
    }
    if (this.currentPresentationStepKeyIncludes(['login']) && !this.guest){ // this inteception should be unnecessary because the button is disabled
      return;
    }
    if (this.presentationSteps?.length > this.currentPresentationStepIndex+1){
      this.currentPresentationStepIndex++;
    } else {
      this.currentPresentationStepIndex = 0;
    }
    this.setActiveStepperStep();
  }
  setActiveStepperStep(){
    if (this.currentPresentationStepIndex === 0 || this.currentPresentationStepIndex > 0){
      this.steps.forEach(s => {
        s.active = s.translationKey === this.presentationSteps[this.currentPresentationStepIndex].step.translationKey ? true:false;
      })
    }
    this.steps = this.setStepCompletionStatuses(this.steps);
  }
  previousStep(){
    if (this.currentPresentationStepIndex>0){
      this.currentPresentationStepIndex--;
    } /*else {
      this.currentPresentationStepIndex = this.presentationSteps.length-1;
    }*/
    this.setActiveStepperStep();
  }

  activeStep(){
    return this.steps.find(s=>s.active);
  }
  navigateTo(routeArray:string[]){
    this.router.navigate(routeArray,{relativeTo:this.route})
  }

  currentAcceptedSnaphot (){
    return this.snapshots?.length ? this.snapshots.find(s=>s.current_accepted) : null;
  }

  storeNewSnapshot(snapshotData:SnapshotRequestData){
    this.loading = true;
    this.error = null;
    this.error_meta = null;
    this.snapshotsSubscription = this.snapshotService.storeNewSnapshot(snapshotData).subscribe(snapshot=>{
      this.router.navigate(['/snapshots/'+snapshot.id], {relativeTo: this.route});
      this.loading = false;
    },
    (error : string | {message:string,meta:any}) => {
      if (typeof error === 'string'){
        this.error = error;
      } else {
        this.error = error.message;
        this.error_meta = error.meta;
        this.loading = false;
      };
    })
  };

  assignSteps(){
    this.presentationSteps.forEach(ps=>{
      ps.step = this.steps.find(s=>s.translationKey===ps.stepperStepTranslationKey)
    })
  }
  addSiteSpecificStepOneContent(content:SiteSpecificContent){
    if(content.step){
      this.steps[0].translationKey = content.step;
    };
    if(content.title){
      this.masterPresentationSteps[0].titleTranslationKey = content.title;
    }
    if(content.text){
      this.masterPresentationSteps[0].textTranslationKey = content.text;
    }
  }
  makePresentationSteps(user:User,guest:Guest,snapshots: Snapshot[]){
    if(this.siteSpecificStepOneTranslationKeys){
      this.addSiteSpecificStepOneContent(this.siteSpecificStepOneTranslationKeys);
    }
    let presentationSteps: PresentationStep[] = user ? this.masterPresentationSteps.filter(mps=>mps.key !== 'login') : this.masterPresentationSteps;
    return snapshots?.length ? presentationSteps : presentationSteps.filter(ps=>ps.key !== 'snapshot-existing');
  }

  updatePresentationSteps(user:User,guest:Guest,snapshots: Snapshot[]){
    this.presentationSteps = this.makePresentationSteps(user,guest,snapshots);
    if (this.steps?.length && this.presentationSteps?.length){
      this.assignSteps();
    }
    this.goToPresentationStep(this.presentationSteps[0]);
  }

  handleNewSnapshotFormStatus(formStatus : FormValidStatus){
    this.newSnapshotFormStatus = formStatus; // TODO - set the variable directly from the template
    let nextStep : PresentationStep;
    let currentStep : PresentationStep = this.presentationSteps[this.currentPresentationStepIndex];
    if(!formStatus.title || !formStatus.description){
      if (currentStep.key !== 'snapshot-description'){
        nextStep = this.presentationSteps.find(ps=>ps.key === 'snapshot-description');
        this.goToPresentationStep(nextStep);
      }
    } else if(currentStep.key !== 'snapshot-cultures'){
      nextStep = this.presentationSteps.find(ps=>ps.key === 'snapshot-cultures');
      this.goToPresentationStep(nextStep);
    }
  }

  ngOnInit(): void {
    this.updatePresentationSteps(this.user,this.guest,this.snapshots);
    this.goToPresentationStep(this.presentationSteps[0]);
  }
  
  ngOnChanges(changesObject): void {
    
    if (changesObject.user || changesObject.guest || changesObject.snapshots || changesObject.siteSpecificStepOneTranslationKeys){
      this.presentationSteps = this.makePresentationSteps(this.user,this.guest, this.snapshots);
      this.assignSteps();
      if(changesObject.guest?.currentValue && !changesObject.guest.previousValue){
        // do nothing (if we just created a new guest, we don't want to go back to the start)
      } else {
          let firstIncompleteStepperStepIndex = this.steps.findIndex(s=>!s.completed);
          if(firstIncompleteStepperStepIndex > -1){
            this.selectStepperStep({step:this.steps[firstIncompleteStepperStepIndex],stepIndex:firstIncompleteStepperStepIndex});
          } else {
              this.goToPresentationStep(this.presentationSteps[0]);
          }
      }
    }
    
  }
  ngOnDestroy(): void {
    if (this.activeLanguageSubscription){
      this.activeLanguageSubscription.unsubscribe();
    }
    if (this.snapshotsSubscription){
      this.snapshotsSubscription.unsubscribe();
    }
  }

}
