<div class="container">
  <div class="row mt-3 feed-with-filters">
    <div class="col-xl-9 col-lg-8 col-md-7">
      <div class="feed-card-holder">


        <div *ngFor="let contentItem of paginatedContentItems?.data">
          <multisite-card-cta-horizontal [content]="contentItem" [imageTransformations]="'w_275,ar_16:9/'">
          </multisite-card-cta-horizontal>
        </div>

        <div class="my-3">
          <frontend-pagination-type-three
            *ngIf="paginatedContentItems?.meta && paginatedContentItems.meta.last_page > 1"
            [classes]="'pg-purple justify-content-center'" [loading]="isAnythingLoading()"
            [meta]="paginatedContentItems.meta" [lastPage]="paginatedContentItems.meta.last_page"
            [links]="paginatedContentItems.links" (changePage)="doGetPaginatedContent($event)">
          </frontend-pagination-type-three>
        </div>

        <multisite-loader-globe-animation-detail *ngIf="isAnythingLoading()" width="100" height="100"
          class="my-3"></multisite-loader-globe-animation-detail>

        <alert [type]="'warning'"
          *ngIf="!isAnythingLoading() && paginatedContentItems?.data && !paginatedContentItems.data.length">
          <div class="text-center my-5" *transloco="let t">{{ t('common.no_results') }}</div>
        </alert>

      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-5">
      <div class="feed-filters">
        <button type="button" class="btn btn-primary w-100 toggle-filters"  *transloco="let t"
          (click)="areFiltersCollapsed = !areFiltersCollapsed" [attr.aria-expanded]="!areFiltersCollapsed"
          aria-controls="collapseFilters"> {{t('common.filters')}} <i class="ml-2 fa-solid fa-angle-down"></i>
        </button>
        <div class="filters-body" id="collapseFilters" [collapse]="areFiltersCollapsed" [isAnimated]="true">

          <frontend-pagination-type-three
            *ngIf="paginatedContentItems?.meta && paginatedContentItems.meta.last_page > 1"
            [classes]="'pg-purple justify-content-between mb-0'" [loading]="isAnythingLoading()"
            [meta]="paginatedContentItems.meta" [lastPage]="paginatedContentItems.meta.last_page"
            [links]="paginatedContentItems.links" [simplified]="true" (changePage)="doGetPaginatedContent($event)">
          </frontend-pagination-type-three>

    

          <form class="mt-3" [formGroup]="searchForm" role="form" *ngIf="searchForm" (ngSubmit)="onSubmit()">

            <div class="input-group" *transloco="let t">
              <input type="search" class="form-control search"
                [placeholder]="t('navigation.search')+': '+t('common.name')" aria-label="Enter what you want to search"
                formControlName="query" />
              <span class="input-group-text" id="keyword-add-on" [ngClass]="{'disabled':searchForm.get('query').errors}"><i class="fa-solid fa-magnifying-glass"></i></span>
            </div>
  
            <div *transloco="let t" class="d-flex flex-column gap-1 filters-border">
              <span class="filters-title">{{t('common.sort')}}</span>
              <div class="d-flex align-items-center gap-1 flex-wrap">
                <span *ngFor="let sortable of sort_criteria" (click)="setSortOrderParams(sortable,true)"
                  class="badge badge-pill clickable" [ngClass]="{
                'badge-primary': queryParams['sort_by'] === sortable.sort_by,
                'badge-secondary': queryParams['sort_by'] !== sortable.sort_by,
                'disabled':isAnythingLoading() || paginatedContentItems?.meta?.total === 1
                }">
                  <span *ngIf="queryParams['sort_by'] === sortable.sort_by">
                    <span *ngIf="sortable.sort_order === 'asc'"><i class="fa-solid fa-arrow-up mr-1"></i></span>
                    <span *ngIf="sortable.sort_order === 'desc'"><i class="fa-solid fa-arrow-down mr-1"></i></span>
                  </span>
                  <span>{{queryParams['sort_by'] === sortable.sort_by && sortable.sort_order === 'asc' ?
                    t(sortable.translationKeyAsc) : t(sortable.translationKeyDesc)}}</span>
                </span>
                <span class="badge badge-pill badge-secondary clickable"
                  [ngClass]="{disabled:isAnythingLoading()} || paginatedContentItems?.meta?.total === 1"
                  (click)="setSortOrderParams(null,true)">{{t('common.none')}}</span>
              </div>
            </div>
            
            @for (filterTagsType of filterTagsByType; track filterTagsType) {

              <div id="selected-filters" class="filters-border">
            <label for="selected-filters" class="mb-2 filters-title"><span
                *transloco="let t">{{t('navigation.filters.'+filterTagsType.type)}}</span></label>
              @for (filterTag of filterTagsType.tags; track filterTag.id) {

              <div class="form-check">
                <input [formControlName]="'tag_'+filterTag.id" class="form-check-input" type="checkbox"
                  [id]="'tag_'+filterTag.id" [attr.disabled]="isAnythingLoading() ? true : null" />
                <label class="form-check-label" [attr.for]="'tag_'+filterTag.id">{{filterTag.name}}</label>
              </div>
              }

            </div>
            }
            <div class="mt-3" *ngIf="demoModeConfigObject">
              <label for="cultures" *transloco="let t" class="filters-title">{{ t('common.national_cultures')}}</label>
              <culture-selector-type-one [cultures]="nationalCultures" [loading]="isAnythingLoading()"
                (selected)="selectCulture($event)" (deselected)="deselectCulture($event)"
                [selectedCultures]="selectedNationalCultures" [placeholderTranslationKey]="">
              </culture-selector-type-one>
            </div>
            <div class="d-flex mt-3 justify-content-between align-items-center">
              <div *ngIf="isAnythingLoading()">
                <multisite-loader-globe-animation-detail width="40"
                  height="40"></multisite-loader-globe-animation-detail>
              </div>
              <button class="btn btn-primary" [disabled]="isAnythingLoading() || searchForm.invalid" (click)="getContent()"
                *transloco="let t">{{t('common.apply_filters')}}</button>
            </div>

          </form>

        </div>

      </div>
    </div>
  </div>
</div>