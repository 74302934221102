import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnapshotContextComponent } from './snapshot-context/snapshot-context.component';
import { TranslocoModule } from '@jsverse/transloco';


@NgModule({
  declarations: [
    SnapshotContextComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
  ],
  exports: [
    SnapshotContextComponent
  ]
})
export class SurveyContextModule { }
