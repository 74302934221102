import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { DataProcessingService } from '@frontend/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
@Component({
  selector: 'multisite-rating-basic',
  templateUrl: './rating-basic.component.html',
  styleUrls: ['./rating-basic.component.scss']
})
export class RatingBasicComponent implements OnChanges {
  
  @Input() text: string;
  @Input() translationKey: string; // 
  @Input() max: number;
  @Input() rate: number;
  @Input() isReadonly: boolean;
  @Input() loading: boolean;
  @Input() newRatingSaved: boolean;
  @Output() submitRating = new EventEmitter<number>();
  @Output() feedbackModalClosed = new EventEmitter<boolean>(); // we only need this if the parent component has more than one instances of the rating component or if we are hiding the component after rating.
  modalCloseSubscription : Subscription;
  
  editMode: boolean = false; // allows the user to change his/her rating
  overStar: number = 0;
  newRatingSaving: boolean; // a local reference, so that we don't open the modal in other instances of this component.

  @ViewChild('successModal') successModal;
  modalRef?: BsModalRef;
  ratingFeedbackTranslationKey : string = '';
  ratingFeedbackTranslationKeys : string[];

  constructor(
    private modalService: BsModalService,
    private dataProcessingService : DataProcessingService
    ) {
    this.ratingFeedbackTranslationKeys = [
      "rating_low", // "We're sorry this wasn't great for you, but this is important feedback for us!",
      "rating_low_again", // "Not great? Please give us another chance soon. Your feedback helps us improve.",
      "rating_valuable", // "Your rating helps others find the best stuff and helps us understand what you find valuable.",
      "rating_important", // "Hearing your opinion helps us do better and helps others too.",
      "rating_high", // Wow! We'll try to make you this happy every time!",
      "rating_high_again", // "We're happy you're happy!",
    ];
    this.ratingFeedbackTranslationKey = this.ratingFeedbackTranslationKeys[2];
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalCloseSubscription = this.modalService.onHide.subscribe(() => {
      this.feedbackModalClosed.emit(true);
    })
  }

  confirmSelection (){
    if (!this.isReadonly && this.rate){
      this.newRatingSaving = true;
      this.submitRating.emit(this.rate);
    }
  }

  enableEditMode (){
    this.editMode = true;
    this.isReadonly = false;
  }

  hoveringOver(value: number): void {
    this.overStar = value;
  }

  resetStar(): void {
    this.overStar = this.rate ? this.rate : 0;
  }

  generateRatingFeedbackTranslationKey (rate){
    if (rate < 3){
      this.ratingFeedbackTranslationKey = this.ratingFeedbackTranslationKeys[this.dataProcessingService.getRandomNumberWithinRange(0,1)];
    } else if (rate > 4){
      this.ratingFeedbackTranslationKey = this.ratingFeedbackTranslationKeys[this.dataProcessingService.getRandomNumberWithinRange(4,5)];
    } else {
      this.ratingFeedbackTranslationKey = this.ratingFeedbackTranslationKeys[this.dataProcessingService.getRandomNumberWithinRange(2,3)];
    }
  }

  ngOnChanges(changesObject): void {
    if (changesObject.rate){
      if (!changesObject.rate.isFirstChange()){
        this.rate = this.rate ? this.rate : 0;
      };
      if (changesObject.rate.currentValue){
        this.isReadonly = true; // you need manually unlock it
        this.editMode = false;
      }
    }
    if (changesObject.newRatingSaved && changesObject.newRatingSaved.currentValue){
        this.generateRatingFeedbackTranslationKey (this.rate);
        if (this.newRatingSaving){
          this.openModal(this.successModal);
          this.newRatingSaving = false;
        }
    }
  }
  ngOnDestroy (){
    if (this.modalCloseSubscription){
      this.modalCloseSubscription.unsubscribe()
    }
  }

}
