<div class="container mb-5 pt-4" *transloco="let t">
  <h1 class="static-title h1 fw-bold">
    {{t('navigation.hub_new')}}
  </h1>

  <form [formGroup]="newHubForm" (submit)="onSubmit()" role="form">
    <div class="row">
      <div class="col-lg-8">
        <div class="input-self mb-3">
          <label for="name" class="form-label">{{ t('common.name') }}<span class="required-sign">*</span>
          </label>
          <div class="input-group" [ngClass]="{
              'ng-invalid':
                newHubForm.get('name').invalid && newHubForm.get('name').touched,
              'ng-valid':
                newHubForm.get('name').valid &&
                newHubForm.get('name').touched &&
                newHubForm.get('name').dirty
            }">
            <span class="input-group-text text-primary-light">
              <i class="fa fa-info" aria-hidden="true"></i>
            </span>
            <input class="form-control" type="text" formControlName="name" id="name"
              placeholder="{{ 'common.name' | transloco }}" />
            <div *ngIf="
                newHubForm.get('name').errors?.required &&
                newHubForm.get('name').touched
              " class="invalid-feedback">
              {{ t('common.required') }}
            </div>
            <div *ngIf="
                newHubForm.get('name').errors?.name &&
                newHubForm.get('name').touched
              " class="invalid-feedback">
              <!--  -->
            </div>
          </div>
          <div class="form-text">
            {{ t('content_management.same_all_languages') }}
          </div>
        </div>

        <div class="input-self mb-3">
          <label for="hubTypeRadio1" class="form-label">{{ t('common.type')
            }}<span class="required-sign">*</span>
          </label>
          <div class="form-text mt-0">
            {{ t('hubs.what_kind') }}
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="hubType" id="hubTypeRadio1" value="personal"
              [ngClass]="{ 'is-invalid': isControlInvalid('hubType') }" [ngClass]="{
                'ng-invalid':
                  newHubForm.get('hubType').invalid &&
                  newHubForm.get('hubType').touched,
                'ng-valid':
                  newHubForm.get('hubType').valid &&
                  newHubForm.get('hubType').touched &&
                  newHubForm.get('hubType').dirty
              }" />
            <label class="form-check-label" for="hubTypeRadio1">
              Personal |
              <span class="text-muted">We will emphasise your individuality and personal
                achievements</span>
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="hubType" id="hubTypeRadio2" value="micro" />
            <label class="form-check-label" for="hubTypeRadio2">
              Solo entrepreneur |
              <span class="text-muted">We will emphasise your brand</span>
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="hubType" id="hubTypeRadio3"
              value="business" />
            <label class="form-check-label" for="hubTypeRadio3">
              Small business |
              <span class="text-muted">We will emphasise the achievements of your company and team</span>
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="hubType" id="hubTypeRadio4" value="agency" />
            <label class="form-check-label" for="hubTypeRadio4">
              Agency |
              <span class="text-muted">We will emphasise your service range and quality</span>
            </label>
          </div>
        </div>
        <div class="language-adding-card">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Languages</h5>
              <p>Here you can add or remove languages for translating all content.</p>
              <div class="active-languages">
                <p>Active languages:</p>
                <div class="d-flex gap-2">
                  <span class="badge disabled badge-pill badge-info">
                    English
                    <button type="button" aria-label="Remove" class="btn btn-dismiss">
                      <i class="fa-solid fa-xmark"></i>
                    </button>
                  </span>
                </div>
              </div>
              <p class="mt-4">Add a language:</p>
              <input typeaheadOptionField="name" name="language" [placeholder]="t('common.start_typing')"
                class="form-control mt-2">

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <multisite-link-type-six *ngFor="let link of links"
          [imageTransformations]="'w_100,c_thumb/'"
          [titleText]="link.label"
          [description]="link.description"
          [imageUrl]="link.media?.base_url"
          [media]="link.media"
          [cta_label]="link.cta_label"
          [cta_type]="link.cta_type"
          [url]="link.url"
          >
        </multisite-link-type-six>
      </div>
    </div>

    <div class="input-self my-4">
      <label for="strapline" class="form-label h3 fw-bold">{{ t('content_management.strapline')
        }}<span class="required-sign">*</span>
      </label>
      <p class="mb-2">{{ t('content_management.strapline_help') }}</p>
      <div class="input-group" [ngClass]="{
          'ng-invalid':
            newHubForm.get('strapline').invalid &&
            newHubForm.get('strapline').touched,
          'ng-valid':
            newHubForm.get('strapline').valid &&
            newHubForm.get('strapline').touched &&
            newHubForm.get('strapline').dirty
        }">
        <span class="input-group-text text-primary-light">
          English
        </span>
        <input class="form-control" type="text" formControlName="strapline" id="strapline"
          placeholder="Come on a journey with us" />
        <div *ngIf="
            newHubForm.get('strapline').errors?.required &&
            newHubForm.get('strapline').touched
          " class="invalid-feedback">
          {{ t('common.required') }}
        </div>
        <div *ngIf="
            newHubForm.get('strapline').errors?.minlength &&
            newHubForm.get('strapline').touched
          " class="invalid-feedback">
          {{ t('common.too_short') }}
        </div>
      </div>
    </div>

    <div class="input-self my-4">
      <label for="description" class="form-label h3 fw-bold">{{ t('common.description')
        }}<span class="required-sign">*</span>
      </label>
      <p class="mb-2">{{ t('content_management.description_help') }}</p>
      <div class="input-group" [ngClass]="{
          'ng-invalid':
            newHubForm.get('description').invalid &&
            newHubForm.get('description').touched,
          'ng-valid':
            newHubForm.get('description').valid &&
            newHubForm.get('description').touched &&
            newHubForm.get('description').dirty
        }">
        <span class="input-group-text text-primary-light">
          English
        </span>
        <textarea class="form-control" formControlName="description" id="description" rows="5" [disabled]="loading"
          placeholder="{{ 'common.write_something_here' | transloco }}">
        </textarea>
        <div *ngIf="
            newHubForm.get('description').errors?.required &&
            newHubForm.get('description').touched
          " class="invalid-feedback">
          {{ t('common.required') }}
        </div>
        <div *ngIf="
            newHubForm.get('description').errors?.minlength &&
            newHubForm.get('description').touched
          " class="invalid-feedback">
          {{ t('common.too_short') }}
        </div>
      </div>
    </div>
  </form>
</div>

<div class="container" *transloco="let t">
  <div class="row">
    <h3>New hub</h3>
    <form [formGroup]="newHubForm" (submit)="onSubmit()" role="form">
      <div class="input-self mb-3">
        <label for="name" class="form-label">{{ t('common.name') }}<span class="required-sign">*</span>
        </label>
        <div class="input-group" [ngClass]="{
            'ng-invalid':
              newHubForm.get('name').invalid && newHubForm.get('name').touched,
            'ng-valid':
              newHubForm.get('name').valid &&
              newHubForm.get('name').touched &&
              newHubForm.get('name').dirty
          }">
          <span class="input-group-text text-primary-light">
            <i class="fa fa-info" aria-hidden="true"></i>
          </span>
          <input class="form-control" type="text" formControlName="name" id="name"
            placeholder="{{ 'common.name' | transloco }}" />
          <div *ngIf="
              newHubForm.get('name').errors?.required &&
              newHubForm.get('name').touched
            " class="invalid-feedback">
            {{ t('common.required') }}
          </div>
          <div *ngIf="
              newHubForm.get('name').errors?.name &&
              newHubForm.get('name').touched
            " class="invalid-feedback">
            <!--  -->
          </div>
        </div>
        <div class="form-text">
          {{ t('content_management.same_all_languages') }}
        </div>
      </div>

      <div class="input-self mb-3">
        <label for="hubTypeRadio1" class="form-label">{{ t('common.type')
          }}<span class="required-sign">*</span>
        </label>
        <div class="form-text mt-0">
          {{ t('hubs.what_kind') }}
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="hubType" id="hubTypeRadio1" value="personal"
            [ngClass]="{ 'is-invalid': isControlInvalid('hubType') }" [ngClass]="{
              'ng-invalid':
                newHubForm.get('hubType').invalid &&
                newHubForm.get('hubType').touched,
              'ng-valid':
                newHubForm.get('hubType').valid &&
                newHubForm.get('hubType').touched &&
                newHubForm.get('hubType').dirty
            }" />
          <label class="form-check-label" for="hubTypeRadio1">
            Personal |
            <span class="text-muted">We will emphasise your individuality and personal
              achievements</span>
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="hubType" id="hubTypeRadio2" value="micro" />
          <label class="form-check-label" for="hubTypeRadio2">
            Solo entrepreneur |
            <span class="text-muted">We will emphasise your brand</span>
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="hubType" id="hubTypeRadio3" value="business" />
          <label class="form-check-label" for="hubTypeRadio3">
            Small business |
            <span class="text-muted">We will emphasise the achievements of your company and team</span>
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="hubType" id="hubTypeRadio4" value="agency" />
          <label class="form-check-label" for="hubTypeRadio4">
            Agency |
            <span class="text-muted">We will emphasise your service range and quality</span>
          </label>
        </div>
      </div>

      <div class="input-self mb-3">
        <label for="strapline" class="form-label">{{ t('content_management.strapline')
          }}<span class="required-sign">*</span>
        </label>
        <div class="input-group" [ngClass]="{
            'ng-invalid':
              newHubForm.get('strapline').invalid &&
              newHubForm.get('strapline').touched,
            'ng-valid':
              newHubForm.get('strapline').valid &&
              newHubForm.get('strapline').touched &&
              newHubForm.get('strapline').dirty
          }">
          <span class="input-group-text text-primary-light">
            <i class="fa fa-info" aria-hidden="true"></i>
          </span>
          <input class="form-control" type="text" formControlName="strapline" id="strapline"
            placeholder="Come on a journey with us" />
          <div *ngIf="
              newHubForm.get('strapline').errors?.required &&
              newHubForm.get('strapline').touched
            " class="invalid-feedback">
            {{ t('common.required') }}
          </div>
          <div *ngIf="
              newHubForm.get('strapline').errors?.minlength &&
              newHubForm.get('strapline').touched
            " class="invalid-feedback">
            {{ t('common.too_short') }}
          </div>
        </div>
        <div class="form-text">
          {{ t('content_management.strapline_help') }}
        </div>
      </div>

      <div class="input-self mb-3">
        <label for="description" class="form-label">{{ t('common.description')
          }}<span class="required-sign">*</span>
        </label>
        <div class="input-group" [ngClass]="{
            'ng-invalid':
              newHubForm.get('description').invalid &&
              newHubForm.get('description').touched,
            'ng-valid':
              newHubForm.get('description').valid &&
              newHubForm.get('description').touched &&
              newHubForm.get('description').dirty
          }">
          <span class="input-group-text text-primary-light">
            <i class="fa fa-info" aria-hidden="true"></i>
          </span>
          <textarea class="form-control" formControlName="description" id="description" rows="5" [disabled]="loading"
            placeholder="{{ 'common.write_something_here' | transloco }}">
          </textarea>
          <div *ngIf="
              newHubForm.get('description').errors?.required &&
              newHubForm.get('description').touched
            " class="invalid-feedback">
            {{ t('common.required') }}
          </div>
          <div *ngIf="
              newHubForm.get('description').errors?.minlength &&
              newHubForm.get('description').touched
            " class="invalid-feedback">
            {{ t('common.too_short') }}
          </div>
        </div>
        <div class="form-text">
          {{ t('content_management.description_help') }}
        </div>
      </div>

      <div class="input-self mb-3">
        <label for="owner" class="form-label">{{ t('common.owner')
          }}<span class="required-sign">*</span>
        </label>
        <div class="form-text mt-0">
          {{ t('hubs.who_owner') }}
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="owner" name="owner" id="owner1" value="me" />
          <label class="form-check-label" for="owner1"> I will be </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="owner" name="owner" id="owner2" value="other" />
          <label class="form-check-label" for="owner2">
            Someone else will be
          </label>
        </div>
      </div>

      <p><b>ownerIsOtherUser</b>: {{ ownerIsOtherUser }}</p>

      <div class="input-self mb-3" *ngIf="ownerIsOtherUser">
        <label for="ownerEmail" class="form-label">{{ t('hubs.owner_email')
          }}<span class="required-sign">*</span>
        </label>
        <div class="input-group" [ngClass]="{
            'ng-invalid':
              newHubForm.get('ownerEmail')?.invalid &&
              newHubForm.get('ownerEmail')?.touched,
            'ng-valid':
              newHubForm.get('ownerEmail')?.valid &&
              newHubForm.get('ownerEmail')?.touched &&
              newHubForm.get('ownerEmail')?.dirty
          }">
          <span class="input-group-text text-primary-light">
            <i class="fa fa-info" aria-hidden="true"></i>
          </span>
          <input class="form-control" type="email" formControlName="ownerEmail" id="ownerEmail"
            placeholder="boss@organisation.com" />
          <div *ngIf="
              newHubForm.get('ownerEmail')?.errors?.required &&
              newHubForm.get('ownerEmail')?.touched
            " class="invalid-feedback">
            {{ t('common.required') }}
          </div>
          <div *ngIf="
              newHubForm.get('ownerEmail')?.errors?.minlength &&
              newHubForm.get('ownerEmail')?.touched
            " class="invalid-feedback">
            {{ t('common.too_short') }}
          </div>
        </div>
        <div class="form-text">
          {{ t('hubs.owner_email_help') }}
        </div>
      </div>

      <div class="input-self">
        <div class="input-group my-3">
          <button type="submit" class="btn btn-primary w-100" [disabled]="newHubForm.invalid || loading">
            {{ t('common.create') }}
          </button>
          <multisite-loader-globe-animation-detail *ngIf="loading" [width]="50" [height]="50">
          </multisite-loader-globe-animation-detail>
          <alert class="mt-1 w-100" [type]="'warning'" *ngIf="errorMessage">
            <strong class="mr-1">
              {{ t('common.problem') }}
            </strong>
            {{ t(errorMessage) }}
          </alert>
          <alert class="mt-1 w-100" [type]="'success'" [dismissOnTimeout]="5000" *ngIf="successMessage">
            <strong class="mr-1">
              {{ t('common.success') }}
            </strong>
            {{ t(successMessage) }}
          </alert>
          <!-- <p>Errors: {{newHubForm.get('remoteFileUrl').errors | json}}</p> -->
        </div>
      </div>
    </form>
  </div>
</div>