import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'ttt-design06',
  templateUrl: './design06.component.html',
  styleUrls: ['./design06.component.scss']
})
export class Design06Component implements OnInit {
  
  max : number;
  rate : number;
  isReadonly : boolean;
  @ViewChild('niceInfoModal') niceInfoModal;
  modalRef?: BsModalRef;
  // Footer colapse
  isCollapsedFooter: boolean;
  
  constructor(private modalService: BsModalService) { }
  
  closeModal(){
    this.modalRef.hide();
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  
  
  ngOnInit(): void {
    // Rating stars in carousel-card 
    this.max = 5;
    this.rate = 5;
    this.isReadonly = true;
    this.isCollapsedFooter = true;
  }

}
