import { Injectable } from "@angular/core";

import { TranslocoService } from "@jsverse/transloco";
import { Observable, BehaviorSubject } from "rxjs";

export interface BreadcrumbFragment {
    urlFragment : string; // course
    fragmentName : string; // Starting a business
}

@Injectable({providedIn:'root'})
export class BreadcrumbService {

    private currentBreadcrumbsSubject: BehaviorSubject<BreadcrumbFragment[]>;
    public currentBreadcrumbs: Observable<BreadcrumbFragment[]>;
    subscribedTranslation : Observable<string>;
    translocoService: TranslocoService;

    constructor(){
        this.currentBreadcrumbsSubject = new BehaviorSubject<BreadcrumbFragment[]>([{urlFragment : 'null',fragmentName:'Starting...'}]);
        this.currentBreadcrumbs = this.currentBreadcrumbsSubject.asObservable();
    }
        
    resetBehaviourSubject (){
        this.currentBreadcrumbsSubject.next([{urlFragment : 'null',fragmentName:'Starting...'}]);
    }
    setBreadcrumbFragment(fragment : BreadcrumbFragment){
        let currentBehaviourSubjectValue = this.currentBreadcrumbsSubject.value;
        let fragmentIndex = currentBehaviourSubjectValue.findIndex(f=>f.urlFragment === fragment.urlFragment);
        if (fragmentIndex >-1){
            currentBehaviourSubjectValue[fragmentIndex] = fragment;
        } else {
            currentBehaviourSubjectValue.push(fragment);   
        }
        this.currentBreadcrumbsSubject.next(currentBehaviourSubjectValue);
    };
};