import { Icon } from "@frontend/shared";

export class ProgressIndicatorItem {
    
    constructor (
        
        public fillClass : string, // 'traffic-light-0'
        public progress : number, // 40,
        public icon: Icon,
        public titleTranslationKey: string, // 'common.hq'
        public titleText: string, // 'Headquarters'
        public slug?: string, // '/settings/address'
        public id?: any, // any identifier

    ){

    }

  }