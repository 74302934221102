import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, Subscription } from 'rxjs';
import { MainNavigationLink } from '../main-navigation-link.model';

/* !!!! TODO - this logic is very brittle. The select element options have a "selected" value set by parsing the URL. We should use routerLinkActive

*/
@Component({
  selector: 'multisite-vertical-menu-type-one',
  templateUrl: './vertical-menu-type-one.component.html',
  styleUrls: ['./vertical-menu-type-one.component.scss']
})
export class VerticalMenuTypeOneComponent implements OnInit {

  @Input() navigationLinks : MainNavigationLink[] = [];
  @Input() picture : string; // "https://www.argonautonline.com/wp-content/uploads/2020/10/richard-farkas-portrait-2018-004-square-head-and-shoulders-w80.jpg" 
  @Input() pictureEditable : boolean;
  @Input() nameText : string; // 'John Smith'
  @Input() nameTranslationKey : string; // 'common.nameTranslationKey'
  @Output() pictureEdit = new EventEmitter();
  routeSubscription: Subscription;

  constructor(private router : Router, private route : ActivatedRoute) { }

  navigate(targetRoute: string){
    
    this.router.navigate([targetRoute], {relativeTo:this.route});
  }

  onMobileMenuSelect(event: any): void {
    const selectedValue = event.target.value; // for some reason, we receive the value as a string 'password', not as an array '['password']'
    this.navigate(selectedValue);
    
  }

  onPictureEdit(){
    this.pictureEdit.emit();
  }

  setSelectedLink (selectedLink : MainNavigationLink){
    if (selectedLink && this.navigationLinks?.length){
      this.navigationLinks.forEach((nl,index)=>{
        if (selectedLink.routerLinkValue === nl.routerLinkValue){
          this.navigationLinks[index].selected = true;
        } else {
          this.navigationLinks[index].selected = false;
        }
      });
    }
  }

  handleRouteChange(): void {

    const fullUrlParts = this.router.routerState.snapshot.url.toLowerCase().split('/').filter(s => s);

    let currentRoute : string;
    if (this.navigationLinks?.length && fullUrlParts?.length){
      let currentNavigationLink : MainNavigationLink;
      currentNavigationLink = this.navigationLinks.find(nl => nl.routerLinkValue?.[0]?.length > 1 && fullUrlParts.includes(nl.routerLinkValue[0]));
      currentRoute = currentNavigationLink?.routerLinkValue[0];
      if (currentNavigationLink){
        this.setSelectedLink(currentNavigationLink);
      }
    }
    if(currentRoute){
      this.navigate(currentRoute);
    }

  }

  ngOnInit(): void {
    this.routeSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.handleRouteChange();
      });
      this.handleRouteChange();
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

}
