import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaImageComponent } from './media-image/media-image.component';



@NgModule({
  declarations: [
    MediaImageComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MediaImageComponent,
  ]
})
export class ImagesModule { }
