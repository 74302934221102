import { Component, OnInit, Input } from '@angular/core';

import { Media } from '@frontend/core';

@Component({
  selector: 'frontend-quotation-type-one',
  templateUrl: './quotation-type-one.component.html',
  styleUrls: ['./quotation-type-one.component.scss']
})
export class QuotationTypeOneComponent implements OnInit {

  @Input() media : Media;
  @Input() originator : string;
  @Input() originator_description : string;
  @Input() quotationText : string;
  @Input() source : string;

  constructor() { }

  ngOnInit(): void {
  }

}
