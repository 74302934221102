
  <div class="comments">
    <div *ngIf="user">
      <h3 class="mb-5" *transloco="let t">{{ t('social.leave_a_comment') }}</h3>
  
      <frontend-comment-form-type-two
        [poster]="user"
        (postComment)="doPostComment($event)"
        [clearCommentForm]="clearCommentForm"
      >
      </frontend-comment-form-type-two>
    </div>
  
<div class="card-header border-0 grey lighten-3" *ngIf="paginatedComments?.meta?.total">
<h6 class="my-auto" *transloco="let t">
  {{ t('social.'+((paginatedComments.meta.total < 6) ? paginatedComments.meta.total : 5)+'_comments',{'number':paginatedComments.meta.total}) }}
</h6>
</div>

<div *ngIf="paginatedComments?.data">
<frontend-comment-type-two
  *ngFor="let comment of paginatedComments.data"
  [comment]="comment"
>
</frontend-comment-type-two>

</div>

<!--Pagination -->
<div class="my-3">
<frontend-pagination-type-three
  *ngIf="paginatedComments?.meta && paginatedComments.meta.last_page > 1"
  [classes]="'pg-purple justify-content-center'"
  [loading]="loadingComments"
  [meta]="paginatedComments.meta"
  [lastPage]="paginatedComments.meta.last_page"
  [links]="paginatedComments.links"
  (changePage)="doGetComments($event)"
  >
</frontend-pagination-type-three>
</div>
<!--Pagination -->
</div>
