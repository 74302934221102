import { UserExtraLite } from './user-extra-lite.model';

export class Contributor {
    constructor(
        public id: number,
        public category: string, // 'creator' or 'translator' or 'reviewer'
        public type: string, // primary
        public weight: number, // should be on a scale of 0 to 100, helps with ordering
        public user: UserExtraLite,
        public created_at: string,
    ) {}
  }