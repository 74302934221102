import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuestLoginComponent } from './guest-login.component';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    GuestLoginComponent
  ],
  exports: [
    GuestLoginComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
  ]
})
export class GuestLoginModule { }
