import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Media } from '@frontend/core';
import { MediaService } from '../../media';

@Component({
  selector: 'multisite-gallery-cta',
  templateUrl: './gallery-cta.component.html',
  styleUrls: ['./gallery-cta.component.scss']
})
export class GalleryCtaComponent {

  @Input() titleTranslationKey: string // 'survey.snapshot_heading'
  @Input() titleText: string; // 'Headquarters'
  @Input() descriptionTranslationKey: string // 'common.hq'
  @Input() descriptionText: string; // 'Headquarters'
  @Input() buttonLabelTranslationKey: string // 'common.hq'
  @Input() buttonLabelText: string; // 'Headquarters'
  @Input() media: Media[];
  @Input() imageTransformations: string = 'w_350,c_fill,ar_16:9/';
  @Output() linkClicked = new EventEmitter();
  fallbackMedia: string[];

  constructor(private mediaService : MediaService) {

    this.fallbackMedia = [
      'https://res.cloudinary.com/cebt/image/upload/scenes/banners/man-in-foreground-of-crowd-midjourney-6693f048-5e73-44a1-a1a4-2478d5557046_0.jpg',
      'https://res.cloudinary.com/cebt/image/upload/scenes/banners/woman-in-foreground-of-crowd-midjourney-630c1152-d32f-4aa7-88ed-654447a424ef_3.jpg',
      'https://res.cloudinary.com/cebt/image/upload/scenes/banners/woman-in-foreground-of-crowd-midjourney-1b54e55b-03bc-4ef0-945c-cbbb370b74c6_0.jpg',
      'https://res.cloudinary.com/cebt/image/upload/scenes/banners/man-in-foreground-of-crowd-midjourney-30687212-119a-4f0a-9dcf-c852ea980be0.jpg',
    ];

  }

  imageWithTransformations(file_url:string,transformations:string){
    if(file_url){
      return this.mediaService.insertCloudinaryTransformationsIntoMediaUrl(file_url,transformations);
    }
  }

  doLinkClick (){

    this.linkClicked.emit();
  }

}
