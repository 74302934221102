<div class="container-fluid">
  <div class="course-content">
    <div class="row" *ngIf="loadingObject.course || loadingObject.auth || loadingObject.lesson">
      <multisite-loader-globe-animation-detail [width]="100" [height]="100"></multisite-loader-globe-animation-detail>
      <alert [type]="'warning'" [dismissible]="true" *ngIf=" !isAnythingLoading() && error"><span *transloco="let t" [innerHTML]="t(error)"></span></alert>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="course-lesson-holder" *ngIf="lesson">
          <frontend-video-player-type-two
              *ngFor="let video of lesson.videos"
              [video]="video"
              [classes]="'mb-3'"
            >
            </frontend-video-player-type-two>
          <div class="course-control-holder" *transloco="let t">
            <button type="button" class="btn btn-link" routerLink="../"><i class="fa-solid fa-angle-left mr-1"></i>{{t('courses.course_home_page')}}</button>
            <div class="course-control">
              <div class="btn-group-toggle" data-toggle="button">
                <button class="btn btn-light btn-sm" [disabled]="!previousLesson || isAnythingLoading()" [routerLink]="'../'+previousLesson?.slug" >
                  <i class="fa-solid fa-angles-left"></i>
                </button>
                <div>
                <label class="btn btn-light btn-sm marker">
                  <input
                  (change)="toggleWatched()"
                  [(ngModel)]="consumed"
                  [checked]="consumed"
                  type="checkbox"
                  [disabled]="isAnythingLoading()"
                  >
                    {{consumed ? t('common.watched') : t('common.mark_watched')}}
                  <span *ngIf="!consumed && !isAnythingLoading()">
                    <i class="fa-solid fa-angles-right"></i>
                  </span>
                  <span class="spinner-grow spinner-grow-sm" *ngIf="isAnythingLoading()" role="status" aria-hidden="true"></span>
                </label>
              </div>
                <button [disabled]="!nextLesson || isAnythingLoading()" [routerLink]="'../'+nextLesson?.slug" class="btn btn-light btn-sm">
                  <i class="fa-solid fa-angles-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="lesson-description">
            <div class="content">
              <h2 class="title font-weight-bold">
                {{lesson.name}}
              </h2>
              <p [innerHTML]="lesson.description" *ngIf="!shortenedDescription"></p>
              <p *ngIf="shortenedDescription && collapseDescription">{{shortenedDescription}}</p>
            </div>
            <div id="collapseDescription" [collapse]="collapseDescription" [isAnimated]="true" *ngIf="shortenedDescription">
              <div class="well well-lg card card-block card-header">
                <p [innerHTML]="lesson.description"></p>
              </div>
            </div>
            <button class="btn btn-link show-more-link" *ngIf="shortenedDescription" (click)="collapseDescription = !collapseDescription"  [ngClass]="{'expanded': collapseDescription, 'collapsed-now': !collapseDescription}"
            [attr.aria-expanded]="!collapseDescription" aria-controls="collapseDescription">
              <i class="fa-solid fa-angle-up"></i>
              <span *transloco="let t">{{collapseDescription ? t('common.show_more') : t('common.show_less')}}</span>
              <i class="fa-solid fa-angle-down"></i>
            </button>

            <hr>
          </div>
          <div class="comments-quiz-holder" *transloco="let t">
            <tabset justified="true" #lessonTabs>

              <tab [heading]="t('social.comments')">
                <div *ngIf="!isAnythingLoading()">
                  <alert
                    [type]="'warning'"
                    [dismissOnTimeout]="6000"
                    *ngIf="interculturalistRoleAlertComments"
                    ><span *transloco="let t"
                      >{{ t('authentication.interculturalist_role_required')
                      }}<a
                        [routerLink]="['/profile']"
                        routerLinkActive="router-link-active"
                        >{{ t('common.more_info') }}</a
                      ></span
                    ></alert
                  >
                  <comments-post-display-pagination-all-in-one
                    [user]="user"
                    [paginatedComments]="paginatedComments"
                    [loadingComments]="loadingObject.comments"
                    [clearCommentForm]="clearCommentForm"
                    (postComment)="postComment($event, null)"
                    (getComments)="getComments($event, null)"
                  >
                  </comments-post-display-pagination-all-in-one>
                </div>
              </tab>
              <tab [heading]="t('content.quiz')">
                <multisite-question-presenter *ngIf="questions?.length"
                [questions]="questions"
                [questionFeedbacks]="questionFeedbacks"
                [loading]="loadingObject.questionResponse"
                [error_message_translation_key]="surveyResponseError"
                (submitResponse)="doSubmitSurveyResponse($event)"
                [showTryAgainButtonWithFeedback]="showTryAgainButtonWithFeedback"
                [showNextButton]="false"
                ></multisite-question-presenter>
              </tab>
            </tabset>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <progress-indicator-card-simple *ngIf="courseProgressValue === 0 || courseProgressValue > 0"
        [titleTranslationKey]="'tracking.progress'"
        [value]="courseProgressValue"
        [unit]="'%'"
        ></progress-indicator-card-simple>
        <div class="all-lessons-list" *transloco="let t">
          <button type="button" class="btn btn-primary w-100 d-lg-none"
            (click)="isCollapsedLessons = !isCollapsedLessons" [attr.aria-expanded]="!isCollapsedLessons"
            aria-controls="collapseAllLessons">
            {{t('courses.lessons_and_notes')}} <i class="fa-solid fa-angle-down"></i>
          </button>
          <div id="collapseAllLessons" [collapse]="isCollapsedLessons" [isAnimated]="true">
            <div class="all-lessons-body">
              <tabset [justified]="true">
                <tab heading="{{t('courses.lessons')}}">
                  <multisite-playlist-accordion-text *ngIf="playlist"
                  [itemGroups]="playlist"
                  [highlightItem]="playlistHighlightItem"
                  [initiallyOpenItemGroupIndex]="currentModuleIndex"
                  (selected)="gotoLesson($event)"
                  >
                </multisite-playlist-accordion-text>
                </tab>
                <tab heading="{{t('content.notes')}}">
                  <div class="notes">
                    <div class="textarea text-break" [innerHTML]="lesson?.notes"></div>
                  </div>
                </tab>
              </tabset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<ng-template #niceInfoModal>
  <div class="modal-header" *transloco="let t">
    <h4 id="dialog-static-name" class="modal-title pull-left">{{t('authentication.login_required')}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close"
    (click)="closeModal()">
        <!-- <span aria-hidden="true" >
          <i class="fa-solid fa-xmark"></i>
        </span> -->
    </button>
</div>
<div class="modal-body" *transloco="let t">
    <p [innerHTML]="t('authentication.need_login_or_interculturalist')"></p>
    <p class="mt-3">{{t('authentication.log_in_or_register')}}</p>
    <button type="button" class="btn btn-warning btn-sm mt-2" (click)="closeModal()">{{t('common.back')}}</button>
    <button type="button" class="btn btn-primary btn-sm mt-2" (click)="navigateTo(['/login'])">{{t('common.log_in')}}</button>
    <span *ngIf="!user && !guest">
      <button type="button" class="btn btn-primary btn-sm mt-2" (click)="navigateTo(['../'])">{{t('call_to_action.join_course')}}</button>
    </span>
    <button type="button" class="btn btn-primary btn-sm mt-2" (click)="navigateTo(['/register'])">{{t('common.register')}}</button>
    
</div>
</ng-template>