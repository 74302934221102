<div class="container">

  <!-- I think the top text section is unnecessary on this page - we have breadcrumb -->
  <!-- <div class="mt-4" *ngIf="survey">
    <p>This is the feedback for:</p>
    <h1 class="h1 font-weight-bold">{{survey.name}}</h1>
    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores distinctio, illo corporis eveniet
      necessitatibus nulla sapiente est, ullam velit blanditiis, tempora inventore itaque. Accusantium nisi doloremque
      vero et, sit obcaecati voluptates, praesentium facilis nostrum reiciendis odit sunt distinctio dolor in magnam
      atque, cupiditate veniam soluta quas deleniti.</p>
  </div> -->

  <div class="row my-4">
    <div class="col-lg-4">
      <div class="card-gray-200 d-flex flex-column gap-2 mb-5">
        <p class="h5 font-weight-bold mb-0">Futured</p>
        <div class="list-group mt-2">
          <a href="#"
            class="list-group-item list-group-item-action d-flex align-items-center justify-content-between active">
            3/19/2024
            <i class="fa-solid fa-angle-right"></i>
          </a>
          <a href="#" class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">
            3/07/2024
            <i class="fa-solid fa-angle-right"></i>
          </a>
          <a href="#" class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">
            2/16/2024
            <i class="fa-solid fa-angle-right"></i>
          </a>
          <a href="#" class="list-group-item list-group-item-action d-flex align-items-center justify-content-between">
            2/02/2024
            <i class="fa-solid fa-angle-right"></i>
          </a>
          <a href="#"
            class="list-group-item list-group-item-action d-flex align-items-center justify-content-between disabled">
            1/22/2024
            <i class="fa-solid fa-angle-right"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="score-card card-gray-200 mb-5" *ngIf="score === 0 || score">
        <p class="h5">Score</p>
        <p class="h2 font-weight-bold">{{score}}<span class="text-small text-muted">%</span></p>
        <multisite-progress-indicator-slider class="status-bar my-4" [progressPercent]="score"
          [barBackground]="'var(--secondary)'" [indicatorColorStyle]="'var(--primary-light)'" [barMinWidth]="'100px'"
          [barWidth]="'50%'" [barPosition]="'absolute'">
        </multisite-progress-indicator-slider>
      </div>

      <div class="card-gray-200 d-flex flex-column gap-2 mb-5">
        <p class="h5 font-weight-bold mb-0">Strengths</p>
        <div>
          Placeholder for unranked listing of tags
        </div>
      </div>

      <div class="card-gray-200 d-flex flex-column gap-2 mb-5">
        <p class="h5 font-weight-bold mb-0">Weaknesses</p>
        <div>
          Placeholder for unranked listing of tags
        </div>
      </div>
    </div>

  </div>
</div>