import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkIconListComponent } from './link-icon-list.component';
import { ImagesModule } from '../../images';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [LinkIconListComponent],
  exports: [LinkIconListComponent],
  imports: [
    CommonModule,
    ImagesModule,
    TranslocoModule,
  ]
})
export class LinkIconListModule { }
