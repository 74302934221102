import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlaylistTextItemGroup } from '../playlist-text-item-group.model';
import { PlaylistTextItem } from '../playlist-text-item.model';


@Component({
  selector: 'multisite-playlist-accordion-text',
  templateUrl: './playlist-accordion-text.component.html',
  styleUrls: ['./playlist-accordion-text.component.scss']
})
export class PlaylistAccordionTextComponent implements OnInit {

  @Input() showItemGroupProgress : boolean; // will show 3/4 if three items in a group are marked "complete"
  @Input() showItemProgress : boolean; // will show a tick if an item is marked "complete"
  @Input() itemGroups : PlaylistTextItemGroup[];
  @Input() highlightItem : PlaylistTextItem;
  @Input() disableHighlightItem : boolean = true; // in the Course homepage we want the highlighted item to be clickable
  @Input() initiallyOpenItemGroupIndex : number;
  @Output() selected = new EventEmitter<PlaylistTextItem>();
  minMillisecondsMinuteFormat = 59000; // highest number of milliseconds before we start to round to the nearest minute

  constructor() { }

  getTotalCompleted (itemGroup: PlaylistTextItemGroup){
    return itemGroup.items.reduce((accumulator,currentValue)=> accumulator + Number(currentValue.completed ? 1:0),0);
  }
  getTotalConsumed (itemGroup: PlaylistTextItemGroup){
    return itemGroup.items.reduce((accumulator,currentValue)=> accumulator + Number(currentValue.consumed ? 1:0),0);
  }

  convertMillisecondsToMinutes (milliseconds:number){
    
    return milliseconds > this.minMillisecondsMinuteFormat ? Math.round(milliseconds/60000) : ((milliseconds/100000) % 60).toFixed(2).slice(-2);
  }
  onSelected(item:PlaylistTextItem){
    this.selected.emit(item);
  }

  ngOnInit(): void {
  }

}
