import { Component, EventEmitter, Input, OnChanges, Output, ChangeDetectorRef } from '@angular/core';
import { CiResult } from '../../ciresult.model';
import { CiResultConstants } from '../../ciresult.constants';
import { DataProcessingService } from '../../../utilities';
import { CiResultService } from '../../ciresult.service';

type Subdomain = {
  slug: string,
  rotation: number,
  score: number, // score from 0 to 100 used in progress bars
  scaleSteps: number, // the number of bars and the traffic-light colour code
  iconClasses: string,
  titleTranslationKey: string
}

type Domain = {
  slug: string,
  titleTranslationKey: string,
  score: number, // score from 0 to 100 used in progress bars
  scaleSteps: number, // the number of bars and the traffic-light colour code
  iconClasses: string,
  subdomains: Subdomain[]
}
type DomainScore = {
  score:number,
  subdomains : number[]
}
type MinimalSelectedObject = {
  slug: string,
  titleTranslationKey: string,
  score: number, // score from 0 to 100 used in progress bars
  scaleSteps: number, // the number of bars and the traffic-light colour code
  iconClasses: string,
}
type FixedDataItem = {
   slug: string,
   iconClasses: string,
   titleTranslationKey: string,
   children ? : FixedDataItem[],
}

@Component({
  selector: 'ciprofile-wheel',
  templateUrl: './wheel.component.html',
  styleUrls: ['./wheel.component.scss']
})
export class CiprofileWheelComponent implements OnChanges {

  @Input() chartSize : number = 64;
  @Input() result : CiResult;
  @Input() highlightedElementSlug : string; // 'values'
  @Output() selected = new EventEmitter<{subdomain : Subdomain, domain : Domain}>();
  @Input() showLabels : boolean = true;
  @Input() scale : string; // 'micro' - add this when displaying the chart extremely small
  domains : Domain[];
  domainOuterPath : string;
  domainOuterPathBorder : string;
  subdomainPathSomething : string;
  subdomainPathInnerBackground : string;
  barPathDefinitions: string[];
  barPathDefinitionsReversed: string[];
  subdomainRotations: number[];
  domainConstants: FixedDataItem[];
  currentSelection : MinimalSelectedObject;
  currentSubdomain : Subdomain;
  currentDomain : Domain;
  rows : { rowClasses : string, domainClasses : string[], domains : Domain[] }[];
  total : MinimalSelectedObject;
  // domainFakeScores : DomainScore[] = [
  //   {score: 66.85, subdomains : [69,74,79,59.67]},
  //   {score: 73, subdomains : [81,69,64,76]},
  //   {score: null, subdomains : [null,null,null,null]},
  //   {score: 48, subdomains : [61,39,46,50]},
  // ];
  domainScores : DomainScore[];

  constructor(
    private dataProcessingService : DataProcessingService,
    private ciResultService : CiResultService,
    private changeDetectorRef: ChangeDetectorRef
    ) { 
      
    this.domainConstants = CiResultConstants.domains;
    this.total = { slug : 'total', score: null, iconClasses : null, titleTranslationKey: null, scaleSteps : null};

    this.subdomainRotations = [0,22.5,45,67.5];
    this.subdomainPathInnerBackground = 'M31.35 119.45C24.15 137.46 20.33 156.65 20.08 176.06L88.09 176.06C88.33 165.59 90.39 155.23 94.18 145.47L31.35 119.45ZM29.50 118.68C22.20 136.94 18.33 156.39 18.08 176.06C18.08 176.72 18.07 177.39 18.07 178.06L90.07 178.06C90.07 166.51 92.35 155.07 96.76 144.40C96.77 144.39 96.77 144.38 96.77 144.38L30.25 116.83C30.00 117.44 29.75 118.06 29.50 118.68Z';
    this.subdomainPathSomething = 'M19 177C19 156.754 22.9966 136.705 30.7616 118L95 144.55C90.7292 154.838 88.5311 165.864 88.5311 177L19 177Z';
    this.domainOuterPath = 'M177 1C153.89 1 131.00 5.55 109.65 14.40C88.29 23.24 68.89 36.21 52.55 52.55C36.21 68.89 23.24 88.29 14.40 109.65C5.55 131.00 1.00 153.89 1.00 177L177 177L177 1Z';
    this.domainOuterPathBorder = 'M176.05 2.08C153.61 2.33 131.43 6.87 110.69 15.46C89.34 24.31 69.94 37.27 53.59 53.61C37.25 69.96 24.29 89.36 15.44 110.71C6.85 131.45 2.31 153.63 2.06 176.06L176.05 176.06L176.05 2.08ZM176.05 0.08C153.35 0.33 130.91 4.92 109.93 13.61C88.33 22.56 68.71 35.67 52.18 52.20C35.65 68.73 22.54 88.35 13.59 109.95C4.90 130.93 0.31 153.37 0.06 176.06C0.05 176.73 0.04 177.40 0.04 178.06L178.05 178.06L178.05 0.06C177.38 0.06 176.71 0.07 176.05 0.08Z';
    this.barPathDefinitions = [
      // null,
      'M80.16 173.90C80.56 163.83 82.43 153.87 85.69 144.34L89.48 145.63C86.33 154.80 84.54 164.38 84.15 174.06L80.16 173.90Z', // bar 1
      'M72.15 173.91C72.56 162.78 74.63 151.78 78.30 141.26L82.07 142.57C78.54 152.71 76.55 163.33 76.15 174.06L72.15 173.91Z', // bar 2
      'M64.14 173.93C64.57 161.74 66.84 149.68 70.90 138.18L74.67 139.51C70.75 150.63 68.55 162.28 68.14 174.06L64.14 173.93Z', // bar 3
      'M56.14 173.93C56.57 160.69 59.05 147.59 63.50 135.11L67.27 136.45C62.96 148.55 60.55 161.23 60.14 174.06L56.14 173.94Z', // bar 4
      'M48.13 173.94C48.57 159.64 51.26 145.50 56.10 132.04L59.86 133.39C55.16 146.46 52.55 160.18 52.13 174.07L48.13 173.94Z', // bar 5
      'M40.13 173.95C40.57 158.60 43.46 143.41 48.70 128.97L52.47 130.34C47.38 144.38 44.56 159.14 44.14 174.06L40.13 173.95Z', // bar 6
      'M32.13 173.96C32.57 157.55 35.67 141.32 41.30 125.90L45.07 127.28C39.58 142.29 36.57 158.09 36.13 174.07L32.13 173.96Z', // bar 7
      'M24.12 173.96C24.57 156.49 27.88 139.22 33.91 122.83L37.66 124.21C31.78 140.19 28.56 157.03 28.12 174.06L24.12 173.96Z', // bar 8
    ];
    this.barPathDefinitionsReversed = this.barPathDefinitions;
    this.barPathDefinitionsReversed.reverse();
  }

  concatenatedDomainsAndSubdomains (){
    return [
      ...this.domainConstants,
      ...this.domainConstants[0].children,
      ...this.domainConstants[1].children,
      ...this.domainConstants[2].children,
      ...this.domainConstants[3].children,
      ...[this.total]]
  }

  setCurrentSelection(selection : {subdomain: Subdomain, domain: Domain}){
    this.currentSelection = null;
    if (this.highlightedElementSlug && !selection?.subdomain && !selection.domain){
      selection.subdomain = this.getSubdomainFromSlug(this.highlightedElementSlug);
      selection.domain = this.getDomainFromSlug(this.highlightedElementSlug);
    }
    this.currentSubdomain = selection.subdomain ?? null;
    this.currentDomain = selection.domain ?? null;
    this.currentSelection = {
      slug: selection.subdomain ? selection.subdomain.slug : selection.domain ? selection.domain.slug : 'total',
      score: selection.domain ? selection.domain.score : this.total.score ?? 0,
      iconClasses: selection.subdomain ? selection.subdomain.iconClasses : selection.domain ? selection.domain.iconClasses : this.total.iconClasses,
      titleTranslationKey: selection.subdomain ? selection.subdomain.titleTranslationKey : selection.domain ? selection.domain.titleTranslationKey : this.total.titleTranslationKey,
      scaleSteps : null
    }
    this.currentSelection.scaleSteps = this.ciResultService.getScaleSteps(this.currentSelection.slug, this.currentSelection.score);
    this.changeDetectorRef.detectChanges(); // we had a problem that the subdomains were not updating after highlightedElementSlug changed 
  };
  doSelection(selection : {subdomain: Subdomain, domain: Domain}, $event : Event){
    this.setCurrentSelection(selection);
    this.selected.emit(selection);
    $event.stopPropagation();
  }
  getDomainFromSlug(slug:string) : Domain {
    return this.domains.find(d=>d.slug === slug);
  }
  getSubdomainFromSlug(slug:string) : Subdomain {
    return [].concat(...this.domains.map(d=>d.subdomains)).find(subdomain => subdomain.slug === slug);
  }
  selectHighlightedElement(highlightedElementSlug : string) {

    let domain : Domain = this.getDomainFromSlug(highlightedElementSlug);
    
    if(domain){
      return this.setCurrentSelection({subdomain:null,domain: domain});
    }

    let subdomain = this.getSubdomainFromSlug(highlightedElementSlug);

    if(subdomain){
      return this.setCurrentSelection({subdomain:subdomain,domain: null});
    }
    
    if(highlightedElementSlug === 'all'){

      return this.setCurrentSelection({subdomain:null,domain: null});

    }
  }

  initialiseDomains (domainConstants : FixedDataItem[], domainScores : DomainScore[], subdomainRotations: number[]){
    let domains : Domain[] = [];
    domainConstants.forEach((d,i) => {
      let currentDomainSubdomains = [];
      domainScores[i].subdomains.forEach((s,index)=>{
        let subdomain : Subdomain = {
          rotation : subdomainRotations[index],
          score : s,
          scaleSteps : this.ciResultService.getScaleSteps( d.children[index].slug,s),
          iconClasses : d.children[index].iconClasses,
          slug : d.children[index].slug,
          titleTranslationKey : d.children[index].titleTranslationKey
        };
        currentDomainSubdomains.push(subdomain);
      })
      let domain : Domain = {
        score : domainScores[i].score,
        scaleSteps : this.ciResultService.getScaleSteps(domainConstants[i].slug,this.domainScores[i].score),
        iconClasses : domainConstants[i].iconClasses,
        titleTranslationKey : domainConstants[i].titleTranslationKey,
        slug : domainConstants[i].slug,
        subdomains : currentDomainSubdomains
      };
      domains.push(domain);
    });
    return domains;
  }

  sliceDomains (array : any[], start : number, end : number = undefined){
    return array.slice(start,end);
  }
  needSomeLogicHere (){
    return false;
  }

  formatDomainScores (ciResult: CiResult, domainConstants : FixedDataItem []) : DomainScore[]{
    let domainScores : DomainScore[] = [];
    domainConstants.forEach(d => {
      let subdomainSlugs = d.children.map(s => s.slug);
      let subdomainScores = [];
      subdomainSlugs.forEach(slug => {
        subdomainScores.push(ciResult[slug])
      });
      domainScores.push({
        score: ciResult[d.slug],
        subdomains: subdomainScores
      })
    })
    return domainScores;
  }

  initialise (){

    if (this.result?.total){
      this.total = { slug: 'total', score: this.result.total, iconClasses : null, titleTranslationKey: null, scaleSteps : this.ciResultService.getScaleSteps('total',this.result.total)};
    } else {
      this.total = { slug: 'total', score: null, iconClasses : null, titleTranslationKey: null, scaleSteps : null};
    }
    this.total.scaleSteps = this.ciResultService.getScaleSteps('total',this.total.score);
    this.domainScores = this.formatDomainScores(this.result, this.domainConstants);
    this.domains = this.initialiseDomains(this.domainConstants, this.domainScores, this.subdomainRotations);
    this.rows = [
      { rowClasses : 'quarter-row', domainClasses : ['quarter top-left','quarter top-right'], domains : this.sliceDomains(this.domains, 0,2) },
      { rowClasses : 'quarter-row bottom', domainClasses : ['quarter bottom-left','quarter bottom-right'], domains : this.sliceDomains(this.domains, 2, undefined) },
    ];
    this.setCurrentSelection({subdomain: null, domain: null});
  }

  ngOnChanges(changesObject) : void {
    if (changesObject.result?.currentValue){
      this.initialise();
    }
    if (changesObject.highlightedElementSlug?.currentValue){
      this.selectHighlightedElement(this.highlightedElementSlug);
    }
  }

}
