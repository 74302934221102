import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { Page } from '@frontend/core';

import { environment } from 'apps/toolbox/src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PageService {

  appKey : string;
  
  pagesByCategoryAndType = {
    // child property: category, gradchild property: type
    // pages are always in an array
    /* 'privacy':{'pages':[arrayOfPagesWithoutType],'notice':[arrayContaningOneSinglePage]} */
  };

  constructor(private http : HttpClient) {
    this.appKey = environment.appKey;
   }

  generateBlocks (page) {
    if (page.category == this.appKey && page.type == 'about'){ // TODO - in future we'll get the blocks from the back end, not generate them here
      page.template_type = 'blocks';
      page.blocks = JSON.parse(page.body);
    }
    return page;
  }

  cachePages (pages : Page[], category : string,  type : string){
    if (!pages || !category){return;};
    let transformedPages : Page[] = [];
    pages.forEach(p => {
      p.published_at = p.published_at ? new Date(p.published_at) : null;
      p.updated_at = p.updated_at ? new Date(p.updated_at) : null;
      p = this.generateBlocks(p);
      transformedPages.push(p);
    });
    if (!this.pagesByCategoryAndType[category]){
      this.pagesByCategoryAndType[category] = {};
    }
    this.pagesByCategoryAndType[category][type] = transformedPages;
    return transformedPages;
  };

  getPagesByCategoryAndType (category: string, type : string){
    if (!category) { throw 'category is required';};
    let url = 'api/v1/pages?category='+category+'&type=';
    if (type) {
      url+=type;
    };
    if (this.pagesByCategoryAndType[category] && this.pagesByCategoryAndType[category][type]){
      return of(this.pagesByCategoryAndType[category][type]);
    };
    return this.http.get<{'data': Page[]}>(url)
        .pipe(
          map(response =>{
            if (response && response.data ){
              return this.cachePages(response.data,category,type);
            };
          })
        )
  };
};