<div class="container" *transloco="let t">
  <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
  </multisite-loader-globe-animation-detail>

  <div class="my-5" *ngIf="hub">
    <div class="row">
      <div class="col-lg-8">
        <!-- OWNER -->
        <div class="editable-row">
          <p class="title">
            {{
            hub.owners.length === 1
            ? t('common.owner')
            : t('common.owners')
            }}
          </p>
          <p>{{ t('hubs.owner_role_description') }}</p>
          <div *ngIf="hub?.owners.length" class="mt-3">
            <div class="contributors">
              <span *ngFor="let owner of hub.owners">
                <multisite-avatar-pill (selected)="removeRoleHolder('owners', owner.email)"
                  [removalMode]="unlockUserRole['owners']" [fallbackImageUrl]="
                'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'
              " [fallbackTranslationKey]="'social.verified_user'" [imageUrl]="owner.picture" [text]="
                owner.pre_honorific +
                ' ' +
                owner.fname +
                ' ' +
                owner.lname +
                '
          ' +
                owner.post_honorific
              "></multisite-avatar-pill>
              </span>

              <button (click)="toggleNewRoleHolderForm('owners')" class="btn btn-pill" [disabled]="loadingRoleUpdate || !isHubOwner()">
                <div class="icon">
                  <i class="fa-solid fa-arrows-rotate"></i>
                </div>
                {{
                showNewRoleForm.owners
                ? t('common.cancel')
                : t('common.replace')
                }}
              </button>
            </div>

          </div>

          <multisite-email-input-form *ngIf="showNewRoleForm.owners" [labelTextTranslationKey]="
          'hubs.replace_owner_instruction'
        " (emailSubmitted)="addRoleHolder('owners', $event)" [errorTranslationKey]="error"
            [loading]="loadingRoleUpdate" ngClass="w-100">
          </multisite-email-input-form>
        </div>
        <!-- End of OWNER -->

        <!-- MANAGER -->
        <div class="editable-row">
          <p class="title">
            {{
            hub.managers.length === 1
            ? t('common.manager')
            : t('common.managers')
            }}

            <button class="btn btn-sm btn-primary primary-dark" (click)="toggleRoleLock('managers')"
              *ngIf="hub?.managers.length">
              <span *ngIf="unlockUserRole['managers']">
                <i class="fa-solid fa-lock-open mr-2"></i>{{t('common.lock')}}
              </span>
              <span *ngIf="!unlockUserRole['managers']">
                <i class="fa-solid fa-lock mr-2"></i>{{t('common.unlock')}}
              </span>
            </button>
          </p>
          <p>{{ t('hubs.manager_role_description') }}</p>
          <div *ngIf="hub?.managers.length" class="mt-3">
            <div class="contributors">
              <span *ngFor="let manager of hub.managers">
                  <multisite-avatar-pill (selected)="removeRoleHolder('managers', manager.email)" [cursorNone]="!unlockUserRole['managers']"
                    [removalMode]="unlockUserRole['managers']" [fallbackImageUrl]="
                  'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'
                " [fallbackTranslationKey]="'social.verified_user'" [imageUrl]="manager.picture" [text]="
                  manager.pre_honorific +
                  ' ' +
                  manager.fname +
                  ' ' +
                  manager.lname +
                  '
            ' +
                  manager.post_honorific
                "></multisite-avatar-pill>
              </span>

              <button (click)="toggleNewRoleHolderForm('managers')" class="btn btn-pill" [disabled]="loadingRoleUpdate">
                <div class="icon">
                  <i class="fa-solid fa-plus"></i>
                </div>
                {{
                showNewRoleForm.managers
                ? t('common.cancel')
                : t('common.new')
                }}
              </button>
            </div>

          </div>

          <multisite-email-input-form *ngIf="showNewRoleForm.managers" [labelTextTranslationKey]="
          'common.enter_person_email_address'
        " (emailSubmitted)="addRoleHolder('managers', $event)" [errorTranslationKey]="error"
            [loading]="loadingRoleUpdate" ngClass="w-100">
          </multisite-email-input-form>
          <alert [type]="'warning'" [dismissible]="true" *ngIf="error && unlockUserRole['managers']">
            <span *transloco="let t">{{ t(error) }}</span>
          </alert>
        </div>
        <!-- End of MANAGER -->

        <!-- CONTACT PERSON -->
        <div class="editable-row">
          <p class="title">
            {{
            hub.contact_persons.length === 1
            ? t('common.contact_person')
            : t('common.contact_persons')
            }}

            <button class="btn btn-sm btn-primary primary-dark" (click)="toggleRoleLock('contact_persons')"
              *ngIf="hub?.contact_persons.length">
              <span *ngIf="unlockUserRole['contact_persons']">
                <i class="fa-solid fa-lock-open mr-2"></i>{{t('common.lock')}}
              </span>
              <span *ngIf="!unlockUserRole['contact_persons']">
                <i class="fa-solid fa-lock mr-2"></i>{{t('common.unlock')}}
              </span>
            </button>
          </p>
          <p>{{ t('hubs.contact_person_role_description') }}</p>
          <div class="mt-3">
            <div class="contributors">
              <span *ngFor="let contact_person of hub.contact_persons">
                <multisite-avatar-pill
                  (selected)="removeRoleHolder('contact_persons', contact_person.email)"
                  [cursorNone]="!unlockUserRole['managers']"
                  [removalMode]="unlockUserRole['contact_persons']"
                  [fallbackImageUrl]="'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'" [fallbackTranslationKey]="'social.verified_user'"
                  [imageUrl]="contact_person.picture"
                  [text]="contact_person.pre_honorific +' ' +contact_person.fname +' ' +contact_person.lname +' ' +contact_person.post_honorific"
                ></multisite-avatar-pill>
              </span>

              <button (click)="toggleNewRoleHolderForm('contact_persons')" class="btn btn-pill"
                [disabled]="loadingRoleUpdate">
                <div class="icon">
                  <i class="fa-solid fa-plus"></i>
                </div>
                {{
                showNewRoleForm.contact_persons
                ? t('common.cancel')
                : t('common.new')
                }}
              </button>
            </div>

          </div>

          <multisite-email-input-form *ngIf="showNewRoleForm.contact_persons" [labelTextTranslationKey]="
          'common.enter_person_email_address'
          " (emailSubmitted)="addRoleHolder('contact_persons', $event)" [errorTranslationKey]="error"
            [loading]="loadingRoleUpdate" ngClass="w-100">
          </multisite-email-input-form>
          <alert [type]="'warning'" [dismissible]="true" *ngIf="error && unlockUserRole['contact_persons']">
            <span *transloco="let t">{{ t(error) }}</span>
          </alert>
        </div>
        <!-- End of CONTACT PERSON -->

        <!-- HUB TRAINERS -->
        <div class="editable-row" *ngIf="isAdmin()">
          <p class="title">
            {{
            hub.hub_trainers?.length === 1
            ? t('common.hub_trainer')
            : t('common.hub_trainers')
            }}
    
            <button class="btn btn-sm btn-primary primary-dark" (click)="toggleRoleLock('hub_trainers')"
              *ngIf="hub?.contact_persons.length">
              <span *ngIf="unlockUserRole['hub_trainers']">
                <i class="fa-solid fa-lock-open mr-2"></i>{{t('common.lock')}}
              </span>
              <span *ngIf="!unlockUserRole['hub_trainers']">
                <i class="fa-solid fa-lock mr-2"></i>{{t('common.unlock')}}
              </span>
            </button>
          </p>
          <p>{{ t('hubs.hub_trainer_role_description') }}</p>
            <div class="contributors" class="mt-3">
              <span *ngFor="let hub_trainer of hub.hub_trainers">
                <multisite-avatar-pill
                  (selected)="removeRoleHolder('hub_trainers', hub_trainer.email)"
                  [removalMode]="unlockUserRole['hub_trainers']"
                  [fallbackImageUrl]="'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'"
                  [fallbackTranslationKey]="'social.verified_user'"
                  [cursorNone]="!unlockUserRole['hub_trainers']"
                  [imageUrl]="hub_trainer.picture"
                  [text]="
                    hub_trainer.pre_honorific +
                    ' ' +
                    hub_trainer.fname +
                    ' ' +
                    hub_trainer.lname +
                    ' ' +
                    hub_trainer.post_honorific
                  "></multisite-avatar-pill>
              </span>
    
    
              <button (click)="toggleNewRoleHolderForm('hub_trainers')" class="btn btn-pill" [disabled]="loadingRoleUpdate">
                <div class="icon">
                  <i class="fa-solid fa-plus"></i>
                </div>
                {{
                showNewRoleForm.hub_trainers
                ? t('common.cancel')
                : t('common.new')
                }}
              </button>
            </div>
    
    
          <multisite-email-input-form *ngIf="showNewRoleForm.hub_trainers" [labelTextTranslationKey]="
              'common.enter_person_email_address'
            " (emailSubmitted)="addRoleHolder('hub_trainers', $event)" [errorTranslationKey]="error"
            [loading]="loadingRoleUpdate" ngClass="w-100">
          </multisite-email-input-form>
          <alert [type]="'warning'" [dismissible]="true" *ngIf="error && unlockUserRole['hub_trainers']">
            <span *transloco="let t">{{ t(error) }}</span>
          </alert>
        </div>
        <!-- End of HUB TRAINERS -->
    
        <!-- Event and programme trainers -->
        <div class="editable-row" *ngIf="isAdmin()">
          <h3 class="title">
            {{
            hub.event_and_programme_trainers?.length === 1
            ? t('hubs.event_and_programme_trainer')
            : t('hubs.event_and_programme_trainers')
            }}
          </h3>
          <p>{{ t('hubs.event_and_programme_trainer_role_description') }}</p>
          <p class="text-warning" *ngIf="unlockUserRole['event_and_programme_trainers']">{{
            t('hubs.event_and_programme_trainer_management_removal_instruction') }}</p>
          <p>{{ t('hubs.event_and_programme_trainer_management_adding_instruction') }}</p>
    
          <button class="btn btn-sm btn-primary primary-dark" (click)="toggleRoleLock('event_and_programme_trainers')"
            *ngIf="hub?.event_and_programme_trainers?.length">
            <span *ngIf="unlockUserRole['event_and_programme_trainers']">
              <i class="fa-solid fa-lock-open mr-2"></i>{{t('common.lock')}}
            </span>
            <span *ngIf="!unlockUserRole['event_and_programme_trainers']">
              <i class="fa-solid fa-lock mr-2"></i>{{t('common.unlock')}}
            </span>
          </button>
    
    
          <div class="contributors">
            <span *ngFor="let event_and_programme_trainer of hub.event_and_programme_trainers"
              [ngClass]="{ disabled: !unlockUserRole['event_and_programme_trainers'] }">
              <multisite-avatar-pill
                (selected)="removeRoleHolder('event_and_programme_trainers', event_and_programme_trainer.email)"
                [removalMode]="unlockUserRole['event_and_programme_trainers']"
                [fallbackImageUrl]="'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'"
                [fallbackTranslationKey]="'social.verified_user'"
                [cursorNone]="!unlockUserRole['event_and_programme_trainers']"
                [imageUrl]="event_and_programme_trainer.picture"
                [text]="
                    event_and_programme_trainer.pre_honorific +
                    ' ' +
                    event_and_programme_trainer.fname +
                    ' ' +
                    event_and_programme_trainer.lname +
                    ' ' +
                    event_and_programme_trainer.post_honorific
                  "></multisite-avatar-pill>
          </span>
          </div>
          <alert [type]="'warning'" [dismissible]="true" *ngIf="error && unlockUserRole['event_and_programme_trainers']">
            <span *transloco="let t">{{ t(error) }}</span>
          </alert>
        </div>
        <!-- End of event and programme trainers -->
    
        <!-- Hub client trainers -->
        <div class="editable-row" *ngIf="isAdmin()">
          <h3 class="title">
            {{
            hub.hub_client_trainers?.length === 1
            ? t('hubs.hub_client_trainer')
            : t('hubs.hub_client_trainers')
            }}
          </h3>
          <p>{{ t('hubs.hub_client_trainer_role_description') }}</p>
          <p class="text-warning" *ngIf="unlockUserRole['hub_client_trainers']">{{
            t('hubs.hub_client_trainer_management_removal_instruction') }}</p>
          <p>{{ t('hubs.hub_client_trainer_management_adding_instruction') }}</p>
    
          <button class="btn btn-sm btn-primary primary-dark" (click)="toggleRoleLock('hub_client_trainers')"
            *ngIf="hub?.hub_client_trainers?.length">
            <span *ngIf="unlockUserRole['hub_client_trainers']">
              <i class="fa-solid fa-lock-open mr-2"></i>{{t('common.lock')}}
            </span>
            <span *ngIf="!unlockUserRole['hub_client_trainers']">
              <i class="fa-solid fa-lock mr-2"></i>{{t('common.unlock')}}
            </span>
          </button>
          <div class="contributors">
            <span *ngFor="let hub_client_trainer of hub.hub_client_trainers">
              <multisite-avatar-pill (selected)="removeRoleHolder('hub_client_trainers', hub_client_trainer.email)"
                [removalMode]="unlockUserRole['hub_client_trainers']"
                [fallbackImageUrl]="'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'"
                [fallbackTranslationKey]="'social.verified_user'"
                [cursorNone]="!unlockUserRole['hub_client_trainers']"
                [imageUrl]="hub_client_trainer.picture"
                [text]="
                    hub_client_trainer.pre_honorific +
                    ' ' +
                    hub_client_trainer.fname +
                    ' ' +
                    hub_client_trainer.lname +
                    ' ' +
                    hub_client_trainer.post_honorific
                  "></multisite-avatar-pill>
            </span>
          </div>
          <alert [type]="'warning'" [dismissible]="true" *ngIf="error && unlockUserRole['hub_client_trainers']">
            <span *transloco="let t">{{ t(error) }}</span>
          </alert>
    
        </div>
        <!-- End of Hub client trainers -->
      </div>

      <div class="col-lg-4">
        <div class="flex-column align-items-center justify-content-center w-100 h-100">
          <multisite-link-type-six *ngFor="let link of links"
            [imageTransformations]="'w_100,c_thumb/'"
            [titleText]="link.label"
            [description]="link.description"
            [imageUrl]="link.media?.base_url"
            [media]="link.media"
            [cta_label]="link.cta_label"
            [cta_type]="link.cta_type"
            [url]="link.url"
          >
          </multisite-link-type-six>
        </div>
      </div>
    </div>

    
    

    <p *ngIf="!loading && !hub">{{ t('common.not_found') }}</p>
  </div>
</div>