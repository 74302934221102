import { FlagExtraLite } from "../flags";

export class Language {
    constructor(
        public id : number, // 78
        public iso : string, // de // also known as languageKey or iso_639_1
        public name :string,  // 'LANGUAGE.DE
        public flag : FlagExtraLite,
        public flag_path?: string, // 'app/images/flags/DE.png' // deprecated
        public flag_class?: string, // 'app/images/flags/DE.png' // deprecated
    ) {}
  }