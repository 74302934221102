<div class="text-center my-5" *ngIf="loading">
  <!-- <mdb-spinner spinnerType="big" spinnerColor="yellow"></mdb-spinner> -->
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<frontend-page-display-type-one
  *ngIf="pages?.length && pages[0].template_type !== 'blocks'"
  [page]="pages[0]"
  [showVersion]="true"
  [showPublishedDate]="true"
  [showUpdatedDate]="false"
>
</frontend-page-display-type-one>

  <frontend-page-display-type-two
    *ngIf="pages?.length && pages[0].template_type === 'blocks'"
    [page]="pages[0]"
    [showVersion]="false"
    [showPublishedDate]="false"
    [showUpdatedDate]="false"
  >
  </frontend-page-display-type-two>