export const AuthConstants = {

    /* Regex */
    // Angular's regex validators don't work!!!! Validators are very unreliable when used in forms. Angular offers various ways to implement pattern validation and they all fail in some scenario. Build your own!

    // Test expressions on regex101.com
   passwordRegexPattern : /^(?=.*[A-Za-z])(?=.*[\d])[A-Za-z \u00C0-\u00ff\s!@#$%\^&*)(+\-=._\d]{8,}$/ // NOTE!! Angular will at the ^ at the start and the $ at the end
   /*
    Explanation:
    ^ start of string
    ?=. must include
    * 1 or more
    (?=.*[A-Za-z]) must include one or more letters, lowercase or uppercase
    (?=.*\d) must include one or more numerals 0-9
    [A-Za-z \u00C0-\u00ff\s!@#$%\^&*)(+\-=._\d] things that are allowed
    A-Za-z letters, lowercase and uppercase
      spaces
    \u00C0-\u00ff\s certain non-Latin characters
    !@#$%\^&*)(+\-=._ these special characters
    \d numerals
    {8,} the string has a minimum length of 8 and no maximum length
    $ end of the string
   */

}