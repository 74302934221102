import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserAccountConsentComponent } from './user-account-consent/user-account-consent.component';

const routes: Routes = [
  { path: '', component: UserAccountConsentComponent, data: { label: 'Consent', translationKey: 'account.consent', labelSource: null} }, // Default route
  { path: ':slugs', component: UserAccountConsentComponent } // Parameterized route
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsentRoutingModule {}