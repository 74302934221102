import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticityVisualisationComponent } from './visualisation/authenticity-visualisation.component';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    AuthenticityVisualisationComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ],
  exports: [
    AuthenticityVisualisationComponent
  ]
})
export class AuthenticityModule { }
