import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  // Translation,
  // TranslocoLoader,
  provideTransloco,
  TranslocoModule,
} from '@jsverse/transloco';
import { Injectable, isDevMode, NgModule } from '@angular/core';
import { concat } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader {
  constructor(private http: HttpClient) {}

  // getTranslation(lang: string) { // this logic is now moved to TranslocoCustomLoader
  // }
}

@NgModule({
  // imports: [ CommonModule ],
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['en', 'fr'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
        fallbackLang: 'en',
        missingHandler: {
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true,
        },
      },
    }),
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
