import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ttt-design03',
  templateUrl: './design03.component.html',
  styleUrls: ['./design03.component.scss']
})
export class Design03Component implements OnInit {

  showWarningAlert : boolean = true;
  showSuccessAlert : boolean = true;
  pauseLoop : boolean = false;


  constructor() { }


  onClosed (alertType:string){
    if (alertType=== 'success'){
      this.showSuccessAlert = false;
      if (!this.pauseLoop){
        setTimeout(()=>{
          this.showSuccessAlert = true;
        },5000)
      }
    };
    if (alertType=== 'warning'){
      this.showWarningAlert = false;
        setTimeout(()=>{
          this.showWarningAlert = true;
        },5000)
    };
  }

  toggleLoop (){
    this.pauseLoop = !this.pauseLoop;
  }

  ngOnInit(): void {
  }

}
