import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperTypeOneComponent } from './stepper-type-one/stepper-type-one.component';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    StepperTypeOneComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
  ],
  exports: [
    StepperTypeOneComponent
  ]
})
export class SteppersModule { }
