import { Component, OnInit, Input } from '@angular/core';
import { UserExtraLite } from '../../../auth';

@Component({
  selector: 'multi-rater-list-card',
  templateUrl: './multi-rater-panel.component.html',
  styleUrls: ['./multi-rater-panel.component.scss']
})
export class MultiRaterPanelComponent implements OnInit {

  @Input() raters : UserExtraLite[] = [];

  constructor() {

    this.raters = [
      {
        'id': null,
        'fname': 'Kimi',
        'lname': 'Sagato',
        'pre_honorific': '',
        'post_honorific': '',
        'name_full_honorific': null,
        'picture': 'https://randomuser.me/api/portraits/men/98.jpg',
        'slug': null,
      },
      {
        'id': null,
        'fname': 'June',
        'lname': 'Bird',
        'pre_honorific': '',
        'post_honorific': '',
        'name_full_honorific': null,
        'picture': 'https://randomuser.me/api/portraits/women/44.jpg',
        'slug': null,
      },
      {
        'id': null,
        'fname': 'Mario',
        'lname': 'Fratelli',
        'pre_honorific': '',
        'post_honorific': '',
        'name_full_honorific': null,
        'picture': 'https://randomuser.me/api/portraits/men/20.jpg',
        'slug': null,
      }
    ]

   }

  ngOnInit(): void {
  }

}
