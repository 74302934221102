<main>
  <div class="container p-5" *transloco="let t">

    <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
    </multisite-loader-globe-animation-detail>

    <div class="card promo-card" *ngFor="let programme of programmesAsContent">
      <a (click)="navigateTo(programme.slug, $event)">
      <div class="row">
        <div class="col-md-4">
          <a (click)="navigateTo(programme.slug, $event)">
          <img class="img-fluid rounded-start w-100" *ngIf="programme.media"
            [src]="programme.media.asset_url && thumbnailTransformations ? programme.media.base_url + thumbnailTransformations + programme.media.asset_url : programme.media.file_url"
            [alt]="programme.media.description">
          </a>
        </div>
        <div class="col-md-8">
          <div class="card-body p-0">
            <a (click)="navigateTo(programme.slug, $event)">
              <h5 class="card-title mt-4 mt-md-0">{{programme.title}}</h5>
            </a>  
            <p class="card-text text-muted mt-2">{{programme.description}}</p>
            <div class="badges mt-2">
              <span *ngFor="let tag of programme.tags" class="m-1" [ngClass]=" [tag.type == 'topic' ? 'badge-info' : 'badge-primary', 'badge badge-pill']">{{tag.name}}</span>
              <!-- <span class="badge badge-pill badge-more" (click)="action()">
                  <i class="fa-solid fa-angle-right"></i>
              </span> -->

          </div>
            <div class="col-12 my-2">
              <div class="col-12 my-2" *ngIf="programme.rating">
                <span style="margin:15px 0;"> {{roundRating(programme.rating)}} </span>
                <rating ngClass="text-warning" [(ngModel)]="programme.rating" [max]="5" [readonly]="true"></rating>
              </div>
            </div>
            <p class="card-text">
              <small class="text-muted">{{t('common.updated')}} {{programme.date | date : 'longDate'}}</small>
            </p>
            <div class="mt-4">
              <button class="btn btn-primary d-block d-sm-inline w-sm-100"
                [routerLink]="['./'+programme.slug]">{{t(programme.cta_translationKey)}}</button>
            </div>
          </div>
        </div>
      </div>
    </a>
    </div>

          <!--Pagination -->
          <div class="my-3">
            <frontend-pagination-type-three
            *ngIf="paginatedProgrammes?.meta && paginatedProgrammes.meta.last_page > 1"
            [classes]="'pg-purple justify-content-center'"
            [loading]="loading"
            [meta]="paginatedProgrammes.meta"
            [lastPage]="paginatedProgrammes.meta.last_page"
            [links]="paginatedProgrammes.links"
            (changePage)="doGetContent($event)"
            >
            </frontend-pagination-type-three>
        </div>
            <!--Pagination -->



    <p *ngIf="!loading && !programmesAsContent?.length">{{t('common.no_results')}}</p>


  </div>
</main>