import { Component, input } from '@angular/core';
import { MediaService } from '../../../media';
import { MetaText } from '../../../metaText.model';

@Component({
  selector: 'multisite-icon-text-list',
  templateUrl: './icon-text-list.component.html',
  styleUrl: './icon-text-list.component.scss'
})
export class IconTextListComponent {

  fallbackIcon = input<string>('fa-solid fa-check');
  list = input<MetaText[]>([]);
  titleText = input<string | undefined>();
  titleTranslationKey = input<string | undefined>();
  titleClasses = input<string>('h4 font-weight-bold');
  cardClasses = input<string>('border-0 p-0'); // 'card-gray-200'
  cloudinary_base_url : string; // 'https://res.cloudinary.com/cebt/image/upload/'
  
  constructor(private mediaService: MediaService) {

    this.cloudinary_base_url = this.mediaService.cloudinary_base_url;

  }

}
