import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserAccountDropdownComponent } from './user-account-dropdown.component';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';


@NgModule({
  declarations: [UserAccountDropdownComponent],
  exports: [UserAccountDropdownComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule
  ]
})
export class UserAccountDropdownModule { }
