export class UserExtraLiteAdmin { // same as UserExtraLite but also has email
    constructor(
        public id: number,
        public email: number,
        public fname: string,
        public lname: string,
        public pre_honorific : string,
        public post_honorific : string,
        public name_full_honorific : string,
        public picture: string,
        public slug: string,
        public job_title?: string,
        public summary?: string,
        public bio?: string,
    ) {}
  }