import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HubService } from '../hub.service';
import { ChangeDetectorRef } from '@angular/core';
import { Hub } from '../hub.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LinksService } from '@frontend/shared';
import { Link } from '@frontend/core';
import { Subscription } from 'rxjs';


@Component({
  selector: 'lib-hub-new',
  templateUrl: './hub-new.component.html',
  styleUrls: ['./hub-new.component.scss']
})
export class HubNewComponent implements OnInit {

  newHubForm: FormGroup;
  linksSubscription: Subscription;
  hubSubscription: Subscription;
  @Input() errorMessage: string;
  @Input() successMessage: string;
  loading: boolean;
  ownerIsOtherUser: boolean;
  links: Link[] = [];

  constructor(
    private hubService : HubService,
    private changeDetectorRef : ChangeDetectorRef,
    private router : Router,
    private route : ActivatedRoute,
    private linksService: LinksService,

  ) { }

  onSubmit (){
    let hubData = {
      'name':this.newHubForm.controls.name.value,
      'type':this.newHubForm.controls.hubType.value,
      'owner_email':this.newHubForm.controls.ownerEmail.value,
      'metatexts': [
        {'en':{
          's':this.newHubForm.controls.strapline.value,
          'l':this.newHubForm.controls.description.value
        }}
      ]
    };
    this.loading = true;
    this.hubSubscription = this.hubService.storeNewHub(hubData).subscribe(response =>{
      this.loading = false;
      let newHub : Hub = response;
      this.successMessage = 'common.success';
      setTimeout(()=>{
        this.router.navigate(['../'+newHub.slug], {relativeTo : this.route});
      },5000)
    },
    error => {
      this.loading = false;
      this.errorMessage = error;
    }
    )
  }
  isControlInvalid(controlName: string): boolean {
    const control = this.newHubForm.get(controlName);
    return control.invalid && (control.touched || control.dirty);
  }
  
  updateConditionalValidators (){
    this.newHubForm.get('ownerEmail').setValidators(this.getOwnerEmailValidators());
  }
  getOwnerEmailValidators() {
    if (this.ownerIsOtherUser) {
      return [Validators.required,Validators.email];
    }
    return [];
  }
  getLinks(){
    let linksRequest = [
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
    ]
    this.linksSubscription = this.linksService.getDynamicLinks(linksRequest).subscribe(links =>{
      this.links = links;
    })
  }
  ngOnInit(): void {
    this.newHubForm = new FormGroup({
      name: new FormControl(null, [Validators.required,
        Validators.minLength(2)]),
      hubType: new FormControl( null, [Validators.required]),
      strapline: new FormControl(null, [Validators.required,Validators.minLength(10)]),
      description: new FormControl('', [
        Validators.required,
        Validators.minLength(100)
      ]),
      owner: new FormControl('me', [Validators.required]),
      ownerEmail: new FormControl(null, this.getOwnerEmailValidators()),
    });
    this.newHubForm.controls.owner.valueChanges.subscribe((value) => {
      this.ownerIsOtherUser = value == 'other' ? true:false;
      this.changeDetectorRef.detectChanges();
      this.updateConditionalValidators();
    });
    this.getLinks();
  }
  ngOnDestroy () : void {
    if (this.hubSubscription){
      this.hubSubscription.unsubscribe();
    }
    if (this.linksSubscription){
      this.linksSubscription.unsubscribe();
    }
  }

}
