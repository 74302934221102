import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    UrlTree,
    CanActivateChild
  } from '@angular/router';
  import { Injectable } from '@angular/core';
  import { Observable} from 'rxjs';
  import { ApplicationService } from '@frontend/common';
  
  @Injectable({ providedIn: 'root' })
  export class HasNoSubdomainGuard implements CanActivateChild {
    constructor(private router: Router, private applicationService : ApplicationService) {}
    canActivateChild(
      route: ActivatedRouteSnapshot,
      router: RouterStateSnapshot
    ): boolean | Promise<boolean> | Observable<boolean | UrlTree> {

      const subdomain = this.applicationService.getSubdomain();
      if (subdomain && subdomain !== 'www'){
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }
  }