  export class CloudinaryMediaAsset {
    id: number; // 145,
    medially_type: string; // "App\\Models\\Tool",
    medially_id: number; // 23,
    file_url: string; // "https://res.cloudinary.com/cebt/image/upload/v1667242551/concepts/coaching-circles-podium.jpg",
    file_name: string; // "concepts/coaching-circles-podium.jpg",
    description: string; // "Businessperson stands by podium in front of abstract circles",
    credits: string; // "Richard Farkas",
    category: string; // "intercultural",
    type: string; // "concepts",
    file_type?: string; // "image",
    permission?: string; // null,
    size?: number; // 734560,
    uploader_id?: number; // 1,
    created_at?: string; // "2022-10-31T13:00:03.000000Z",
    updated_at?: string; // "2022-10-31T13:00:03.000000Z"
    // we add the following here in the frontend:
    base_url?: string; // https://res.cloudinary.com/cebt/image/upload/
    transformations_url?: string; // c_thumb,w_200,g_face/
    asset_url?: string; // v1611931415/Culture/Tools/red-shoes.jpg
  }