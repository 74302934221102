import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { Media } from '@frontend/core';


@Component({
  selector: 'frontend-link-type-five',
  templateUrl: './link-type-five.component.html',
  styleUrls: ['./link-type-five.component.scss']
})
export class LinkTypeFiveComponent implements OnInit, OnChanges {

  // Good for internal links including people-links, where no Link model is required

  @Input() titleText : string;
  @Input() titleTextTranslation : string;
  @Input() subtitleText : string; // optional
  @Input() subtitleTextTranslation : string; // optional
  @Input() description : string; // optional
  @Input() date : Date;
  @Input() dateAsTimeAgo : boolean; // NOT IN USE yet - will be in use when a Moment-type library is installed.
  @Input() imageUrl : string;
  @Input() media : Media;
  @Input() descriptionTruncateLength : number = 110;
  @Output() linkClicked = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }

  doLinkClick (){
    this.linkClicked.emit();
  }

  ngOnChanges(changesObject): void {
    if (changesObject?.description?.currentValue?.length){
      if (changesObject.description.currentValue.length > this.descriptionTruncateLength){
        this.description = this.truncateText(changesObject.description.currentValue,this.descriptionTruncateLength-10);
      };
    }
  }

  truncateText(sourceText, length){
    let truncatedText = sourceText.substr(0, length);
    return truncatedText.trim()+'...';
  }

}
