<div class="container" *transloco="let t">
  <div class="row">
   
    <div class="col-lg-8 p-2">
      <p *ngIf="!loading && !hub.brands?.length">{{t('common.no_results')}}</p>
      <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
      </multisite-loader-globe-animation-detail>

      <div class="brand-list-item" *ngFor="let brand of hub?.brands">
        <div class="card">
          <div class="card-body">
            <p class="h3 font-weight-bold">
              {{brand.name}}
            </p>
            <!-- <img *ngIf="brand.logo_banner" [src]="imageWithTransformations(brand.logo_banner,'w_150,c_fill/')
                " [alt]="brand.name+' logo'" />
            <img *ngIf="brand.logo_square && !brand.logo_banner" [src]="imageWithTransformations(brand.logo_square,'w_150,c_fill/')
                " [alt]="brand.name+' logo'" /> -->
            <!-- <span class="text-small">
              {{brand.colours |json}}
            </span> -->
            <!-- <div class="d-flex flex-row gap-1 align-items-center my-2">
              <div class="color-square"
                style="border-color: {{brand.colours[0].hex}};background:{{brand.colours[1].hex}}; color:{{brand.colours[2].hex}};">
              </div>
              Brand {{brand.colours[1].name}} color
            </div> -->


            <p class="d-flex align-items-center gap-2 mt-2">
              <!-- {{t('branding.example_address')}}: -->
              <i class="fa-solid fa-globe"></i>
              <a class=" align-items-center text-brek" [href]="'https://'+brand.slug+'.culturequestion.com'"
                target="_blank">
                <span class="url-example-mark">
                  {{brand.slug}}
                </span>
                .culturequestion.com</a>
            </p>
            
            <p class="info-item mt-4">{{t('tracking.completion')}}: <span class="value">{{brand.completion}}%</span> </p>
            <div class="d-flex flex-row gap-2 justify-content-end">
              <button class="btn-outline-primary" [disabled]="brand.category === 'primary'"
                (click)="setAsPrimary(brand)" *ngIf="hub.brands.length > 1">
                <span *ngIf="brand.category !== 'primary'">{{t('branding.set_primary')}}</span>
                <span *ngIf="brand.category === 'primary'"><i
                    class="fa-solid fa-check mr-2"></i>{{t('branding.primary_brand')}}</span>
              </button>
              <button class="btn btn-primary align-self-end"
                [routerLink]="[brand.slug]">{{t('common.manage')}}</button>
            </div>
          </div>
        </div>
      </div>

    </div>
     <div class="col-lg-4 p-2">
      <button class="btn btn-primary w-100" [routerLink]="['new']" routerLinkActive="router-link-active">
        {{t('branding.new_brand')}} <i class="pl-2 fa-solid fa-plus"></i>
      </button>
      <!-- For promo items as on hub listing page you can put there Link Type Six Component -->
      <div class="my-2">
        <multisite-link-type-six *ngFor="let link of links"
          [imageTransformations]="'w_100,c_thumb/'"
          [titleText]="link.label"
          [description]="link.description"
          [imageUrl]="link.media?.base_url"
          [media]="link.media"
          [cta_label]="link.cta_label"
          [cta_type]="link.cta_type"
          [url]="link.url"
        >
        </multisite-link-type-six>
      </div>
    </div>
  </div>
</div>