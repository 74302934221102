<div class="mdb-feed">
  <div class="news">
    <!-- Label -->
    <div class="label" style="cursor: pointer;" (click)="doLinkClick()">
      <!-- <img src="https://www.argonautonline.com/wp-content/uploads/2019/03/brenda-turnnidge.jpg" class="rounded-circle z-depth-1-half"> -->
     <frontend-media-image
        *ngIf="media"
        [classes]="'rounded-circle z-depth-1-half'"
        [media]="media"
        [transformations]="'w_700/'"
      >
      </frontend-media-image>
      <img
        *ngIf="!media"
        [src]="imageUrl"
        class="rounded-circle z-depth-1-half"
        alt="image related to the link name"
      />
    </div>

    <!-- Excerpt -->
    <div class="excerpt">
      <!-- Brief -->
      <div class="">
        <a class="brief name title-link" (click)="doLinkClick()">
          <span *ngIf="titleTextTranslation; else elseBlock1">
            <span *transloco="let t">
              <span> {{ t(titleTextTranslation) }} </span>
            </span>
          </span>
          <ng-template #elseBlock1>
            {{ titleText }}
          </ng-template>
        </a>
        <span
          (click)="doLinkClick()"
          *ngIf="subtitleTextTranslation || subtitleText"
        >
          &nbsp;&middot;&nbsp;
          <span *ngIf="subtitleTextTranslation; else elseBlock2">
            <span *transloco="let t">
              <span> {{ t(subtitleTextTranslation) }} </span>
            </span>
          </span>
          <ng-template #elseBlock2>
            {{ subtitleText }}
          </ng-template>
        </span>
        <div *ngIf="date" class="date">
          <div *ngIf="dateAsTimeAgo">7,000 years ago</div>
          <div *ngIf="!dateAsTimeAgo">{{ date | date: 'longDate' }}</div>
        </div>
      </div>
      <!-- Added text -->
      <div *ngIf="description" class="added-text">{{ description }}</div>
    </div>
    <!-- Excerpt -->
  </div>
</div>
