import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataProcessingService } from '../../../../utilities/data-processing.service';
import { Subscription } from 'rxjs';
import { Progressable } from '../../../progressable.model';
import { TrackingService } from '../../../tracking.service';
import { Router } from '@angular/router';
import { AuthService } from 'libs/common/src/lib/auth';
import { BrandDisplayService } from '../../../../branding';


@Component({
  selector: 'progressable-progress-summariser',
  templateUrl: './progressable-progress-summariser.component.html',
  styleUrls: ['./progressable-progress-summariser.component.scss']
})
export class ProgressableProgressSummariserComponent implements OnInit {

  @Input() slimMode : boolean;
  @Input() defaultListMaxLength : number = 2; // if we have more items in the list, we'll collapse them
  @Output() toggleSidenav = new EventEmitter();
  @Output() progressablesArray = new EventEmitter<Progressable[]>(); // same as progressables
  progressables : Progressable[]; // master list
  progressablesShown : Progressable[]; // what we show to the user
  progressablesSubscription : Subscription;
  progressablesInitialSubscription : Subscription;
  userSubscription : Subscription;
  darkMode : boolean;
  @Input() showAll : boolean = false;

  constructor(
    private trackingService: TrackingService,
    private dataProcessingService: DataProcessingService,
    private authService: AuthService,
    private router: Router,
    private brandDisplayService : BrandDisplayService,
    ) { }

  getHardCodedIconClasses(progressable:Progressable){
    let iconClass : string = 'fa-solid fa-chalkboard';
    switch (progressable.slug) {
      case 'swing':
        iconClass = 'fa-solid fa-weight-hanging';
        break;
      case 'swing-strategies':
        iconClass = 'fa-regular fa-lightbulb';
        break;
      default:
        if (progressable.type?.includes('Survey')){
          iconClass = 'fa-solid fa-clipboard-question';
        }
    }
    return iconClass;
  } 

  transformUserInterventions (progressables : Progressable[]){
    let transformedProgressables : Progressable[] = [];
    if (progressables?.length){
      progressables.forEach(p=>{
        p.icon = !p.icon?.identifier_1 && !p.icon?.identifier_2 ? {identifier_1 :this.getHardCodedIconClasses(p),identifier_2 :null} : p.icon;
        p.statusClass = p.progress === 100 ? 'success' : p.progress > 10 ? 'warning' : 'danger',
        transformedProgressables.push(p)
      })
    }
    return transformedProgressables;
  }
  darkModeActive () {
    return this.brandDisplayService.darkMode;
  }
  makeListForShowing (){
    if (!this.showAll && this.progressables?.length > this.defaultListMaxLength){
      this.progressablesShown = this.progressables.slice(0,this.defaultListMaxLength);
    } else {
      this.progressablesShown = this.progressables;
    }
  }
  toggleShowAll (){
    // this.showAll = !this.showAll;
    this.makeListForShowing ();
  }

  getProgressables (){
    this.progressablesInitialSubscription = this.trackingService.getProgressables('survey',null).subscribe(result => {
    })
    this.progressablesSubscription = this.trackingService.progressables.subscribe( progressables => {
      this.progressables = this.transformUserInterventions(progressables);
      this.progressablesArray.emit(this.progressables);
      this.makeListForShowing ();
    })
  }

  gotoTopicCourse(item:Progressable){
    this.router.navigate(['/'+item.tags.find(t=>t.type=='topic').slug,'courses',item.slug]);
  }

  onItemSelected(item : Progressable ){
    if (this.slimMode || !item){
      this.toggleSidenav.emit();
      return;
    }
    if (item.type == this.dataProcessingService.convertModelNameToBackendClass('course') && item.tags?.find(t=>t.type=='topic')){
      this.gotoTopicCourse(item);
    }
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      // this.user = user;
      if (user){
        this.getProgressables ();
      }
      // this.isAuthenticated = !!user;
    });
  }
  ngOnChanges(changesObject): void {
    if (changesObject.showAll && !changesObject.showAll.isFirstChange()){
      this.toggleShowAll();
    }
  }
  ngOnDestroy(): void {
    if (this.progressablesInitialSubscription){
      this.progressablesInitialSubscription.unsubscribe();
    }
    if (this.progressablesSubscription){
      this.progressablesSubscription.unsubscribe();
    }
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    }
  }

}
