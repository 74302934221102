<!--Grid row-->
<div class="container-fluid" style="padding-left: 0 ;">
  <div class="d-flex align-items-center justify-content-center">
    <main class="authentication-overview mb-4 mx-auto p-4">
      <multisite-registration-form *ngIf="(mode==='register' || mode==='design/10')"></multisite-registration-form>
      <multisite-login-form *ngIf="mode==='login'"></multisite-login-form>
      <multisite-forgot-password-form *ngIf="mode==='forgot-password'"></multisite-forgot-password-form>
      <multisite-password-reset-form *ngIf="mode==='password-reset' && email" [email]="email"
        [resetToken]="token"></multisite-password-reset-form>
    </main>
  </div>
  <!-- <div class="row">
      <div class="col-lg-8">
        <aside>
          <div class="carousel-left-carousel">
  
  
            
              THIS COMPONENT IS BASED ON MDB CODE
              <multisite-carousel-page-body-fill
            *ngIf="mode!=='design/10'"
            [controls]="false"
            [indicators]="true"
            [animation]="'fade'"
            [dark]="false"
            [carouselItems]="carouselItems"
  
  
            ></multisite-carousel-page-body-fill>
            
            THE FOLLOWING COMPONENT IS NOT BASED ON MDB AND THE LAYOUT IS DIFFERENT

            <multisite-carousel
              [interval]="interval"
              [carouselItems]="carouselItems"
              [showIndicators]="true"
              [controls]="false"
              [showCaptions]="true"
              [noPause]="false"
              [imageUrlTransformations]="'c_limit,w_1000'"
              [imageInlineStyles]="'filter:brightness(50%)'"
              >
            </multisite-carousel>

          </div>
        </aside>
  
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="carousel-right-content">
          <main>
            <multisite-registration-form *ngIf="(mode==='register' || mode==='design/10')"></multisite-registration-form>
            <multisite-login-form *ngIf="mode==='login'"></multisite-login-form>
            <multisite-forgot-password-form *ngIf="mode==='forgot-password'"></multisite-forgot-password-form>
            <multisite-password-reset-form *ngIf="mode==='password-reset' && email" [email]="email" [resetToken]="token"></multisite-password-reset-form>
          </main>
        </div>
      </div>
    </div> -->
</div>
<!--Grid row -->