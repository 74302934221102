<div class="list-box-item">
  <div class="content-wrap">
    <div class="icon-holder">
      <i class="{{iconClasses}} icon-size"></i>
      <span class="splitter"></span>
    </div>
    <div class="content" *transloco="let t">
      <strong>{{ titleTranslationKey ? t(titleTranslationKey) : titleText }}</strong
      ><span *ngIf="descriptionText || descriptionTranslationKey"><br />{{ descriptionTranslationKey ? t(descriptionTranslationKey) : descriptionText }}</span>
    </div>
  </div>
</div>
