<div class="container-fluid">
    <nav id="breadcrumb" aria-label="breadcrumb" class="main-breadcrumb">
        <h1 class="page-title">
            Topic Tips
        </h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">The Intercultural Pendulum</a></li>
            <li class="breadcrumb-item active" aria-current="page">Topic Tips</li>
        </ol>
    </nav>
    <div class="row mt-3 feed-with-filters">
        <div class="col-xl-9 col-lg-8 col-md-7">
            <div class="feed-card-holder">
                <!-- CARD -->
                <div class="card large-horizontal-card">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="img-holder">
                                <img class="main-img"
                                    src="https://images.pexels.com/photos/810775/pexels-photo-810775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="Take two full minutes to breathe before reacting">
                                <div class="rating">
                                    <pre class="rate-value"><b>{{rate}}</b></pre>
                                    <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="text-content">
                                <div class="title">Take two full minutes to breathe before reacting</div>
                                <div class="badges">
                                    <span class="badge badge-pill badge-info">Mindfulness</span>
                                    <span class="badge badge-pill badge-warning">Meditation</span>
                                    <span class="badge badge-pill badge-more" (click)="openModal(niceInfoModal)">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </span>
                                </div>
                                <p class="text">Many people find it very useful to take a pause to breathe when hit by a
                                    moment of culture shock.</p>
                                <div class="action">
                                    <button>
                                        <p>Complete understanding</p>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </button>
                                    <p class="reading-time">
                                        in <span>10</span>min
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CARD -->
                <div class="card large-horizontal-card">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="img-holder">
                                <div class="video-content">
                                    <i class="fa-solid fa-circle-play"></i>
                                    <p>Video Tip</p>
                                </div>
                                <img class="main-img"
                                    src="https://images.pexels.com/photos/839462/pexels-photo-839462.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="Take two full minutes to breathe before reacting">
                                <div class="rating">
                                    <pre class="rate-value"><b>{{rate}}</b></pre>
                                    <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="text-content">
                                <div class="title">Inviting participants to CultureConnector in less than 2 minutes
                                </div>
                                <div class="badges">
                                    <span class="badge badge-pill badge-info">Mindfulness</span>
                                    <span class="badge badge-pill badge-warning">Meditation</span>
                                    <span class="badge badge-pill badge-more" (click)="openModal(niceInfoModal)">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </span>
                                </div>
                                <p class="text">You have a training coming and you need to get your participants to
                                    create
                                    cultural profiles. Let's skip some details and options. This is how to do it, in a
                                    nutshell.</p>
                                <div class="action">
                                    <button>
                                        <p>Complete understanding</p>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </button>
                                    <p class="reading-time">
                                        in <span>10</span>min
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CARD -->
                <div class="card large-horizontal-card">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="img-holder">

                                <img class="main-img"
                                    src="https://images.pexels.com/photos/810775/pexels-photo-810775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="Take two full minutes to breathe before reacting">
                                <div class="rating">
                                    <pre class="rate-value"><b>{{rate}}</b></pre>
                                    <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="text-content">
                                <div class="title">Take two full minutes to breathe before reacting</div>
                                <div class="badges">
                                    <span class="badge badge-pill badge-info">Mindfulness</span>
                                    <span class="badge badge-pill badge-warning">Meditation</span>
                                    <span class="badge badge-pill badge-more" (click)="openModal(niceInfoModal)">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </span>
                                </div>
                                <p class="text">Many people find it very useful to take a pause to breathe when hit by a
                                    moment of culture shock.</p>
                                <div class="action">
                                    <button>
                                        <p>Complete understanding</p>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </button>
                                    <p class="reading-time">
                                        in <span>10</span>min
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CARD -->
                <div class="card large-horizontal-card">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="img-holder">
                                <div class="video-content">
                                    <i class="fa-solid fa-circle-play"></i>
                                    <p>Video Tip</p>
                                </div>
                                <img class="main-img"
                                    src="https://images.pexels.com/photos/839462/pexels-photo-839462.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="Take two full minutes to breathe before reacting">
                                <div class="rating">
                                    <pre class="rate-value"><b>{{rate}}</b></pre>
                                    <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="text-content">
                                <div class="title">Inviting participants to CultureConnector in less than 2 minutes
                                </div>
                                <div class="badges">
                                    <span class="badge badge-pill badge-info">Mindfulness</span>
                                    <span class="badge badge-pill badge-warning">Meditation</span>
                                    <span class="badge badge-pill badge-more" (click)="openModal(niceInfoModal)">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </span>
                                </div>
                                <p class="text">You have a training coming and you need to get your participants to
                                    create
                                    cultural profiles. Let's skip some details and options. This is how to do it, in a
                                    nutshell.</p>
                                <div class="action">
                                    <button>
                                        <p>Complete understanding</p>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </button>
                                    <p class="reading-time">
                                        in <span>10</span>min
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CARD -->
                <div class="card large-horizontal-card">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="img-holder">

                                <img class="main-img"
                                    src="https://images.pexels.com/photos/810775/pexels-photo-810775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="Take two full minutes to breathe before reacting">
                                <div class="rating">
                                    <pre class="rate-value"><b>{{rate}}</b></pre>
                                    <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="text-content">
                                <div class="title">Take two full minutes to breathe before reacting</div>
                                <div class="badges">
                                    <span class="badge badge-pill badge-info">Mindfulness</span>
                                    <span class="badge badge-pill badge-warning">Meditation</span>
                                    <span class="badge badge-pill badge-more" (click)="openModal(niceInfoModal)">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </span>
                                </div>
                                <p class="text">Many people find it very useful to take a pause to breathe when hit by a
                                    moment of culture shock.</p>
                                <div class="action">
                                    <button>
                                        <p>Complete understanding</p>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </button>
                                    <p class="reading-time">
                                        in <span>10</span>min
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CARD -->
                <div class="card large-horizontal-card">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="img-holder">
                                <div class="video-content">
                                    <i class="fa-solid fa-circle-play"></i>
                                    <p>Video Tip</p>
                                </div>
                                <img class="main-img"
                                    src="https://images.pexels.com/photos/839462/pexels-photo-839462.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="Take two full minutes to breathe before reacting">
                                <div class="rating">
                                    <pre class="rate-value"><b>{{rate}}</b></pre>
                                    <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="text-content">
                                <div class="title">Inviting participants to CultureConnector in less than 2 minutes
                                </div>
                                <div class="badges">
                                    <span class="badge badge-pill badge-info">Mindfulness</span>
                                    <span class="badge badge-pill badge-warning">Meditation</span>
                                    <span class="badge badge-pill badge-more" (click)="openModal(niceInfoModal)">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </span>
                                </div>
                                <p class="text">You have a training coming and you need to get your participants to
                                    create
                                    cultural profiles. Let's skip some details and options. This is how to do it, in a
                                    nutshell.</p>
                                <div class="action">
                                    <button>
                                        <p>Complete understanding</p>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </button>
                                    <p class="reading-time">
                                        in <span>10</span>min
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Pagination -->
                <div class="pagination">
                    <!-- <pagination [totalItems]="68" [rotate]="rotate" [maxSize]="maxSize"></pagination> -->
                    <pagination ngClass="d-none" [totalItems]="totalItems" (numPages)="smallnumPages = $event">
                    </pagination>
                    <div class="pagination-input-group">
                        <div class="pull-left">
                            <p class="mr-1">Page:</p>
                            <span class="input-group">
                                <input type="text" class="form-control curent-page" value="{{currentPage}}"
                                    aria-label="This is a current page">
                                <button class="btn-input-icon btn btn-primary btn-sm" type="button">
                                    <i class="fa-solid fa-angle-right"></i>
                                </button>
                            </span>
                            <p>of <span>{{smallnumPages}}</span></p>
                        </div>
                        <div class="pull-right">
                            <pager ngClass="pagination-control" [totalItems]="totalItems" [align]="true"
                                [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" pageBtnClass="btn"
                                [itemsPerPage]="10">
                            </pager>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-5">
            <div class="feed-filters">
                <button type="button" class="btn btn-primary w-100 toggle-filters" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseFilters">Filters <i
                        class="ml-2 fa-solid fa-angle-down"></i>
                </button>
                <div class="filters-body" id="collapseFilters" [collapse]="isCollapsed" [isAnimated]="true">
                    <div class="input-group">
                        <input type="search" class="form-control search" placeholder="Search..."
                            aria-label="Enter what you want to search">
                        <button class="btn-input-icon" type="button">
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </button>
                    </div>
                    <div class="form-group">
                        <label for="sortby">Sort by:</label>

                        <select class="form-select" id="sortby" aria-label="Select the sorting method">
                            <option selected>Name</option>
                            <option>Date</option>
                            <option>Tag</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="filterby">Filter by:</label>
                        <select class="form-select" id="filterby" aria-label="Select the filtering method">
                            <option selected disabled>Select tags</option>
                            <option>Date</option>
                            <option>Tag</option>
                        </select>
                        <input class="form-control mt-2" type="search" placeholder="Add new tag"
                            aria-label="Enter what you want to add">
                    </div>
                    <div class="form-group">
                        <label for="selected-filters" class="mb-2">Selected tags:</label>
                        <div id="selected-filters">
                            <div class="form-check">
                                <input type="checkbox" value="" id="flexCheckDefault" checked disabled>
                                <label class="form-check-label" for="flexCheckDefault">
                                    Pendulum
                                </label>
                            </div>
                            <div class="form-check">
                                <input type="checkbox" value="" id="flexCheckChecked" checked disabled>
                                <label class="form-check-label" for="flexCheckChecked">
                                    Image
                                </label>
                            </div>
                            <div class="form-check">
                                <input type="checkbox" value="" id="flexCheckChecked" checked disabled>
                                <label class="form-check-label" for="flexCheckChecked">
                                    Profile
                                </label>
                            </div>
                            <div class="form-check">
                                <input type="checkbox" value="" id="flexCheckChecked" checked disabled>
                                <label class="form-check-label" for="flexCheckChecked">
                                    Picture
                                </label>
                            </div>
                            <div class="form-check">
                                <input type="checkbox" value="" id="flexCheckChecked" checked disabled>
                                <label class="form-check-label" for="flexCheckChecked">
                                    Trainers
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<ng-template #niceInfoModal>
    <!--<div class="modal-header">
       <h4 class="modal-title pull-left">Modal</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div> -->
    <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left">All tags</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true">
                <i class="fa-solid fa-xmark"></i>
            </span>
        </button>
    </div>
    <div class="modal-body">
        <span class="badge badge-pill badge-info">Mindfulness</span>
        <span class="badge badge-pill badge-warning">Meditation</span>

    </div>
    <!-- <div class="modal-footer">
    <button type="button" class="btn-primary-outline pull-right" aria-label="Close"
    (click)="staticModal.hide()">
    Close
</button>
</div> -->
</ng-template>