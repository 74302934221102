import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor() { }

  goToTop(elementClass:string = '.main-section'){
    const contentContainer = document.querySelector(elementClass); // TODO - find a more Angular way of doing this (the normal methods don't work)
    if (contentContainer) {
      contentContainer.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
    };
  }

  scrollToAnchor(anchorId: string): void {
    // scroll to a position in the HTML, for example <a id="myAnchor"></a>
    const anchorElement: HTMLElement | null = document.getElementById(anchorId);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
