<a class="row mt-3" (click)="doLinkClick($event)" *transloco="let t"
  [attr.aria-label]="cta_label ? cta_label : t('call_to_action.'+cta_type)">
  <div class="top-content row">
    <div class="col-4" *ngIf="media">
      <img class="img-responsive w-100"
        [src]="media.asset_url && imageTransformations ? media.base_url + imageTransformations + media.asset_url : media.file_url"
        [alt]="media.description">
    </div>
    <div [class]="media ? 'col-8 px-0' : 'col-12 px-2'">
      <p class="font-weight-bold">{{titleText}}</p>
      <p>
        {{description}}
      </p>
    </div>
  </div>
  <button type="button" class="btn btn-primary btn-sm"
    [attr.aria-label]="cta_label ? cta_label : t('call_to_action.'+cta_type)" *ngIf="cta_label || cta_type">
    {{cta_label ? cta_label : t('call_to_action.'+cta_type)}}
  </button>
</a>