import { AfterContentInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { TabBasic } from './tab-basic.model';

@Component({
  selector: 'multisite-tabs-basic',
  templateUrl: './tabs-basic.component.html',
  styleUrls: ['./tabs-basic.component.scss']
})
export class TabsBasicComponent implements AfterContentInit {

  @Input() tabs : TabBasic[];
  @Input() activeTabIndex: number;
  @Output() tabSelected : EventEmitter<TabBasic> = new EventEmitter();
  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;


  constructor() {
   }

  onSelect(tab,event){
    let interestingButNotUsed = event;
    this.tabSelected.emit(tab)
  }

  setActiveTab (){
    setTimeout(()=>{
      if (this.activeTabIndex && this.staticTabs?.tabs[this.activeTabIndex]) {
        this.staticTabs.tabs[this.activeTabIndex].active = true;
      }
    },500)
  }

  ngAfterContentInit () {
      this.setActiveTab();
    
  }


}
