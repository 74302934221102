import { CloudinaryMediaAsset } from "../../models";
import { MetaText } from "../metaText.model";

export class ProcessStep {
   constructor(
    public metaText: MetaText, // we'll use weight and num for numbering the steps; (may include icon)
    public media?: CloudinaryMediaAsset,
    public subtitleText?: string, // 'some interesting words'
    public subtitleTranslationKey?: string, // 'tracking.accredited_until'
    public date?: Date,
    public divClasses?: string, // 'bg-success text-white'
    ){}
  }