<div class="container" *transloco="let t">
    <div class="row">
        
        <div class="col-md-8 p-2">
            <p *ngIf="!loading && !hubs?.length"><span>{{t('common.none_yet')}}</span></p>
            <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
            </multisite-loader-globe-animation-detail>

            <a *ngFor="let hub of hubsAsContentItems" [routerLink]="[hub.slug]">
                <card-banner-list-item-promo [content]="hub"
                    [imageTransformations]="'w_1000,c_fill,ar_16:9/'"></card-banner-list-item-promo>
            </a>
            
        </div>
        <div class="col-md-4 p-2">
            <button class="btn btn-success w-100" *ngIf="hubsAsContentItems?.length === 1"  [routerLink]="[hubsAsContentItems[0].slug]">{{t('common.go')}} <i class="pl-2 fa-solid fa-arrow-right"></i></button>
            <button class="btn btn-primary w-100 my-3" [routerLink]="['new']" routerLinkActive="router-link-active">
                {{t('common.new')}} <i class="pl-2 fa-solid fa-plus"></i>
            </button>
            <multisite-link-type-six *ngFor="let link of links"
                [imageTransformations]="'w_100,c_thumb/'"
                [titleText]="link.label"
                [description]="link.description"
                [imageUrl]="link.media?.base_url"
                [media]="link.media"
                [cta_label]="link.cta_label"
                [cta_type]="link.cta_type"
                [url]="link.url"
            >
            </multisite-link-type-six>
        </div>
    </div>
</div>