import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonCardTestimonialTypeTwoComponent } from './person-card-testimonial-type-two/person-card-testimonial-type-two.component';
import { PersonCardTestimonialTypeOneComponent } from './person-card-testimonial-type-one/person-card-testimonial-type-one.component';



@NgModule({
  declarations: [PersonCardTestimonialTypeTwoComponent,PersonCardTestimonialTypeOneComponent],
  exports: [PersonCardTestimonialTypeTwoComponent,PersonCardTestimonialTypeOneComponent],
  imports: [
    CommonModule
  ]
})
export class PeopleCardsModule { }
