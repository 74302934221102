import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { Media } from '@frontend/core';


@Component({
  selector: 'frontend-link-type-two',
  templateUrl: './link-type-two.component.html',
  styleUrls: ['./link-type-two.component.scss']
})
export class LinkTypeTwoComponent implements OnInit, OnChanges {

  // Good for internal links, where no Link model is required

  @Input() titleText : string;
  @Input() titleTextTranslation : string;
  @Input() buttonText : string;
  @Input() buttonTextTranslation : string;
  @Input() description : string;
  @Input() descriptionTruncateLength : number = 110;
  @Input() imageUrl : string;
  @Input() media : Media;
  @Input() imageTransformations : string; // 'w_350,ar_16:9/' // used for media, not for imageUrl
  @Input() likesCount : number;
  @Input() hideButton : boolean;
  @Input() hideImage : boolean;
  @Output() linkClicked = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }

  doLinkClick (){
    this.linkClicked.emit();
  }

  ngOnChanges(changesObject): void {
    if (changesObject?.description?.currentValue?.length){
      if (changesObject.description.currentValue.length > this.descriptionTruncateLength){
        this.description = this.truncateText(changesObject.description.currentValue,this.descriptionTruncateLength-10);
      };
    }
  }

  truncateText(sourceText, length){
    let truncatedText = sourceText.substr(0, length);
    return truncatedText.trim()+'...';
  }

}
