import { Tag } from "@frontend/core";
import { Culture } from "../cultures";
import { CcoDimension } from "./cco-dimension.model";

export class CcoRecommendation {
    
    constructor(
        public culture_id: number,
        public dimension_ids: number[],
        // public dimension_tags?: Tag[], // TODO - consider deleting; not in use
        public dimensions?: CcoDimension[],
        public culture?: Culture,
    ){}

}