import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserAccountRoutingModule } from './user-account-routing.module';
import { AccountHomeComponent } from './account-home/account-home.component';
import { VerticalMenuTypeOneModule } from '../navigation';
import { UserAccountDetailsUpdateComponent } from './user-account-details-update/user-account-details-update.component';
import { EmailInputFormModule } from '@frontend/shared';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { LoadersModule } from '../loaders';
import { ReactiveFormsModule } from '@angular/forms';
// import { UserAccountConsentComponent } from './consent/user-account-consent/user-account-consent.component';


@NgModule({
  declarations: [
    AccountHomeComponent,
    UserAccountDetailsUpdateComponent,
    // UserAccountConsentComponent
  ],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'account' }],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UserAccountRoutingModule,
    TranslocoModule,
    VerticalMenuTypeOneModule,
    EmailInputFormModule,
    AlertModule,
    LoadersModule,
  ],
  exports: [
    UserAccountDetailsUpdateComponent
  ]
})
export class UserAccountModule { }
