import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkSocialIconComponent } from './link-social-icon.component';
import { ImagesModule } from '../../images';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [LinkSocialIconComponent],
  exports: [LinkSocialIconComponent],
  imports: [
    CommonModule,
    ImagesModule,
    TranslocoModule
  ]
})
export class LinkSocialIconModule { }
