import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageService } from '@frontend/common';
import { catchError, map, of, Subscription, throwError } from 'rxjs';
import { Tag } from '../models';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  tags: Tag[] = [];
  activeLanguageSubscription: Subscription;


  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
  ) { 

    this.activeLanguageSubscription =
    this.languageService.activeLanguageObject.subscribe(() => {
      this.clearTranslations();
    });
  }

  clearTranslations() {
    this.tags = [];
  }
  clearData() {
    // this.clearTranslations();
  }


  getTagsByType (types: string[], freshFromServer : boolean){
    if (!types){return this.handleError(new HttpErrorResponse({error:'Tag type missing'}));};

    let allTypesCached = types.every(type => this.tags.some(tag => tag.type === type));

    if(allTypesCached && !freshFromServer){
      return of(this.tags.filter(t=> types.includes(t.type)));
    }

    const tagTypesCached = [...new Set(this.tags.map(tag => tag.type))]; // Set gives us unique values
    const tagTypesNotCached = types.filter(type=>!tagTypesCached.includes(type));

    return this.http.get<{'data': Tag[]}>('api/v1/tags?type='+tagTypesNotCached.join(','))
      .pipe(
        map(response =>{
          if (response && response.data ){
            this.tags = this.tags.concat(response.data);
            return this.tags.filter(t=> types.includes(t.type));
          } else {
            return [];
          }
        }),
        catchError((error) => {
          return this.handleError(error);
        })
      )
  }

  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    switch (errorResponse.error.message) {
      case 'The type is invalid.':
        errorMessage = 'error.data_invalid';
        break;
      default :
        // no action needed. We already set the default error message.
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }


}
