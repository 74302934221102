import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserConnectionsDetailsUpdateComponent } from './user-connections-details-update/user-connections-details-update.component';
import { UserConnectionsHomeComponent } from './user-connections-home/user-connections-home.component';

const routes: Routes = [
  {
    path: '',
    component: UserConnectionsHomeComponent,
    data: { label: 'Connections', translationKey: 'navigation.connections', labelSource: null,consent:['dj']},
    children : [
      { 
        path: 'raters',
        component: UserConnectionsDetailsUpdateComponent,
        data: { label: 'Raters', translationKey: 'common.raters', labelSource: null,consent:['dj']},
      },
      { path: '', redirectTo: 'raters', pathMatch: 'full'},
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class UserConnectionsRoutingModule { }
