<div class="upload-form" *transloco="let t">
  <tabset #staticTabs>
    <tab heading="{{t('content_management.gallery')}}" *ngIf="gallery?.length"
      (selectTab)="changeSource('gallery',$event)">
      <p *ngIf="!gallery?.length">Empty</p>
      <div>
        <div class="my-3 gallery" *ngIf="gallery?.length">
          <div class="row my-4" *ngIf="isAdmin">
            <div class="col-lg-4">
              <div class="input-group mb-3">
                <input type="text" class="form-control" [(ngModel)]="searchTerm" (keyup)="filterGallery()"
                  [placeholder]="t('common.start_typing')" aria-label="Search term" aria-describedby="searchbox">
                <span class="input-group-text" id="searchbox"><i class="fa fa-search"></i></span>
                <!-- <input type="text" class="form-control" placeholder="Search..." aria-label="Recipient's username"
                  aria-describedby="search-icon">
                <div class="input-group-append">
                  <button type="button" class="btn btn-sm btn-primary" id="search-icon">
                    <i class="fa fa-search"></i>
                  </button>
                </div> -->
              </div>
            </div>
            <div class="col-lg-8">
              <div class="w-100 h-100 bg-success text-white d-flex align-items-center justify-content-center">
                Pagination placeholder
              </div>
            </div>
          </div>
          <!-- Media object -->
          <div class="row">
            <div class="gallery-item my-2 col-lg-3 col-md-6" *ngFor="let item of filteredGallery"
              (click)="onSelectGalleryItem(item.id)">
              <div class="card bg-dark text-white"
                [ngClass]="{'selected': item.file_url === selectedGalleryItemMediaFileUrl}">
                <img class="card-img" [src]="
              item.asset_url && imageTransformations
                ? item.base_url + imageTransformations + item.asset_url
                : item.file_url" [alt]="item.description">
                <div class="card-img-overlay">
                  <div class="select" *ngIf="item.file_url !== selectedGalleryItemMediaFileUrl">
                    <i class="fa-solid fa-circle-check mr-1"></i>{{t('common.select')}}
                  </div>
                  <div class="selected text-success" *ngIf="item.file_url === selectedGalleryItemMediaFileUrl">
                    <i class="fa-solid fa-circle-check text-success mr-1"></i>{{t('common.selected')}}
                  </div>
                  <div class="globe">
                    <!-- <multisite-loader-globe-animation-detail *ngIf="item.id === selectedGalleryItemId && uploadProgress"
                      [width]="60" [height]="60" class="d-inline-block align-bottom">
                    </multisite-loader-globe-animation-detail> -->
                  </div>
                </div>
              </div>
              <h5 class="fw-bold mt-3">
                {{ item.description }}
                <small class="text-muted">{{ '' }}</small>
              </h5>
              <p>
                {{ item.credits }}
              </p>

              <div class="mt-2" *ngIf="item.id === selectedGalleryItemId">
                <alert class="mt-3 w-100" [type]="'success'" [dismissOnTimeout]="5000" *ngIf="successMessage">
                  <strong class="mr-1">
                    {{ t('common.success') }}
                  </strong>
                  {{ t(successMessage) }}
                </alert>
              </div>

            </div>
          </div>
          <!-- Media object -->
        </div>
      </div>
    </tab>
    <tab heading="{{t('content_management.file_on_device')}}" (selectTab)="changeSource('local',$event)">
      <form class="position-relative" [formGroup]="fileUploadForm" (submit)="onUploadSubmit()" role="form">
        <div class="row mt-4">
          <div class="col-lg-6">
            <label for="formFile" *ngIf="!askForLicenceAndCredit && fileDescriptionTranslationKey">{{
              t(fileDescriptionTranslationKey)}}<span class="required-sign">*</span></label>
            <div [ngClass]="{'input-group': !askForLicenceAndCredit}">
              <div class="input-group">
                <label for="formFile" class="form-label w-100" *ngIf="askForLicenceAndCredit">{{
                  t(fileDescriptionTranslationKey)
                  }}<span class="required-sign">*</span>
                </label>
                <input class="form-control" type="file" id="formFile" [accept]="requiredFileType"
                  formControlName="fileData" (change)="onFileSelected($event)" [attr.disabled]="disabled ? true : null"
                  #fileUpload />
                <button type="submit" class="btn btn-sm btn-primary primary-dark"
                  [disabled]="fileUploadForm.invalid || uploadProgress || disabled">
                  {{ t(uploadButtonTranslationKey) }}
                </button>
              </div>
            </div>


          </div>
          <div class="col-lg-6">
            <div class="input-self mb-3" *ngIf="askForLicenceAndCredit">
              <!-- <div class="input-self mb-3"> -->
              <label for="licence" class="form-label">{{ t('common.licence')
                }}<span class="required-sign">*</span>
              </label>
              <div class="input-group" [ngClass]="{
                'ng-invalid':
                  fileUploadForm.get('licence').invalid &&
                  fileUploadForm.get('licence').touched,
                'ng-valid':
                  fileUploadForm.get('licence').valid &&
                  fileUploadForm.get('licence').touched &&
                  fileUploadForm.get('licence').dirty
              }">
                <span class="input-group-text text-primary-light">
                  <i class="fa fa-circle-check" aria-hidden="true"></i>
                </span>
                <input class="form-control" type="text" formControlName="licence" id="licence"
                  [attr.disabled]="disabled ? true : null" placeholder="Creative Commons 2.0 Share Alike" />
                <div *ngIf="
                  fileUploadForm.get('licence').errors?.required &&
                  fileUploadForm.get('licence').touched
                " class="invalid-feedback">
                  {{ t('common.required') }}
                </div>
                <div *ngIf="
                  fileUploadForm.get('licence').errors?.licence &&
                  fileUploadForm.get('licence').touched
                " class="invalid-feedback">
                  <!--  -->
                </div>
              </div>
              <div class="form-text">
                {{ t('content_management.image_licence') }}
              </div>
            </div>

            <div class="input-self mb-3" *ngIf="askForLicenceAndCredit">
              <label for="licence_url" class="form-label">{{ t('common.licence_url') }}
              </label>
              <div class="input-group" [ngClass]="{
                'ng-invalid':
                  fileUploadForm.get('licence_url').invalid &&
                  fileUploadForm.get('licence_url').touched,
                'ng-valid':
                  fileUploadForm.get('licence_url').valid &&
                  fileUploadForm.get('licence_url').touched &&
                  fileUploadForm.get('licence_url').dirty
              }">
                <span class="input-group-text text-primary-light">
                  <i class="fa fa-link" aria-hidden="true"></i>
                </span>
                <input class="form-control" type="text" formControlName="licence_url" id="licence_url"
                  [attr.disabled]="disabled ? true : null"
                  placeholder="https://creativecommons.org/licenses/by-sa/4.0/" />
                <div *ngIf="
                  fileUploadForm.get('licence_url').errors?.required &&
                  fileUploadForm.get('licence_url').touched
                " class="invalid-feedback">
                  {{ t('common.required') }}
                </div>
                <div *ngIf="
                fileUploadForm.get('licence_url').errors?.minlength &&
                fileUploadForm.get('licence_url').touched
                " class="invalid-feedback">
                  {{ t('common.too_short') }}
                </div>
              </div>
              <div class="form-text">
                {{ t('content_management.image_licence_address') }}
              </div>
            </div>

            <div class="input-self mb-3" *ngIf="askForLicenceAndCredit">
              <label for="credit" class="form-label">{{ t('common.credit')
                }}<span class="required-sign">*</span>
              </label>
              <div class="input-group" [ngClass]="{
                'ng-invalid':
                  fileUploadForm.get('credit').invalid &&
                  fileUploadForm.get('credit').touched,
                'ng-valid':
                  fileUploadForm.get('credit').valid &&
                  fileUploadForm.get('credit').touched &&
                  fileUploadForm.get('credit').dirty
              }">
                <span class="input-group-text text-primary-light">
                  <i class="fa fa-id-card" aria-hidden="true"></i>
                </span>
                <input class="form-control" type="text" formControlName="credit" id="credit"
                  placeholder="UNICEF/UN0792788/Tibaweswa" [attr.disabled]="disabled ? true : null" />
                <div *ngIf="
                  fileUploadForm.get('credit').errors?.required &&
                  fileUploadForm.get('credit').touched
                " class="invalid-feedback">
                  {{ t('common.required') }}
                </div>
                <div *ngIf="
                fileUploadForm.get('credit').errors?.minlength &&
                fileUploadForm.get('credit').touched
                " class="invalid-feedback">
                  {{ t('common.too_short') }}
                </div>
              </div>
              <div class="form-text">
                {{ t('content_management.credit') }}
              </div>
            </div>
          </div>
          <div class="d-flex flex-column w-100 my-2 justify-content-end align-items-end my-3" *ngIf="!emitOnChange">
            <alert class="mt-1 w-100" [type]="'warning'" *ngIf="errorMessage">
              <strong class="mr-1">
                {{ t('common.problem') }}
              </strong>
              {{ t(errorMessage) }}
            </alert>
            <alert class="mt-1 w-100" [type]="'success'" [dismissOnTimeout]="5000" *ngIf="successMessage">
              <strong class="mr-1">
                {{ t('common.success') }}
              </strong>
              {{ t(successMessage) }}
            </alert>
            <!-- <p>Errors: {{fileUploadForm.get('remoteFileUrl').errors | json}}</p> -->
            <button type="submit" class="btn btn-primary primary-dark align-self-end" *ngIf="askForLicenceAndCredit"
              [disabled]="fileUploadForm.invalid || uploadProgress || disabled">
              {{ t(uploadButtonTranslationKey) }}
            </button>
          </div>
        </div>

        <div class="uploading-progress" *ngIf="uploadProgress && (source === 'remote' || source === 'local')">

          <div class="holder">
            <!-- <multisite-loader-globe-animation-detail *ngIf="uploadProgress" [width]="100" [height]="100">
            </multisite-loader-globe-animation-detail> -->
            <div class="progress">
              <div class="progress-bar" role="progressbar" aria-label="Upload progress indicator"
                [attr.aria-valuenow]="uploadProgress" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: uploadProgress + '%' }" *ngIf="uploadProgress"></div>

              <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="doCancelUpload()"
                *ngIf="uploadProgress">
                <span aria-hidden="true">
                  <i class="fa-solid fa-xmark"></i>
                </span>
              </button>
            </div>
          </div>
        </div>


      </form>
    </tab>
    <tab heading="{{t('content_management.internet')}}" (selectTab)="changeSource('remote',$event)">
      <form class="position-relative" [formGroup]="fileUploadForm" (submit)="onUploadSubmit()" role="form">

        <div class="row mt-4">
          <div class="col-lg-6">
            <label for="remoteFile" class="form-label">{{ t(remoteFileDescriptionTranslationKey)
              }}<span class="required-sign">*</span>
            </label>
            <div class="input-group">
              <span class="input-group-text" id="fileAddressLabel">{{
                t('content_management.file_address')
                }}</span>
              <input type="text" class="form-control" id="remoteFile" formControlName="remoteFileUrl"
                aria-describedby="fileAddressLabel"
                placeholder="https://www.mycompanysite.com/images/banners/companybanner_wide.jpg"
                [attr.disabled]="disabled ? true : null" />
              <button type="submit" class="btn btn-sm btn-primary primary-dark " *ngIf="!askForLicenceAndCredit"
                [disabled]="fileUploadForm.invalid || uploadProgress || disabled">
                {{ t(uploadButtonTranslationKey) }}
              </button>
              <div *ngIf="
                    fileUploadForm.get('remoteFileUrl').errors?.minlength &&
                    fileUploadForm.get('remoteFileUrl').touched
                    " class="invalid-feedback">
                {{ t('common.too_short') }}
              </div>

            </div>
            <div class="form-text">{{ t(fileInstructionsTranslationKey) }}</div>
            <alert [type]="'warning'" *ngIf="remoteImageUrlError && source === 'remote'"><span *transloco="let t">{{
                t('content_management.remote_image_url_error')
                }}</span>
            </alert>
            <!-- Preview an image from the internet before the user confirms it (before upload) -->
            <div class="gallery w-sm-100 w-50 mt-3"
              *ngIf="source === 'remote' && this.fileUploadForm.get('remoteFileUrl').value && !remoteImageUrlError">
              <p>{{t('content_management.happy_with_image')}} {{t('common.click_confirm')}}</p>
              <div class="gallery-item">
                <div class="card bg-dark text-white">
                  <img class="card-img" [src]="this.fileUploadForm.get('remoteFileUrl').value" [alt]="''">
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-self mb-3" *ngIf="askForLicenceAndCredit">
              <label for="licence" class="form-label">{{ t('common.licence')
                }}<span class="required-sign">*</span>
              </label>
              <div class="input-group" [ngClass]="{
              'ng-invalid':
                fileUploadForm.get('licence').invalid &&
                fileUploadForm.get('licence').touched,
              'ng-valid':
                fileUploadForm.get('licence').valid &&
                fileUploadForm.get('licence').touched &&
                fileUploadForm.get('licence').dirty
            }">
                <span class="input-group-text text-primary-light">
                  <i class="fa fa-circle-check" aria-hidden="true"></i>
                </span>
                <input class="form-control" type="text" formControlName="licence" id="licence"
                  [attr.disabled]="disabled ? true : null" placeholder="Creative Commons 2.0 Share Alike" />
                <div *ngIf="
                fileUploadForm.get('licence').errors?.required &&
                fileUploadForm.get('licence').touched
              " class="invalid-feedback">
                  {{ t('common.required') }}
                </div>
                <div *ngIf="
                fileUploadForm.get('licence').errors?.licence &&
                fileUploadForm.get('licence').touched
              " class="invalid-feedback">
                  <!--  -->
                </div>
              </div>
              <div class="form-text">
                {{ t('content_management.image_licence') }}
              </div>
            </div>

            <div class="input-self mb-3" *ngIf="askForLicenceAndCredit">
              <label for="licence_url" class="form-label">{{ t('common.licence_url') }}
              </label>
              <div class="input-group" [ngClass]="{
              'ng-invalid':
                fileUploadForm.get('licence_url').invalid &&
                fileUploadForm.get('licence_url').touched,
              'ng-valid':
                fileUploadForm.get('licence_url').valid &&
                fileUploadForm.get('licence_url').touched &&
                fileUploadForm.get('licence_url').dirty
            }">
                <span class="input-group-text text-primary-light">
                  <i class="fa fa-link" aria-hidden="true"></i>
                </span>
                <input class="form-control" type="text" formControlName="licence_url" id="licence_url"
                  [attr.disabled]="disabled ? true : null"
                  placeholder="https://creativecommons.org/licenses/by-sa/4.0/" />
                <div *ngIf="
                fileUploadForm.get('licence_url').errors?.required &&
                fileUploadForm.get('licence_url').touched
              " class="invalid-feedback">
                  {{ t('common.required') }}
                </div>
                <div *ngIf="
              fileUploadForm.get('licence_url').errors?.minlength &&
              fileUploadForm.get('licence_url').touched
              " class="invalid-feedback">
                  {{ t('common.too_short') }}
                </div>
              </div>
              <div class="form-text">
                {{ t('content_management.image_licence_address') }}
              </div>
            </div>

            <div class="input-self mb-3" *ngIf="askForLicenceAndCredit">
              <label for="credit" class="form-label">{{ t('common.credit')
                }}<span class="required-sign">*</span>
              </label>
              <div class="input-group" [ngClass]="{
              'ng-invalid':
                fileUploadForm.get('credit').invalid &&
                fileUploadForm.get('credit').touched,
              'ng-valid':
                fileUploadForm.get('credit').valid &&
                fileUploadForm.get('credit').touched &&
                fileUploadForm.get('credit').dirty
            }">
                <span class="input-group-text text-primary-light">
                  <i class="fa fa-id-card" aria-hidden="true"></i>
                </span>
                <input class="form-control" type="text" formControlName="credit" id="credit"
                  placeholder="UNICEF/UN0792788/Tibaweswa" [attr.disabled]="disabled ? true : null" />
                <div *ngIf="
                fileUploadForm.get('credit').errors?.required &&
                fileUploadForm.get('credit').touched
              " class="invalid-feedback">
                  {{ t('common.required') }}
                </div>
                <div *ngIf="
              fileUploadForm.get('credit').errors?.minlength &&
              fileUploadForm.get('credit').touched
              " class="invalid-feedback">
                  {{ t('common.too_short') }}
                </div>
              </div>
              <div class="form-text">
                {{ t('content_management.credit') }}
              </div>
            </div>
          </div>
          <div class="d-flex flex-column w-100 my-2 justify-content-end align-items-end my-3" *ngIf="!emitOnChange">
            <alert class="mt-1 w-100" [type]="'warning'" *ngIf="errorMessage">
              <strong class="mr-1">
                {{ t('common.problem') }}
              </strong>
              {{ t(errorMessage) }}
            </alert>
            <alert class="mt-1 w-100" [type]="'success'" [dismissOnTimeout]="5000" *ngIf="successMessage">
              <strong class="mr-1">
                {{ t('common.success') }}
              </strong>
              {{ t(successMessage) }}
            </alert>
            <!-- <p>Errors: {{fileUploadForm.get('remoteFileUrl').errors | json}}</p> -->
            <button type="submit" class="btn btn-primary primary-dark align-self-end" *ngIf="askForLicenceAndCredit"
              [disabled]="fileUploadForm.invalid || uploadProgress || disabled">
              {{ t(uploadButtonTranslationKey) }}
            </button>
          </div>

        </div>

        <div class="uploading-progress" *ngIf="uploadProgress && (source === 'remote' || source === 'local')">

          <div class="holder">
            <!-- <multisite-loader-globe-animation-detail *ngIf="uploadProgress" [width]="100" [height]="100">
            </multisite-loader-globe-animation-detail> -->
            <div class="progress">
              <div class="progress-bar" role="progressbar" aria-label="Upload progress indicator"
                [attr.aria-valuenow]="uploadProgress" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: uploadProgress + '%' }" *ngIf="uploadProgress"></div>

              <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="doCancelUpload()"
                *ngIf="uploadProgress">
                <span aria-hidden="true">
                  <i class="fa-solid fa-xmark"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </tab>
  </tabset>
</div>