import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'ttt-design01',
  templateUrl: './design01.component.html',
  styleUrls: ['./design01.component.scss']
})
export class Design01Component {

 

  constructor() {}


}

