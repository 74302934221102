import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ContentItem } from '../../content/content-item.model';
import { Survey } from '../survey.model';
import { SurveysService } from '../surveys.service';
import { AvailableLanguage, LanguageService, WindowService } from '@frontend/common';

@Component({
  selector: 'culturequestion-survey-search',
  templateUrl: './survey-search.component.html',
  styleUrls: ['./survey-search.component.scss']
})
export class SurveySearchComponent implements OnInit {

contentSubscription: Subscription;
loading: boolean;
surveys: Survey[] = [];
surveysAsContent: ContentItem[];
imageTransformations: string = 'w_700,c_fill,ar_16:9/';
activeLanguageSubscription: Subscription;
activeLanguageObject: AvailableLanguage;

// override these in the routeConfig if necessary
path_to_surveys : string = '../quiz/';
filter_category : string = 'cultures'; 
filter_type : string = 'knowledge';
//
queryParamsSubscription: Subscription;
queryParams : {[key:string]:string} // 'cultures=france,germany'

constructor(
  private router: Router,
  private route: ActivatedRoute,
  private surveysService: SurveysService,
  private languageService: LanguageService,
  private windowService: WindowService,
) {}

gotoSurvey(surveysAsContentItem: ContentItem){
  if (surveysAsContentItem){
    this.router.navigate(['/quiz/'+surveysAsContentItem.slug]);
  }
}
getContent(freshFromServer:boolean) {
  this.loading = true;

  this.contentSubscription = this.surveysService
    .getSurveys(this.filter_category,this.filter_type,this.queryParams,freshFromServer)
    .subscribe(
      (response) => {
        this.surveys = response;
        this.loading = false;
        this.surveysAsContent = this.surveysService.convertSurveysToContentItems(this.surveys);
      },
      (error) => {
        this.loading = false;
      }
    );
}
// performSearch(culturesSearchKeywords: string): void {
//   this.router.navigate([], {
//     queryParams: { cultures: culturesSearchKeywords },
//     queryParamsHandling: 'merge', // Merge with existing query parameters
//     replaceUrl: true // Replace the current URL without adding to browser history
//   });
// }

ngOnInit(): void {
  this.windowService.goToTop();
  this.path_to_surveys = this.route.routeConfig.data?.path_to_surveys ?? this.path_to_surveys;
  this.filter_category = this.route.routeConfig.data?.filter_category ?? this.filter_category;
  this.filter_type = this.route.routeConfig.data?.filter_type ?? this.filter_type;
  // let params = this.route.snapshot.queryParams;
  this.queryParamsSubscription = this.route.queryParamMap.subscribe((params: ParamMap) => {
    const paramValue = params.get('cultures');
    if (paramValue) {
      this.queryParams = {cultures:paramValue}
      // this.performSearch(paramValue);
    }
    this.getContent(false);
  });
  this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
  this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
    // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
    if (newActiveLanguage?.languageKey !== this.activeLanguageObject.languageKey){
      this.activeLanguageObject = newActiveLanguage;
      this.getContent(true);
    }
  });
}
ngOnDestroy () {
  if (this.activeLanguageSubscription){
    this.activeLanguageSubscription.unsubscribe();
  }
  if (this.queryParamsSubscription) {
    this.queryParamsSubscription.unsubscribe();
  }
  if (this.contentSubscription) {
    this.contentSubscription.unsubscribe();
  }
}

}
