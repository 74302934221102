import { UserProfile } from './user-profile.model';
import { UserRole } from './user-role.model';

export class User {
    constructor(
        public id: number,
        public email: string,
        public fname: string,
        public lname: string,
        public pre_honorific : string,
        public post_honorific : string,
        public name_full_honorific : string,
        public picture: string,
        public profile: UserProfile,
        public roles: UserRole[],
        public created_at: Date,
        public password_set: boolean,
        public email_pending: string,
    ) {}
  }