import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LearnerCertification } from '../../../learner-certification.model';
import { CertificationDisplayService } from '../../certification-display.service';

@Component({
  selector: 'certification-display-list-slimmable',
  templateUrl: './certification-display-list-slimmable.component.html',
  styleUrls: ['./certification-display-list-slimmable.component.scss']
})
export class CertificationDisplayListSlimmableComponent implements OnInit {

  @Input() slimMode : boolean;
  learnerCertifications : LearnerCertification[];
  loading : boolean;
  learnerCertificationsSubscription : Subscription;

  constructor(private certificationDisplayService : CertificationDisplayService) { }


  getMyCertifications (){
    this.loading = true;
    this.learnerCertificationsSubscription = this.certificationDisplayService.getMyLearnerCertifications().subscribe(certifications => {
      this.loading = false;
      this.learnerCertifications = certifications;
    },
    error => {
      this.loading = false;
    })
  }

  ngOnInit(): void {
    this.getMyCertifications ();
  }

  ngOnDestroy () {
    if (this.learnerCertificationsSubscription){
      this.learnerCertificationsSubscription.unsubscribe();
    };
  }

}
