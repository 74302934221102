import { Component, OnInit, WritableSignal, computed, signal } from '@angular/core';
import { AvailableLanguage, LanguageService, MediaService, Topic, TopicService, WindowService } from '@frontend/common';
import { Subscription } from 'rxjs';



@Component({
  selector: 'culturettt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  topics = signal<Topic[] | undefined>([]);
  // topicsAsContentItems = computed(() => this.getTopicsAsContentItems(this.topicsByWeight()));
  topicsByWeight = computed(() => this.topics().sort((a, b) => b.weight - a.weight));
  thumbnailTransformations = signal<string>('ar_16:9,w_375/');
  activeLanguageObject = signal<AvailableLanguage | undefined>(undefined);
  subscriptions = signal<Subscription[]>([]);
  loading: boolean;
  cloudinary_base_url : WritableSignal<string> = signal<string>(null);
  
  constructor(
    private mediaService : MediaService,
    private topicService: TopicService,
    private languageService: LanguageService,
    private windowService: WindowService,
  ) {
      this.cloudinary_base_url.set(this.mediaService.cloudinary_base_url);

    }
    
  
    getTopics() {
      this.loading = true;
      const topicsSubscription = this.topicService.getTopics('interculturalist', null).subscribe((topics) => {
        this.topics.set(topics);
        this.loading = false;
      },
      (error) => {
        console.error('Error getting topics:', error);
        this.loading = false;

      });
      this.subscriptions().push(topicsSubscription);

    }
    getThumbnail(topic:Topic){
        return topic.media?.find(media => media.category === 'thumbnail' && media.type==='main');
    }

    // getTopicsAsContentItems(topics:Topic[]){
    //   const contentItems = this.topicService.makeContentItems(topics).map((contentItem) => {
    //     return {...contentItem,meta:{buttons:[
    //     {classes:'btn btn-primary',labelTranslationKey:'common.explore',type:''} as Button,
    //     {classes:'btn btn-primary',labelTranslationKey:'content.get_tips',type:''} as Button,
    //   ]}
    //   }});
    //   return contentItems
    // }


  ngOnInit(): void {
    this.windowService.goToTop();
    this.getTopics();
    this.activeLanguageObject.set(this.languageService.activeLanguageObjectSynchronously);
    const activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
        // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
        if (this.activeLanguageObject() && newActiveLanguage?.languageKey !== this.activeLanguageObject().languageKey){
          this.activeLanguageObject.set(newActiveLanguage);
          this.getTopics();
        }
    });
    this.subscriptions().push(activeLanguageSubscription);

  }
  ngOnDestroy(): void {
    this.subscriptions().forEach(sub => sub.unsubscribe());
  }


}
