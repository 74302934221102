<span class="{{classesTopLevel}}">
    <span *ngIf="icon.icon_type == 'fa-brands' || icon.icon_type == 'fab' || icon.icon_type == 'fa-solid' || icon.icon_type == 'fas' || icon.icon_type == 'fa-regular' || icon.icon_type == 'far'">
        <!-- <mdb-icon fab [icon]="icon.icon_name" [class]="icon.icon_colour"> </mdb-icon> -->
        <!-- <mdb-icon fab [icon]="icon.icon_name" [style.color]="icon.icon_colour" class="{{classesIconLevel}}"> </mdb-icon> -->
        <i class="{{icon.icon_type+' fa-'+icon.icon_name+' '+classesIconLevel}}" [ngStyle]="{'color':icon.icon_colour}"></i>
    </span>
    <!-- <span *ngIf="icon.icon_type == 'fas'">
        <i class="fas {{icon.icon_name+' '+classesIconLevel}}" [ngStyle]="{'color':icon.icon_colour}"></i>
        <mdb-icon fas [icon]="icon.icon_name" [style.color]="icon.icon_colour" class="{{classesIconLevel}}"> </mdb-icon>
    </span>
    <span *ngIf="icon.icon_type == 'far'">
        <mdb-icon far [icon]="icon.icon_name" [style.color]="icon.icon_colour" class="{{classesIconLevel}}"> </mdb-icon>
    </span> -->
    <span *ngIf="icon.icon_type == 'custom'" class="{{classesIconLevel}}">
        <svg *ngIf="link?.type == 'wechat'"  [attr.height]="custom_icon_height"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 1 24 24">
            <path [attr.fill]="icon.icon_colour" d="M16.5,8V8l.15,0h0a.55.55,0,0,0,.51-.5.5.5,0,0,0-.09-.29C15.92,4.09,12.59,2,8.75,2,3.93,2,0,5.36,0,9.5a7.06,7.06,0,0,0,3.09,5.71l-1,2.07a.5.5,0,0,0,.7.66l2.65-1.51a10,10,0,0,0,2.22.51.5.5,0,0,0,.54-.61A5.8,5.8,0,0,1,8,15C8,11.14,11.81,8,16.5,8ZM11,6a1,1,0,1,1-1,1A1,1,0,0,1,11,6ZM6,8A1,1,0,1,1,7,7,1,1,0,0,1,6,8Z"/>
            <path [attr.fill]="icon.icon_colour" d="M24,15c0-3.31-3.36-6-7.5-6S9,11.69,9,15s3.36,6,7.5,6a9.23,9.23,0,0,0,2.3-.29l2.48,1.24a.5.5,0,0,0,.22.05.5.5,0,0,0,.46-.69l-.68-1.7A5.64,5.64,0,0,0,24,15ZM14,14a1,1,0,1,1,1-1A1,1,0,0,1,14,14Zm5,0a1,1,0,1,1,1-1A1,1,0,0,1,19,14Z"/>
        </svg>
    </span>
</span>
