import { CloudinaryMediaAsset } from "./cloudinary-media-asset.model";

  export class CarouselItem {
    title : string;
    description : string;
    bgImageUrl : string;
    bgImageStyles : string;
    bgImageContainerStyles : string;
    bgImageAlt : string;
    interval: number; // milliseconds
    recommendedTransformations : string; // 'ar_16:9'
    media?: CloudinaryMediaAsset
  }