import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressIndicatorSliderComponent } from './progress-indicator-slider.component';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    ProgressIndicatorSliderComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
  ],
  exports : [ProgressIndicatorSliderComponent]
})
export class ProgressIndicatorSliderModule { }
