<div>
  <div class="container" *transloco="let t">

    <alert [type]="'warning'" [dismissOnTimeout]="3500" *ngIf="error">
      <span *transloco="let t">
        <span *ngIf="error_code">{{t('error.report_code_support', { code: error_code})}}</span>
        <span *ngIf="!error_code">{{t(error)}}</span>
      </span>
    </alert>


    <div *ngIf="survey" class="language-preferences">
      <div class="row">

        <div class="select-language-box">
          <p class="h4 font-weight-bold mb-3">{{t('survey.choose_language_questions')}}</p>          
          <hr>
          <multisite-avatar-pill *ngFor="let language of availableLanguagesForThisSurvey()"
            (selected)="setActiveLanguage(language)"
            [fallbackImageUrl]="getFallbackFlagUrl()"
            [fallbackTranslationKey]="'common.no_results'"
            [cursorNone]="isAnythingLoading()"
            [imageUrl]="language.flag_path"
            [active]="activeLanguageObjectForSurvey?.languageKey === language.languageKey"
            [translationKey]="language.name">
          </multisite-avatar-pill>
        </div>

        <div class="select-language-box" *ngIf="grammaticalFormsArrayForThisSurveyInCurrentLanguage().length > 1">
          <p class="h4 font-weight-bold mb-1">{{t('survey.choose_grammatical_form')}}</p>
          <p class="mb-3" *ngIf="hasRole('Admin')">{{t('survey.subject_of_questions')}}<strong class="ml-1">{{t('survey.subject_you')}}</strong>
          </p>
          <hr>
          <ul class="form-of-words" *ngFor="let grammatical_form of languagePreferenceOptionsAsArray()">
            <li class="form-of-words-item" [ngClass]="{active: isPreferredGrammaticalForm(grammatical_form.key)}" (click)="selectGrammaticalForm(grammatical_form.key)">
              <i class="fa-solid fa-angle-right icon"></i>{{ grammatical_form.value }}
            </li>
            <!-- TODO implement this element as an HTML button so that we can disable it -->
          </ul>
          <div class="bg-icon">
            <i class="fa-solid fa-comments"></i>
          </div>
        </div>



        <div class="mt-4 mt-lg-0">

        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">&nbsp;</div>
      <div class="col-auto">
        <button class="btn btn-primary" (click)="startSurvey()" [disabled]="isAnythingLoading() || !isGrammaticalFormSelected()">
          {{ t('common.start') }}
        </button>
      </div>
    </div>

    <div *ngIf="isAnythingLoading()">
      <multisite-loader-globe-animation-detail [width]="100" [height]="100">
      </multisite-loader-globe-animation-detail>
    </div>
  </div>
</div>