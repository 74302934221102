import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

import { Faq } from '@frontend/core';
import { LanguageService } from '@frontend/common';



@Injectable({
  providedIn: 'root'
})
export class FaqService {
  
  activeLanguageSubscription: Subscription;
  faqs: Faq[] = [];

  constructor(
    private http : HttpClient,
    private languageService: LanguageService,

    ) {
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
  }
  
  clearTranslations() {
     this.faqs = [];
  }
  clearData() {
    this.clearTranslations();
  }

   transformFaq (faq) : Faq {
    faq.created_at = new Date(faq.created_at);
    faq.updated_at = new Date(faq.updeated_at);
    let transformedFaq : Faq = faq;
    return transformedFaq;
  };
  transformFaqs (faqs) : Faq[] {
    let transformedFaqs : Faq[] = [];
    faqs.forEach(p=>{
      transformedFaqs.push(this.transformFaq(p));
    });
    return transformedFaqs;
  }
  cacheFaq(faq){
    let found : Faq = this.faqs.find(f=>f.slug === faq.slug);
    if(!found){
      this.faqs.push(faq);
    }
    return faq;
  }

  cacheFaqs (faqs : Faq[]) : Faq[]{
    faqs.forEach(faq=>{
      this.cacheFaq(faq);
    })
    return faqs;
  };

  getFaq (slug: string){
    if (!slug) { throw 'slug is required';};
    let url = 'api/v1/faqs/'+slug;
    let cachedFaq = this.faqs.find(f=>f.slug === slug);
    if (cachedFaq){
      return of(cachedFaq);
    }
    return this.http.get<{'data': Faq}>(url)
      .pipe(
        map(response =>{
          if (response && response.data ){
            let faq = this.transformFaq(response.data);
            return this.cacheFaq(faq);
          };
        })
      )
  };
  getFaqsByTags (tags: string){
    if (!tags) { throw 'tags are required';};
    let url = 'api/v1/faqs?tags='+tags;
    return this.http.get<{'data': Faq[]}>(url)
        .pipe(
          map(response =>{
            if (response && response.data ){
              let faqs = this.transformFaqs(response.data);
              return this.cacheFaqs(faqs);
            };
          })
        )
  };
};