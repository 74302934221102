import { Component, OnInit, Input } from '@angular/core';
import { Link } from '@frontend/core';


@Component({
  selector: 'frontend-link-social-icon',
  templateUrl: './link-social-icon.component.html',
  styleUrls: ['./link-social-icon.component.scss']
})
export class LinkSocialIconComponent implements OnInit {

  icon_type : string;
  icon_name : string;
  icon : {icon_type:string,icon_name:string,icon_colour:string};
  default_colour : string = '#6c757d'; // grey
  custom_icon_height : string = '21px'; // TODO - make dynamic

  // Colours are unfinished: #CCCCCC means not yet defined

  lookup = [
    {'category':'profile', 'type':'organisation','icon_type':'fa-regular','icon_name':'building','icon_colour':this.default_colour},
    {'category':'profile', 'type':'publication','icon_type':'fa-solid','icon_name':'book','icon_colour':this.default_colour},
    {'category':'profile', 'type':'network','icon_type':'fa-solid','icon_name':'user-plus','icon_colour':this.default_colour},
    {'category':'profile', 'type':'project','icon_type':'fa-solid','icon_name':'check-circle','icon_colour':this.default_colour},
    {'category':'social', 'type':'facebook','icon_type':'fa-brands','icon_name':'facebook-f','icon_colour':'#3b5998'},
    {'category':'social', 'type':'linkedin','icon_type':'fa-brands','icon_name':'linkedin','icon_colour':'#0072B1'},
    {'category':'social', 'type':'youtube','icon_type':'fa-brands','icon_name':'youtube','icon_colour':'#c4302b'},
    {'category':'social', 'type':'twitter','icon_type':'fa-brands','icon_name':'twitter','icon_colour':'#1DA1F2'},
    {'category':'social', 'type':'renren','icon_type':'fa-brands','icon_name':'renren','icon_colour':'#01579b'},
    {'category':'social', 'type':'skype','icon_type':'fa-brands','icon_name':'skype','icon_colour':'#00aff0'},
    {'category':'social', 'type':'snapchat','icon_type':'fa-brands','icon_name':'snapchat','icon_colour':'#f4cb0d'},
    {'category':'social', 'type':'slideshare','icon_type':'fa-brands','icon_name':'slideshare','icon_colour':'#0273b7'},
    // {'category':'social', 'type':'tencent-weibo','icon_type':'fa-brands','icon_name':'tencent-weibo','icon_colour':'#CCCCCC'}, // closed down
    {'category':'social', 'type':'wechat','icon_type':'custom','icon_name':'wechat','icon_colour':'#7bb32e'}, // wechat not available
    {'category':'social', 'type':'tumblr','icon_type':'fa-brands','icon_name':'tumblr','icon_colour':'#2f4155'},
    {'category':'social', 'type':'reddit','icon_type':'fa-brands','icon_name':'reddit','icon_colour':'#f74300'},
    {'category':'social', 'type':'twitch','icon_type':'fa-brands','icon_name':'twitch','icon_colour':'#9147ff'},
    {'category':'social', 'type':'weibo','icon_type':'fa-brands','icon_name':'weibo','icon_colour':'#ce292b'},
    {'category':'social', 'type':'whatsapp','icon_type':'fa-brands','icon_name':'whatsapp','icon_colour':'#25D366'},
    {'category':'social', 'type':'viber','icon_type':'fa-brands','icon_name':'viber','icon_colour':'#784f99'},
    {'category':'social', 'type':'xing','icon_type':'fa-brands','icon_name':'xing','icon_colour':'#026466'},
    {'category':'social', 'type':'yandex','icon_type':'fa-brands','icon_name':'yandex','icon_colour':'#FF0000'},
    {'category':'social', 'type':'yandex-international','icon_type':'fa-brands','icon_name':'yandex-international','icon_colour':'#e61400'},
    {'category':'social', 'type':'instagram','icon_type':'fa-brands','icon_name':'instagram','icon_colour':'#405DE6'},
    {'category':'general', 'type':'link','icon_type':'fa-solid','icon_name':'link','icon_colour':this.default_colour},
    {'category':'general', 'type':'lightbulb','icon_type':'fa-regular','icon_name':'lightbulb','icon_colour':this.default_colour},
  ]

  @Input() link : Link;
  @Input() colourCode : string;
  @Input() classesTopLevel : string;
  @Input() classesIconLevel : string;

  constructor() { }

  ngOnInit(): void {

    let selectedIcon
    if (this.link?.category && this.link?.type){
      selectedIcon = this.lookup.find(ic=>ic.category == this.link.category && ic.type == this.link.type);
    };
    this.icon = selectedIcon ? selectedIcon : this.lookup.find(ic=>ic.category == 'general' && ic.type == 'link');
    if (this.colourCode){
      this.icon.icon_colour= this.colourCode;
    }
  }

}
