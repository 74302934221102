<div class="my-4" *transloco="let t">
  <alert [type]="'danger'" [dismissOnTimeout]="3500" *ngIf="error">
    <span *transloco="let t">{{ t(error) }}</span>
  </alert>

  <div class="branding-control">
    <div class="header" [ngClass]="{'header-border':!disabled && !collapsed}">
      <h5>
        {{ t(logoDescriptionShortTranslationKey) }}
        <span class="h6 text-muted">{{logo
          ? t('branding.change_logo')
          : t('branding.add_logo')
          }}</span>
      </h5>
      <span class="my-3 text-center">
        <a class="btn btn-sm btn-link mr-3" (click)="onToggleCollapsed()">{{collapsed
          ? t('common.open')
          : t('common.close')
          }}</a>
      </span>
    </div>

    <div [collapse]="collapsed">
      <div class="d-flex flex-column gap-4">

        <!-- FALLBACK LOGO or SAVED LOGO -->
  
        <div>
          <div *ngIf="logo && pending_logo" class="h6 mt-3">
            <p>{{ t('branding.saved_logo') }}</p>
          </div>
    
          <div class="preview">
            <img [class]="navbarPreviewClass" [src]="
            cloudImagesBaseUrl +
            'w_1000,c_fill/' +
            logoBlankExampleImageAssetUrl
          " alt="Top navigation bar with logo missing" />
            <img *ngIf="logo; else elseBlock1" [src]="imageWithTransformations(logo, 'w_250,c_fill/')"
              [class]="logoPreviewClass" alt="This is the logo you selected" />
            <ng-template #elseBlock1>
              <div [class]="logoPreviewClass" [ngClass]="{'hide-logo-text':fallbackLogoPreviewHideLogoText}">
                <multisite-website-logos [logo]="'icpendulum'" [lightColourOn]="fallbackLogoPreviewLightColourOn"
                  [darkColourOn]="!fallbackLogoPreviewLightColourOn"></multisite-website-logos>
              </div>
            </ng-template>
    
          </div>
    
          <a *ngIf="logo && !disabled && !loading && !pending_logo" (click)="toggleMarkSavedLogoForDeletion()"
            class="text-danger ml-3">
            <span class="fa fa-xmark"></span>
            <span *ngIf="!pendingUpdateRemoveSavedLogo">{{
              t('common.delete')
              }}</span>
            <span *ngIf="pendingUpdateRemoveSavedLogo">{{
              t('branding.delete_when_saving')
              }}</span>
          </a>
        </div>
  
  
        <!-- PENDING LOGO - uploaded but not saved yet -->
  
        <div>
          <div *ngIf="!disabled && pending_logo" class="h6">
            <p>{{ t('content_management.unsaved_changes') }}</p>
          </div>
    
          <div class="preview" *ngIf="!disabled && pending_logo">
            <img [class]="navbarPreviewClass" [src]="
            cloudImagesBaseUrl +
            'w_1000,c_fill/' +
            logoBlankExampleImageAssetUrl
          " alt="Top navigation bar with logo missing" />
    
            <img [src]="
            imageWithTransformations(pending_logo, 'w_350,c_fill/')
          " [class]="logoPreviewClass" alt="This logo will be saved when you save the brand" />
          </div>
          <div class="" *ngIf="!disabled && pending_logo">
            <a (click)="onRemovePendingItem()" class="text-danger">
              <span class="fa fa-xmark"></span>
              {{ t('content_management.cancel_unsaved_changes') }}</a>
          </div>
        </div>
  
        <div class="edit" *ngIf="!disabled">
  
          <multisite-uploader-single-file [requiredFileType]="'image/*'" [imageTransformations]="'w_350,c_fill,ar_16:9/'"
            [fileInstructionsTranslationKey]="logoDescriptionTranslationKey" [fileDescriptionTranslationKey]=""
            [remoteFileDescriptionTranslationKey]="'content_management.image_already_published'"
            (fileFormData)="onFormDataChanged($event)" [resetFormOnChange]="resetFormOnChange"
            [askForLicenceAndCredit]="false" [emitOnChange]="false" [uploadButtonTranslationKey]="'common.confirm'"
            [disabled]="disabled || loading" (cancelUpload)="cancelUploadFile($event)" [errorMessage]="error">
          </multisite-uploader-single-file>
  
          <p class="my-3 text-center">
            <a class="btn btn-sm btn-link mr-3" (click)="helpCollapsed = !helpCollapsed">{{ t('common.more_info')
              }}&nbsp;|&nbsp;{{
              helpCollapsed
              ? t('common.show')
              : t('common.hide')
              }}</a>
          </p>
  
          <div class="mt-2" [collapse]="helpCollapsed">
            <div class="well well-lg card card-block card-header">
              <h4>{{ t('branding.logo_guide') }}</h4>
              <ul>
                <li *ngFor="
                let text of helpTextIterator;
                let i = index
              ">
                  {{ t(helpTextIterationKey + i) }}
                </li>
              </ul>
              <div class="my-2">
                <a (click)="openModal('Margin')">
                  <img [src]="
                  cloudImagesBaseUrl +
                  'w_450,c_fill/' +
                  logoExampleImagesAssetUrlMargin
                " alt="Two logos, one with the margins cropped away, marked as correct" />
                </a>
              </div>
              <div class="my-2">
                <a (click)="openModal('Transparency')">
                  <img [src]="
                  cloudImagesBaseUrl +
                  'w_450,c_fill/' +
                  logoExampleImagesAssetUrlTransparency
                " alt="Two logos, one with a transparent background marked as correct" />
                </a>
              </div>
            </div>
          </div>
        </div>
  
  
  
      </div>
    </div>


  </div>
</div>
<ng-template #imageEnlargedModal>
  <div class="modal-header" *transloco="let t">
    <h4 id="dialog-static-name" class="modal-title pull-left">
      {{ t('branding.logo') }}
    </h4>
    <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="modalRef?.hide()"></button>
  </div>
  <div class="modal-body" *transloco="let t">
    <img class="img-responsive" *ngIf="logoExampleImageSelected"
      [src]="cloudImagesBaseUrl + 'w_1000,c_fill/' +logoExampleImageSelected" [alt]="logoExampleImageSelected" />
  </div>
</ng-template>