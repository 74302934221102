import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MediaService } from '../../content';

@Component({
  selector: 'multisite-brand-logo-editor',
  templateUrl: './brand-logo-editor.component.html',
  styleUrls: ['./brand-logo-editor.component.scss']
})
export class BrandLogoEditorComponent implements OnInit {

  
  @Input() logoDescriptionShortTranslationKey : string; // 'branding.logo_square_inverse_description_short'
  @Input() logoDescriptionTranslationKey : string; // 'branding.logo_square_inverse_description'
  @Input() fallbackLogoPreviewLightColourOn : boolean; // If true, the SVG logo will be white
  @Input() fallbackLogoPreviewHideLogoText : boolean; // If true, only the Logo symbol will appear, not the text
  @Input() pending_logo : string;
  @Input() pendingUpdateRemoveSavedLogo : boolean;
  @Input() disabled : boolean;
  @Input() loading : boolean; // TODO - use this to disable stuff
  @Input() error : string;
  @Input() logo : string;
  @Input() logoPreviewClass : string; // 'preview-logo' or 'preview-logomark'
  @Input() navbarPreviewClass : string; // 'preview-bg w-lg-100 w-50' or 'preview-bg'
  @Input() cloudImagesBaseUrl: string; // 'https://res.cloudinary.com/cebt/image/upload/'
  @Input() logoBlankExampleImageAssetUrl : string; // 'v1689603629/UI/branding-logo-blank-light-mode-desktop.png'
  @Input() logoExampleImagesAssetUrlMargin : string; // 'v1688069489/UI/branding-logo-example-margin_ltsopi.jpg'
  @Input() logoExampleImagesAssetUrlTransparency : string; // 'v1688131886/UI/branding-logo-inverse-example-transparency_lybno7.jpg'
  @Input() resetFormOnChange : number; // an integer counter starting at 0
  @Input() helpTextIterator : number[]; // [1,2,3,4], could be array of anything. Just used for running a foreach loop though the help text bullet points
  @Input() helpTextIterationKey : string; // 'branding.logo_square_inverse_guide_' will be 'branding.logo_square_inverse_guide_1' etc
  @Output() formDataChanged = new EventEmitter<FormData>();
  @Output() removePendingItem = new EventEmitter();
  @Output() markSavedLogoForDeletion = new EventEmitter();
  @ViewChild('imageEnlargedModal') imageEnlargedModal;
  modalRef?: BsModalRef;
  logoExampleImageSelected: string = 'banner'; // 'banner_inverse'
  @Input() collapsed : boolean; // the whole element body
  @Output() toggleCollapsed = new EventEmitter();
  helpCollapsed : boolean = true; // just the help panel

  // TODO: how can we restore these?

  // fileUploadError: string;
  // fileUploadSuccess: string;
  // uploadProgress: number; // should be an object with a property for each form

  constructor( private modalService: BsModalService, private mediaService: MediaService) {};

  imageWithTransformations(file_url:string,transformations:string){
    return this.mediaService.insertCloudinaryTransformationsIntoMediaUrl(file_url,transformations);
  }
  openModal(type:string) {
    this.logoExampleImageSelected = this['logoExampleImagesAssetUrl'+type];
    this.modalRef = this.modalService.show(
      this.imageEnlargedModal,
      Object.assign({}, { class: 'image-enlarge-modal' })
    );
  }

  onToggleCollapsed(){
    this.toggleCollapsed.emit()
  }
  toggleMarkSavedLogoForDeletion(){
    this.markSavedLogoForDeletion.emit()
  }

  onFormDataChanged (formData : FormData){
    this.formDataChanged.emit(formData)
  }
  onRemovePendingItem (){
    this.removePendingItem.emit()
  }
  cancelUploadFile (bool : boolean){
    // TODO - restore the fileuploadProgress / cancel functionalities
    // this.fileUploadSuccess = null;
    // if (this.fileUploadSubscription){
    //   this.fileUploadSubscription.unsubscribe();
    // }
    // for (let category in this.uploadProgress){
    //   for (let type in this.uploadProgress[category]){
    //     this.uploadProgress[category][type] = null;
    //   }
    // }
  }
  ngOnInit(): void {
    this.error = null;
  }
}
