import { Injectable } from '@angular/core';
import { PlaylistTextItemGroup } from './playlist-text-item-group.model';
import { PlaylistTextItem } from './playlist-text-item.model';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  constructor() { }

  findPlaylistItem(item_identifier_numeric : number, item_identifier_string : string, playlistGroup : PlaylistTextItemGroup[]){
    let playlistItem : PlaylistTextItem;
    let playlistItemGroupIndex : number;
    playlistGroup.forEach((plg,i) => {
      let foundItem = plg.items.find(item => (item_identifier_string ? item.identifier_string === item_identifier_string : false) || ((item_identifier_numeric === 0 || item_identifier_numeric) ? item.identifier_numeric === item_identifier_numeric : false));
      if (foundItem){
        playlistItemGroupIndex = i;
        playlistItem = foundItem;
      }
    });
    return {'item':playlistItem, 'itemGroupIndex': playlistItemGroupIndex};
  }

}
