import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconArrayComponent } from './icon-array.component';
import { TranslocoModule } from '@jsverse/transloco';
import { TooltipModule } from 'ngx-bootstrap/tooltip';



@NgModule({
  declarations: [
    IconArrayComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    TooltipModule,
  ],
  exports: [
    IconArrayComponent
  ]
})
export class IconArrayModule { }
