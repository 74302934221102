import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Link } from '@frontend/core';
import { LinksService } from '@frontend/shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, merge, Subscription } from 'rxjs';
import { Hub, HubService } from '..';
import { MediaService } from '../../content';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
import { Setting } from '../../settings';
import { DataProcessingService, WindowService } from '../../utilities';
// import { PageTitleService } from '../../navigation/title/title.service';

@Component({
  selector: 'multisite-hub-cco',
  templateUrl: './hub-cco.component.html',
  styleUrls: ['./hub-cco.component.scss'],
})
export class HubCultureConnectorComponent implements OnInit, OnDestroy {

  hub: Hub;
  loading: boolean;
  hubSubscription: Subscription;
  hub_settingsSubscription: Subscription;
  formStatusSubscription: Subscription;
  linksSubscription: Subscription;
  links: Link[] = [];
  settingsForm: FormGroup;
  referral_code: string;
  cloudinary_base_url : string; // 'https://res.cloudinary.com/cebt/image/upload/'
  cco_links_screenshot_path: string = 'UI/cultureconnector-links-in-ciprofile-feedback-arrows.jpg';
  cco_home_url : string;
  cco_shop_url : string;
  cco_shop_product_url : string;
  @ViewChild('imageEnlargedModal') imageEnlargedModal;
  modalRef?: BsModalRef;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hubService: HubService,
    private dataProcessingService: DataProcessingService,
    private breadcrumbService : BreadcrumbService,
    private linksService: LinksService,
    private windowService: WindowService,
    private mediaService: MediaService,
    private modalService: BsModalService, 
  ) {
    this.cloudinary_base_url = this.mediaService.cloudinary_base_url;
    this.cco_home_url = 'https://www.cultureconnector.com';
    this.cco_shop_url = this.cco_home_url+'/#/shop';
    this.cco_shop_product_url = this.cco_shop_url+'/products/coaching-and-training-gold';
  }

  openModal() {
    this.modalRef = this.modalService.show(
      this.imageEnlargedModal,
      Object.assign({}, { class: 'image-enlarge-modal' })
    );
  }
  getReferralCode(hub:Hub) : string {
    return hub?.settings?.find(s=>s.setting_name==='referral_code' && s.category === 'cco')?.setting_value;
  }
  getSettings() {
    this.loading = true;
    this.hub_settingsSubscription = this.hubService
      .getSettings(this.hub)
      .subscribe(
        (response) => {
          this.hub = response;
          this.makeForm();
          this.defineFormValueChangesHandler();
          this.referral_code = this.getReferralCode(response);
          this.loading = false;
          this.getLinks();
        },
        (error) => {
          this.loading = false;
        }
      );
  }
  updateSettings(deletedOrChangedSettings : Setting[]) {
    this.loading = true;
    this.hub_settingsSubscription = this.hubService
      .updateSettings(this.hub,deletedOrChangedSettings)
      .subscribe(
        (response) => {
          this.hub = response;
          this.patchSettingsValuesIntoForm(response.settings);
          this.referral_code = this.getReferralCode(response);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }
  getLinks(){
    let linksRequest = [
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
    ]
    this.linksSubscription = this.linksService.getDynamicLinks(linksRequest).subscribe(links =>{
      this.links = links;
    })
  }
  getHub (slug){
    this.loading = true;
    this.hubService.getManagedHub(slug).subscribe(response => {
      this.loading = false;
      this.hub = response;
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'hub',fragmentName:this.hub.name});
      if (this.hub && !this.hub.settings){
        this.getSettings();
      }
    })
  }
  disableLinks(value){
    if (value){
      this.settingsForm.get('redirect_general_link').disable();
      this.settingsForm.get('redirect_personalised_links').disable();
    } else {
      this.settingsForm.get('redirect_general_link').enable();
      this.settingsForm.get('redirect_personalised_links').enable();
    }
  }
  patchSettingsValuesIntoForm (settings:Setting[]){
    const settingsPatchObject = {};
    if (!settings?.length){ return; };
    const controlNames = Object.keys(this.settingsForm.controls);
    controlNames.forEach(controlName => {
      let setting = settings.find(s=>s.category==='cco'&&s.setting_name===controlName);
      if (setting && this.settingsForm.controls[controlName].value != setting.setting_value){
        settingsPatchObject[controlName] = setting.setting_value;
      }
      
    });
    this.settingsForm.patchValue(settingsPatchObject);
  }
  defineFormValueChangesHandler (){
    const generalLinkChanges = this.settingsForm.controls.redirect_general_link.valueChanges;
    const personalisedLinksChanges = this.settingsForm.controls.redirect_personalised_links.valueChanges;
    const formStatusSubscription : Subscription = merge(generalLinkChanges,personalisedLinksChanges).pipe(
      debounceTime(3000)
    ).subscribe((thing) => {

      if (this.settingsForm.controls.hide_links.value){
        return null;
      }
      const updatedSettingsArray : Setting[] = [];
      const redirectKeys = ['redirect_general_link','redirect_personalised_links'];
      let redirect_settings : {[key:string] : {storedSetting : Setting, formControl : AbstractControl}} = {};
      redirectKeys.forEach(redirectKey => {
        
        redirect_settings[redirectKey] = {
          storedSetting : this.hub?.settings.find(s=>s.category==='cco'&&s.setting_name==redirectKey),
          formControl: this.settingsForm.controls[redirectKey]
        }

        let new_setting : Setting;

        if (redirect_settings[redirectKey].formControl.value){

          if (
            redirect_settings[redirectKey].formControl.valid &&
            redirect_settings[redirectKey].formControl.value !== redirect_settings[redirectKey].storedSetting?.setting_value){
  
  
            new_setting = new Setting(
              redirect_settings[redirectKey].storedSetting?.id,
              redirectKey,
              redirect_settings[redirectKey].formControl.value,
              'cco',
              'display',
              false,
              null
            );
          }
  
        } else if (redirect_settings[redirectKey].storedSetting?.setting_value){
          new_setting = new Setting(
            redirect_settings[redirectKey].storedSetting.id,
            redirectKey,
            null,
            'cco',
            'display',
            true,
            null
          );
        }
        if(new_setting){
          updatedSettingsArray.push(new_setting);
        }
      });
      if(updatedSettingsArray?.length){
        this.updateSettings(updatedSettingsArray);
      }
    });
  }
  makeForm(): void {
    this.loading = false;
    let hideLinksInSettings = !!this.hub?.settings.find(s=>s.category==='cco'&&s.setting_name==='hide_links')?.setting_value;
    let generalLinkInSettings = this.hub?.settings.find(s=>s.category==='cco'&&s.setting_name==='redirect_general_link')?.setting_value;
    let personalisedLinksInSettings = this.hub?.settings.find(s=>s.category==='cco'&&s.setting_name==='redirect_personalised_links')?.setting_value;
    this.settingsForm = new FormGroup({
      hide_links: new FormControl(hideLinksInSettings),
      redirect_general_link: new FormControl(generalLinkInSettings, [Validators.pattern(this.dataProcessingService.urlRegex)]),
      redirect_personalised_links: new FormControl(personalisedLinksInSettings, [Validators.pattern(this.dataProcessingService.urlRegex)]),
    });
    this.disableLinks(hideLinksInSettings);


    this.settingsForm.controls.hide_links.valueChanges.subscribe(
      (value) => {
        this.disableLinks(value);
        console.log('hide links value changed to: ',value);
        let hideLinksInSettings = this.hub?.settings.find(s=>s.category==='cco'&&s.setting_name==='hide_links');
        let newSetting : Setting;
        if(hideLinksInSettings){
          newSetting = new Setting (hideLinksInSettings.id,hideLinksInSettings.setting_name,value,hideLinksInSettings.category,hideLinksInSettings.type,hideLinksInSettings.disabled,hideLinksInSettings.deleted_at ?? null);
        } else {
          newSetting = new Setting (null,'hide_links',value,'cco','display',null,null);
        }
        this.updateSettings([newSetting]);
      }
    );
  }
  ngOnInit(): void {
    this.route.url.subscribe(() => {
      let fullUrlParts = this.router.routerState.snapshot.url
        .toLowerCase()
        .split('/')
        .filter((s) => s);
      this.getHub(fullUrlParts[1]);
    });
    this.windowService.goToTop();
  }
  ngOnDestroy () : void {
    if (this.hubSubscription){
      this.hubSubscription.unsubscribe();
    }
    if (this.hub_settingsSubscription){
      this.hub_settingsSubscription.unsubscribe();
    }
    if (this.linksSubscription){
      this.linksSubscription.unsubscribe();
    }
    if (this.formStatusSubscription){
      this.formStatusSubscription.unsubscribe();
    }
  }
}
