import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Icon } from './icon.model';
import { Tag } from '@frontend/core';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  icons: Icon[];
  tags: Tag[];

  constructor(private http: HttpClient) { }

  getAllIcons() { // This is intended for Admin/Designer use
    if (this.icons) {
      return of(this.icons);
    }

    return this.http.get<{data:Icon[]}>('api/v1/icons').pipe(
      map((response) => {
        if (response?.data?.length) {
          // response.data = this.transformTopics(response.data);
          this.icons = response.data;
          return response.data;
        }
      })
    );
  }
  getTagsWithIcons() { // This is intended for Admin/Designer use
    if (this.tags) {
      return of(this.tags);
    }

    return this.http.get<{data:Tag[]}>('api/v1/tags-with-icons').pipe(
      map((response) => {
        if (response?.data?.length) {
          // response.data = this.transformTopics(response.data);
          this.tags = response.data;
          return response.data;
        }
      })
    );
  }
}
