<div class="container">
  <div class="d-flex justify-content-center">
    <ng-template transloco let-t class="mt-5">
    <div class="card mt-5">
      <div class="card-body">
        <h1 class="card-title"> {{ t('common.coming_soon') }} </h1>
        <p>{{ t('website_interface.working_on_it') }}</p>
      </div>
    </div>
  </ng-template>


  </div>
  
</div>