import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ContentItem, PaginatedContentItems } from '../../../../content';

@Component({
  selector: 'multisite-content-list-formats',
  templateUrl: './content-list-formats.component.html',
  styleUrls: ['./content-list-formats.component.scss']
})
export class ContentListFormatsComponent implements OnChanges {

  @Input() loading: boolean;
  @Input() paginatedContent : PaginatedContentItems;
  @Input() contentItems : ContentItem[];
  @Input() contentItemsDisplay : ContentItem[] = []; // Sliced part of the contentItems which is visible
  @Input() contentItemsChunkSize : number;
  @Input() imageTransformations : string = 'w_275,ar_16:9/'; // 'w_700,c_fill,ar_16:9/'
  @Output() getContent = new EventEmitter<number>();
  @Output() itemSelected = new EventEmitter<ContentItem>();

  constructor(){}

  onItemSelected(item:ContentItem){
    this.itemSelected.emit(item);
  }
  doGetPaginatedContent(whichPage){
    this.getContent.emit(whichPage);
  }
  showMoreOrLess(){
    if (this.contentItems?.length > this.contentItemsDisplay.length + this.contentItemsChunkSize){
      this.contentItemsDisplay = this.contentItems.slice(0,this.contentItems.length+this.contentItemsChunkSize);
    } else if (this.contentItemsDisplay.length === this.contentItems.length) {
      this.contentItemsDisplay = this.contentItems.slice(0,this.contentItemsChunkSize);
    } else {
      this.contentItemsDisplay = this.contentItems;
    }
  }
  ngOnChanges(changesObject){
    if (changesObject.contentItems?.currentValue?.length && (!changesObject.contentItems.previousValue || !changesObject.contentItems.previousValue.length)){
      if (this.contentItemsChunkSize){
        this.contentItemsDisplay = this.contentItems.slice(0,this.contentItemsChunkSize-1);
      } else {
        this.contentItemsDisplay = this.contentItems;
      }
    }
  }

}
