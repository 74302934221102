import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselPageBodyFillComponent } from './carousel-page-body-fill.component';
import { TranslocoModule } from '@jsverse/transloco';
// import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { CarouselModule } from 'ngx-bootstrap/carousel';



@NgModule({
  declarations: [CarouselPageBodyFillComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    // MdbCarouselModule,
    CarouselModule,
  ],
  exports:[
    CarouselPageBodyFillComponent
  ]
})
export class CarouselPageBodyFillModule { }
