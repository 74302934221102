<ng-template #guestImportModal>
  <div class="modal-header" *transloco="let t">
    <h4 class="modal-title pull-left">{{t('guest_import.title')}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body" *transloco="let t">
    <p class="my-2">{{t('guest_import.question')}}</p>
    <alert class="mb-2 d-block" *ngIf="error" [dismissible]="true" [type]="'warning'"><span
        [innerHTML]="error | transloco"></span></alert>
    <div class="d-flex gap-2 flex-wrap">
      <button class="btn btn-secondary" [disabled]="loading || confirmingRemoval"
        (click)="confirmRemoval()">{{t('guest_import.no_remove')}}</button>
      <button class="btn btn-primary" [disabled]="loading || confirmingRemoval"
        (click)="importActivity(guest?.uuid)">{{t('guest_import.yes_import')}}</button>
    </div>
    <div *ngIf="confirmingRemoval" class="mt-3">
      <h6>{{t('common.are_you_sure')}}</h6>
      <button class="btn btn-primary" [disabled]="loading"
        (click)="confirmingRemoval = false">{{t('common.no_go_back')}}</button>
      <button class="btn btn-warning" [disabled]="loading"
        (click)="importActivity(guest?.uuid)">{{t('common.yes_sure')}}</button>
    </div>
    <!-- <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100"> 
    </multisite-loader-globe-animation-detail>-->
    <div class="card-gray-200 mt-3">
      <h5 class="mt-1 mb-1 d-flex justify-content-between align-items-center" (click)="infoCollapsed = !infoCollapsed"
        style="cursor: pointer;">
        <span><i class="fa-solid fa-circle-info mr-2"></i>{{t('common.more_info')}}</span>
        <span *ngIf="infoCollapsed"><i class="fa-solid fa-chevron-down ml-1"></i></span>
        <span *ngIf="!infoCollapsed"><i class="fa-solid fa-chevron-up ml-1"></i></span>
      </h5>
      <div class="mt-3" [collapse]="infoCollapsed" [isAnimated]="true">
        <p class="mt-3">{{t('guest_import.info_01')}}</p>
        <h6 class="h6 mt-3">{{t('guest_import.import_subtitle')}}</h6>
        <p class="mt-3">{{t('guest_import.info_02')}}</p>
        <p class="mt-3">{{t('guest_import.info_03')}}</p>
      </div>
    </div>
  </div>
</ng-template>