export class Media {
    constructor(
        public id : number,
        public medially_type : string,
        public medially_id : number,
        public file_url : string,
        public base_url : string, // https://res.cloudinary.com/cebt/image/upload/
        public transformations_url : string, // c_thumb,w_200,g_face/
        public asset_url : string, // v1611931415/Culture/Tools/red-shoes.jpg
        public file_name : string,
        public file_type : string,
        public size : number,
        public uploader_id : number,
        public description : string,
        public credits : string,
        public permission : string,
        public category : string,
        public type : string,
        public created_at : Date,
        public updated_at : Date
    ){}
}