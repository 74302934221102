import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HubsRoutingModule } from './hubs-routing.module';
import { HubsListingComponent } from './hubs-listing/hubs-listing.component';
import { HubHomeComponent } from './hub-home/hub-home.component';
import { LoadersModule } from '../loaders/loaders.module';
import { AvatarsModule, EmailInputFormModule, LinksModule } from '@frontend/shared';
import { UploadersModule } from '@frontend/shared';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { HubNewComponent } from './hub-new/hub-new.component';
import { CardsModule } from '../content/cards';
import { HubRolesComponent } from './roles/hub-roles.component';
import { HubMarketingComponent } from './hub-marketing/hub-marketing.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { MultilingualFormsModule } from '@frontend/shared';
import { HubProfilesComponent } from './hub-profiles/hub-profiles.component';
import { HubProfileEditComponent } from './hub-profile-edit/hub-profile-edit.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { HubCultureConnectorComponent } from './cultureconnector/hub-cco.component';


@NgModule({
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'hubs' }],
  declarations: [
    HubsListingComponent,
    HubHomeComponent,
    HubNewComponent,
    HubRolesComponent,
    HubCultureConnectorComponent,
    HubMarketingComponent,
    HubProfilesComponent,
    HubProfileEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HubsRoutingModule,
    LoadersModule,
    AvatarsModule,
    TranslocoModule,
    UploadersModule,
    CardsModule,
    AlertModule,
    ReactiveFormsModule,
    EmailInputFormModule,
    TypeaheadModule,
    MultilingualFormsModule,
    LinksModule,
    PopoverModule,
    CollapseModule,

  ],
  exports: [
    HubMarketingComponent
  ]
})
export class HubsModule { }
