import { Component, Input } from '@angular/core';
import { BrandDisplayService } from '@frontend/common';

@Component({
  selector: 'multisite-bell-curve',
  templateUrl: './bell-curve.component.html',
  styleUrls: ['./bell-curve.component.scss']
})
export class BellCurveComponent {

  @Input() widthRelativeToParentContainer: number = 100;
  @Input() scaleY: number = 100;
  defaultSvgViewBoxHeight : number = 500;

  constructor(private brandDisplayService : BrandDisplayService) { }


  setCurvePath (){
    return 'M0,'+this.defaultSvgViewBoxHeight*this.scaleY+'C230,'+this.defaultSvgViewBoxHeight*this.scaleY+' 290,0 400,0C500,0 560,'+this.defaultSvgViewBoxHeight*this.scaleY+' 790,'+this.defaultSvgViewBoxHeight*this.scaleY+'v5H0';
    // 'M0,500C230,500 290,0 400,0C500,0 560,500 790,500v5H0z';
    /*
      M0,500 starting position drawing the curve
      C230,500 position of first control point
      290,0 next position (about 100 units left of centre)
      400,0 next position
      C500,0 next control point (about 100 units right of centre)
      560,500 next position
      790,500 final position on the main curve
      v5 draw a vertical line down 5 units (put a bigger number here to make a thicker base for the curve)
      H0z go back to start and close the shape so we can add a fill
    */
  }
  setViewBox (){
    return '0 0 800 '+this.defaultSvgViewBoxHeight*(this.scaleY);
  }

  darkModeActive () {
    return this.brandDisplayService.darkMode;
  }

}
