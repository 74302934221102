import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslocoModule } from '@jsverse/transloco';

import { CardsModule } from './cards/cards.module';
import { TopicsModule } from './topics/topics.module';
import { PlaylistAccordionTextModule } from './lists/playlists/playlist-accordion-text/playlist-accordion-text.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslocoModule,
    CardsModule,
    TopicsModule,
    PlaylistAccordionTextModule,
  ],
  exports: [
  ]
})
export class ContentModule { }
