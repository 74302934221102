import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComingSoonComponent } from './coming-soon.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ComingSoonRoutingModule } from './coming-soon-routing.module';



@NgModule({
  declarations: [
    ComingSoonComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    ComingSoonRoutingModule
  ],
  exports: [
    ComingSoonComponent
  ]
})
export class ComingSoonModule { }
