import { Injectable } from '@angular/core';

export interface LocalStorageNotificationStatus {

  type_dismissed_until : Date, // pending_tasks

}

type LocalStorageNotificationsByModel = {

  [key:string]:{[key:number|string]:{[key:string]:LocalStorageNotificationStatus}}

  /*
    "hub":{"14":{"pending_tasks":{"type_dismissed_until":"2024-12-15 12:49:21"}}}
  */

}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  localstorageNotificationsByModel : LocalStorageNotificationsByModel = {};

  constructor() { 
    this.clearExpiredLocalStorageNotifications();
  }

  clearData (){
    localStorage.removeItem('localNotifications');
  }
  clearExpiredLocalStorageNotifications(){
    const timeNow = new Date().getTime();
    let storedNotificationStatuses : LocalStorageNotificationsByModel = localStorage.getItem('localNotifications') ? JSON.parse(localStorage.getItem('localNotifications')) : {};
    for (let model in storedNotificationStatuses){
      for (let identifier in storedNotificationStatuses[model]){
        for (let type in storedNotificationStatuses[model][identifier]){
          if (storedNotificationStatuses[model][identifier][type]){ // TODO - consider removing this condition;it corrected an earlier bug and it should be unnecessary
            let dismissed_until = storedNotificationStatuses[model][identifier][type].type_dismissed_until;
            if (dismissed_until){
              let dismissed_until_date = new Date (dismissed_until);
              if (dismissed_until_date.getTime() < timeNow){
                delete storedNotificationStatuses[model][identifier][type];
              }
            }
          }
        }
      }
    }
    localStorage.setItem('localNotifications',JSON.stringify(storedNotificationStatuses))
  }
  getLocalNotificationStatusFromLocalStorage (model : string, identifier : string, type : string){
    if (!model || !identifier || !type ){return;};
    let storedNotificationStatuses : LocalStorageNotificationsByModel = localStorage.getItem('localNotifications') ? JSON.parse(localStorage.getItem('localNotifications')) : {};
    return storedNotificationStatuses[model]?.[identifier]?.[type];
  }
  putNotificationStatusInLocalStorage (model : string, identifier : string, type : string, status : LocalStorageNotificationStatus){
    if (!model || !identifier || !type || !status ){return;};
    let storedNotificationStatuses : LocalStorageNotificationsByModel = localStorage.getItem('localNotifications') ? JSON.parse(localStorage.getItem('localNotifications')) : {};
    if (!storedNotificationStatuses[model]){
      storedNotificationStatuses[model] = {[identifier]:{[type]:status}};
    } else if (!storedNotificationStatuses[model][identifier]){
      storedNotificationStatuses[model][identifier] = {[type]:status};
    } else {
      storedNotificationStatuses[model][identifier][type] = status;
    }
    localStorage.setItem('localNotifications',JSON.stringify(storedNotificationStatuses))
  }
}
