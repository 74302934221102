import { FlagExtraLite } from "../flags";

export class Culture {
    public id : number;
    public name : string;
    public slug : string;
    public metonyms : string[]; // ['Brunei Darussalam']
    public iso : string; // 'FJ'
    public cco : number;
    public category : string; // 'geographic'
    public type : string; // 'national'
    public flag : FlagExtraLite;
    public tag_id : number; // TODO: should this be the tag itself?
    public pivot? : any; // for example cultures related to Snapshots arrive with the pivot data
}