<form [formGroup]="emailForm" role="form">
  <div class="input-self w-100" *transloco="let t">
    <label for="email" class="form-label">{{labelTextTranslationKey ? t(labelTextTranslationKey) : labelText}}
      <!-- <span class="required-sign">*</span> -->
    </label>
    <div class="input-group w-100" [ngClass]="{
        'ng-invalid':
          emailForm.get('email').invalid &&
          (emailForm.get('email').touched ||
          emailForm.get('email').dirty),
        'ng-valid':
          emailForm.get('email').valid &&
          emailForm.get('email').touched &&
          emailForm.get('email').dirty
      }">
      <input class="form-control" type="email" formControlName="email" id="email" placeholder="example@email.com" />
      <button [disabled]="!emailForm.valid || loading" (click)="submit()" class="btn btn-sm btn-primary primary-dark"
        type="button" id="button-addon2">
        {{t('common.submit')}}
      </button>
    </div>
    <multisite-loader-globe-animation-detail *ngIf="loading" [width]="60" [height]="60">
    </multisite-loader-globe-animation-detail>
    <div *ngIf="
        emailForm.get('email').errors?.email && emailForm.get('email').touched
      " class="invalid-feedback">
      {{ t('authentication.invalid_address') }}
    </div>
    <div *ngIf="
        emailForm.get('email').errors?.required &&
        emailForm.get('email').dirty
      " class="invalid-feedback">
      {{ t('common.required') }}
    </div>
    <div *ngIf="errorTranslationKey" class="text-danger" [innerHTML]="t(errorTranslationKey)"></div>
  </div>
</form>