import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AccessCodeConstants } from '../access-code-regex.constants';

@Component({
  selector: 'multisite-access-code-form',
  templateUrl: './access-code-form.component.html',
  styleUrls: ['./access-code-form.component.scss']
})
export class AccessCodeFormComponent {

  accessCodeForm: FormGroup;
  accessCodeConstants: any
  @Output() submitted = new EventEmitter<string>();
  @Output() codeUpdated = new EventEmitter<string>();
  @Input() backgroundFill : boolean;
  @Input() error : string; // 'error.data_invalid' (translation key)
  @Input() loading : boolean;


  constructor(private formBuilder: FormBuilder) {
    this.accessCodeConstants = AccessCodeConstants;
    this.accessCodeForm = this.formBuilder.group({
      accessCode: ['', [Validators.required, Validators.pattern(this.accessCodeConstants.regex)]]
    });
    this.accessCodeForm.controls.accessCode.valueChanges.subscribe((value) => {
      if(!value || this.accessCodeConstants.regex.test(value)){
        this.codeUpdated.emit(value);
      } else {
        this.codeUpdated.emit(''); // If the parent is a stepper, we do not want it to submit any invalid codes
      }
    });

  }

  submit() {
    const accessCode = this.accessCodeForm.value.accessCode;
    this.submitted.emit(accessCode);
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.accessCodeForm.valid) {
      // Prevent the default form submission behavior
      event.preventDefault();
      this.submit();
    }
  }

}
