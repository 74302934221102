import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JumboGreetingComponent } from './jumbo-greeting/jumbo-greeting.component';
import { NumberThumbnailsBannerComponent } from './number-thumbnails/number-thumbnails-banner.component';
import { TranslocoModule } from '@jsverse/transloco';
import { GalleryCtaModule } from './gallery-cta/gallery-cta.module';



@NgModule({
  declarations: [
    JumboGreetingComponent,
    NumberThumbnailsBannerComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    GalleryCtaModule
  ],
  exports: [
    JumboGreetingComponent,
    NumberThumbnailsBannerComponent,
  ]
})
export class BannersModule { }
