import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatetimeProcessingService {

  constructor() { }

  isDateWithinXDays(date: Date, x : number): boolean {
    const currentDate = new Date();
    const XDaysFromNow = new Date();
    XDaysFromNow.setDate(currentDate.getDate() + x);

    return date <= XDaysFromNow;
  }
  isDatePassed(date: Date): boolean {
    const currentDate = new Date();

    return currentDate >= date;
  }

  getFutureDateByUnits(count : number, units = 'weeks') {
    const now = new Date();
    const result = new Date(now);
    const multiplier = units === 'weeks' ? 7 : 1; 
    result.setDate(now.getDate() + count * multiplier);
    return result;
  }
  
  
}
