<div class="quiz" *transloco="let t">
  <div class="quiz-content">
    <div class="text-content">
      <div class="mini-progress" *ngIf="surveyProgressValue">
        <!-- <p class="h4 value">
          {{surveyProgressValue+surveyProgressUnit}}
        </p> -->
        <!-- <p class="helper-text">Progress:</p> -->
        <div class="bar-box" [style]="'--value-width: '+surveyProgressValue+'%;'">
          <div class="bar-fill">
          </div>
        </div>
      </div>
      <div class="d-flex flex-column gap-2">
        <p *ngIf="question.context">
          {{question.context}}
        </p>
        <p class="title">
          {{question.text}}
        </p>
      </div>
    </div>
    <img class="img-responsive" *ngIf="question.media"
      [src]="question.media.asset_url && imageTransformations ? question.media.base_url + imageTransformations + question.media.asset_url : question.media.file_url"
      [alt]="question.media.description" (click)="openModal(imageEnlargedModal)">
  </div>
  <p class="my-4">
    {{question.help}}
  </p>
  <div class="bell-curve-group">
    <div *ngIf="question.visualisation?.type === 'bell_curve'">
      <div
        [style.transform]="'translateX(' + (((inputValue / 100) * sliderInput.offsetWidth) - (sliderInput.offsetWidth * (bellCurveRelativeWidth/100))/2 )+ 'px)'"
        class="bell-curve" [ngClass]="{'active':sliderActive}" *ngIf="bellCurveRelativeScaleY > 0">
        <multisite-bell-curve ngClass="bell-position" [widthRelativeToParentContainer]="bellCurveRelativeWidth"
          [scaleY]="bellCurveRelativeScaleY">
        </multisite-bell-curve>
      </div>
    </div>
    <div *ngIf="question.visualisation?.type === 'icon_array'" class="mb-4">
      <multisite-icon-array [selectedValue]="inputValue" (setValue)="setValueByValue($event)"
        [iconClasses]="'fa-solid fa-person'" [numberOfIcons]="25" [max]="100">
      </multisite-icon-array>
    </div>
    <form [formGroup]="lickertForm" class="form-bell-curve" role="form">

      <!-- <span>{{setElementHeightProperty ()}}</span> -->
      <div [ngClass]="{'input-container':true}">
        <!-- There u can put top level label -->
        <!-- --element-height should have value of ".label-holder" height -->
        <div class="range-input-column-layout" data-role="rangeslider"
          style="--element-height: {{labelHolder.offsetHeight}}px;">
          <div class="slider-holder">
            <input type="range" #sliderInput formControlName="response" id="range-input" step="1"
              class="form-range range-input" (mousedown)="onSliderMouseDown()" (mouseup)="onSliderMouseUp()">
          </div>
          <div class="label-holder" #labelHolder>
            <label for="range-input" class="align-center label-clickable" [ngClass]="{'active': isAnchorActive(i)}"
              *ngFor="let anchor of question.anchor_set;
              let i = index" (click)="setValueByLabel(i)">{{t('survey.anchor.'+anchor)}}
            </label>
          </div>
        </div>
        <!-- There u can put bottom level label -->
      </div>
      <!-- <textarea placeholder="Write your answer here" disabled></textarea> -->
      <p *ngIf="feedbackText" class="lickert-question-feedback my-2 p-3">
        <span class="icon-holder">
          <i class="fa-regular fa-lightbulb"></i>
        </span>
        <span>
          {{feedbackText}}
        </span>
      </p>
      <alert [type]="'info'" *ngIf="error_message_translation_key" class="py-2 w-100">
        <button (click)="clearError()" type="button" class="close float-right" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <p *transloco="let t" class="my-3">
          {{ t('error.'+error_message_translation_key) }}<br>
          <span *ngIf="error_message_translation_key.indexOf('login_required') > -1">
            <a [routerLink]="['/login']">
              <strong>{{ t('common.log_in') }}</strong>
            </a>
          </span>
        </p>
      </alert>
      <div class="w-100 my-3">
        <button class="btn btn-primary float-right" type="submit" [disabled]="!isValidValue()" (click)="doSubmitResponse()"
          *ngIf="!hideSubmitButton">
          <span class="pull-left float-left" *ngIf="loading">
            <multisite-loader-globe-animation-detail [width]="24" [height]="24">
            </multisite-loader-globe-animation-detail>
          </span>
          <span *transloco="let t">
            {{feedback ? t(showTryAgainButtonWithFeedback) : t('common.submit')}}
          </span>
        </button>
      </div>


    </form>
  </div>
</div>



<!-- Modal -->

<ng-template #imageEnlargedModal>
  <div class="modal-header">
    <h4 id="dialog-static-name" class="modal-title pull-left">{{question.media.description}}</h4>
    <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <img class="img-responsive" *ngIf="question.media" [src]="question.media.file_url"
      [alt]="question.media.description">
  </div>
</ng-template>