import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';

import { AuthConstants } from '../auth.constants';
import { PageTitleService } from '../../navigation/title/title.service';

@Component({
  selector: 'multisite-password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.scss'],
})
export class PasswordResetFormComponent implements OnInit, OnDestroy {
  @Input() email: string;
  @Input() resetToken: string;
  showWarningAlert: boolean = false;
  showSuccessAlert: boolean = false;
  PasswordResetForm: FormGroup;
  loading: boolean;
  errorMessage: string;
  hideBackendErrorMessage: boolean;
  passwordResetSubscription: Subscription;
  authConstants: any;
  patternValid: { password: boolean; repeatPassword: boolean };

  constructor(private authService: AuthService, private titleService : PageTitleService) {
    this.authConstants = AuthConstants;
    this.patternValid = { password: false, repeatPassword: false };
  }

  hideAlerts() {
    this.showSuccessAlert = false;
    this.showWarningAlert = false;
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('repeatPassword').value
      ? null
      : { mismatch: true };
  }
  passwordMatchValidatorAnotherOne(control: FormControl) {
    if (!this.PasswordResetForm){return};
    return this.PasswordResetForm.get('password').value === control.value
      ? null
      : { mismatch: true };
  }
  checkRegex(string: string, field) {
    if (field && !string) {
      this.patternValid[field] = false;
    }
    if (!field) {
      return;
    }
    this.patternValid[field] =
      this.authConstants.passwordRegexPattern.test(string);
  }
  sendRequest() {
    this.loading = true;
    this.hideAlerts();
    this.errorMessage = null;

    this.passwordResetSubscription = this.authService
      .resetPassword(
        this.email,
        this.PasswordResetForm.controls.password.value,
        this.PasswordResetForm.controls.repeatPassword.value,
        this.resetToken
      )
      .subscribe(
        (response) => {
          this.loading = false;
          this.PasswordResetForm.reset(); // this change also calls hideAlerts()
          this.showSuccessAlert = true;
        },
        (errorMessage) => {
          this.errorMessage = errorMessage;
          this.loading = false;
        }
      );
  }
  generateForm (){
    this.PasswordResetForm = new FormGroup(
      {
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          Validators.pattern(this.authConstants.passwordRegexPattern)
        ]),
        repeatPassword: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          Validators.pattern(this.authConstants.passwordRegexPattern),
          this.passwordMatchValidatorAnotherOne.bind(this)
        ]),
      },
      this.passwordMatchValidator
    );
  }
  ngOnInit(): void {
    this.loading = false;
    this.generateForm ();
    this.PasswordResetForm.controls.password.valueChanges.subscribe((value) => {
      this.checkRegex(value, 'password');
      this.hideAlerts();
    });
    this.PasswordResetForm.controls.repeatPassword.valueChanges.subscribe(
      (value) => {
        this.checkRegex(value, 'repeatPassword');
        this.hideAlerts();
      }
    );
    this.titleService.setTitle(null);
  }
  ngOnDestroy() {
    if (this.passwordResetSubscription) {
      this.passwordResetSubscription.unsubscribe();
    }
  }
}
