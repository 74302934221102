import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

type StatusItem = {
  text : string // "Contact people"
  translationKey : string // "common.contact_people"
  type: string // ICONS. null type and null status mean simple bullets; or type could be 'check', 'warning', 'info', 'cancel' 'status' means icons like tick, cross, warning-sign, info etc
  status: string // COLOURS 'status' means 'success','info','warning' and 'danger' which convert to ng-valid, ng-info, ng-warning, ng-invalid
}

@Component({
  selector: 'multisite-left-bordered-card',
  templateUrl: './left-bordered-card.component.html',
  styleUrls: ['./left-bordered-card.component.scss']
})
export class CardLeftBorderedComponent {

  @Input() disabled : boolean;
  @Input() card_clickable: boolean;
  @Input() title_text: string; // 'Brands'
  @Input() title_text_translation_key: string; // 'navigation.brands'
  @Input() subtitle_text: string; // 'Settings'
  @Input() subtitle_text_translation_key: string; // 'common.settings'
  @Input() body: string; // paragraph text
  @Input() card_link_route: string[]; // ['brands/my-brand']
  @Input() button_link_route: string[]; // ['brands/my-brand']
  @Input() button_text: string; // 'Manage'
  @Input() button_text_translation_key: string; // 'common.manage'
  @Input() statusItems : StatusItem[];
  @Output() onSelected = new EventEmitter();

  constructor(private router : Router, private route : ActivatedRoute) { }

  onButtonSelected(event: MouseEvent){
    event.stopPropagation();
    if (this.button_link_route){
      this.router.navigate(this.button_link_route, {relativeTo: this.route});
    } else {
      this.onSelected.emit();
    }
  }
  onCardSelected(){
    if (this.card_link_route){
      this.router.navigate(this.card_link_route, {relativeTo: this.route});
    } else {
      this.onSelected.emit();
    }
  }

}
