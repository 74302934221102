import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ttt-design05',
  templateUrl: './design05.component.html',
  styleUrls: ['./design05.component.scss']
})
export class Design05Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
