<div class="container" *transloco="let t">
  <!-- <h5>Debugging</h5>
  <p>
  <b>allSurveysCompleted()</b>: {{allSurveysCompleted()}}<br>
  <b>snapshotIncludesSurveyCategoryAndType(ciprofile,profiling)</b>: {{snapshotIncludesSurveyCategoryAndType('ciprofile','profiling')}}<br>
  <b>snapshotIncludesSurveyCategoryAndType(x,profiling)</b>: {{snapshotIncludesSurveyCategoryAndType('x','profiling')}}<br>
  <b>snapshotIncludesSurveyCategoryAndType(ciprofile,x)</b>: {{snapshotIncludesSurveyCategoryAndType('ciprofile','x')}}<br>
  <b>snapshotIncludesSurveyCategoryAndType(ciprofile,null)</b>: {{snapshotIncludesSurveyCategoryAndType('ciprofile',null)}}<br>
  <b>snapshotIncludesSurveyCategoryAndType(null,profiling)</b>: {{snapshotIncludesSurveyCategoryAndType(null,'profiling')}}<br>
  <b>snapshotIncludesSurveyCategoryAndType(cultures,knowledge)</b>: {{snapshotIncludesSurveyCategoryAndType('cultures','knowledge')}}<br>
  </p> -->
  <div class="snapshot-post" *ngIf="snapshot">
    <div class="card img-holder">
      <div class="position-relative">
        <button class="btn btn-sm btn-primary btn-edit d-flex d-lg-none" *ngIf="user" (click)="openModal(imageUploaderModal)">
          <!-- Guests cannot upload images -->
          <i class="fa-solid fa-pen mr-1"></i><span class="text">{{ t('content_management.image_add_edit') }}</span>
        </button>
        <div class="date-mobile d-flex d-lg-none align-self-end justify-content-start">
          <div class="d-flex flex-row align-items-center">
            <p class="year">{{ snapshot.date | date: 'yyyy' }}</p>
            <p class="month-day">
              <span class="month">
                {{ snapshot.date | date: 'MMM' }}
              </span>
            </p>
          </div>
        </div>
        <div class="card-img-overlay-color d-none d-lg-block"></div>
        <img class="card-img" *ngIf="snapshot.media"
          [src]="snapshot.media.asset_url && mainImageTransformations ? snapshot.media.base_url + mainImageTransformations + snapshot.media.asset_url : snapshot.media.file_url"
          [alt]="snapshot.media?.description ? snapshot.media.description : 'A background image representing this snapshot'">
      </div>
      <div class="card-img-overlay">
        <div class="d-flex flex-column flex-lg-row align-items-start gap-2">
          <div class="date d-none d-lg-flex">
            <p class="year">{{ snapshot.date | date: 'yyyy' }}</p>
            <p class="month-day">
              <span class="month">
                {{ snapshot.date | date: 'MMM' }}
              </span>
            </p>
          </div>
          <div class="content">
            <h3 class="title d-inline d-lg-flex">
              <span class="icons-holder d-inline d-lg-flex flex-row flex-wrap gap-2" *ngIf="snapshot.cultures?.length">
                <span class="mr-2 d-none d-lg-block">{{snapshot.title}}</span>
                <span *ngFor="let culture of snapshot.cultures" class="icons-holder tooltip-custom">
                  <img [src]="'https://cultureconnector.com/app/images/flags/'+culture.iso+'.png'" [alt]="culture.name">
                  <p class="tooltiptext-custom-top">{{culture.name}}</p>
                </span>
              </span>
            </h3>
            <p class="d-inline d-lg-block">
              {{snapshot.description}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="control-bar">
      <div class="status-holder" style="--status-color: var(--bs-danger);">
        <div class="status"
          [ngClass]="{bgWarning: isDateWithinXDays(snapshot.date,7),bgDanger: isDatePassed(snapshot.date)}"
          *ngIf="(isDateWithinXDays(snapshot.date,7) || isDatePassed(snapshot.date)) && !allSurveysCompleted()">
          <i class="fa-solid fa-clock icon"></i>
          {{isDatePassed(snapshot.date) ? t('common.expired'):t('common.expiring')}}&nbsp;{{ snapshot.date | date :
          'MMMM d' }}
        </div>
      </div>
      <div class="control">
        <button class="btn btn-sm btn-secondary" (click)="requestCancelSnapshot()" *ngIf="
            (!snapshot?.cohort_id && !anySurveysStarted()) ||
            (anySurveysStarted() && !allSurveysCompleted())
          ">
          {{ t('common.cancel') }}
        </button>
        <button class="btn btn-sm btn-secondary" (click)="openModal(endSnapshotModal)"
          *ngIf="allSurveysCompleted() && snapshot?.current_accepted">
          {{ t('survey.end_snapshot') }}
        </button>
        <button *ngIf="!snapshot.cohort_id && !anySurveysStarted()" class="btn btn-sm btn-primary"
          (click)="openModal(snapshotEditModal)">
          <i class="fa-solid fa-pen mr-2"></i>{{ t('common.edit') }}
        </button>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!allSurveysCompleted()">
    <div class="col-lg-9">
      <alert [type]="'danger'" *ngIf="error && !snapshot">
        <span *transloco="let t">{{ t(error) }}</span>
      </alert>
      <div class="row">
        <div class="d-flex flex-column gap-2">
          <div class="col-lg">
            <a *ngFor="let surveyWrapper of getSurveyWrappersSorted(surveyWrappers)"
              (click)="gotoSurvey(surveyWrapper.slug)">
              <span *ngIf="surveyWrapper.survey">
                <multisite-status-list-item [title]="surveyWrapper.survey.name"
                  [description]="surveyWrapper.survey.description" [media]="surveyWrapper.survey.media"
                  [progressValue]="surveyWrapper.progressValue" [minutes]="surveyWrapper.remainingMinutes"
                  [buttonTextTranslationKey]="surveyWrapper.progressValue > 99 ? 'common.view_feedback' : surveyWrapper.progressValue > 0 ? 'survey.continue_survey' :  'common.start'"></multisite-status-list-item>
              </span>
              <multisite-loader-globe-animation-detail *ngIf="!surveyWrapper.survey && surveyWrapper.loadingSurvey"
                [width]="100" [height]="100">
              </multisite-loader-globe-animation-detail>
              <p *ngIf="!surveyWrapper.survey && !surveyWrapper.loadingSurvey">
                {{ t('error.something_went_wrong') }}
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <!-- INSTRUCTIONS FOR DEVELOPER -->

      <!-- At the moment when there is no raters element, it is necessary to remove the elements that define the layout when they are there: 

        <div class="row flex-column-reverse flex-lg-row">
        <div class="col-lg-9"></div>
        <div class="col-lg-3"></div>
        </div>
       -->

      <div class="raters-card">
        <div class="header">
          <h5 class="title font-weight-bold">{{ t('common.raters') }}</h5>
          <button class="btn btn-sm btn-primary" (click)="openModal(editRatersModal)">
            <i class="fa-solid fa-plus mr-1"></i>{{ t('common.add') }}
          </button>
        </div>
        <div class="body">
          <span *ngFor="let rater of raters?.slice(0, 3)">
            <multisite-avatar-pill [fallbackImageUrl]="
                'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'
              " [imageUrl]="rater.picture" [text]="
                rater.pre_honorific +
                ' ' +
                rater.fname +
                ' ' +
                rater.lname +
                '
    ' +
                rater.post_honorific
              "></multisite-avatar-pill>
          </span>
        </div>
      </div>
    </div>
  </div>

  <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
  </multisite-loader-globe-animation-detail>

  <div class="snapshot-content-container" *ngIf="allSurveysCompleted()">
    <div class="row">
      <div class="col-lg-8">


        <div class="card" *ngIf="snapshotIncludesSurveyCategoryAndType('ciprofile','profiling')">
          <div class="card-body">
            <multisite-loader-globe-animation-detail *ngIf="isLoading('ciResult')" [width]="100" [height]="100">
            </multisite-loader-globe-animation-detail>
            <div>
              <ciprofile-wheel *ngIf="ciResult" [chartSize]="64" [result]="ciResult" (selected)="gotoFeedback(null)">
              </ciprofile-wheel>
            </div>
          </div>
        </div>


      </div>
      <div class="col-lg-4 mt-4 mt-lg-0">

        <div class="d-flex flex-column mb-4 d-lg-none" *ngIf="user">
          <button type="button" class="btn btn-primary btn-sm w-100 mb-2" [routerLink]="['feedback/guide']"
            *ngIf="ciResult">
            <i class="fa-solid fa-circle-info mr-1"></i>
            <span *transloco="let t">{{t('ciprofile_guide.chart_explained')}}</span>
          </button>
          <button class="btn btn-warning btn-sm text-uppercase w-100 font-weight-bold my-2" [routerLink]="['feedback']">
            <span>{{t('survey.get_full_results')}}</span>
          </button>
        </div>

        <frontend-link-type-eleven *ngIf="ciResult?.meta?.priorities_as_content_items?.length"
          [titleTranslationKey]="'common.priorities'"
          [items]="prioritiesWithCta(ciResult.meta.priorities_as_content_items)" [descriptionCharactersMax]="150"
          (linkClicked)="priorityClick($event)"></frontend-link-type-eleven>


        <div class="d-flex flex-column gap-3 mt-4" *ngIf="!user">
          <p>{{t('survey.log_in_for_extra_feedback')}}</p>
          <button class="btn btn-warning btn-sm text-uppercase w-100 font-weight-bold my-2" [routerLink]="['/login']">
            <span>{{t('common.log_in')}}</span>
          </button>
        </div>
        <div class="d-flex flex-column my-4 d-none d-lg-block" *ngIf="user">
          <button class="btn btn-warning btn-sm text-uppercase w-100 font-weight-bold my-2" [routerLink]="['feedback']">
            <span>{{t('survey.get_full_results')}}</span>
          </button>
          <button type="button" class="btn btn-primary btn-sm w-100 my-2" [routerLink]="['feedback/guide']"
            *ngIf="ciResult">
            <i class="fa-solid fa-circle-info mr-1"></i>
            <span *transloco="let t">{{t('ciprofile_guide.chart_explained')}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #snapshotEditModal>
  <div class="modal-header" *transloco="let t">
    <h4 class="h4 font-weight-bold mb-0">
      {{ t('survey.snapshot_context_question') }}
    </h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </button>
  </div>
  <div class="modal-body overflow-hidden">
    <snapshot-create-edit-form-simple (submit)="editSnapshot($event)" [snapshot]="snapshot"
      [loading]="loadingSnapshotEdits" [error]="error">
    </snapshot-create-edit-form-simple>
  </div>
</ng-template>

<ng-template #imageUploaderModal>
  <div class="modal-header" *transloco="let t">
    <h4 class="h4 font-weight-bold mb-0">
      {{ t('content_management.upload_image') }}
    </h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </button>
  </div>
  <div class="modal-body overflow-hidden">
    <p class="my-3" *transloco="let t">
      {{ t('content_management.cultural_situation_image') }}
    </p>

    <multisite-uploader-single-file [requiredFileType]="'image/*'" [imageTransformations]="'w_350,c_fill,ar_16:9/'"
      [fileInstructionsTranslationKey]="imageDescriptionTranslationKey" [fileDescriptionTranslationKey]=""
      [remoteFileDescriptionTranslationKey]="
        'content_management.image_already_published'
      " (fileFormData)="onFormDataChanged($event)" [resetFormOnChange]="resetFormOnChange"
      [askForLicenceAndCredit]="false" [emitOnChange]="false" [uploadButtonTranslationKey]="'common.confirm'"
      [disabled]="imageUplodingDisabled || loading" (cancelUpload)="cancelUploadFile($event)" [errorMessage]="error">
    </multisite-uploader-single-file>
  </div>
</ng-template>

<ng-template #endSnapshotModal>
  <div class="modal-header" *transloco="let t">
    <h4 class="h4 font-weight-bold mb-0">
      {{ t('survey.end_snapshot_question') }}
    </h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </button>
  </div>
  <div class="modal-body overflow-hidden" *transloco="let t">
    <p class="my-3">{{ t('survey.end_snapshot_help1') }}</p>
    <p class="my-3">{{ t('survey.end_snapshot_help2') }}</p>

    <hr class="my-3" />

    <div class="d-flex justify-content-between">
      <button class="btn btn-sm btn-primary" (click)="closeModal()">
        {{ t('common.no_go_back') }}
      </button>
      <button class="btn btn-sm btn-danger" (click)="endSnapshot()">
        {{ t('common.yes_sure') }}
      </button>
    </div>

    <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
    </multisite-loader-globe-animation-detail>

    <alert [type]="'danger'" [dismissOnTimeout]="3500" *ngIf="error">
      <span *transloco="let t">{{ t(error) }}</span>
    </alert>
  </div>
</ng-template>

<ng-template #cancelSnapshotModal>
  <div class="modal-header" *transloco="let t">
    <h4 class="h4 font-weight-bold mb-0">
      {{ t('common.cancel_are_you_sure') }}
    </h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </button>
  </div>
  <div class="modal-body overflow-hidden" *transloco="let t">
    <p class="my-3">
      <span *ngIf="
          !snapshot?.cohort_id &&
          !snapshot?.event_id &&
          !snapshot?.current_accepted
        ">
        {{ t('survey.cancel_snapshot_help_creator') }}</span>
      <span *ngIf="snapshot?.current_accepted">
        {{ t('survey.cancel_snapshot_help_current_accepted') }}</span>
    </p>

    <div class="d-flex justify-content-between">
      <button class="btn btn-primary" (click)="closeModal()">
        {{ t('common.no_go_back') }}
      </button>
      <button class="btn btn-warning" (click)="cancelSnapshot()">
        {{ t('common.yes_sure') }}
      </button>
    </div>

    <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
    </multisite-loader-globe-animation-detail>

    <alert [type]="'danger'" [dismissOnTimeout]="3500" *ngIf="error">
      <span *transloco="let t">{{ t(error) }}</span>
    </alert>
  </div>
</ng-template>

<ng-template #editRatersModal>
  <div class="modal-header" *transloco="let t">
    <h4 class="h4 font-weight-bold mb-0">
      {{ t('survey.snapshot_choose_raters') }}
    </h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </button>
  </div>
  <div class="modal-body overflow-hidden" *transloco="let t">
    <p class="text-muted mb-3" *ngIf="false">
      placeholder
    </p>

    <div class="raters-list">
      <div class="list select">
        <p class="info-title">Select:</p>
        <span *ngFor="let rater of raters; let i = index">
          <multisite-avatar-pill [removalMode]="i < 3" [fallbackImageUrl]="
              'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'
            " [imageUrl]="rater.picture" [text]="
              rater.pre_honorific +
              ' ' +
              rater.fname +
              ' ' +
              rater.lname +
              '
    ' +
              rater.post_honorific
            "></multisite-avatar-pill>
        </span>
      </div>
      <div class="list selected">
        <p class="info-title">Selected:</p>
        <span *ngFor="let rater of raters; let i = index">
          <multisite-avatar-pill [removalMode]="i < 3" [fallbackImageUrl]="
              'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'
            " [imageUrl]="rater.picture" [text]="
              rater.pre_honorific +
              ' ' +
              rater.fname +
              ' ' +
              rater.lname +
              '
    ' +
              rater.post_honorific
            "></multisite-avatar-pill>
        </span>
      </div>
    </div>

    <hr class="my-3" />

    <div class="d-flex gap-2 justify-content-end">
      <button class="btn btn-sm btn-primary" (click)="gotoRaters()">
        <i class="fa-solid fa-plus mr-1"></i>{{ t('common.add_new') }}
      </button>
      <button class="btn btn-sm btn-success" (click)="closeModal()">
        {{ t('common.done') }}
      </button>
    </div>

    <multisite-loader-globe-animation-detail *ngIf="loading" [width]="100" [height]="100">
    </multisite-loader-globe-animation-detail>

    <div class="mt-3">
      <alert [type]="'danger'" [dismissOnTimeout]="3500" *ngIf="error">
        <span *transloco="let t">{{ t(error) }}</span>
      </alert>
    </div>

    <hr class="my-3" />

    <multisite-list-item-icon *ngFor="let instruction of multiRaterSelectionInstructions"
      [iconClasses]="'fa-solid ' + instruction.icon?.identifier_1 ?? 'fa-check'" [titleText]="instruction.s"
      [descriptionText]="instruction.m"></multisite-list-item-icon>
  </div>
</ng-template>