import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressIndicatorCardSimpleComponent } from './progress-indicator-card-simple.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';



@NgModule({
  declarations: [
    ProgressIndicatorCardSimpleComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    ProgressbarModule,

  ],
  exports : [ProgressIndicatorCardSimpleComponent]
})
export class ProgressIndicatorCardSimpleModule { }
