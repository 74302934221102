import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSelectorTypeOneComponent } from './language-selector-type-one/language-selector-type-one.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    LanguageSelectorTypeOneComponent
  ],
  imports: [
    CommonModule,
    BsDropdownModule,
    TranslocoModule,
  ],
  exports: [
    LanguageSelectorTypeOneComponent
  ]
})
export class LanguageModule { }
