<div class="card bg-dark text-white card-banner-list-item-promo" [ngClass]="{static: static}" *transloco="let t"
  [style.--primaryColourRgb]="primaryColourRgb">
  <div class="card-img-overlay-color"></div>

  <img class="card-img" *ngIf="content.media"
    [src]="content.media.asset_url && imageTransformations ? content.media.base_url + imageTransformations + content.media.asset_url : content.media.file_url"
    [alt]="content.media.description">

  <div class="card-img-overlay" [style.--primaryColourRgb]="primaryColourRgb">
    <div class="top-part">
      <h5 class="card-title h5">
        <!-- 324 <span class="vertical-splitter"></span>  -->
        {{content.title ?? t(content.titleTranslationKey)}}
      </h5>

      <div class="cta-holder">
        <span *ngIf="content.user; else elseBlock">
          <!-- For users/people -->
          <div class="user-holder">
            <img class="user-img"
              [src]="content.user.picture ? content.user.picture : 'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'"
              [alt]="content.user.fname+' '+content.user.lname">
            <div class="user-info">
              <p class="user-name">{{content.user.fname+' '+content.user.lname}}</p>
              <p class="user-subtitle">{{content.user.job_title}}</p>
            </div>
          </div>

        </span>
        <ng-template #elseBlock>
          <!-- For Brands -->
          <span *ngIf="content.meta?.logo_banner">
            <img class="img-responsive logo" [src]="content.meta.logo_banner" [alt]="content.title+' logo'">
          </span>
        </ng-template>
      </div>
    </div>
    <div class="bottom-part mt-3">
      <p class="card-title h5">
        {{content.subtitle ?? t(content.subtitleTranslationKey)}}
      </p>
      <div class="info-bar" *ngIf="content?.rating === 0 || content?.rating || content?.meta?.avatars_count">
        <div class="rating" *ngIf="content?.rating === 0 || content?.rating">
          {{content.rating}}<i class="ml-2 fa-solid fa-star"></i>
        </div>
        <span class="vertical-splitter" *ngIf="content?.meta?.avatars_count"></span>
        <div class="user-counter" *ngIf="content?.meta?.avatars_count">
          <img class="user-img" *ngFor="let avatar of randomAvatars" [src]="avatar" alt="A person">
          <span class="ml-2">{{content.meta.avatars_count.number}} {{content.meta.avatars_count.titleText ?? t(content.meta.avatars_count.titleTranslationKey)}}</span>
        </div>
      </div>
      <p class="card-text">
        {{content.description ?? t(content.descriptionTranslationKey)}}
      </p>
      <div class="statistics" *ngIf="content?.meta?.statistics?.length">
        <div class="num-holder" *ngFor="let statistic of content.meta.statistics">
          <p>{{statistic.titleText ?? t(statistic.titleTranslationKey)}}</p>
          <span>{{statistic.number}}</span>
        </div>
      </div>
    </div>
  </div>
</div>