import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ContentItem } from '@frontend/common';

import { Media, Tag } from '@frontend/core';
import { TranslocoService } from '@jsverse/transloco';


@Component({
  selector: 'frontend-link-type-eleven',
  templateUrl: './link-type-eleven.component.html',
  styleUrls: ['./link-type-eleven.component.scss']
})
export class LinkTypeElevenComponent implements OnInit {

  // Good for internal links including people-links, where no Link model is required

  @Input() titleText : string;
  @Input() titleTranslationKey : string; // 'common.instructions'
  @Input() items : ContentItem[] = [];
  @Input() descriptionCharactersMax : number;
  currentItemIndex : number = 0;

  @Output() linkClicked = new EventEmitter<ContentItem>();


  constructor(private translocoService: TranslocoService) { }

  currentItem () : ContentItem {
    return this.items?.[this.currentItemIndex];
  }

  ngOnInit(): void {
  }

  gotoNext(){
    if(this.currentItemIndex == this.items?.length-1){
      this.currentItemIndex = 0;
    } else {
      this.currentItemIndex++;
    }
  }

  getTranslatedText(key: string): string {
    return this.descriptionCharactersMax && this.translocoService.translate(key).length > this.descriptionCharactersMax + 20 ? this.translocoService.translate(key).substring(0,this.descriptionCharactersMax) + '...' : this.translocoService.translate(key);
  }

  gotoPrevious(){
    if(this.currentItemIndex == 0){
      this.currentItemIndex = this.items?.length-1;
    } else {
      this.currentItemIndex--;
    }
  }

  doLinkClick (){
    this.linkClicked.emit(this.items[this.currentItemIndex]);
  }

}
