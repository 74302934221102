import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { PaginatedReviews } from './reviews-paginated.model';
import { Review } from './review.model';
import { ContentItem } from '@frontend/common';

import { of } from 'rxjs';
import { IModelsToContentItemsConverter } from '@frontend/core';

/* nterface reviewsResponse {
  'data': Review[],//{'likes': UserExtraLite[],'count':number, 'liked': boolean, 'id': number, 'identifier': string},
  'links': any,
  'meta': any
} */

@Injectable({ providedIn: 'root' })
export class ReviewsService implements IModelsToContentItemsConverter { // TODO: This is a placeholder service. Not fully tested yet.

  reviewsByModel : any;
  
  constructor(
    private http: HttpClient
    ) {
      this.reviewsByModel = {};
    }



  
  cacheReviews (reviews : PaginatedReviews, modelNameSingular : string, identifier, page : number){
    if (!this.reviewsByModel[modelNameSingular]){
      this.reviewsByModel[modelNameSingular] = {};
    };
    if (!this.reviewsByModel[modelNameSingular][identifier]){
      this.reviewsByModel[modelNameSingular][identifier] = {};
    };
    this.reviewsByModel[modelNameSingular][identifier][page] = reviews;
  }

  getCachedReviews (modelNameSingular,identifier,page){
    let cachedReviews : PaginatedReviews = null;
    if (this.reviewsByModel[modelNameSingular] && this.reviewsByModel[modelNameSingular][identifier] && this.reviewsByModel[modelNameSingular][identifier][page]){
      return this.reviewsByModel[modelNameSingular][identifier][page];
    }
    return cachedReviews
  };

  clearCachedReviews (modelNameSingular,identifier){
    if (this.reviewsByModel[modelNameSingular]){
      this.reviewsByModel[modelNameSingular][identifier] = null;
    }
  }

  transformReviews (reviewsArray){
    if (!reviewsArray || !reviewsArray.length ){ return [];};
    // let transformedReviews = [];
    reviewsArray.forEach((review,index,array) => {
      review.created_at = new Date(review.created_at);
    });
    return reviewsArray;
  }

  getReviews (modelNameSingular : string, identifier ,page : number, publishers: boolean, freshFromServer : boolean){
    
    /* publishers
     if true will return only reviews by people who have the right to publish social interactions, for example users with the "interculturalist" role
    if publishers is empty, the backend will default to true; 
    */

    page = page ? page : 1;

    let cachedReviews : PaginatedReviews;
    
    if (!freshFromServer) {
      cachedReviews = this.getCachedReviews (modelNameSingular,identifier,page);
    }
    if (cachedReviews){
      return of(cachedReviews);
    };
    let url = 'api/v1/reviews/class/'+modelNameSingular+'/'+identifier;
    /* for example 
      'api/v1/reviews/class/episode/how-to-train-online?&page=2;
      'api/v1/reviews/class/tip/inviting-participants?&page=1;
    */
    return this.http.get<PaginatedReviews>(url)
        .pipe(
          map(response =>{
            if (response ){
              response.data = this.transformReviews(response.data);
              this.cacheReviews(response, modelNameSingular, identifier, page);
              return response;
            };
          })
        )
  };
  setCachedPostingOfReviewForAfterAuthentication (modelNameSingular : string, identifier : number, message : string){
    localStorage.setItem('cachedReviewForAfterAuthentication',JSON.stringify([modelNameSingular, identifier, message]))
  }
  getCachedPostingOfReviewForAfterAuthentication (){
    let cachedReview = localStorage.getItem('cachedReviewForAfterAuthentication');
    if (cachedReview){
      localStorage.removeItem('cachedReviewForAfterAuthentication');
      return {
        'modelNameSingular': JSON.parse(cachedReview)[0],
        'identifier': JSON.parse(cachedReview)[1],
        'message': JSON.parse(cachedReview)[2]
      };
    };
  }
  convertReviewToContentItem (review: Review): ContentItem {
    if (!review) {return null;};
    let contentItem = new ContentItem (
      review.id,
      review.title,
      null,
      null,
      null,
      null,
      null,
      review.text,
      null,null,null,null,null,null,
      review.rating,
      null,
      new Date(review.created_at),
      null,
      review.reviewer,
      null
      );
      return contentItem;

  }
  makeContentItems (reviews: Review[]) : ContentItem[] {
    let content : ContentItem[] = [];
    if (reviews?.length){
      reviews.forEach(review => {
        content.push(this.convertReviewToContentItem(review));
      });
    }
    return content;
  }

}
