import { Injectable } from '@angular/core';

interface HslValue {
  h : number,
  s : number,
  l : number,
}


@Injectable({
  providedIn: 'root'
})
export class ColourService {

  constructor() { }

  get trafficLightsAsHex (){
    return [
      '#a51d1d',
      '#FE3600',
      '#FE4B00',
      '#FE7B00',
      '#FEAC02',
      '#C5B808',
      '#91C20E',
      '#5CCD11',
      '#4dad0e',
      '#398308',
    ]
  }

  validateHexColourValue (hex:string) : string {
    if (!hex){return '#000000';};
    hex = hex.replace(`#`, ``);
    if (hex.length === 3) { // Hex shorthand values are three letters #FFF. Convert this back to six (#F0F to #FF00FF).
      hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    };
    if (!/^([A-Fa-f0-9]{6})$/.test(hex)){ // The regex /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/ checks for valid HEX codes; the ! at the start looks for invalid ones
      return '#000000';
    }
    return "#"+hex;
  }

  convertHSLtoHEX(h : number, s : number, l : number) : string {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    let result = `#${f(0)}${f(8)}${f(4)}`;
    if (!result || result.length !== 7){
    }
    return result;
  }

  convertHEXtoHSL (hexcode) {
    // function is from: https://stackoverflow.com/questions/46432335/hex-to-hsl-convert-javascript
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexcode);

    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);

    r /= 255, g /= 255, b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if(max == min){
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    s = s*100;
    s = Math.round(s);
    l = l*100;
    l = Math.round(l);
    h = Math.round(360*h);

    return {'h':h,'s':s,'l':l};
};
convertHEXtoRGB (hexcode) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexcode);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}
convertHEXtoRGBstring (hexcode) : string {
  var rgbObject = this.convertHEXtoRGB(hexcode);
  return rgbObject ? rgbObject.r+', '+rgbObject.g+', '+rgbObject.b : null;
}

adjustColourLuminosity(hex : string, lum : number, relativeAdjustment : boolean) {
  hex = this.validateHexColourValue(hex);
  // lum (if relativeAdjustment is false) increases luminosity by lum on a scale of 0 to 100; Recommended: something in the -20 to +20 range
  // lum (if relativeAdjustment is true) pushes luminosity towards black (minus numbers e.g. -10) or white (positive numbers e.g. 10) by a percentage of the gap between the luminosity of base colour and black or white
  hex = hex.replace(`#`, ``);
  let hsl : HslValue  = this.convertHEXtoHSL(hex);
  let newL: number;
  if (relativeAdjustment) { // here we set the new value according to a percentage of the distance to the extreme, not to an absolute L value
    newL = (lum > 0) ? // if lum is positive, we'll lighten the colour
                        ((100 - hsl['l']) * ((100 - lum)/100)) + hsl['l'] // of the distance between old luminosity and 100, we'll go 100-lum % of the distance
         : (lum === 0) ? hsl['l'] // if lum is zero, we'll do nothing
         : hsl['l'] * (Math.abs(lum)/100); // if lum is a negative number we'll darken the luminosity by going almost all the distance towards black, but we'll stop at lum % of that distance
  } else {
   newL = hsl['l'] + lum;
   newL = (newL > 100) ? (100-hsl['l'])*.5 + hsl['l'] : (newL < 0) ? hsl['l']*.5 : newL; // if it has overshot 100 or undershot 5, lessen the darkening/lightening factor lum
  }
  return this.convertHSLtoHEX(hsl['h'],hsl['s'],newL)
}


}
