import { UserExtraLite } from '@frontend/common';

export class Review {
    constructor (
        public id: number, // 6
        public title: string, // "I love it",
        public text: string, // I must say I love it and I am really impressed with...
        public reviewer: UserExtraLite,
        public rating: number,
        public created_at: string, // "2017-03-01T16:54:17.000000Z"
    ){

    }
}