import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AccordionModule } from 'ngx-bootstrap/accordion';

import { CaptchaModule } from './captcha/captcha.module';
// import { ChipModule } from './ui/chip/chip.module';
import { PodcastPlayerMultipleComponent } from './podcast/podcast-player-multiple/podcast-player-multiple.component';
import { PodcastPlayerSingleComponent } from './podcast/podcast-player-single/podcast-player-single.component';
import { UserListItemSimpleTypeOneComponent } from './users/user-list-item-simple-type-one/user-list-item-simple-type-one.component';
import { ContentListItemSimpleTypeOneComponent } from './content/content-list-item-simple-type-one/content-list-item-simple-type-one.component';
import { LikesListingMiniComponent } from './social/likes-listing-mini/likes-listing-mini.component';
import { CommentTypeOneComponent } from './social/comments/comment-type-one/comment-type-one.component';
import { CommentFormTypeOneComponent } from './social/comments/comment-form-type-one/comment-form-type-one.component';
import { QuotationTypeOneComponent } from './ui/quotations/quotation-type-one/quotation-type-one.component';
// import { LinkTypeOneComponent } from './ui/links/link-type-one/link-type-one.component';
// import { LinkTypeThreeOverlayComponent } from './ui/links/link-type-three-overlay/link-type-three-overlay.component';
// import { LinkTypeFourComponent } from './ui/links/link-type-four/link-type-four.component';
// import { LinkTypeFiveComponent } from './ui/links/link-type-five/link-type-five.component';
import { FormInputSingleLinkComponent } from './forms/form-input-single-link/form-input-single-link.component';
import { FaqListingComponent } from '../../../common/src/lib/content/faqs/faq-listing/faq-listing.component';
import { PageDisplayTypeOneComponent } from './pages/page-display-type-one/page-display-type-one.component';
import { PageDisplayTypeTwoComponent } from './pages/page-display-type-two/page-display-type-two.component';
import { PageOverviewComponent } from './pages/page-overview/page-overview.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CommentsModule } from './social/comments/comments.module';
import { PaginationModule } from './ui/pagination/pagination.module';
import { ImagesModule } from './ui/images/images.module';
import { ComingSoonModule } from './pages/coming-soon/coming-soon.module';
import { PeopleCardsModule } from './people/people-cards/people-cards.module';
import { TranslocoModule } from '@jsverse/transloco';
import { LinkSocialIconModule } from './ui';

export interface SharedModuleConfig {
  appKey: string;
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CaptchaModule,
    FormsModule,
    BrowserAnimationsModule,
    // ChipModule, // Commented out in preparation for upgrade to Bootstrap 5 or MDB 5
    TranslocoModule,
    AlertModule, // alerts are only used in 1 component so far. So aim to import the AlertModule in a smaller module closer to form-input-single-link.component.html 
    AccordionModule, CommentsModule, PaginationModule, ImagesModule, ComingSoonModule, PeopleCardsModule,
    LinkSocialIconModule,
  ],
  exports: [
    CaptchaModule,
    PodcastPlayerMultipleComponent,
    PodcastPlayerSingleComponent,
    UserListItemSimpleTypeOneComponent,
    ContentListItemSimpleTypeOneComponent,
    LikesListingMiniComponent,
    CommentTypeOneComponent,
    CommentFormTypeOneComponent,
    QuotationTypeOneComponent,
    // LinkTypeOneComponent,
    // LinkTypeThreeOverlayComponent,
    // LinkTypeFourComponent,
    // LinkTypeFiveComponent,
    // LinkSocialIconComponent,
    FormInputSingleLinkComponent,
    PageDisplayTypeOneComponent,
    PageDisplayTypeTwoComponent,
    PageOverviewComponent,
  ],
  declarations: [
    PodcastPlayerMultipleComponent,
    PodcastPlayerSingleComponent,
    UserListItemSimpleTypeOneComponent,
    ContentListItemSimpleTypeOneComponent,
    LikesListingMiniComponent,
    CommentTypeOneComponent,
    CommentFormTypeOneComponent,
    QuotationTypeOneComponent,
    // LinkTypeOneComponent,
    // LinkTypeThreeOverlayComponent,
    // LinkTypeFourComponent,
    // LinkTypeFiveComponent,
    // LinkSocialIconComponent,
    FormInputSingleLinkComponent,
    PageDisplayTypeOneComponent,
    PageDisplayTypeTwoComponent,
    PageOverviewComponent,

  ],
  providers: [
  ],
})
export class SharedModule {}
