import { Button, StatisticNumerical } from "@frontend/core";


export class ContentItemMeta {
    
        statistics? : StatisticNumerical[];
        iconClasses? : string; // 'fa-solid fa-star'
        logo_banner? : string; // image file url  
        primary_colour_rgb? : string;
        primary_colour_hex? : string;
        completions? : number; // 44
        cohorts_count? : number; // 44
        avatars_count? : StatisticNumerical; // like other statistics, but we'll show some avatars with this one
        buttons?: Button[];
}