<div class="container">
  <div class="text-center my-5" *ngIf="loading">
    <multisite-loader-globe-animation-detail
    [width]="100" [height]="100">
  </multisite-loader-globe-animation-detail>
  </div>
  
  <div class="text-center my-5" *ngIf="!loading && faqs && !faqs.length">
    <span *transloco="let t">
      {{ t('common.no_results') }}
    </span>
  </div>
  
  <accordion [isAnimated]="true" *ngIf="faqs?.length" class="mt-5">
    <accordion-group *ngFor="let faq of faqs">
      <button
      class="btn btn-link btn-block justify-content-between d-flex w-100 shadow-none"
      accordion-heading type="button">
      <div class="pull-left float-left"[innerHTML]="faq.question"></div>
      <!-- <span class="badge badge-secondary bg-secondary float-right pull-right">Some HTML here if needed</span> -->
    </button>
    <span [innerHTML]="faq.answer"></span>
    </accordion-group>
  </accordion>
</div>