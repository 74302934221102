import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@jsverse/transloco';

// MDB Imports
// import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';

// NGX-Bootstrap imports
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { MainNavigationTypeTwoComponent } from './main-navigation-type-two/main-navigation-type-two.component';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
// import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { MultisiteSidenavComponent } from './sidenav-multisite/multisite-sidenav.component';
import { NavDropdownDoublecheckComponent } from './nav-dropdown-doublecheck/nav-dropdown-doublecheck.component';
import { UserAccountDropdownModule } from '../account/user-account-dropdown/user-account-dropdown.module';
import { WebsitesModule } from '../sites';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ProgressIndicatorsSlimmableModule, ProgressTrackingModule } from '../tracking';
import { CertificationDisplayListsModule } from '../certification/certification-display/certification-display-lists/certification-display-lists.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
// import { LanguageModule } from '../language';



@NgModule({
  declarations: [
    MainNavigationTypeTwoComponent,
    MainNavigationComponent,
    MultisiteSidenavComponent,
    NavDropdownDoublecheckComponent,
    BreadcrumbComponent,
  ],
  exports: [
    MainNavigationTypeTwoComponent,
    MainNavigationComponent,
    MultisiteSidenavComponent,
    BreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    // MdbCollapseModule,
    CollapseModule,
    TranslocoModule,
    RouterModule,
    CarouselModule,
    // MdbDropdownModule,
    BsDropdownModule,
    UserAccountDropdownModule,
    WebsitesModule,
    ProgressTrackingModule,
    ProgressIndicatorsSlimmableModule,
    CertificationDisplayListsModule,
    // LanguageModule // commented out until we can use the NGX-Bootstrap dropdown for the main navigation language switcher (probable conflict with MDBootstrap)
  ],
})
export class NavigationModule { }
