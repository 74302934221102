import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserExtraLite } from '../../../auth';
import { BreadcrumbService } from '../../../navigation/breadcrumb/breadcrumb.service';
import { Snapshot, SnapshotService } from '../../snapshots';
import { SurveyRaterService } from '../../survey-raters/survey-rater.service';

@Component({
  selector: 'multisite-question-presenter',
  templateUrl: './question-presenter.component.html',
  styleUrls: ['./question-presenter.component.scss']
})
export class QuestionPresenterComponent implements OnInit {

  @Input() questions : any[];
  @Input() loading : boolean;
  @Input() error_message_translation_key : string;
  @Input() questionFeedbacks: any = {}; // [question_id]['correction'][any] 
  @Input() titleText : string;
  @Input() titleTranslationKey : string;
  @Input() showNextButton: boolean;
  @Input() showSnapshotContext: boolean;
  @Input() showMultiRaters: boolean;
  @Input() showTryAgainButtonWithFeedback: string; // a translation key
  @Input() hideProgressIndicator: boolean;
  @Input() disableNextButton: boolean;
  @Input() surveyProgressValue: number;
  @Output() submitResponse = new EventEmitter<any>()
  @Output() setSnapshot = new EventEmitter<Snapshot>()
  @Output() next = new EventEmitter<any>()
  snapshot : Snapshot;
  snapshotSubscription : Subscription;
  raters: UserExtraLite[];

  constructor(
    private route : ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private snapshotService :SnapshotService,
    private surveyRaterService: SurveyRaterService,
    ) { }

  doSubmitResponse(questionResponse){
    // TODO - support multiselect (use formArray instead of formGroup?) in the case of MultipleChoiceQuestion
    this.submitResponse.emit(questionResponse);
  }
  gotoNext(){
    this.next.emit();
    // Added by milos for Richard review
    const contentContainer = document.querySelector('.main-section');

    if (contentContainer) {
      contentContainer.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
    };
  }

  getSnapshot(id:number, freshFromServer: boolean){
    this.snapshotSubscription = this.snapshotService.getSnapshot(id,false).subscribe((snapshot)=>{
      this.snapshot = snapshot;
      this.setSnapshot.emit(snapshot);
      this.breadcrumbService.setBreadcrumbFragment({
        urlFragment: 'snapshot',
        fragmentName: this.snapshot.title,
      });
    });
    this.raters = this.surveyRaterService.getDemoRaters();
  }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params : Params)=>{
        if(params['snapshot']){
          this.getSnapshot (+params['snapshot'], false);
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.snapshotSubscription){
      this.snapshotSubscription.unsubscribe();
    }
  }

}
