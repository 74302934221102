import { Injectable } from '@angular/core';
import { UserExtraLite } from '../../auth';

@Injectable({
  providedIn: 'root'
})
export class SurveyRaterService {

  demoRaters : UserExtraLite[];

  constructor() {

    this.demoRaters = [
      {
        'id': null,
        'fname': 'Kimi',
        'lname': 'Sagato',
        'pre_honorific': '',
        'post_honorific': '',
        'name_full_honorific': null,
        'picture': 'https://randomuser.me/api/portraits/men/98.jpg',
        'slug': null,
      },
      {
        'id': null,
        'fname': 'June',
        'lname': 'Bird',
        'pre_honorific': '',
        'post_honorific': '',
        'name_full_honorific': null,
        'picture': 'https://randomuser.me/api/portraits/women/44.jpg',
        'slug': null,
      },
      {
        'id': null,
        'fname': 'Mario',
        'lname': 'Fratelli',
        'pre_honorific': '',
        'post_honorific': '',
        'name_full_honorific': null,
        'picture': 'https://randomuser.me/api/portraits/men/20.jpg',
        'slug': null,
      },
      {
        'id': null,
        'fname': 'Sveta',
        'lname': 'Dolinski',
        'pre_honorific': '',
        'post_honorific': '',
        'name_full_honorific': null,
        'picture': 'https://randomuser.me/api/portraits/women/23.jpg',
        'slug': null,
      },
      {
        'id': null,
        'fname': 'Mawar',
        'lname': 'Wahyuni',
        'pre_honorific': '',
        'post_honorific': '',
        'name_full_honorific': null,
        'picture': 'https://randomuser.me/api/portraits/women/27.jpg',
        'slug': null,
      },
    ];

  }
  getDemoRaters (){
    return this.demoRaters;
  }
}
