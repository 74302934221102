import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Hub, HubService } from '../../hubs';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
import { PageTitleService } from '../../navigation/title/title.service';
import { Programme } from '../programme.model';
import { ProgrammeService } from '../programme.service';

@Component({
  selector: 'multisite-programme-editor',
  templateUrl: './programme-editor.component.html',
  styleUrls: ['./programme-editor.component.scss'],
})
export class ProgrammeEditorComponent implements OnInit, OnDestroy {

  programmeSubscription: Subscription;
  hubSubscription: Subscription;
  loading: boolean;
  hubSlug: string;
  hub: Hub;
  programme: Programme;
  error = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private programmeService: ProgrammeService,
    private hubService: HubService,
    private titleService: PageTitleService,
    private breadcrumbService : BreadcrumbService,
  ) {}

  
  getProgramme(slug : string, freshFromServer: boolean) {
    this.loading = true;

    this.programmeSubscription = this.programmeService
      .getProgrammeForEditing(slug,freshFromServer)
      .subscribe(
        (response) => {
          this.programme = response;
          this.loading = false;
          this.titleService.setTitle(this.programme.name);
        },
        (error) => {
          this.loading = false;
        }
      );
  }
  getHub (slug){
    this.hubService.getManagedHub(slug).subscribe(response => {
      this.hub = response;
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'hub',fragmentName:this.hub.name});
    })
  }

  ngOnInit(): void {
    this.route.url.subscribe(() => {
      let fullUrlParts = this.router.routerState.snapshot.url
        .toLowerCase()
        .split('/')
        .filter((s) => s);
      this.getHub(fullUrlParts[1]);
      this.getProgramme(fullUrlParts[3],false);
    });
  }
  ngOnDestroy () : void {
    if (this.hubSubscription){
      this.hubSubscription.unsubscribe();
    }
    if (this.programmeSubscription){
      this.programmeSubscription.unsubscribe();
    }
  }
}
