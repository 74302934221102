import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'ttt-design07',
  templateUrl: './design07.component.html',
  styleUrls: ['./design07.component.scss'],
})
export class Design07Component implements OnInit {
  max: number;
  rate: number;
  isReadonly: boolean;
  rotate: boolean;
  totalItems: number;
  currentPage: number;
  smallnumPages: number;
  isCollapsed: boolean;
  @ViewChild('niceInfoModal') niceInfoModal;
  modalRef?: BsModalRef;

  constructor(private modalService: BsModalService) {}

  closeModal() {
    this.modalRef.hide();
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit(): void {
    this.max = 5;
    this.rate = 5;
    this.isReadonly = true;
    this.rotate = true;
    this.isCollapsed = false;
    this.totalItems = 64;
    this.currentPage = 1;
    this.smallnumPages = 0;
  }

  pageChanged(event: PageChangedEvent): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
  }
}
