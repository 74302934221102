import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'multisite-progress-indicator-slider',
  templateUrl: './progress-indicator-slider.component.html',
  styleUrls: ['./progress-indicator-slider.component.scss'],
})
export class ProgressIndicatorSliderComponent implements OnInit {

  @Input() progressPercent: number; // 55.3
  @Input() barBackground: string = 'linear-gradient(90deg, rgba(var(--traffic-light-000-rgb), 1) 0%, rgba(var(--traffic-light-900-rgb), 1) 100%)';
  @Input() indicatorColorStyle: string = '--primary-light';
  @Input() barWidth: string = '100%';
  @Input() barMinWidth: string = '180px';
  @Input() barPosition: string = 'relative'; // 'absolute' or 'relative'
  
  constructor() { }

  ngOnInit(): void {

  }

}
