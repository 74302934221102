import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileUserRoutingModule } from './profile-user-routing.module';
import { VerticalMenuTypeOneModule } from '../navigation';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TranslocoModule } from '@jsverse/transloco';
import { LoadersModule } from '../loaders';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileUserHomeComponent } from './profile-user-home/profile-user-home.component';
import { ProfilePictureSettingsComponent } from './profile-picture-settings/profile-picture-settings.component';


@NgModule({
  declarations: [
    ProfileUserHomeComponent,
    ProfilePictureSettingsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProfileUserRoutingModule,
    TranslocoModule,
    VerticalMenuTypeOneModule,
    AlertModule,
    LoadersModule,
  ],
  exports: [
  
    ProfileUserHomeComponent
  ]
})
export class ProfileUserModule { }
