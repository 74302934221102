import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService, AvailableLanguage, Contributor, Culture, CultureService, Experience, LanguageService, MediaService, Snapshot, SnapshotService, Topic, TopicService, User, WindowService } from '@frontend/common';
import { ActivityLogService, Tag } from '@frontend/core';
import { catchError, forkJoin, map, of, Subscription, switchMap } from 'rxjs';
import { PageTitleService } from '../../navigation/title/title.service';
import { DataProcessingService } from '../../utilities/data-processing.service';
import { Survey } from '../survey.model';
import { LanguagePreferences, SurveysService } from '../surveys.service';
import { Progressable } from '../../tracking';
import { TrackingService } from '../../tracking/tracking.service';
import { Guest } from '../../auth/user/guest.model';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'multisite-survey-home',
  templateUrl: './survey-home.component.html',
  styleUrls: ['./survey-home.component.scss']
})
export class SurveyHomeComponent implements OnInit, OnDestroy {

  @ViewChild('blockingSnapshotModal') blockingSnapshotModal;
  modalRef?: BsModalRef;
  loading: boolean;
  loadingObject: {[key:string]:boolean} = {}; // .content .survey .starting .new_guest
  survey : Survey;
  cultures: Culture[];
  topics: Topic[];
  durationConverted: number;
  contributors: Contributor[];
  subscriptions : Subscription[] = [];
  loadingNewGuestError: string;
  languagePreferences : LanguagePreferences;
  surveyProgressValue: number;
  user : User = null;
  guest : Guest = null;
  prefersToContinueWithoutLoggingIn: boolean;
  ratingMax: number;
  error : string = null;
  error_code : number = null;
  nextQuestion: any; // could be any type of question
  isCollapsedLessons = false;
  started: boolean; // has the current user started the survey already
  loadingRelatedExperiences: boolean;
  loadedRelatedExperiences: boolean; // to avoid requesting these twice
  thumbnailTransformations: string = 'c_fill,g_auto,ar_16:9,w_700/';
  activeLanguageObject: AvailableLanguage;
  snapshot: Snapshot;
  blockingSnapshot: Snapshot; // another snapshot which is preventing us from using this current snapshpt
  tagTypesForDisplay: string[] = ['topic','culture'];
  tagsForDisplay: Tag[];
  cloudinary_base_url : string; // 'https://res.cloudinary.com/cebt/image/upload/'

  constructor(
    private surveysService : SurveysService,
    private route : ActivatedRoute,
    private router : Router,
    private authService : AuthService,
    private activityLogService : ActivityLogService,
    private pageTitleService : PageTitleService,
    private breadcrumbService : BreadcrumbService,
    private dataProcessingService : DataProcessingService,
    private trackingService: TrackingService,
    private mediaService : MediaService,
    private languageService: LanguageService,
    private topicService: TopicService,
    private cultureService: CultureService,
    private snapshotService : SnapshotService,
    private modalService: BsModalService,
    private windowService: WindowService,
  ) {
      this.cloudinary_base_url = this.mediaService.cloudinary_base_url;
  }

  roundRating (num: number){
    return num ? Number(num).toFixed(1): 0;
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  closeModal(){
    this.modalRef.hide();
  }
  getFallbackFlagUrl (){
    return this.mediaService.fallback_flag_round_url;
  }
  isAnythingLoading(){
    for (let something in this.loadingObject){
      if (this.loadingObject[something]){
        return true;
      }
    }
    return false;
  }

  convertSurveyDuration (duration: number){
    let converted : number = 0;
    if (duration){
      converted = Number(this.dataProcessingService.convertMillisecondsToMinutes(duration, 59000));
    };
    return converted;
  }
  goToSnapshot(snapshot:Snapshot){
    this.closeModal();
    if (snapshot){
      this.router.navigate(['/snapshots/'+snapshot.id]);
    }
  }

  getRelatedExperiences (){
    if (!this.user && !this.guest){
      // do something or nothing
    } else if (this.survey && !this.loadedRelatedExperiences && !this.loadingRelatedExperiences) {
      this.loadingRelatedExperiences = true;
      this.surveysService.getRelatedExperiences (this.survey.slug,+this.route.snapshot.params['snapshot'])
        .subscribe(
          (response : Experience[]) => {
            this.loadingRelatedExperiences = false;
            this.loadedRelatedExperiences = true;
            this.started = this.surveysService.getBooleanStatusOfSurvey(response,this.survey.id,'started');
            this.surveyProgressValue = this.surveysService.getProgressValueOfSurvey(response,this.survey);
          },
          error => {
            this.loadingRelatedExperiences = false;
          }
        );
    }
  }
  formatContributors (contributors : Contributor[]){
    if (!contributors?.length){return [];};
    return contributors.map(c => {
      c.user = Object.assign(c.user,{picture:this.mediaService.insertCloudinaryTransformationsIntoMediaUrl(c.user.picture,'w_24,h_24,c_thumb,g_face/')});
      return c;
    })
  }
  getSnapshot(id:number, freshFromServer: boolean){
    const snapshotSubscription = this.snapshotService.getSnapshot(id,false).subscribe((snapshot)=>{
      this.snapshot = snapshot;
      this.breadcrumbService.setBreadcrumbFragment({
        urlFragment: 'snapshot',
        fragmentName: this.snapshot.title,
      });
    }, error => {
      if (typeof error === 'string'){
        this.error = error;
      } else {
        this.error = error.message;
        this.error_code = error.meta?.error_code;
      }
      if (this.error === 'error.permissions_lacking_view'){
        setTimeout(()=>{
          this.router.navigate(['/snapshots']);
        },5000);
      }
    });
    this.subscriptions.push(snapshotSubscription);
  }
  joinContent(surveySlug : string, freshFromServer: boolean){
    this.loadingObject.content = true;
    return this.cultureService.getCultures ('geographic','national',freshFromServer).pipe(
      switchMap(cultures => forkJoin({
        topics: this.topicService.getTopics('learner', null ,false),
        // differences: this.differenceService.getDifferences(null, null),
        // cultures: this.cultureService.getCultures('geographic', 'national', false),
        cultures: of(cultures)
      })),
      map((result : { topics: Topic[], /*differences: Difference[],*/ cultures: Culture[], survey : Survey }) => {
        // use the data here if you want
        this.loadingObject.content = false;
        return result;
      }),
      catchError(error => {
        this.loadingObject.content = false;
        console.error('Error getting content:', error);
        throw error; // Rethrow the error
      })
    );
  }
  getContent(slug:string, freshFromServer: boolean){
    const contentSubscription = this.joinContent(slug,freshFromServer).subscribe({
      next: result => {

        if(!this.survey.cultures){
          this.surveysService.insertCulturesIntoCachedSurveys(result.cultures);
          this.survey = this.surveysService.insertCulturesIntoSurvey(this.survey, result.cultures);
        }
        if(!this.survey.topics){
          this.surveysService.insertTopicsIntoCachedSurveys(result.topics);
          this.survey = this.surveysService.insertTopicsIntoSurvey(this.survey, result.topics);
        }
        let activityLogParams = {
          'model':'survey',
          'identifier' : slug,
          'identifier_type' : 'slug',
          'activity_type' : 'viewed',
          'properties' : null
        };
        this.activityLogService.log(activityLogParams);
      },
      error: error => {
        // Already handled in joinContent
      }
    });
    this.subscriptions.push(contentSubscription);
  }

  getSurvey (slug:string, freshFromServer: boolean){
    this.loadingObject.survey = true;
    this.error = null;
    const surveySub = this.surveysService.getSurvey (slug,freshFromServer)
      .subscribe(
        response => {
          if (response){
            this.survey = response;
            // this.tagsForDisplay = this.dataProcessingService.sortArrayOfObjectsByProperty(this.survey.tags.filter(t=> this.tagTypesForDisplay.includes(t.type)),'type') ;
            this.contributors = this.formatContributors(response.contributors);
            this.pageTitleService.setTitle(this.survey.name);
            if (this.user || this.guest){
              this.getRelatedExperiences();
              this.getLanguagePreferences(this.survey.id);
            }
            this.getContent(slug,freshFromServer);
          }
        this.loadingObject.survey = false;
        },
        error => {
          this.error = error;  
          this.loadingObject.survey = false;
        }
      );
      this.subscriptions.push(surveySub);
  };
  findCurrentSurveyProgressFromProgressables (progressables : Progressable[]){
    if (!progressables?.length || !this.survey){
      return null;
    }
    return this.trackingService.getProgressFromProgressables(progressables,'survey',this.survey.slug);
  }
  getSurveyProgress (){
    const progressablesSubscription = this.trackingService.progressables.subscribe( progressables => {
      this.surveyProgressValue = this.findCurrentSurveyProgressFromProgressables(progressables);
    })
    this.subscriptions.push(progressablesSubscription);
  }
  getLanguagePreferences(survey_id:number){
    const languagePreferencesSubscription = this.surveysService.getLanguagePreferences(survey_id).subscribe(response => {
    this.languagePreferences = response;
    });
    this.subscriptions.push(languagePreferencesSubscription);
}
  goToQuestions (){
    if (this.surveyProgressValue > 99){
      this.router.navigate(['feedback'], { relativeTo: this.route });
    } else {
      this.router.navigate(['questions'], { relativeTo: this.route });
    }
  }
  setActiveLanguage(languageKey : string){
    this.languageService.setLanguage(languageKey);
  }
  languagePreferenceOptionIsAvailable (){ // we should send the user to choose an option
    if (!this.activeLanguageObject){return false;}; // this may in fact be true, but we cannot be sure if there is no activeLanguageObject
    if (!this.survey?.meta?.available_languages){return false;}; // this may in fact be true, but we cannot be sure if there is no activeLanguageObject
    if (
      this.survey.meta.available_languages.length > 1 ||
      (this.survey.meta.available_languages.length === 1 && this.survey.meta.available_languages.find(al=>al.grammatical_forms?.length > 1 && al.iso === this.activeLanguageObject.languageKey)) ||
      !this.survey.meta.available_languages.find(al=>al.iso === this.activeLanguageObject.languageKey)
      ){
      return true;
    }
    return false;
  }
  goToNextStep (){
    if (this.survey?.meta?.available_languages){
      if (!this.languagePreferences){
        this.goToLanguagePreferences();
        return;
      }
      if (!this.survey.meta.available_languages.find(al=>al.iso === this.activeLanguageObject.languageKey)){
        if (Object.keys(this.languagePreferences).length == 1){
          // change the language and then go to the next step
          this.setActiveLanguage(Object.keys(this.languagePreferences)[0]);
          if (this.survey.instructions?.length){
            this.goToInstructions()
          } else {
            this.goToQuestions();
          }
        }
        return;
      }
    }
    if(!this.surveyProgressValue && this.survey.instructions?.length){
      this.goToInstructions();
    } else {
      this.goToQuestions();
    }
  }
  goToLanguagePreferences (){
    this.router.navigate(['language'], { relativeTo: this.route });
  }
  goToInstructions (){
    if (this.surveyProgressValue > 99){
      this.router.navigate(['feedback'], { relativeTo: this.route });
    } else {
      this.router.navigate(['instructions'], { relativeTo: this.route });
    }
  }
  goToLogin(){
    this.authService.setRouteForRedirectAfterAuth([window.location.pathname]);
    this.router.navigate(['/login']);
  }
  makeGuest(){
    this.loadingObject.new_guest = true;
      const makingGuestSub = this.authService.makeGuest(null,null,null)
        .subscribe((response)=>{
          this.loadingObject.new_guest = false;
        }, error => {
          this.loadingObject.new_guest = false;
          this.loadingNewGuestError = error;
        });
      this.subscriptions.push(makingGuestSub);
  }
  acceptSnapshot (){
    if (this.snapshot && !this.snapshot.current_accepted){
      return this.snapshotService.acceptSnapshot(this.snapshot.id)
        .pipe(
          map(snapshot =>{
            if (snapshot){
              this.snapshot = snapshot;
              return true;
            };
          }),
          catchError((error) => {
            this.error = error;
            return of(false);
          })
        )
    } else {
      return of(true);
    }
  };
  doStartSurvey(){
    if (this.started){
      this.goToQuestions();
    } else if (!this.survey.instructions?.length && !this.languagePreferenceOptionIsAvailable()) {
        this.loadingObject.starting = true;
        this.trackingService.submitExperience('survey',this.survey.id,this.survey.slug,1,'started',null).subscribe(response=>{
          this.loadingObject.starting = false;
          this.goToQuestions();
        }, error => {
          this.loadingObject.starting = false;
        })
    } else {
      this.goToNextStep();
    };
  }
  startSurveyWithinSnapshot (){
    this.blockingSnapshot = null;
    this.snapshotService.findBlockingSnapshot(this.snapshot).subscribe((blockingSnapshot : Snapshot) => {
      if (!blockingSnapshot){
        return this.acceptSnapshot().subscribe(accepted => {
          if (accepted) {
            this.doStartSurvey();
          } else {
            this.error = 'survey.snapshot_cannot_accept'
            this.loadingObject.starting = false;
          }
        }, error => {
            this.loadingObject.starting = false;
            // TODO - do something here
        });
      } else {
        this.blockingSnapshot = blockingSnapshot;
        this.openModal(this.blockingSnapshotModal);
        this.loadingObject.starting = false;
      }
     })
  }
  startSurvey (){
    this.error = null;
    this.loadingObject.starting = true;
    if (this.user || this.guest){
      if (this.snapshot){
        this.startSurveyWithinSnapshot ();
      } else {
        this.doStartSurvey();
      }
    } else {
      alert('You must log in or continue as a visitor');
    }
  }

  ngOnInit(): void {
    this.windowService.goToTop();
    const userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
      this.getRelatedExperiences();
    });
    this.subscriptions.push(userSubscription);
    const guestSub = this.authService.guest.subscribe(guest => {
      this.guest = guest;
      this.getRelatedExperiences();
    });
    this.subscriptions.push(guestSub);
    this.route.params.subscribe(
      (params : Params)=>{
        this.getSurvey (params['survey'], false);
      }
    );
    this.route.params.subscribe(
      (params : Params)=>{
        if(params['snapshot']){
          this.getSnapshot (+params['snapshot'], false);
        }
      }
    );
    this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
    const activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
        // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
        if (newActiveLanguage?.languageKey !== this.activeLanguageObject.languageKey){
          this.activeLanguageObject = newActiveLanguage;
          if (this.survey){
            this.getSurvey(this.survey.slug, true);
          }
        }
    });
    this.subscriptions.push(activeLanguageSubscription);
    this.getSurveyProgress ();
  }
  ngOnDestroy () {
    this.subscriptions.forEach(subscription=>subscription.unsubscribe());
  }

}
