import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentListFormatsComponent } from '../content-list-formats/content-list-formats.component';
import { CardsModule } from '../../../cards';
import { PaginationModule } from '@frontend/shared';
import { TranslocoModule } from '@jsverse/transloco';



@NgModule({
  declarations: [
    ContentListFormatsComponent,
  ],
  exports: [
    ContentListFormatsComponent,
  ],
  imports: [
    CommonModule,
    CardsModule,
    PaginationModule,
    TranslocoModule,
  ]
})
export class ContentListFormatsModule { }
