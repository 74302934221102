import { Icon } from '@frontend/shared'
import { Media } from './media.model'

export class  Link {
  constructor(
    public id: number,
    public media: Media,
    public icon: Icon,
    public label: string, // "Brenda Turnnidge's World of Photography"
    public name: string, // "Brenda Turnnidge homepage"
    public url: string, // "https:\/\/www.brenda-turnnidge.fr"
    public description: string, // "A personal blog and archive of cross-cultural images by the interculturalist and photographer Brenda Turnnidge",
    public cta_label: string, // "Explore"
    public cta_type: string, // "resource" // we may use this to make the translation key 'call_to_action.resource'
    public category: string,
    public type: string,
  ){}
}