<div class="container-fluid">
    <nav id="breadcrumb" aria-label="breadcrumb" class="main-breadcrumb">
        <h1 class="page-title">
            Topic
        </h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Topic</li>
        </ol>
    </nav>
    <div class="row">
        <div class="wide-card mt-4 mb-3">
            <div class="card">
                <div class="row">
                    <div class="col-md-3">
                        <img class="card-img-left"
                            src="https://images.pexels.com/photos/317157/pexels-photo-317157.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                            alt="Yoga Trainer">
                    </div>
                    <div class="col-md-9">
                        <div class="card-body">
                            <h5 class="card-title">The Intercultural Pendulum</h5>
                            <p class="card-text">
                                The IC Pendulum helps coaches get faster, more long-lasting results with clients who are
                                dealing
                                with the disruptive affects of crossing cultures. The tool is an evolution of the
                                Intercutural
                                Development Continuum. You can use the IC Pendulum as a coach, trainer or consultant,
                                counsellor
                                or other professional supporting people in international situations. If you simply want
                                to
                                improve your own international life, not your clients, start first at <a
                                    href="#">www.icpendulum.com</a>
                            </p>
                            <div class="info-group">
                                <div class="text-badge">
                                    <p class="title">
                                        Courses
                                    </p>
                                    <p class="value">
                                        03
                                    </p>
                                </div>
                                <span class="splitter"></span>
                                <div class="text-badge">
                                    <p class="title">
                                        Tips
                                    </p>
                                    <p class="value">
                                        14
                                    </p>
                                </div>
                                <span class="splitter"></span>
                                <div class="img-badge">
                                    <img src="https://iili.io/HH3KBiF.png" alt="Badge">
                                    <p class="title">
                                        Accreditation available
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 mt-2">
            <button class="btn button-wide btn-warning">
                <p>Explore our tips</p>
                <i class="fa-solid fa-angle-right"></i>
            </button>
        </div>
        <div class="col-md-6 mt-2">
            <button class="btn button-wide btn-primary" (click)="openModal(niceInfoModal)">
                <p>Explore our courses</p>
                <i class="fa-solid fa-angle-right"></i>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="carousel-card">
                <carousel [interval]="false">
                    <slide>
                        <div class="row">
                            <div class="col-md-4 slide-content">
                                <div class="img-holder">
                                    <div class="main-img"
                                        style="background: url('https://images.pexels.com/photos/810775/pexels-photo-810775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1') no-repeat;">
                                    </div>
                                    <div class="rating">
                                        <pre class="rate-value"><b>{{rate}}</b></pre>
                                        <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 text-content">
                                <p class="title">
                                    Take two full minutes to breathe before reacting
                                </p>
                                <div class="badges">
                                    <span class="badge badge-pill badge-info">Mindfulness</span>
                                    <span class="badge badge-pill badge-warning">Meditation</span>
                                </div>
                                <p class="text">
                                    Many people find it very useful to take a pause to breathe when hit by a moment
                                    of culture shock.
                                </p>
                                <div class="action">
                                    <button>
                                        <p>Complete understanding</p>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </button>
                                    <p class="reading-time">
                                        in <span>10</span>min
                                    </p>
                                </div>
                            </div>
                        </div>
                    </slide>
                    <slide>
                        <div class="row">
                            <div class="col-md-4 slide-content">
                                <div class="img-holder">
                                    <div class="main-img"
                                        style="background: url('https://images.pexels.com/photos/158465/waterlily-pink-water-lily-water-plant-158465.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1') no-repeat;">
                                    </div>
                                    <div class="rating">
                                        <pre class="rate-value"><b>{{rate}}</b></pre>
                                        <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 text-content">
                                <p class="title">
                                    I'm title number two
                                </p>
                                <div class="badges">
                                    <span class="badge badge-pill badge-info">Mindfulness</span>
                                    <span class="badge badge-pill badge-warning">Meditation</span>
                                </div>
                                <p class="text">
                                    Many people find it very useful to take a pause to breathe when hit by a moment
                                    of culture shock.
                                </p>
                                <div class="action">
                                    <button>
                                        <p>Complete understanding</p>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </button>
                                    <p class="reading-time">
                                        in <span>10</span>min
                                    </p>
                                </div>
                            </div>
                        </div>
                    </slide>
                    <slide>
                        <div class="row">
                            <div class="col-md-4 slide-content">
                                <div class="img-holder">
                                    <div class="main-img"
                                        style="background: url('https://images.pexels.com/photos/7869588/pexels-photo-7869588.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1') no-repeat;">
                                    </div>
                                    <div class="rating">
                                        <pre class="rate-value"><b>{{rate}}</b></pre>
                                        <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 text-content">
                                <p class="title">
                                    I'm the last one :D
                                </p>
                                <div class="badges">
                                    <span class="badge badge-pill badge-info">Mindfulness</span>
                                    <span class="badge badge-pill badge-warning">Meditation</span>
                                </div>
                                <p class="text">
                                    Many people find it very useful to take a pause to breathe when hit by a moment
                                    of culture shock.
                                </p>
                                <div class="action">
                                    <button>
                                        <p>Complete understanding</p>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </button>
                                    <p class="reading-time">
                                        in <span>10</span>min
                                    </p>
                                </div>
                            </div>
                        </div>
                    </slide>
                    <div class="card-header">
                        <p class="carousel-title">Latest tips</p>
                        <p class="carousel-index">
                            <span class="curent-index">
                                1
                            </span>
                            /
                            <span class="max-index">
                                3
                            </span>
                        </p>
                    </div>
                </carousel>
            </div>
        </div>
        <div class="col-md-6">
            <div class="carousel-card">
                <carousel [interval]="false">
                    <slide>
                        <div class="row">
                            <div class="text-content">
                                <p class="title">
                                    Kudos to the platform and the teachers!
                                </p>
                                <p class="text mt-2">
                                    “Ullamcorper platea nec donec sed lectus fringilla adipiscing velit facilisis.
                                    Mauris sagittis faucibus amet varius sollicitudin enim, ullamcorper. Mi risus cras
                                    quam faucibus libero rutrum non interdum. Expedita consequatur praesentium commodi,
                                    laboriosam
                                    eligendi facere voluptates nobis asperiores.”
                                </p>
                                <div class="action">
                                    <div class="profile">
                                        <div class="profile-img"
                                            style="background: url('https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg') no-repeat;">
                                        </div>
                                        <div class="text">
                                            <p class="name">
                                                Amy Goldberg
                                            </p>
                                            <p class="position">
                                                COO at Slack
                                            </p>
                                        </div>
                                    </div>
                                    <span class="splitter"></span>
                                    <div class="rating">
                                        <pre class="rate-value"><b>{{rate}}</b></pre>
                                        <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slide>
                    <slide>
                        <div class="row">
                            <div class="text-content">
                                <p class="title">
                                    I'm title number two!
                                </p>
                                <p class="text mt-2">
                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus modi dicta fuga
                                    veritatis esse eum ex obcaecati cupiditate ad cum! Debitis blanditiis nam,
                                    laudantium nihil ipsam quas labore asperiores tempora a quos quidem eos sunt,
                                    possimus fugit sapiente impedit minima. Quibusdam suscipit facere ullam magnam
                                    nesciunt accusamus."
                                </p>
                                <div class="action">
                                    <div class="profile">
                                        <div class="profile-img"
                                            style="background: url('https://images.pexels.com/photos/91227/pexels-photo-91227.jpeg') no-repeat;">
                                        </div>
                                        <div class="text">
                                            <p class="name">
                                                Milos usljebrka
                                            </p>
                                            <p class="position">
                                                UX/UI Designer
                                            </p>
                                        </div>
                                    </div>
                                    <span class="splitter"></span>
                                    <div class="rating">
                                        <pre class="rate-value"><b>{{rate}}</b></pre>
                                        <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slide>
                    <slide>
                        <div class="row">
                            <div class="text-content">
                                <p class="title">
                                    I'm the last one :D
                                </p>
                                <p class="text mt-2">
                                    "Amet consectetur adipisicing elit. Debitis exercitationem
                                    nesciunt earum iusto saepe blanditiis id vel perferendis obcaecati distinctio, odio
                                    reiciendis dolores sapiente, expedita consequatur praesentium commodi, laboriosam
                                    eligendi facere voluptates nobis asperiores? Officia, voluptatibus non adipisci
                                    numquam expedita reprehenderit soluta."
                                </p>
                                <div class="action">
                                    <div class="profile">
                                        <div class="profile-img"
                                            style="background: url('https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg') no-repeat;">
                                        </div>
                                        <div class="text">
                                            <p class="name">
                                                John Doe
                                            </p>
                                            <p class="position">
                                                Web Developer
                                            </p>
                                        </div>
                                    </div>
                                    <span class="splitter"></span>
                                    <div class="rating">
                                        <pre class="rate-value"><b>{{rate}}</b></pre>
                                        <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slide>
                    <div class="card-header">
                        <p class="carousel-title">Satisfied customers</p>
                        <p class="carousel-index">
                            <span class="curent-index">
                                1
                            </span>
                            /
                            <span class="max-index">
                                3
                            </span>
                        </p>
                    </div>
                </carousel>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<ng-template #niceInfoModal>
    <!--<div class="modal-header">
     <h4 class="modal-title pull-left">Modal</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div> -->
    <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left">We want you to know...</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true">
                <i class="fa-solid fa-xmark"></i>
            </span>
        </button>
    </div>
    <div class="modal-body">
        <p>There are currently no active courses that you could apply for. We strive to add new courses as soon as
            possible. In order not to miss some of our courses, you can subscribe to the newsletter:</p>
        <form class="bilten-subscription-modal" action="" method="post">
            <div class="mt-3">
                <input type="email" class="input-email" name="" id="" placeholder="Your Email">
                <input class="btn btn-primary btn-sm" type="submit" value="Submit">
            </div>
        </form>

    </div>
    <!-- <div class="modal-footer">
    <button type="button" class="btn-primary-outline pull-right" aria-label="Close"
    (click)="niceInfoModal.hide()">
    Close
</button>
</div> -->
</ng-template>