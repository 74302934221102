import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageTitleService } from '../../../navigation/title/title.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService, CiResult, CiResultService, ContentItem, DatetimeProcessingService, Experience, FormCachingService, LanguageService, MetaText, Snapshot, SnapshotService, User, UserExtraLite, WindowService } from '@frontend/common';
import { finalize, Subscription } from 'rxjs';
import { DataProcessingService } from '../../../utilities/data-processing.service';
import { Survey } from '../../survey.model';
import { SurveysService } from '../../surveys.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Guest } from '../../../auth/user/guest.model';
import { SurveyRaterService } from '../../survey-raters/survey-rater.service';
import { HttpEventType } from '@angular/common/http';

type SurveyWrapper = {
  survey:Survey,
  relatedExperiences:Experience[],
  started: boolean,
  progressValue: number,
  remainingMinutes: number,
  loadingSurvey:boolean,
  loadingRelatedExperiences:boolean,
  slug:string
};

@Component({
  selector: 'multisite-snapshot-home',
  templateUrl: './snapshot-home.component.html',
  styleUrls: ['./snapshot-home.component.scss']
})
export class SnapshotHomeComponent implements OnInit, OnDestroy {

  modalRef?: BsModalRef;
  @ViewChild('cancelSnapshotModal') cancelSnapshotModal;
  @ViewChild('endSnapshotModal') endSnapshotModal;
  @ViewChild('editRatersModal') editRatersModal;
  @ViewChild('snapshotEditModal') snapshotEditModal;
  @ViewChild('imageUploaderModal') imageUploaderModal;
  loading: boolean;
  snapshot: Snapshot;
  survey : Survey;
  surveyWrappers : SurveyWrapper[] = [];
  starting: boolean; // submitting a 'started' experience to the backend
  durationConverted: number;
  userSubscription: Subscription;
  snapshotSubscription: Subscription;
  guestSub: Subscription; // existing from storage
  // surveySub: Subscription; // deleted by Angular
  loadingLinks: boolean;
  loadingSnapshotEdits: boolean;
  loadingArray: {name:string, loading: boolean}[] = [];
  progressablesSubscription: Subscription;
  resultSubscription: Subscription;
  user : User = null;
  guest : Guest = null;
  prefersToContinueWithoutLoggingIn: boolean;
  ratingMax: number;
  error = null;
  nextQuestion: any; // could be any type of question
  isCollapsedLessons = false;
  started: boolean; // has the current user started the survey already
  loadingRelatedExperiences: boolean;
  loadedRelatedExperiences: boolean; // to avoid requesting these twice
  thumbnailTransformations: string = 'ar_16:9,w_200/';
  mainImageTransformations: string = 'c_fill,g_auto,h_250,w_970/b_rgb:000000,e_gradient_fade,y_-0.50/c_scale,co_rgb:ffffff,fl_relative/';
  activeLanguageSubscription: Subscription;
  alive: boolean = true; // experimenting with this way of destroying subscriptions
  raters: UserExtraLite[];
  imageDescriptionTranslationKey: 'content_management.cultural_situation_image'
  resetFormOnChange : number; // an integer counter starting at 0
  imageUplodingDisabled: boolean;
  gallerySubscription: Subscription;
  galleryItemSelectSubscription: Subscription;
  fileUploadSubscription: Subscription;
  fileUploadError: string;
  fileUploadSuccess: string;
  uploadProgress: number; // {'profile':{'feature':77}}
  multiRatersRequired: boolean; // true is any of the related surveys requires this
  multiRaterSelectionInstructions: MetaText[] = [];
  ciResult : CiResult;
  formCachingKey: string = 'snapshot_create_edit';

  constructor(
    private modalService: BsModalService,
    private surveysService : SurveysService,
    private route : ActivatedRoute,
    private router : Router,
    private authService : AuthService,
    private pageTitleService : PageTitleService,
    private dataProcessingService : DataProcessingService,
    private datetimeProcessingService : DatetimeProcessingService,
    private snapshotService: SnapshotService,
    private surveyRaterService: SurveyRaterService,
    private languageService: LanguageService,
    private ciResultService: CiResultService,
    private formCachingService: FormCachingService,
    private windowService: WindowService,
  ) { }

  closeModal(){
    if (this.modalRef){
      this.modalRef.hide();
    }
  }
  openModal(template: TemplateRef<any>) {
    this.error = null;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-lg' })
    );
  }

  isLoading(name:string){
    if(name){
      return this.loadingArray.find(l=>l.name === name && l.loading);
    }
    return this.loadingArray.find(l => l.loading);
  }
  toggleLoading (name:string, loading: boolean){
    let index = this.loadingArray.findIndex(l=>l.name === name);
    if (index > -1){
      this.loadingArray[index].loading = loading;
    } else {
      this.loadingArray.push({name,loading});
    }
  }

  onFormDataChanged (formData : FormData){
    formData.append('snapshot_id',this.snapshot.id.toString());
    // do something
    this.uploadFile(formData);

  }
  uploadFile (formData : FormData){
    this.fileUploadSuccess = null;
    this.fileUploadError = null;
    if (!this.snapshot || !formData){return;};
    formData.append('category','profile');
    formData.append('type','banner');
    const upload$ = this.snapshotService.uploadFile(this.snapshot.id,formData).pipe(
      finalize(() => {
        // Code to run when the subscription is finalized (cancelled or completed)
        // For example, you can reset any relevant properties or perform cleanup tasks
      })
    );
    this.uploadProgress = 0;
    this.fileUploadSubscription = upload$.subscribe(event => {
      if (event.type == HttpEventType.UploadProgress) {
        this.uploadProgress = Math.round(100 * (event.loaded / event.total));
      } else if (event.type === HttpEventType.Response) {
        this.fileUploadSuccess = 'common.saved';
        this.uploadProgress = null;
        this.getSnapshot(this.snapshot?.id,true);
        debugger; // why do we need to get this fresh from the server? this.getSnapshot(this.snapshot?.id,true)
      }
    }, error=>{
      this.fileUploadError = error;
        this.uploadProgress = null;
    });
  }
  cancelUploadFile (bool : boolean){
    this.fileUploadSuccess = null;
    if (this.fileUploadSubscription){
      this.fileUploadSubscription.unsubscribe();
    }
    this.uploadProgress = null;
  }
  convertSurveyDuration (duration: number){
    let converted : number = 0;
    if (duration){
      converted = Number(this.dataProcessingService.convertMillisecondsToMinutes(duration, 59000));
    };
    return converted;
  }

  round(number:number){
    return number === 0 || number > 0 ? Math.round(number) : 0;
  }
  isDateWithinXDays(date: Date, x : number): boolean {
   return this.datetimeProcessingService.isDateWithinXDays(date,x);
  }
  isDatePassed(date: Date): boolean {
   return this.datetimeProcessingService.isDatePassed(date);
  }
  allSurveysCompleted (){
    return (this.surveyWrappers.length && (
      // this.surveyWrappers.filter(sw=>!sw.progressValue || sw.progressValue < 100).length > 0)
      this.surveyWrappers.every(sw => sw.relatedExperiences?.some(e => e.experience_verb === 'completed' && e.experience_value === 1))
      ) ? true:false
    );

  }
  anySurveysStarted (){
    return this.surveyWrappers.length && this.surveyWrappers.filter(sw=>sw.started).length > 0 ? true:false;
  }

  getResults(){
    if (!this.snapshot || !this.allSurveysCompleted()){
      return;
    }
    if (this.snapshotIncludesSurveyCategoryAndType('ciprofile','profiling') && !this.isLoading('ciResult') && !this.ciResult){
      this.getCiProfileResult(this.snapshot.id,false);
    }
  }

  getRelatedExperiences (surveyWrapper : SurveyWrapper){
    if (!this.user && !this.guest){
      // do something or nothing
    } else if (surveyWrapper.survey && !surveyWrapper.relatedExperiences && !surveyWrapper.loadingRelatedExperiences) {
      surveyWrapper.loadingRelatedExperiences = true;
      this.surveysService.getRelatedExperiences (surveyWrapper.slug,this.route.snapshot.params['snapshot'])
        .subscribe(
          (response : Experience[]) => {
            surveyWrapper.loadingRelatedExperiences = false;
            surveyWrapper.relatedExperiences = response;
            surveyWrapper.started = this.surveysService.getBooleanStatusOfSurvey(response,surveyWrapper.survey.id,'started');
            surveyWrapper.progressValue = this.surveysService.getProgressValueOfSurvey(response, surveyWrapper.survey);
            surveyWrapper.remainingMinutes = Math.ceil(this.convertSurveyDuration(surveyWrapper.survey.duration) * (100-(surveyWrapper.progressValue ?? 0))/100);
            this.getResults();
          },
          error => {
            this.loadingRelatedExperiences = false;
          }
        );
    }
  }
  getAllRelatedExperiences (){
    if (!this.user && !this.guest){
      // do something or nothing
    };
    this.surveyWrappers.forEach(sw=>{
      if(sw.survey && !sw.loadingRelatedExperiences && !sw.relatedExperiences){
        this.getRelatedExperiences(sw);
      }
    });
  }
  getSnapshot(id:number, freshFromServer: boolean){
    this.snapshotSubscription = this.snapshotService.getSnapshot(id,false).subscribe((snapshot : Snapshot)=>{
      this.snapshot = snapshot;
      this.getRelatedSurveys(this.snapshot.surveys ? this.snapshot.surveys.map(s=>s.slug):[]);
      this.pageTitleService.setTitle(snapshot.title);
      this.loading = false;
    },
      error => {
        this.error = error;
        this.loading = false;
      }
    );
  }
  requestCancelSnapshot(){
    if ((!this.snapshot?.cohort_id && !this.anySurveysStarted()) || (this.anySurveysStarted() && !this.allSurveysCompleted())){
      this.openModal(this.cancelSnapshotModal)
    }
  }
  cancelSnapshot(){
    this.error = null;
    this.loading = true;
    // remove acceptance of this snapshot
    // if self-created snapshot, also delete the snapshot
    this.snapshotSubscription = this.snapshotService.cancelSnapshot(this.snapshot.id).subscribe((snapshot)=>{
      this.closeModal();
      this.loading = false;
      this.router.navigate(['../']);
    },
    error => {
      this.error = error;
      this.loading = false;
    })
  }

  prioritiesWithCta(priorities_as_content_items : ContentItem[]){
    if (!priorities_as_content_items){return null};
    return priorities_as_content_items.map(p => {
      p.cta_translationKey = 'content.feedback';
      return p;
    })
  }

  getCiProfileResult (snapshot_id : number, freshFromServer : boolean){
    this.toggleLoading('ciResult',true);
    this.resultSubscription = this.ciResultService.getCiResult(snapshot_id,false).subscribe((result)=>{
      this.ciResult = result;
      this.toggleLoading('ciResult',false);
      // if (this.detailSlug){
      //   this.initialise(this.detailSlug);
      // }
      // this.breadcrumbService.setBreadcrumbFragment({
      //   urlFragment: 'snapshot',
      //   fragmentName: this.snapshot.title,
      // });
    }, error => {
      if (typeof error === 'string'){
        this.error = error;
      } else {
        this.error = error.message;
        // this.error_code = error.meta?.error_code;
      }
      if (this.error === 'error.permissions_lacking_view'){
        // this.errorTimeout1 = setTimeout(()=>{
        //   this.router.navigate(['/snapshots']);
        // },5000);
      }
      this.toggleLoading('ciResult',false);
    })
  }
  gotoRaters(){
    this.closeModal();
    this.router.navigate(['/connections/raters']);
  }
  gotoFeedback(slug:string){
    let route = 'feedback';
    // TODO - this is fragile. It only supports routes with this structure /feedback/survey-name/ not /survey-name/feedback; consider making the routing an environment variable with process.env
    route = slug ? route+'/'+slug : route;
    this.router.navigate([route], {relativeTo: this.route});
  }
  gotoSurvey(slug:string){
    let completed = this.surveyWrappers.find(sw => sw.slug === slug)?.progressValue > 99;
    if (completed){
      this.gotoFeedback(slug);
    } else {
      this.router.navigate([slug], {relativeTo: this.route});
    }
  }
  priorityClick(contentItem :ContentItem){
    if(!this.user){ // TODO - need a stronger way to force login for going to Feedback
      this.router.navigate(['/login']);
    } else if (contentItem){
      this.gotoFeedback(contentItem.slug);
    }
  }
  endSnapshot(){
    this.error = null;
    this.loading = true;
    // remove acceptance of this snapshot
    // if self-created snapshot, also delete the snapshot
    this.snapshotSubscription = this.snapshotService.endSnapshot(this.snapshot.id).subscribe((snapshot)=>{
      this.getSnapshot(this.snapshot.id,false);
      this.closeModal();
      this.loading = false;
    },
    error => {
      this.error = error;
      this.loading = false;
    })
  }
  editSnapshot(updatedSnapshotData : Snapshot){
    this.loadingSnapshotEdits = true;
    this.snapshotSubscription = this.snapshotService.updateSnapshot(this.snapshot, updatedSnapshotData).subscribe((snapshot)=>{
      this.closeModal();
      this.formCachingService.clearDataItem(this.formCachingKey);
      this.snapshot = snapshot;
      this.pageTitleService.setTitle(snapshot.title);
      this.loadingSnapshotEdits = false;

    },
    error => {
      this.loadingSnapshotEdits = false;
      // do something here
    }
    )
  }
  


  getSurvey (slug:string, freshFromServer: boolean){
    let foundSurveyIndex = this.surveyWrappers.findIndex(sw=>sw.slug === slug);
    if (foundSurveyIndex >-1){
      this.surveyWrappers[foundSurveyIndex]['loadingSurvey'] = true;
    }
    let sub : Subscription = this.surveysService.getSurvey (slug,freshFromServer)
      .subscribe(
        survey => {
          if (survey){
            let foundSurveyIndex = this.surveyWrappers.findIndex(sw=>sw.slug === survey.slug);
            if (foundSurveyIndex >-1){
              this.surveyWrappers[foundSurveyIndex]['survey'] = survey;
            } else {
              this.surveyWrappers.push({'survey':survey, relatedExperiences:null,started:null,progressValue:null,remainingMinutes:null,loadingRelatedExperiences:false,loadingSurvey:false,slug:survey.slug});
              foundSurveyIndex = this.surveyWrappers.length-1;
            }
            if (this.user || this.guest){
              this.getRelatedExperiences(this.surveyWrappers[foundSurveyIndex]);
            }
            if(survey.meta?.multi_raters_required_by_default){
              this.multiRatersRequired = true;
              let multiRaterSelectionInstructions = survey.instructions?.length ? survey.instructions.filter(i => i.type === 'multi_rater_selection') : [];
              this.multiRaterSelectionInstructions = this.dataProcessingService.mergeTwoArraysWithUniqueIdentifiersAvoidingDuplicates(this.multiRaterSelectionInstructions,multiRaterSelectionInstructions,'s');
            };
          }
          let foundSurveyIndexAgain = this.surveyWrappers.findIndex(sw => sw.slug ===slug);
          if (foundSurveyIndexAgain > -1){
            this.surveyWrappers[foundSurveyIndexAgain].loadingSurvey = false;
          }

        }, error => {
          this.error = JSON.stringify(error);
            let foundSurveyIndexAgain = this.surveyWrappers.findIndex(sw => sw.slug ===slug);
            if (foundSurveyIndexAgain){
              this.surveyWrappers[foundSurveyIndexAgain].loadingSurvey = false;
            }
        });
  };
  // goToLogin(){
  //   this.authService.setRouteForRedirectAfterAuth([window.location.pathname]);
  //   this.router.navigate(['/login']);
  // }

  snapshotIncludesSurveyCategoryAndType (category : string, type : string){
    if(category && !type){
      return this.surveyWrappers.map(sw => sw.survey).map(s=>s?.category).includes(category);
    } else if (!category && type){
      return this.surveyWrappers.map(sw => sw.survey).map(s=>s?.type).includes(type);
    } else if (category && type){
      return !!this.surveyWrappers.map(sw => sw.survey).find(s => s && s.type === type && s.category === category);
    } 
  }
  getSurveyWrappersSorted(surveyWrappers : SurveyWrapper[]){
    // moves the completed surveys to the end
    return surveyWrappers?.length ?
      surveyWrappers.filter(sw => !sw.progressValue || sw.progressValue < 100).concat(surveyWrappers.filter(sw=>sw.progressValue>=100))
    : [];
  }

  getRelatedSurveys(relatedSurveySlugs: string[]){ // Hard-coded now, but will come from the Snapshot
    this.error = null;
    this.surveyWrappers = relatedSurveySlugs.map((slug)=> {
      return {survey:null, relatedExperiences:null, started: null, progressValue: null, remainingMinutes:null, loadingSurvey:null,loadingRelatedExperiences:null,slug:slug}
    } );
    this.surveyWrappers.forEach(surveyWrapper => {
      this.getSurvey(surveyWrapper.slug,false);
    });
  }

  ngOnInit(): void {
    this.windowService.goToTop();
    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
      this.getAllRelatedExperiences();
    });
    this.guestSub = this.authService.guest.subscribe(guest => {
      this.guest = guest;
      this.getAllRelatedExperiences();
    });
    this.route.params.subscribe(
      (params : Params)=>{
        this.getSnapshot (params['snapshot'], false);
        // this.getSnapshot (this.route.snapshot.params['snapshot'], false); // TODO - test this approach; seems better - what happens if the user edits the URL?
      }
    );
    this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( () => {
      if (this.snapshot){
        this.getSnapshot(this.snapshot.id, true);
      }
    });
    this.raters = this.surveyRaterService.getDemoRaters();
  }
  ngOnDestroy () {
    if (this.guestSub){
      this.guestSub.unsubscribe();
    };
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    }
    if (this.snapshotSubscription){
      this.snapshotSubscription.unsubscribe();
    }
    if (this.fileUploadSubscription){
      this.fileUploadSubscription.unsubscribe();
    }
    if (this.progressablesSubscription){
      this.progressablesSubscription.unsubscribe();
    }
    if (this.activeLanguageSubscription){
      this.activeLanguageSubscription.unsubscribe();
    }
    if (this.resultSubscription){
      this.resultSubscription.unsubscribe();
    }
    if (this.alive){
      this.alive = false;
    }
  }

}
