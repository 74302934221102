import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ValidatorFn, FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { CloudinaryMediaAsset } from '@frontend/common';

@Component({
  selector: 'multisite-uploader-single-file',
  templateUrl: './uploader-single-file.component.html',
  styleUrls: ['./uploader-single-file.component.scss']
})
export class UploaderSingleFileComponent implements OnInit, OnChanges {

  @Input() requiredFileType : string;
  @Output() fileFormData  = new EventEmitter<FormData>();
  @Output() cancelUpload  = new EventEmitter<boolean>();
  @Output() selectGalleryItem  = new EventEmitter<number>();
  fileName = '';
  @Input() uploadProgress:number;
  @Input() isAdmin:boolean;
  @Input() askForLicenceAndCredit:boolean; // if yes, the form will show three extra fields: 'licence_url','licence','credit'
  @Input() emitOnChange : boolean; // if yes, don't wait for the form-submit. Emit immediately.
  @Input() errorMessage: string;
  @Input() successMessage: string;
  @Input() remoteFileDescriptionTranslationKey:string;
  @Input() fileDescriptionTranslationKey:string;
  @Input() fileInstructionsTranslationKey:string;
  @Input() uploadButtonTranslationKey:string = 'common.upload';
  @Input() gallery:CloudinaryMediaAsset[];
  @Input() filteredGallery:CloudinaryMediaAsset[];
  @Input() selectedGalleryItemMediaFileUrl: string;// Media id
  @Input() imageTransformations : string; // 'w_700,c_fill,ar_16:9/'
  @Input() disabled: boolean;
  @Input() resetFormOnChange: number;
  searchTerm: string;
  minimumImageUrlLength : number = 30;
  uploadSub: Subscription;
  file: File;
  fileUploadForm: FormGroup;
  sourceForm: FormGroup;
  source: string; // 'gallery','local' or 'remote'
  remoteImageUrlError: boolean;
  selectedGalleryItemId: number;
  remoteFileUrlChange$: Subject<string> = new Subject<string>();
  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;

  constructor() {}

  getFormData (formData : FormData): FormData {

    let foundError;

    if (this.source === 'local'){
      if (this.file) {
        this.fileName = this.file.name;
        formData.append("file", this.file);
      } else {
        alert('Could not find the file');
        foundError = true;
      }
    }
    if (this.source === 'remote'){
    let url = this.fileUploadForm.controls.remoteFileUrl.value;
      if (url) {
        formData.append("file_url", url);
      } else {
        alert('Could not find the file address');
        foundError = true;
      }
    }
    return foundError ? null : formData;
  }

  onUploadSubmit (){
    if (!this.fileUploadForm.valid) {
      return;
    }
    let formData = new FormData();
    formData = this.getFormData(formData);
    if (this.askForLicenceAndCredit){
      formData.append("licence", this.fileUploadForm.controls.licence.value);
      formData.append("licence_url", this.fileUploadForm.controls.licence_url.value);
      formData.append("credit", this.fileUploadForm.controls.credit.value);
    }
    this.fileFormData.emit(formData);
  }

  filterGallery(){
    this.filteredGallery = this.searchTerm ?  this.gallery.filter(mediaItem => mediaItem.description.includes(this.searchTerm)) : this.gallery;
  }
  onSelectGalleryItem (media_id: number){
    if (this.disabled){return;};
    let newSelection = this.gallery?.find(m=>m.id === media_id);
    if (this.selectedGalleryItemMediaFileUrl && this.selectedGalleryItemMediaFileUrl === newSelection?.file_url){
      return; // it's already selected, so do
    }
    const formData = new FormData();
    formData.append("media_id", media_id.toString());
    this.selectedGalleryItemId = media_id;
    this.fileFormData.emit(formData);
  }

  onFileSelected(event) {
      this.file = event.target.files[0];
      this.fileName = this.file ? this.file.name : '';
      if (this.emitOnChange){
        let formData = new FormData();
        formData = this.getFormData(formData);
        this.fileFormData.emit(formData);
      }
  }
  onRemoteUrlUpdated() {
    if (this.emitOnChange){
      let formData = new FormData();
      formData = this.getFormData(formData);
      this.fileFormData.emit(formData);
    }
  }

  checkUrlIsValidImage(url) {
    let image = new Image();
    image.onload = ()=> {
      if (image.width > 0) {
        this.updateRemoteImageUrlError(false);
        this.onRemoteUrlUpdated();
        console.log("image exists");

      }
    }
    image.onerror = () => {
      this.updateRemoteImageUrlError(true);
      console.log("image doesn't exist");
    }
    image.src = url;
  }

  updateRemoteImageUrlError(value: boolean) {
    this.remoteImageUrlError = value;
    const remoteFileUrlControl = this.fileUploadForm.get('remoteFileUrl');
    remoteFileUrlControl.setValidators([
      ...this.getRemoteFileUrlValidators(),
      this.remoteImageUrlErrorValidator(this.remoteImageUrlError)
    ]);
    remoteFileUrlControl.updateValueAndValidity();
  }

  remoteImageUrlErrorValidator(remoteImageUrlError: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (remoteImageUrlError) {
        return { remoteImageUrl_error: true };
      }
      return null;
    };
    /* TODO
    is this causing thousands of network requests?
    */
  }
  
  doCancelUpload() {
    this.cancelUpload.emit(true);
  }

  updateConditionalValidators (){
    this.fileUploadForm.get('fileData').setValidators(this.getFileDataValidators());
      this.fileUploadForm.get('remoteFileUrl').setValidators(this.getRemoteFileUrlValidators());

      this.fileUploadForm.get('fileData').updateValueAndValidity();
      this.fileUploadForm.get('remoteFileUrl').updateValueAndValidity();
  }

  getFileDataValidators() {
    if (this.source === 'local') {
      return [Validators.required];
    }
    return [];
  }

  getRemoteFileUrlValidators() {
    if (this.source === 'remote') {
      return [Validators.required,Validators.minLength(this.minimumImageUrlLength)];
    }
    return [];
  }
  changeSource (source:string, data: TabDirective){
    this.source = source;
    this.updateConditionalValidators();
    this.updateRemoteImageUrlError(false);
  }
  ngOnChanges (changesObject){
    if (changesObject.successMessage?.currentValue && !changesObject.successMessage?.previousValue){
      this.fileUploadForm.reset();
    }
    if (changesObject.resetFormOnChange?.currentValue > 0){
      this.fileUploadForm.reset(); 
    }
    if (changesObject.gallery?.currentValue?.length > 0){
      setTimeout(()=>{ // This is a bit hacky because ngIf prevents the tab from existing at this moment
        if (this.staticTabs?.tabs[2]) {
          this.staticTabs.tabs[2].active = true;
        }
      },250)
      this.filteredGallery = this.gallery;
    }
  }

  ngOnInit (){
    this.fileUploadForm = new FormGroup({
      fileData: new FormControl(null,  this.getFileDataValidators()),
      remoteFileUrl: new FormControl(null, [
        ...this.getRemoteFileUrlValidators(),
        this.remoteImageUrlErrorValidator(this.remoteImageUrlError)
      ])
    });
    if (this.askForLicenceAndCredit){
      this.fileUploadForm.addControl('licence', new FormControl(null, [Validators.required]));
      this.fileUploadForm.addControl('licence_url', new FormControl(null, [Validators.minLength(20),]));
      this.fileUploadForm.addControl('credit', new FormControl(null, [Validators.required,Validators.minLength(5),]));
    }
    this.fileUploadForm.controls.remoteFileUrl.valueChanges
      .pipe(
        debounceTime(500), // Adjust the debounce time as needed
        distinctUntilChanged()
      )
      .subscribe((value: string) => {
        this.remoteFileUrlChange$.next(value);
      });
    this.remoteFileUrlChange$.subscribe((value: string) => {
      if (this.source === 'remote' && value?.length >= this.minimumImageUrlLength) {
        this.checkUrlIsValidImage(value);
      }
    });
    if (this.staticTabs?.tabs[1]) { // we need changeSource to run so that we can update the validators
      this.staticTabs.tabs[1].active = true;
    } else {
      this.changeSource('local',null);
    }
  }

}
