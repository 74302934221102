import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { LoginFormComponent } from './login-form/login-form.component';

// import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { CollapseModule as NgxCollapseModule } from 'ngx-bootstrap/collapse';

import { AuthenticationOverviewComponent } from './authentication-overview/authentication-overview.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { RouterModule } from '@angular/router';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { PasswordResetFormComponent } from './password-reset-form/password-reset-form.component';

import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LoadersModule } from '../loaders/loaders.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { VerifyEmailLandingPageComponent } from './verify-email-landing-page/verify-email-landing-page.component';
import { TranslocoModule } from '@jsverse/transloco';
import { CarouselBasicModule, CarouselPageBodyFillModule } from '../content';



@NgModule({
  declarations: [
    LoginFormComponent,
    AuthenticationOverviewComponent,
    RegistrationFormComponent,
    VerifyEmailLandingPageComponent,
    ForgotPasswordFormComponent,
    PasswordResetFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    CarouselBasicModule,
    CarouselPageBodyFillModule,
    TranslocoModule,
    // MdbCollapseModule,
    NgxCollapseModule,
    AlertModule,
    TooltipModule,
    LoadersModule,
    ModalModule.forRoot(),
  ],
  exports: [
    LoginFormComponent,
    AuthenticationOverviewComponent,
    RegistrationFormComponent,
    VerifyEmailLandingPageComponent,
    ForgotPasswordFormComponent,
    PasswordResetFormComponent,
  ]
})
export class AuthModule { }
