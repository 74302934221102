import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'multisite-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  loading: boolean;
  errorMessage: string;
  forgotSubscription: Subscription;
  showWarningAlert : boolean = false;
  showSuccessAlert : boolean = false;
  sentEmail : string; // we store the email address here

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  resetErrorMessages() {
      this.showSuccessAlert = false;
      this.showWarningAlert = false;
      this.errorMessage = null;
  }
  sendForgotPasswordRequest() {
    let email = this.forgotPasswordForm.controls.email.value;
    if (!this.forgotPasswordForm.valid || !email) {
      return;
    }

    this.loading = true;
    this.resetErrorMessages();
    this.sentEmail = email;

    let forgotObservable = this.authService.forgotPassword(email);

    this.forgotSubscription = forgotObservable.subscribe(
      () => {
        this.loading = false;
        this.showSuccessAlert = true;
        this.forgotPasswordForm.controls.email.disable();
      },
      errorMessage => {
        this.showWarningAlert = true;
        this.errorMessage = errorMessage;
        this.loading = false;
      }
    );

  }
  ngOnInit(): void {
    this.loading = false;
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email])
    });
    this.forgotPasswordForm.controls.email.valueChanges.subscribe((value) => {
      if (this.forgotPasswordForm.controls.email.status !== 'DISABLED'){ // because when we disable the input, it causes a value change and so resets the alert messages and we don't want that.
        this.resetErrorMessages()
      }
    });
  }
  ngOnDestroy () : void {
    if (this.forgotSubscription){
      this.forgotSubscription.unsubscribe();
    };
  }

}
