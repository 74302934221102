import { Component, Inject, OnInit } from '@angular/core';
import { ApplicationService, LanguageService, WindowService } from '@frontend/common';
import { Page, PageService } from '@frontend/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'multisite',
  templateUrl: './open-science.component.html',
  styleUrls: ['./open-science.component.scss']
})
export class OpenScienceComponent implements OnInit {

  loading : boolean = false;
  pages : Page[];
  pagesSubscription : Subscription;
  activeLanguageSubscription: Subscription;
  error = null;
  pageType : string = 'open-science';

  constructor(
    private pageService : PageService,
    private applicationService : ApplicationService, 
    private languageService : LanguageService, 
    private windowService: WindowService,
    @Inject('appKey') private appKey: string,
  ) { }

  getPages (category: string, type : string){
    this.loading = true;
    this.error = null;
    this.pagesSubscription = this.pageService.getPagesByCategoryAndType(category, type)
      .subscribe(
        response => {
          this.pages = response;
          this.loading = false;
        },
        error => {
          this.error = error;  
          this.loading = false;
        }
      );
  }

  ngOnInit(): void {
    this.windowService.goToTop();
    if (this.appKey){
      this.getPages(this.appKey,this.pageType);
    };
    this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( () => {
      if (this.appKey){
        this.getPages(this.appKey,this.pageType);
      };
    })
  }

  ngOnDestroy (){
    this.pagesSubscription.unsubscribe();
  }

}
