import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { User } from '@frontend/common';
import { PaginatedComments } from '../comments-paginated.model';

@Component({
  selector: 'comments-post-display-pagination-all-in-one',
  templateUrl: './comments-post-display-pagination-all-in-one.component.html',
  styleUrls: ['./comments-post-display-pagination-all-in-one.component.scss']
})
export class CommentsPostDisplayPaginationAllInOneComponent {

  @Input() user : User;
  @Input() loadingComments: boolean;
  @Input() clearCommentForm: boolean; // if this changes, clear the comment form, no matter true or false
  @Output() postComment = new EventEmitter<string>();
  @Output() getComments = new EventEmitter<number>();

  @Input() paginatedComments : PaginatedComments

  constructor() { }

  doGetComments(whichPage){
    this.getComments.emit(whichPage);
  }

  doPostComment (message){
    this.postComment.emit(message);
  }
}
